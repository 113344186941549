import React, { ChangeEvent } from 'react'

import { Avatar, Box, Flex, Text } from '@chakra-ui/react'

import { ImageWrapper } from 'components'
import { useLanguages } from 'hooks'
import { responsivePadding } from 'utils/constant'

import { ReactComponent as CameraIcon } from 'assets/images/camera.svg'
import ReplaceBanner from 'assets/images/replace_banner.png'

interface AvatarBannerGroupProps {
  name?: string
  avatar?: string
  handleUpdateAvatar: (e: ChangeEvent<HTMLInputElement>) => void
  banner?: string
  handleUpdateBanner: (e: ChangeEvent<HTMLInputElement>) => void
}

const AvatarBannerGroup = ({
  name,
  avatar,
  handleUpdateAvatar,
  banner,
  handleUpdateBanner,
}: AvatarBannerGroupProps) => {
  const { _t } = useLanguages()

  return (
    <Box position='relative' mb='1rem'>
      <ImageWrapper
        src={banner || ReplaceBanner}
        defaultSrc={ReplaceBanner}
        alt='banner'
        w='full'
        h='full'
        maxH='14rem'
        objectFit='cover'
      />
      <Box px={responsivePadding}>
        <Box
          position='absolute'
          left={responsivePadding}
          transform='translateY(-50%)'
          pl='2rem'
        >
          <Box w='6rem' h='6rem' position='relative'>
            <Avatar
              size='xl'
              src={avatar}
              name={name || ' '}
              alt='User avatar'
              objectFit='cover'
            />
            <Flex
              position='absolute'
              top={0}
              justifyContent='center'
              alignItems='center'
              w='6rem'
              h='6rem'
              rounded='full'
            >
              <Box
                as='label'
                width='full'
                display='flex'
                alignItems='center'
                htmlFor='avatar'
              >
                <CameraIcon cursor='pointer' />
              </Box>

              <Box
                id='avatar'
                as='input'
                type='file'
                w='0.1px'
                h='0.1px'
                opacity={0}
                zIndex={-1}
                onChange={handleUpdateAvatar}
              />
            </Flex>
          </Box>
        </Box>
        <Box
          position='absolute'
          bottom='1rem'
          right={responsivePadding}
          h='3.1rem'
        >
          <Flex
            as='label'
            bg='primary'
            color='#fff'
            htmlFor='bannerFile'
            px='1rem'
            alignItems='center'
            h='100%'
            cursor='pointer'
            _hover={{ bg: '#4a6ee1' }}
            borderRadius='0.5rem'
          >
            <Flex alignItems='center'>
              <CameraIcon width='3.5rem' />
            </Flex>
            <Text fontWeight='700' textTransform='uppercase'>
              {_t('enterprise.banner.edit')}
            </Text>
          </Flex>
          <Box
            id='bannerFile'
            as='input'
            type='file'
            w='0.1px'
            h='0.1px'
            opacity={0}
            zIndex={-1}
            onChange={handleUpdateBanner}
          />
        </Box>
      </Box>
    </Box>
  )
}

export default AvatarBannerGroup

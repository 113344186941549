import React from 'react'

import { Text } from '@chakra-ui/react'

import { useLanguages } from 'hooks'
import { CashFlowType } from 'types'

interface CashFlowTypeTextProps {
  type?: CashFlowType
}

const CashFlowTypeText: React.FC<CashFlowTypeTextProps> = ({ type }) => {
  const { _t } = useLanguages()

  switch (type) {
    case CashFlowType.INSTRUCTOR:
      return <Text>{_t('cash_flow.type.instructor')}</Text>
    case CashFlowType.ENTERPRISE:
      return <Text>{_t('cash_flow.type.enterprise')}</Text>
    case CashFlowType.COMMISSION:
      return <Text>{_t('cash_flow.type.commission')}</Text>
    default:
      return <Text>{_t('common.unknown')}</Text>
  }
}

export default CashFlowTypeText

import React, { useEffect, useRef } from 'react'
import { useForm } from 'react-hook-form'

import { useAtom } from 'jotai'

import EditAdminProfileContainer from '../../containers/EditAdminProfile'
import { CreateCentreAdminForm } from '../../containers/forms'
import { getUploadAvatarUrl, uploadAvatar } from 'apis/user'
import { AvatarRef } from 'components'
import { useLanguages, useLoading, useToast } from 'hooks'
import { useAddUser } from 'queries/user'
import { userProfileAtom } from 'store/authAtom'
import { AddUserData, UserRole } from 'types'
import { checkValidFilesName, isMSTAdmin } from 'utils/helper'
import { getErrorMessage } from 'utils/parser'

interface CreateCentreAdminContainerProps {
  enterpriseId?: string
  centerId?: string
  handleToBack: () => void
}

const CreateCentreAdminContainer = ({
  enterpriseId,
  centerId,
  handleToBack,
}: CreateCentreAdminContainerProps) => {
  const { _t } = useLanguages()
  const { setLoading } = useLoading()
  const toast = useToast()
  const {
    control,
    formState: { errors: formErrors },
    getValues,
    watch: profileWatch,
    handleSubmit,
  } = useForm<CreateCentreAdminForm>({
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      recoveryEmail: '',
      address1: '',
      address2: '',
      city: '',
      state: '',
      country: '',
      zipCode: '',
      contactNumber: '',
    },
  })

  const { mutate: createUser, isSuccess, data: resData } = useAddUser()

  const avatarRef = useRef<AvatarRef>()

  const [userProfile] = useAtom(userProfileAtom)
  const isKnovoAdmin = isMSTAdmin(userProfile)

  const handleCreateUser = () => {
    if (
      avatarRef.current &&
      avatarRef.current.getUpdatedAvatar &&
      avatarRef.current.getUpdatedAvatar().avatar
    ) {
      const updatedAvatar = avatarRef.current.getUpdatedAvatar()
      const isFileNameValid = checkValidFilesName([
        { file: updatedAvatar.avatar as File },
      ])

      if (!isFileNameValid) {
        toast({
          title: _t('message.error.title'),
          status: 'error',
          duration: 6000,
          description: _t('message.error.InvalidFileName'),
        })
        return
      }
    }

    let createData = {} as AddUserData
    const roleName = UserRole.CenterAdmin

    const values = getValues()
    const formattedDataUser = {
      ...values,
      centerId: centerId || values.centre?.value,
      birthday: values.birthday
        ? new Date(values.birthday).getTime()
        : undefined,
    }
    delete formattedDataUser.centre
    delete formattedDataUser.enterprise

    createData = {
      roleName,
      ...formattedDataUser,
    }

    createUser(createData)
  }

  // use useEffect for uploading avatar after create user
  useEffect(() => {
    const handleUploadAvatar = async (userId: string) => {
      try {
        setLoading(true)
        const updatedAvatar = avatarRef.current?.getUpdatedAvatar?.()
        if (updatedAvatar?.avatar) {
          const { avatar, fileType } = updatedAvatar
          const { data } = await getUploadAvatarUrl(userId, fileType as string)
          const { fields, url } = data.data

          const formData = new FormData()
          Object.entries(fields).forEach(([key, value]) => {
            formData.append(key, value)
          }, [])
          formData.append('file', avatar as File)

          await uploadAvatar(url, formData)
        }
      } catch (err) {
        console.log(getErrorMessage(err))
      } finally {
        setLoading(false)
      }
    }
    if (isSuccess && resData && avatarRef.current) {
      const createdUserId = resData.data.data.id
      handleUploadAvatar(createdUserId)
    }
  }, [isSuccess, resData, setLoading])

  return (
    <EditAdminProfileContainer
      enterpriseId={enterpriseId}
      toBackLabel={_t('user_management.centre_admin.title')}
      title={_t('user_management.centre_admin.create_title')}
      handleToBack={handleToBack}
      handleSubmit={handleSubmit}
      handleSubmitCall={handleCreateUser}
      profileWatch={profileWatch}
      isLoading={false}
      avatarRef={avatarRef}
      control={control}
      formErrors={formErrors}
      showEnterprise={isKnovoAdmin}
      showCentre
    />
  )
}

export default CreateCentreAdminContainer

import React, {
  useCallback,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react'

import { Box, Flex } from '@chakra-ui/react'

import { StarsRagingRef } from 'types'

import { ReactComponent as ColorStarIcon } from 'assets/images/star_color.svg'

interface RatingStarProps {
  scale?: number
  starSize: string
  fillColor: string
  isEditable?: boolean
  initialValue?: number
}

const StarsRating: React.ForwardRefRenderFunction<
  StarsRagingRef,
  RatingStarProps
> = (
  {
    scale = 5, // number of stars
    starSize,
    fillColor,
    isEditable = true,
    initialValue = 3,
  },
  ref
) => {
  const [rating, setRating] = useState<number>(initialValue) // ex: 1,2,3,4,5
  const starsArr = useMemo(
    () => Array.from({ length: scale }, (_, i) => i + 1),
    [scale]
  )

  const onClickStar = useCallback(
    (idx: number) => {
      if (isEditable) {
        setRating(idx)
      }
    },
    [isEditable]
  )

  useImperativeHandle(
    ref,
    () => ({
      getStarNumber: () => rating,
    }),
    [rating]
  )

  return (
    <Flex>
      {starsArr.map(idx => (
        <Box key={idx} w={starSize} h={starSize} mr='3px'>
          <ColorStarIcon
            width={starSize}
            height={starSize}
            fill={idx <= rating ? fillColor : '#E0E0E0'}
            onClick={() => onClickStar(idx)}
            cursor='pointer'
          />
        </Box>
      ))}
    </Flex>
  )
}

export default React.forwardRef(StarsRating)

import { useMutation, useQuery } from 'react-query'

import {
  addCentre,
  createUploadAvatarCentre,
  createUploadBannerCentre,
  deleteCentre,
  updateCentre,
  uploadFileCentre,
} from '../apis/centre'
import { useInvalidateUrl, useLanguages, useLoading, useToast } from '../hooks'
import { useToastError } from './helpers'
import { centre, enterprise } from 'configs/apiPath'
import {
  Centre,
  ListingParams,
  Meta,
  Paginate,
  SubjectCombinationCentre,
  UserSearchResult,
} from 'types'

export type CentreQuery = {
  data: Centre[]
} & Meta

export type CentreTutorQuery = {
  data: UserSearchResult[]
} & Meta

export type CentreDetailQuery = {
  data: Centre
}

export type SubjectCombinationCentreQuery = {
  data: SubjectCombinationCentre[]
} & Meta

type ResponseData = {
  data: {
    data: {
      id: string
    }
  }
}

export const sleep = (m: number) => new Promise(r => setTimeout(r, m))

const handleUploadFile = async (
  type: 'avatar' | 'banner',
  centreId: string,
  file: File
) => {
  try {
    let res = undefined
    if (type === 'avatar') {
      res = await createUploadAvatarCentre({
        centreId,
      })
    } else {
      res = await createUploadBannerCentre({ centreId })
    }
    const formData = new FormData()
    const { fields, url } = res.data.data
    Object.entries(fields).forEach(([key, value]) => {
      formData.append(key, value)
    }, [])
    formData.append('file', file as File)
    const resUpload = await uploadFileCentre(url, formData)
    if (resUpload) {
      return 'success'
    }
  } catch (err) {
    console.log(err)
  }
}

export const useCentres = ({
  enterpriseId,
  ...listingParams
}: ListingParams & { enterpriseId: string }) => {
  return useQuery<CentreQuery>(centre.list(enterpriseId, { ...listingParams }))
}

export const useCentreDetail = ({ centreId }: { centreId: string }) => {
  return useQuery<CentreDetailQuery>(centre.detail(centreId))
}

export const useAddCentre = (enterpriseId: string) => {
  const toast = useToast()
  const invalidateUrl = useInvalidateUrl(
    centre.list(enterpriseId, { limit: 10, page: 1 })
  )
  const invalidateEnterpriseUrl = useInvalidateUrl(
    enterprise.list({ limit: 10, page: 1 })
  )
  const { setLoading } = useLoading()
  const { _t } = useLanguages()

  return useMutation(addCentre, {
    onMutate: () => setLoading(true),
    onSettled: () => setLoading(false),
    onSuccess: async (
      resData: ResponseData,
      { onClose, bannerFile, avatarFile, resetFile }
    ) => {
      const centreId = resData.data.data.id
      if (avatarFile) {
        await handleUploadFile('avatar', centreId, avatarFile)
      }
      if (bannerFile) {
        await handleUploadFile('banner', centreId, bannerFile)
      }
      // waiting for updating on database
      await sleep(2300)
      resetFile()
      if (!!enterpriseId) {
        invalidateUrl()
      } else {
        invalidateEnterpriseUrl()
      }
      onClose()
      toast({
        title: 'Success!',
        description: _t('message.success.centre_add'),
        status: 'success',
      })
    },
    onError: useToastError(),
  })
}

export const useUpdateCentre = (
  enterpriseId: string,
  centreId: string,
  refreshList: boolean = false
) => {
  const toast = useToast()
  const invalidateUrl = useInvalidateUrl(centre.detail(centreId))
  const invalidateUrlCentreList = useInvalidateUrl(
    centre.list(enterpriseId, { limit: 10, page: 1 })
  )
  const invalidateEnterpriseUrl = useInvalidateUrl(
    enterprise.list({ limit: 10, page: 1 })
  )
  const { setLoading } = useLoading()
  const { _t } = useLanguages()

  return useMutation(updateCentre, {
    onMutate: () => setLoading(true),
    onSettled: () => setLoading(false),
    onSuccess: async (_, { id, bannerFile, avatarFile, resetFile }) => {
      if (avatarFile) {
        await handleUploadFile('avatar', id, avatarFile)
      }
      if (bannerFile) {
        await handleUploadFile('banner', id, bannerFile)
      }
      // waiting for updating on database
      await sleep(2300)
      resetFile && resetFile()
      invalidateUrl()
      if (refreshList) {
        if (!!enterpriseId) {
          invalidateUrlCentreList()
        } else {
          invalidateEnterpriseUrl()
        }
      }
      toast({
        title: 'Success!',
        description: _t('message.success.centre_profile_update'),
        status: 'success',
      })
    },
    onError: useToastError(),
  })
}

export const useDeleteCentre = (
  enterpriseId: string,
  limit: number,
  currentPage: number
) => {
  const toast = useToast()
  const invalidateCentreUrl = useInvalidateUrl(
    centre.list(enterpriseId, { limit, page: currentPage })
  )
  const invalidateEnterpriseUrl = useInvalidateUrl(
    enterprise.list({ limit, page: currentPage })
  )
  const { setLoading } = useLoading()
  const { _t } = useLanguages()

  return useMutation(deleteCentre, {
    onMutate: () => setLoading(true),
    onSettled: () => setLoading(false),
    onSuccess: async () => {
      await invalidateCentreUrl()
      await invalidateEnterpriseUrl()
      sleep(2000)
      toast({
        title: 'Success!',
        description: _t('message.success.centre_remove'),
        status: 'success',
      })
    },
    onError: useToastError(),
  })
}

export const useCentreTeachingSubjectList = ({
  centreId,
}: {
  centreId: string
}) => {
  return useQuery<SubjectCombinationCentreQuery>(
    centre.teachingSubjectList(centreId)
  )
}

export const useCentreListByAdmin = ({
  page,
  limit,
  search,
}: ListingParams) => {
  return useQuery<CentreQuery>(centre.listByAdmin({ page, limit, search }))
}

export const useCentreGetTutors = ({
  centreId,
  ...paginate
}: Paginate & { centreId: string }) => {
  return useQuery<CentreTutorQuery>(
    centre.getListUser(centreId, {
      ...paginate,
      filterBy: 'tutor',
    })
  )
}

export const useCentreProfile = (centreId: string) => {
  return useQuery<any>(centre.profile(centreId))
}

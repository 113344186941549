import Axios from 'axios'

import { cashFlowPath } from '../configs/apiPath'
import { CashFlowFreezeRequest, CashFlowUpdateRequest } from 'types'

export const freeze = ({ cashFlowIds, isFreeze }: CashFlowFreezeRequest) => {
  return Axios.put(cashFlowPath.freeze(), {
    cashFlowIds,
    freeze: isFreeze,
  })
}

export const update = ({
  cashFlowIds,
  status,
  withdrawalType,
  remarks,
}: CashFlowUpdateRequest) => {
  return Axios.put(cashFlowPath.update(), {
    cashFlowIds,
    status,
    withdrawalType,
    remarks,
  })
}

import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import {
  Box,
  Button,
  Flex,
  Grid,
  Text,
  useNumberInput,
  useRadioGroup,
} from '@chakra-ui/react'
import { useAtom } from 'jotai'

import RadioCard from './RadioCard'
import { Input, Modal, ModalProps } from 'components'
import { useLanguages } from 'hooks'
import { cartAtom } from 'store/cartAtom'
import { formatAmount } from 'utils/parser'

interface TopUpModalProps extends Omit<ModalProps, 'children'> {}

const TopUpModal: React.FC<TopUpModalProps> = ({ isOpen, onClose }) => {
  const { _t } = useLanguages()
  const navigate = useNavigate()
  const [, setCart] = useAtom(cartAtom)
  const [topUpValue, setTopUpValue] = useState<number>(10)
  const [error, setError] = useState<string>('')
  const topUpOptions = ['10', '30', '50', '100']
  const { getRootProps, getRadioProps } = useRadioGroup({
    name: 'topUpValue',
    defaultValue: '10',
    onChange: (nextValue: string) => setTopUpValue(parseInt(nextValue)),
  })
  const group = getRootProps()

  const handleChangeTopUpInput = useCallback(
    (valueAsString: string, valueAsNumber: number) => {
      const parsedValue = isNaN(valueAsNumber)
        ? 0
        : Number(valueAsNumber.toFixed(2))
      setTopUpValue(parsedValue)
    },
    []
  )

  const { getInputProps } = useNumberInput({
    step: 0.01,
    precision: 2,
    onChange: handleChangeTopUpInput,
    value: topUpValue,
  })

  const handleContinue = useCallback(() => {
    setCart([
      {
        title: 'Top-up Credits',
        subtitle: `Top-up $${formatAmount(topUpValue)} credits.`,
        quantity: 1,
        price: Math.round(topUpValue * 100),
      },
    ])
    navigate('/payment/topup?callback=/wallet')
  }, [navigate, setCart, topUpValue])

  const handleCloseModal = useCallback(() => {
    onClose()
  }, [onClose])

  useEffect(() => {
    if (topUpValue === 0 || (topUpValue && topUpValue < 10)) {
      setError(_t('message.error.MinTopUpAmount'))
    } else if (topUpValue > 10000000) {
      setError(_t('message.error.MaxTopUpAmount'))
    } else {
      setError('')
    }
  }, [_t, topUpValue])

  return (
    <Modal isOpen={isOpen} onClose={handleCloseModal} size={'xl'}>
      <Box textStyle='headingModalText' textAlign='center' mb='1rem'>
        {_t('wallet.top_up.title')}
      </Box>

      <Box>
        <Input
          label={_t('wallet.top_up.amount')}
          w='14rem'
          placeholder={_t('wallet.top_up.placeholder_amount')}
          error={error}
          {...getInputProps()}
        />

        <Text fontWeight='600' mt='1.8rem' mb='1rem'>
          {_t('wallet.top_up.fix_amount')}
        </Text>

        <Flex
          alignItems='center'
          {...group}
          direction={{ base: 'column', md: 'row' }}
          justifyContent='space-between'
          gridGap='0.6rem'
        >
          {topUpOptions.map(value => {
            const radio = getRadioProps({ value })
            return (
              <RadioCard key={value} {...radio}>
                {value}
              </RadioCard>
            )
          })}
        </Flex>
      </Box>

      <Grid columnGap='0.5rem' gridTemplateColumns='1fr 1fr' mt='3rem'>
        <Button
          variant='solidPrimary'
          textTransform='uppercase'
          onClick={handleContinue}
          disabled={
            !topUpValue ||
            Number(topUpValue) < 10 ||
            Number(topUpValue) > 10000000
          }
        >
          {_t('common.continue')}
        </Button>
        <Button
          variant='transparent'
          textTransform='uppercase'
          onClick={onClose}
        >
          {_t('common.cancel')}
        </Button>
      </Grid>
    </Modal>
  )
}

export default TopUpModal

import React, { useCallback, useState } from 'react'
import { Wheel } from 'react-custom-roulette'
import TagManager from 'react-gtm-module'

import { Box, Button, Flex, Image, Text, useDisclosure } from '@chakra-ui/react'
import { useAtom } from 'jotai'

import { RewardModal } from '../../containers'
import { useLanguages } from 'hooks'
import { gameUserAtom } from 'store/gameAtom'
import { GameReward } from 'types'

import cloverImage from 'assets/images/clover.png'
import congratsImage from 'assets/images/congrats.png'
import tokenImage from 'assets/images/token.png'

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_ID || '',
  dataLayer: {
    event: 'Games',
    games_event: 'played_spin',
  },
}

const KnovoWheel = () => {
  const { _t } = useLanguages()
  const [gameUser, setGameUser] = useAtom(gameUserAtom)
  const [isSpinning, setIsSpinning] = useState(false)
  const [prizeNumber, setPrizeNumber] = useState(0)
  const [reward, setReward] = useState<GameReward>({
    key: '',
    title: '',
    description: '',
    distribution: [],
    promoCode: '',
    icon: <></>,
  })

  const { isOpen, onOpen, onClose } = useDisclosure()

  const data = [
    { option: 'Better Luck Next Time', style: { backgroundColor: '#7D89F4' } },
    { option: 'One More Spin', style: { backgroundColor: '#F7C59F' } },
    { option: '$5 Off', style: { backgroundColor: '#EF626C' } },
    { option: 'Better Luck Next Time', style: { backgroundColor: '#7D89F4' } },
    { option: '$10 Off', style: { backgroundColor: '#985F99' } },
    { option: '$5 Off', style: { backgroundColor: '#EF626C' } },
    { option: 'Better Luck Next Time', style: { backgroundColor: '#7D89F4' } },
    { option: 'One More Spin', style: { backgroundColor: '#F7C59F' } },
    { option: '$5 Off', style: { backgroundColor: '#EF626C' } },
    { option: 'Better Luck Next Time', style: { backgroundColor: '#7D89F4' } },
    { option: '$10 Off', style: { backgroundColor: '#985F99' } },
    { option: '$5 Off', style: { backgroundColor: '#EF626C' } },
  ]
  const rewardModals = {
    tenDollar: {
      key: 'tenDollar',
      title: _t('games.wheel.reward_title_ten_dollar'),
      description: '',
      distribution: [4, 10],
      promoCode: 'DYWKY4C',
      icon: <Image src={congratsImage} alt='tenDollar' />,
    },
    fiveDollar: {
      key: 'fiveDollar',
      title: _t('games.wheel.reward_title_five_dollar'),
      description: '',
      distribution: [2, 5, 8, 11],
      promoCode: 'KRFY1EF',
      icon: <Image src={congratsImage} alt='fiveDollar' />,
    },
    oneSpin: {
      key: 'oneSpin',
      title: _t('games.wheel.reward_title_free_spin'),
      description: '',
      distribution: [1, 7],
      promoCode: '',
      icon: <Image src={tokenImage} alt='oneSpin' />,
    },
    betterLuck: {
      key: 'betterLuck',
      title: _t('games.wheel.reward_title_better_luck'),
      description: '',
      distribution: [0, 3, 6, 9],
      promoCode: '',
      icon: <Image src={cloverImage} alt='betterLuck' />,
    },
  }

  const handleSpinClick = useCallback(() => {
    TagManager.dataLayer(tagManagerArgs)

    const percent = Math.floor(Math.random() * 99)

    if (gameUser?.tokens !== undefined) {
      if (gameUser.tokens > 0) {
        setIsSpinning(true)
        setGameUser({ tokens: gameUser.tokens - 1 })
        let rewardModal = rewardModals.betterLuck
        if (percent >= 87) {
          rewardModal = rewardModals.tenDollar
        } else if (percent >= 68) {
          rewardModal = rewardModals.fiveDollar
        } else if (percent >= 60) {
          rewardModal = rewardModals.oneSpin
        }
        const index = Math.floor(
          Math.random() * rewardModal.distribution.length
        )
        setPrizeNumber(rewardModal.distribution[index])
        setReward(rewardModal)
      }
    }
  }, [
    gameUser,
    rewardModals.betterLuck,
    rewardModals.fiveDollar,
    rewardModals.oneSpin,
    rewardModals.tenDollar,
    setGameUser,
  ])

  return (
    <Box pt='1rem' pb='4rem' mx='auto'>
      <Flex
        mx='auto'
        justifyContent='center'
        alignItems='center'
        mb='2rem'
        mt='2rem'
      >
        <Text
          as='h1'
          mx='1.5rem'
          textAlign='center'
          fontWeight='semibold'
          className={'blue-purple-gradient-text'}
        >
          {_t('games.wheel.title').toUpperCase()}
        </Text>
      </Flex>
      <Flex direction={'column'} justifyContent={'center'} align={'center'}>
        <Wheel
          mustStartSpinning={isSpinning}
          prizeNumber={prizeNumber}
          data={data}
          fontSize={12}
          textColors={['white']}
          outerBorderWidth={0}
          radiusLineWidth={1}
          radiusLineColor={'white'}
          onStopSpinning={() => {
            onOpen()
            setIsSpinning(false)
            if (reward.key === 'oneSpin') {
              setGameUser({ tokens: (gameUser?.tokens || 0) + 1 })
            }
          }}
        />
        <Flex direction={'column'} align={'center'} mt='1rem'>
          <Text fontSize={'0.875rem'}>
            {`${_t('games.wheel.label_spin_remaining')}: ${
              gameUser?.tokens || 0
            }`}
          </Text>
          <Button
            variant='solidPrimary'
            w='20rem'
            disabled={!!!gameUser?.tokens || isSpinning}
            onClick={handleSpinClick}
          >
            {_t('games.wheel.button_spin').toUpperCase()}
          </Button>
        </Flex>
      </Flex>
      <RewardModal gameReward={reward} isOpen={isOpen} handleClose={onClose} />
    </Box>
  )
}

export default KnovoWheel

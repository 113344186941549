import React, { useEffect, useMemo, useState } from 'react'

import {
  Button,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
} from '@chakra-ui/react'
import { useAtom } from 'jotai'

import { Dot } from 'components/ActionDot'
import { ChatBox } from 'containers'
import { userProfileAtom } from 'store/authAtom'
import { derivedTotalUnreadMessagesAtom } from 'store/chatAtom'
import { UserType } from 'types'
import { isInstructor, isLearner } from 'utils/helper'

import { ReactComponent as Chat } from 'assets/images/chat.svg'

const ChatPopover = () => {
  let userType = ''
  const [userProfile] = useAtom(userProfileAtom)
  const [totalUnreadMessagesAtom] = useAtom(derivedTotalUnreadMessagesAtom)
  const [displayTotalUnreadMsg, setDisplayTotalUnreadMsg] = useState<number>(0)

  useEffect(() => {
    const countTotalToDisplay = totalUnreadMessagesAtom.reduce((acc, cur) => {
      return acc + cur.amount
    }, 0)
    setDisplayTotalUnreadMsg(countTotalToDisplay)
  }, [totalUnreadMessagesAtom])

  const isInstructorMemo = useMemo(() => isInstructor(userProfile), [
    userProfile,
  ])
  const isLearnerMemo = useMemo(() => isLearner(userProfile), [userProfile])

  if (!isInstructorMemo && !isLearnerMemo) {
    return null
  }

  if (isInstructorMemo) {
    userType = UserType.Tutor
  } else if (isLearnerMemo) {
    userType = UserType.Student
  }

  return (
    <Popover>
      <PopoverTrigger>
        <Button variant='transparent' px='0'>
          <Chat cursor='pointer' />
          {!!displayTotalUnreadMsg && (
            <Dot
              w='0.8rem'
              h='0.8rem'
              bgColor='error'
              position='absolute'
              top='5px'
              right='2px'
              fontSize='0.5rem'
              color='white'
              display='flex'
              justifyContent='center'
              alignItems='center'
            >
              {displayTotalUnreadMsg}
            </Dot>
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent
        w={{ md: '26rem', base: '98vw' }}
        h={{ md: '34rem', base: '100vh' }}
        rounded='lg'
        boxShadow='lg'
        _focus={{
          outline: 0,
        }}
      >
        <PopoverBody p='0' background='white' rounded='lg' overflow='hidden'>
          <ChatBox userType={userType} />
        </PopoverBody>
      </PopoverContent>
    </Popover>
  )
}

export default ChatPopover

import React, { useMemo, useState } from 'react'

import { Box, BoxProps, Container, Flex } from '@chakra-ui/react'
import { useAtom } from 'jotai'

import Sidebar from '../Sidebar'
import { adminRoutes } from 'configs/adminRoutes'
import { Header } from 'shared/containers'
import { userProfileAtom } from 'store/authAtom'
import { Login, UserRole } from 'types'

interface LayoutProps extends BoxProps {
  containerProps?: BoxProps
  topElement?: React.ReactElement
}

const Layout: React.FC<LayoutProps> = ({
  children,
  containerProps,
  topElement,
  ...boxProps
}) => {
  const [userProfile] = useAtom(userProfileAtom)
  const roles = useMemo(() => userProfile?.roles?.map(role => role.type), [
    userProfile,
  ])
  const isMSTAdmin = useMemo(
    () => roles?.includes(UserRole.MSTAdmin) || false,
    [roles]
  )

  const [sideBarOpen, setSideBarOpen] = useState<boolean>(true)
  const toggleSidebar = () => setSideBarOpen(!sideBarOpen)

  return (
    <Box {...boxProps}>
      <Header
        sideBarOpen={sideBarOpen}
        toggleSidebar={toggleSidebar}
        loginType={Login.UserName}
      />
      <Flex>
        <Sidebar sideBarStatus={sideBarOpen} routes={adminRoutes(isMSTAdmin)} />
        <Box
          ml={sideBarOpen ? '16.5rem !important' : '0rem !important'}
          w={sideBarOpen ? 'calc(100% - 16.5rem)' : '100%'}
          transition='0.5s'
          bgColor='containerBg'
        >
          <Box w='full'>{topElement}</Box>
          <Container
            maxWidth='100%'
            minHeight='calc(100vh - 4.5rem)'
            px='5rem'
            py='2.25rem'
            overflow='auto'
            {...containerProps}
          >
            {children}
          </Container>
        </Box>
      </Flex>
    </Box>
  )
}

export default Layout

import React, { useCallback, useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'

import { Box } from '@chakra-ui/react'

import BankInfoSection from '../containers/BankInfoSection'
import { WithdrawalEditForm } from '../form'
import EditWithdrawalForm from './EditForm'
import { HeadingSection, LoadingSpinner } from 'components'
import { useLanguages } from 'hooks'
import {
  useAdminTransaction,
  useAdminUpdateWithdrawal,
} from 'queries/transaction'
import { useUserWallet } from 'queries/wallet'
import { ApiUserType, TransactionStatus } from 'types'

interface EditWithdrawalProps {
  backTitle: string
  handleBack?: () => void
}

const EditWithdrawal: React.FC<EditWithdrawalProps> = ({
  backTitle,
  handleBack,
}) => {
  const { _t } = useLanguages()
  const params = useParams()
  const transactionId = useMemo(() => params.id, [params])

  const {
    data: transaction,
    isLoading: isTransactionLoading,
  } = useAdminTransaction({
    transactionId,
  })
  const transactionData = useMemo(() => transaction?.data, [transaction])

  const { control, errors, handleSubmit } = useForm<WithdrawalEditForm>({
    mode: 'onChange',
    defaultValues: {
      isCompleted: transactionData?.status === TransactionStatus.COMPLETED,
      remarks: transactionData?.remarks,
    },
  })

  const userId = useMemo(() => (transactionData?.userId as string) || '', [
    transactionData,
  ])
  const centerId = useMemo(() => (transactionData?.centerId as string) || '', [
    transactionData,
  ])
  const walletRequest = useMemo(() => {
    if (centerId) {
      return { type: 'center' as ApiUserType, id: centerId }
    }
    return { type: 'user' as ApiUserType, id: userId }
  }, [centerId, userId])
  const { data: walletInfo, isLoading } = useUserWallet(walletRequest)
  const { mutate: updateWithdrawal, isSuccess } = useAdminUpdateWithdrawal()
  const walletData = useMemo(() => walletInfo?.data, [walletInfo])

  const handleUpdateWithdrawal = useCallback(
    (value: WithdrawalEditForm) => {
      const { isCompleted, remarks } = value
      const formattedData = {
        transactionId,
        status: isCompleted ? TransactionStatus.COMPLETED : undefined,
        remarks,
      }

      updateWithdrawal({ data: formattedData })
    },
    [updateWithdrawal, transactionId]
  )

  const buttonData = useMemo(() => {
    const result = []

    if (handleSubmit) {
      result.push({
        label: _t('common.update'),
        onClick: handleSubmit(handleUpdateWithdrawal),
      })
    }

    return result
  }, [handleSubmit, handleUpdateWithdrawal, _t])

  useEffect(() => {
    if (isSuccess) {
      handleBack && handleBack()
    }
  }, [handleBack, isSuccess])

  return (
    <Box>
      <HeadingSection
        title={_t('withdrawal.management.edit_title')}
        description={_t('withdrawal.management.edit_description')}
        backLabel={backTitle}
        handleToBack={handleBack}
        buttonData={buttonData}
        isAdminStyle
      />
      <Box p='2rem 5rem 0'>
        {isLoading && <LoadingSpinner />}
        {!isLoading && walletData && (
          <BankInfoSection data={walletData} isEdit />
        )}
        {isTransactionLoading && <LoadingSpinner />}
        {!isTransactionLoading && transactionData && (
          <EditWithdrawalForm
            data={transactionData}
            control={control}
            errors={errors}
          />
        )}
      </Box>
    </Box>
  )
}

export default EditWithdrawal

import Axios, { AxiosResponse } from 'axios'

import { tokenPath } from 'configs/apiPath'
import { FirebaseTokenResponse } from 'types'

export const getFirebaseToken = async (): Promise<
  AxiosResponse<FirebaseTokenResponse>
> => {
  return Axios.post(tokenPath.getFirebaseToken())
}

import React from 'react'

import { Flex, Text } from '@chakra-ui/react'

import { Tooltip } from 'components'

import { ReactComponent as InfoIcon } from 'assets/images/info_outline_icon.svg'
import { ReactComponent as WarningIcon } from 'assets/images/warning_icon.svg'

interface ItemDetailProps {
  title: string
  text?: string
  tooltipText?: string
  isWarning?: boolean
  isVertical?: boolean
}

const ItemDetail: React.FC<ItemDetailProps> = ({
  title,
  text = '',
  tooltipText = null,
  isWarning = false,
  isVertical = false,
}) => {
  return (
    <Flex
      flexDir={isVertical ? 'column' : 'row'}
      alignItems={isVertical ? '' : 'center'}
    >
      <Text
        textStyle='detailTitle'
        color='gray.100'
        lineHeight='1.125rem'
        textTransform='uppercase'
      >
        {title}
      </Text>
      <Flex alignItems='center'>
        <Text
          ml={isVertical ? '' : '1rem'}
          mr='0.5rem'
          fontSize='14px'
          color={isWarning ? 'Orange' : 'black'}
          noOfLines={3}
        >
          {text}
        </Text>
        {tooltipText && (
          <Tooltip label={tooltipText}>
            {isWarning ? (
              <WarningIcon height='14px' width='14px' />
            ) : (
              <InfoIcon height='14px' width='14px' />
            )}
          </Tooltip>
        )}
      </Flex>
    </Flex>
  )
}

export default ItemDetail

import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'

import qs from 'query-string'

export default () => {
  const location = useLocation()

  return useMemo(() => {
    const params = qs.parse(location.search)
    return +(params?.page || 1)
  }, [location])
}

import { useQuery } from 'react-query'

import { adminPath } from 'configs/apiPath'
import { Permission } from 'types'

type PermissionQuery = {
  data: Permission[]
}

export const usePermission = () => {
  return useQuery<PermissionQuery>(adminPath.getListPermission())
}

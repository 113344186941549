import React, { Suspense, useEffect, useMemo } from 'react'
import { Route, Routes } from 'react-router-dom'

import { useAtom } from 'jotai'

import AdminRoutes from './AdminRoutes'
import CenterAdminRoutes from './CenterAdminRoutes'
import LearnerDashboardRoutes from './DashboardRoutes'
import EnterpriseAdminRoutes from './EnterpriseAdminRoutes'
import GuestRoutes from './GuestRoutes'
import TutorRoutes from './TutorRoutes'
import { LoadingScreen } from 'components'
import storageKey from 'configs/storageKey'
import SignIn from 'containers/SignIn'
import { useToast, useUserId, useUtilsForEnterpriseId } from 'hooks'
import { authenticationCheckedAtom, userProfileAtom } from 'store/authAtom'
import { UserRole } from 'types'
import { getData, removeData } from 'utils/helper'

const AdminRoute = ({ isMstAdmin }: { isMstAdmin: boolean }) => {
  return (
    <Suspense fallback={<LoadingScreen />}>
      <Routes>
        <Route path='/*' element={<AdminRoutes isMstAdmin={isMstAdmin} />} />
      </Routes>
    </Suspense>
  )
}

const EnterpriseAdminRoute = () => {
  return (
    <Suspense fallback={<LoadingScreen />}>
      <Routes>
        <Route path='/*' element={<EnterpriseAdminRoutes />} />
      </Routes>
    </Suspense>
  )
}

const CenterAdminRoute = () => {
  return (
    <Suspense fallback={<LoadingScreen />}>
      <Routes>
        <Route path='/*' element={<CenterAdminRoutes />} />
      </Routes>
    </Suspense>
  )
}

const TutorRoute = () => {
  return (
    <Suspense fallback={<LoadingScreen />}>
      <Routes>
        <Route path='/*' element={<TutorRoutes />} />
      </Routes>
    </Suspense>
  )
}

const LearnerRoute = () => {
  return (
    <Suspense fallback={<LoadingScreen />}>
      <Routes>
        <Route path='/*' element={<LearnerDashboardRoutes />} />
      </Routes>
    </Suspense>
  )
}

const GuestRoute = () => {
  return (
    <Suspense fallback={<LoadingScreen />}>
      <Routes>
        <Route path='/*' element={<GuestRoutes />} />
      </Routes>
    </Suspense>
  )
}

const AppRoutes = () => {
  const [isAuthenticationChecked] = useAtom(authenticationCheckedAtom)
  const [userProfile] = useAtom(userProfileAtom)
  const {
    enterpriseId,
    saveEnterpriseId,
    error,
    resetError,
    loading,
  } = useUtilsForEnterpriseId()
  const userId = useUserId()
  const toast = useToast()

  const selectedRoleInSignUp = getData(storageKey.signUpRole)
  const isGuest = getData(storageKey.loginType) === 'guest'

  const roles = useMemo(() => userProfile?.roles?.map(role => role.type), [
    userProfile,
  ])
  const isMSTAdmin = useMemo(
    () =>
      roles?.includes(UserRole.MSTSuperAdmin) ||
      roles?.includes(UserRole.MSTAdmin),
    [roles]
  )
  const isCenterAdmin = useMemo(() => roles?.includes(UserRole.CenterAdmin), [
    roles,
  ])
  const isEnterpriseAdmin = useMemo(
    () =>
      roles?.includes(UserRole.EnterpriseSuperAdmin) ||
      roles?.includes(UserRole.EnterpriseAdmin),
    [roles]
  )
  const isTutor = useMemo(
    () =>
      (roles?.includes(UserRole.Tutor) &&
        localStorage.getItem(storageKey.role) === UserRole.Tutor) ||
      roles?.includes(UserRole.EnterpriseTutor),
    [roles]
  )
  const isLearner = useMemo(
    () =>
      (roles?.includes(UserRole.Student) &&
        localStorage.getItem(storageKey.role) === UserRole.Student) ||
      roles?.includes(UserRole.EnterpriseStudent),
    [roles]
  )

  useEffect(() => {
    if (isEnterpriseAdmin && userId) {
      saveEnterpriseId(userId)
    }
  }, [isEnterpriseAdmin, saveEnterpriseId, userId])

  useEffect(() => {
    if (!!error) {
      toast({
        title: 'Error!',
        description: error,
        status: 'error',
      })
      resetError()
    }
  }, [error, resetError, toast])

  if (!isAuthenticationChecked) {
    return <LoadingScreen />
  }

  if (isGuest) {
    return <GuestRoute />
  }

  if (isMSTAdmin) {
    return (
      <AdminRoute isMstAdmin={roles?.includes(UserRole.MSTAdmin) || false} />
    )
  }

  if (isEnterpriseAdmin) {
    if (!enterpriseId && loading) {
      return <LoadingScreen />
    }
    if (enterpriseId && !error) {
      return <EnterpriseAdminRoute />
    } else {
      return <SignIn />
    }
  }

  if (isCenterAdmin) {
    return <CenterAdminRoute />
  }

  if (isTutor) {
    return <TutorRoute />
  }

  if (isLearner) {
    return <LearnerRoute />
  }

  // incase user sign up success and then automatically login
  // at this moment server has not update role yet
  if (selectedRoleInSignUp === UserRole.Tutor) {
    setTimeout(() => removeData(storageKey.signUpRole), 5000)
    return <TutorRoute />
  }
  if (selectedRoleInSignUp === UserRole.Student) {
    setTimeout(() => removeData(storageKey.signUpRole), 5000)
    return <LearnerRoute />
  }

  return <GuestRoute />
}

export default AppRoutes

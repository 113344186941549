import { Option } from 'components'

interface CardInfo {
  name: string
  last4: string
  brand: string
}

interface WalletInfo {
  currency: string
  topUpBalance: number
  payoutBalance: number
  availableBalance: number
  expenseBalance: number
  holdingBalance: number
  expenseHoldingBalance: number
}

export interface UserWalletInfo {
  id: string
  balance: number
  currency: string
  bankAccountName?: string
  bankAccountNumber?: string
  bankName?: string
  bankRemarks?: string
  country?: string
  wallet?: WalletInfo
  card?: CardInfo
}

export type ApiUserType = 'user' | 'center' | 'enterprise' | 'admin'

export type UserAddCardType = 'privateTutor' | 'enterprise' | 'student' | 'mst'

export interface UserCard {
  id: string
  type: UserAddCardType
  enterpriseId?: string
}

export interface BankDetailForm {
  bankAccountName: string
  bankName: string
  bankAccountNumber: string
  bankRemarks: string
  country: Option
}

export interface WalletInfoRequest {
  type: ApiUserType
  id: string
}

export interface WalletInfoResponse {
  data: UserWalletInfo
}

export interface WalletBankRequest {
  type: ApiUserType
  id: string
  bankAccountName: string
  bankAccountNumber: string
  bankName: string
  bankRemarks: string
  country: string
}

export enum PaymentMethod {
  CREDIT = 'credit',
  DEBIT = 'debit',
}

export enum PaymentType {
  CREDIT = 'credit',
  CARD = 'card',
  GCASH = 'gcash',
  BANK = 'bank',
  CHEQUE = 'cheque',
  CASH = 'cash',
}

export interface WalletTopUpRequest {
  amount: number
  paymentType: PaymentType
  paymentMethodId?: string
  callbackUrl?: string
}

export interface WalletWriteOffRequest {
  userId?: string
  centerId?: string
  method: PaymentMethod
  amount: number
  remarks: string
}

import React from 'react'
import { Control, Controller } from 'react-hook-form'

import { SimpleGrid } from '@chakra-ui/react'

import { ContentContainer, Input, Select, Textarea } from 'components'
import { useLanguages } from 'hooks'
import { BankDetailForm } from 'types'
import { bankAccountCountryOptions } from 'utils/constant'

interface BankAccountEditProps {
  control: Control<BankDetailForm>
  errors: any
}

const BankAccountEdit: React.FC<BankAccountEditProps> = ({
  control,
  errors,
}) => {
  const { _t } = useLanguages()

  return (
    <ContentContainer title={_t('user.bank_account.title')}>
      <SimpleGrid mb='2rem' columns={[1, 1, 2, 2]} spacing='2rem'>
        <Controller
          as={Input}
          control={control}
          name='bankAccountName'
          label={_t('user.bank_account.name')}
          aria-label='Bank Account Holder Name'
          placeholder={_t('user.bank_account.placeholder_name')}
          error={errors?.bankAccountName?.message || ''}
        />
        <Controller
          as={Input}
          control={control}
          name='bankName'
          label={_t('user.bank_account.bank_name')}
          aria-label='Bank Name'
          placeholder={_t('user.bank_account.placeholder_bank_name')}
          error={errors?.bankName?.message || ''}
        />
        <Controller
          as={Input}
          control={control}
          name='bankAccountNumber'
          label={_t('user.bank_account.number')}
          aria-label='Bank Account Number'
          placeholder={_t('user.bank_account.placeholder_number')}
          error={errors?.bankAccountNumber?.message || ''}
        />
        <Controller
          as={Select}
          control={control}
          name='country'
          label={_t('user.bank_account.country')}
          aria-label='Country'
          placeholder={_t('user.bank_account.placeholder_country')}
          defaultOptions={bankAccountCountryOptions}
          selectProps={{
            fontSize: '1rem',
          }}
          error={errors?.country?.message || ''}
          rules={{
            required: _t('user.bank_account.validate_country_required'),
          }}
        />
      </SimpleGrid>
      <Controller
        as={Textarea}
        control={control}
        name='bankRemarks'
        label={_t('user.bank_account.remarks')}
        aria-label='Remarks'
        placeholder={_t('user.bank_account.placeholder_remarks')}
        error={errors?.bankRemarks?.message || ''}
      />
    </ContentContainer>
  )
}

export default BankAccountEdit

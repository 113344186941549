import React from 'react'
import { useNavigate } from 'react-router-dom'

import { Box, Flex } from '@chakra-ui/react'

import { getClassDetail } from 'apis/class'
import { LoadingSpinner, Paginate, Result } from 'components'
import { classPath } from 'configs/apiPath'
import {
  useCurrentPage,
  useInvalidateUrl,
  useLanguages,
  useLoading,
  useToast,
  useUserId,
} from 'hooks'
import {
  useDeleteClass,
  useDraftClasses,
  useDuplicateClass,
  useUpdateClass,
} from 'queries/class'
import { DraftCard } from 'shared/containers'
import { ResultType } from 'types'
import { getErrorMessage } from 'utils/parser'

const limit = 10

const DraftClassesContainer = () => {
  const userId = useUserId()
  const toast = useToast()
  const navigate = useNavigate()
  const currentPage = useCurrentPage()
  const { setLoading } = useLoading()
  const { _t } = useLanguages()
  const invalidateDraftClasses = useInvalidateUrl(
    classPath.listDraftClasses(userId, {
      page: currentPage,
      limit,
      order: 'desc',
    })
  )

  const { data: draftClasses, isLoading } = useDraftClasses({
    userId,
    page: currentPage,
    limit,
    order: 'desc',
  })
  const { mutate: deleteClass } = useDeleteClass({})
  const { mutate: duplicateClass } = useDuplicateClass()
  const { mutate: updateClass } = useUpdateClass()

  const handleDuplicateClass = (classId: string) => {
    duplicateClass({ classId, onInvalidateUrl: invalidateDraftClasses })
  }

  const handleEditDraft = (id: string) => {
    navigate(`/draft-class/edit/class/${id}`)
  }

  const handlePreviewDraft = (id: string) => {
    navigate(`/draft-class/preview/class/${id}`)
  }

  const handleDeleteClass = (classId: string) => {
    deleteClass({ classId, onInvalidateUrl: invalidateDraftClasses })
  }

  const handlePublish = async (classId: string) => {
    try {
      setLoading(true)
      const res = await getClassDetail({ classId })
      const detailData = res?.data.data

      if (detailData) {
        const formattedData = {
          classId,
          centerId: detailData?.center?.id,
          tutorId: detailData?.user?.id,
          status: 'public',
        }

        updateClass({
          classId,
          data: formattedData,
          onInvalidateUrl: invalidateDraftClasses,
        })
      }
    } catch (error) {
      toast({
        title: 'Error!',
        description: _t(`message.error.${getErrorMessage(error)}`),
        status: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <Box>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <Flex direction='column'>
            {draftClasses?.data.map(item => (
              <Box mt='1.8rem' key={item.id}>
                <DraftCard
                  data={item}
                  onEditDraft={handleEditDraft}
                  onDeleteDraft={handleDeleteClass}
                  onDuplicateDraft={handleDuplicateClass}
                  onPublish={handlePublish}
                  onPreviewDraft={handlePreviewDraft}
                />
              </Box>
            ))}
            {!draftClasses?.data?.length && (
              <Box my='2rem'>
                <Result
                  title={_t('product.drafts.empty_title')}
                  subTitle={_t('product.drafts.empty_description')}
                  type={ResultType.CLASS}
                />
              </Box>
            )}
            {!!draftClasses?.data?.length && (
              <Box display='flex' justifyContent='center' my='1.8rem'>
                <Paginate
                  currentPage={currentPage}
                  perPage={limit}
                  totalItems={draftClasses.meta.total}
                />
              </Box>
            )}
          </Flex>
        </>
      )}
    </Box>
  )
}

export default DraftClassesContainer

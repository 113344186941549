import React, { useCallback, useEffect, useState } from 'react'

import { Avatar, Box, Button, Flex, Grid, Text } from '@chakra-ui/react'
import { useAtom } from 'jotai'

import { useUserId } from 'hooks'
import { useAcceptConnection } from 'queries/connection'
import { hasNewAcceptedNotificationAtom } from 'store/notificationAtom'
import { NotificationActionType, NotificationOnApp } from 'types'
import { parseTimeDistance, parseTimeNotification } from 'utils/parser'

import defaultAvatar from 'assets/images/default_avatar_grayscale.svg'

interface NotificationItemProps {
  data: NotificationOnApp
  onClickItem: (item: any) => () => void
}

const NotificationItem: React.FC<NotificationItemProps> = ({
  data,
  onClickItem,
}) => {
  const currentUserId = useUserId()
  const [hide, setHide] = useState(false)
  const [, setHasNewAcceptedNotification] = useAtom(
    hasNewAcceptedNotificationAtom
  )

  // ** Queries
  const {
    mutate: acceptConnection,
    isSuccess: acceptConnectionIsSuccess,
    isLoading: acceptConnectionIsLoading,
    reset: acceptConnectionOnReset,
  } = useAcceptConnection()

  // ** Effect
  // Hide button accept
  useEffect(() => {
    if (acceptConnectionIsSuccess) {
      setHide(true)
      acceptConnectionOnReset()
    }
  }, [acceptConnectionIsSuccess, acceptConnectionOnReset])

  useEffect(() => {
    if (acceptConnectionIsSuccess) {
      setHasNewAcceptedNotification(true)
    }
  }, [acceptConnectionIsSuccess, setHasNewAcceptedNotification])

  const handleAcceptConnection = useCallback(
    e => {
      e.stopPropagation()
      acceptConnection({ userId: currentUserId, requestId: data.connectionId })
    },
    [acceptConnection, currentUserId, data.connectionId]
  )

  const renderButtonAccept = (buttonData: any) => {
    if (
      buttonData?.action === NotificationActionType.ADD_FRIEND &&
      !hide &&
      !buttonData?.isHiddenAccept
    ) {
      return (
        <Box flex='1'>
          <Button
            isLoading={acceptConnectionIsLoading}
            variant='outlinePrimary'
            p='1rem'
            onClick={handleAcceptConnection}
          >
            ACCEPT
          </Button>
        </Box>
      )
    }
    return null
  }

  const generateMessage = useCallback(
    (notificationData: NotificationOnApp, content: any) => {
      if (notificationData?.isParse) {
        const regexDate = /\d{13}_Date/i
        const regexTime = /\d{13}_Time/i
        const regexDateTime = /\d{13}_DateTime/i
        let message = notificationData.message.replace(
          '{{name}}',
          `${content.firstName} ${content.lastName}`
        )

        const formattedMessageArr = message.split(' ').map(item => {
          const isMatchDate = item.match(regexDate)
          const isMatchTime = item.match(regexTime)
          const isMatchDateTime = item.match(regexDateTime)

          if (isMatchDate || isMatchTime || isMatchDateTime) {
            return parseTimeNotification(item)
          }

          return item
        })

        return formattedMessageArr.join(' ')
      }

      return notificationData.message.replace(
        '{{name}}',
        `${content.firstName} ${content.lastName}`
      )
    },
    []
  )

  const generateContent = () => {
    let content: any = {}
    if (data.data) {
      content = JSON.parse(data.data)
    }

    return (
      <>
        <Box textAlign='right' fontSize='0.75rem'>
          {parseTimeDistance(data.createdAt)}
        </Box>
        <Grid gridTemplateColumns='2fr 9fr'>
          <Flex alignItems='center'>
            <Avatar src={content.avatar || defaultAvatar} size='md' />
          </Flex>
          <Flex justifyContent='space-between' alignItems='center'>
            <Box flex='5' mr='0.5rem'>
              <Box
                as='h2'
                mb='0.5rem'
                wordBreak='break-word'
                noOfLines={3}
                title={data.title}
              >
                {data.title || 'Connection Request'}
              </Box>
              <Text fontSize='0.75rem' wordBreak='break-word'>
                {generateMessage(data, content)}
              </Text>
            </Box>
            {/* {data.type === 'connection' && ( */}
            {renderButtonAccept(data)}
            {/* )} */}
          </Flex>
        </Grid>
      </>
    )
  }

  return (
    <Box
      p='0.6rem 1.5rem 1rem'
      borderTop='1px solid #EBEBEB'
      color='#000'
      borderLeft='3px solid'
      borderLeftColor={data.isRead ? 'transparent' : 'primary'}
      onClick={onClickItem(data)}
    >
      {generateContent()}
    </Box>
  )
}

export default NotificationItem

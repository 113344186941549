import React from 'react'
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from 'react-router-dom'

import { Link, LinkProps } from '@chakra-ui/react'

interface CustomLinkProps extends RouterLinkProps, Omit<LinkProps, 'color'> {}

const CustomLink: React.FC<CustomLinkProps> = ({ children, ...linkProps }) => {
  return (
    <Link
      as={RouterLink}
      _hover={{
        color: 'primaryDarker',
      }}
      _focus={{
        outline: 'none',
      }}
      {...linkProps}
    >
      {children}
    </Link>
  )
}

export default CustomLink

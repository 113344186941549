import { lazy } from 'react'

const CenterAdminProfile = lazy(
  () => import(/* webpackChunkName: "center-admin-profile" */ './MyProfile')
)
const CenterProfile = lazy(
  () =>
    import(/* webpackChunkName: "center-profile" */ './CentreProfile/Preview')
)
const UserManagement = lazy(
  () =>
    import(/* webpackChunkName: "center-user-management" */ './UserManagement')
)
const CreateLearner = lazy(
  () =>
    import(
      /* webpackChunkName: "create-learner-user" */ './UserManagement/Learner/CreateLearner'
    )
)
const EditLearner = lazy(
  () =>
    import(
      /* webpackChunkName: "edit-learner-user" */ './UserManagement/Learner/EditLearner'
    )
)
const CreateInstructor = lazy(
  () =>
    import(
      /* webpackChunkName: "create-instructor-user" */ './UserManagement/Instructor/CreateInstructor'
    )
)
const EditInstructor = lazy(
  () =>
    import(
      /* webpackChunkName: "edit-instructor-user" */ './UserManagement/Instructor/EditInstructor'
    )
)
const EditCentreAdmin = lazy(
  () =>
    import(
      /* webpackChunkName: "edit-instructor-user" */ './UserManagement/CentreAdmin/EditCentreAdmin'
    )
)
const ViewCenterAdmin = lazy(
  () =>
    import(
      /* webpackChunkName: "edit-instructor-user" */ './UserManagement/CentreAdmin/ViewCenterAdmin'
    )
)
const SessionManagement = lazy(
  () =>
    import(/* webpackChunkName: "session-management" */ './SessionManagement')
)
const CreateSessionLanding = lazy(
  () =>
    import(
      /* webpackChunkName: "create-session-landing" */ './SessionManagement/Create'
    )
)
const CreateClass = lazy(
  () =>
    import(
      /* webpackChunkName: "create-session" */ './SessionManagement/Create/Class'
    )
)
const CreateContent = lazy(
  () =>
    import(
      /* webpackChunkName: "create-content" */ './SessionManagement/Create/Content'
    )
)
const CreateLesson = lazy(
  () =>
    import(
      /* webpackChunkName: "create-lesson" */ './SessionManagement/Create/Lesson'
    )
)
const CenterEditClass = lazy(
  () =>
    import(
      /* webpackChunkName: "center-edit-class" */ './SessionManagement/Edit/Class'
    )
)
const CenterEditContent = lazy(
  () =>
    import(
      /* webpackChunkName: "center-edit-content" */ './SessionManagement/Edit/Content'
    )
)
const CenterPreviewClass = lazy(
  () =>
    import(
      /* webpackChunkName: "center-preview-class" */ './SessionManagement/Preview/Class'
    )
)
const CenterPreviewContent = lazy(
  () =>
    import(
      /* webpackChunkName: "center-preview-content" */ './SessionManagement/Preview/Content'
    )
)
const CenterPreviewLesson = lazy(
  () =>
    import(
      /* webpackChunkName: "center-preview-lesson" */ './SessionManagement/Preview/Lesson'
    )
)

const Wallet = lazy(() => import(/* webpackChunkName: "wallet" */ './Wallet'))
const ViewMyTransaction = lazy(
  () =>
    import(
      /* webpackChunkName: "view-my-transaction" */ './Wallet/PreviewTransaction'
    )
)
const TransactionApproval = lazy(
  () =>
    import(
      /* webpackChunkName: "transaction-approval-management" */ './TransactionApproval'
    )
)
const Notifications = lazy(
  () =>
    import(
      /* webpackChunkName: "center-admin-notifications" */ './Notifications'
    )
)

const EditCenterProfile = lazy(
  () =>
    import(/* webpackChunkName: "edit-center-profile" */ './CentreProfile/Edit')
)

export {
  CenterAdminProfile,
  UserManagement,
  CreateLearner,
  EditLearner,
  CreateInstructor,
  EditInstructor,
  EditCentreAdmin,
  ViewCenterAdmin,
  SessionManagement,
  CreateSessionLanding,
  CreateClass,
  CreateContent,
  CreateLesson,
  Wallet,
  ViewMyTransaction,
  TransactionApproval,
  Notifications,
  CenterEditClass,
  CenterEditContent,
  CenterPreviewClass,
  CenterPreviewContent,
  CenterPreviewLesson,
  CenterProfile,
  EditCenterProfile,
}

import React, { useState } from 'react'

import { Box, Button, Grid, Text } from '@chakra-ui/react'
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js'
import { StripeCardNumberElement } from '@stripe/stripe-js'

import { Input, Modal, ModalProps } from 'components'
import { useLanguages, useUserId } from 'hooks'
import { useAddUserCard } from 'queries/wallet'
import { UserAddCardType } from 'types'

interface AddCardModalProps extends Omit<ModalProps, 'children'> {
  type: UserAddCardType
  enterpriseId?: string
}

const ELEMENT_OPTION_STYLE = {
  style: {
    base: {
      fontSize: '12px',
      color: '#000',
      fontWeight: '300',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    invalid: {
      color: '#9e2146',
    },
  },
}

const AddCardModal: React.FC<AddCardModalProps> = ({
  isOpen,
  onClose,
  type,
  enterpriseId,
}) => {
  const { _t } = useLanguages()
  const [name, setName] = useState<string>('')
  const [errorMessage, setErrorMessage] = useState<string | undefined | null>(
    null
  )
  const elements = useElements()
  const stripe = useStripe()
  const userId = useUserId()

  const { mutate: addCard, isLoading } = useAddUserCard({
    type: type === 'enterprise' ? type : 'user',
    id: type === 'enterprise' && enterpriseId ? enterpriseId : userId,
  })

  const handleAddCard = async () => {
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return
    }

    const cardElement = elements.getElement(
      CardNumberElement
    ) as StripeCardNumberElement

    const payload = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
      billing_details: {
        name,
      },
      metadata: {
        userId,
      },
    })

    if (payload.error) {
      console.log('[error]', payload.error)
      setErrorMessage(payload.error.message)
    } else {
      console.log('[PaymentMethod]', payload.paymentMethod)
      const paymentMethodId = payload.paymentMethod.id
      addCard({
        id: paymentMethodId,
        type,
        ...(type === 'enterprise' && { enterpriseId }),
      })
      setErrorMessage(null)
      onClose()
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size='xl'>
      <Box textStyle='headingModalText' textAlign='center' mb='1rem'>
        {_t('wallet.card.add')}
      </Box>
      <Text fontWeight='600' my='1rem'>
        {_t('wallet.card.details')}
      </Text>

      <Grid gridTemplateColumns='1fr 1fr' columnGap='3rem'>
        <Box>
          <Text fontSize='0.875rem' fontWeight='600' mb='0.25rem'>
            {_t('wallet.card.number')}
          </Text>
          <CardNumberElement options={ELEMENT_OPTION_STYLE} />
        </Box>
        <Box>
          <Input
            value={name}
            onChange={e => setName(e.target.value)}
            label={_t('wallet.card.name')}
            placeholder='Name'
            fontSize='0.75rem'
          />
        </Box>
        <Grid gridTemplateColumns='1fr 1fr' columnGap='1.5rem' mt='1.5rem'>
          <Box>
            <Text fontSize='0.875rem' fontWeight='600'>
              {_t('wallet.card.expiry')}
            </Text>
            <CardExpiryElement options={ELEMENT_OPTION_STYLE} />
          </Box>

          <Box>
            <Text fontSize='0.875rem' fontWeight='600'>
              {_t('wallet.card.cvc')}
            </Text>
            <CardCvcElement options={ELEMENT_OPTION_STYLE} />
          </Box>
        </Grid>
      </Grid>

      <Box mt='1rem'>
        {errorMessage && <Text color='error'>{errorMessage}</Text>}
      </Box>

      <Grid columnGap='0.5rem' gridTemplateColumns='1fr 1fr' mt='1.5rem'>
        <Button
          variant='solidPrimary'
          onClick={handleAddCard}
          textTransform='uppercase'
          isLoading={isLoading}
        >
          {_t('common.add')}
        </Button>
        <Button
          variant='transparent'
          textTransform='uppercase'
          onClick={onClose}
        >
          {_t('common.cancel')}
        </Button>
      </Grid>
    </Modal>
  )
}

export default AddCardModal

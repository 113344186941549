import React, { useMemo } from 'react'

import { Box, Divider, Flex, Text } from '@chakra-ui/react'

import { useLanguages } from 'hooks'
import { UserWalletInfo } from 'types'
import { formatAmount } from 'utils/parser'

interface BankInfoSectionProps {
  data: UserWalletInfo
  isEdit?: boolean
}

const BankInfoSection: React.FC<BankInfoSectionProps> = ({ data, isEdit }) => {
  const { _t } = useLanguages()

  const availableWithdrawBalance = useMemo(
    () =>
      (data.wallet?.availableBalance || 0) + (data.wallet?.topUpBalance || 0),
    [data]
  )

  return (
    <>
      <Flex direction={{ base: 'column', md: 'row' }} justify='space-between'>
        <Box flex={1}>
          <Box mb='1rem'>
            <Text textStyle='detailTitle' color='gray.300' mb='0.25rem'>
              {_t('user.bank_account.name')}
            </Text>
            <Text>{data?.bankAccountName || '-'}</Text>
          </Box>
          <Box mb='1rem'>
            <Text textStyle='detailTitle' color='gray.300' mb='0.25rem'>
              {_t('user.bank_account.bank_name')}
            </Text>
            <Text>{data?.bankName || '-'}</Text>
          </Box>
        </Box>
        <Box flex={1}>
          <Box mb='1rem'>
            <Text textStyle='detailTitle' color='gray.300' mb='0.25rem'>
              {_t('user.bank_account.number')}
            </Text>
            <Text>{data?.bankAccountNumber || '-'}</Text>
          </Box>
          <Box mb='1rem'>
            <Text textStyle='detailTitle' color='gray.300' mb='0.25rem'>
              {_t('user.bank_account.country')}
            </Text>
            <Text>{data?.country || '-'}</Text>
          </Box>
        </Box>
      </Flex>
      <Box mb='1rem'>
        <Text textStyle='detailTitle' color='gray.300' mb='0.25rem'>
          {_t('user.bank_account.remarks')}
        </Text>
        <Text whiteSpace='pre-wrap'>{data?.bankRemarks || '-'}</Text>
      </Box>
      {!isEdit && (
        <Box mb='1rem'>
          <Text textStyle='detailTitle' color='gray.300' mb='0.25rem'>
            {_t('transaction.detail.available_balance')}
          </Text>
          <Text>{`$${formatAmount(availableWithdrawBalance / 100)}`}</Text>
        </Box>
      )}
      <Divider my='1rem' />
    </>
  )
}

export default BankInfoSection

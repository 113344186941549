import React, { useMemo } from 'react'

import { Avatar, Box, Flex, Grid, GridItem, Text } from '@chakra-ui/react'
import { useAtom } from 'jotai'

import { HeadingSection, ImageWrapper } from 'components'
import storageKey from 'configs/storageKey'
import { useLanguages, useUserId } from 'hooks'
import { ConnectionButton } from 'shared/containers'
import { userProfileAtom } from 'store/authAtom'
import { Centre } from 'types'
import { responsivePadding } from 'utils/constant'
import { getData, isInstructor, isLearner } from 'utils/helper'

import defaultBanner from 'assets/images/default_centre_banner.jpg'

interface CentreHeaderProps {
  data: Centre
  tabData: any[]
  currentTab: string
}

const CentreHeader: React.FC<CentreHeaderProps> = ({
  data,
  tabData,
  currentTab,
}) => {
  const { _t } = useLanguages()
  const userId = useUserId()
  const [userProfile] = useAtom(userProfileAtom)

  const isGuest = getData(storageKey.loginType) === 'guest'
  const isLearnerMemo = useMemo(() => isLearner(userProfile), [userProfile])
  const isInstructorMemo = useMemo(() => isInstructor(userProfile), [
    userProfile,
  ])

  return (
    <Box as='section' backgroundColor='secondaryLightBlue'>
      <ImageWrapper
        h='auto'
        maxH='14rem'
        w='full'
        objectFit='cover'
        src={data?.banner}
        defaultSrc={defaultBanner}
      />
      <Grid
        templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(7, 1fr)' }}
        px={responsivePadding}
      >
        <GridItem colSpan={1}>
          <Flex mt={{ base: -30, md: -15 }} justify='center'>
            <Avatar
              size='2xl'
              src={data?.avatar}
              name={data?.name || ''}
              alt='User avatar'
              objectFit='cover'
            />
          </Flex>
        </GridItem>
        <GridItem
          colSpan={{ base: 1, md: 5 }}
          mt='1rem'
          textAlign={{ base: 'center', md: 'left' }}
          ml={{ base: 0, md: '1rem' }}
        >
          <Text fontSize='0.85rem' fontWeight='600' textTransform='uppercase'>
            {_t('common.centre')}
          </Text>
          <Text as='h1'>{data?.name || ''}</Text>
          {!isGuest && (
            <Flex direction='column'>
              <Text>{_t('centre.profile.session_enrolled')}</Text>
              {data?.sessionsEnrolled ? data.sessionsEnrolled : 0}
            </Flex>
          )}
        </GridItem>
        <GridItem colSpan={1} mt='1rem'>
          <Flex justifyContent='center'>
            {(isLearnerMemo || isInstructorMemo) &&
              data.id &&
              data.id !== userProfile?.centers?.[0].id && (
                <ConnectionButton userId={userId} friendId={data.id} />
              )}
          </Flex>
        </GridItem>
      </Grid>

      <HeadingSection
        title=''
        tabData={tabData}
        currentTab={currentTab}
        widthType='narrow'
      />
    </Box>
  )
}

export default CentreHeader

import React from 'react'

import { Text } from '@chakra-ui/react'

import { useLanguages } from 'hooks'
import { PaymentType } from 'types'

interface PaymentTypeTextProps {
  type?: PaymentType
}

const PaymentTypeText: React.FC<PaymentTypeTextProps> = ({ type }) => {
  const { _t } = useLanguages()

  switch (type) {
    case PaymentType.CARD:
      return <Text>{_t('transaction.payment_type.card')}</Text>
    case PaymentType.CREDIT:
      return <Text>{_t('transaction.payment_type.credit')}</Text>
    case PaymentType.GCASH:
      return <Text>{_t('transaction.payment_type.gcash')}</Text>
    case PaymentType.BANK:
      return <Text>{_t('transaction.payment_type.bank')}</Text>
    case PaymentType.CHEQUE:
      return <Text>{_t('transaction.payment_type.cheque')}</Text>
    case PaymentType.CASH:
      return <Text>{_t('transaction.payment_type.cash')}</Text>
    default:
      return <Text>Unknown</Text>
  }
}

export default PaymentTypeText

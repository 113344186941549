import React from 'react'

import { Box, BoxProps, Flex, FlexProps } from '@chakra-ui/react'

export const Dot = (props: BoxProps) => (
  <Box
    w='0.25rem'
    h='0.25rem'
    borderRadius='50%'
    bgColor='primary'
    {...props}
  />
)

const ActionDot = (props: FlexProps) => {
  return (
    <Flex
      alignItems='center'
      justifyContent='center'
      w='2rem'
      h='2rem'
      borderRadius='1rem'
      boxShadow='0px 16px 24px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)'
      cursor='pointer'
      {...props}
    >
      <Dot />
      <Dot mx='0.25rem' />
      <Dot />
    </Flex>
  )
}

export default ActionDot

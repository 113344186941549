import React, { useMemo, useState } from 'react'

import { Box, Checkbox, Flex, Grid, Text } from '@chakra-ui/react'
import startCase from 'lodash.startcase'

import { Permission } from 'types'

import { ReactComponent as ArrowDown } from 'assets/images/arrow_down.svg'
import { ReactComponent as ArrowUp } from 'assets/images/arrow_up.svg'

interface PermissionGroupProps {
  groupName: string
  data?: Permission[]
  onChange: (data: string[]) => void
  selectedPermissions?: string[]
}

const PermissionGroup = ({
  groupName,
  data = [],
  onChange,
  selectedPermissions = [],
}: PermissionGroupProps) => {
  const [show, setShow] = useState(false)
  const isSelectAll = useMemo(() => {
    if (!selectedPermissions || !data.length) {
      return false
    }

    return selectedPermissions.length === data.length
  }, [selectedPermissions, data])

  const toggleSelectAll = () => {
    if (isSelectAll) {
      return onChange([])
    }
    const allList = data.map(per => per.id)

    onChange(allList)
  }

  const changeSelectPermission = (id: string) => () => {
    if (selectedPermissions?.includes(id)) {
      return onChange(selectedPermissions?.filter(option => option !== id))
    }

    onChange([...(selectedPermissions || []), id])
  }

  return (
    <Box py='1rem'>
      <Flex
        onClick={() => setShow(!show)}
        cursor='pointer'
        alignItems='center'
        justifyContent='space-between'
        pr='1rem'
      >
        <Flex alignItems='center'>
          <Checkbox
            mr='0.5rem'
            onChange={toggleSelectAll}
            isChecked={isSelectAll}
          ></Checkbox>
          <Text color='primary' fontSize='1.125rem' fontWeight='600'>
            {startCase(groupName)}
          </Text>
        </Flex>
        {show ? <ArrowUp /> : <ArrowDown />}
      </Flex>

      <Grid gridTemplateColumns='1fr' gridColumnGap='3rem'>
        {show &&
          data.map(permission => {
            const isChecked =
              selectedPermissions?.includes(permission.id) || false

            return (
              <Checkbox
                mr='0.5rem'
                key={permission.id}
                mt='0.5rem'
                onChange={changeSelectPermission(permission.id)}
                isChecked={isChecked}
              >
                <Box fontSize='0.75rem'>{permission.name}</Box>
              </Checkbox>
            )
          })}
      </Grid>
    </Box>
  )
}

export default PermissionGroup

import React, { useEffect } from 'react'
import { Calendar, dateFnsLocalizer } from 'react-big-calendar'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import { useNavigate } from 'react-router-dom'

import { Box, Text } from '@chakra-ui/react'
import endOfWeek from 'date-fns/endOfWeek'
import format from 'date-fns/format'
import getDay from 'date-fns/getDay'
import parse from 'date-fns/parse'
import startOfWeek from 'date-fns/startOfWeek'

import { Period } from 'components/Calendar'
import { ClassColor, ClassType } from 'types'
import { isPackageClassType } from 'utils/helper'

import './calendar.css'

const locales = {
  'en-US': require('date-fns/locale/en-US'),
}

interface FullCalendarProps {
  onChangePeriod?: (newPeriod: Period) => void
  events?: Event[]
}

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek: (date: any, options: any) => {
    return startOfWeek(date, { ...options, weekStartsOn: 1 })
  },
  getDay,
  locales,
})

export interface Event {
  id: number
  title?: string
  description?: string
  color?: string
  type?: string
  start: Date
  end: Date
}

const EventBox = ({ event }: { event: Event }) => {
  return (
    <Box
      backgroundColor={event.color || 'primary'}
      padding='0.25rem 0.625rem'
      width='100%'
      height='100%'
      borderRadius='0.25rem'
    >
      <Text fontSize='0.625rem' fontWeight='700' mb='0.25rem' isTruncated>
        {event.title}
      </Text>
      <Text fontSize='0.5rem' fontWeight='400' isTruncated>
        {event.description}
      </Text>
    </Box>
  )
}

const FullCalendar = ({ onChangePeriod, events = [] }: FullCalendarProps) => {
  const navigate = useNavigate()
  const today = new Date()
  const handleNavigate = (test: any) => {
    const date = new Date(test)

    onChangePeriod?.({
      from: startOfWeek(date, {
        weekStartsOn: 1,
      }),
      to: endOfWeek(date, {
        weekStartsOn: 1,
      }),
    })
  }
  useEffect(() => {
    const date = new Date()

    onChangePeriod?.({
      from: startOfWeek(date, {
        weekStartsOn: 1,
      }),
      to: endOfWeek(date, {
        weekStartsOn: 1,
      }),
    })
  }, [onChangePeriod])

  return (
    <Box height='80vh' width='100%' backgroundColor='white' color='black'>
      <Calendar
        views={['week']}
        events={events.map(event => ({
          ...event,
          color: isPackageClassType(event?.type)
            ? ClassColor.PACKAGE_COLOR
            : ClassColor.PRIVATE_COLOR,
        }))}
        step={60}
        timeslots={1}
        min={
          new Date(today.getFullYear(), today.getMonth(), today.getDate(), 6)
        }
        defaultDate={new Date()}
        localizer={localizer}
        defaultView='week'
        components={{
          event: EventBox,
        }}
        onSelectEvent={event => {
          navigate(`/product/class/${event.id}`)
        }}
        onNavigate={handleNavigate}
      />
    </Box>
  )
}

export default FullCalendar

import React from 'react'
import { Link } from 'react-router-dom'

import { Avatar, Box, Flex, Text } from '@chakra-ui/react'

import { Rating } from 'components'
import { useLanguages } from 'hooks'
import { UserProfile } from 'types'
import { getYoutubeUrl } from 'utils/parser'

interface InstructorSectionProps {
  instructor: UserProfile | undefined
}

const InstructorSection: React.FC<InstructorSectionProps> = ({
  instructor,
}) => {
  const { _t } = useLanguages()
  return (
    <Box my='2rem'>
      <Text as='h2' mb='1rem'>
        {_t('product.class.instructor_title')}
      </Text>
      <Flex justifyContent='space-between' alignItems='center' mb='1rem'>
        <Flex flex='1' alignItems='center' position='relative'>
          <Avatar
            size='lg'
            name={`${instructor?.firstName} ${instructor?.lastName}`}
            src={instructor?.avatar}
            key={instructor?.avatar || 'default-avatar'}
          />
          <Box ml='1rem'>
            {instructor?.rating && <Rating rate={instructor?.rating} />}
            <Box
              as={Link}
              to={`/instructors/${instructor?.id}`}
              _hover={{
                color: 'primary',
              }}
              _before={{
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                cursor: 'pointer',
              }}
              noOfLines={1}
            >
              {`${instructor?.firstName} ${instructor?.lastName}`}
            </Box>
            {instructor?.centers &&
              instructor?.centers.map((centre: any, index: number) => (
                <Text
                  key={`center-${index}`}
                  fontSize='0.75rem'
                  color='grey.500'
                >
                  {index > 0 && ', '}
                  {centre.name}
                </Text>
              ))}
          </Box>
        </Flex>
        {/* <Box w='10rem'>
          <UserConnectionButton
            friendId={instructor?.id || ''}
            // connection={instructor?.connection}
            handleView={handleView}
          />
        </Box> */}
      </Flex>
      <Text fontSize='0.75rem' whiteSpace='pre-wrap' mb='1rem'>
        {instructor?.description}
      </Text>
      {instructor?.youtubeLink && (
        <Box
          as='iframe'
          src={getYoutubeUrl(instructor.youtubeLink || '')}
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
          allowFullScreen
          w={{ base: 'full', md: '30rem' }}
          h='16.875rem'
          mb='1rem'
          borderRadius='0.25rem'
        />
      )}
    </Box>
  )
}

export default InstructorSection

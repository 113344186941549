import React, { lazy, useCallback, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Box, SimpleGrid, useDisclosure } from '@chakra-ui/react'

import { useQueryParams } from 'hooks'
import { CreateCard } from 'shared/containers'
import { TutorAssignment } from 'types'

const AssignmentModal = lazy(
  () =>
    import(
      'containers/Instructor/MyCourses/Assignment/CreateAssignmentModal'
    )
)
const AssignmentCreatedSuccessModal = lazy(
  () =>
    import(
      'containers/Instructor/MyCourses/Assignment/AssignmentCreatedSuccessModal'
    )
)

type TabId = 'create' | 'drafts'

type ProductType = {
  name: string
  description: string
  shortDescriptions: string[]
}

interface CreateProductsContainerProps {
  product: ProductType[]
}

const CreateProductsContainer: React.FC<CreateProductsContainerProps> = ({
  product,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const {
    isOpen: isSuccessModalOpen,
    onOpen: successModalOpen,
    onClose: closeSuccessModal,
  } = useDisclosure()

  const [createdAssignment, setCreatedAssignment] = useState<
    Partial<TutorAssignment>
  >()
  const [uploadedFilesError, setUploadedFilesError] = useState<string[]>()

  const createdSuccessCallback = useCallback(
    (createdItem: Partial<TutorAssignment>, fileErrors?: string[]) => {
      setCreatedAssignment(createdItem)
      setUploadedFilesError(fileErrors)
      successModalOpen()
    },
    [successModalOpen]
  )

  const navigate = useNavigate()

  const handleCreateClass = (type: string) => {
    navigate(`/create/${type.toLowerCase()}`)
  }

  // ** Hooks
  const query = useQueryParams()

  const currentTab = useMemo<TabId>(() => (query.tab || 'create') as TabId, [
    query,
  ])

  return (
    <Box>
      {currentTab === 'create' && (
        <SimpleGrid
          columns={[1, 1, 2, 2]}
          columnGap={['0rem', '1rem', '2rem']}
          px={['0rem', '0rem', '0rem', '5rem']}
          pb='2rem'
        >
          {Object.entries(product).map(([key, value]) => {
            return (
              <CreateCard
                key={key}
                title={value.name}
                description={value.description}
                listItems={value.shortDescriptions}
                onClick={
                  value.name === 'Assignment'
                    ? onOpen
                    : () => handleCreateClass(value.name)
                }
              />
            )
          })}
        </SimpleGrid>
      )}
      {isOpen && (
        <AssignmentModal
          isOpen={isOpen}
          onClose={onClose}
          onCreatedSuccess={createdSuccessCallback}
        />
      )}
      {isSuccessModalOpen && (
        <AssignmentCreatedSuccessModal
          isOpen={isSuccessModalOpen}
          onClose={closeSuccessModal}
          createdAssignmentTitle={createdAssignment?.name as string}
          uploadedFilesError={uploadedFilesError}
        />
      )}
    </Box>
  )
}

export default CreateProductsContainer

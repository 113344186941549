import React, { useCallback, useMemo, useState } from 'react'

import { Box, Flex, useDisclosure } from '@chakra-ui/react'

import BankAccountInfo from '../Wallet/OverviewTab/BankAccountInfo'
import CreditBalance from '../Wallet/OverviewTab/CreditBalance'
import MyCard from '../Wallet/OverviewTab/MyCard'
import TransactionDataTable from '../Wallet/OverviewTab/TransactionDataTable'
import DataTableHeader from './containers/DataTableHeader'
import FilterModal from './containers/FilterModal'
import { FilterForm, searchOptions } from './form'
import {
  ExportModal,
  HeadingSection,
  LoadingSpinner,
  Option,
  Paginate,
} from 'components'
import {
  useCenterId,
  useCurrentPage,
  useLanguages,
  useLoading,
  useToast,
  useUserId,
  useUtilsForEnterpriseId,
} from 'hooks'
import { useTransactions } from 'queries/transaction'
import { ApiUserType, UserRole, UserRoleType, UserWalletInfo } from 'types'
import { getErrorMessage } from 'utils/parser'

const limit = 10

interface WalletManagementProps {
  role: UserRoleType
  data?: UserWalletInfo
  isLoading: boolean
  handleView: (id: string) => string
}

const WalletManagement: React.FC<WalletManagementProps> = ({
  role,
  data,
  isLoading,
  handleView,
}: WalletManagementProps) => {
  const { _t } = useLanguages()
  const { loading, setLoading } = useLoading()
  const toast = useToast()
  const userId = useUserId()
  const centerId = useCenterId()
  const { enterpriseId } = useUtilsForEnterpriseId()
  const currentPage = useCurrentPage()
  const [search, setSearch] = useState<string>('')
  const [searchBy, setSearchBy] = useState<Option>(searchOptions[0])
  const [orderBy, setOrderBy] = useState<string>('createdAt')
  const [filters, setFilters] = useState<FilterForm>({})
  const {
    isOpen: isOpenFilter,
    onOpen: onOpenFilter,
    onClose: onCloseFilter,
  } = useDisclosure()
  const {
    isOpen: isOpenExport,
    onOpen: onOpenExport,
    onClose: onCloseExport,
  } = useDisclosure()

  const type = useMemo(() => {
    switch (role) {
      case UserRole.MSTAdmin:
      case UserRole.MSTSuperAdmin:
        return { user: 'admin', id: userId }
      case UserRole.EnterpriseAdmin:
      case UserRole.EnterpriseSuperAdmin:
        return { user: 'enterprise', id: enterpriseId }
      case UserRole.CenterAdmin:
        return { user: 'center', id: centerId }
      default:
        return { user: 'user', id: userId }
    }
  }, [role, userId, enterpriseId, centerId])

  const {
    data: transactions,
    isLoading: isTransactionLoading,
  } = useTransactions(
    {
      type: type.user as ApiUserType,
      id: type.id,
    },
    {
      page: currentPage,
      limit,
      order: 'desc',
      orderBy,
      ...(search && { search }),
      ...(search && {
        searchBy: searchBy.value !== 'all' ? searchBy.value : undefined,
      }),
      ...filters,
    }
  )

  const handleSearch = useCallback((value: string) => {
    setSearch(value)
  }, [])
  const handleSearchBy = useCallback((value: Option) => {
    setSearchBy(value)
  }, [])
  const handleSort = useCallback((sortBy: string, onCloseSort: () => void) => {
    setOrderBy(sortBy)
    onCloseSort()
  }, [])
  const handleFilter = useCallback((options: FilterForm) => {
    setFilters(options)
  }, [])

  const handleExport = useCallback(
    async ([startDate, endDate]: [Date, Date]) => {
      try {
        setLoading(true)
        //   const formattedData = {
        //     type: isMSTSuperAdmin ? '' : 'enterprise',
        //     fileType: 'xlsx',
        //     startDateTime: startDate.getTime(),
        //     endDateTime: endDate.getTime(),
        //     ...(!isMSTSuperAdmin && { enterpriseId }),
        //   } as ExportWalletData
        //   const res = await exportWalletData(formattedData)
        //   if (res) {
        //     onCloseExport()
        //     window.open(res.data.data.url)
        //   }
      } catch (error) {
        toast({
          title: 'Error!',
          description: _t(`message.error.${getErrorMessage(error)}`),
          status: 'error',
        })
      } finally {
        setLoading(false)
      }
    },
    [_t, setLoading, toast]
  )

  return (
    <Box>
      <HeadingSection
        title={_t('wallet.management.title')}
        description={_t('wallet.management.description')}
        isAdminStyle
      />
      <Box p='2rem 5rem 0'>
        {isLoading && <LoadingSpinner />}
        {data && (
          <Flex
            direction={{ base: 'column', md: 'row' }}
            justify='space-between'
            mb='2rem'
            flexWrap='wrap'
          >
            <CreditBalance role={role} walletInfo={data} />
            {[
              UserRole.MSTAdmin,
              UserRole.MSTSuperAdmin,
              UserRole.CenterAdmin,
            ].includes(role) && (
              <BankAccountInfo role={role} walletInfo={data} />
            )}
            {[UserRole.EnterpriseAdmin, UserRole.EnterpriseSuperAdmin].includes(
              role
            ) && <MyCard role={role} walletInfo={data} />}
          </Flex>
        )}
        <Box p='1rem 0 0' width='100%'>
          <DataTableHeader
            count={transactions?.meta.total || 0}
            orderBy={orderBy}
            handleOpenFilter={onOpenFilter}
            handleSearch={handleSearch}
            handleSearchBy={handleSearchBy}
            handleSort={handleSort}
          />
          <Box mt='1.5rem'>
            <TransactionDataTable
              role={role}
              data={transactions?.data || []}
              isLoading={isTransactionLoading}
              handleView={handleView}
            />
          </Box>
          <Flex justifyContent='center' my='1.5rem'>
            {!!transactions?.meta.total && !isTransactionLoading && (
              <Paginate
                currentPage={currentPage}
                perPage={limit}
                totalItems={transactions?.meta.total || 0}
              />
            )}
          </Flex>
        </Box>
      </Box>
      <FilterModal
        isOpen={isOpenFilter}
        onClose={onCloseFilter}
        onSubmit={handleFilter}
      />
      <ExportModal
        isOpen={isOpenExport}
        onClose={onCloseExport}
        onSubmit={handleExport}
        loading={loading}
      />
    </Box>
  )
}

export default WalletManagement

import React, { useCallback, useState } from 'react'

import { Box, Flex, useDisclosure } from '@chakra-ui/react'

import DataTableHeader from './containers/DataTableHeader'
import FilterModal from './containers/FilterModal'
import { FilterForm, searchOptions } from './form'
import TransactionDataTable from './TransactionDataTable'
import { HeadingSection, Option, Paginate } from 'components'
import { useCurrentPage } from 'hooks'
import { useAdminTransactions } from 'queries/transaction'

const limit = 10

interface TransactionManagementProps {
  title: string
  description: string
  defaultFilters?: FilterForm
  isWithdrawal?: boolean
  handleView: (id: string) => string
  handleUpdateWithdrawal?: (id: string) => void
}

const TransactionManagement: React.FC<TransactionManagementProps> = ({
  title,
  description,
  defaultFilters = {},
  isWithdrawal = false,
  handleView,
  handleUpdateWithdrawal,
}: TransactionManagementProps) => {
  const currentPage = useCurrentPage()
  const [search, setSearch] = useState<string>('')
  const [searchBy, setSearchBy] = useState<Option>(searchOptions[0])
  const [orderBy, setOrderBy] = useState<string>('createdAt')
  const [filters, setFilters] = useState<FilterForm>(defaultFilters)
  const {
    isOpen: isOpenFilter,
    onOpen: onOpenFilter,
    onClose: onCloseFilter,
  } = useDisclosure()

  const {
    data: transactions,
    isLoading: isTransactionLoading,
  } = useAdminTransactions({
    page: currentPage,
    limit,
    order: 'desc',
    orderBy,
    ...(search && { search }),
    ...(search && {
      searchBy: searchBy.value !== 'all' ? searchBy.value : undefined,
    }),
    ...filters,
  })

  const handleSearch = useCallback((value: string) => {
    setSearch(value)
  }, [])
  const handleSearchBy = useCallback((value: Option) => {
    setSearchBy(value)
  }, [])
  const handleSort = useCallback((sortBy: string, onCloseSort: () => void) => {
    setOrderBy(sortBy)
    onCloseSort()
  }, [])
  const handleFilter = useCallback(
    (options: FilterForm) => {
      setFilters({ ...options, ...defaultFilters })
    },
    [defaultFilters]
  )

  return (
    <Box>
      <HeadingSection title={title} description={description} isAdminStyle />
      <Box p='2rem 5rem 0'>
        <Box p='1rem 0 0' width='100%'>
          <DataTableHeader
            filters={filters}
            count={transactions?.meta.total || 0}
            orderBy={orderBy}
            handleOpenFilter={onOpenFilter}
            handleSearch={handleSearch}
            handleSearchBy={handleSearchBy}
            handleSort={handleSort}
          />
          <Box mt='1.5rem'>
            <TransactionDataTable
              data={transactions?.data || []}
              isLoading={isTransactionLoading}
              isWithdrawal={isWithdrawal}
              handleView={handleView}
              handleUpdateWithdrawal={handleUpdateWithdrawal}
            />
          </Box>
          <Flex justifyContent='center' my='1.5rem'>
            {!!transactions?.meta.total && !isTransactionLoading && (
              <Paginate
                currentPage={currentPage}
                perPage={limit}
                totalItems={transactions?.meta.total || 0}
              />
            )}
          </Flex>
        </Box>
      </Box>
      <FilterModal
        isOpen={isOpenFilter}
        onClose={onCloseFilter}
        onSubmit={handleFilter}
        isFilterType={!defaultFilters?.type}
      />
    </Box>
  )
}

export default TransactionManagement

import { useMutation, useQuery } from 'react-query'

import { useToastError } from './helpers'
import { createReview } from 'apis/review'
import { reviewPath } from 'configs/apiPath'
import { useLanguages, useLoading, useToast } from 'hooks'
import { CreatedReview, ListingParams, Meta } from 'types'

export type RequestQuery = {
  data: CreatedReview[]
} & Meta

export const useReviewList = (params: ListingParams) => {
  return useQuery<RequestQuery>(reviewPath.list(params))
}

export const useCreateReview = ({
  onCloseModal,
  onInvalidateUrl,
}: {
  onCloseModal?: () => void
  onInvalidateUrl?: () => void
}) => {
  const toast = useToast()
  const { setLoading } = useLoading()
  const { _t } = useLanguages()

  return useMutation(createReview, {
    onMutate: () => setLoading(true),
    onSettled: () => setLoading(false),
    onSuccess: async () => {
      onCloseModal && onCloseModal()
      onInvalidateUrl && onInvalidateUrl()
      toast({
        title: 'Success!',
        description: _t('message.success.review_add'),
        status: 'success',
      })
    },
    onError: useToastError(),
  })
}

import React, { useMemo } from 'react'

import {
  Box,
  Button,
  Flex,
  Icon,
  Image,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
} from '@chakra-ui/react'

import { ConfirmAlert, PopoverItem } from 'components'
import Link from 'components/Link'
import { useLanguages } from 'hooks'
import { responsivePadding } from 'utils/constant'

import ArrowBackIcon from 'assets/images/arrowBack_icon.svg'

type TabData = {
  id: string
  title: string
  defaultTitle?: string
  path: string
}

type PopoverItemData = {
  label: string
  icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
  onClick: () => void
}

export type ButtonData = {
  label: string
  isSecondary?: boolean
  isDisabled?: boolean
  style?: string
  showAlert?: boolean
  alertTitle?: string
  alertDescription?: string
  confirmLabel?: string
  closeOnConfirm?: boolean
  popoverItemData?: PopoverItemData[]
  onClick?: () => void
}

export type HeaderWidthType = 'admin' | 'wide' | 'narrow'

const getWidthParameters = (widthType: HeaderWidthType) => {
  switch (widthType) {
    case 'wide':
      return {
        px: { base: '1.5rem', lg: '6rem', md: '4rem' },
        maxWidth: '1600px',
      }
    case 'narrow':
      return { px: responsivePadding }
    case 'admin':
    default:
      return { px: '5rem' }
  }
}

interface HeadingSectionProps {
  title?: string
  backLabel?: string
  handleToBack?: () => void
  description?: string
  tabData?: TabData[]
  currentTab?: string
  buttonData?: ButtonData[]
  widthType?: HeaderWidthType
  isAdminStyle?: boolean
}

const HeadingSection: React.FC<HeadingSectionProps> = ({
  title,
  backLabel,
  handleToBack,
  description,
  tabData,
  currentTab,
  buttonData,
  widthType = 'admin',
  isAdminStyle = false,
}) => {
  const { _t } = useLanguages()

  const showBack = useMemo(() => !!(backLabel && handleToBack), [
    backLabel,
    handleToBack,
  ])

  const renderButton = (buttonData: ButtonData, index: number) => {
    let variant = buttonData?.style || 'primary'

    if (buttonData.isSecondary) {
      variant = 'transparent'
    }

    const button = (handleClick?: () => void) => (
      <Button
        minW='10rem'
        isDisabled={buttonData?.isDisabled}
        onClick={handleClick}
        textTransform='uppercase'
        variant={variant}
      >
        {buttonData.label}
      </Button>
    )

    if (buttonData?.popoverItemData) {
      return (
        <Popover key={`popover-${index}`}>
          <PopoverTrigger>{button()}</PopoverTrigger>
          <PopoverContent
            bgColor='primaryDarker'
            color='#fff'
            maxW='17rem'
            transform='translateX(1140px)'
            _focus={{
              outline: 0,
            }}
          >
            <PopoverBody p='0'>
              {buttonData.popoverItemData.map((popover, index) => (
                <PopoverItem
                  key={`popover-${index}-${index}`}
                  onClick={popover.onClick}
                >
                  <Icon as={popover.icon} w='1rem' h='1rem' />
                  <Box ml='1rem'>{popover.label}</Box>
                </PopoverItem>
              ))}
            </PopoverBody>
          </PopoverContent>
        </Popover>
      )
    }

    if (buttonData?.showAlert) {
      return (
        <ConfirmAlert
          onSubmit={buttonData.onClick}
          title={buttonData?.alertTitle}
          description={
            <Box textAlign='center'>
              <Text>{buttonData?.alertDescription}</Text>
            </Box>
          }
          submitTitle={buttonData?.confirmLabel || buttonData.label}
          immediatelyCloseOnSubmit={buttonData?.closeOnConfirm}
        >
          {({ showAlert }) => button(showAlert)}
        </ConfirmAlert>
      )
    }

    return button(buttonData.onClick || (() => {}))
  }

  return (
    <Flex bgColor='secondaryLightBlue' direction='column' alignItems='center'>
      <Box w='100%' {...getWidthParameters(widthType)}>
        <Flex direction='column' gridGap={isAdminStyle ? '0' : '1rem'}>
          {showBack && (
            <Flex
              as='button'
              align='center'
              onClick={handleToBack}
              mt='1rem'
              alignSelf='flex-start'
            >
              <Image src={ArrowBackIcon} d='inline' mr='0.5rem' />
              {backLabel}
            </Flex>
          )}
          {(title || description || buttonData) && (
            <Flex
              direction='row'
              alignItems='center'
              justifyContent='space-between'
              mt={showBack ? '1rem' : '2rem'}
              mb={tabData && currentTab ? '0' : '2rem'}
            >
              <Flex direction='column'>
                {title && (
                  <Box
                    as='h1'
                    textStyle={
                      isAdminStyle ? 'adminHeadingSection' : 'headingModalText'
                    }
                  >
                    {title}
                  </Box>
                )}
                {description && <Box fontSize='1rem'>{description}</Box>}
              </Flex>
              {buttonData && (
                <Flex direction='row' gridGap='1rem' zIndex={1}>
                  {buttonData.map((buttonData, index) =>
                    renderButton(buttonData, index)
                  )}
                </Flex>
              )}
            </Flex>
          )}
          {tabData && currentTab && (
            <Box as='ul' whiteSpace='nowrap' overflowX='auto' mt='1rem'>
              {tabData.map(({ id, title, defaultTitle, path }) => {
                const isSelected = id === currentTab
                let displayTitle = defaultTitle || title

                if (_t(title) !== title) {
                  displayTitle = _t(title)
                }

                return (
                  <Box
                    as={Link}
                    to={path}
                    key={id}
                    pb='0.5rem'
                    mr='2.25rem'
                    textStyle='headingTab'
                    display='inline-block'
                    borderBottom='2px solid'
                    borderColor={isSelected ? 'primary' : 'transparent'}
                    cursor='pointer'
                    alignSelf='baseline'
                  >
                    {displayTitle}
                  </Box>
                )
              })}
            </Box>
          )}
        </Flex>
      </Box>
    </Flex>
  )
}

export default HeadingSection

import React, { useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import { Box, Flex, Text } from '@chakra-ui/react'
import { useAtom } from 'jotai'

import { ActionButton, ConnectionButton } from './components'
import { LoadingSpinner } from 'components'
import { useLanguages } from 'hooks'
import { useNotification } from 'queries/notification'
import { userProfileAtom } from 'store/authAtom'
import { isMSTAdmin } from 'utils/helper'
import { parseTimeDistance } from 'utils/parser'

import { ReactComponent as ArrowIcon } from 'assets/images/arrowBack_icon.svg'
import { ReactComponent as Logo } from 'assets/images/knovo_logo_grayscale.svg'

interface NotificationDetailProps {
  id: string
  userId: string
  type: string
}

const NotificationDetail: React.FC<NotificationDetailProps> = ({
  id,
  userId,
  type,
}) => {
  const navigate = useNavigate()
  const { _t } = useLanguages()
  const [userProfile] = useAtom(userProfileAtom)
  const { data, isLoading } = useNotification(id)

  const isMSTAdminMemo = useMemo(() => isMSTAdmin(userProfile), [userProfile])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const handleGoBack = () => {
    if (isMSTAdminMemo) {
      navigate(`/admin/all-notifications`)
    } else {
      navigate(`/notifications?tab=${type}`)
    }
  }

  return (
    <>
      <Flex justify={'space-between'}>
        <Flex
          onClick={handleGoBack}
          py='1.5rem'
          cursor='pointer'
          alignItems='center'
        >
          <ArrowIcon />
          <Box display={{ base: 'none', sm: 'flex' }}>
            <Text ml='0.5rem'>{_t('notifications.go_back')}</Text>
          </Box>
        </Flex>
        <Box py='0.5rem'>
          {!isLoading && data && (
            <>
              <ActionButton data={data} isMSTAdmin={isMSTAdminMemo} />
              <ConnectionButton data={data} userId={userId} />
            </>
          )}
        </Box>
      </Flex>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <Box as='section' pb='3rem'>
          <Box p={'1.5rem'} bg='white'>
            {!data && (
              <Flex mt='1rem' justifyContent='center' alignItems='center'>
                <Flex direction='column' alignItems='center'>
                  <Logo />
                  <Text color='gray.300' mt='1rem'>
                    {_t('notifications.not_found')}
                  </Text>
                </Flex>
              </Flex>
            )}
            <Text as='h2' pb='0.5rem'>
              {data?.title}
            </Text>
            <Text fontSize={'0.75rem'} color={'gray.300'} pb='1rem'>
              {parseTimeDistance(data?.createdAt)}
            </Text>
            <Text>{data?.message || data?.title}</Text>
          </Box>
        </Box>
      )}
    </>
  )
}

export default NotificationDetail

import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { Avatar, Box, Button, Flex, Text } from '@chakra-ui/react'

import {
  Checkbox,
  LoadingSpinner,
  Modal,
  ModalProps,
  SearchBar,
  Visible,
} from 'components'
import { useSearch } from 'hooks'
import { useConnectionListFriend } from 'queries/connection'
import { Learner } from 'types'
import {
  Connection,
  ConnectionStatusType,
  ConnectionType,
} from 'types/connection'

interface AddLearnerModalProps extends Omit<ModalProps, 'children'> {
  userId: string
  type: string
  learners: Learner[]
  handleAddLearner: (data: Learner[]) => void
}

const limit = 10

const AddLearnerModal = ({
  isOpen,
  onClose,
  userId,
  learners,
  handleAddLearner,
  type,
}: AddLearnerModalProps) => {
  const { search, handleSearch } = useSearch(300)
  const [selectedLearner, setSelectedLearner] = useState<Connection[]>([])
  const [savedLearnerData, setSavedLearnerData] = useState<Connection[]>([])
  const [loadingPage, setLoadingPage] = useState<number>(1)

  const { data: learnersData, isLoading } = useConnectionListFriend(userId, {
    page: loadingPage,
    search,
    limit,
    type: ConnectionType.STUDENT,
    status: ConnectionStatusType.CONFIRM,
  })

  useEffect(() => {
    handleSearch('')
  }, [handleSearch, isOpen])

  useEffect(() => {
    setLoadingPage(1)
  }, [search])

  useEffect(() => {
    const allResults = learnersData?.data || []

    if (loadingPage > 1) {
      setSavedLearnerData(oldData => [...oldData, ...allResults])
    } else {
      setSavedLearnerData([...allResults])
    }
  }, [learnersData, loadingPage])

  const handleLoadMore = useCallback(() => {
    if (learnersData?.meta.total) {
      const lastPage = Math.ceil(learnersData?.meta.total / limit)

      if (!isLoading && loadingPage <= lastPage) {
        setLoadingPage(oldPage => oldPage + 1)
      }
    }
  }, [isLoading, learnersData, loadingPage])

  const handleUpdateLearner = useCallback(() => {
    const list = selectedLearner.map(
      d =>
        ({
          id: d.id,
          avatar: d.avatar,
          email: d.email,
          firstName: d.firstName,
          lastName: d.lastName,
          middleName: '',
        } as Learner)
    )
    handleAddLearner(list)
    onClose()
  }, [handleAddLearner, onClose, selectedLearner])

  useEffect(() => {
    if (learners.length) {
      const list = learners.map(
        d =>
          ({
            id: d.id,
            avatar: d.avatar,
            email: d.email,
            firstName: d.firstName,
            lastName: d.lastName,
            middleName: '',
          } as Connection)
      )
      setSelectedLearner(list)
    }
  }, [learners])

  const allLearnerChecked = useMemo(() => {
    return selectedLearner.length === savedLearnerData?.length
  }, [savedLearnerData, selectedLearner.length])

  const toggleAllCheckBox = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked) {
        setSelectedLearner(() => savedLearnerData || [])
        return
      }

      setSelectedLearner([])
    },
    [savedLearnerData]
  )

  const deselectAllCheckBox = useCallback(() => {
    setSelectedLearner([])
  }, [])

  const isChecked = useCallback(
    (item: any) => {
      if (selectedLearner.findIndex(learner => learner.id === item.id) !== -1) {
        return true
      }
      return false
    },
    [selectedLearner]
  )

  const toggleCheckBox = useCallback(
    (value: any) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const checked = event.target.checked

      setSelectedLearner(oldLearner => {
        if (checked) {
          return [...oldLearner, value]
        }
        return oldLearner.filter(x => x.id !== value.id)
      })
    },
    []
  )

  return (
    <Modal isOpen={isOpen} onClose={onClose} size='xl'>
      <Box textStyle='headingModalText' textAlign='center' mb='30px'>
        Connected Learners
      </Box>
      <Flex justifyContent='space-between' alignItems='center'>
        <SearchBar
          w='20rem'
          placeholder='Search Requests'
          onSearch={handleSearch}
        />
        <Flex>
          <Box pr='1rem' borderRight='1px solid #E0E0E0'>
            <Button
              variant='transparent'
              fontSize='0.875rem'
              textTransform='capitalize'
              color='primary'
              p='0'
              h='fit-content'
              onClick={deselectAllCheckBox}
            >
              Deselect all
            </Button>
          </Box>
          <Box pl='1rem'>
            <Checkbox
              isChecked={allLearnerChecked}
              onChange={toggleAllCheckBox}
            />
          </Box>
        </Flex>
      </Flex>
      <Box>
        <Flex
          flexDirection='column'
          borderRadius='4px'
          mb='1.875rem'
          py='0.625rem'
          pr='24px'
          maxHeight='20rem'
          overflowY='scroll'
        >
          {savedLearnerData.map(item => (
            <Flex
              key={item.id}
              py='1rem'
              pr='3px'
              justifyContent='space-between'
              alignItems='center'
              borderBottom='1px solid rgba(229, 229, 229, 0.6)'
              _last={{ borderBottom: 'none' }}
            >
              <Flex fontSize='0.875rem'>
                <Avatar
                  src={item?.avatar}
                  size='sm'
                  name={`${item.firstName} ${item.lastName}`}
                />
                <Text ml='0.5rem' mr='1rem'>
                  {`${item.firstName} ${item.lastName}`}
                </Text>
                <Text color='gray.300'>{item.email}</Text>
              </Flex>
              <Checkbox
                isChecked={isChecked(item)}
                onChange={toggleCheckBox(item)}
              />
            </Flex>
          ))}
          {isLoading && <LoadingSpinner />}
          <Visible onVisible={handleLoadMore} />
        </Flex>
        <Flex justifyContent='space-around' alignItems='center'>
          <Button
            variant='primarySolid'
            px='1.5rem'
            disabled={!selectedLearner.length}
            onClick={handleUpdateLearner}
          >
            ADD LEARNERS
          </Button>
          <Button variant='transparent' px='1.5rem' onClick={onClose}>
            CANCEL
          </Button>
        </Flex>
      </Box>
    </Modal>
  )
}

export default AddLearnerModal

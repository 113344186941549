import { useMutation, useQuery } from 'react-query'

import { CentreQuery, sleep } from './centre'
import { useToastError } from './helpers'
import {
  addEnterprise,
  deleteEnterprise,
  getUploadAvatarUrl,
  getUploadBannerUrl,
  updateEnterprise,
  uploadFileEnterprise,
} from 'apis/enterprise'
import { enterprise } from 'configs/apiPath'
import { useInvalidateUrl, useLanguages, useLoading, useToast } from 'hooks'
import {
  Enterprise,
  EnterpriseDetail,
  EnterpriseExportRequest,
  EnterpriseExportResponse,
  ListingParams,
  Meta,
} from 'types'

export type EnterpriseQuery = {
  data: Enterprise[]
} & Meta

export type EnterpriseDetailQuery = {
  data: EnterpriseDetail
} & Meta

const handleUploadFile = async (
  type: 'avatar' | 'banner',
  id: string,
  file: File
) => {
  try {
    let res = undefined
    if (type === 'avatar') {
      res = await getUploadAvatarUrl(id)
    } else {
      res = await getUploadBannerUrl(id)
    }
    if (res) {
      const formData = new FormData()
      const { fields, url } = res.data.data
      Object.entries(fields).forEach(([key, value]) => {
        formData.append(key, value)
      }, [])
      formData.append('file', file as File)
      const resUpload = await uploadFileEnterprise(url, formData)
      if (resUpload) {
        return 'success'
      }
    }
  } catch (err) {
    console.log(err)
  }
}

export const useEnterprises = ({
  page,
  limit,
  search,
  searchBy,
  order,
  orderBy,
  filterBy,
  centers,
  learners,
  instructors,
  admin,
  wallet,
  status,
}: ListingParams) => {
  return useQuery<EnterpriseQuery>(
    enterprise.list({
      page,
      limit,
      search,
      searchBy,
      order,
      orderBy,
      filterBy,
      centers,
      learners,
      instructors,
      admin,
      wallet,
      status,
    })
  )
}

export const useAddNewEnterprise = () => {
  const toast = useToast()
  const invalidateUrl = useInvalidateUrl(
    enterprise.list({ limit: 10, page: 1 })
  )
  const { setLoading } = useLoading()
  const { _t } = useLanguages()

  return useMutation(addEnterprise, {
    onMutate: () => setLoading(true),
    onSettled: () => setLoading(false),
    onSuccess: async (
      resData: { data: { data: { id: string } } },
      { onClose, avatarFile, bannerFile, resetFile }
    ) => {
      const enterpriseId = resData.data.data.id
      if (avatarFile) {
        await handleUploadFile('avatar', enterpriseId, avatarFile)
      }
      if (bannerFile) {
        await handleUploadFile('banner', enterpriseId, bannerFile)
      }

      // waiting for uploading on database
      await sleep(1000)

      onClose()
      resetFile()
      invalidateUrl()
      toast({
        title: 'Success!',
        description: _t('message.success.enterprise_add'),
        status: 'success',
      })
    },
    onError: useToastError(),
  })
}

export const useDeleteEnterprise = () => {
  const toast = useToast()
  const invalidateUrl = useInvalidateUrl(
    enterprise.list({ limit: 10, page: 1 })
  )
  const { setLoading } = useLoading()
  const { _t } = useLanguages()

  return useMutation(deleteEnterprise, {
    onMutate: () => setLoading(true),
    onSettled: () => setLoading(false),
    onSuccess: async () => {
      await invalidateUrl()
      toast({
        title: 'Success!',
        description: _t('message.success.enterprise_remove'),
        status: 'success',
      })
    },
    onError: useToastError(),
  })
}

export const useUpdateEnterprise = (enterpriseId: string) => {
  const toast = useToast()
  const invalidateUrlDetail = useInvalidateUrl(
    enterprise.detailForSuperAdmin(enterpriseId)
  )
  const invalidateUrlList = useInvalidateUrl(
    enterprise.list({ limit: 10, page: 1 })
  )
  const { setLoading } = useLoading()
  const { _t } = useLanguages()

  return useMutation(updateEnterprise, {
    onMutate: () => setLoading(true),
    onSettled: () => setLoading(false),
    onSuccess: async (_, { id, avatarFile, bannerFile, resetFile }) => {
      if (avatarFile) {
        await handleUploadFile('avatar', id, avatarFile)
      }
      if (bannerFile) {
        await handleUploadFile('banner', id, bannerFile)
      }
      // waiting for uploading on database
      await sleep(1000)

      invalidateUrlDetail()
      invalidateUrlList()
      resetFile()
      toast({
        title: 'Success!',
        description: _t('message.success.enterprise_update'),
        status: 'success',
      })
    },
    onError: useToastError(),
  })
}

export const useEnterpriseDetail = (userId: string) => {
  return useQuery<EnterpriseDetailQuery>(enterprise.detail(userId), {
    enabled: !!userId,
  })
}

export const useEnterpriseDetailSuperAdmin = (enterpriseId: string) => {
  return useQuery<EnterpriseDetailQuery>(
    enterprise.detailForSuperAdmin(enterpriseId)
  )
}

export const useEnterpriseProfile = (enterpriseId: string) => {
  return useQuery<EnterpriseDetailQuery>(enterprise.profile(enterpriseId), {
    enabled: !!enterpriseId,
  })
}

export const useCentresForEnterprise = (
  enterpriseId: string,
  page: number,
  limit: number
) => {
  return useQuery<CentreQuery>(
    enterprise.centresForEnterprise(enterpriseId, page, limit)
  )
}

export const useEnterpriseExport = (params: EnterpriseExportRequest) => {
  const options = { enabled: false }
  return useQuery<EnterpriseExportResponse>(
    enterprise.export(params.fileType || 'xlsx'),
    options
  )
}

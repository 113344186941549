import React from 'react'

import { Text } from '@chakra-ui/react'

import { useLanguages } from 'hooks'
import { TransactionType } from 'types'

interface TransactionTypeTextProps {
  type?: TransactionType
}

const TransactionTypeText: React.FC<TransactionTypeTextProps> = ({ type }) => {
  const { _t } = useLanguages()

  switch (type) {
    case TransactionType.TOPUP:
      return <Text>{_t('transaction.type.top_up')}</Text>
    case TransactionType.PURCHASE:
      return <Text>{_t('transaction.type.purchase')}</Text>
    case TransactionType.SUBSCRIPTION:
      return <Text>{_t('transaction.type.subscription')}</Text>
    case TransactionType.EARNING:
      return <Text>{_t('transaction.type.earning')}</Text>
    case TransactionType.SUBSCRIPTION_EARNING:
      return <Text>{_t('transaction.type.subscription_earning')}</Text>
    case TransactionType.REFUND:
      return <Text>{_t('transaction.type.refund')}</Text>
    case TransactionType.WITHDRAWAL:
      return <Text>{_t('transaction.type.withdrawal')}</Text>
    case TransactionType.WRITE_OFF:
      return <Text>{_t('transaction.type.write_off')}</Text>
    default:
      return <Text>{_t('common.unknown')}</Text>
  }
}

export default TransactionTypeText

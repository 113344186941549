export interface FilterForm {
  status?: string
  type?: string
  paymentType?: string
}

export interface WithdrawalCreateForm {
  amount: number
  paymentType: string
  remarks: string
}

export interface WithdrawalEditForm {
  isCompleted?: boolean
  remarks?: string
}

export const searchOptions = [
  { value: 'all', label: 'All' },
  { value: 'transactionId', label: 'Transaction ID' },
  { value: 'description', label: 'Description' },
  { value: 'status', label: 'Status' },
  { value: 'paymentType', label: 'Payment Type' },
  { value: 'userId', label: 'User ID' },
  { value: 'centerId', label: 'Centre ID' },
  { value: 'enterpriseId', label: 'Enterprise ID' },
]

export const sortOptions = [
  { value: 'createdAt', label: 'Date' },
  { value: 'updatedAt', label: 'Last Update' },
  { value: 'amount', label: 'Amount' },
  { value: 'status', label: 'Status' },
]

export const typeOptions = [
  {
    value: 'topup',
    label: 'transaction.type.top_up',
  },
  {
    value: 'purchase',
    label: 'transaction.type.purchase',
  },
  {
    value: 'subscription',
    label: 'transaction.type.subscription',
  },
  {
    value: 'earning',
    label: 'transaction.type.earning',
  },
  {
    value: 'subscription_earning',
    label: 'transaction.type.subscription_earning',
  },
  {
    value: 'refund',
    label: 'transaction.type.refund',
  },
  {
    value: 'withdrawal',
    label: 'transaction.type.withdrawal',
  },
  {
    value: 'write_off',
    label: 'transaction.type.write_off',
  },
]

export const statusOptions = [
  {
    value: 'pending',
    label: 'transaction.status.pending',
  },
  {
    value: 'pending_approval',
    label: 'transaction.status.pending_approval',
  },
  {
    value: 'approved',
    label: 'transaction.status.approved',
  },
  {
    value: 'rejected',
    label: 'transaction.status.rejected',
  },
  {
    value: 'success',
    label: 'transaction.status.success',
  },
  {
    value: 'failed',
    label: 'transaction.status.failed',
  },
  {
    value: 'processing',
    label: 'transaction.status.processing',
  },
  {
    value: 'completed',
    label: 'transaction.status.completed',
  },
  {
    value: 'refunded',
    label: 'transaction.status.refunded',
  },
]

export const paymentTypeOptions = [
  {
    value: 'card',
    label: 'transaction.payment_type.card',
  },
  {
    value: 'credit',
    label: 'transaction.payment_type.credit',
  },
  {
    value: 'gcash',
    label: 'transaction.payment_type.gcash',
  },
  {
    value: 'bank',
    label: 'transaction.payment_type.bank',
  },
  {
    value: 'cheque',
    label: 'transaction.payment_type.cheque',
  },
  {
    value: 'cash',
    label: 'transaction.payment_type.cash',
  },
]

import { getGuessStatuses } from './status'

// Change solutions here!
const numbers = [
  '23232',
  '93283',
  '23034',
  '32032',
  '37009',
  '68097',
  '49543',
  '20235',
  '21379',
  '83737',
  '05069',
  '40159',
  '99035',
  '55996',
  '97494',
  '04522',
  '72183',
  '11346',
  '28249',
  '51535',
  '20909',
  '69283',
  '39882',
  '66991',
  '92099',
  '37563',
  '60469',
  '82750',
  '51034',
  '20168',
  '66228',
  '05458',
  '11138',
  '05265',
  '42303',
  '17895',
  '71618',
  '42964',
  '09179',
  '85168',
  '87395',
  '67166',
  '19250',
  '78209',
  '35693',
  '42592',
  '15409',
  '48052',
  '33597',
  '20442',
]

export const isNumberInNumberList = (number: string) => {
  return numbers.includes(number.toLowerCase())
}

export const isWinningNumber = (number: string) => {
  return solution === number
}

// build a set of previously revealed numbers - present and correct
// guess must use correct numbers in that space and any other revealed numbers
export const findFirstUnusedReveal = (number: string, guesses: string[]) => {
  const knownNumberSet = new Set<string>()
  for (const guess of guesses) {
    const statuses = getGuessStatuses(guess)

    for (let i = 0; i < guess.length; i++) {
      if (statuses[i] === 'correct' || statuses[i] === 'present') {
        knownNumberSet.add(guess[i])
      }
      if (statuses[i] === 'correct' && number[i] !== guess[i]) {
        return `Must use ${guess[i]} in position ${i + 1}`
      }
    }
  }

  for (const num of Array.from(knownNumberSet.values())) {
    // fail fast, always return first failed number if applicable
    if (!number.includes(num)) {
      return `Guess must contain ${num}`
    }
  }
  return false
}

const getNumberOfDay = () => {
  // January 1, 2022 Game Epoch
  const epochMs = new Date('January 1, 2022 00:00:00').valueOf()
  const now = Date.now()
  const msInDay = 86400000
  const index = Math.floor((now - epochMs) / msInDay)
  const nextday = (index + 1) * msInDay + epochMs

  return {
    solution: numbers[index % numbers.length].toUpperCase(),
    tomorrow: nextday,
  }
}

export const { solution, tomorrow } = getNumberOfDay()

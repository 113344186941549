import React from 'react'

import { Box, Flex } from '@chakra-ui/react'

import { CharStatus } from 'utils/game/status'

interface CellProps {
  value?: string
  status?: CharStatus
}

const Cell: React.FC<CellProps> = ({ value, status }) => {
  switch (status) {
    case 'absent':
      return (
        <Flex
          w={{ base: '2.5rem', md: '4rem' }}
          h={{ base: '2.5rem', md: '4rem' }}
          border='2px solid grey'
          alignItems='center'
          justifyContent='center'
          mx='0.25rem'
          rounded='0.5rem'
          backgroundColor='#64748b'
          color='white'
          borderColor='#64748b'
        >
          <Box fontSize={{ base: '1rem', md: '2rem' }}>{value}</Box>
        </Flex>
      )

    case 'correct':
      return (
        <Flex
          w={{ base: '2.5rem', md: '4rem' }}
          h={{ base: '2.5rem', md: '4rem' }}
          border='2px solid grey'
          alignItems='center'
          justifyContent='center'
          mx='0.25rem'
          rounded='0.5rem'
          background='#78BD88'
          color='white'
          borderColor='#78BD88'
        >
          <Box fontSize={{ base: '1rem', md: '2rem' }}>{value}</Box>
        </Flex>
      )

    case 'present':
      return (
        <Flex
          w={{ base: '2.5rem', md: '4rem' }}
          h={{ base: '2.5rem', md: '4rem' }}
          border='2px solid grey'
          alignItems='center'
          justifyContent='center'
          mx='0.25rem'
          rounded='0.5rem'
          background='#EAB184'
          color='white'
          borderColor='#EAB184'
        >
          <Box fontSize={{ base: '1rem', md: '2rem' }}>{value}</Box>
        </Flex>
      )
    default:
      return (
        <Flex
          w={{ base: '2.5rem', md: '4rem' }}
          h={{ base: '2.5rem', md: '4rem' }}
          border='2px solid grey'
          alignItems='center'
          justifyContent='center'
          mx='0.25rem'
          rounded='0.5rem'
        >
          <Box fontSize={{ base: '1rem', md: '2rem' }}>{value}</Box>
        </Flex>
      )
  }
}

export default Cell

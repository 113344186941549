import React from 'react'
import { Control, Controller } from 'react-hook-form'

import { Box, Flex, Text } from '@chakra-ui/react'

import { WithdrawalEditForm } from '../form'
import { Checkbox, Textarea } from 'components'
import { useLanguages } from 'hooks'
import { TransactionInfo, TransactionStatus } from 'types'
import { formatAmount } from 'utils/parser'

interface EditWithdrawalFormProps {
  data: TransactionInfo
  control: Control<WithdrawalEditForm>
  errors: any
}

const EditWithdrawalForm: React.FC<EditWithdrawalFormProps> = ({
  data,
  control,
  errors,
}) => {
  const { _t } = useLanguages()

  return (
    <Box>
      <Flex direction={{ base: 'column', md: 'row' }} justify='space-between'>
        <Box flex={1}>
          <Box mb='1rem'>
            <Text fontSize='0.875rem' fontWeight='600'>
              {_t('transaction.detail.withdrawal_amount')}
            </Text>
            <Text>{`$${formatAmount(data.amount / 100)}`}</Text>
          </Box>
          <Box mb='1rem'>
            <Text fontSize='0.875rem' fontWeight='600'>
              {_t('transaction.detail.status')}
            </Text>
            <Controller
              control={control}
              name='isCompleted'
              defaultValue={data.status === TransactionStatus.COMPLETED}
              render={({ value, onChange }) => (
                <Checkbox
                  isChecked={value}
                  onChange={e => onChange(e.target.checked)}
                  isDisabled={data.status === 'completed'}
                >
                  {_t('transaction.status.completed')}
                </Checkbox>
              )}
              error={errors.isCompleted?.message}
            />
          </Box>
        </Box>
        <Box flex={1}></Box>
      </Flex>
      <Box mb='1rem'>
        <Controller
          as={Textarea}
          control={control}
          name='remarks'
          defaultValue={data.remarks}
          label={_t('transaction.detail.remarks')}
          aria-label='Remarks'
          placeholder={_t('transaction.detail.placeholder_remarks')}
          error={errors.remarks?.message}
        />
      </Box>
    </Box>
  )
}

export default EditWithdrawalForm

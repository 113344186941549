import React, { useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import { Box, Button, Flex, Grid } from '@chakra-ui/react'
import { useAtom } from 'jotai'

import ProfileLayout from '../../containers/ProfileLayout'
import ViewProfileContainer from '../../containers/ViewProfile'
import {
  CalendarRequestController,
  ContentContainer,
  HeadingSection,
  IconText,
  LoadingSpinner,
  ProfileHeaderInfo,
  TutorReviews,
} from 'components'
import { useLanguages, useQueryParams } from 'hooks'
import { ConnectionButton, ConnectionsSection } from 'shared/containers'
import { userProfileAtom } from 'store/authAtom'
import { GroupedCombination, UserProfile } from 'types'
import { responsivePadding } from 'utils/constant'

import { ReactComponent as ClockIcon } from 'assets/images/clock_icon.svg'

interface PreviewProfileContainerProps {
  userId?: string
  viewerId?: string
  profileData: UserProfile | undefined | null
  teachingCombinations?: GroupedCombination[]
  isInstructor?: boolean
  isLearner?: boolean
  isPublicView?: boolean
}

const tabData = (hasConnectionsTab?: boolean, hasReviewTab?: boolean) => {
  const connectionsTab = hasConnectionsTab
    ? [
        {
          id: 'connection',
          title: 'user.tab.connections',
          path: `?tab=connection`,
        },
      ]
    : []

  const reviewTab = hasReviewTab
    ? [
        {
          id: 'reviews',
          title: 'user.tab.reviews',
          path: '?tab=reviews',
        },
      ]
    : []

  return [
    {
      id: 'about',
      title: 'user.tab.about',
      path: `?tab=about`,
    },
    ...connectionsTab,
    ...reviewTab,
  ]
}

const connectionsTabData = (hasLearnerTab?: boolean) => {
  const learnerTab = hasLearnerTab
    ? [
        {
          id: 'learner',
          label: 'profile.connection.learner',
        },
      ]
    : []

  return [
    ...learnerTab,
    {
      id: 'instructor',
      label: 'profile.connection.instructor',
    },
    {
      id: 'centre',
      label: 'profile.connection.center',
    },
    {
      id: 'pending',
      label: 'profile.connection.pending',
    },
  ]
}

type TabId = 'about' | 'connection' | 'reviews'

const PreviewProfileContainer: React.FC<PreviewProfileContainerProps> = ({
  userId,
  viewerId,
  profileData,
  teachingCombinations,
  isInstructor = false,
  isLearner = false,
  isPublicView = false,
}) => {
  const { _t } = useLanguages()
  const query = useQueryParams()
  const navigate = useNavigate()
  const [selfProfile] = useAtom(userProfileAtom)
  const selfRole = selfProfile?.roles?.[0]

  const currentTab = useMemo<TabId>(() => (query.tab || 'about') as TabId, [
    query,
  ])

  const handleEditProfile = useCallback(() => {
    navigate('/my-profile/edit')
  }, [navigate])

  if (!profileData) {
    return <LoadingSpinner />
  }

  const connectButton =
    viewerId && userId ? (
      <ConnectionButton userId={viewerId} friendId={userId} />
    ) : undefined

  const profileHeader = (
    <Box px={responsivePadding}>
      <ProfileHeaderInfo
        data={profileData}
        rightComponent={
          isPublicView ? (
            connectButton
          ) : (
            <Button
              variant='outlinePrimary'
              onClick={handleEditProfile}
              px='1.5rem'
              textTransform='uppercase'
            >
              {_t('profile.edit')}
            </Button>
          )
        }
      />
    </Box>
  )

  return (
    <ProfileLayout
      topElement={
        isPublicView && isLearner ? (
          profileHeader
        ) : (
          <Flex direction='column'>
            {profileHeader}
            <HeadingSection
              tabData={tabData(!isPublicView, isInstructor)}
              currentTab={currentTab}
              widthType='narrow'
            />
          </Flex>
        )
      }
      topBoxProps={{ pb: '0' }}
    >
      {currentTab === 'about' && (
        <Grid
          gridTemplateColumns={
            isInstructor && isPublicView
              ? { base: '1fr', lg: '2fr 1fr' }
              : '1fr'
          }
          gap='2rem'
        >
          <ViewProfileContainer
            profileData={profileData}
            handleEditProfile={handleEditProfile}
            teachingCombinations={teachingCombinations}
            isLearner={isLearner}
            isInstructor={isInstructor}
            isPublicView={isPublicView}
          />
          {isInstructor && isPublicView && (
            <ContentContainer h='42rem' minW='24rem'>
              <IconText
                icon={ClockIcon}
                text={_t('user.availability.title')}
                textProps={{
                  fontWeight: '600',
                  textTransform: 'uppercase',
                }}
              />
              <Box mt='1.5rem' h='95%' w='100%' overflowY='hidden'>
                <CalendarRequestController
                  tutorId={userId}
                  roleName={selfRole?.name.replace(/ /g, '') || ''}
                />
              </Box>
            </ContentContainer>
          )}
        </Grid>
      )}
      {!isPublicView && currentTab === 'connection' && (
        <ConnectionsSection
          userId={profileData.id!}
          tabData={connectionsTabData(isInstructor)}
        />
      )}
      {isInstructor && currentTab === 'reviews' && (
        <TutorReviews
          rating={profileData.rating}
          tutorId={profileData.id!}
          type='instructor'
        />
      )}
    </ProfileLayout>
  )
}

export default PreviewProfileContainer

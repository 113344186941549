import React from 'react'

import { Box, Flex, Link } from '@chakra-ui/react'

import { ReactComponent as ExternalLinkIcon } from 'assets/images/external_link_icon.svg'

type LabelTextType = 'horizontal' | 'vertical'

interface LabelTextProps {
  label: string
  value?: string | number
  type?: LabelTextType
  isLink?: boolean
  mb?: string | string[]
}

const LabelText: React.FC<LabelTextProps> = ({
  label,
  value,
  type = 'vertical',
  isLink = false,
  mb,
}: LabelTextProps) => {
  if (type === 'horizontal') {
    return (
      <Flex justifyContent='space-between' mb={mb}>
        <Box fontSize='0.875rem' fontWeight='600'>
          {label}
        </Box>
        <Flex>
          <Box as='span'>{value || '-'}</Box>
          {isLink && value && typeof value === 'string' && (
            <Link href={value} isExternal>
              <ExternalLinkIcon />
            </Link>
          )}
        </Flex>
      </Flex>
    )
  }

  return (
    <Box mb={mb}>
      <Box fontSize='0.875rem' fontWeight='600' mb='0.25rem'>
        {label}
      </Box>
      <Flex>
        <Box as='span'>{value || '-'}</Box>
        {isLink && value && typeof value === 'string' && (
          <Link href={value} isExternal>
            <ExternalLinkIcon />
          </Link>
        )}
      </Flex>
    </Box>
  )
}

export default LabelText

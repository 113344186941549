import { atom } from 'jotai'

export const totalResultAtom = atom(0)
export const totalResult = atom(get => get(totalResultAtom))

export const countryAtom = atom('')
export const country = atom(get => get(countryAtom))

export const eduSysAtom = atom('')
export const eduSys = atom(get => get(eduSysAtom))
export const eduSysEnabled = atom(get => (get(countryAtom) ? true : false))

export const filterAtom = atom<any>({})
export const filter = atom(get => get(filterAtom))

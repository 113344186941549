import React from 'react'
import DatePicker, { ReactDatePickerProps } from 'react-datepicker'

import { Box, BoxProps, Flex, Text } from '@chakra-ui/react'

import { useLanguages } from 'hooks'

interface DateTimePickerProps extends ReactDatePickerProps {
  error?: string
  label?: string
  selected?: any
  isShowTime?: boolean
  disabled?: boolean
  onChange: (e: any) => void
  labelProps?: BoxProps
  optional?: boolean
  filterTime?(date: Date): boolean
  containerProps?: BoxProps
}

const DateTimePicker: React.FC<DateTimePickerProps> = ({
  error,
  children,
  label,
  selected,
  disabled = false,
  onChange,
  isShowTime = false,
  inline = false,
  labelProps,
  optional = false,
  containerProps,
  selectsRange = false,
  ...dateTimeProps
}) => {
  const { _t } = useLanguages()

  return (
    <Box {...containerProps} opacity={disabled ? 0.3 : 1}>
      {label && (
        <Box fontSize='0.875rem' fontWeight='600' {...labelProps}>
          {label}
          {optional && (
            <Text
              display='inline'
              fontSize={labelProps?.fontSize || '0.875rem'}
              fontStyle='italic'
              color='gray.300'
            >
              - {_t('common.optional')}
            </Text>
          )}
        </Box>
      )}
      <Flex
        justifyContent='center'
        py='0.25rem'
        height={inline ? 'auto' : '2.125rem'}
        borderBottom={inline ? '' : '1px solid #828282'}
      >
        <DatePicker
          onChange={onChange}
          selected={selected}
          placeholderText='Select Date and Time'
          showTimeSelect={isShowTime}
          inline={inline}
          dateFormat={isShowTime ? 'dd/MM/yyyy h:mm aa' : 'dd/MM/yyyy'}
          disabled={disabled}
          selectsRange={selectsRange}
          {...dateTimeProps}
        />
      </Flex>
      {error && <Text textStyle='error'>{error}</Text>}
    </Box>
  )
}

export default DateTimePicker

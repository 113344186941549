import React, { useMemo, useState } from 'react'

import { Box, Flex, Text } from '@chakra-ui/react'

import LoadingSpinner from '../LoadingSpinner'
import Select, { Option } from '../Select'
import { Attachment, AttachmentFileStatus, UploadFileStatus } from 'types'
import { getBytes } from 'utils/parser'

import { ReactComponent as CloseIcon } from 'assets/images/close_gray_icon.svg'
import { ReactComponent as EyeIcon } from 'assets/images/eye_dark.svg'
import { ReactComponent as FileIcon } from 'assets/images/file_icon.svg'
import { ReactComponent as UploadFileFailure } from 'assets/images/upload_failure_icon.svg'
import { ReactComponent as UploadFileSuccess } from 'assets/images/upload_success_icon.svg'

interface AttachmentFileProps {
  item: Attachment
  status?: string
  uploadStatus?: UploadFileStatus
  downloadable?: boolean
  deletable?: boolean
  onChangeStatus?: (status: AttachmentFileStatus) => void
  onDelete?: (name: string, status?: string, id?: string) => void
  onReUpload?: () => Promise<void>
  withStatus?: boolean
  onViewFile?: (file: Attachment) => void
}

const fileType = [
  {
    label: 'Public',
    value: 'public',
  },
  {
    label: 'Private',
    value: 'enrolled',
  },
]

const AttachmentFile: React.FC<AttachmentFileProps> = ({
  item,
  status,
  uploadStatus = 'pending',
  deletable,
  onChangeStatus,
  onDelete,
  onReUpload,
  withStatus = true,
  onViewFile,
}) => {
  const [reUploading, setReuploading] = useState(false)

  const defaultStatus = useMemo(() => {
    return fileType.find(type => type.value === status) || fileType[0]
  }, [status])

  const handleChangeStatus = (option: Option) => {
    onChangeStatus?.(option.value as AttachmentFileStatus)
  }

  const handleDeleteFile = () => {
    onDelete?.(item.name, uploadStatus, item.id)
  }

  const handleReUpload = async () => {
    setReuploading(true)
    try {
      await onReUpload?.()
    } catch {
    } finally {
      setReuploading(false)
    }
  }

  return (
    <Flex
      alignItems='center'
      width='100%'
      p='1rem'
      mb='0.5rem'
      bgColor='#fff'
      boxShadow='lg'
      _last={{
        mb: 0,
      }}
    >
      <Box mr='0.8125rem'>
        <FileIcon />
      </Box>
      <Box fontSize='0.75rem' w='70%' flex={1}>
        <Text noOfLines={1} textOverflow={'ellipsis'}>
          {item?.name}
        </Text>
        <Text color='gray.300'>{getBytes(item.size)}</Text>
      </Box>
      {withStatus && (
        <Select
          defaultOptions={fileType}
          containerProps={{
            w: '1.75rem',
            mt: 0,
          }}
          selectProps={{
            menuHeight: '5rem',
            fontSize: '0.75rem',
          }}
          onChange={handleChangeStatus}
          defaultValue={defaultStatus}
        />
      )}
      {onViewFile && item.id && (
        <Box ml='1.5rem' cursor='pointer' onClick={() => onViewFile(item)}>
          <EyeIcon width='1.4rem' height='1.4rem' />
        </Box>
      )}
      {deletable && (
        <Box ml='1.5rem' cursor='pointer' onClick={handleDeleteFile}>
          <CloseIcon />
        </Box>
      )}
      {(uploadStatus === 'uploading' || reUploading) && (
        <Box ml='1.5rem' onClick={handleDeleteFile}>
          <LoadingSpinner
            spinnerProps={{
              size: 'sm',
            }}
          />
        </Box>
      )}
      {uploadStatus === 'uploaded' && (
        <Box ml='1.5rem' cursor='pointer'>
          <UploadFileSuccess />
        </Box>
      )}
      {uploadStatus === 'failure' && !reUploading && (
        <Box ml='1.5rem' cursor='pointer' onClick={handleReUpload}>
          <UploadFileFailure />
        </Box>
      )}
    </Flex>
  )
}

export default AttachmentFile

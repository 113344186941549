import Axios from 'axios'

import { transactionPath } from './../configs/apiPath'
import {
  TransactionAdminCreateWithdrawalRequest,
  TransactionAdminUpdateWithdrawalRequest,
} from 'types'

export const createWithdrawal = ({
  data,
  onClose,
}: {
  data: TransactionAdminCreateWithdrawalRequest
  onClose?: () => void
}) => {
  return Axios.post(transactionPath.adminCreateWithdrawal(), data)
}

export const updateWithdrawal = ({
  data,
  onClose,
}: {
  data: TransactionAdminUpdateWithdrawalRequest
  onClose?: () => void
}) => {
  const { transactionId, status, remarks } = data
  return Axios.put(transactionPath.adminUpdateWithdrawal(transactionId), {
    status,
    remarks,
  })
}

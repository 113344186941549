import React, { useCallback } from 'react'
import { Control, Controller } from 'react-hook-form'

import { Box, Flex, Text } from '@chakra-ui/react'
import validator from 'validator'

import { CreateClassForm } from '../form'
import { ContentContainer, DateTimePicker, Input, Tooltip } from 'components'
import { useLanguages } from 'hooks'

import { ReactComponent as InfoIcon } from 'assets/images/info_outline_icon.svg'

interface OccurrenceSectionProps {
  control: Control<CreateClassForm>
  watch: any
  setValue: any
  getValues: any
  register?: any
  errors: any
}

const OccurrenceSection: React.FC<OccurrenceSectionProps> = ({
  control,
  watch,
  setValue,
  getValues,
  register,
  errors,
}) => {
  const { _t } = useLanguages()
  const filterDate = useCallback((value: any) => {
    const selectedDate = new Date(value)
    return selectedDate.getDay() === 0
  }, [])

  return (
    <Flex direction={{ base: 'column', md: 'row' }} justify='space-between'>
      <Box w='100%'>
        <Flex align='center' mb='0.75rem'>
          <Text as='h2' mr='0.5rem'>
            {_t('product.lesson.package_occurrence_title')}
          </Text>
          <Tooltip label={_t('product.lesson.tooltip_package_occurrence')}>
            <InfoIcon />
          </Tooltip>
        </Flex>
        <ContentContainer>
          <Flex
            direction={{ base: 'column', md: 'row' }}
            justify='space-between'
          >
            <Box flex='1'>
              <Flex alignItems='center' fontSize='0.75rem' fontWeight='600'>
                <Text mr='0.5rem'>
                  {_t('product.lesson.occurrence_start_week')}
                </Text>
                <Tooltip
                  label={_t('product.lesson.tooltip_occurrence_start_week')}
                >
                  <InfoIcon />
                </Tooltip>
              </Flex>
              <Controller
                control={control}
                name={`occurrenceStartDate`}
                render={({ onChange, value }) => (
                  <DateTimePicker
                    onChange={onChange}
                    selected={value}
                    placeholderText={_t(
                      'product.lesson.placeholder_occurrence_start_week'
                    )}
                    labelProps={{ fontSize: '0.75rem' }}
                    error={errors?.occurrenceStartDate?.message}
                    filterDate={filterDate}
                  />
                )}
                rules={{
                  validate: {
                    occurrenceStartDate: (value: any) => {
                      if (!value) {
                        return _t(
                          'product.lesson.validate_occurrence_start_week_required'
                        )
                      }
                      return true
                    },
                  },
                }}
              />
            </Box>
            <Box w='4rem' />
            <Box flex='1'>
              <Box>
                <Flex alignItems='center' fontSize='0.75rem' fontWeight='600'>
                  <Text mr='0.5rem'>
                    {_t('product.lesson.recurring_packages')}
                  </Text>
                  <Tooltip
                    label={_t('product.lesson.tooltip_recurring_packages')}
                  >
                    <InfoIcon />
                  </Tooltip>
                </Flex>
                <Controller
                  control={control}
                  name='numberOfPackage'
                  render={({ value, onChange }) => (
                    <Input
                      type='number'
                      placeholder={_t(
                        'product.lesson.placeholder_recurring_packages'
                      )}
                      fontSize='0.875rem'
                      value={value}
                      onChange={onChange}
                      error={errors?.numberOfPackage?.message}
                    />
                  )}
                  rules={{
                    validate: {
                      numberOfPackage: (value: string = '0') =>
                        validator.isInt(value.toString(), { min: 1, max: 30 })
                          ? true
                          : _t(
                              'product.lesson.validate_recurring_packages_invalid'
                            ),
                    },
                  }}
                />
              </Box>
            </Box>
          </Flex>
        </ContentContainer>
      </Box>
    </Flex>
  )
}

export default OccurrenceSection

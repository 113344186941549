import React from 'react'

import { Avatar, Box, Flex } from '@chakra-ui/react'

import { ImageWrapper } from 'components'
import { Centre } from 'types'

import defaultBanner from 'assets/images/default_centre_banner.jpg'

type CentreDisplayCardProps = {
  data: Centre
}

const CentreDisplayCard: React.FC<CentreDisplayCardProps> = ({ data }) => {
  return (
    <Flex
      h={{ base: '140px', md: '180px' }}
      w={{ base: '160px', md: '210px' }}
      minW={{ base: '160px', md: '210px' }}
      direction='column'
      mx='0.7rem'
      mt='0.5rem'
      mb='0.7rem'
      boxShadow='lg'
      rounded='lg'
      position='relative'
      bg='white'
    >
      <ImageWrapper
        h={{ base: '60px', md: '80px' }}
        w='full'
        roundedTop='lg'
        bg='primary'
        src={data.banner || defaultBanner}
        key={data.banner || 'default-banner'}
        objectFit={'cover'}
      />
      <Flex justify='center' mt={-8}>
        <Avatar
          size='lg'
          name={data.name || ''}
          src={data.avatar}
          key={data.avatar || 'default-avatar'}
          css={{
            border: '2px solid white',
          }}
        />
      </Flex>
      <Flex flex='1' direction='column' p='1rem' pt='0.5rem'>
        <Flex
          flex='1'
          direction='column'
          alignItems='center'
          justifyContent='flex-start'
        >
          <Box textAlign='center' fontWeight='600' mb='4px' noOfLines={2}>
            {data.name || ''}
          </Box>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default CentreDisplayCard

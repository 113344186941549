import React from 'react'

import { Box } from '@chakra-ui/react'

export const EnterpriseLabel = () => {
  return (
    <Box
      width='62px'
      height='20px'
      background='primary'
      borderRadius='2px'
      p='0px 4px'
      display='flex'
      alignItems='center'
      justifyContent='center'
      color='white'
      fontSize='0.563rem'
      textTransform='uppercase'
      position='absolute'
      margin='auto'
      left='0'
      right='0'
      top='55px'
    >
      ENTERPRISE
    </Box>
  )
}

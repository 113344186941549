import Axios from 'axios'

import { enterprise } from '../configs/apiPath'
import { EnterpriseImageUrl } from 'types'

export const getEnterpriseDetails = (userId: string) => {
  return Axios.get(enterprise.detail(userId))
}

export const addEnterprise = ({
  data,
  onClose,
  avatarFile,
  bannerFile,
  resetFile,
}: {
  data: any
  onClose: () => void
  avatarFile?: File
  bannerFile?: File
  resetFile: () => void
}) => {
  return Axios.post(enterprise.create(), data)
}

export const updateEnterprise = ({
  data,
  id,
  avatarFile,
  bannerFile,
  resetFile,
}: {
  data: any
  id: string
  avatarFile?: File
  bannerFile?: File
  resetFile: () => void
}) => {
  return Axios.put(enterprise.update(id), data)
}

export const deleteEnterprise = (enterpriseId: string) => {
  return Axios.delete(enterprise.delete(enterpriseId))
}

export const getUploadAvatarUrl = (enterpriseId: string) => {
  return Axios.get<EnterpriseImageUrl>(
    enterprise.getUrlUploadAvatar(enterpriseId)
  )
}

export const getUploadBannerUrl = (enterpriseId: string) => {
  return Axios.get<EnterpriseImageUrl>(
    enterprise.getUrlUploadBanner(enterpriseId)
  )
}

export const uploadFileEnterprise = async (url: string, formData: FormData) => {
  return Axios.post(url, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      'No-Need-Authorization': true,
    },
  })
}

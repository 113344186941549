import React from 'react'
import { useNavigate } from 'react-router-dom'

import { Box, Button, Flex, Text } from '@chakra-ui/react'

import Modal, { ModalProps } from 'components/Modal'
import { assignment } from 'configs/apiPath'
import { useInvalidateUrl } from 'hooks'

import KnovoLogo from 'assets/images/knovo_logo.svg'

export interface ImportClassesSuccessModalProps
  extends Omit<ModalProps, 'children'> {
  totalImportedClass: number
}

const ImportClassesSuccessModal: React.FC<ImportClassesSuccessModalProps> = ({
  isOpen,
  onClose,
  totalImportedClass,
}) => {
  const navigate = useNavigate()
  const invalidate = useInvalidateUrl(assignment.tutorList())

  const goToDashboard = () => {
    onClose()
    navigate('/')
  }

  const backToSessions = () => {
    invalidate()
    onClose()
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size='xl'>
      <Flex direction='column' alignItems='center'>
        <Box as='img' src={KnovoLogo} alt="Tutor's icon" />
        <Text
          mt='1.75rem'
          mb='1rem'
          fontSize='1.125rem'
          fontWeight='bold'
          lineHeight='1.25rem'
        >
          Classes Successfully Imported
        </Text>

        <Text>
          {totalImportedClass || 0} classes have been successfully imported into
          class drafts.
        </Text>

        <Flex my='2rem'>
          <Button variant='solidPrimary' mr='0.5rem' onClick={backToSessions}>
            Back To sessions
          </Button>
          <Button variant='outlinePrimary' ml='0.5rem' onClick={goToDashboard}>
            Go To Dashboard
          </Button>
        </Flex>
      </Flex>
    </Modal>
  )
}

export default ImportClassesSuccessModal

import React, { useEffect, useState } from 'react'

import {
  Box,
  Button,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
} from '@chakra-ui/react'
import { useAtom } from 'jotai'

import { NotificationPopup } from 'components'
import { Dot } from 'components/ActionDot'
import { useUserId } from 'hooks'
import { useNotificationList } from 'queries/notification'
import { hasNewAcceptedNotificationAtom } from 'store/notificationAtom'

import { ReactComponent as NotificationBell } from 'assets/images/notification.svg'

const NotificationPopover = () => {
  const userId = useUserId()
  const [, setHasNewAcceptedNotification] = useAtom(
    hasNewAcceptedNotificationAtom
  )

  const [hasNewNotification, setHasNewNotification] = useState<boolean>(false)

  const { list: notificationList } = useNotificationList({
    userId,
  })

  useEffect(() => {
    if (notificationList?.length) {
      const hasNew = !!notificationList.find(o => !o.isRead)
      setHasNewNotification(hasNew)
    }
  }, [notificationList])

  useEffect(() => {
    setHasNewAcceptedNotification(false)
    if (
      notificationList[0] &&
      notificationList[0].actionType === 'UserAcceptFriend'
    ) {
      setHasNewAcceptedNotification(true)
    }
  }, [notificationList, setHasNewAcceptedNotification])

  return (
    <Box position='relative'>
      <Popover>
        <PopoverTrigger>
          <Button variant='transparent' px='0'>
            <NotificationBell cursor='pointer' />
            {hasNewNotification && (
              <Dot
                w='0.5rem'
                h='0.5rem'
                bgColor='error'
                position='absolute'
                top='10px'
                right='10px'
              />
            )}
          </Button>
        </PopoverTrigger>
        <Box zIndex='1' position='relative'>
          <PopoverContent
            w='26rem'
            h='34rem'
            mr={{ base: '0', md: '6rem' }}
            color='#fff'
            borderRadius='0.5rem'
            boxShadow='md'
            _focus={{
              outline: 0,
            }}
          >
            <PopoverBody
              p='0'
              background='white'
              borderRadius='1rem'
              overflow='hidden'
            >
              <NotificationPopup data={notificationList} />
            </PopoverBody>
          </PopoverContent>
        </Box>
      </Popover>
    </Box>
  )
}

export default NotificationPopover

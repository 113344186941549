import React, { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'

import { Box, Button, HStack, Link, Text, VStack } from '@chakra-ui/react'

import Input from '../Input'
import Checkbox from '../Input/CustomCheckbox'
import { DateTimePicker } from 'components'
import { useLanguages } from 'hooks'
import { UserRole, UserRoleType } from 'types'
import {
  PasswordErrorMessages,
  passwordValidators,
} from 'utils/passwordValidator'
import { EMAIL_PATTERN } from 'utils/regexp'

interface SignUpFormProps {
  onSubmit: (values: SignUpFormInput) => Promise<void>
  role: UserRoleType
}

export interface SignUpFormInput {
  firstName: string
  lastName: string
  email: string
  birthday: number | undefined
  password: string
  confirmPassword: string
  agreement: boolean
  contactNumber: string
  companyName: string
  companyDescription: string
}

const TERMS_URL = 'https://www.knovo.io/terms/'
const PRIVACY_URL = 'https://www.knovo.io/privacy/'

const SignUpForm: React.FC<SignUpFormProps> = ({ onSubmit, role }) => {
  const [loading, setLoading] = useState(false)
  const [formError, setFormError] = useState('')
  const [show, setShow] = useState(false)
  const [showConfirm, setShowConfirm] = useState(false)
  const handleClick = () => setShow(!show)
  const handleClickConfirm = () => setShowConfirm(!showConfirm)
  const { _t } = useLanguages()

  const { handleSubmit, control, errors, getValues } = useForm<SignUpFormInput>(
    {
      mode: 'onChange',
      criteriaMode: 'all',
      defaultValues: {
        firstName: '',
        lastName: '',
        email: '',
        birthday: undefined,
        password: '',
        confirmPassword: '',
        agreement: false,
        contactNumber: '',
        companyName: '',
        companyDescription: '',
      },
    }
  )

  const handleSignUp = async (values: SignUpFormInput) => {
    setFormError('')
    setLoading(true)
    try {
      await onSubmit(values)
    } catch (err) {
      setFormError(
        err?.message || 'Something went wrong, please try again later.'
      )
    } finally {
      setLoading(false)
    }
  }

  return (
    <Box as='form' onSubmit={handleSubmit(handleSignUp)} px='2rem'>
      <VStack spacing={errors?.password ? 1 : 4} align='stretch'>
        <HStack spacing={2}>
          <Controller
            as={Input}
            control={control}
            rules={{
              required: _t('auth.register.validate_first_name_required'),
            }}
            error={errors.firstName?.message}
            name='firstName'
            placeholder={_t('auth.register.placeholder_first_name')}
            aria-label='First Name'
            textStyle='authText'
          />

          <Controller
            as={Input}
            control={control}
            rules={{
              required: _t('auth.register.validate_last_name_required'),
            }}
            error={errors.lastName?.message}
            name='lastName'
            placeholder={_t('auth.register.placeholder_last_name')}
            aria-label='Last Name'
            textStyle='authText'
          />
        </HStack>
        <HStack spacing={2}>
          <Controller
            as={Input}
            control={control}
            rules={{
              required: _t('auth.register.validate_email_required'),
              pattern: {
                value: EMAIL_PATTERN,
                message: _t('auth.register.validate_email_invalid'),
              },
            }}
            error={errors.email?.message}
            name='email'
            placeholder={
              role === UserRole.Enterprise
                ? _t('auth.register.placeholder_business_email')
                : _t('auth.register.placeholder_email')
            }
            aria-label='Email'
            textStyle='authText'
          />
          {role === UserRole.Student && (
            <Controller
              control={control}
              name='birthday'
              rules={{
                required: _t('auth.register.validate_date_of_birth_required'),
              }}
              render={({ onChange, value }) => (
                <DateTimePicker
                  placeholderText={_t(
                    'auth.register.placeholder_date_of_birth'
                  )}
                  dateFormat='dd MMM yyyy'
                  maxDate={new Date()}
                  onChange={e => onChange(e.getTime())}
                  selected={value}
                  showMonthDropdown
                  showYearDropdown
                  yearDropdownItemNumber={130}
                  scrollableYearDropdown
                  error={errors.birthday?.message}
                />
              )}
            />
          )}
          {role === UserRole.Enterprise && (
            <Controller
              as={Input}
              control={control}
              rules={{
                required: _t('auth.register.validate_contact_required'),
              }}
              error={errors.contactNumber?.message}
              name='contactNumber'
              placeholder={_t('auth.register.placeholder_contact')}
              aria-label='Contact Number'
              textStyle='authText'
            />
          )}
        </HStack>

        {role === UserRole.Enterprise ? (
          <>
            <Controller
              as={Input}
              control={control}
              rules={{
                required: _t('auth.register.validate_company_name_required'),
              }}
              error={errors.companyName?.message}
              name='companyName'
              placeholder={_t('auth.register.placeholder_company_name')}
              aria-label='Company Name'
              textStyle='authText'
            />
            <Controller
              as={Input}
              control={control}
              rules={{
                required: _t(
                  'auth.register.validate_company_description_required'
                ),
              }}
              error={errors.companyDescription?.message}
              name='companyDescription'
              placeholder={_t('auth.register.placeholder_company_description')}
              aria-label='Brief description of company'
              textStyle='authText'
            />
          </>
        ) : (
          <>
            <Controller
              control={control}
              rules={{
                required: 'Please input your password',
                validate: Object.keys(passwordValidators).reduce((acc, cur) => {
                  return {
                    ...acc,
                    [cur]: passwordValidators[cur].validate,
                  }
                }, {}),
              }}
              name='password'
              aria-label='Password'
              textStyle='authText'
              render={({ value, onChange }) => (
                <Input
                  type={show ? 'text' : 'password'}
                  placeholder={_t('auth.register.placeholder_password')}
                  value={value}
                  onChange={onChange}
                  size='sm'
                  customErrorMessages={
                    <PasswordErrorMessages errors={errors.password} />
                  }
                  rightElement={
                    <Box
                      onClick={handleClick}
                      color='primary'
                      textTransform='uppercase'
                      cursor='pointer'
                      pointerEvents={'auto'}
                      fontSize={'0.7rem'}
                    >
                      {show ? _t('common.hide') : _t('common.show')}
                    </Box>
                  }
                />
              )}
            />
            <Controller
              control={control}
              rules={{
                required: _t(
                  'auth.register.validate_confirm_password_required'
                ),
                validate: {
                  matchPassword: value =>
                    value === getValues()['password'] ||
                    _t('auth.register.validate_confirm_password_invalid'),
                },
              }}
              name='confirmPassword'
              aria-label='Confirm Password'
              textStyle='authText'
              render={({ value, onChange }) => (
                <Input
                  type={showConfirm ? 'text' : 'password'}
                  placeholder={_t('auth.register.placeholder_confirm_password')}
                  value={value}
                  onChange={onChange}
                  size='sm'
                  error={errors.confirmPassword?.message}
                  rightElement={
                    <Box
                      onClick={handleClickConfirm}
                      color='primary'
                      textTransform='uppercase'
                      cursor='pointer'
                      pointerEvents={'auto'}
                      fontSize={'0.7rem'}
                    >
                      {showConfirm ? _t('common.hide') : _t('common.show')}
                    </Box>
                  }
                />
              )}
            />
          </>
        )}
        <Box
          display='flex'
          alignItems='center'
          justifyContent='space-between'
          my='1.5rem'
        >
          <Controller
            control={control}
            rules={{
              required: _t('auth.register.validate_agree_required'),
            }}
            name='agreement'
            render={({ onChange }) => (
              <Checkbox
                onChange={e => onChange(e.target.checked)}
                error={errors.agreement?.message}
                mr='1rem'
              >
                <Box as='p' fontWeight='600' textStyle='authText'>
                  {_t('auth.register.agree')}{' '}
                  <Link
                    color='primary'
                    isExternal
                    href={TERMS_URL}
                    _hover={{
                      color: 'primaryDarker',
                    }}
                    _focus={{
                      outline: 'none',
                    }}
                    textDecoration='underline'
                  >
                    {_t('common.terms')}
                  </Link>{' '}
                  &{' '}
                  <Link
                    color='primary'
                    isExternal
                    _hover={{
                      color: 'primaryDarker',
                    }}
                    _focus={{
                      outline: 'none',
                    }}
                    href={PRIVACY_URL}
                    textDecoration='underline'
                  >
                    {_t('common.policy')}
                  </Link>
                </Box>
              </Checkbox>
            )}
          />
        </Box>
      </VStack>

      {formError && (
        <Text textStyle='error' textAlign='center' mb='1rem'>
          {formError}
        </Text>
      )}

      <Button
        type='submit'
        width='100%'
        isLoading={loading}
        textTransform='uppercase'
      >
        {_t('auth.register.button_sign_up')}
      </Button>
    </Box>
  )
}

export default SignUpForm

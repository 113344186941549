export const EMAIL_PATTERN = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
export const MOBILE_NUMBER_PATTERN = /^\+?\d+([ -]?\d+)*$/

export const YOUTUBE_LINK_PATTERN = new RegExp(
  [
    /^(https?:\/\/)?(www\.)?youtu\.?be(\.com)?\//,
    /(\S*(watch|embed)(((?=\/[-a-zA-Z0-9_]{11,}(?!\S))\/)|(\S*v=|v\/)))?/,
    /[-a-zA-Z0-9_]{11,}(&\S*=\S*)*$/,
  ]
    .map(function (r) {
      return r.source
    })
    .join('')
)

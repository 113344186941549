import React, { useCallback, useEffect, useState } from 'react'

import { Box, Button, Grid } from '@chakra-ui/react'
import { compareAsc, format } from 'date-fns'

import { getSessionLink } from 'apis/classSession'
import { useLanguages, useLoading, useToast } from 'hooks'
import { CalendarClass, MeetingType, ReviewInfo } from 'types'
import { buildMeetingLink, getErrorMessage } from 'utils/parser'

interface UpcomingClassItemProps {
  data: CalendarClass
  onJoinSession?: (props: ReviewInfo) => void
}

const UpcomingClassItem: React.FC<UpcomingClassItemProps> = ({
  data,
  onJoinSession,
}) => {
  const toast = useToast()
  const { setLoading } = useLoading()
  const { _t } = useLanguages()
  const [disableJoinClass, setDisableJoinClass] = useState<boolean>(true)

  const { startDateTime, endDateTime, name } = data
  const timeStart = new Date(startDateTime)
  const timeEnd = new Date(endDateTime)
  const bgColor = '#fff'

  const handleJoin = useCallback(async () => {
    const fetchLink = async () => {
      try {
        setLoading(true)
        const res = await getSessionLink(data?.class?.id || '', data.id)

        if (res.data) {
          onJoinSession &&
            onJoinSession({
              tutorId: data?.tutor?.id || '',
              sessionId: data?.id || '',
              endDateTime: data?.endDateTime || 0,
            })

          const { meetingType, meetingLink } = res.data.data
          const link =
            meetingType === 'zoom'
              ? buildMeetingLink(res.data.data)
              : meetingLink
          const openLink = window.open(link, '_blank') as Window
          openLink.focus()
        }
      } catch (error) {
        toast({
          title: 'Error!',
          description: _t(`message.error.${getErrorMessage(error)}`),
          status: 'error',
        })
      } finally {
        setLoading(false)
      }
    }

    await fetchLink()
  }, [setLoading, data, onJoinSession, toast, _t])

  const checkJoinClassButton = useCallback(() => {
    const now = new Date()

    setDisableJoinClass(
      compareAsc(now, startDateTime - 1800000) === -1 ||
        compareAsc(now, endDateTime + 1800000) === 1
    )
  }, [endDateTime, startDateTime])

  useEffect(() => {
    checkJoinClassButton()

    const interval = setInterval(checkJoinClassButton, 10 * 1000)

    return () => {
      clearInterval(interval)
    }
  }, [checkJoinClassButton])

  return (
    <Box
      py='0.625rem'
      px='1.125rem'
      mx='0.5rem'
      mb='0.375rem'
      backgroundColor={bgColor}
      border='0.8px solid #DEDEDE'
      borderRadius='lg'
      boxShadow='lg'
    >
      <Grid gap='0.4rem' gridTemplateColumns='7fr 3fr' alignItems='center'>
        <Box>
          <Box as='p' fontSize='0.875rem'>
            {format(timeStart, 'h:mma')} - {format(timeEnd, 'h:mma')}
          </Box>
          <Box as='p' maxWidth='16rem'>
            {name}
          </Box>
        </Box>
        {data.meetingType !== MeetingType.PHYSICAL && (
          <Button
            disabled={disableJoinClass}
            variant='solidAction'
            onClick={handleJoin}
          >
            JOIN
          </Button>
        )}
      </Grid>
    </Box>
  )
}

export default UpcomingClassItem

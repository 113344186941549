import React, { useCallback, useState } from 'react'

import { useAtom } from 'jotai'

import useLanguages from './useLanguages'
import { getImportClassesUrl, importClassesFile } from 'apis/class'
import { uploadFile } from 'apis/upload'
import { classPath } from 'configs/apiPath'
import { useCurrentPage, useInvalidateUrl, useLoading, useToast } from 'hooks'
import { userProfileAtom } from 'store/authAtom'
import { Attachment } from 'types'
import { getErrorMessage } from 'utils/parser'

const getRoleType = (type: string) => {
  switch (type) {
    case 'MSTSuperAdmin':
    case 'MSTAdmin':
      return 'mst'
    case 'EnterpriseSuperAdmin':
    case 'EnterpriseAdmin':
    case 'CenterAdmin':
      return 'enterprise'
    case 'PrivateTutor':
      return 'tutor'
    default:
      return ''
  }
}

const useImportClassesFile = (onUpdateTotalClass?: (value: number) => void) => {
  const currentPage = useCurrentPage()
  const toast = useToast()
  const { setLoading } = useLoading()
  const { _t } = useLanguages()
  const [userProfile] = useAtom(userProfileAtom)
  const invalidate = useInvalidateUrl(
    classPath.list({ page: currentPage || 1, limit: 10 })
  )

  const [attachments, setAttachments] = useState<File[]>([])
  const [isError, setIsError] = useState<boolean>(false)
  const [errorData, setErrorData] = useState<string[]>([])
  const [isSettled, setIsSettled] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)

  const handleImportFile = useCallback(
    async (data: Attachment) => {
      try {
        setLoading(true)
        const url = await getImportClassesUrl(data)
        if (url.status === 201 && userProfile?.roles) {
          await uploadFile(data, url.data)
          const importResponse = await importClassesFile(
            getRoleType(userProfile.roles[0].type),
            url.data.data.fields.key
          )

          if (importResponse.status === 201) {
            setIsSuccess(true)
            onUpdateTotalClass &&
              onUpdateTotalClass(importResponse.data.data.length)
            invalidate()
          }
        }
      } catch (error) {
        const errorAny = error as any

        if (!errorAny.data || !errorAny.data.error) {
          toast({
            title: 'Error!',
            description: _t(`message.error.${getErrorMessage(error)}`),
            status: 'error',
          })
          return
        }

        setIsError(true)
        setIsSuccess(false)

        if (
          errorAny.data.error.message === 'InvalidDataExcel' &&
          errorAny.data.error.data
        ) {
          setErrorData(errorAny.data.error.data.map((err: any) => err.message))
          return
        }

        if (errorAny.data.error.message === 'InValidNameWorkSheet') {
          setErrorData([
            'Class information does not match the required data types. Please double check your input and try to reupload',
          ])
          return
        }

        if (errorAny.data.error.message) {
          setErrorData([errorAny.data.error.message])
          return
        }

        setErrorData([
          'Something went wrong, please follow the template and try again',
        ])
      } finally {
        setLoading(false)
        setIsSettled(true)
      }
    },
    [setLoading, userProfile, onUpdateTotalClass, invalidate, toast, _t]
  )

  return {
    handleImportFile,
    attachments,
    setAttachments,
    isError,
    setIsError,
    errorData,
    setErrorData,
    isSuccess,
    setIsSuccess,
    isSettled,
    setIsSettled,
  }
}

export default useImportClassesFile

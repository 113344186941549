import React, { useMemo } from 'react'

import { Avatar, Box, Flex, Grid, GridItem, Text } from '@chakra-ui/react'

import { useLanguages } from 'hooks'
import { UserProfile, UserRole } from 'types'
import { getDisplayName } from 'utils/parser'

import { ReactComponent as StarIcon } from '../../assets/images/star.svg'

const justifyConst = { base: 'center', md: 'flex-start' }
export interface ProfileHeaderInfoProps {
  data: UserProfile
  rightComponent?: React.ReactElement
}

const ProfileHeaderInfo: React.FC<ProfileHeaderInfoProps> = ({
  data,
  rightComponent,
}) => {
  const { _t } = useLanguages()

  const role = useMemo(() => data?.accountType?.name || '', [data])
  const accountTypes = useMemo(() => {
    switch (role) {
      case UserRole.Student:
        return _t('common.learner')
      case UserRole.Tutor:
        return _t('common.instructor')
      case UserRole.EnterpriseStudent:
        return _t('common.enterprise_learner')
      case UserRole.EnterpriseTutor:
        return _t('common.enterprise_instructor')
      case UserRole.CenterAdmin:
        return _t('common.center_admin')
      case UserRole.EnterpriseAdmin:
        return _t('common.enterprise_admin')
      case UserRole.MSTAdmin:
        return _t('common.knovo_admin')
      default:
        return _t('common.unknown')
    }
  }, [_t, role])

  return (
    <Grid
      templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(7, 1fr)' }}
      templateRows='repeat(3, 1fr)'
      pt='1rem'
      pb='1.5rem'
      rowGap='1rem'
    >
      <GridItem colSpan={1} rowSpan={3}>
        <Flex justifyContent='center' w='100%'>
          <Avatar
            size='2xl'
            name={getDisplayName(data.firstName || '', data.lastName || '')}
            src={data.avatar}
            key={data.avatar || 'default-avatar'}
          />
        </Flex>
      </GridItem>
      <GridItem colSpan={4} rowSpan={3} ml={{ base: 0, md: '1.5rem' }}>
        <Grid templateColumns='repeat(6)' templateRows='repeat(4)' gap={0.75}>
          <GridItem rowSpan={1} colSpan={6} justifySelf={justifyConst}>
            <Flex flexDirection='row'>
              <Box
                fontSize='0.85rem'
                fontWeight='600'
                textTransform='uppercase'
              >
                {accountTypes}
              </Box>
              {data?.rating && (
                <Flex ml='0.75rem' align='center'>
                  <StarIcon color='orange' height='20px' />
                  <Text fontSize='0.85rem' ml='0.2rem' color='primaryDarker'>
                    {data?.rating?.toFixed(1)}
                  </Text>
                </Flex>
              )}
            </Flex>
          </GridItem>
          <GridItem rowSpan={1} colSpan={6} justifySelf={justifyConst}>
            <Text
              as='h1'
              fontWeight='400'
              pr={{ base: '0', md: '2rem' }}
              textAlign={{ base: 'center', md: 'left' }}
            >
              {getDisplayName(data.firstName || '', data.lastName || '')}
            </Text>
          </GridItem>
          <GridItem rowSpan={1} colSpan={6} justifySelf={justifyConst}>
            {[UserRole.EnterpriseTutor, UserRole.Tutor].includes(
              role as UserRole
            ) ? (
              <Grid
                gridTemplateColumns='repeat(2, 1fr)'
                gap={3}
                textAlign={{ base: 'center', md: 'initial' }}
              >
                <Flex direction='column'>
                  <Text>{_t('user.header.sessions')}</Text>
                  {data.countCompletedSessions || '-'}
                </Flex>
                <Flex direction='column'>
                  <Text>{_t('user.header.years_of_experience')}</Text>
                  {data.yearOfExperience || '-'}
                </Flex>
              </Grid>
            ) : (
              <Flex
                direction='column'
                textAlign={{ base: 'center', md: 'initial' }}
              >
                <Text>{_t('user.header.connections')}</Text>
                {data.countConnections || '-'}
              </Flex>
            )}
          </GridItem>
        </Grid>
      </GridItem>
      <GridItem
        colSpan={2}
        rowSpan={3}
        justifySelf={{ base: 'center', md: 'right' }}
      >
        {rightComponent}
      </GridItem>
    </Grid>
  )
}

export default ProfileHeaderInfo

import React, { Suspense, useState } from 'react'

import { Box, Tab, TabList, TabPanels, TabProps, Tabs } from '@chakra-ui/react'

import { AlertProtect, LoadingSpinner } from 'components'
import { useLanguages } from 'hooks'
import {
  ConnectedCentre,
  ConnectedLearner,
  ConnectedPending,
  ConnectedTutor,
} from 'shared/containers'

export const tabClassStyle: TabProps = {
  height: '60px',
  textColor: 'black',
  textStyle: 'subHeadingModalText',
  _focus: { outline: 'none' },
  _selected: { backgroundColor: 'rgba(57, 88, 191, 0.1)' },
  _active: {
    background: 'none',
    borderBottom: 'none',
    outline: 'none',
  },
  background: 'white',
}

export interface ProfileConnectionsProps {
  userId: string
  isInstructor?: boolean
  tabData?: any
}

const ProfileConnections = ({
  userId,
  isInstructor = false,
  tabData,
}: ProfileConnectionsProps) => {
  const { _t } = useLanguages()
  // ** States
  const [tab, setTab] = useState<number>(0)
  const [tabs] = useState(tabData)

  // ** Events
  const handleTabChange = React.useCallback((val: any) => {
    setTab(val)
  }, [])

  const renderTabs = () => {
    return (
      <>
        <TabList bg='rgba(255, 255, 255, 0.6)'>
          {tabs.map((item: any, index: number) => (
            <Tab key={index} {...tabClassStyle} flex='1'>
              {_t(item.label) === item.label ? item.label : _t(item.label)}
            </Tab>
          ))}
        </TabList>
      </>
    )
  }

  return (
    <Box as='div'>
      <AlertProtect text={_t('profile.alert.connection')} />
      <Tabs isFitted variant='unstyled' onChange={handleTabChange} index={tab}>
        {renderTabs()}
        <TabPanels>
          <Box pt='1.5rem'>
            <Suspense fallback={<LoadingSpinner />}>
              {isInstructor ? (
                <>
                  {tab === 0 && <ConnectedLearner userId={userId} />}
                  {tab === 1 && <ConnectedTutor userId={userId} />}
                  {tab === 2 && <ConnectedCentre userId={userId} />}
                  {tab === 3 && <ConnectedPending userId={userId} />}
                </>
              ) : (
                <>
                  {tab === 0 && <ConnectedTutor userId={userId} />}
                  {tab === 1 && <ConnectedCentre userId={userId} />}
                  {tab === 2 && <ConnectedPending userId={userId} />}
                </>
              )}
            </Suspense>
          </Box>
        </TabPanels>
      </Tabs>
    </Box>
  )
}

export default ProfileConnections

import { lazy } from 'react'

const SignIn = lazy(() => import(/* webpackChunkName: "sign-in" */ './SignIn'))
const SignUp = lazy(() => import(/* webpackChunkName: "sign-up" */ './SignUp'))
const ResetPassword = lazy(
  () => import(/* webpackChunkName: "reset-password" */ './ResetPassword')
)
const ForgotPassword = lazy(
  () => import(/* webpackChunkName: "forget-password" */ './ForgotPassword')
)
const ForgotEmail = lazy(
  () => import(/* webpackChunkName: "forget-email" */ './ForgotEmail')
)

const Dashboard = lazy(
  () => import(/* webpackChunkName: "dashboard" */ './Dashboard')
)
const LearnerCourses = lazy(
  () => import(/* webpackChunkName: "learner courses" */ './Learner/MyCourses')
)
const LearnerPreviewRequest = lazy(
  () =>
    import(
      /* webpackChunkName: "learner preview request" */ './Learner/MyCourses/Request/RequestDetail'
    )
)
const LearnerPreviewAssignment = lazy(
  () =>
    import(
      /* webpackChunkName: "learner preview assignment" */ './Learner/MyCourses/Assignment/AssignmentDetail'
    )
)
const LearnerInstructorView = lazy(
  () =>
    import(
      /* webpackChunkName: "instructor_detail" */ './Learner/InstructorProfile'
    )
)
const LearnerCentreProfileView = lazy(
  () =>
    import(
      /* webpackChunkName: "learner view centre profile" */ './Learner/CentreProfile'
    )
)
const LearnerProfile = lazy(
  () => import(/* webpackChunkName: "learner-profile" */ './Learner/MyProfile')
)
const LearnerEditProfile = lazy(
  () =>
    import(
      /* webpackChunkName: "learner-edit-profile" */ './Learner/MyProfile/EditProfile'
    )
)
const LearnerWallet = lazy(
  () => import(/* webpackChunkName: "learner-wallet" */ './Learner/Wallet')
)
const LearnerPreviewClass = lazy(
  () =>
    import(
      /* webpackChunkName: "learner preview class" */ './Learner/Product/Class'
    )
)
const LearnerPreviewContent = lazy(
  () =>
    import(
      /* webpackChunkName: "learner preview content" */ './Learner/Product/Content'
    )
)
const LearnerPreviewAdhocClass = lazy(
  () =>
    import(
      /* webpackChunkName: "learner preview adhoc class" */ './Learner/Product/AdhocClass'
    )
)
const LearnerPreviewLesson = lazy(
  () =>
    import(
      /* webpackChunkName: "learner preview lesson" */ './Learner/Product/Lesson'
    )
)
const LearnerNotifications = lazy(
  () =>
    import(
      /* webpackChunkName: "learner-notifications" */ './Learner/Notifications'
    )
)

const Explore = lazy(
  () => import(/* webpackChunkName: "explore" */ './Explore')
)

const InstructorCourses = lazy(
  () =>
    import(
      /* webpackChunkName: "instructor courses" */ './Instructor/MyCourses'
    )
)
const InstructorPreviewAssignment = lazy(
  () =>
    import(
      /* webpackChunkName: "instructor preview assignment" */ './Instructor/MyCourses/Assignment/AssignmentDetail'
    )
)
const InstructorPreviewClass = lazy(
  () =>
    import(
      /* webpackChunkName: "instructor preview class" */ './Instructor/Product/Class/Preview'
    )
)
const InstructorPreviewContent = lazy(
  () =>
    import(
      /* webpackChunkName: "instructor preview content" */ './Instructor/Product/Content/Preview'
    )
)
const InstructorPreviewAdhocClass = lazy(
  () =>
    import(
      /* webpackChunkName: "instructor preview adhoc class" */ './Instructor/Product/AdhocClass'
    )
)
const InstructorPreviewDraftClass = lazy(
  () =>
    import(
      /* webpackChunkName: "instructor preview draft class" */ './Instructor/Draft/Class/Preview'
    )
)
const InstructorPreviewLesson = lazy(
  () =>
    import(
      /* webpackChunkName: "instructor preview lesson" */ './Instructor/Product/Lessons/Preview'
    )
)
const InstructorCreateClass = lazy(
  () =>
    import(
      /* webpackChunkName: "instructor create class" */ './Instructor/Product/Class/Create'
    )
)
const InstructorCreateContent = lazy(
  () =>
    import(
      /* webpackChunkName: "instructor create content" */ './Instructor/Product/Content/Create'
    )
)
const InstructorCreateLesson = lazy(
  () =>
    import(
      /* webpackChunkName: "instructor create lesson" */ './Instructor/Product/Lessons/Create'
    )
)
const InstructorEditClass = lazy(
  () =>
    import(
      /* webpackChunkName: "instructor edit class" */ './Instructor/Product/Class/Edit'
    )
)
const InstructorEditContent = lazy(
  () =>
    import(
      /* webpackChunkName: "instructor edit content" */ './Instructor/Product/Content/Edit'
    )
)
const InstructorEditDraftClass = lazy(
  () =>
    import(
      /* webpackChunkName: "instructor edit draft class" */ './Instructor/Draft/Class/Edit'
    )
)
const InstructorViewProfile = lazy(
  () =>
    import(
      /* webpackChunkName: "instructor-profile" */ './Instructor/MyProfile'
    )
)
const InstructorEditProfile = lazy(
  () =>
    import(
      /* webpackChunkName: "instructor-edit-profile" */ './Instructor/MyProfile/EditProfile'
    )
)
const InstructorNotifications = lazy(
  () =>
    import(
      /* webpackChunkName: "instructor-notification" */ './Instructor/Notifications'
    )
)
const InstructorInstructorView = lazy(
  () =>
    import(
      /* webpackChunkName: "instructor_detail" */ './Instructor/InstructorProfile'
    )
)
const CreateProductLanding = lazy(
  () =>
    import(
      /* webpackChunkName: "create product landing" */ './Instructor/Product/CreateLanding'
    )
)
const InstructorWallet = lazy(
  () => import(/* webpackChunkName: "wallet" */ './Instructor/Wallet')
)
const SearchResult = lazy(
  () => import(/* webpackChunkName: "search_result" */ './SearchResult')
)

const ChatBox = lazy(
  () => import(/* webpackChunkName: "chat_box" */ './ChatBox')
)
const InstructorLearnerView = lazy(
  () =>
    import(
      /* webpackChunkName: "learner_public_profile" */ './Instructor/LearnerProfile'
    )
)
const Meeting = lazy(
  () => import(/* webpackChunkName: "meeting" */ './Meeting')
)

export {
  SignIn,
  SignUp,
  ForgotPassword,
  ForgotEmail,
  Dashboard,
  Explore,
  LearnerCourses,
  LearnerPreviewRequest,
  LearnerWallet,
  LearnerPreviewClass,
  LearnerPreviewContent,
  LearnerPreviewAdhocClass,
  LearnerPreviewLesson,
  LearnerPreviewAssignment,
  LearnerNotifications,
  LearnerInstructorView,
  LearnerCentreProfileView,
  LearnerProfile,
  LearnerEditProfile,
  InstructorCourses,
  InstructorPreviewAssignment,
  InstructorViewProfile,
  InstructorEditProfile,
  InstructorPreviewClass,
  InstructorPreviewContent,
  InstructorPreviewAdhocClass,
  InstructorPreviewDraftClass,
  InstructorPreviewLesson,
  InstructorCreateClass,
  InstructorCreateContent,
  InstructorCreateLesson,
  InstructorEditClass,
  InstructorEditContent,
  InstructorEditDraftClass,
  CreateProductLanding,
  InstructorWallet,
  InstructorNotifications,
  InstructorLearnerView,
  InstructorInstructorView,
  SearchResult,
  ChatBox,
  ResetPassword,
  Meeting,
}

import React, { ChangeEvent, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'

import { Box } from '@chakra-ui/react'

import { DetailEditSection } from '../containers'
import { AvatarBannerGroup, HeadingSection, Option } from 'components'
import { useLanguages } from 'hooks'
import { useAddNewEnterprise } from 'queries/enterprise'
import { UserRole, UserRoleType } from 'types'
import { responsivePadding } from 'utils/constant'

interface CreateEnterpriseProfileContainerProps {
  backLabel: string
  handleToBack: () => void
  role?: UserRoleType
}

export interface CreateEnterpriseForm {
  name: string
  email: string
  active: boolean
  contactNumber: string
  address1: string
  country?: Option
  city: string
  zipCode: string
}

const CreateEnterpriseProfileContainer: React.FC<CreateEnterpriseProfileContainerProps> = ({
  backLabel,
  handleToBack,
  role,
}) => {
  const { _t } = useLanguages()
  const [avatar, setAvatar] = useState<string>()
  const [avatarFile, setAvatarFile] = useState<File>()
  const [banner, setBanner] = useState<string>()
  const [bannerFile, setBannerFile] = useState<File>()

  const isSuperAdmin = useMemo(
    () => role === UserRole.MSTSuperAdmin || role === UserRole.MSTAdmin,
    [role]
  )

  const {
    handleSubmit,
    control,
    formState: { dirtyFields },
  } = useForm<CreateEnterpriseForm>({
    defaultValues: {
      name: '',
      email: '',
      active: true,
      contactNumber: '',
      address1: '',
      country: { value: '', label: '' },
      city: '',
      zipCode: '',
    },
  })

  const { mutate: addEnterprise } = useAddNewEnterprise()

  const handleResetFile = () => {
    setAvatarFile(undefined)
    setBannerFile(undefined)
  }

  const handleAddNewEnterprise = (data: CreateEnterpriseForm) => {
    let newData = Object.assign({}, data)
    delete newData.country
    const formattedData = {
      ...newData,
      countryId: data.country ? data.country.value : '',
    }
    addEnterprise({
      data: formattedData,
      avatarFile: avatarFile,
      bannerFile: bannerFile,
      resetFile: handleResetFile,
      onClose: handleToBack,
    })
  }

  const handleUpdateAvatar = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0]
    if (file) {
      setAvatar(URL.createObjectURL(file))
      setAvatarFile(file)
    }
  }

  const handleUpdateBanner = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0]
    if (file) {
      setBanner(URL.createObjectURL(file))
      setBannerFile(file)
    }
  }

  const enableCreateBtn = () => {
    if (
      dirtyFields &&
      dirtyFields.hasOwnProperty('name') &&
      dirtyFields.hasOwnProperty('email')
    ) {
      return false
    }
    return true
  }

  return (
    <>
      <HeadingSection
        title={_t('enterprise.profile.add_title')}
        backLabel={backLabel}
        handleToBack={handleToBack}
        buttonData={[
          {
            label: _t('common.create'),
            isDisabled: enableCreateBtn(),
            onClick: handleSubmit(handleAddNewEnterprise),
          },
          {
            label: _t('common.cancel'),
            isSecondary: true,
            onClick: handleToBack,
          },
        ]}
        isAdminStyle
      />
      <Box pb='2rem'>
        <AvatarBannerGroup
          avatar={avatar}
          banner={banner}
          handleUpdateAvatar={handleUpdateAvatar}
          handleUpdateBanner={handleUpdateBanner}
        />
        <Box px={responsivePadding}>
          <Box mt='1.5rem'>
            <DetailEditSection
              control={control}
              isAllowUpdateStatus={isSuperAdmin}
            />
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default CreateEnterpriseProfileContainer

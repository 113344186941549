import React, { useEffect } from 'react'
import { Column, Row, useFlexLayout, useRowSelect, useTable } from 'react-table'
import { useSticky } from 'react-table-sticky'

import { Box, Flex } from '@chakra-ui/react'

import { LoadingSpinner } from 'components'

interface TableProps<T extends object> {
  columns: Column<T>[]
  data: T[]
  loading: boolean
  colorHead?: string
  onSelectRows?: (rowIds: T[]) => void
  colorScheme?: string
  disabledIds?: string[]
  initialState?: object
}

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }: any, ref) => {
    const defaultRef = React.useRef()
    const resolvedRef = (ref || defaultRef) as any

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])

    return (
      <input
        // className='table__user-selectionInput'
        type='checkbox'
        ref={resolvedRef}
        {...rest}
      />
    )
  }
)

function Table<T extends object>({
  columns,
  data,
  loading,
  onSelectRows,
  colorScheme = 'primaryDarker',
  disabledIds = [],
  initialState = {},
}: TableProps<T>) {
  const defaultColumn = React.useMemo(
    () => ({
      width: 165,
    }),
    []
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
  } = useTable<T>(
    { columns: columns, data: data, defaultColumn, initialState },
    useFlexLayout,
    useRowSelect,
    useSticky,
    hooks => {
      if (onSelectRows) {
        // @ts-ignore
        const sticky = columns[0].sticky === 'left' ? 'left' : null
        hooks.visibleColumns.push(fields => [
          // Let's make a column for selection
          {
            id: 'selection',
            // The header can use the table's getToggleAllRowsSelectedProps method
            // to render a checkbox
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            ),
            // The cell can use the individual row's getToggleRowSelectedProps method
            // to the render a checkbox
            Cell: ({ row }: { row: Row<T> }) => {
              const disabled = disabledIds.includes((row.original as any).id)
              const tmpProps = row.getToggleRowSelectedProps()
              return (
                <Flex align='center' h='full'>
                  <IndeterminateCheckbox
                    {...tmpProps}
                    disabled={disabled}
                    checked={!disabled && tmpProps.checked}
                  />
                </Flex>
              )
            },
            sticky,
            width: '55',
          },
          ...fields,
        ])
      }
    }
  )

  useEffect(() => {
    if (onSelectRows) {
      const selectedRows = selectedFlatRows
        .map(d => d.original)
        .filter(o => !disabledIds.includes((o as any)?.id))

      onSelectRows(selectedRows)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFlatRows, onSelectRows])

  //@ts-ignore
  const hasSticky = !!columns[0].sticky

  return (
    <Box
      w='100%'
      {...getTableProps()}
      className='sticky'
      overflow='auto'
      sx={{
        '[data-sticky-td]': {
          position: 'sticky',
        },
        '[data-sticky-last-left-td]': {
          boxShadow: '2px 0px 3px #ccc',
        },

        '[data-sticky-first-right-td]': {
          boxShadow: '-2px 0px 3px #ccc',
        },
      }}
    >
      <Box>
        {headerGroups.map(headerGroup => (
          <Flex {...headerGroup.getHeaderGroupProps()} width='100%'>
            {headerGroup.headers.map(column => (
              <Box
                py='1.25rem'
                px='1rem'
                fontSize='0.875rem'
                bgColor={colorScheme}
                color='#fff'
                _first={{
                  borderTopLeftRadius: '0.5rem',
                  pl: '2rem',
                }}
                _last={{
                  borderTopRightRadius: '0.5rem',
                  pr: '2rem',
                }}
                {...column.getHeaderProps({
                  // @ts-ignore
                  className: column.className,
                })}
              >
                {column.render('Header')}
              </Box>
            ))}
          </Flex>
        ))}
      </Box>
      <Box {...getTableBodyProps()} position='relative'>
        {loading ? (
          <LoadingSpinner />
        ) : (
          rows.map((row, rowIndex) => {
            const isLastRow = rowIndex === rows.length - 1
            prepareRow(row)
            return (
              <Box {...row.getRowProps()} width='100%'>
                {row.cells.map(cell => {
                  return (
                    <Box
                      textStyle='tableRow'
                      bgColor='tBody'
                      py='1.25rem'
                      px='1rem'
                      borderBottom='1px solid'
                      borderColor='gray.200'
                      _first={{
                        borderBottomLeftRadius: isLastRow ? '0.5rem' : '0',
                        pl: '2rem',
                      }}
                      _last={{
                        borderBottomRightRadius: isLastRow ? '0.5rem' : '0',
                        pr: '2rem',
                      }}
                      {...cell.getCellProps({
                        // @ts-ignore
                        className: cell.column.className,
                      })}
                    >
                      {cell.render('Cell')}
                    </Box>
                  )
                })}
              </Box>
            )
          })
        )}
      </Box>
    </Box>
  )
}

export default Table

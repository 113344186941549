import React from 'react'
import { useNavigate } from 'react-router-dom'

import { Avatar, Box, Button, Divider, Flex, Text } from '@chakra-ui/react'
import format from 'date-fns/format'

import { useLanguages } from 'hooks'
import { Assignment, LearnerAssignment } from 'types'
import { getFullName } from 'utils/parser'

import { ReactComponent as AttachmentIcon } from '../../assets/images/attachment_icon.svg'
import { ReactComponent as StarIcon } from '../../assets/images/star.svg'

interface AssignmentItemProps extends Assignment {
  data: LearnerAssignment
}

const AssignmentItem: React.FC<AssignmentItemProps> = ({
  data,
  status = 'current',
}) => {
  const { _t } = useLanguages()
  const navigate = useNavigate()

  const handleViewDetail = () => {
    navigate(`/my-courses/assignments/${data.id}`)
  }

  return (
    <Flex
      direction='column'
      p='1.5rem'
      bgColor='#fff'
      boxShadow='lg'
      rounded='lg'
    >
      <Box>
        <Text fontSize='1.125rem' fontWeight='600'>
          {data.name}
        </Text>
        <Flex justifyContent='space-between' mt='0.5rem' fontSize='0.75rem'>
          <Box>
            <Text color={status === 'overdue' ? 'overdue' : 'inherit'}>
              {format(data.deadline, 'dd MMM yyyy, h:mma')}
            </Text>
          </Box>
          <Flex alignItems='center'>
            <AttachmentIcon />
            <Box ml='0.5rem'>
              {`${data.totalAttachments} ${_t('common.attachment')}${
                data.totalAttachments > 1 ? 's' : ''
              }`}
            </Box>
          </Flex>
        </Flex>
      </Box>

      <Divider my='1rem' color='gray.500' />

      <Flex>
        <Flex flexDirection='column' alignItems='center' flexShrink={0}>
          <Avatar
            size='sm'
            src={data.user.avatar}
            name={getFullName(data.user)}
            key={data.user.avatar || 'defaultAvatar'}
          />
          {data.user.rating && (
            <Flex>
              <StarIcon />
              <Text ml='6px' fontSize='0.625rem' color='primary'>
                {data.user.rating}
              </Text>
            </Flex>
          )}
        </Flex>

        <Box ml='0.875rem'>
          <Text fontSize='0.75rem' whiteSpace='nowrap'>
            {getFullName(data.user)}
          </Text>
          {data.user.center && (
            <Text fontSize='0.625rem' fontWeight='600'>
              {data.user.center.name}
            </Text>
          )}
        </Box>
      </Flex>
      {data.sessions[0] && (
        <Box mt='0.5rem'>
          <Text
            fontSize='0.625rem'
            color='primary'
            title={data.sessions[0].id}
            noOfLines={1}
          >
            {_t('assignment.item.session_id')}: {data.sessions[0].id}
          </Text>
          <Text fontSize='0.75rem' noOfLines={1}>
            {data.sessions[0].name}
          </Text>
        </Box>
      )}

      <Box pt='1rem' mt='auto' textAlign='right'>
        <Button
          size='md'
          variant='solidPrimary'
          textTransform='uppercase'
          onClick={handleViewDetail}
        >
          {_t('assignment.item.view')}
        </Button>
      </Box>
    </Flex>
  )
}

export default AssignmentItem

import { useMutation, useQuery } from 'react-query'

import { useToastError } from './helpers'
import { createPromo, deletePromo, updatePromo } from 'apis/promo'
import { promoPath } from 'configs/apiPath'
import { useLanguages, useLoading, useToast } from 'hooks'
import {
  FilterPromoOption,
  ListingParams,
  Meta,
  Promo,
  PromoDetail,
  UserPromoDetail,
} from 'types'

type PromoQuery = {
  data: Promo[]
} & Meta

type UserPromoQuery = {
  data: UserPromoDetail[]
} & Meta

export const usePromoCodes = (params: ListingParams & FilterPromoOption) => {
  return useQuery<PromoQuery>(promoPath.list(params))
}

export const usePromoCodeDetail = (promoId: string) => {
  return useQuery<{ data: PromoDetail }>(promoPath.detail(promoId), {
    enabled: !!promoId,
  })
}

export const useUserGetPromoCodes = (data: ListingParams) => {
  return useQuery<UserPromoQuery>(promoPath.userList(data))
}

export const useCreatePromo = () => {
  const toast = useToast()
  const { setLoading } = useLoading()
  const { _t } = useLanguages()

  return useMutation(createPromo, {
    onMutate: () => setLoading(true),
    onSettled: () => setLoading(false),
    onSuccess: () => {
      toast({
        title: 'Success!',
        description: _t('message.success.promo_code_add'),
        status: 'success',
      })
    },
    onError: useToastError(),
  })
}

export const useUpdatePromo = () => {
  const toast = useToast()
  const { setLoading } = useLoading()
  const { _t } = useLanguages()

  return useMutation(updatePromo, {
    onMutate: () => setLoading(true),
    onSettled: () => setLoading(false),
    onSuccess: () => {
      toast({
        title: 'Success!',
        description: _t('message.success.promo_code_update'),
        status: 'success',
      })
    },
    onError: useToastError(),
  })
}

export const useDeletePromo = ({
  onInvalidateUrl,
}: {
  onInvalidateUrl?: () => void
}) => {
  const toast = useToast()
  const { setLoading } = useLoading()
  const { _t } = useLanguages()

  return useMutation(deletePromo, {
    onMutate: () => setLoading(true),
    onSettled: () => setLoading(false),
    onSuccess: () => {
      onInvalidateUrl && onInvalidateUrl()
      toast({
        title: 'Success!',
        description: _t('message.success.promo_code_remove'),
        status: 'success',
      })
    },
    onError: useToastError(),
  })
}

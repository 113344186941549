import { Attachment } from './assignment'
import { Centre } from './centre'
import { Country } from './country'
import { Language } from './multilingualism'
import { Role } from './role'
import { Subject } from './subject'
import { System } from './system'
import { TeachingLevel } from './teachingLevel'
import { CredentialDetailForm } from 'shared/containers/User/containers/forms'
import { Enterprise } from 'types'

export interface User {
  id: string
  username: string
  attributes: {
    email: string
    email_verified: boolean
    sub: string
  }
}

export enum Login {
  UserName = 'username',
  Guest = 'guest',
  Social = 'social',
}

export type LoginType = Login.UserName | Login.Guest | Login.Social

export enum UserRole {
  Student = 'Student',
  Tutor = 'PrivateTutor',
  Enterprise = 'Enterprise',
  MSTSuperAdmin = 'MSTSuperAdmin',
  MSTAdmin = 'MSTAdmin',
  EnterpriseSuperAdmin = 'EnterpriseSuperAdmin',
  EnterpriseAdmin = 'EnterpriseAdmin',
  EnterpriseTutor = 'EnterpriseTutor',
  EnterpriseStudent = 'EnterpriseStudent',
  CenterAdmin = 'CenterAdmin',
  AdminManageStudent = 'student',
  AdminManageTutor = 'tutor',
  AdminManageEnterpriseAdmin = 'enterpriseAdmin',
  AdminManageCenterAdmin = 'centerAdmin',
  AdminManageAdmin = 'mstSuperAdmin',
}

export enum UserType {
  Student = 'Student',
  Tutor = 'Tutor',
}

export type UserRoleType =
  | UserRole.Student
  | UserRole.Tutor
  | UserRole.Enterprise
  | UserRole.MSTSuperAdmin
  | UserRole.MSTAdmin
  | UserRole.AdminManageStudent
  | UserRole.AdminManageTutor
  | UserRole.AdminManageEnterpriseAdmin
  | UserRole.EnterpriseSuperAdmin
  | UserRole.EnterpriseAdmin
  | UserRole.EnterpriseTutor
  | UserRole.EnterpriseStudent
  | UserRole.CenterAdmin
  | UserRole.AdminManageCenterAdmin

export interface AccountType {
  id: string
  name: string
}

type EducationalLevel = {
  country?: Country
  system?: System
  teachingLevel?: TeachingLevel
  startDateTime?: number
  endDateTime?: number
  isCompleted?: boolean
}

export interface CredentialDetail {
  id: string
  name: string
  issueEnterpriseId?: string
  issueName?: string
  issueDate?: number
  expiryDate?: number
  referenceId?: string
  referenceUrl?: string
  attachmentKey?: string
  attachment?: Attachment
}

// when created user might not have profile => might not have id
export interface UserProfile {
  id?: string
  email?: string
  name?: string
  firstName?: string
  lastName?: string
  middleName?: string
  gender?: string
  birthday?: number
  contactNumber?: string
  description?: string
  countryOfResidence?: string
  nationality?: string
  educationLevel?: string
  yearOfExperience?: number
  youtubeLink?: string
  educationQualification?: string
  address1?: string
  address2?: string
  city?: string
  state?: string
  country?: string
  zipCode?: string
  recoveryEmail?: string
  avatar?: string
  roles?: Role[]
  subjectCombinationIds?: string[]
  languages?: Language[]
  subjects?: string[]
  accountType?: AccountType
  accountTypes?: AccountType[]
  centers?: Centre[]
  educationSystem?: EducationalLevel
  createdAt?: number
  centerId?: string
  attachments?: Attachment[]
  confirmationStatus?: string
  accountStatus?: boolean
  enterprises?: Enterprise[]
  rating?: number
  countConnections?: number
  countCompletedSessions?: number
  credentials?: CredentialDetail[]
  center?: {
    id: string
    name: string
  }
}

export interface UserEditForm
  extends Omit<
    UserProfile,
    'languages' | 'subjectCombinationIds' | 'credentials'
  > {
  centerId?: string
  enterpriseId?: string
  languages?: { opt: string; id: string }[]
  subjectCombinations?: { opt: string; id: string }[]
  credentials?: CredentialDetailForm[]
}

export interface UserProfileRef {
  getData: () => any
}

export interface CredentialRef {
  upload: (userId: string) => Promise<void>
  getRemoveCredential: () => CredentialDetailForm[]
}

export interface UserSubjectCombination {
  id: string
  country: Country
  system: System
  subject: Subject
  teachingLevels: TeachingLevel[]
}

export interface EducationSystem {
  countryId?: string
  systemId?: string
  teachingLevelId?: string
  startDateTime?: number
  endDateTime?: number
  isCompleted?: boolean
}

export interface AddUserData {
  email: string
  roleName: UserRoleType
  centerId?: string
  enterpriseId?: string
  educationSystem?: EducationSystem

  firstName?: string
  lastName?: string
  description?: string
  yearOfExperience?: number
  youtubeLink?: string

  birthday?: number
  gender?: string
  countryOfResidence?: string
  nationality?: string
  languages?: { opt: string; id: string }[]
  recoveryEmail?: string

  educationQualification?: string

  address1?: string
  address2?: string
  city?: string
  country?: string
  zipCode?: string
  subjectCombinations?: { id: string; opt: string }[]
}

export interface RequestAccount {
  type: 'enterprise'
  firstName: string
  lastName: string
  email: string
  contactNumber: string
  companyName: string
  companyDescription: string
}

export interface UserExportUrl {
  url: string
}

export interface UserExportRequest {
  order?: string
  orderBy?: string
  fileType?: string
  type?: string
  centerId?: string
  enterpriseId?: string
  userType: string
}

export interface UserExportResponse {
  data: UserExportUrl
}

import React from 'react'

import { Box, Flex, Image, Text } from '@chakra-ui/react'

import storageKey from 'configs/storageKey'
import { getData } from 'utils/helper'

interface GameCardProps {
  title: string
  description: string
  route: string
  thumbnail: string
  onClick: () => void
}

const GameCard: React.FC<GameCardProps> = ({
  title,
  description,
  route,
  thumbnail,
  onClick,
}) => {
  const isGuest = getData(storageKey.loginType) === 'guest'

  return (
    <Box
      cursor={route === 'wheel' && isGuest ? 'not-allowed' : 'pointer'}
      mt={['1rem', '1rem', '2rem']}
      onClick={route === 'wheel' && isGuest ? undefined : onClick}
    >
      <Flex
        w='100%'
        h='100%'
        maxH='23.5rem'
        p={{ base: '2.5rem', md: '2.5rem 2.5rem 3.5rem 2.5rem' }}
        bg={route === 'wheel' && isGuest ? '#8A8A8A' : '#FFF'}
        boxShadow='lg'
        rounded='lg'
        flexDirection='column'
      >
        <Flex w='100%' justifyContent='center' direction='column'>
          <Flex direction='column' textAlign='center'>
            <Text as='h2' fontWeight='semibold'>
              {title}
            </Text>
            <Text>{description}</Text>
          </Flex>
          <Flex justifyContent='center' alignItems='center' mt='1.5rem'>
            <Image w='40%' h='auto' src={thumbnail} alt={title} />
          </Flex>
        </Flex>
      </Flex>
    </Box>
  )
}

export default GameCard

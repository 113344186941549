import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'

import EditPaymentDetailsSection from '../../containers/EditPaymentDetailsSection'
import EditProfileContainer from '../../containers/EditProfile'
import EditSubscriptionSection from '../../containers/EditSubscriptionSection'
import ProfileLayout from '../../containers/ProfileLayout'
import { AvatarRef, LoadingSpinner, TeachingSubjectSection } from 'components'
import { useLanguages, useQueryParams } from 'hooks'
import { useUpdateUser, useUserDetail } from 'queries/user'
import { CredentialRef, TabRef, UserEditForm, UserProfileRef } from 'types'
import { getErrorMessage } from 'utils/parser'
import { uploadCredentials, uploadUserAvatar } from 'utils/upload'

interface AdminEditInstructorContainerProps {
  handleCancel?: () => void
}

type TabId = 'profile' | 'paymentDetail' | 'subscriptions'

const AdminEditInstructorContainer: React.FC<AdminEditInstructorContainerProps> = ({
  handleCancel,
}) => {
  const { _t } = useLanguages()
  const query = useQueryParams()
  const params = useParams()
  const [, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<string>()

  const privateTabData = (id: string) => [
    {
      id: 'paymentDetail',
      title: 'user.tab.payment_details',
      path: `/admin/user-management/instructor/${id}?tab=paymentDetail`,
    },
    {
      id: 'subscriptions',
      title: 'user.tab.subscriptions',
      path: `/admin/user-management/instructor/${id}?tab=subscriptions`,
    },
  ]

  const tabData = (id: string) => {
    const extra = isPrivate ? privateTabData(id) : []

    return [
      {
        id: 'profile',
        title: 'user.tab.profile',
        path: `/admin/user-management/instructor/${id}?tab=profile`,
      },
      ...extra,
    ]
  }

  const currentTab = useMemo<TabId>(() => (query.tab || 'profile') as TabId, [
    query,
  ])
  const userId = useMemo(() => params.id, [params])

  const subscriptionRef = useRef<TabRef>()
  const paymentDetailRef = useRef<TabRef>()
  const { data: userProfile } = useUserDetail(userId)

  const profileData = userProfile?.data
  const userRef = useRef<UserProfileRef>()
  const credentialRef = useRef<CredentialRef>()
  const avatarRef = useRef<AvatarRef>()

  const isPrivate =
    userProfile?.data?.accountType?.name?.includes('Private') || false

  const { mutate: updateUser, isSuccess } = useUpdateUser(userId)

  useEffect(() => {
    if (isSuccess) {
      setLoading(false)
    }
  }, [setLoading, isSuccess])

  const handleSave = async () => {
    if (currentTab === 'profile') {
      try {
        await uploadUserAvatar(avatarRef, userId)
        await uploadCredentials(credentialRef, userId)

        const formattedData = (await userRef.current?.getData()) as UserEditForm
        if (!formattedData) {
          return
        }

        updateUser({ userId, data: formattedData })
      } catch (err) {
        setError(getErrorMessage(err))
      } finally {
        return
      }
    }

    if (currentTab === 'subscriptions') {
      return subscriptionRef.current?.submit()
    }

    if (currentTab === 'paymentDetail') {
      return paymentDetailRef.current?.submit()
    }
  }

  return (
    <ProfileLayout
      title={_t('user_management.instructor.edit_title')}
      backLabel={_t('user_management.instructor.title')}
      handleCancel={handleCancel}
      handleSubmit={handleSave}
      tabData={tabData(userId)}
      currentTab={currentTab}
      isAdminView
    >
      {userProfile ? (
        <>
          {currentTab === 'profile' && profileData && (
            <EditProfileContainer
              userId={userId}
              profileData={profileData}
              avatarRef={avatarRef}
              credentialRef={credentialRef}
              userRef={userRef}
              subjectSection={
                <TeachingSubjectSection
                  userId={userId}
                  centerId={profileData.centers?.[0].id}
                  isAdmin
                />
              }
              toastError={error}
              setToastError={setError}
              isInstructor
              isPrivate={isPrivate}
              isAdmin
            />
          )}
          {currentTab === 'paymentDetail' && (
            <EditPaymentDetailsSection
              userId={userId}
              ref={paymentDetailRef as React.Ref<TabRef>}
            />
          )}
          {currentTab === 'subscriptions' && (
            <EditSubscriptionSection
              instructorId={userId}
              ref={subscriptionRef as React.Ref<TabRef>}
            />
          )}
        </>
      ) : (
        <LoadingSpinner />
      )}
    </ProfileLayout>
  )
}

export default AdminEditInstructorContainer

import Axios from 'axios'

import { FileUrl, UploadFileUrl } from 'types'

export const uploadFileAPI = (url: string, formData: FormData) => {
  return Axios.post(url, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      'No-Need-Authorization': true,
    },
  })
}

export const uploadFile = async (file: File, uploadFileUrl: UploadFileUrl) => {
  const { fields, url } = uploadFileUrl.data

  const formData = new FormData()
  Object.entries(fields).forEach(([key, value]) => {
    formData.append(key, value)
  }, [])

  formData.append('file', file)

  await uploadFileAPI(url, formData)
}

export const uploadFileUrl = async (file: File, fileUrl: FileUrl) => {
  const { fields, url } = fileUrl.data

  const formData = new FormData()
  Object.entries(fields).forEach(([key, value]) => {
    formData.append(key, value)
  }, [])

  formData.append('file', file)

  await uploadFileAPI(url, formData)
}

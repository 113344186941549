import React from 'react'

import { Box } from '@chakra-ui/react'

import TeachingSubjectLevelItem from '../TeachingSubjectLevelItem'
import { GroupedCombination } from 'types'

interface TeachingSubjectLevelItemGroupProps {
  data: GroupedCombination[]
  onRemoveItem: (combinationId: string) => void
}

const TeachingSubjectLevelItemGroup: React.FC<TeachingSubjectLevelItemGroupProps> = ({
  data,
  onRemoveItem,
}) => {
  return (
    <Box>
      {data.map(groupItem => {
        return (
          <Box
            key={
              groupItem.country.id + groupItem.subject.id + groupItem.system.id
            }
            mb='1rem'
          >
            {groupItem.teachingLevels.map((item, index) => {
              const subjectItem = {
                id: item.subjectCombinationId as string,
                country: groupItem.country,
                subject: groupItem.subject,
                system: groupItem.system,
                teachingLevel: item,
              }

              return (
                <TeachingSubjectLevelItem
                  key={index}
                  data={subjectItem}
                  showTitle={index === 0}
                  onRemoveItem={onRemoveItem}
                />
              )
            })}
          </Box>
        )
      })}
    </Box>
  )
}

export default TeachingSubjectLevelItemGroup

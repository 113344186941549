import { useCallback } from 'react'
import { useQueryClient } from 'react-query'

export default (url: string) => {
  const queryClient = useQueryClient()

  return useCallback(
    () =>
      queryClient.invalidateQueries({
        predicate: query => {
          let queryKey
          if (Array.isArray(query.queryKey)) {
            queryKey = query.queryKey[0] as string
          } else {
            queryKey = query.queryKey as string
          }

          return queryKey?.includes(url)
        },
      }),
    [queryClient, url]
  )
}

import React, { useCallback, useEffect, useState } from 'react'

import { Button, Flex } from '@chakra-ui/react'

import { LoadingSpinner } from 'components'
import { useLanguages } from 'hooks'
import {
  useAcceptConnection,
  useAddConnection,
  useFriendStatus,
  useUnFriend,
} from 'queries/connection'
import { ConnectionStatusType } from 'types/connection'

export const ConnectionButton = ({
  userId,
  friendId,
}: {
  userId: string
  friendId: string
}) => {
  const { _t } = useLanguages()
  const [connectionStatus, setConnectionStatus] = useState<
    ConnectionStatusType | undefined
  >()
  const [connectionId, setConnectionId] = useState<string>()

  // ** Queries
  const {
    mutate: addConnection,
    isSuccess: addConnectionIsSuccess,
    isLoading: addConnectionIsLoading,
    reset: addConnectionOnReset,
  } = useAddConnection(userId, friendId)

  const {
    mutate: acceptConnection,
    isSuccess: acceptConnectionIsSuccess,
    isLoading: acceptConnectionIsLoading,
    reset: acceptConnectionOnReset,
  } = useAcceptConnection()

  const {
    mutate: delConnection,
    isSuccess: delConnectionIsSuccess,
    isLoading: delConnectionIsLoading,
    reset: delConnectionOnReset,
  } = useUnFriend()

  // ** Fetch connection status
  const {
    data: connectionStatusResponse,
    isLoading: connectionStatusIsLoading,
  } = useFriendStatus(userId, friendId)

  // ** Effects
  // Check connection status
  useEffect(() => {
    const status = connectionStatusResponse?.data.status
    const idRequestFriend = connectionStatusResponse?.data.idRequestFriend
    const id = connectionStatusResponse?.data.connectionId

    if (status) {
      setConnectionId(id)

      if (
        userId === idRequestFriend &&
        status === ConnectionStatusType.PENDING
      ) {
        setConnectionStatus(ConnectionStatusType.IM_WAITING)
      } else if (
        userId !== idRequestFriend &&
        status === ConnectionStatusType.PENDING
      ) {
        setConnectionStatus(ConnectionStatusType.PENDING)
      } else {
        setConnectionStatus(status)
      }
    }
  }, [userId, connectionStatusIsLoading, connectionStatusResponse])

  useEffect(() => {
    if (delConnectionIsSuccess) {
      setConnectionStatus(ConnectionStatusType.UN_FRIEND)
      delConnectionOnReset()
    }
  }, [delConnectionIsSuccess, delConnectionOnReset])

  useEffect(() => {
    if (addConnectionIsSuccess) {
      setConnectionStatus(ConnectionStatusType.IM_WAITING)
      addConnectionOnReset()
    }
  }, [addConnectionIsLoading, addConnectionIsSuccess, addConnectionOnReset])

  useEffect(() => {
    if (acceptConnectionIsSuccess) {
      setConnectionStatus(ConnectionStatusType.CONFIRM)
      acceptConnectionOnReset()
    }
  }, [
    acceptConnectionIsSuccess,
    acceptConnectionOnReset,
    addConnectionIsSuccess,
  ])

  // ** Events

  const handleAccept = useCallback(() => {
    acceptConnection({
      userId,
      requestId: connectionId,
    })
  }, [acceptConnection, connectionId, userId])

  const handleConnect = useCallback(() => {
    addConnection()
  }, [addConnection])

  const handleUnconnect = useCallback(() => {
    delConnection({
      userId,
      requestId: friendId,
    })
  }, [delConnection, friendId, userId])

  // ** Renderer
  const renderButtonMessage = useCallback(() => {
    if (connectionStatus === ConnectionStatusType.CONFIRM) {
      return (
        <Button
          p='0.75rem'
          color='primary'
          background='none'
          textTransform='uppercase'
          isLoading={delConnectionIsLoading}
          onClick={handleUnconnect}
        >
          {_t('user.connection.remove')}
        </Button>
      )
    }

    return null
  }, [_t, delConnectionIsLoading, connectionStatus, handleUnconnect])

  const renderButtonAccept = useCallback(() => {
    if (connectionStatus === ConnectionStatusType.PENDING) {
      return (
        <Button
          isLoading={acceptConnectionIsLoading}
          p='0.75rem'
          colorScheme='blue'
          textTransform='uppercase'
          onClick={handleAccept}
        >
          {_t('user.connection.accept')}
        </Button>
      )
    }

    return null
  }, [_t, acceptConnectionIsLoading, connectionStatus, handleAccept])

  const renderButtonInProgress = useCallback(() => {
    if (connectionStatus === ConnectionStatusType.IM_WAITING) {
      return (
        <Button
          p='0.75rem'
          textTransform='uppercase'
          background='transparent'
          color='#828282'
          borderColor='#828282'
        >
          {_t('user.connection.request_sent')}
        </Button>
      )
    }

    return null
  }, [_t, connectionStatus])

  const renderButtonConnect = useCallback(() => {
    if (
      !connectionStatus ||
      connectionStatus === ConnectionStatusType.IM_WAITING ||
      connectionStatus === ConnectionStatusType.PENDING ||
      connectionStatus === ConnectionStatusType.CONFIRM
    ) {
      return null
    }

    return (
      <Button
        isLoading={addConnectionIsLoading}
        p='0.75rem'
        colorScheme='blue'
        textTransform='uppercase'
        onClick={handleConnect}
      >
        {_t('user.connection.connect')}
      </Button>
    )
  }, [_t, addConnectionIsLoading, connectionStatus, handleConnect])

  return (
    <Flex>
      {renderButtonMessage()}
      {renderButtonAccept()}
      {renderButtonInProgress()}
      {renderButtonConnect()}
      {connectionStatusIsLoading && <LoadingSpinner />}
    </Flex>
  )
}

export * from './UserConnectionButton'

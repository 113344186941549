import { useMutation, useQuery } from 'react-query'

import { useToastError } from './helpers'
import { createAdHocRequest } from 'apis/learner'
import { learnerPath } from 'configs/apiPath'
import {
  AllSearchResult,
  CentreSearchResult,
  ClassSearchResult,
  ContentSearchResult,
  LessonSearchResult,
  QueryListResponse,
  SearchFilterBy,
  SearchParam,
  UserSearchResult,
} from 'types'

export const useLearnerSearchAll = (params: SearchParam) => {
  return useQuery<QueryListResponse<AllSearchResult>>(
    learnerPath.search({
      ...params,
      minPrice: isNaN(params.minPrice || NaN) ? undefined : params.minPrice,
      maxPrice: isNaN(params.maxPrice || NaN) ? undefined : params.maxPrice,
      meetingType: params.meetingType || 'all',
      filterBy: SearchFilterBy.ALL,
    })
  )
}

export const useLearnerSearchLesson = (params: SearchParam) => {
  return useQuery<QueryListResponse<LessonSearchResult>>(
    learnerPath.search({
      ...params,
      minPrice: isNaN(params.minPrice || NaN) ? undefined : params.minPrice,
      maxPrice: isNaN(params.maxPrice || NaN) ? undefined : params.maxPrice,
      meetingType: params.meetingType || 'all',
      filterBy: SearchFilterBy.LESSON,
    })
  )
}

export const useLearnerSearchClass = (params: SearchParam) => {
  return useQuery<QueryListResponse<ClassSearchResult>>(
    learnerPath.search({
      ...params,
      minPrice: isNaN(params.minPrice || NaN) ? undefined : params.minPrice,
      maxPrice: isNaN(params.maxPrice || NaN) ? undefined : params.maxPrice,
      meetingType: params.meetingType || 'all',
      filterBy: SearchFilterBy.CLASS,
    })
  )
}

export const useLearnerSearchContent = (params: SearchParam) => {
  return useQuery<QueryListResponse<ContentSearchResult>>(
    learnerPath.search({
      ...params,
      minPrice: isNaN(params.minPrice || NaN) ? undefined : params.minPrice,
      maxPrice: isNaN(params.maxPrice || NaN) ? undefined : params.maxPrice,
      meetingType: params.meetingType || 'all',
      filterBy: SearchFilterBy.CONTENT,
    })
  )
}

export const useLearnerSearchInstructor = (params: SearchParam) => {
  return useQuery<QueryListResponse<UserSearchResult>>(
    learnerPath.search({
      ...params,
      filterBy: SearchFilterBy.TUTOR,
    })
  )
}

export const useLearnerSearchCentre = (params: SearchParam) => {
  return useQuery<QueryListResponse<CentreSearchResult>>(
    learnerPath.search({
      ...params,
      filterBy: SearchFilterBy.CENTRE,
    })
  )
}

export const useCreateAdHocRequest = () => {
  return useMutation(
    ({ userId, request }: any) => createAdHocRequest({ userId, request }),
    {
      onError: useToastError(),
    }
  )
}

import React from 'react'

import { Box, Flex, Text } from '@chakra-ui/react'

import { Attachment } from 'types'
import { getBytes } from 'utils/parser'

import { ReactComponent as CloseIcon } from 'assets/images/close_gray_icon.svg'
import { ReactComponent as DownloadIcon } from 'assets/images/download_icon.svg'
import { ReactComponent as FileIcon } from 'assets/images/file_icon.svg'

interface UploadedItemProps {
  item: Attachment
  downloadable?: boolean
  deletable?: boolean
  downloadIconColor?: string
  onClickItem?: () => Promise<void>
  onDownloadItem?: () => Promise<void>
  onDeleteItem?: () => Promise<void>
}

const UploadedItem: React.FC<UploadedItemProps> = ({
  item,
  downloadable,
  deletable,
  downloadIconColor = '#000',
  onClickItem,
  onDownloadItem,
  onDeleteItem,
}) => {
  const handleDownload = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    onDownloadItem?.()
  }

  const handleDelete = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    onDeleteItem?.()
  }

  return (
    <Flex
      alignItems='center'
      width='100%'
      mb='0.5rem'
      p='1rem'
      bgColor='#fff'
      boxShadow='0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04)'
      onClick={onClickItem}
    >
      <Box mr='0.8125rem'>
        <FileIcon />
      </Box>
      <Box fontSize='0.8125rem' flex={1}>
        <Text noOfLines={1}>{item.name}</Text>
        <Text color='gray.300'>{getBytes(item.size)}</Text>
      </Box>
      {downloadable && (
        <Box onClick={handleDownload}>
          <DownloadIcon cursor='pointer' color={downloadIconColor} />
        </Box>
      )}
      {deletable && (
        <Box ml='1.25rem' onClick={handleDelete}>
          <CloseIcon cursor='pointer' />
        </Box>
      )}
    </Flex>
  )
}

export default UploadedItem

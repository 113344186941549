import { useMutation, useQuery } from 'react-query'

import { AxiosResponse } from 'axios'

import { useToastError } from './helpers'
import {
  addUserCard,
  deleteUserCard,
  topUp,
  updateBankAccount,
  writeOff,
} from 'apis/wallet'
import { walletPath } from 'configs/apiPath'
import { useInvalidateUrl, useLanguages, useLoading, useToast } from 'hooks'
import { WalletInfoRequest, WalletInfoResponse } from 'types'
import { sleep } from 'utils/helper'

export const useUserWallet = ({ type, id }: WalletInfoRequest) => {
  const options = { enabled: !!id }
  return useQuery<WalletInfoResponse>(walletPath.show({ type, id }), options)
}

export const useAddUserCard = ({ type, id }: WalletInfoRequest) => {
  const toast = useToast()
  const invalidateWallet = useInvalidateUrl(walletPath.show({ type, id }))
  const { setLoading } = useLoading()
  const { _t } = useLanguages()

  return useMutation(addUserCard, {
    onMutate: () => setLoading(true),
    onSettled: () => setLoading(false),
    onSuccess: () => {
      invalidateWallet()
      toast({
        title: 'Success!',
        description: _t('message.success.wallet_add_card'),
        status: 'success',
      })
    },
    onError: useToastError(),
  })
}

export const useDeleteUserCard = ({ type, id }: WalletInfoRequest) => {
  const toast = useToast()
  const invalidateWallet = useInvalidateUrl(walletPath.show({ type, id }))
  const { setLoading } = useLoading()
  const { _t } = useLanguages()

  return useMutation(deleteUserCard, {
    onMutate: () => setLoading(true),
    onSettled: () => setLoading(false),
    onSuccess: () => {
      invalidateWallet()
      toast({
        title: 'Success!',
        description: _t('message.success.wallet_remove_card'),
        status: 'success',
      })
    },
    onError: useToastError(),
  })
}

export const useUpdateBankAccount = ({ type, id }: WalletInfoRequest) => {
  const toast = useToast()
  const invalidateWallet = useInvalidateUrl(walletPath.show({ type, id }))
  const { setLoading } = useLoading()
  const { _t } = useLanguages()

  return useMutation(updateBankAccount, {
    onMutate: () => setLoading(true),
    onSettled: () => setLoading(false),
    onSuccess: async (_, { onClose }) => {
      invalidateWallet()
      onClose && onClose()
      toast({
        title: 'Success!',
        description: _t('message.success.wallet_update_bank_account'),
        status: 'success',
      })
    },
    onError: useToastError(),
  })
}

export const useTopUp = ({ type, id }: WalletInfoRequest) => {
  const toast = useToast()
  const invalidateWallet = useInvalidateUrl(walletPath.show({ type, id }))
  const { setLoading } = useLoading()
  const { _t } = useLanguages()

  return useMutation(topUp, {
    onMutate: () => setLoading(true),
    onSettled: () => setLoading(false),
    onSuccess: async (response: AxiosResponse) => {
      const { success, payUrl } = response?.data?.data
      if (success && payUrl) {
        invalidateWallet()
      } else {
        await sleep(5000)
        invalidateWallet()
        toast({
          title: 'Success!',
          description: _t('message.success.wallet_payment'),
          status: 'success',
        })
      }
    },
    onError: useToastError(),
  })
}

export const useWriteOff = ({ type, id }: WalletInfoRequest) => {
  const toast = useToast()
  const invalidateWallet = useInvalidateUrl(walletPath.show({ type, id }))
  const { setLoading } = useLoading()
  const { _t } = useLanguages()

  return useMutation(writeOff, {
    onMutate: () => setLoading(true),
    onSettled: () => setLoading(false),
    onSuccess: async (_, { onClose }) => {
      invalidateWallet()
      onClose && onClose()
      toast({
        title: 'Success!',
        description: _t('message.success.wallet_update_balance'),
        status: 'success',
      })
    },
    onError: useToastError(),
  })
}

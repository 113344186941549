import React from 'react'

import { Box, Image, Link } from '@chakra-ui/react'

import { AdsLayout } from 'types'

interface AdvertisementProps {
  imageUrl: string
  description: string
  pageUrl?: string
  layout?: AdsLayout
  onClick?: () => void
}

const Advertisement: React.FC<AdvertisementProps> = ({
  imageUrl,
  description,
  pageUrl = '#',
  layout = AdsLayout.LEADERBOARD,
  onClick,
}) => {
  const getDimension = () => {
    switch (layout) {
      case AdsLayout.SQUARE:
        return {
          height: '250px',
          width: '250px',
        }
      case AdsLayout.RECTANGLE:
        return {
          height: '250px',
          width: '300px',
        }
      case AdsLayout.BANNER:
        return {
          height: '60px',
          width: '468px',
        }
      case AdsLayout.LEADERBOARD:
        return {
          height: '90px',
          width: '728px',
        }
      case AdsLayout.LARGE_LEADERBOARD:
        return {
          height: 'auto',
          width: '100%',
        }
      case AdsLayout.MOBILE_LEADERBOARD:
        return {
          height: '50px',
          width: '320px',
        }
      default:
        return {
          height: 0,
          width: 0,
        }
    }
  }

  return (
    <Link isExternal href={pageUrl} onClick={onClick}>
      <Box height={getDimension().height} width={getDimension().width}>
        <Image src={imageUrl} alt={description} />
      </Box>
    </Link>
  )
}

export default Advertisement

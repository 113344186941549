import React from 'react'

import { Box, BoxProps, ButtonProps } from '@chakra-ui/react'

const Arrow: React.FC<BoxProps & ButtonProps> = ({
  transform,
  ...otherProps
}) => {
  return (
    <Box
      width='0.625rem'
      height='0.625rem'
      marginTop='0.45rem'
      borderStyle='solid'
      borderWidth='0 0.125rem 0.125rem 0'
      borderColor='black'
      transform={transform}
      cursor={'pointer'}
      _disabled={{
        borderColor: '#ccc',
        cursor: 'not-allowed',
      }}
      {...otherProps}
    />
  )
}

export default Arrow

import React, { useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router'
import { useParams } from 'react-router-dom'

import { Box, Button, Flex, Grid, Text, useDisclosure } from '@chakra-ui/react'
import { compareAsc } from 'date-fns'
import { useAtom } from 'jotai'

import { getSessionLink } from 'apis/classSession'
import { getAttachmentUrl } from 'apis/request'
import { CancelClassModal, ImageWrapper, LoadingSpinner } from 'components'
import { useLanguages, useLoading, useToast, useUserId } from 'hooks'
import {
  useClassDetail,
  useLearnerCancelClass,
  useTutorCancelClass,
} from 'queries/class'
import { userProfileAtom } from 'store/authAtom'
import { ClassDetail } from 'types'
import {
  buildMeetingLink,
  generateDuration,
  getErrorMessage,
  getFullName,
} from 'utils/parser'

import { ReactComponent as ArrowBackIcon } from 'assets/images/arrowBack_icon.svg'
import { ReactComponent as AttachmentIcon } from 'assets/images/attachment_icon.svg'
import defaultAvatar from 'assets/images/no_avatar.png'

interface AdhocClassDetail {
  classId: string
  onTutorCancel?: () => void
  onLearnerCancel?: () => void
}

export default () => {
  const navigate = useNavigate()
  const params = useParams()
  const [userProfile] = useAtom(userProfileAtom)
  const userId = useUserId()
  const toast = useToast()
  const { setLoading } = useLoading()
  const { _t } = useLanguages()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const now = new Date()

  const classId = params.id

  const accountType = useMemo(() => userProfile?.accountType?.name, [
    userProfile,
  ])

  const isTutor = useMemo(
    () => accountType?.toLowerCase()?.includes('tutor') || false,
    [accountType]
  )
  const isLearner = useMemo(
    () => accountType?.toLowerCase()?.includes('student') || false,
    [accountType]
  )

  // ** Queries
  const { data: classDetail, isLoading: isClassLoading } = useClassDetail({
    classId,
  })
  const { mutate: learnerCancelClass } = useLearnerCancelClass(classId)
  const { mutate: tutorCancelClass } = useTutorCancelClass(classId)

  // ** Effects
  const isExpired = useMemo(() => {
    const isMore30MinsBeforeClass =
      compareAsc(
        now,
        new Date(classDetail?.data?.sessions[0]?.startDateTime || 0).getTime() -
          1800000
      ) === -1
    const isMore30MinsAfterClass =
      compareAsc(
        now,
        new Date(classDetail?.data?.sessions[0]?.endDateTime || 0).getTime() +
          1800000
      ) === 1

    return isMore30MinsBeforeClass || isMore30MinsAfterClass
  }, [classDetail, now])

  // ** Events
  const handleToBack = useCallback(() => {
    navigate('/my-courses')
  }, [navigate])

  const handleOpenCancelClass = useCallback(() => {
    onOpen()
  }, [onOpen])

  const handleLearnerJoin = useCallback(
    async dataItem => {
      const fetchLink = async () => {
        try {
          setLoading(true)
          const res = await getSessionLink(
            dataItem.id,
            (dataItem?.sessions || [{}])[0]?.id || ''
          )
          if (res.data) {
            const { meetingType, meetingLink } = res.data.data
            const link =
              meetingType === 'zoom'
                ? buildMeetingLink(res.data.data)
                : meetingLink
            const openLink = window.open(link, '_blank') as Window
            openLink.focus()
          }
        } catch (error) {
          toast({
            title: 'Error!',
            description: _t(`message.error.${getErrorMessage(error)}`),
            status: 'error',
          })
        } finally {
          setLoading(false)
        }
      }

      await fetchLink()
    },
    [_t, setLoading, toast]
  )

  const handleCloseAfterCancel = useCallback(() => {
    onClose()
    handleToBack && handleToBack()
  }, [handleToBack, onClose])

  const handleCancelClass = useCallback(
    (reason: string) => {
      if (isLearner) {
        learnerCancelClass({
          userId,
          classId,
          data: { reason },
          onClose: handleCloseAfterCancel,
        })
      } else {
        tutorCancelClass({
          userId,
          classId,
          data: { reason },
          onClose: handleCloseAfterCancel,
        })
      }
    },
    [
      classId,
      handleCloseAfterCancel,
      learnerCancelClass,
      tutorCancelClass,
      userId,
      isLearner,
    ]
  )

  return (
    <Box as='section' pt='1.5rem' pb='3rem' px={['1.5rem', '6rem', '7rem']}>
      <Box>
        <Flex
          cursor='pointer'
          onClick={handleToBack}
          alignItems='center'
          w='fit-content'
        >
          <ArrowBackIcon />
          <Text ml='1rem'>My Classes</Text>
        </Flex>
        <Grid gridTemplateColumns='6fr 4fr' mt='1rem' columnGap='1.25rem'>
          <Flex flexDirection='column'>
            <LearnerRequest
              data={classDetail?.data}
              isLoading={isClassLoading}
            />
            <BidDetail data={classDetail?.data} isLoading={isClassLoading} />
          </Flex>
          <ClassInfo data={classDetail?.data} isLoading={isClassLoading} />
        </Grid>

        <Flex mt='1.75rem' direction='row'>
          {isLearner && !isClassLoading && (
            <>
              <Button
                variant='solidPrimary'
                mr='0.75rem'
                isDisabled={isExpired}
                onClick={() => handleLearnerJoin(classDetail?.data)}
              >
                JOIN CLASS
              </Button>
              <Button
                variant='outlinePrimary'
                mr='0.75rem'
                onClick={handleOpenCancelClass}
              >
                CANCEL BOOKING
              </Button>
            </>
          )}
          {isTutor && !isClassLoading && (
            <Button
              variant='outlinePrimary'
              mr='0.75rem'
              onClick={handleOpenCancelClass}
            >
              CANCEL CLASS
            </Button>
          )}
        </Flex>
      </Box>
      <CancelClassModal
        userRole={isLearner ? 'learner' : 'instructor'}
        className={classDetail?.data.name}
        startDateTime={classDetail?.data.sessions[0].startDateTime}
        isOpen={isOpen}
        onClose={onClose}
        onSubmit={handleCancelClass}
        isNoBookedLearner={false}
      />
    </Box>
  )
}

const LearnerRequest = ({
  data,
  isLoading = false,
}: {
  data?: ClassDetail
  isLoading?: boolean
}) => {
  const userId = useUserId()

  const learner = useMemo(() => {
    if ((data?.confirmedStudents || []).length > 0) {
      return data?.confirmedStudents[0]
    }

    return undefined
  }, [data])

  const handleGetAttachmentUrl = useCallback(
    async (requestId: string | undefined, key: string | undefined) => {
      if (requestId && key) {
        const res = await getAttachmentUrl({ userId, requestId, key })
        const url = res.data.data as string
        const openLink = window.open(url, '_blank')
        openLink?.focus()
      }
    },
    [userId]
  )

  return (
    <Box borderRadius='20px' bg='#fff' p='2rem' mb='1.25rem' minH='15.125rem'>
      <Box as='h2' mb='1.25rem'>
        Learner’s Request
      </Box>

      <Box pb='2rem' borderBottom='1px solid #E0E0E0'>
        {isLoading && <LoadingSpinner />}
        {!isLoading && (
          <Grid gridTemplateColumns='2.5fr 7.5fr'>
            <Flex flexDirection='column' alignItems='flex-start'>
              <ImageWrapper
                src={learner?.avatar || defaultAvatar}
                defaultSrc={defaultAvatar}
                alt='avatar'
                width='50px'
                height='auto'
                objectFit='cover'
              />
              <Text textStyle='assignUserName' mt='0.5rem'>
                {getFullName(learner || {})}
              </Text>
            </Flex>

            <Box noOfLines={4} overflowY='scroll'>
              <Text
                as='h3'
                fontSize='1rem'
                fontWeight='600'
                lineHeight='1.3rem'
                mb='8px'
              >
                {data?.name || ''}
              </Text>
              <Text>{data?.description || ''}</Text>
            </Box>
          </Grid>
        )}
      </Box>

      <Flex color='primary' mt='1rem'>
        {(data?.attachments || []).map(a => (
          <Flex
            mr='1rem'
            onClick={() => handleGetAttachmentUrl(data?.id, a.key)}
            cursor='pointer'
          >
            <AttachmentIcon />
            <Text ml='0.5rem' lineHeight='1.125rem' fontSize='0.875rem'>
              {a.name || 'Attachment'}
            </Text>
          </Flex>
        ))}
      </Flex>
    </Box>
  )
}

const BidDetail = ({
  data,
  isLoading = false,
}: {
  data?: ClassDetail
  isLoading?: boolean
}) => {
  return (
    <Box borderRadius='20px' bg='#fff' p='2rem' minH='16.4rem'>
      {isLoading && <LoadingSpinner />}
      {!isLoading && (
        <Grid gridTemplateColumns='2.5fr 7.5fr'>
          <Flex flexDirection='column' alignItems='flex-start'>
            <ImageWrapper
              src={data?.user?.avatar || defaultAvatar}
              defaultSrc={defaultAvatar}
              alt='avatar'
              width='50px'
              height='auto'
              objectFit='cover'
            />
            <Text textStyle='assignUserName' mt='0.5rem'>
              {getFullName(data?.user || {})}
            </Text>
          </Flex>

          <Box>
            <Text
              textStyle='detailTitle'
              color='#4f4f4f'
              lineHeight='1.125rem'
              textTransform='uppercase'
            >
              REPLY TO LEARNER
            </Text>
            <Text
              noOfLines={10}
              overflowY='auto'
              lineHeight='1rem'
              fontSize='0.75rem'
            >
              {data?.bid?.message || ''}
            </Text>
          </Box>
        </Grid>
      )}
    </Box>
  )
}

const ClassInfo = ({
  data,
  isLoading = false,
}: {
  data?: ClassDetail
  isLoading?: boolean
}) => {
  const session = useMemo(() => {
    if ((data?.sessions?.length || 0) > 0) {
      return data?.sessions[0]
    }

    return undefined
  }, [data])
  return (
    <Box borderRadius='8px' bg='#fff' py='5.1rem' px='4rem'>
      {isLoading && <LoadingSpinner />}
      {!isLoading && (
        <>
          <Box mb='2rem'>
            <Text textStyle='detailTitle'>CLASS DATE & TIME</Text>
            <Text
              textStyle='detailText'
              lineHeight='1.3rem'
              fontSize='1rem'
              fontWeight='600'
              color='#333333'
            >
              {session?.startDateTime
                ? new Date(session?.startDateTime).toLocaleString(undefined, {
                    month: 'long',
                    day: '2-digit',
                    year: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit',
                  })
                : 0}
            </Text>
          </Box>
          <Box mb='2rem'>
            <Text textStyle='detailTitle'>DURATION</Text>
            <Text
              textStyle='detailText'
              lineHeight='1.3rem'
              fontSize='1rem'
              fontWeight='600'
              color='#333333'
            >
              {generateDuration(session?.startDateTime, session?.endDateTime)}
            </Text>
          </Box>
          <Box mb='2rem'>
            <Text textStyle='detailTitle'>PRICE</Text>
            <Text
              textStyle='detailText'
              lineHeight='1.3rem'
              fontSize='1rem'
              fontWeight='600'
              color='#333333'
            >
              ${data?.price}
            </Text>
          </Box>
          <Box mb='2rem'>
            <Text textStyle='detailTitle'>SUBJECT LEVEL</Text>
            <Text
              textStyle='detailText'
              lineHeight='1.3rem'
              fontSize='1rem'
              fontWeight='600'
              color='#333333'
            >
              {data?.teachingLevel?.name}
            </Text>
          </Box>
          <Box mb='2rem'>
            <Text textStyle='detailTitle'>LANGUAGE</Text>
            <Text
              textStyle='detailText'
              lineHeight='1.3rem'
              fontSize='1rem'
              fontWeight='600'
              color='#333333'
            >
              {data?.language?.name}
            </Text>
          </Box>
          <Box>
            <Text textStyle='detailTitle'>SUBJECT</Text>
            <Text
              textStyle='detailText'
              lineHeight='1.3rem'
              fontSize='1rem'
              fontWeight='600'
              color='#333333'
            >
              {data?.subject?.name}
            </Text>
          </Box>
        </>
      )}
    </Box>
  )
}

import { useMutation, useQuery } from 'react-query'

import { useToastError } from './helpers'
import { cancel, subscribe } from 'apis/subscription'
import { subscriptionPath } from 'configs/apiPath'
import { useInvalidateUrl, useLanguages, useLoading, useToast } from 'hooks'
import {
  SubscriptionInfoRequest,
  SubscriptionInfoResponse,
  SubscriptionPackageRequest,
  SubscriptionPackageResponse,
} from 'types'

export const useSubscriptionPackages = ({
  enterpriseId,
  type,
  adminId,
  tutorId,
}: SubscriptionPackageRequest) => {
  return useQuery<SubscriptionPackageResponse>(
    subscriptionPath.package({ type, enterpriseId, adminId, tutorId })
  )
}

export const useSubscription = ({
  userId,
  enterpriseId,
}: SubscriptionInfoRequest) => {
  const options = { enabled: !!userId }
  return useQuery<SubscriptionInfoResponse>(
    subscriptionPath.show({ userId, enterpriseId }),
    options
  )
}

export const useSubscribeSubscription = ({
  userId,
  enterpriseId,
}: SubscriptionInfoRequest) => {
  const toast = useToast()
  const invalidateSubscription = useInvalidateUrl(
    subscriptionPath.show({ userId, enterpriseId })
  )
  const { setLoading } = useLoading()
  const { _t } = useLanguages()

  return useMutation(subscribe, {
    onMutate: () => setLoading(true),
    onSettled: () => setLoading(false),
    onSuccess: () => {
      invalidateSubscription()
      toast({
        title: 'Success!',
        description: _t('message.success.subscription_changed'),
        status: 'success',
      })
    },
    onError: useToastError(),
  })
}

export const useCancelSubscription = ({
  userId,
  enterpriseId,
}: SubscriptionInfoRequest) => {
  const toast = useToast()
  const invalidateSubscription = useInvalidateUrl(
    subscriptionPath.show({ userId, enterpriseId })
  )
  const { setLoading } = useLoading()
  const { _t } = useLanguages()

  return useMutation(cancel, {
    onMutate: () => setLoading(true),
    onSettled: () => setLoading(false),
    onSuccess: () => {
      invalidateSubscription()
      toast({
        title: 'Success!',
        description: _t('message.success.subscription_cancelled'),
        status: 'success',
      })
    },
    onError: useToastError(),
  })
}

import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router'

import { useAtom } from 'jotai'

import EditAdminProfileContainer from '../../containers/EditAdminProfile'
import { EditEnterpriseAdminForm } from '../../containers/forms'
import { updateProfile } from 'apis/profile'
import { getUploadAvatarUrl, uploadAvatar } from 'apis/user'
import { AvatarRef } from 'components'
import {
  useLanguages,
  useLoading,
  useToast,
  useUserId,
  useUtilsForEnterpriseId,
} from 'hooks'
import { useEnterpriseDetail } from 'queries/enterprise'
import { useUpdateUser, useUserDetail } from 'queries/user'
import { userProfileAtom } from 'store/authAtom'
import { isMSTAdmin } from 'utils/helper'
import { getErrorMessage } from 'utils/parser'

interface EditEnterpriseProfileProps {
  handleToBack: () => void
  selfUpdate?: boolean
}

const EditEnterpriseProfile = ({
  handleToBack,
  selfUpdate = false,
}: EditEnterpriseProfileProps) => {
  const { _t } = useLanguages()
  const avatarRef = useRef<AvatarRef>()
  const { id } = useParams()
  const { setLoading } = useLoading()
  const [error, setError] = useState<string>()
  const toast = useToast()
  const { enterpriseId } = useUtilsForEnterpriseId()
  const personalId = useUserId()
  const userId = selfUpdate ? personalId : id

  const { data: userProfile, isLoading } = useUserDetail(userId)
  const { data: enterpriseData } = useEnterpriseDetail(userId)

  const userDetail = useMemo(() => userProfile?.data || undefined, [
    userProfile,
  ])
  const enterpriseDetail = useMemo(() => enterpriseData?.data || undefined, [
    enterpriseData,
  ])

  const { mutate: updateUser, isSuccess } = useUpdateUser(userId)

  const [selfProfile] = useAtom(userProfileAtom)
  const isKnovoAdmin = isMSTAdmin(selfProfile)

  const {
    control,
    formState: { errors: formErrors },
    handleSubmit,
    reset,
  } = useForm<EditEnterpriseAdminForm>({
    defaultValues: {
      ...({} as EditEnterpriseAdminForm),
      accountStatus: userDetail?.accountStatus || false,
    },
  })

  // set init data
  useEffect(() => {
    if (userProfile?.data) {
      const initData: EditEnterpriseAdminForm = {
        firstName: userDetail?.firstName || '',
        lastName: userDetail?.lastName || '',
        email: userDetail?.email || '',
        recoveryEmail: userDetail?.recoveryEmail || '',
        address1: userDetail?.address1 || '',
        address2: userDetail?.address2 || '',
        city: userDetail?.city || '',
        state: userDetail?.state || '',
        country: userDetail?.country || '',
        contactNumber: userDetail?.contactNumber || '',
        zipCode: userDetail?.zipCode || '',
        accountStatus: userDetail?.accountStatus || false,
        enterprise: {
          value: enterpriseDetail?.id || '',
          label: enterpriseDetail?.name || '',
        },
        birthday: userDetail?.birthday
          ? new Date(userDetail.birthday)
          : undefined,
      }
      reset(initData)
    }
  }, [enterpriseDetail, reset, userDetail, userProfile])

  const handleSave = async (values: EditEnterpriseAdminForm) => {
    try {
      setLoading(true)
      const updatedAvatar = avatarRef.current?.getUpdatedAvatar?.()

      if (updatedAvatar?.avatar) {
        const { avatar, fileType } = updatedAvatar
        const { data } = await getUploadAvatarUrl(userId, fileType as string)
        const { fields, url } = data.data

        const formData = new FormData()
        Object.entries(fields).forEach(([key, value]) => {
          formData.append(key, value)
        }, [])
        formData.append('file', avatar as File)

        await uploadAvatar(url, formData)
      }
    } catch (err) {
      setError(getErrorMessage(err))
    }

    const formattedDataUser = {
      ...values,
      enterpriseId: isKnovoAdmin ? values.enterprise?.value : enterpriseId,
      birthday: values.birthday
        ? new Date(values.birthday).getTime()
        : undefined,
    }

    delete formattedDataUser.enterprise
    if (!selfUpdate) {
      updateUser({
        userId,
        data: formattedDataUser,
      })
    } else {
      await updateProfile(userId, formattedDataUser)
      window.location.reload()
    }
  }

  useEffect(() => {
    if (isSuccess) {
      setLoading(false)
    }
  }, [setLoading, isSuccess])

  useEffect(() => {
    if (error) {
      setLoading(false)
      toast({
        title: _t('message.error.title'),
        description: error,
        status: 'error',
      })
      setError('')
    }
  }, [_t, error, setLoading, toast])

  return (
    <EditAdminProfileContainer
      toBackLabel={_t('user_management.enterprise_admin.title')}
      title={_t('user_management.enterprise_admin.edit_title')}
      handleToBack={handleToBack}
      handleSubmit={handleSubmit}
      handleSubmitCall={handleSave}
      isLoading={isLoading}
      avatarRef={avatarRef}
      userDetail={userDetail}
      control={control}
      formErrors={formErrors}
      isEdit
      showEnterprise={!selfUpdate && isKnovoAdmin}
      showStatus={!selfUpdate && isKnovoAdmin}
    />
  )
}

export default EditEnterpriseProfile

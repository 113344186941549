import React, { useCallback, useEffect, useState } from 'react'

import { useBreakpointValue } from '@chakra-ui/media-query'
import { Box, Flex, Grid } from '@chakra-ui/react'

import { useLanguages } from 'hooks'
import { UserRole, UserRoleType } from 'types'

import { ReactComponent as Enterprise } from 'assets/images/enterprise.svg'
import { ReactComponent as Instructor } from 'assets/images/instructor.svg'
import { ReactComponent as Learner } from 'assets/images/learner.svg'

interface AuthRoleSelectionProps {
  initRole?: UserRoleType
  heading: string
  onChange: (role: UserRoleType) => void
}

const options = [
  {
    label: 'auth.role.learner',
    value: UserRole.Student,
    Icon: Learner,
  },
  {
    label: 'auth.role.instructor',
    value: UserRole.Tutor,
    Icon: Instructor,
  },
  {
    label: 'auth.role.enterprise',
    value: UserRole.Enterprise,
    Icon: Enterprise,
  },
]

const AuthRoleSelection: React.FC<AuthRoleSelectionProps> = ({
  initRole,
  heading,
  onChange,
}) => {
  const [value, setValue] = useState(initRole || options[0].value)
  const { _t } = useLanguages()
  const handleSelectRole = (role: UserRoleType) => () => {
    setValue(role)
  }

  const size = useBreakpointValue({ base: '70px', md: '100px' })

  useEffect(() => {
    if (value) {
      // @ts-ignore
      onChange(value)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  const getIntroText = useCallback(() => {
    switch (value) {
      case UserRole.Student:
        return _t('auth.role.subtitle_learner')

      case UserRole.Tutor:
        return _t('auth.role.subtitle_instructor')

      default:
        return _t('auth.role.subtitle_enterprise')
    }
  }, [_t, value])

  return (
    <Box as='section'>
      <Box
        as='h1'
        mb='1rem'
        width='100%'
        fontWeight='600'
        fontSize='2rem'
        lineHeight='2.625rem'
        textAlign='center'
      >
        {heading}
      </Box>
      <Grid gridTemplateColumns='repeat(3, 1fr)' gridColumnGap='0.8125rem'>
        {options.map(({ label, value: optionValue, Icon }) => (
          <Flex
            key={optionValue}
            direction='column'
            alignItems='center'
            cursor='pointer'
            py='1rem'
            px='0.875rem'
            backgroundColor={optionValue === value ? 'lavender' : 'inherit'}
            borderRadius='0.5rem'
            // @ts-ignore
            onClick={handleSelectRole(optionValue)}
          >
            <Icon width={size} height={size} />
            <Box as='p' mt='0.25rem' fontWeight='600' lineHeight='1.25rem'>
              {_t(label)}
            </Box>
          </Flex>
        ))}
      </Grid>
      <Box
        as='p'
        my='1.25rem'
        fontSize='0.875rem'
        lineHeight='1.125rem'
        textAlign='center'
      >
        {getIntroText()}
      </Box>
    </Box>
  )
}

export default AuthRoleSelection

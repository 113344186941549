import { lazy } from 'react'

const Dashboard = lazy(
  () => import(/* webpackChunkName: "admin-dashboard" */ './Dashboard')
)

const RoleAndResponsibility = lazy(
  () =>
    import(
      /* webpackChunkName: "role-responsibility" */ './RoleAndResponsibility'
    )
)

const EditRoleResponsibility = lazy(
  () =>
    import(
      /* webpackChunkName: "edit-role-responsibility" */ './EditRoleResponsibility'
    )
)

const ExploreManagement = lazy(
  () =>
    import(/* webpackChunkName: "explore-management" */ './ExploreManagement')
)

const UserManagement = lazy(
  () => import(/* webpackChunkName: "user-management" */ './UserManagement')
)

const InstructorProfile = lazy(
  () =>
    import(
      /* webpackChunkName: "instructor-profile" */ './UserManagement/InstructorProfile/Edit'
    )
)

const LearnerProfile = lazy(
  () =>
    import(
      /* webpackChunkName: "learner-profile" */ './UserManagement/LearnerProfile/Edit'
    )
)

const SubjectLibrarySettings = lazy(
  () =>
    import(
      /* webpackChunkName: "subject-library-setting" */ './Settings/SubjectLibrary'
    )
)

const SubjectMatrixSettings = lazy(
  () =>
    import(
      /* webpackChunkName: "subject-matrix-setting" */ './Settings/SubjectMatrix'
    )
)

const TeachingLanguage = lazy(
  () =>
    import(
      /* webpackChunkName: "teaching-language-management" */ './Settings/TeachingLanguage'
    )
)

const SessionManagement = lazy(
  () =>
    import(/* webpackChunkName: "session-management" */ './SessionManagement')
)
const CreateSession = lazy(
  () =>
    import(
      /* webpackChunkName: "create-session-landing" */ './SessionManagement/Create'
    )
)
const CreateSessionClass = lazy(
  () =>
    import(
      /* webpackChunkName: "create-session-class" */ './SessionManagement/Create/Class'
    )
)
const CreateSessionContent = lazy(
  () =>
    import(
      /* webpackChunkName: "create-session-content" */ './SessionManagement/Create/Content'
    )
)
const CreateSessionLesson = lazy(
  () =>
    import(
      /* webpackChunkName: "create-session-lesson" */ './SessionManagement/Create/Lesson'
    )
)
const EditSessionClass = lazy(
  () =>
    import(
      /* webpackChunkName: "edit-session-class" */ './SessionManagement/Edit/Class'
    )
)
const EditSessionContent = lazy(
  () =>
    import(
      /* webpackChunkName: "edit-session-content" */ './SessionManagement/Edit/Content'
    )
)
const PreviewSessionClass = lazy(
  () =>
    import(
      /* webpackChunkName: "preview-session-class" */ './SessionManagement/Preview/Class'
    )
)
const PreviewSessionContent = lazy(
  () =>
    import(
      /* webpackChunkName: "preview-session-content" */ './SessionManagement/Preview/Content'
    )
)
const PreviewSessionLesson = lazy(
  () =>
    import(
      /* webpackChunkName: "preview-session-lesson" */ './SessionManagement/Preview/Lesson'
    )
)
const BidsAndRequests = lazy(
  () =>
    import(
      /* webpackChunkName: "bids-and-requests" */ './SessionManagement/BidsAndRequests'
    )
)

const Multilingualism = lazy(
  () =>
    import(
      /* webpackChunkName: "multilingualism" */ './Settings/Multilingualism'
    )
)

const EnterpriseManagement = lazy(
  () =>
    import(
      /* webpackChunkName: "enterprise-management" */ './EnterpriseManagement'
    )
)
const ProfileEnterprise = lazy(
  () =>
    import(
      /* webpackChunkName: "profile-enterprise" */ './EnterpriseManagement/Enterprise/Preview'
    )
)
const CreateEnterprise = lazy(
  () =>
    import(
      /* webpackChunkName: "create-enterprise" */ './EnterpriseManagement/Enterprise/Create'
    )
)
const EditEnterprise = lazy(
  () =>
    import(
      /* webpackChunkName: "edit-enterprise" */ './EnterpriseManagement/Enterprise/Edit'
    )
)
const AdminPreviewCentre = lazy(
  () =>
    import(
      /* webpackChunkName: "admin-preview-centre" */ './EnterpriseManagement/Centre/Preview'
    )
)
const AdminCreateCentre = lazy(
  () =>
    import(
      /* webpackChunkName: "admin-create-centre" */ './EnterpriseManagement/Centre/Create'
    )
)
const AdminEditCentre = lazy(
  () =>
    import(
      /* webpackChunkName: "admin-edit-centre" */ './EnterpriseManagement/Centre/Edit'
    )
)

const EditEnterpriseAdminProfile = lazy(
  () =>
    import(
      /* webpackChunkName: "enterprise-profile" */ './UserManagement/EnterpriseAdmin/Edit'
    )
)
const CreateEnterpriseAdminProfile = lazy(
  () =>
    import(
      /* webpackChunkName: "create-enterprise-profile" */ './UserManagement/EnterpriseAdmin/Create'
    )
)
const CreateCentreAdminProfile = lazy(
  () =>
    import(
      /* webpackChunkName: "create-center-profile" */ './UserManagement/CentreAdmin/Create'
    )
)

const Notification = lazy(
  () => import(/* webpackChunkName: "notification" */ './Notification')
)
const AddNotification = lazy(
  () =>
    import(
      /* webpackChunkName: "add-notification" */ './Notification/AddNotification'
    )
)
const EditNotification = lazy(
  () =>
    import(
      /* webpackChunkName: "edit-notification" */ './Notification/EditNotification'
    )
)

const Wallet = lazy(() => import(/* webpackChunkName: "wallet" */ './Wallet'))
const ViewMyTransaction = lazy(
  () =>
    import(
      /* webpackChunkName: "view-my-transaction" */ './Wallet/PreviewTransaction'
    )
)
const Transaction = lazy(
  () => import(/* webpackChunkName: "transaction-management" */ './Transaction')
)
const ViewTransaction = lazy(
  () =>
    import(/* webpackChunkName: "view-transaction" */ './Transaction/Preview')
)
const TransactionApproval = lazy(
  () =>
    import(
      /* webpackChunkName: "transaction-approval-management" */ './TransactionApproval'
    )
)
const TransactionWithdrawal = lazy(
  () =>
    import(
      /* webpackChunkName: "withdrawal-management" */ './TransactionWithdrawal'
    )
)
const ViewWithdrawalTransaction = lazy(
  () =>
    import(
      /* webpackChunkName: "view-withdrawal-transaction" */ './TransactionWithdrawal/Preview'
    )
)
const CreateWithdrawalTransaction = lazy(
  () =>
    import(
      /* webpackChunkName: "create-withdrawal-transaction" */ './TransactionWithdrawal/Create'
    )
)
const EditWithdrawalTransaction = lazy(
  () =>
    import(
      /* webpackChunkName: "edit-withdrawal-transaction" */ './TransactionWithdrawal/Edit'
    )
)
const CashFlow = lazy(
  () => import(/* webpackChunkName: "cash-flow-management" */ './CashFlow')
)
const ViewCashFlow = lazy(
  () => import(/* webpackChunkName: "view-cash-flow" */ './CashFlow/Preview')
)

const SessionCostSettings = lazy(
  () =>
    import(
      /* webpackChunkName: "session-cost-settings" */ './Settings/SessionCost'
    )
)

const CreateLearner = lazy(
  () =>
    import(
      /* webpackChunkName: "create-Learner" */ './UserManagement/LearnerProfile/Create'
    )
)

const CreateInstructor = lazy(
  () =>
    import(
      /* webpackChunkName: "create-instructor" */ './UserManagement/InstructorProfile/Create'
    )
)

const EditMstAdmin = lazy(
  () =>
    import(
      /* webpackChunkName: "edit-mstadmin" */ './UserManagement/MstAdmin/Edit'
    )
)

const CreateMstAdmin = lazy(
  () =>
    import(
      /* webpackChunkName: "create-mstadmin" */ './UserManagement/MstAdmin/Create'
    )
)

const EditCentreAdmin = lazy(
  () =>
    import(
      /* webpackChunkName: "edit-center-admin" */ './UserManagement/CentreAdmin/Edit'
    )
)
const Connections = lazy(
  () => import(/* webpackChunkName: "connections" */ './Connections')
)

const Campaign = lazy(
  () => import(/* webpackChunkName: "campaign" */ './Campaign')
)

const CreateCampaign = lazy(
  () => import(/* webpackChunkName: "campaign" */ './Campaign/Create')
)

const ViewCampaign = lazy(
  () => import(/* webpackChunkName: "campaign" */ './Campaign/View')
)

const PromoCodes = lazy(
  () => import(/* webpackChunkName: "promo-codes" */ './PromoCodes')
)

const CreatePromoCode = lazy(
  () => import(/* webpackChunkName: "promo-codes" */ './PromoCodes/Create')
)

const EditPromoCode = lazy(
  () => import(/* webpackChunkName: "promo-codes" */ './PromoCodes/Edit')
)
const ViewPromoCode = lazy(
  () => import(/* webpackChunkName: "promo-codes" */ './PromoCodes/ViewDetail')
)

const Notifications = lazy(
  () => import(/* webpackChunkName: "all-notification" */ './Notifications')
)

export {
  Dashboard,
  RoleAndResponsibility,
  EditRoleResponsibility,
  ExploreManagement,
  UserManagement,
  InstructorProfile,
  LearnerProfile,
  SubjectLibrarySettings,
  SubjectMatrixSettings,
  TeachingLanguage,
  SessionManagement,
  PreviewSessionClass,
  PreviewSessionContent,
  PreviewSessionLesson,
  CreateSession,
  CreateSessionClass,
  CreateSessionContent,
  CreateSessionLesson,
  EditSessionClass,
  EditSessionContent,
  BidsAndRequests,
  Multilingualism,
  EnterpriseManagement,
  ProfileEnterprise,
  CreateEnterprise,
  EditEnterprise,
  AdminPreviewCentre,
  AdminCreateCentre,
  AdminEditCentre,
  EditEnterpriseAdminProfile,
  Notification,
  AddNotification,
  EditNotification,
  Wallet,
  ViewMyTransaction,
  Transaction,
  ViewTransaction,
  TransactionApproval,
  TransactionWithdrawal,
  ViewWithdrawalTransaction,
  CreateWithdrawalTransaction,
  EditWithdrawalTransaction,
  CashFlow,
  ViewCashFlow,
  CreateLearner,
  CreateInstructor,
  CreateEnterpriseAdminProfile,
  CreateCentreAdminProfile,
  CreateMstAdmin,
  EditMstAdmin,
  EditCentreAdmin,
  SessionCostSettings,
  Connections,
  Campaign,
  CreateCampaign,
  ViewCampaign,
  PromoCodes,
  CreatePromoCode,
  EditPromoCode,
  ViewPromoCode,
  Notifications,
}

import React, { SVGProps } from 'react'

const Percentage = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      fill='none'
      height={38}
      viewBox='0 0 43 38'
      width={43}
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M13.887 36.55c-.467.867-1.117 1.3-1.95 1.3-.5 0-.95-.183-1.35-.55-.367-.367-.55-.833-.55-1.4 0-.467.133-.917.4-1.35l18.7-32.35c.5-.867 1.167-1.3 2-1.3.5 0 .933.183 1.3.55.4.367.6.833.6 1.4 0 .467-.133.917-.4 1.35zm-4.65-13.7c-2.7 0-4.817-.917-6.35-2.75-1.533-1.833-2.3-4.517-2.3-8.05 0-3.533.767-6.2 2.3-8C4.42 2.217 6.537 1.3 9.237 1.3c2.733 0 4.867.917 6.4 2.75 1.533 1.8 2.3 4.467 2.3 8 0 3.567-.767 6.267-2.3 8.1-1.533 1.8-3.667 2.7-6.4 2.7zm0-3.4c1.467 0 2.55-.583 3.25-1.75.7-1.2 1.05-3.083 1.05-5.65 0-2.533-.35-4.383-1.05-5.55-.7-1.167-1.783-1.75-3.25-1.75s-2.55.583-3.25 1.75c-.667 1.133-1 2.983-1 5.55 0 2.567.333 4.45 1 5.65.7 1.167 1.783 1.75 3.25 1.75zm24.55 17.9c-2.733 0-4.867-.9-6.4-2.7-1.533-1.833-2.3-4.533-2.3-8.1 0-3.533.767-6.2 2.3-8 1.533-1.833 3.667-2.75 6.4-2.75 2.7 0 4.817.917 6.35 2.75 1.567 1.8 2.35 4.467 2.35 8s-.767 6.217-2.3 8.05c-1.533 1.833-3.667 2.75-6.4 2.75zm0-3.4c1.467 0 2.533-.583 3.2-1.75.7-1.2 1.05-3.083 1.05-5.65 0-2.533-.35-4.383-1.05-5.55-.7-1.2-1.767-1.8-3.2-1.8-1.467 0-2.55.583-3.25 1.75s-1.05 3.033-1.05 5.6.35 4.45 1.05 5.65c.7 1.167 1.783 1.75 3.25 1.75z'
        fill='currentColor'
      />
    </svg>
  )
}

export default Percentage

import React, { useMemo } from 'react'

import { Box, Flex, Text } from '@chakra-ui/react'
import { useAtom } from 'jotai'

import { useLanguages } from 'hooks'
import { useSubscription } from 'queries/subscription'
import { SubscriptionContainer } from 'shared/containers'
import { userProfileAtom } from 'store/authAtom'
import { isMSTAdmin } from 'utils/helper'
import { getNumber } from 'utils/parser'

interface EnterpriseSubscriptionProps {
  enterpriseId: string
}

const EnterpriseSubscription: React.FC<EnterpriseSubscriptionProps> = ({
  enterpriseId,
}) => {
  const { _t } = useLanguages()
  const [userProfile] = useAtom(userProfileAtom)

  const { data: subscriptionData } = useSubscription({
    userId: userProfile?.id!,
    enterpriseId,
  })

  const isSuperAdmin = useMemo(() => isMSTAdmin(userProfile), [userProfile])
  const subscriptionName = useMemo(() => subscriptionData?.data.name, [
    subscriptionData,
  ])
  const commissionFee = useMemo(
    () => getNumber((subscriptionData?.data.commission || 0) * 100, 0),
    [subscriptionData]
  )

  return (
    <Flex flexDirection='column'>
      <Box
        mb='2rem'
        backgroundColor='#DDF2FF'
        borderRadius='0.5rem'
        p='1rem'
        w='100%'
      >
        {_t('enterprise.alert.subscription')}
      </Box>
      {isSuperAdmin ? (
        <Box mb='1.8rem'>
          <Text fontWeight='600' mb='0.5rem'>
            {_t('enterprise.profile.subscription_plan')}
          </Text>
          <Text as='h1'>{subscriptionName || '-'}</Text>
          <Text color='gray.300' fontSize='0.875rem'>
            {_t('enterprise.profile.commission_description', {
              rate: `${commissionFee}` || '-',
            })}
          </Text>
        </Box>
      ) : (
        <>
          <SubscriptionContainer type='enterprise' id={enterpriseId} />
          <Box textAlign='center'>
            <Text
              mt='1rem'
              textAlign='center'
              fontWeight='700'
              fontSize='0.875'
              color='#3959BF'
            >
              {_t('enterprise.subscription.customise')}{' '}
              <a href='mailto:hello@knovo.io'>hello@knovo.io</a>
            </Text>
          </Box>
        </>
      )}
    </Flex>
  )
}

export default EnterpriseSubscription

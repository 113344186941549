import React, { useEffect, useState } from 'react'

import { Box, Button, Flex, Text, useDisclosure } from '@chakra-ui/react'

import {
  EditTeachingSubjectModal,
  TeachingSubjectLevelItemGroup,
  UserEditTeachingSubjectModal,
} from 'components'
import { useLanguages } from 'hooks'
import {
  useUpdateUser,
  useUpdateUserProfile,
  useUserSubjectCombination,
} from 'queries/user'
import { GroupedCombination } from 'types'

interface TeachingSubjectSectionProps {
  userId: string
  centerId?: string
  isAdmin?: boolean
}

const TeachingSubjectSection: React.FC<TeachingSubjectSectionProps> = ({
  userId,
  centerId,
  isAdmin,
}) => {
  const { _t } = useLanguages()
  const { onOpen, isOpen, onClose } = useDisclosure()
  const { data: userSubjectCombinations } = useUserSubjectCombination(userId)
  const [deletedId, setDeletedId] = useState<string>()

  const {
    mutate: updateUserAsAdmin,
    isSuccess: isSuccessAsAdmin,
  } = useUpdateUser(userId)
  const { mutate: updateUser, isSuccess } = useUpdateUserProfile(userId)

  const [combinations, setCombinations] = useState<GroupedCombination[]>([])

  useEffect(() => {
    setCombinations(userSubjectCombinations?.data || [])
  }, [userSubjectCombinations])

  useEffect(() => {
    if (isSuccess || isSuccessAsAdmin) {
      setCombinations(oldCombinations =>
        oldCombinations.map(combination => ({
          ...combination,
          teachingLevels: combination.teachingLevels.filter(
            subject => subject.subjectCombinationId !== deletedId
          ),
        }))
      )
    }
  }, [isSuccess, isSuccessAsAdmin, deletedId])

  const handleRemoveCombinationItem = (subjectId: string) => {
    setDeletedId(subjectId)
    const data = {
      centerId,
      subjectCombinations: [{ opt: 'remove', id: subjectId }],
    }

    if (isAdmin) {
      updateUserAsAdmin({ userId, data })
    } else {
      updateUser({ userId, data })
    }
  }

  return (
    <>
      <Box w='100%'>
        <Flex
          direction='column'
          justifyContent='flex-start'
          mt='1rem'
          p='1rem'
          h='auto'
          minH='16rem'
          maxH='32rem'
          bg='secondaryLightBlue'
          rounded='lg'
        >
          <Flex
            direction='row'
            justifyContent='space-between'
            alignItems='center'
            mb='1rem'
          >
            <Text fontSize='1rem' fontWeight='600' ml='0.5rem' mr='0.5rem'>
              {_t('user.profile.subjects_and_levels')}
            </Text>
            <Button variant='outlinePrimary' size='md' onClick={onOpen}>
              {_t('common.add')}
            </Button>
          </Flex>
          {combinations.length > 0 ? (
            <Flex
              direction='column'
              justifyContent='flex-start'
              overflowY='scroll'
              css={{
                '&::-webkit-scrollbar-track': {
                  background: 'transparent',
                },
              }}
              mb='auto'
            >
              <TeachingSubjectLevelItemGroup
                data={combinations}
                onRemoveItem={handleRemoveCombinationItem}
              />
            </Flex>
          ) : (
            <Box py='5rem' justifyContent='center' alignItems='center'>
              <Text
                fontSize='0.75rem'
                fontWeight='400'
                color='gray.300'
                textAlign='center'
              >
                {_t('user.profile.teaching_subjects_and_levels_empty')}
              </Text>
            </Box>
          )}
        </Flex>
      </Box>

      {isAdmin ? (
        <EditTeachingSubjectModal
          isOpen={isOpen}
          onClose={onClose}
          userId={userId}
          centerId={centerId}
          initData={combinations}
          isNoNeedUpdateForUser
        />
      ) : (
        <UserEditTeachingSubjectModal
          isOpen={isOpen}
          onClose={onClose}
          userId={userId}
          initData={combinations}
          centerId={centerId}
        />
      )}
    </>
  )
}

export default TeachingSubjectSection

import { useEffect, useState } from 'react'
import { useMutation, useQuery } from 'react-query'

import { usePagination } from 'use-pagination-firestore'

import { useToastError } from './helpers'
import {
  addNotification,
  deleteNotification,
  readAllNotification,
  readNotification,
  updateNotification,
} from 'apis/notification'
import { notification } from 'configs/apiPath'
import { firestore as db } from 'configs/firebase'
import { useInvalidateUrl, useLanguages, useLoading, useToast } from 'hooks'
import {
  ListingParams,
  Meta,
  Notification,
  NotificationOnApp,
  NotificationType,
  TriggerPoint,
} from 'types'

const notificationDb = db.collection('Notification')

export type ListNotification = {
  data: Notification[]
} & Meta

export type NotificationParam = {
  userId: string
  search?: string
  createdBy?: string
  type?: string
  limit?: number
}

// firestore for app
export const useNotificationOnApp = () => {
  return notificationDb.get()
}

export const useNotificationList = (params: NotificationParam) => {
  const { userId, limit = 10, type = NotificationType.All } = params

  const [list, setList] = useState<NotificationOnApp[]>([])

  // added index which has 'type' on firebase store
  // there are 2 different indexes of 'createdAt' (Ascending & Descending)
  // because they are for pagination purposes: getPrev & getNext
  let typeFilteredNotificationDb =
    type === NotificationType.All
      ? notificationDb
      : notificationDb.where('type', '==', type)

  const {
    items,
    isLoading,
    isStart,
    isEnd,
    getPrev,
    getNext,
  } = usePagination<NotificationOnApp>(
    typeFilteredNotificationDb
      .where('userId', '==', userId)
      .where('deletedAt', '==', 0)
      .orderBy('createdAt', 'desc'),
    {
      limit: limit,
    }
  )

  useEffect(() => {
    setList(items)
  }, [items, isLoading])

  return { list, isLoading, isEnd, getNext, isStart, getPrev }
}

export const useNotification = (id: string) => {
  const [result, setResult] = useState<any>()
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<any>()
  useEffect(() => {
    const fetchNotification = async () => {
      try {
        setLoading(true)
        const noti = await notificationDb.doc(id).get()
        setResult(noti.data())
      } catch (err) {
        setError(err)
      } finally {
        setLoading(false)
      }
    }
    if (id) {
      fetchNotification()
    }
  }, [id])

  return {
    data: result,
    isLoading: loading,
    error,
  }
}

export const useTriggerPoints = () => {
  return useQuery<{ data: TriggerPoint[] }>(notification.getTriggerPoints())
}

export const useTriggerPointDetail = (triggerPointId: string) => {
  return useQuery(notification.getTriggerPointDetail(triggerPointId))
}

export const useNotifications = (params: ListingParams) => {
  return useQuery<ListNotification>(notification.list(params))
}

export const useNotificationDetail = (notificationId: string) => {
  return useQuery<{ data: Notification }>(notification.detail(notificationId))
}

export const useAddNotification = () => {
  const toast = useToast()
  const invalidateUrl = useInvalidateUrl(notification.list())
  const { setLoading } = useLoading()
  const { _t } = useLanguages()

  return useMutation(addNotification, {
    onMutate: () => setLoading(true),
    onSettled: () => setLoading(false),
    onSuccess: () => {
      invalidateUrl()
      toast({
        title: 'Success!',
        description: _t('message.success.notification_add'),
        status: 'success',
      })
    },
    onError: useToastError(),
  })
}

export const useUpdateNotification = (notificationId: string) => {
  const toast = useToast()
  const invalidateUrl = useInvalidateUrl(notification.list())
  const invalidateDetailUrl = useInvalidateUrl(
    notification.detail(notificationId)
  )
  const { setLoading } = useLoading()
  const { _t } = useLanguages()

  return useMutation(updateNotification, {
    onMutate: () => setLoading(true),
    onSettled: () => setLoading(false),
    onSuccess: () => {
      invalidateUrl()
      invalidateDetailUrl()
      toast({
        title: 'Success!',
        description: _t('message.success.notification_update'),
        status: 'success',
      })
    },
    onError: useToastError(),
  })
}

export const useDeleteNotification = () => {
  const toast = useToast()
  const invalidateUrl = useInvalidateUrl(notification.list())
  const { setLoading } = useLoading()
  const { _t } = useLanguages()

  return useMutation(deleteNotification, {
    onMutate: () => setLoading(true),
    onSettled: () => setLoading(false),
    onSuccess: () => {
      invalidateUrl()
      toast({
        title: 'Success!',
        description: _t('message.success.notification_remove'),
        status: 'success',
      })
    },
    onError: useToastError(),
  })
}

export const useReadNotification = () => {
  return useMutation(readNotification, {
    onError: useToastError(),
  })
}

export const useReadAllNotification = () => {
  const toast = useToast()
  const { setLoading } = useLoading()
  const { _t } = useLanguages()

  return useMutation(readAllNotification, {
    onMutate: () => setLoading(true),
    onSettled: () => setLoading(false),
    onSuccess: () => {
      toast({
        title: 'Success!',
        description: _t('message.success.notification_mark_all_read'),
        status: 'success',
      })
    },
    onError: useToastError(),
  })
}

import React from 'react'

import { Flex } from '@chakra-ui/react'

import { Cell } from '../../components'
import { maxNumberLength } from 'configs/game'

interface CurrentRowProps {
  guess: string
}

const CurrentRow: React.FC<CurrentRowProps> = ({ guess }) => {
  const splitGuess = guess.split('')
  const emptyCells = Array.from(Array(maxNumberLength - splitGuess.length))

  return (
    <Flex justifyContent='center' mb='0.5rem'>
      {splitGuess.map((letter, i) => (
        <Cell key={i} value={letter} />
      ))}
      {emptyCells.map((_, i) => (
        <Cell key={i} />
      ))}
    </Flex>
  )
}

export default CurrentRow

import React, { useCallback, useMemo } from 'react'
import { Link } from 'react-router-dom'

import { Avatar, Box, Flex, SimpleGrid, Text } from '@chakra-ui/react'
import format from 'date-fns/format'

import { BannerField, Pricing } from '../components'
import { ImageWrapper, Rating } from 'components'
import { useLanguages } from 'hooks'
import { SessionSearchResult, UserInSearch } from 'types'
import { isContentClassType, isLessonClassType } from 'utils/helper'
import { getDisplayName, parseMeetingTypes } from 'utils/parser'

import { ReactComponent as LabelDate } from 'assets/images/label-date.svg'
import { ReactComponent as LabelLocation } from 'assets/images/label-location.svg'
import { ReactComponent as LabelNumber } from 'assets/images/label-subject-level.svg'
import { ReactComponent as LabelTime } from 'assets/images/label-time.svg'
import bg from 'assets/images/mapping.png'

interface ClassBannerCardProps {
  data: SessionSearchResult
}

const ClassBannerCard: React.FC<ClassBannerCardProps> = ({ data }) => {
  const { _t } = useLanguages()
  const { user = {} as UserInSearch } = data

  const isLesson = useMemo(() => isLessonClassType(data.type), [data])
  const isContent = useMemo(() => isContentClassType(data.type), [data])
  const isMultiSession = useMemo(() => {
    const { sessions } = data
    if (sessions) {
      return sessions.length > 1 ? true : false
    }
    return false
  }, [data])

  const meetingType = useMemo(() => {
    const sessions = data.sessions
    if (!sessions || sessions?.length === 0) {
      return ''
    }
    return parseMeetingTypes(sessions)
  }, [data])

  const getLink = useCallback(() => {
    const { id } = data
    if (isLesson) {
      return `/product/lesson/${id}`
    } else if (isContent) {
      return `/product/content/${id}`
    }
    return `/product/class/${id}`
  }, [data, isContent, isLesson])

  const getCentre = useCallback(() => {
    const { centers } = data
    if (centers && centers.length > 0) {
      const centre = centers[0]
      return centre.name
    }
    return ''
  }, [data])

  const getDateRange = useCallback(() => {
    const { sessions } = data
    if (sessions && sessions.length > 0) {
      const range = sessions.reduce(
        (prev, curr) => {
          return {
            startDateTime:
              prev.startDateTime < curr.startDateTime
                ? prev.startDateTime
                : curr.startDateTime,
            endDateTime:
              prev.endDateTime > curr.endDateTime
                ? prev.endDateTime
                : curr.endDateTime,
          }
        },
        {
          startDateTime: sessions[0].startDateTime,
          endDateTime: sessions[0].endDateTime,
        }
      )
      const startDate = format(range.startDateTime, 'dd MMM yyyy')
      const endDate = format(range.endDateTime, 'dd MMM yyyy')
      if (startDate === endDate) {
        return startDate
      }
      return `${startDate} - ${endDate}`
    }
    return '-'
  }, [data])

  const getTime = useCallback(() => {
    const sessions = data.sessions

    if (!sessions || sessions?.length === 0) {
      return '-'
    }

    const formatStr = 'h:mma'

    const start = sessions[0].startDateTime
      ? format(new Date(sessions[0].startDateTime), formatStr)
      : 'n/a'
    const end = sessions[0].endDateTime
      ? format(new Date(sessions[0].endDateTime), formatStr)
      : 'n/a'

    return `${start} - ${end}`
  }, [data.sessions])

  const renderClassBadge = useCallback(() => {
    return (
      <Flex justify='flex-end'>
        {isMultiSession && (
          <Text
            fontSize='0.75rem'
            color='white'
            bg={'#EB975E'}
            px={5}
            py={1}
            mt='1rem'
            position='absolute'
          >
            {_t('explore.product.package')}
          </Text>
        )}
        {isLesson && (
          <Text
            fontSize='0.75rem'
            color='white'
            bg={'primary'}
            px={5}
            py={1}
            mt='1rem'
            position='absolute'
          >
            {_t('explore.product.lesson')}
          </Text>
        )}
        {isContent && (
          <Text
            fontSize='0.75rem'
            color='white'
            bg={'actionGreen'}
            px={5}
            py={1}
            mt='1rem'
            position={'absolute'}
          >
            {_t('explore.product.content')}
          </Text>
        )}
      </Flex>
    )
  }, [_t, isContent, isLesson, isMultiSession])

  const renderFields = useCallback(() => {
    return (
      <SimpleGrid mb='1rem' columns={[1, 1, 3, 3]} spacing='0.5rem'>
        {meetingType && (
          <BannerField
            label={_t('common.location')}
            value={meetingType}
            icon={<LabelLocation />}
          />
        )}
        {!isLesson && !isContent && (
          <BannerField
            label={_t('common.date')}
            value={getDateRange()}
            icon={<LabelDate />}
          />
        )}
        {!isLesson && !isContent && !isMultiSession && (
          <BannerField
            label={_t('common.time')}
            value={getTime()}
            icon={<LabelTime />}
          />
        )}
        {!isLesson && !isContent && isMultiSession && (
          <BannerField
            label={_t('product.class.number_of_sessions')}
            value={`${data?.sessions?.length || 0} ${_t('common.sessions')}`}
            icon={<LabelNumber />}
          />
        )}
        {isLesson && (
          <BannerField
            label={_t('product.class.number_of_packages')}
            value={`${data?.packages?.length || 0} ${_t('common.packages')}`}
            icon={<LabelNumber />}
          />
        )}
      </SimpleGrid>
    )
  }, [
    _t,
    data,
    getDateRange,
    getTime,
    isContent,
    isLesson,
    isMultiSession,
    meetingType,
  ])

  return (
    <Flex
      h='25rem'
      ml='1.5rem'
      mr='0.5rem'
      mb='1.5rem'
      direction='row'
      boxShadow='lg'
      rounded='lg'
      position='relative'
      bg='white'
    >
      <Box
        flex='1.2'
        h='full'
        w='full'
        position={{ base: 'absolute', lg: 'inherit' }}
        bg='white'
        opacity={{ base: '0.03', lg: '1' }}
      >
        <ImageWrapper
          rounded='lg'
          roundedRight={{ base: 'lg', lg: 'none' }}
          bg='white'
          h='full'
          w='full'
          src={data.banner || bg}
          key={data.banner || 'default-banner'}
          objectFit='cover'
        />
      </Box>
      <Box flex='0.8'>
        {renderClassBadge()}
        <Flex flex='1' h='full' direction='column' p='1.5rem'>
          <Flex
            flex='1'
            direction='column'
            justifyContent='flex-start'
            cursor='pointer'
          >
            <Box
              as={Link}
              to={getLink() || '#'}
              fontSize={{ base: '1rem', md: '1.5rem' }}
              lineHeight={{ base: '1.3rem', md: '1.8rem' }}
              fontWeight='600'
              mb='0.5rem'
              pr={isMultiSession || isLesson || isContent ? '4rem' : '0'}
              _hover={{
                color: 'primary',
              }}
              _before={{
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                left: 0,
                width: 'full',
                height: 'full',
              }}
              noOfLines={{ base: 2, md: 4 }}
            >
              {data.name || ''}
            </Box>
            <Text
              fontSize={{ base: '0.875rem', md: '1rem' }}
              noOfLines={{ base: 3, md: 5 }}
              whiteSpace='pre-wrap'
            >
              {data.description || ''}
            </Text>
          </Flex>
          {renderFields()}
          <Flex direction='row' justify='space-between'>
            <Box
              position='relative'
              alignSelf='flex-end'
              zIndex='1'
              cursor={'pointer'}
            >
              <Flex direction='row' alignItems='center'>
                <Avatar
                  size='md'
                  name={getDisplayName(user.firstName, user.lastName)}
                  src={user.avatar}
                  key={user.avatar || 'default-avatar'}
                />
                <Box flex='1' ml='0.75rem'>
                  <Flex direction='column' justifyContent='center'>
                    <Box>
                      {user.rating && (
                        <Rating rate={user.rating} size={'0.5rem'} />
                      )}
                    </Box>
                    <Box
                      as={Link}
                      to={`/instructors/${user.id}`}
                      fontWeight='600'
                      fontSize='0.875rem'
                      _hover={{
                        color: 'primary',
                      }}
                      _before={{
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: 'full',
                        height: 'full',
                        cursor: 'pointer',
                      }}
                      noOfLines={1}
                    >
                      {getDisplayName(user.firstName, user.lastName)}
                    </Box>
                    <Text fontSize='0.875rem' color='gray.500' noOfLines={1}>
                      {getCentre()}
                    </Text>
                  </Flex>
                </Box>
              </Flex>
            </Box>
            <Pricing
              price={data?.price || 0}
              usualPrice={data?.usualPrice}
              size='lg'
            />
          </Flex>
        </Flex>
      </Box>
    </Flex>
  )
}

export default ClassBannerCard

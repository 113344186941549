import Axios from 'axios'

import { blockPath, connectionPath } from 'configs/apiPath'

export const addFriend = async ({
  userId,
  friendId,
}: {
  userId: string
  friendId: string
}): Promise<void> => {
  return Axios.post(connectionPath.add(userId), { id: friendId })
}

export const unFriend = async ({
  userId,
  requestId,
}: {
  userId: string
  requestId: string
}): Promise<void> => {
  return Axios.delete(connectionPath.del(userId), {
    data: {
      id: requestId,
    },
  })
}

export const blockUser = async ({
  blockerId,
}: {
  userId: string
  blockerId: string
}): Promise<void> => {
  return Axios.post(blockPath.block(), { blockerId })
}

export const unBlockUser = async ({
  blockId,
}: {
  blockId: string
}): Promise<void> => {
  return Axios.delete(blockPath.unBlock(), {
    data: { blockId },
  })
}

export const acceptFriend = async ({
  userId,
  requestId,
}: {
  userId: string
  requestId: string
}): Promise<void> => {
  return Axios.put(connectionPath.accept(userId), { id: requestId })
}

export const declineFriend = async ({
  userId,
  requestId,
}: {
  userId: string
  requestId: string
}): Promise<void> => {
  return Axios.delete(connectionPath.decline(userId), {
    data: {
      id: requestId,
    },
  })
}

export const adminDeleteConnection = async ({
  userId,
  targetId,
}: {
  userId: string
  targetId: string
}): Promise<void> => {
  return Axios.delete(connectionPath.adminDelete(), {
    data: {
      userId,
      targetId,
    },
  })
}

export const adminAddConnection = async ({
  userId,
  targetIds,
}: {
  userId: string
  targetIds: string[]
}): Promise<void> => {
  return Axios.post(connectionPath.adminAdd(), {
    userId,
    targetIds,
  })
}

import React from 'react'

import { Box } from '@chakra-ui/react'

import Progress from './Progress'
import { useLanguages } from 'hooks'
import { GameStats } from 'types'

interface HistogramProps {
  gameStats: GameStats
}

const Histogram: React.FC<HistogramProps> = ({ gameStats }) => {
  const { _t } = useLanguages()
  const winDistribution = gameStats.winDistribution
  const maxValue = Math.max(...winDistribution)

  return (
    <>
      <h4>{_t('games.knovole.guess_distribution')}</h4>
      <Box justifyContent='left' m='2'>
        {winDistribution.map((value, i) => (
          <Progress
            key={i}
            index={i}
            size={90 * (value / maxValue)}
            label={String(value)}
          />
        ))}
      </Box>
    </>
  )
}

export default Histogram

import React from 'react'

import { Text } from '@chakra-ui/react'

import { useLanguages } from 'hooks'
import { CashFlowStatus } from 'types'

interface CashFlowStatusTextProps {
  status?: CashFlowStatus
}

const CashFlowStatusText: React.FC<CashFlowStatusTextProps> = ({ status }) => {
  const { _t } = useLanguages()

  switch (status) {
    case CashFlowStatus.INITIATE:
      return <Text>{_t('cash_flow.status.initiate')}</Text>
    case CashFlowStatus.ONHOLD:
      return <Text>{_t('cash_flow.status.on_hold')}</Text>
    case CashFlowStatus.UNRELEASED:
      return <Text>{_t('cash_flow.status.unreleased')}</Text>
    case CashFlowStatus.RELEASED:
      return <Text>{_t('cash_flow.status.released')}</Text>
    case CashFlowStatus.CANCELLED:
      return <Text>{_t('cash_flow.status.cancelled')}</Text>
    default:
      return <Text>{_t('common.unknown')}</Text>
  }
}

export default CashFlowStatusText

import { useMutation } from 'react-query'

import { useToastError } from './helpers'
import { refundApproval, refundRequest } from 'apis/refund'
import { classPath, transactionPath } from 'configs/apiPath'
import { useInvalidateUrl, useLanguages, useLoading, useToast } from 'hooks'
import { ListingParams, TransactionApprovalListRequest } from 'types'

export const useRefundRequest = ({
  userId,
  page,
  limit,
  status,
}: {
  userId: string
  page: number
  limit: number
  status: string
}) => {
  const toast = useToast()
  const invalidateUrl = useInvalidateUrl(
    classPath.listLearnerHistoryClasses(userId, {
      status,
      page,
      limit,
    })
  )
  const { setLoading } = useLoading()
  const { _t } = useLanguages()

  return useMutation(refundRequest, {
    onMutate: () => setLoading(true),
    onSettled: () => setLoading(false),
    onSuccess: (_, { onClose }) => {
      invalidateUrl()
      !!onClose && onClose()
      toast({
        title: 'Success!',
        description: _t('message.success.refund_add'),
        status: 'success',
      })
    },
    onError: useToastError(),
  })
}

export const useRefundApproval = (
  { type, id }: TransactionApprovalListRequest,
  params: ListingParams
) => {
  const toast = useToast()
  const invalidateUrl = useInvalidateUrl(
    transactionPath.approvalList({ type, id }, params)
  )
  const { setLoading } = useLoading()
  const { _t } = useLanguages()

  return useMutation(refundApproval, {
    onMutate: () => setLoading(true),
    onSettled: () => setLoading(false),
    onSuccess: (_, { onClose }) => {
      invalidateUrl()
      !!onClose && onClose()
      toast({
        title: 'Success!',
        description: _t('message.success.refund_approval_update'),
        status: 'success',
      })
    },
    onError: useToastError(),
  })
}

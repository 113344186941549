import React, { useCallback, useMemo, useState } from 'react'
import { Column, Row } from 'react-table'

import {
  Box,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import format from 'date-fns/format'

import ApprovalModal from './containers/ApprovalModal'
import {
  ActionDot,
  PopoverItem,
  Table,
  TransactionApprovalStatusBadge,
  TransactionTypeText,
} from 'components'
import { useLanguages } from 'hooks'
import {
  RefundApprovalStatus,
  TransactionApprovalInfo,
  TransactionApprovalStatus,
} from 'types'
import { formatAmount, getFullName } from 'utils/parser'

import { ReactComponent as UpdateStatusIcon } from 'assets/images/update_status_icon.svg'

interface ApprovalDataTableProps {
  isLoading: boolean
  colorScheme?: string
  data: TransactionApprovalInfo[]
  refundApproval: (data: any) => void
  handleSelect?: (values: TransactionApprovalInfo[]) => void
}

const ApprovalDataTable: React.FC<ApprovalDataTableProps> = ({
  isLoading,
  colorScheme,
  data,
  refundApproval,
  handleSelect,
}) => {
  const { _t } = useLanguages()
  const [approvalInfo, setApprovalInfo] = useState<TransactionApprovalInfo>()
  const {
    isOpen: isOpenApprovalModal,
    onOpen: onOpenApprovalModal,
    onClose: onCloseApprovalModal,
  } = useDisclosure()

  const handleApprovalAction = useCallback(
    (info: TransactionApprovalInfo) => {
      setApprovalInfo(info)
      onOpenApprovalModal()
    },
    [onOpenApprovalModal]
  )

  const handleAcceptRefund = useCallback(
    (id: string) => {
      refundApproval({
        data: {
          transactionApprovalIds: [id],
          status: RefundApprovalStatus.APPROVE,
        },
        onClose: onCloseApprovalModal,
      })
    },
    [refundApproval, onCloseApprovalModal]
  )

  const handleRejectRefund = useCallback(
    (id: string, comment: string) => {
      refundApproval({
        data: {
          transactionApprovalIds: [id],
          status: RefundApprovalStatus.REJECT,
          comment,
        },
        onClose: onCloseApprovalModal,
      })
    },
    [refundApproval, onCloseApprovalModal]
  )

  const columns = useMemo<Column<TransactionApprovalInfo>[]>(
    () => [
      {
        Header: _t('transaction_approval.detail.id'),
        id: 'id',
        Cell: ({ row }: { row: Row<TransactionApprovalInfo> }) => (
          <Text noOfLines={1}>{row.original.id}</Text>
        ),
        width: '260',
        sticky: 'left',
      },
      {
        Header: _t('transaction_approval.detail.description'),
        id: 'description',
        Cell: ({ row }: { row: Row<TransactionApprovalInfo> }) => (
          <Text noOfLines={1}>{row.original.description || '-'}</Text>
        ),
        width: '260',
        sticky: 'left',
      },
      {
        Header: _t('transaction_approval.detail.status'),
        id: 'status',
        Cell: ({ row }: { row: Row<TransactionApprovalInfo> }) => (
          <TransactionApprovalStatusBadge status={row.original.status} />
        ),
        width: '160',
        sticky: 'left',
      },
      {
        Header: _t('transaction_approval.detail.date_and_time'),
        id: 'createdAt',
        Cell: ({ row }: { row: Row<TransactionApprovalInfo> }) => (
          <Text noOfLines={1}>
            {format(new Date(row.original.createdAt), 'dd MMM yyyy, h:mm a')}
          </Text>
        ),
        width: '200',
      },
      {
        Header: _t('transaction_approval.detail.reason'),
        id: 'reason',
        Cell: ({ row }: { row: Row<TransactionApprovalInfo> }) => (
          <Text noOfLines={1}>{row.original.reason || '-'}</Text>
        ),
        width: '400',
      },
      {
        Header: _t('transaction_approval.detail.amount'),
        id: 'amount',
        Cell: ({ row }: { row: Row<TransactionApprovalInfo> }) => (
          <Text noOfLines={1}>
            ${formatAmount((row.original.transaction.amount || 0) / 100)}
          </Text>
        ),
        width: '160',
      },
      {
        Header: _t('transaction_approval.detail.type'),
        id: 'type',
        Cell: ({ row }: { row: Row<TransactionApprovalInfo> }) => (
          <TransactionTypeText type={row.original.transaction.type} />
        ),
        width: '160',
      },
      {
        Header: _t('transaction_approval.detail.requestor_name'),
        id: 'requestor_name',
        Cell: ({ row }: { row: Row<TransactionApprovalInfo> }) => (
          <Text noOfLines={1}>{getFullName(row.original.learner)}</Text>
        ),
        width: '260',
      },
      {
        Header: _t('transaction_approval.detail.approver_comment'),
        id: 'approver_comment',
        Cell: ({ row }: { row: Row<TransactionApprovalInfo> }) => (
          <Text noOfLines={1}>{row.original.approverComment || '-'}</Text>
        ),
        width: '400',
      },
      {
        Header: _t('transaction_approval.detail.last_update'),
        id: 'updatedAt',
        Cell: ({ row }: { row: Row<TransactionApprovalInfo> }) => (
          <Text noOfLines={1}>
            {format(new Date(row.original.updatedAt), 'dd MMM yyyy, h:mm a')}
          </Text>
        ),
        width: '200',
      },
      {
        Header: _t('transaction_approval.management.action'),
        Cell: ({ row }: { row: Row<TransactionApprovalInfo> }) => {
          if (row.original.status === TransactionApprovalStatus.PENDING) {
            return (
              <Popover>
                <PopoverTrigger>
                  <Box>
                    <ActionDot />
                  </Box>
                </PopoverTrigger>
                <Portal>
                  <PopoverContent
                    w='16rem'
                    bgColor='primaryDarker'
                    color='#fff'
                    _focus={{
                      outline: 0,
                    }}
                  >
                    <PopoverBody p='0'>
                      <PopoverItem
                        onClick={() => handleApprovalAction(row.original)}
                      >
                        <UpdateStatusIcon />
                        <Box ml='1rem'>
                          {_t(
                            'transaction_approval.management.update_approval'
                          )}
                        </Box>
                      </PopoverItem>
                    </PopoverBody>
                  </PopoverContent>
                </Portal>
              </Popover>
            )
          }
          return <></>
        },
        width: '100',
      },
    ],
    [_t, handleApprovalAction]
  )

  return (
    <>
      <Table<TransactionApprovalInfo>
        columns={columns}
        data={data}
        loading={isLoading}
        colorScheme={colorScheme}
        onSelectRows={handleSelect}
      />
      {approvalInfo && (
        <ApprovalModal
          isOpen={isOpenApprovalModal}
          onClose={onCloseApprovalModal}
          data={approvalInfo}
          onAccept={handleAcceptRefund}
          onReject={handleRejectRefund}
        />
      )}
    </>
  )
}

export default ApprovalDataTable

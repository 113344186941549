import React, { useCallback, useImperativeHandle, useState } from 'react'
import { Control, DeepMap, FieldError, useFieldArray } from 'react-hook-form'

import { Box, Button, Flex, Text, useDisclosure } from '@chakra-ui/react'
import uniqueId from 'lodash.uniqueid'

import { CommonProfileForm, CredentialDetailForm } from '../forms'
import {
  ContentContainer,
  CredentialEditItem,
  IconText,
  PreviewCredentialModal,
} from 'components'
import { useLanguages, useLoading, useUploadCredential } from 'hooks'
import { Attachment, CredentialDetail, CredentialRef } from 'types'

import { ReactComponent as InfoIcon } from 'assets/images/info_outline_icon.svg'

interface CredentialCardProps {
  userId?: string
  profileControl: Control<CommonProfileForm>
  profileErrors: DeepMap<CommonProfileForm, FieldError>
  setValue: any
  credentials?: CredentialDetail[]
}

const CredentialCard: React.ForwardRefRenderFunction<
  CredentialRef,
  CredentialCardProps
> = (
  { userId, profileControl, profileErrors, setValue, credentials = [] },
  ref
) => {
  const { _t } = useLanguages()
  const { setLoading } = useLoading()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [removeCredential, setRemoveCredential] = useState<
    CredentialDetailForm[]
  >([])
  const [previewFile, setPreviewFile] = useState<Attachment | undefined>(
    undefined
  )
  const { fields, append, remove } = useFieldArray<CredentialDetailForm>({
    control: profileControl,
    name: 'credentials',
  })

  const {
    uploadFiles,
    isUploading,
    handleChangeFiles,
    handleUpload,
  } = useUploadCredential(credentials)

  const handleAddCredential = useCallback(() => {
    const groupKey = uniqueId('credential_')
    append({
      opt: 'add',
      id: groupKey,
      name: '',
      issueEnterpriseId: '',
      issueName: '',
      issueDate: undefined,
      expiryDate: undefined,
      referenceId: '',
      referenceUrl: '',
      attachmentKey: '',
      attachment: undefined,
    })
    handleChangeFiles(groupFiles => {
      return [
        ...groupFiles,
        {
          index: groupKey,
          uploadFiles: [],
        },
      ]
    })
  }, [handleChangeFiles, append])

  const handleRemoveCredential = useCallback(
    (index: number) => {
      const resources = [] as any[]
      const deletedCredentials = uploadFiles.filter((item, i) => {
        if (i === index) {
          item.uploadFiles.forEach(fileItem => {
            if (fileItem.file.id) {
              resources.push({ opt: 'remove', resourceId: fileItem.file.id })
            }
          })
          return false
        }
        return true
      })

      if (fields[index].opt === 'update') {
        setRemoveCredential(list => [
          ...list,
          {
            opt: 'remove',
            id: fields[index].id,
            ...(resources.length > 0 && { attachment: resources[0] }),
          },
        ])
      }
      remove(index)
      handleChangeFiles(deletedCredentials)
    },
    [uploadFiles, fields, remove, handleChangeFiles]
  )

  const handleUploadAllFiles = useCallback(
    async (id: string) => {
      setLoading(true)
      const promises: Promise<string[] | undefined>[] = []
      fields.forEach(async item => {
        promises.push(handleUpload(id, item.id))
      })
      const results = await Promise.all(promises)
      results.forEach(async (result, index) => {
        if (result && result.length > 0) {
          setValue(`credentials[${index}].attachmentKey`, result[0])
        }
      })
      setLoading(false)
    },
    [fields, handleUpload, setLoading, setValue]
  )

  const handleOpenPreviewFile = useCallback(
    (file: Attachment) => {
      setPreviewFile(file)
      onOpen()
    },
    [onOpen]
  )

  const handleClosePreviewFile = useCallback(() => {
    setPreviewFile(undefined)
    onClose()
  }, [onClose])

  useImperativeHandle(
    ref,
    () => ({
      upload: handleUploadAllFiles,
      getRemoveCredential: () => removeCredential,
    }),
    [handleUploadAllFiles, removeCredential]
  )

  return (
    <ContentContainer
      title={_t('profile.heading.credential_detail')}
      mt='1rem'
      rightElement={
        <Flex justifyContent='space-between'>
          <IconText
            icon={InfoIcon}
            text={_t('common.public')}
            tooltipText={_t('profile.alert.edit_about')}
            textProps={{ textColor: 'gray.500' }}
          />
          <Button
            ml='1rem'
            variant='outlinePrimary'
            size='md'
            onClick={handleAddCredential}
          >
            {_t('common.add')}
          </Button>
        </Flex>
      }
    >
      {!fields?.length ? (
        <Box p='2rem' justifyContent='center' alignItems='center'>
          <Text textAlign='center' color='gray.300' fontSize='0.75rem'>
            {_t('user.profile.credential_empty')}
          </Text>
        </Box>
      ) : (
        <Box>
          {fields
            .filter(item => item.opt !== 'remove')
            .map((item: CredentialDetailForm, index: number) => (
              <Box
                key={item.id}
                py='1.5rem'
                _first={{ pt: 0 }}
                _notFirst={{ borderTop: '1px solid #BDBDBD' }}
              >
                <CredentialEditItem
                  index={index}
                  data={item}
                  control={profileControl}
                  setValue={setValue}
                  errors={profileErrors}
                  handleRemove={handleRemoveCredential}
                  handleViewFile={handleOpenPreviewFile}
                  uploadFiles={
                    uploadFiles.find(file => file.index === item.id)
                      ?.uploadFiles || []
                  }
                  isUploadingFile={isUploading}
                  handleChangeFile={handleChangeFiles}
                />
              </Box>
            ))}
        </Box>
      )}
      <PreviewCredentialModal
        isOpen={isOpen}
        onClose={handleClosePreviewFile}
        userId={userId || ''}
        file={previewFile}
      />
    </ContentContainer>
  )
}

export default React.forwardRef(CredentialCard)

import React from 'react'

import { Flex, FlexProps } from '@chakra-ui/react'

const PopoverItem = ({
  children,
  ...props
}: React.PropsWithChildren<FlexProps>) => {
  return (
    <Flex
      alignItems='center'
      py='0.5rem'
      px='1rem'
      color='#fff'
      borderStyle='solid'
      borderColor='#1D368B'
      cursor='pointer'
      _notLast={{
        borderBottomWidth: '1px',
      }}
      _hover={{
        opacity: 0.8,
      }}
      {...props}
    >
      {children}
    </Flex>
  )
}

export default PopoverItem

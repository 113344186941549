import React from 'react'

import { Flex, Text } from '@chakra-ui/react'
import { useAtom } from 'jotai'

import { filter, filterAtom } from '../atom'
import { Select } from 'components'
import { useLanguages } from 'hooks'

export default ({ ...selectContainerProps }) => {
  const { _t } = useLanguages()
  const [, setFilter] = useAtom(filterAtom)
  const [filterValues] = useAtom(filter)

  const onChange = (item: any) => {
    setFilter({
      ...filterValues,
      order: item?.value || '',
    })
  }

  return (
    <Flex direction='column' w='100%'>
      <Text as='h4' fontWeight='400' fontSize='0.75rem'>
        {_t('search.filter.sort_by')}
      </Text>
      <Select
        placeholder={_t('search.filter.placeholder_sort_by')}
        selectProps={{
          menuHeight: '5.5rem',
        }}
        defaultOptions={[
          { value: 'asc', label: _t('search.filter.sort_by_alphabetical') },
          { value: 'desc', label: _t('search.filter.sort_by_latest') },
        ]}
        defaultValue={[
          { value: 'asc', label: _t('search.filter.sort_by_alphabetical') },
        ]}
        containerProps={{
          mt: '0',
          width: '100%',
          ...selectContainerProps,
        }}
        onChange={onChange}
      />
    </Flex>
  )
}

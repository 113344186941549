import { useToast, UseToastOptions } from '@chakra-ui/react'

export default () => {
  const toast = useToast()

  return (options: UseToastOptions) =>
    toast({
      duration: 3000,
      isClosable: true,
      position: 'top-right',
      ...options,
    })
}

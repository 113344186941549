import React, { useCallback, useState } from 'react'
import { Control, Controller, useFieldArray } from 'react-hook-form'

import {
  Box,
  Button,
  Divider,
  Flex,
  Radio,
  RadioGroup,
  SimpleGrid,
  Text,
} from '@chakra-ui/react'
import validator from 'validator'

import { CreateClassForm, OccurrenceSessionForm } from '../form'
import {
  ConfirmAlert,
  DateTimePicker,
  Input,
  Select,
  Textarea,
  Tooltip,
} from 'components'
import { useGeocode, useLanguages } from 'hooks'
import { MeetingLocation, MeetingType } from 'types'
import { dayOfWeek } from 'utils/constant'

import { ReactComponent as TrashIcon } from 'assets/images/color_trash_icon.svg'
import { ReactComponent as InfoIcon } from 'assets/images/info_outline_icon.svg'

interface OccurrenceSectionProps {
  control: Control<CreateClassForm>
  watch: any
  setValue: any
  getValues: any
  register?: any
  errors: any
}

const OccurrenceSection: React.FC<OccurrenceSectionProps> = ({
  control,
  watch,
  setValue,
  getValues,
  register,
  errors,
}) => {
  const { _t } = useLanguages()
  const { getGeocode } = useGeocode()
  const [addresses, setAddresses] = useState<{
    [x: number]: MeetingLocation[]
  }>()
  const { fields, append, remove } = useFieldArray<OccurrenceSessionForm>({
    control,
    name: 'occurrenceSession',
  })

  const handleAddOccurrenceSession = useCallback(
    () =>
      append({
        name: '',
        description: '',
        week: 1,
        dayOfWeek: undefined,
        startTime: undefined,
        endTime: undefined,
        meetingType: MeetingType.VIRTUAL_DEFAULT,
      }),
    [append]
  )

  const handleRemoveOccurrenceSession = useCallback(
    (index: number) => {
      remove(index)
    },
    [remove]
  )

  const handleAddressKeyDown = useCallback(
    async (e: any, index: number) => {
      if (e.keyCode === 13) {
        const query =
          getValues(`occurrenceSession[${index}].meetingLocation.address`) || ''
        const geocodeOptions = await getGeocode(query as string)
        setAddresses({ [index]: geocodeOptions })
        setValue(`occurrenceSession[${index}].meetingLocation.lng`, undefined)
        setValue(`occurrenceSession[${index}].meetingLocation.lat`, undefined)
      }
    },
    [getGeocode, getValues, setValue]
  )

  const handleSelectAddress = useCallback(
    (address: MeetingLocation, index: number) => {
      setValue(`occurrenceSession[${index}].meetingLocation`, address)
      setAddresses(undefined)
    },
    [setValue]
  )

  const filterStart = useCallback(
    (value: any, index: number) => {
      const selectedDate = new Date(value)
      const endTime = getValues(`occurrenceSession[${index}].endTime`)
      if (endTime) {
        return endTime.getTime() > selectedDate.getTime()
      }
      return true
    },
    [getValues]
  )

  const filterEnd = useCallback(
    (value: any, index: number) => {
      const selectedDate = new Date(value)
      const startTime = getValues(`occurrenceSession[${index}].startTime`)
      if (startTime) {
        return startTime.getTime() < selectedDate.getTime()
      }
      return true
    },
    [getValues]
  )

  const renderMeetingInput = useCallback(
    (index: number) => {
      const meetingType = watch(`occurrenceSession[${index}].meetingType`)
      switch (meetingType) {
        case MeetingType.PHYSICAL:
          return (
            <Flex direction='column' mt='1.2rem'>
              <Flex alignItems='center' fontSize='0.75rem' fontWeight='600'>
                <Text mr='0.5rem'>{_t('product.class.address')}</Text>
                <Tooltip label={_t('product.class.tooltip_address')}>
                  <InfoIcon />
                </Tooltip>
              </Flex>
              <Controller
                control={control}
                name={`occurrenceSession[${index}].meetingLocation.address`}
                render={({ value, onChange }) => (
                  <Input
                    placeholder={_t('product.class.placeholder_address')}
                    fontSize='0.875rem'
                    value={value}
                    onChange={onChange}
                    onKeyDown={e => handleAddressKeyDown(e, index)}
                    labelProps={{ fontSize: '0.75rem' }}
                    error={errors?.meetingLocation?.address?.message}
                  />
                )}
                rules={{
                  validate: {
                    address: value => {
                      if (!value) {
                        return _t('product.class.validate_address_required')
                      }
                      return true
                    },
                  },
                }}
              />
              <Controller
                control={control}
                name={`occurrenceSession[${index}].meetingLocation.lng`}
                render={({ value, onChange }) => (
                  <Input type='hidden' value={value} onChange={onChange} />
                )}
              />
              <Controller
                control={control}
                name={`occurrenceSession[${index}].meetingLocation.lat`}
                render={({ value, onChange }) => (
                  <Input type='hidden' value={value} onChange={onChange} />
                )}
              />
              {register && (
                <Input
                  type='hidden'
                  {...register(
                    `occurrenceSession[${index}].meetingLocation.id`
                  )}
                />
              )}
              {addresses && addresses[index] && addresses[index].length > 0 && (
                <Box mt='0.5rem'>
                  <Flex alignItems='center' mb='0.5rem'>
                    <Text fontSize='0.75rem' fontWeight='600' mr='0.5rem'>
                      {_t('product.class.address_lookup')}
                    </Text>
                    <Tooltip label={_t('product.class.tooltip_address_lookup')}>
                      <InfoIcon />
                    </Tooltip>
                  </Flex>
                  {addresses[index].map(
                    (item: MeetingLocation, idx: number) => {
                      return (
                        <Box key={`address-${idx}`}>
                          {idx !== 0 && <Divider my='0.5rem' />}
                          <Box
                            cursor='pointer'
                            fontSize='0.75rem'
                            onClick={() => handleSelectAddress(item, index)}
                          >
                            {item?.address}
                          </Box>
                        </Box>
                      )
                    }
                  )}
                </Box>
              )}
            </Flex>
          )
        default:
          return null
      }
    },
    [
      _t,
      addresses,
      control,
      errors,
      handleAddressKeyDown,
      handleSelectAddress,
      register,
      watch,
    ]
  )

  const renderOccurrenceSessionItem = useCallback(
    (item: OccurrenceSessionForm, index: number) => {
      return (
        <Box p='2rem' rounded='lg' boxShadow='lg' bg='white'>
          <Flex
            direction={{ base: 'column', md: 'row' }}
            justify='space-between'
          >
            <Box flex='1'>
              <Box mt='1.2rem'>
                <Controller
                  control={control}
                  name={`occurrenceSession[${index}].name`}
                  defaultValue={item.name}
                  render={({ onChange, value }) => (
                    <Input
                      label={_t('product.class.module_name')}
                      aria-label='Module Name'
                      placeholder={_t('product.class.placeholder_module_name')}
                      onChange={onChange}
                      value={value}
                      fontSize='0.875rem'
                      labelProps={{ fontSize: '0.75rem' }}
                      error={
                        errors?.occurrenceSession?.[index]?.name?.message || ''
                      }
                    />
                  )}
                  rules={{
                    required: _t('product.class.validate_module_name_required'),
                  }}
                />
              </Box>
              <Box mt='1.2rem'>
                <Controller
                  control={control}
                  name={`occurrenceSession[${index}].description`}
                  defaultValue={item.description}
                  render={({ onChange, value }) => (
                    <Textarea
                      label={_t('product.class.module_description')}
                      aria-label='Module Description'
                      placeholder={_t(
                        'product.class.placeholder_module_description'
                      )}
                      onChange={onChange}
                      value={value}
                      fontSize='0.875rem'
                      labelProps={{ fontSize: '0.75rem' }}
                      error={
                        errors?.occurrenceSession?.[index]?.description
                          ?.message || ''
                      }
                    />
                  )}
                  rules={{
                    required: _t(
                      'product.class.validate_module_description_required'
                    ),
                  }}
                />
              </Box>
              <>
                <Flex flexDirection='column'>
                  <Text fontSize='0.75rem' fontWeight='600' mt='1.2rem'>
                    {_t('product.class.meeting_type')}
                  </Text>
                  <Controller
                    control={control}
                    name={`occurrenceSession[${index}].meetingType`}
                    defaultValue={item.meetingType}
                    render={({ onChange, value }) => (
                      <RadioGroup onChange={onChange} value={value}>
                        <Flex direction={{ base: 'column', md: 'row' }}>
                          <Radio
                            value={MeetingType.VIRTUAL_DEFAULT}
                            mt='0.5rem'
                            mr='1rem'
                          >
                            <Flex alignItems='center'>
                              <Text fontSize='0.875rem' mr='0.5rem'>
                                {_t('product.class.meeting_type_knovo')}
                              </Text>
                              <Tooltip
                                label={_t(
                                  'product.class.tooltip_meeting_type_knovo'
                                )}
                              >
                                <InfoIcon />
                              </Tooltip>
                            </Flex>
                          </Radio>
                          <Radio
                            value={MeetingType.PHYSICAL}
                            mt='0.5rem'
                            mr='1rem'
                          >
                            <Flex alignItems='center'>
                              <Text fontSize='0.875rem' mr='0.5rem'>
                                {_t('product.class.meeting_type_physical')}
                              </Text>
                              <Tooltip
                                label={_t(
                                  'product.class.tooltip_meeting_type_physical'
                                )}
                              >
                                <InfoIcon />
                              </Tooltip>
                            </Flex>
                          </Radio>
                        </Flex>
                      </RadioGroup>
                    )}
                  />
                </Flex>
                {renderMeetingInput(index)}
              </>
            </Box>
            <Box w='2rem' />
            <Box flex='1'>
              <SimpleGrid
                columns={[1, 1, 2]}
                spacing='1.2rem'
                flex='1'
                mt='1.2rem'
              >
                <Box>
                  <Flex alignItems='center' fontSize='0.75rem' fontWeight='600'>
                    <Text mr='0.5rem'>{_t('product.class.week')}</Text>
                    <Tooltip w='20rem' label={_t('product.class.tooltip_week')}>
                      <InfoIcon />
                    </Tooltip>
                  </Flex>
                  <Controller
                    control={control}
                    name={`occurrenceSession[${index}].week`}
                    defaultValue={item.week}
                    render={({ value, onChange }) => (
                      <Input
                        type='number'
                        placeholder={_t('product.class.placeholder_week')}
                        fontSize='0.875rem'
                        value={value}
                        onChange={onChange}
                        error={
                          errors?.occurrenceSession?.[index]?.week?.message
                        }
                      />
                    )}
                    rules={{
                      validate: {
                        week: (value: string = '0') =>
                          validator.isInt(value.toString(), {
                            min: 1,
                            max: 100,
                          })
                            ? true
                            : _t('product.class.validate_week_invalid'),
                      },
                    }}
                  />
                </Box>
                <Controller
                  as={Select}
                  control={control}
                  name={`occurrenceSession[${index}].dayOfWeek`}
                  defaultValue={item.dayOfWeek}
                  label={_t('product.class.day_of_week')}
                  error={errors?.occurrenceSession?.[index]?.dayOfWeek?.message}
                  rules={{
                    validate: {
                      dayOfWeek: ({ value }: any) => {
                        if (!value) {
                          return _t(
                            'product.class.validate_day_of_week_required'
                          )
                        }
                        return true
                      },
                    },
                  }}
                  options={dayOfWeek}
                  labelProps={{ fontSize: '0.75rem' }}
                />
              </SimpleGrid>
              <SimpleGrid
                columns={[1, 1, 2]}
                spacing='1.2rem'
                flex='1'
                mt='1.2rem'
              >
                <Controller
                  control={control}
                  name={`occurrenceSession[${index}].startTime`}
                  defaultValue={item.startTime}
                  render={({ onChange, value }) => (
                    <DateTimePicker
                      label={_t('product.class.start_time')}
                      onChange={onChange}
                      selected={value}
                      showTimeSelect
                      showTimeSelectOnly
                      dateFormat='h:mm aa'
                      timeIntervals={15}
                      placeholderText={_t(
                        'product.class.placeholder_start_time'
                      )}
                      labelProps={{ fontSize: '0.75rem' }}
                      filterTime={data => filterStart(data, index)}
                      error={
                        errors?.occurrenceSession?.[index]?.startTime?.message
                      }
                    />
                  )}
                  rules={{
                    validate: {
                      startTime: (value: any) => {
                        if (!value) {
                          return _t(
                            'product.class.validate_start_time_required'
                          )
                        }
                        return true
                      },
                    },
                  }}
                />
                <Controller
                  control={control}
                  name={`occurrenceSession[${index}].endTime`}
                  defaultValue={item.endTime}
                  render={({ onChange, value }) => (
                    <DateTimePicker
                      label={_t('product.class.end_time')}
                      onChange={onChange}
                      selected={value}
                      showTimeSelect
                      showTimeSelectOnly
                      dateFormat='h:mm aa'
                      timeIntervals={15}
                      placeholderText={_t('product.class.placeholder_end_time')}
                      labelProps={{ fontSize: '0.75rem' }}
                      filterTime={data => filterEnd(data, index)}
                      error={
                        errors?.occurrenceSession?.[index]?.endTime?.message
                      }
                    />
                  )}
                  rules={{
                    validate: {
                      endTime: (value: any) => {
                        if (!value) {
                          return _t('product.class.validate_end_time_required')
                        }
                        return true
                      },
                    },
                  }}
                />
              </SimpleGrid>
            </Box>
          </Flex>
        </Box>
      )
    },
    [_t, control, errors, filterEnd, filterStart, renderMeetingInput]
  )

  return (
    <Flex direction={{ base: 'column', md: 'row' }} justify='space-between'>
      <Box w='100%'>
        <Flex align='center' mb='0.75rem'>
          <Text as='h2' mr='0.5rem'>
            {_t('product.class.modules_title')}
          </Text>
          <Box>
            <Button
              variant='primary'
              textTransform='uppercase'
              size='sm'
              onClick={handleAddOccurrenceSession}
            >
              <span style={{ fontSize: '2rem' }}>&#43;</span>
              <Text ml='0.5rem'>{_t('product.class.add_module')}</Text>
            </Button>
          </Box>
        </Flex>
        {fields.map((item: OccurrenceSessionForm, index: number) => (
          <Flex key={item.id} mt='1.25rem'>
            <Box w='3rem' mr='0.75rem' display={{ base: 'none', md: 'inline' }}>
              <Flex
                alignItems='center'
                justifyContent='center'
                w='3rem'
                h='4rem'
                fontSize='1.5rem'
              >
                {index + 1}
              </Flex>
            </Box>
            <Flex align='center' w='full' flex='1'>
              <Box flex='1' mr='1rem'>
                {renderOccurrenceSessionItem(item, index)}
              </Box>
              <ConfirmAlert
                onSubmit={() => handleRemoveOccurrenceSession(index)}
                description={
                  <Text textAlign='center'>
                    {_t('product.class.module_remove_description')}
                  </Text>
                }
                immediatelyCloseOnSubmit
              >
                {({ showAlert }) => (
                  <TrashIcon cursor='pointer' onClick={showAlert} />
                )}
              </ConfirmAlert>
            </Flex>
          </Flex>
        ))}
      </Box>
    </Flex>
  )
}

export default OccurrenceSection

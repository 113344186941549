import React, { useCallback } from 'react'

import { Box, Button, Flex, Text } from '@chakra-ui/react'

import Modal, { ModalProps } from 'components/Modal'
import { useLanguages } from 'hooks'

export interface CancelContentProps extends Omit<ModalProps, 'children'> {
  onSubmit: (reason: string) => void
  className?: string
}

const CancelContentModal: React.FC<CancelContentProps> = ({
  isOpen,
  onClose,
  onSubmit,
  className,
}) => {
  const { _t } = useLanguages()

  const handleConfirm = useCallback(() => {
    onSubmit('No content')
  }, [onSubmit])

  return (
    <Modal isOpen={isOpen} onClose={onClose} size='xl'>
      <Flex direction='column' alignItems='center' w='100%'>
        <Text textStyle='headingModalText' align='center' mb='2rem'>
          {_t('product.content.cancel_title')}
        </Text>
        <Box textAlign='center'>
          <Text fontSize='0.875rem' mb='1rem'>
            {_t('product.content.cancel_description', {
              name: className || '',
            })}
          </Text>
          <Text fontSize='0.875rem' color='gray.300'>
            {_t('product.content.cancel_note')}
          </Text>
        </Box>
        <Flex mt='2rem'>
          <Button
            variant='solidPrimary'
            mr='0.5rem'
            onClick={handleConfirm}
            textTransform='uppercase'
          >
            {_t('common.confirm')}
          </Button>
          <Button variant='transparent' ml='0.5rem' onClick={onClose}>
            {_t('common.cancel')}
          </Button>
        </Flex>
      </Flex>
    </Modal>
  )
}

export default CancelContentModal

import React, { ReactNode } from 'react'

import { Box, Flex, Image, Text } from '@chakra-ui/react'

import { ResultType } from 'types'

import { ReactComponent as ClassEmptyIcon } from 'assets/images/empty_classes_icon.svg'
import { ReactComponent as KnovoSearchIcon } from 'assets/images/knovo-search.svg'
import { ReactComponent as UserGroupIcon } from 'assets/images/user-group-icon.svg'

interface ResultProps {
  title?: string
  subTitle?: string
  type?: ResultType
  extra?: ReactNode
}

const Result: React.FC<ResultProps> = ({
  title = '',
  subTitle = '',
  type = ResultType.SEARCH,
  extra,
}) => {
  let iconComponent = KnovoSearchIcon

  if (type === ResultType.CLASS) {
    iconComponent = ClassEmptyIcon
  } else if (type === ResultType.USER) {
    iconComponent = UserGroupIcon
  }

  return (
    <Flex
      w='100%'
      minH='170px'
      direction='column'
      alignItems='center'
      justifyContent='space-between'
    >
      <Image as={iconComponent} />
      <Box
        fontSize='1rem'
        fontWeight='400'
        lineHeight='1.125rem'
        color='#828282'
        textAlign='center'
      >
        <Text mt='1rem'>{title}</Text>
        <Text>{subTitle}</Text>
        {extra && <Box mt='1rem'>{extra}</Box>}
      </Box>
    </Flex>
  )
}

export default Result

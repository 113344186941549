import React, { useCallback, useEffect, useMemo } from 'react'

import { Box, Button, Flex } from '@chakra-ui/react'

import { CreateClassForm, OccurrenceSessionForm } from '../form'
import { ButtonPublish } from 'components'
import { useLanguages } from 'hooks'
import { useRecurrence } from 'queries/class'
import { PreviewClass } from 'shared/containers'
import { Attachment, ClassDetailItem, ClassSessionItem } from 'types'
import { isLessonClassType } from 'utils/helper'
import { parseDateTimeUnix } from 'utils/parser'

interface PreviewProps {
  previewBanner: string
  onClose: () => void
  data: CreateClassForm | undefined
  userId: string
  handleCreateClass: (
    creatingStatus: 'public' | 'draft'
  ) => (value: CreateClassForm) => void
  attachments: Attachment[]
  sessionData?: ClassDetailItem[]
}

const Preview: React.FC<PreviewProps> = ({
  previewBanner,
  onClose,
  data,
  userId,
  handleCreateClass,
  attachments,
  sessionData,
}) => {
  const { _t } = useLanguages()
  const isLessonClassMemo = useMemo(() => isLessonClassType(data?.type), [data])
  const { mutate: recurrence, data: packageData } = useRecurrence()

  const handlePublish = useCallback(() => {
    if (data) {
      handleCreateClass('public')(data)
    }
  }, [data, handleCreateClass])

  useEffect(() => {
    if (data && !packageData) {
      const { occurrenceStartDate, numberOfPackage, occurrenceSession } = data
      if (occurrenceStartDate && occurrenceSession) {
        recurrence({
          data: {
            startDate: parseDateTimeUnix(occurrenceStartDate),
            numberOfPackage: (numberOfPackage && +numberOfPackage) || 1,
            sessions: occurrenceSession?.map((item: OccurrenceSessionForm) => {
              return {
                name: item.name || '',
                description: item.description || '',
                week: (item?.week && +item.week) || 1,
                dayOfWeek: item.dayOfWeek?.value || '',
                startTime: parseDateTimeUnix(item.startTime, item.startTime),
                endTime: parseDateTimeUnix(item.endTime, item.endTime),
                meetingType: item.meetingType,
                meetingLocation: item.meetingLocation,
              }
            }),
          },
        })
      }
    }
  }, [data, packageData, recurrence])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const formatData = (classData: CreateClassForm | undefined) => {
    if (classData) {
      const {
        occurrenceStartDate,
        numberOfPackage,
        occurrenceSession,
        ...rest
      } = classData

      if (isLessonClassMemo) {
        return {
          ...rest,
          packages:
            (packageData?.data?.data &&
              packageData?.data.data.map((item: ClassSessionItem) => {
                return {
                  name: classData.name,
                  description: classData.description,
                  sessions: item,
                }
              })) ||
            [],
        }
      }

      return rest
    }
  }

  return (
    <Box as='section'>
      <Flex
        justifyContent='space-between'
        alignItems='center'
        py='1.5rem'
        px='1.5rem'
      >
        <Box>
          <Box as='h1' className={'blue-purple-gradient-text'}>
            {_t('product.class.preview_title')}
          </Box>
          <Box as='h2'>{_t('product.class.preview_description')}</Box>
        </Box>
        <Box>
          <ButtonPublish onSubmit={handlePublish} mr='1rem' />
          <Button
            variant='outlinePrimary'
            textTransform='uppercase'
            onClick={onClose}
          >
            {_t('product.class.back_to_draft')}
          </Button>
        </Box>
      </Flex>

      <PreviewClass
        isPreview
        previewBanner={previewBanner}
        userId={userId}
        data={formatData(data)}
        attachments={attachments || []}
        sessionData={sessionData}
      />
    </Box>
  )
}

export default Preview

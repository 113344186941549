import React, { useCallback } from 'react'

import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Divider,
  Flex,
  Text,
} from '@chakra-ui/react'
import { format } from 'date-fns'

import {
  downloadAllSessionAttachments,
  downloadSessionAttachment,
} from 'apis/classSession'
import { Map } from 'components'
import { useLanguages, useLoading, useToast } from 'hooks'
import { ClassDetailItem, MeetingType } from 'types'
import { getBytes, getErrorMessage } from 'utils/parser'

import { ReactComponent as AttachmentIcon } from 'assets/images/attachment_icon.svg'

interface SessionSectionProps {
  classId: string | undefined
  sessions: ClassDetailItem[] | undefined
}

const SessionSection: React.FC<SessionSectionProps> = ({
  classId,
  sessions = [],
}) => {
  const toast = useToast()
  const { setLoading } = useLoading()
  const { _t } = useLanguages()

  const handleDownloadAll = useCallback(
    async (sessionId?: string) => {
      if (classId && sessionId) {
        setLoading(true)
        try {
          const res = await downloadAllSessionAttachments(classId, sessionId)
          window.open(res.data.data)
        } catch (error) {
          toast({
            title: 'Error',
            description: _t(`message.error.${getErrorMessage(error)}`),
            status: 'error',
          })
        } finally {
          setLoading(false)
        }
      }
    },
    [_t, classId, setLoading, toast]
  )

  const handleDownloadAttachment = useCallback(
    async (sessionId?: string, key?: string) => {
      if (classId && sessionId && key) {
        setLoading(true)
        try {
          const res = await downloadSessionAttachment(classId, sessionId, key)
          window.open(res.data.data)
        } catch (error) {
          toast({
            title: 'Error',
            description: _t(`message.error.${getErrorMessage(error)}`),
            status: 'error',
          })
        } finally {
          setLoading(false)
        }
      }
    },
    [_t, classId, setLoading, toast]
  )

  return (
    <Box my='2rem'>
      <Text as='h2' mb='1rem'>
        {_t('product.class.sessions_title')}
      </Text>
      <Accordion allowToggle w='100%'>
        {sessions.map((item, index) => (
          <Flex key={index}>
            <Box w='3rem' mr='0.75rem'>
              <Flex
                alignItems='center'
                justifyContent='center'
                w='3rem'
                h='5rem'
                fontSize='1.5rem'
              >
                {index + 1}
              </Flex>
            </Box>
            <Box w='100%' mb='1.25rem'>
              <AccordionItem
                rounded='lg'
                boxShadow='lg'
                border='0.8px solid #DEDEDE'
              >
                <AccordionButton p='1rem'>
                  <Box flex='1' textAlign='left'>
                    <Text
                      fontSize='0.75rem'
                      fontWeight='600'
                      color='primary'
                      mb='0.25rem'
                    >
                      {item.startDateTime && item.endDateTime
                        ? `${format(
                            item.startDateTime,
                            'dd MMM yyyy, eee | h:mma'
                          )} - ${format(item.endDateTime, 'h:mma')}`
                        : 'Date and time not added yet'}
                    </Text>
                    <Text>{item.name}</Text>
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel p='1rem'>
                  <Text
                    fontSize='0.75rem'
                    color='gray.300'
                    mb='0.5rem'
                    textTransform='uppercase'
                  >
                    {_t('product.class.about')}
                  </Text>
                  <Text fontSize='0.75rem' whiteSpace='pre-wrap'>
                    {item.description}
                  </Text>
                  {item?.meetingType === MeetingType.PHYSICAL && (
                    <>
                      <Text
                        fontSize='0.75rem'
                        color='gray.300'
                        mt='1rem'
                        mb='0.5rem'
                        textTransform='uppercase'
                      >
                        {_t('common.location')}
                      </Text>
                      <Text fontSize='0.75rem' whiteSpace='pre-wrap'>
                        {item?.meetingLocation?.address}
                      </Text>
                      {item?.meetingLocation?.lat &&
                        item?.meetingLocation?.lng && (
                          <Box mt='1rem'>
                            <Map
                              lat={item?.meetingLocation?.lat}
                              lng={item?.meetingLocation?.lng}
                              w={{ base: 'full', md: '30rem' }}
                              h='16.875rem'
                            />
                          </Box>
                        )}
                    </>
                  )}
                  <Divider my='1rem' />
                  <Flex justify='space-between'>
                    <Flex alignItems='center' mb='0.5rem'>
                      <AttachmentIcon height='0.75rem' width='0.75rem' />
                      <Text fontSize='0.75rem' color='gray.300' ml='0.5rem'>
                        {_t('product.class.attachments', {
                          count: `${item.sessionAttachments?.length || 0}`,
                        })}
                      </Text>
                    </Flex>
                    {!!item.sessionAttachments?.length && (
                      <Text
                        fontSize='0.75rem'
                        color='primary'
                        cursor='pointer'
                        textTransform='uppercase'
                        onClick={() => handleDownloadAll(item.id)}
                        _hover={{
                          textDecoration: 'underline',
                        }}
                      >
                        {_t('product.class.download')}
                      </Text>
                    )}
                  </Flex>
                  {item.sessionAttachments?.map((attachment, i) => (
                    <Text
                      key={`attachment-${i}`}
                      fontSize='0.75rem'
                      color='primary'
                      cursor='pointer'
                      ml='1.2rem'
                      onClick={() =>
                        handleDownloadAttachment(item.id, attachment?.file?.key)
                      }
                      _hover={{
                        textDecoration: 'underline',
                      }}
                    >
                      {attachment?.file?.name} (
                      {getBytes(attachment?.file?.size)})
                    </Text>
                  ))}
                </AccordionPanel>
              </AccordionItem>
            </Box>
          </Flex>
        ))}
      </Accordion>
    </Box>
  )
}

export default SessionSection

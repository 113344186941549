import React, { useEffect } from 'react'

import PaymentCheckout from './Checkout'
import PaymentSubscribe from './Subscribe'
import PaymentTopUp from './TopUp'
import { HeadingSection, Layout } from 'components'
import { useLanguages } from 'hooks'

interface PaymentProps {
  type: 'topup' | 'subscribe' | 'checkout'
}

const Payment: React.FC<PaymentProps> = ({ type }) => {
  const { _t } = useLanguages()
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const renderContent = () => {
    switch (type) {
      case 'topup':
        return <PaymentTopUp />
      case 'subscribe':
        return <PaymentSubscribe />
      case 'checkout':
        return <PaymentCheckout />
      default:
        return <></>
    }
  }

  return (
    <Layout
      as='main'
      topElement={
        <HeadingSection title={_t('payment.title')} widthType='wide' />
      }
    >
      {renderContent()}
    </Layout>
  )
}

export default Payment

import { useCallback, useState } from 'react'

import { useAtom } from 'jotai'

import { getEnterpriseDetails } from 'apis/enterprise'
import { derivedEnterpriseIdAtom } from 'store/authAtom'
import { getErrorMessage } from 'utils/parser'

export default () => {
  const [error, setError] = useState('')
  const [enterpriseId, setEnterpriseId] = useAtom(derivedEnterpriseIdAtom)
  const [loading, setLoading] = useState(false)

  const resetEnterpriseId = useCallback(() => {
    setEnterpriseId('')
  }, [setEnterpriseId])

  const resetError = useCallback(() => {
    setError('')
  }, [])

  const saveEnterpriseId = useCallback(
    async (userId: string | undefined) => {
      if (userId) {
        try {
          setLoading(true)
          const response = await getEnterpriseDetails(userId)
          const id: string = response.data.data.id
          setEnterpriseId(id)
          setError('')
          setLoading(false)
        } catch (err) {
          setLoading(false)
          localStorage.clear()
          setEnterpriseId('')
          setError(getErrorMessage(err))
        }
      }
    },
    [setEnterpriseId]
  )

  return {
    saveEnterpriseId,
    enterpriseId,
    resetEnterpriseId,
    error,
    resetError,
    loading,
  }
}

import React, { useCallback } from 'react'

import { Box, Flex, Image, Text } from '@chakra-ui/react'
import format from 'date-fns/format'

import { BirthdayIcon, PercentageIcon } from 'components/Icons'
import { UserPromoDetail } from 'types'

import VoucherBackgrounda from 'assets/images/voucher_background.png'

interface VoucherItemProps {
  item: UserPromoDetail
  colorScheme?: string
  onOpenDetail: (item: UserPromoDetail) => void
}

const VoucherItem: React.FC<VoucherItemProps> = ({
  item,
  colorScheme = 'primary',
  onOpenDetail,
}) => {
  const getNumberDaysLeft = (endDate: number) => {
    const today = new Date().getTime()
    const differenceInTime = endDate - today
    const differenceInDays = differenceInTime / (1000 * 3600 * 24)

    if (differenceInDays > 0) {
      return `${Math.ceil(differenceInDays)} DAYS LEFT`
    }

    return 'EXPIRED'
  }

  const generateValue = useCallback((value: number, unit: string) => {
    if (unit === 'percent') {
      return `%${value * 100}`
    }

    return `${value}$`
  }, [])

  return (
    <Box
      position='relative'
      mb='1rem'
      cursor='pointer'
      onClick={() => onOpenDetail(item)}
    >
      <Image
        src={VoucherBackgrounda}
        w='100%'
        h='100%'
        position='absolute'
        top='0'
        left='0'
        style={{
          filter:
            'drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.04)) drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.04)) drop-shadow(0px 16px 24px rgba(0, 0, 0, 0.06))',
        }}
      />

      <Flex
        alignItems='center'
        position='relative'
        zIndex='5'
        p='0.7rem 1.6rem'
      >
        <Box
          flex='5'
          borderRight='1px dashed #3959BF'
          pr='1rem'
          textAlign='center'
        >
          <Box as='h1' color={colorScheme}>
            {generateValue(item.discountValue, item.discountUnit)}
            <span style={{ fontSize: '1rem' }}>{` OFF`}</span>
          </Box>
          <Text fontSize='0.625rem'>*min. spend ${item.minSpend || 0}</Text>
          <Text fontSize='0.625rem'>
            Ends {format(item.endOn, 'dd/MM/yyyy')}
          </Text>
          <Box
            bgColor={
              getNumberDaysLeft(item.endOn) === 'EXPIRED'
                ? 'gray.300'
                : colorScheme
            }
            py='0.4rem'
            textAlign='center'
            color='#fff'
            fontSize='0.75rem'
            mt='0.3rem'
          >
            {getNumberDaysLeft(item.endOn)}
          </Box>
        </Box>

        <Box flex='6' pl='1rem' textAlign='center'>
          <Flex justifyContent='center'>
            <Box color={colorScheme}>
              {item.name.toLowerCase().includes('birthday') ? (
                <BirthdayIcon />
              ) : (
                <PercentageIcon />
              )}
            </Box>
          </Flex>
          <Text fontWeight='600'>{item.name}</Text>
          <Text fontSize='0.625rem' mt='0.3rem'>
            USE CODE:
          </Text>
          <Box
            as='h1'
            color={colorScheme}
            mx='auto'
            maxW='15.625rem'
            isTruncated
          >
            {item.code}
          </Box>
        </Box>
      </Flex>
    </Box>
  )
}

export default VoucherItem

import React, { useCallback } from 'react'

import { Box, Flex, Grid, Text } from '@chakra-ui/react'

import { IconBox, LoadingSpinner } from 'components'
import { useLanguages } from 'hooks'
import { UserProfile } from 'types'

import { ReactComponent as BioIcon } from 'assets/images/bio.svg'
import { ReactComponent as DetailsIcon } from 'assets/images/details_icon.svg'

interface ViewAdminProfileProps {
  userId?: string
  profileData: UserProfile
  showCenter?: boolean
}

const ViewAdminProfileContainer: React.FC<ViewAdminProfileProps> = ({
  userId,
  profileData,
  showCenter = false,
}) => {
  const { _t } = useLanguages()

  const renderItem = useCallback((label, value) => {
    return (
      <Box>
        <Text fontSize='0.75rem' color='gray.300' textTransform='uppercase'>
          {label}
        </Text>
        <Text>{value || '-'}</Text>
      </Box>
    )
  }, [])

  if (!profileData) {
    return <LoadingSpinner />
  }

  return (
    <Flex direction='column' mt='2.5rem'>
      <IconBox icon={BioIcon} title={_t('profile.heading.about')}>
        <Grid
          gridTemplateColumns={{ base: '1fr', lg: 'repeat(2, 1fr)' }}
          gap={4}
        >
          {renderItem(_t('user.profile.user_id'), userId || profileData?.id)}
          {renderItem(_t('user.profile.email'), profileData?.email)}
          {renderItem(
            _t('user.profile.recovery_email'),
            profileData?.recoveryEmail
          )}
          {showCenter &&
            renderItem(
              _t('user.profile.centre'),
              profileData?.centers?.[0].name
            )}
        </Grid>
      </IconBox>
      <IconBox
        mt='2rem'
        pt='2rem'
        borderTopWidth='1px'
        borderTopColor='gray.200'
        icon={DetailsIcon}
        title={_t('profile.heading.personal_detail')}
      >
        <Grid
          gridTemplateColumns={{ base: '1fr', lg: 'repeat(2, 1fr)' }}
          gap={4}
        >
          {renderItem(_t('user.profile.address_line_1'), profileData?.address1)}
          {renderItem(_t('user.profile.address_line_2'), profileData?.address2)}
          {renderItem(_t('user.profile.city'), profileData?.city)}
          {renderItem(_t('user.profile.state'), profileData?.state)}
          {renderItem(_t('user.profile.country'), profileData?.country)}
          {renderItem(_t('user.profile.zip_code'), profileData?.zipCode)}
          {renderItem(_t('user.profile.mobile'), profileData?.contactNumber)}
        </Grid>
      </IconBox>
    </Flex>
  )
}

export default ViewAdminProfileContainer

import React from 'react'

import {
  Box,
  BoxProps,
  InputProps as ChakraInputProps,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Text,
} from '@chakra-ui/react'

import { useLanguages } from 'hooks'

interface InputProps extends ChakraInputProps {
  error?: string | undefined
  customErrorMessages?: React.ReactNode
  containerBoxProps?: BoxProps
  labelProps?: BoxProps
  label?: string
  leftElement?: React.ReactNode
  rightElement?: React.ReactNode
  optional?: boolean
  isDisabled?: boolean
}

const CustomInput = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      error,
      customErrorMessages,
      type = 'text',
      containerBoxProps,
      children,
      label,
      leftElement,
      rightElement,
      labelProps,
      optional,
      isDisabled,
      ...inputProps
    },
    ref
  ) => {
    const { _t } = useLanguages()

    return (
      <Box
        width='100%'
        {...(isDisabled && { color: 'gray.300' })}
        {...containerBoxProps}
      >
        {label && (
          <Box fontSize='0.875rem' fontWeight='600' {...labelProps}>
            {label}
            {optional && (
              <Text
                display='inline'
                fontSize={labelProps?.fontSize || '0.875rem'}
                fontStyle='italic'
                color='gray.300'
              >
                - {_t('common.optional')}
              </Text>
            )}
          </Box>
        )}
        <Box>
          <InputGroup>
            {leftElement && (
              <InputLeftElement
                pointerEvents='none'
                zIndex='0'
                width='min-content'
                children={leftElement}
              />
            )}
            <Input
              ref={ref}
              type={type}
              variant='flushed'
              borderBottom='1px solid'
              borderBottomColor='gray.300'
              errorBorderColor='error'
              height='2.125rem'
              pr={rightElement ? '4rem' : '0'}
              isDisabled={isDisabled}
              {...inputProps}
            />
            {rightElement && (
              <InputRightElement
                w='4rem'
                h='100%'
                pointerEvents='none'
                zIndex='0'
                children={rightElement}
                px='0'
              />
            )}
          </InputGroup>
          {customErrorMessages ||
            (error && <Text textStyle='error'>{error}</Text>)}
        </Box>
      </Box>
    )
  }
)

export default CustomInput

import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Control, Controller, ErrorOption, FieldName } from 'react-hook-form'

import {
  Box,
  Flex,
  Radio,
  RadioGroup,
  SimpleGrid,
  Text,
} from '@chakra-ui/react'
import { useAtom } from 'jotai'
import validator from 'validator'

import { CreateClassForm } from '../form'
import { Checkbox, Input, Select, Tooltip } from 'components'
import {
  centre,
  classPath,
  language,
  subject,
  teachingLevel,
} from 'configs/apiPath'
import {
  useCenterId,
  useLanguages,
  useUserId,
  useUtilsForEnterpriseId,
} from 'hooks'
import { userProfileAtom } from 'store/authAtom'
import { ClassType, ListingParams, UserRole } from 'types'
import {
  isAdmin,
  isCenterAdmin,
  isEnterpriseAdmin,
  isInstructor,
  isMSTAdmin,
  validateMultiple,
  validateNonNegativeDecimal,
} from 'utils/helper'
import {
  formatCombinationOption,
  formatOption,
  formatUserOption,
} from 'utils/parser'

import { ReactComponent as InfoIcon } from 'assets/images/info_outline_icon.svg'

type ProductType = 'class' | 'content' | 'lesson'

interface DetailSectionProps {
  role: string
  control: Control<CreateClassForm>
  watch: any
  setValue: any
  getValues: any
  register?: any
  setError?: (name: FieldName<CreateClassForm>, error: ErrorOption) => void
  errors: any
  type?: ProductType
}

const DetailSection: React.FC<DetailSectionProps> = ({
  role,
  control,
  watch,
  setValue,
  getValues,
  register,
  setError,
  errors,
  type = 'class',
}) => {
  const { _t } = useLanguages()
  const userId = useUserId()
  const { enterpriseId } = useUtilsForEnterpriseId()
  const adminCenterId = useCenterId()
  const [userProfile] = useAtom(userProfileAtom)
  const [errorMaxStudents, setErrorMaxStudents] = useState<string>()
  const [errorUsualPrice, setErrorUsualPrice] = useState<string>()
  const [
    errorUsualEnterprisePrice,
    setErrorUsualEnterprisePrice,
  ] = useState<string>()
  const tutorType = useMemo(() => watch('tutorType'), [watch])
  const requireMinimum = useMemo(() => watch('requireMinimum'), [watch])
  const centreId = useMemo(() => watch('centre')?.value, [watch])
  const tutorId = useMemo(() => watch('tutor')?.value, [watch])
  const isAdminMemo = useMemo(() => isAdmin(userProfile), [userProfile])
  const isMSTAdminMemo = useMemo(() => isMSTAdmin(userProfile), [userProfile])
  const isEnterpriseAdminMemo = useMemo(() => isEnterpriseAdmin(userProfile), [
    userProfile,
  ])
  const isCenterAdminMemo = useMemo(() => isCenterAdmin(userProfile), [
    userProfile,
  ])
  const isInstructorMemo = useMemo(() => isInstructor(userProfile), [
    userProfile,
  ])

  // make min-student-number back to 0 when requireMinimum is false
  useEffect(() => {
    if (!requireMinimum) {
      setValue('minStudent', 0)
    }
  }, [requireMinimum, setValue])

  useEffect(() => {
    const centres = userProfile?.centers || []
    if (isCenterAdminMemo && centres.length > 0) {
      setValue('centre', {
        value: centres[0].id,
        label: centres[0].name,
      })
    }
  }, [userProfile, isCenterAdminMemo, setValue])

  useEffect(() => {
    if (tutorType === UserRole.Tutor) {
      if (type === 'lesson') {
        setValue('type', ClassType.PUBLIC_LESSON)
      } else if (type === 'content') {
        setValue('type', ClassType.PUBLIC_CONTENT)
      } else {
        setValue('type', ClassType.PUBLIC_PACKAGE)
      }
    }
  }, [tutorType, setValue, type])

  const checkGreaterThan = useCallback(
    (name: string, refName: string) => {
      let refValue = getValues(refName)
      let value = getValues(name)

      // Value validity checks are not done in this function
      if (!refValue || !value) return true

      try {
        return +value > +refValue
      } catch (e) {
        console.log(e)
      }

      return true
    },
    [getValues]
  )

  const handleCheck = (
    name: string,
    label: string,
    refName: string,
    refLabel: string,
    setErrorCall: (errorLabel: string) => void
  ) => {
    return (errorSet: boolean = false) => {
      const checkValid = checkGreaterThan(name, refName)
      if (checkValid) {
        if (errorSet) setErrorCall('')
        return true
      }

      const errorLabel = `${label} must be greater than ${refLabel}`
      if (errorSet) {
        setErrorCall(errorLabel)
        return true
      } else {
        return errorLabel
      }
    }
  }

  const checkPrice = handleCheck(
    'usualPrice',
    'Usual Class Price',
    'price',
    'Class Price',
    setErrorUsualPrice
  )

  const checkEnterprisePrice = handleCheck(
    'usualEnterprisePrice',
    'Usual Enterprise Price',
    'enterprisePrice',
    'Enterprise Price',
    setErrorUsualEnterprisePrice
  )

  const checkStudentCount = handleCheck(
    'maxStudent',
    'Maximum Learners',
    'minStudent',
    'Minimum Learners',
    setErrorMaxStudents
  )

  const getListCentreByType = (typeAdmin: string) => {
    if (isMSTAdminMemo) {
      return centre.listByAdmin({ page: 1, limit: 50 })
    } else if (isEnterpriseAdminMemo) {
      return centre.list(enterpriseId, { page: 1, limit: 50 })
    }
  }

  const getListTutorByType = () => {
    const isHasCenterId = !!adminCenterId || !!centreId

    if (tutorType === UserRole.EnterpriseTutor && isHasCenterId) {
      return centre.getListUser(
        role === UserRole.CenterAdmin ? adminCenterId : centreId,
        {
          page: 1,
          limit: 50,
          filterBy: 'tutor',
        }
      )
    }

    if (tutorType === UserRole.Tutor) {
      return classPath.listTutor(userId, {
        page: 1,
        limit: 50,
        filterBy: 'private',
      })
    }

    return ''
  }

  const getListUrlByTutor = (
    urlMethod: (userId: string, params?: ListingParams) => string
  ) => {
    if (isAdminMemo) {
      if (tutorId) {
        return urlMethod(tutorId, { page: 1, limit: 100 })
      }
      return ''
    }
    return userId ? urlMethod(userId, { page: 1, limit: 100 }) : ''
  }

  const getTypeOptions = useCallback(() => {
    if (type === 'class') {
      return [
        {
          title: _t('product.class.type_enterprise'),
          type: ClassType.ENTERPRISE_PACKAGE,
          tooltip: _t('product.class.tooltip_type_enterprise'),
          allow: isAdminMemo || role === UserRole.EnterpriseTutor,
          isDisabled: tutorType === UserRole.Tutor,
        },
        {
          title: _t('product.class.type_private'),
          type: ClassType.PRIVATE_PACKAGE,
          tooltip: _t('product.class.tooltip_type_private'),
          allow: isMSTAdminMemo || isInstructorMemo,
          isDisabled: false,
        },
        {
          title: _t('product.class.type_public'),
          type: ClassType.PUBLIC_PACKAGE,
          tooltip: _t('product.class.tooltip_type_public'),
          allow: true,
          isDisabled: false,
        },
      ]
    } else if (type === 'content') {
      return [
        {
          title: _t('product.class.type_enterprise'),
          type: ClassType.ENTERPRISE_CONTENT,
          tooltip: _t('product.class.tooltip_type_enterprise'),
          allow: isAdminMemo || role === UserRole.EnterpriseTutor,
          isDisabled: tutorType === UserRole.Tutor,
        },
        {
          title: _t('product.class.type_private'),
          type: ClassType.PRIVATE_CONTENT,
          tooltip: _t('product.class.tooltip_type_private'),
          allow: isMSTAdminMemo || isInstructorMemo,
          isDisabled: false,
        },
        {
          title: _t('product.class.type_public'),
          type: ClassType.PUBLIC_CONTENT,
          tooltip: _t('product.class.tooltip_type_public'),
          allow: true,
          isDisabled: false,
        },
      ]
    } else if (type === 'lesson') {
      return [
        {
          title: _t('product.class.type_enterprise'),
          type: ClassType.ENTERPRISE_LESSON,
          tooltip: _t('product.class.tooltip_type_enterprise'),
          allow: isAdminMemo || role === UserRole.EnterpriseTutor,
          isDisabled: tutorType === UserRole.Tutor,
        },
        {
          title: _t('product.class.type_public'),
          type: ClassType.PUBLIC_LESSON,
          tooltip: _t('product.class.tooltip_type_public'),
          allow: true,
          isDisabled: false,
        },
      ]
    }
    return []
  }, [_t, type, role, tutorType, isAdminMemo, isMSTAdminMemo, isInstructorMemo])

  const renderVisibility = useCallback(() => {
    const typeOptions = getTypeOptions()
    if (typeOptions.length > 0) {
      return (
        <Controller
          control={control}
          name='type'
          rules={{
            required: 'Type is required!',
          }}
          render={({ onChange, value }) => (
            <RadioGroup onChange={onChange} value={value}>
              <Flex direction={{ base: 'column', md: 'row' }}>
                {typeOptions.map((item: any, index: number) => {
                  return (
                    item.allow && (
                      <Radio
                        key={`visibility-${index}`}
                        value={item.type}
                        mr='1rem'
                        mt='0.5rem'
                        isDisabled={item.isDisabled}
                      >
                        <Flex alignItems='center'>
                          <Text fontSize='0.875rem' mr='0.5rem'>
                            {_t(item.title)}
                          </Text>
                          {item.tooltip && (
                            <Tooltip label={_t(item.tooltip)}>
                              <InfoIcon />
                            </Tooltip>
                          )}
                        </Flex>
                      </Radio>
                    )
                  )
                })}
              </Flex>
            </RadioGroup>
          )}
        />
      )
    }
    return <></>
  }, [_t, control, getTypeOptions])

  return (
    <Box>
      <Flex direction={{ base: 'column', md: 'row' }} justify='space-between'>
        <Box flex='1'>
          {isMSTAdminMemo && (
            <Box mt='1.2rem'>
              <Controller
                control={control}
                name='tutorType'
                render={({ onChange, value }) => (
                  <RadioGroup onChange={onChange} value={value}>
                    <Flex alignItems='center'>
                      <Radio value={UserRole.EnterpriseTutor}>
                        <Text fontSize='0.875rem' mr='0.5rem'>
                          {_t('product.class.enterprise')}
                        </Text>
                      </Radio>
                      <Radio
                        value={UserRole.Tutor}
                        isDisabled={type === 'lesson'}
                      >
                        <Text fontSize='0.875rem' mr='0.5rem'>
                          {_t('product.class.private_instructor')}
                        </Text>
                      </Radio>
                    </Flex>
                  </RadioGroup>
                )}
              />
            </Box>
          )}

          {tutorType === UserRole.EnterpriseTutor ? (
            <Flex direction='column'>
              <SimpleGrid columns={[1, 1, 2]} spacing='1.2rem' mt='1.2rem'>
                <Box>
                  <Text fontSize='0.75rem' fontWeight='600' mr='0.5rem'>
                    {_t('product.class.price')}
                  </Text>
                  <Controller
                    render={({ value, onChange }) => (
                      <Input
                        type='number'
                        fontSize='0.875rem'
                        labelProps={{ fontSize: '0.75rem' }}
                        onChange={onChange}
                        onBlur={_e => checkPrice(true)}
                        value={value}
                        leftElement={
                          <Box pb='0.5rem' fontSize='0.875rem'>
                            $
                          </Box>
                        }
                        error={errors?.price?.message}
                        containerBoxProps={{
                          h: '100%',
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'space-between',
                        }}
                      />
                    )}
                    control={control}
                    name='price'
                    label={_t('product.class.price')}
                    rules={{
                      validate: {
                        price: value =>
                          validateMultiple([
                            validateNonNegativeDecimal(value),
                            checkPrice(true),
                          ]),
                      },
                    }}
                  />
                </Box>
                <Box>
                  <Flex direction='row' align='centre'>
                    <Text fontSize='0.75rem' fontWeight='600' mr='0.5rem'>
                      {_t('product.class.usual_price')}
                    </Text>
                    <Tooltip label={_t('product.class.tooltip_usual_price')}>
                      <InfoIcon />
                    </Tooltip>
                  </Flex>
                  <Controller
                    render={({ value, onChange }) => (
                      <Input
                        type='number'
                        fontSize='0.875rem'
                        labelProps={{ fontSize: '0.75rem' }}
                        placeholder={_t('common.optional')}
                        onChange={onChange}
                        onBlur={_e => checkPrice()}
                        value={value}
                        leftElement={
                          <Box pb='0.5rem' fontSize='0.875rem'>
                            $
                          </Box>
                        }
                        error={errorUsualPrice || errors?.usualPrice?.message}
                        containerBoxProps={{
                          h: '100%',
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'space-between',
                        }}
                      />
                    )}
                    control={control}
                    name='usualPrice'
                    rules={{
                      validate: {
                        usualPrice: value =>
                          validateMultiple([
                            validateNonNegativeDecimal(value),
                            checkPrice(),
                          ]),
                      },
                    }}
                  />
                </Box>
              </SimpleGrid>
              <SimpleGrid columns={[1, 1, 2]} spacing='1.2rem' mt='1.2rem'>
                <Box>
                  <Text fontSize='0.75rem' fontWeight='600' mr='0.5rem'>
                    {_t('product.class.enterprise_price')}
                  </Text>
                  <Controller
                    render={({ value, onChange }) => (
                      <Input
                        type='number'
                        fontSize='0.875rem'
                        labelProps={{ fontSize: '0.75rem' }}
                        onChange={onChange}
                        onBlur={_e => checkEnterprisePrice(true)}
                        value={value}
                        leftElement={
                          <Box pb='0.5rem' fontSize='0.875rem'>
                            $
                          </Box>
                        }
                        error={errors?.enterprisePrice?.message}
                        containerBoxProps={{
                          h: '100%',
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'space-between',
                        }}
                      />
                    )}
                    control={control}
                    name='enterprisePrice'
                    label={_t('product.class.enterprise_price')}
                    rules={{
                      validate: {
                        enterprisePrice: value =>
                          validateMultiple([
                            validateNonNegativeDecimal(value),
                            checkEnterprisePrice(true),
                          ]),
                      },
                    }}
                  />
                </Box>
                <Box>
                  <Flex direction='row' align='centre'>
                    <Text fontSize='0.75rem' fontWeight='600' mr='0.5rem'>
                      {_t('product.class.usual_enterprise_price')}
                    </Text>
                    <Tooltip
                      label={_t('product.class.tooltip_usual_enterprise_price')}
                    >
                      <InfoIcon />
                    </Tooltip>
                  </Flex>
                  <Controller
                    render={({ value, onChange }) => (
                      <Input
                        type='number'
                        fontSize='0.875rem'
                        labelProps={{ fontSize: '0.75rem' }}
                        placeholder={_t('common.optional')}
                        onChange={onChange}
                        onBlur={_e => checkEnterprisePrice()}
                        value={value}
                        leftElement={
                          <Box pb='0.5rem' fontSize='0.875rem'>
                            $
                          </Box>
                        }
                        error={
                          errorUsualEnterprisePrice ||
                          errors?.usualEnterprisePrice?.message
                        }
                        containerBoxProps={{
                          h: '100%',
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'space-between',
                        }}
                      />
                    )}
                    control={control}
                    name='usualEnterprisePrice'
                    rules={{
                      validate: {
                        usualEnterprisePrice: value =>
                          validateMultiple([
                            validateNonNegativeDecimal(value),
                            checkEnterprisePrice(),
                          ]),
                      },
                    }}
                  />
                </Box>
              </SimpleGrid>
            </Flex>
          ) : (
            <SimpleGrid columns={[1, 1, 2]} spacing='1.2rem' mt='1.2rem'>
              <Box>
                <Text fontSize='0.75rem' fontWeight='600' mr='0.5rem'>
                  {_t('product.class.price')}
                </Text>
                <Controller
                  render={({ value, onChange }) => (
                    <Input
                      type='number'
                      fontSize='0.875rem'
                      labelProps={{ fontSize: '0.75rem' }}
                      onChange={onChange}
                      onBlur={_e => checkPrice(true)}
                      value={value}
                      leftElement={
                        <Box pb='0.5rem' fontSize='0.875rem'>
                          $
                        </Box>
                      }
                      error={errors?.price?.message}
                      containerBoxProps={{
                        h: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                      }}
                    />
                  )}
                  control={control}
                  name='price'
                  label={_t('product.class.price')}
                  rules={{
                    validate: {
                      price: value =>
                        validateMultiple([
                          validateNonNegativeDecimal(value),
                          checkPrice(true),
                        ]),
                    },
                  }}
                />
              </Box>
              <Box>
                <Flex direction='row' align='centre'>
                  <Text fontSize='0.75rem' fontWeight='600' mr='0.5rem'>
                    {_t('product.class.usual_price')}
                  </Text>
                  <Tooltip label={_t('product.class.tooltip_usual_price')}>
                    <InfoIcon />
                  </Tooltip>
                </Flex>
                <Controller
                  render={({ value, onChange }) => (
                    <Input
                      type='number'
                      fontSize='0.875rem'
                      labelProps={{ fontSize: '0.75rem' }}
                      onChange={onChange}
                      onBlur={_e => checkPrice()}
                      value={value}
                      placeholder={_t('common.optional')}
                      leftElement={
                        <Box pb='0.5rem' fontSize='0.875rem'>
                          $
                        </Box>
                      }
                      error={errorUsualPrice || errors?.usualPrice?.message}
                      containerBoxProps={{
                        h: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                      }}
                    />
                  )}
                  control={control}
                  name='usualPrice'
                  rules={{
                    validate: {
                      usualPrice: value =>
                        validateMultiple([
                          validateNonNegativeDecimal(value),
                          checkPrice(),
                        ]),
                    },
                  }}
                />
              </Box>
            </SimpleGrid>
          )}

          {isAdminMemo && (
            <>
              <Box mt='1.2rem' display={isCenterAdminMemo ? 'none' : ''}>
                <Controller
                  as={Select}
                  control={control}
                  name='centre'
                  label={_t('product.class.enterprise_centre')}
                  error={errors?.centre?.message}
                  rules={{
                    validate: {
                      centre: ({ value }: any) => {
                        if (!value && tutorType === UserRole.EnterpriseTutor) {
                          return _t(
                            'product.class.validate_enterprise_centre_required'
                          )
                        }
                        return true
                      },
                    },
                  }}
                  optionUrl={getListCentreByType(role)}
                  formatOption={formatOption}
                  isDisabled={tutorType === UserRole.Tutor}
                  placeholder={_t(
                    'product.class.placeholder_enterprise_centre'
                  )}
                  labelProps={{ fontSize: '0.75rem' }}
                />
              </Box>

              <Box mt='1.2rem'>
                <Controller
                  as={Select}
                  control={control}
                  name='tutor'
                  label={_t('product.class.instructor')}
                  error={errors?.tutor?.message}
                  rules={{
                    validate: {
                      tutor: ({ value }: any) => {
                        if (!value) {
                          return _t(
                            'product.class.validate_instructor_required'
                          )
                        }
                        return true
                      },
                    },
                  }}
                  optionUrl={getListTutorByType()}
                  formatOption={formatUserOption}
                  placeholder={_t('product.class.placeholder_instructor')}
                  labelProps={{ fontSize: '0.75rem' }}
                  isDisabled={
                    tutorType === UserRole.EnterpriseTutor && !centreId
                  }
                />
              </Box>
            </>
          )}

          <Box mt='1.2rem'>
            <Flex direction='row' fontSize='0.75rem' fontWeight='600'>
              <Text mr='0.5rem'>{_t('product.class.subject_level')}</Text>
              <Tooltip label={_t('product.class.tooltip_subject_level')}>
                <InfoIcon />
              </Tooltip>
            </Flex>
            <Controller
              as={Select}
              control={control}
              name='teachingLevel'
              placeholder={_t('product.class.placeholder_subject_level')}
              error={errors?.teachingLevel?.message}
              rules={{
                validate: {
                  teachingLevel: ({ value }: any) => {
                    if (!value) {
                      return _t('product.class.validate_subject_level_required')
                    }
                    return true
                  },
                },
              }}
              optionUrl={getListUrlByTutor(teachingLevel.userList)}
              formatOption={formatCombinationOption}
              labelProps={{ fontSize: '0.75rem' }}
            ></Controller>
          </Box>
          <Box mt='1.2rem'>
            <Flex direction='row' fontSize='0.75rem' fontWeight='600'>
              <Text mr='0.5rem'>{_t('product.class.subject')}</Text>
              <Tooltip label={_t('product.class.tooltip_subject')}>
                <InfoIcon />
              </Tooltip>
            </Flex>
            <Controller
              as={Select}
              control={control}
              name='subject'
              placeholder={_t('product.class.placeholder_subject')}
              error={errors?.subject?.message}
              rules={{
                validate: {
                  subject: ({ value }: any) => {
                    if (!value) {
                      return _t('product.class.validate_subject_required')
                    }
                    return true
                  },
                },
              }}
              optionUrl={getListUrlByTutor(subject.userList)}
              formatOption={formatCombinationOption}
              labelProps={{ fontSize: '0.75rem' }}
            />
          </Box>
          <Box mt='1.2rem'>
            <Flex direction='row' fontSize='0.75rem' fontWeight='600'>
              <Text mr='0.5rem'>
                {_t('product.class.language_of_instruction')}
              </Text>
              <Tooltip
                label={_t('product.class.tooltip_language_of_instruction')}
              >
                <InfoIcon />
              </Tooltip>
            </Flex>
            <Controller
              as={Select}
              control={control}
              name='language'
              placeholder={_t(
                'product.class.placeholder_language_of_instruction'
              )}
              error={errors?.language?.message}
              rules={{
                validate: {
                  language: ({ value }: any) => {
                    if (!value) {
                      return _t(
                        'product.class.validate_language_of_instruction_required'
                      )
                    }
                    return true
                  },
                },
              }}
              optionUrl={getListUrlByTutor(language.userList)}
              formatOption={formatCombinationOption}
              labelProps={{ fontSize: '0.75rem' }}
            />
          </Box>
        </Box>
        <Box w='4rem' />
        <Box flex='1'>
          <Flex flexDirection='column'>
            <Text fontSize='0.75rem' fontWeight='600' mt='1.2rem'>
              {_t('product.class.visibility')}
            </Text>
            {renderVisibility()}
          </Flex>
          {type !== 'content' && (
            <SimpleGrid columns={[1, 1, 2]} spacing='1.2rem' mt='1.2rem'>
              <>
                <Box>
                  <Flex alignItems='center'>
                    <Controller
                      control={control}
                      name='requireMinimum'
                      render={({ onChange, value }) => (
                        <Checkbox
                          onChange={e => {
                            onChange(e.target.checked)
                          }}
                          checked={value}
                        />
                      )}
                    />
                    <Text fontSize='0.75rem' fontWeight='600' mx='0.5rem'>
                      {type !== 'lesson'
                        ? _t('product.class.min_learners')
                        : _t('product.class.min_learners_start')}
                    </Text>
                    <Tooltip label={_t('product.class.tooltip_min_learners')}>
                      <InfoIcon />
                    </Tooltip>
                  </Flex>
                  <Controller
                    control={control}
                    name='minStudent'
                    render={({ value, onChange }) => (
                      <Input
                        type='number'
                        isDisabled={!requireMinimum}
                        placeholder={_t(
                          'product.class.placeholder_min_learners'
                        )}
                        fontSize='0.875rem'
                        value={value}
                        onChange={onChange}
                        onBlur={_e => checkStudentCount(true)}
                        error={errors?.minStudent?.message}
                      />
                    )}
                    rules={{
                      validate: {
                        minStudent: value =>
                          validateMultiple([
                            validator.isInt(value.toString(), { min: 0 })
                              ? true
                              : _t(
                                  'product.class.validate_min_learners_invalid'
                                ),
                            checkStudentCount(true),
                          ]),
                      },
                    }}
                  />
                </Box>
                <Box>
                  <Flex alignItems='center'>
                    <Text fontSize='0.75rem' fontWeight='600' mr='0.5rem'>
                      {type !== 'lesson'
                        ? _t('product.class.max_learners')
                        : _t('product.class.max_learners_package')}
                    </Text>
                    <Tooltip label={_t('product.class.tooltip_max_learners')}>
                      <InfoIcon />
                    </Tooltip>
                  </Flex>
                  <Controller
                    control={control}
                    name='maxStudent'
                    render={({ value, onChange }) => (
                      <Input
                        type='number'
                        placeholder={_t(
                          'product.class.placeholder_max_learners'
                        )}
                        fontSize='0.875rem'
                        value={value}
                        onChange={onChange}
                        onBlur={_e => checkStudentCount()}
                        error={errorMaxStudents || errors?.maxStudent?.message}
                      />
                    )}
                    rules={{
                      validate: {
                        maxStudent: value =>
                          validateMultiple([
                            validator.isInt(value.toString(), {
                              min: 1,
                              max: 299,
                            })
                              ? true
                              : _t(
                                  'product.class.validate_max_learners_invalid'
                                ),
                            checkStudentCount(),
                          ]),
                      },
                    }}
                  />
                </Box>
              </>
            </SimpleGrid>
          )}
        </Box>
      </Flex>
    </Box>
  )
}

export default DetailSection

import React, { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import { Avatar, Button, Flex, Text } from '@chakra-ui/react'

import { ContentContainer, ImageWrapper } from 'components'
import { useLanguages } from 'hooks'
import { useCentreDetail } from 'queries/centre'

import defaultBanner from 'assets/images/default_centre_banner.jpg'

type CentrePreviewCardProps = {
  centreId: string
}

const CentrePreviewCard: React.FC<CentrePreviewCardProps> = ({ centreId }) => {
  const { _t } = useLanguages()
  const navigate = useNavigate()
  const { data: centreDetails } = useCentreDetail({ centreId })
  const data = useMemo(() => centreDetails?.data || {}, [centreDetails])

  const handleView = () => {
    navigate(`/centre/${centreId}`)
  }

  return (
    <ContentContainer p={0} mt='1rem' maxW='42rem'>
      <ImageWrapper
        w='100%'
        minH='5rem'
        maxH='8rem'
        objectFit='cover'
        bg='primary'
        roundedTop='lg'
        src={data.banner || defaultBanner}
        key={data.banner || 'default-banner'}
      />
      <Flex direction='column' justify='space-between' mx='2rem' pb='1rem'>
        <Flex direction='row' justify='space-between' alignItems='flex-start'>
          <Flex
            direction='row'
            justify='flex-start'
            alignItems='center'
            wrap='wrap'
          >
            <Avatar
              size='xl'
              mr='1rem'
              mt='-2.5rem'
              name={data.name || ''}
              src={data.avatar}
              key={data.avatar || 'default-avatar'}
              css={{
                border: '2px solid white',
              }}
            />
            <Flex direction='column' mt='0.5rem' mr='1rem'>
              <Text fontSize='1.25rem' fontWeight='600' noOfLines={1}>
                {data.name || ''}
              </Text>
              <Text
                fontSize='0.75rem'
                color='gray.300'
                mt='-0.25rem'
                noOfLines={1}
              >
                {data.enterprise?.name || '-'}
              </Text>
            </Flex>
          </Flex>
          <Flex direction='row' mt='1rem'>
            <Button
              color='socialBlue'
              bg='transparent'
              size='sm'
              fontWeight='700'
              fontSize='0.875rem'
              alignItems='center'
              textTransform='uppercase'
              border='solid 1px'
              borderColor='socialBlue'
              rounded='full'
              mt='0.125rem'
              onClick={handleView}
            >
              {_t('user.connection.view')}
            </Button>
          </Flex>
        </Flex>
        {data.description && (
          <Text
            fontSize='0.875rem'
            color='gray.600'
            noOfLines={6}
            mt='1rem'
            whiteSpace='pre-wrap'
          >
            {data.description}
          </Text>
        )}
      </Flex>
    </ContentContainer>
  )
}

export default CentrePreviewCard

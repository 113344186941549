import React from 'react'

import { Alert, Icon } from '@chakra-ui/react'

import { ReactComponent as ShieldIcon } from 'assets/images/shield.svg'

interface AlertProtectProps {
  text: string
}

const AlertProtect: React.FC<AlertProtectProps> = ({ text }) => {
  return (
    <Alert status='info' borderRadius='0.5rem' mb='1rem'>
      <Icon as={ShieldIcon} w='20px' h='20px' mr='0.75rem' />
      {text}
    </Alert>
  )
}

export default AlertProtect

import React from 'react'
import { Control, Controller } from 'react-hook-form'

import { Box, Flex, SimpleGrid, Switch, Text } from '@chakra-ui/react'

import { Input, Select } from 'components'
import { country } from 'configs/apiPath'
import { useLanguages } from 'hooks'
import { formatCombinationOption } from 'utils/parser'

interface DetailEditSectionProps {
  control: Control<any>
  isAllowUpdateStatus: boolean
}

const DetailEditSection: React.FC<DetailEditSectionProps> = ({
  control,
  isAllowUpdateStatus,
}) => {
  const { _t } = useLanguages()

  return (
    <Box
      bg='white'
      py='1.5rem'
      px='2rem'
      boxShadow='lg'
      rounded='lg'
      mt='1.5rem'
    >
      <SimpleGrid mt='1rem' columns={[1, 1, 2, 2]} spacing='2rem'>
        <Controller
          as={Input}
          control={control}
          name='name'
          aria-label='Name'
          label={_t('enterprise.profile.name')}
          placeholder={_t('enterprise.profile.placeholder_name')}
        />
        {isAllowUpdateStatus && (
          <Flex justify='space-between' align='center'>
            <Text width='100%' textStyle='tableRow' fontWeight='600'>
              {_t('enterprise.profile.account_status')}
            </Text>
            <Controller
              control={control}
              name='active'
              render={({ onChange, value }) => (
                <Switch
                  onChange={e => onChange(e.target.checked)}
                  isChecked={value}
                />
              )}
            />
          </Flex>
        )}
      </SimpleGrid>
      <SimpleGrid mt='2rem' columns={[1, 1, 2, 2]} spacing='2rem'>
        <Controller
          as={Input}
          type='email'
          control={control}
          name='email'
          label={_t('enterprise.profile.email')}
          placeholder={_t('enterprise.profile.placeholder_email')}
        />
        <Controller
          as={Input}
          control={control}
          name='contactNumber'
          label={_t('enterprise.profile.contact')}
          placeholder={_t('enterprise.profile.placeholder_contact')}
          optional
        />
        <Controller
          as={Input}
          control={control}
          name='address1'
          label={_t('enterprise.profile.address')}
          placeholder={_t('enterprise.profile.placeholder_address')}
          optional
        />
        <Controller
          as={Input}
          control={control}
          name='city'
          label={_t('enterprise.profile.city')}
          placeholder={_t('enterprise.profile.placeholder_city')}
        />
        <Controller
          as={Input}
          control={control}
          name='zipCode'
          label={_t('enterprise.profile.zip_code')}
          placeholder={_t('enterprise.profile.placeholder_zip_code')}
        />
        <Controller
          as={Select}
          control={control}
          name='country'
          label={_t('enterprise.profile.country')}
          rules={{
            required: _t('enterprise.profile.validate_country_required'),
          }}
          optionUrl={country.list({ page: 1, limit: 20 })}
          formatOption={formatCombinationOption}
        />
      </SimpleGrid>
    </Box>
  )
}

export default DetailEditSection

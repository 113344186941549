import React from 'react'

import { Tab, TabProps } from '@chakra-ui/react'

interface LearnerTabHeaderProps extends TabProps {}

const LearnerTabHeader: React.FC<LearnerTabHeaderProps> = ({
  children,
  ...boxProps
}) => (
  <Tab
    px='1.25rem'
    py='0.5rem'
    textTransform='uppercase'
    borderRadius='1.5rem'
    color='#000'
    bgColor='transparent'
    _selected={{
      color: '#fff',
      bgColor: 'primary',
    }}
    _notLast={{
      mr: '2.5rem',
    }}
    cursor='pointer'
    {...boxProps}
  >
    {children}
  </Tab>
)

export default LearnerTabHeader

import { extendTheme } from '@chakra-ui/react'

const breakpoints: any = ['30em', '48em', '64.1em', '90em']

const baseStylePopper = {
  w: '100%',
  maxW: 'xs',
  zIndex: 10,
}

// add an alias for object responsive prop
breakpoints.sm = breakpoints[0]
breakpoints.md = breakpoints[1]
breakpoints.lg = breakpoints[2]
breakpoints.xl = breakpoints[3]

const customTheme = extendTheme({
  breakpoints,
  styles: {
    global: {
      h1: {
        fontSize: '2rem',
        fontWeight: 500,
        lineHeight: '2.5rem',
      },
      h2: {
        fontSize: '1.125rem',
        fontWeight: 500,
        lineHeight: '1.3rem',
      },
      '.chakra-portal': {
        position: 'absolute',
        top: 0,
      },
    },
  },
  colors: {
    primary: '#837BD8',
    primaryDark: '#4B42C6',
    primaryDarker: '#312A8D',
    primaryLight: '#F3F0FE',
    secondaryLightBlue: '#EDF4F8',
    secondaryHighlightBlue: '#C2E3FC',
    secondaryBlue: '#5C96D6',
    onboardingBg: '#E9FAFF',
    containerBg: '#F8F8F8',
    sidebarBg: '#2B2F34',
    gray: {
      100: '#BDBDBD',
      200: '#E0E0E0',
      300: '#828282',
      500: '#4F4F4F',
      700: '#859199',
    },
    lavender: '#DCEEFC',
    purple: '#4B42C6',
    lightPurple: '#876DF3',
    lightGreen: '#7CCDDA',
    socialBlue: '#54B3C8',
    actionGreen: '#5FC083',
    darkGreen: '#3D7478',
    darkGreen2: '#5D969F',
    lightBlue: '#F2FBFF',
    darkBlue: '#1B3A9B',
    orange: '#D66B5C',
    error: '#F36D6D',
    overdue: '#CC2931',
    tBody: '#fff',
    progressPurple: {
      100: '#B4BBF8',
      200: '#A1AAF7',
      300: '#7D89F4',
      500: '#5666F0',
      700: '#4354EF',
    },
  },
  layerStyles: {
    contentContainer: {
      padding: '0.75rem 1rem 1.25rem',
      backgroundColor: '#fff',
    },
  },
  textStyles: {
    highlightText: {
      fontSize: '0.75rem',
      color: '#3959BF',
      fontWeight: 400,
    },
    authText: {
      fontSize: '0.875rem',
      lineHeight: '1.125rem',
    },
    error: {
      marginTop: '0.125rem',
      fontSize: 'xs',
      color: 'error',
    },
    navItem: {
      display: 'inline-block',
      margin: {
        base: '0 0.75rem',
        xl: '0 1.5rem',
      },
      fontSize: '16px',
      fontWeight: 600,
      lineHeight: '21px',
      textTransform: 'uppercase',
      background: 'linear-gradient(126.41deg, #4B42C6 -0.41%, #3B82D4 100%)',
      backgroundClip: 'text',
      color: 'transparent',
      cursor: 'pointer',
    },
    adminHeadingSection: {
      fontSize: '1.5rem',
      fontWeight: '600',
      lineHeight: '3.5rem',
      background: 'linear-gradient(126.41deg, #4B42C6 -0.41%, #3B82D4 100%)',
      backgroundClip: 'text',
      color: 'transparent',
    },
    headingModalText: {
      fontSize: '2rem',
      fontWeight: '600',
      lineHeight: '2.5rem',
    },
    subHeadingModalText: {
      fontSize: '1.125rem',
      fontWeight: '600',
      lineHeight: '1.5rem',
    },
    assignUserName: {
      fontSize: '0.875rem',
      fontWeight: '700',
      lineHeight: '1.25rem',
    },
    headingTab: {
      lineHeight: '1.25rem',
      textTransform: 'uppercase',
      color: 'gray.700',
    },
    headingTabTutor: {
      lineHeight: '1.25rem',
      textTransform: 'uppercase',
      color: '#000',
    },
    actionTitle: {
      fontWeight: '600',
      lineHeight: '1.25rem',
      textTransform: 'uppercase',
    },
    tableRow: {
      fontSize: '0.875rem',
      lineHeight: '1.125rem',
    },
    infoHeadline: {
      fontWeight: '600',
      fontSize: '0.625rem',
      lineHeight: '0.875rem',
    },
    infoText: {
      fontWeight: '600',
      fontSize: '1rem',
      lineHeight: '1.3125rem',
      color: '#000',
    },
    contentHeader: {
      fontWeight: '600',
      lineHeight: '1.125rem',
      color: 'primaryDarker',
      textTransform: 'uppercase',
    },
    detailTitle: {
      fontWeight: '600',
      fontSize: '0.625rem',
      lineHeight: '0.875rem',
      textTransform: 'uppercase',
    },
    detailText: {
      fontSize: '0.75rem',
      lineHeight: '1rem',
    },
    createTitle: {
      fontWeight: '700',
      fontSize: ['1.25rem', '1.25rem', '1.25rem', '1.375rem'],
      lineHeight: ['2.2rem', '2.25rem'],
    },
  },
  sizes: {
    lg: '30rem',
    xl: '40rem',
    '2xl': '48rem',
    '3xl': '64rem',
    '4xl': '72rem',
  },
  zIndices: {
    header: 20,
    sidebar: 3,
    portal: 1400,
  },
  components: {
    Popover: {
      parts: ['popper'],
      baseStyle: {
        popper: ({ width }: { width: any }) => ({
          ...baseStylePopper,
          maxW: width ? width : baseStylePopper.maxW,
        }),
      },
    },
    Button: {
      baseStyle: {
        borderRadius: '0.5rem',
        borderColor: 'primary',
        backgroundColor: 'primary',
        border: '1px solid',
        color: '#fff',
        _hover: {
          boxShadow:
            '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)',
          transform: 'scale(1.015)',
          transition: '0.5s',
        },
        _focus: {
          outline: 0,
          boxShadow: 'none',
        },
      },
      sizes: {
        md: {
          py: '1.25rem',
          px: '1.5rem',
        },
        lg: {
          fontSize: '1rem',
          py: '1.5rem',
          px: '2.5rem',
        },
      },
      variants: {
        solidPrimary: {
          color: '#fff',
          backgroundColor: 'primary',
          _hover: {
            transform: 'none',
            opacity: 0.8,
          },
        },
        outlinePrimary: {
          color: 'primary',
          backgroundColor: 'transparent',
          _hover: {
            transform: 'none',
            opacity: 0.8,
          },
        },
        solidAction: {
          color: '#fff',
          backgroundColor: 'actionGreen',
          _hover: {
            transform: 'none',
            opacity: 0.8,
          },
        },
        transparent: {
          textTransform: 'uppercase',
          color: 'primary',
          backgroundColor: 'transparent',
          borderWidth: 0,
          _hover: null,
          _focus: {
            outline: '0 !important',
          },
        },
        userModalTab: {
          color: '#fff',
          borderColor: 'purple',
          backgroundColor: 'purple',
        },
      },
      defaultProps: {
        size: 'lg',
        variant: 'solidPrimary',
      },
    },
    Divider: {
      baseStyle: {
        colorSchemes: '#3959BF',
      },
      sizes: {
        xl: '3px',
      },
    },
  },
})

export default customTheme

import React from 'react'

import { Box, Divider, Text } from '@chakra-ui/react'
import { useAtom } from 'jotai'

import { OrderItem } from '../../components'
import { Result } from 'components'
import { useLanguages } from 'hooks'
import { cartAtom } from 'store/cartAtom'
import { CartItem, ResultType } from 'types'

interface OrderProps {
  footerText?: string
}

const Order: React.FC<OrderProps> = ({ footerText }) => {
  const { _t } = useLanguages()
  const [cart] = useAtom(cartAtom)

  return (
    <Box bg='white' rounded='lg' boxShadow='lg' p='1.5rem'>
      <Text as='h2' pb='1.5rem'>
        {_t('payment.order.title')}
      </Text>
      {cart.length ? (
        <Box>
          {cart.map((item: CartItem, index: number) => (
            <OrderItem
              key={`order-item-${index}`}
              title={item.title}
              subtitle={item.subtitle}
              extra={item.extra}
              quantity={item.quantity}
              price={item.price}
            />
          ))}
        </Box>
      ) : (
        <Box mb='2rem'>
          <Divider mb='3rem' />
          <Result title={_t('payment.order.empty')} type={ResultType.CLASS} />
        </Box>
      )}
      {footerText && (
        <Box>
          <Divider my='1.5rem' />
          <Text textAlign='center' fontSize='0.875rem' color='gray.300'>
            {footerText}
          </Text>
        </Box>
      )}
    </Box>
  )
}

export default Order

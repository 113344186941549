import React from 'react'

import { Box, BoxProps, Text } from '@chakra-ui/react'

import { useLanguages } from 'hooks'
import {
  PasswordErrorMessagesProps,
  passwordValidators,
} from 'utils/passwordValidator'

const PasswordAlert: React.FC<PasswordErrorMessagesProps & BoxProps> = ({
  errors,
  ...boxProps
}) => {
  const { _t } = useLanguages()

  return (
    <Box
      as='ul'
      bgColor='lightBlue'
      p='1rem'
      borderRadius='0.8125rem'
      {...boxProps}
    >
      {Object.keys(passwordValidators).map(type => {
        const isError = errors?.types?.[type]

        return (
          <Text
            key={type}
            as='li'
            fontSize='0.8125rem'
            mb='0.35rem'
            color={errors === undefined || isError ? '#000' : 'primary'}
          >
            &#8226; &#160; {_t(passwordValidators[type].message)}
          </Text>
        )
      })}
    </Box>
  )
}

export default PasswordAlert

import React from 'react'

import {
  Flex,
  Icon,
  IconProps,
  Text,
  TextProps,
  TooltipProps,
} from '@chakra-ui/react'

import { Tooltip } from 'components'

interface IconTextProps {
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
  text: string
  tooltipText?: string
  iconProps?: IconProps
  textProps?: TextProps
  tooltipProps?: TooltipProps
}

const IconText: React.FC<IconTextProps> = ({
  icon,
  text,
  tooltipText,
  iconProps,
  textProps,
  tooltipProps,
}) => {
  const innerFlex = (
    <Flex flexDirection='row' justifyContent='flex-start'>
      <Icon as={icon} w='1rem' h='1rem' mr='0.5rem' {...iconProps} />
      <Text lineHeight='1.125rem' {...textProps}>
        {text}
      </Text>
    </Flex>
  )

  if (tooltipText) {
    return (
      <Tooltip placement='top' {...tooltipProps} label={tooltipText}>
        {innerFlex}
      </Tooltip>
    )
  }

  return innerFlex
}

export default IconText

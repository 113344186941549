import React, { useCallback, useMemo } from 'react'

import { Box, Flex, Link, SimpleGrid, Text } from '@chakra-ui/react'
import { useAtom } from 'jotai'

import CentreInstructorSection from './InstructorSection'
import { LoadingSpinner } from 'components'
import storageKey from 'configs/storageKey'
import { useLanguages } from 'hooks'
import { useCentreTeachingSubjectList } from 'queries/centre'
import { userProfileAtom } from 'store/authAtom'
import { Centre, GroupedCombination, Language } from 'types'
import { getData, isLearner } from 'utils/helper'
import { getYoutubeUrl } from 'utils/parser'

import { ReactComponent as YouTubePlay } from 'assets/images/play.svg'

interface CentreDetailProps {
  data: Centre
}

const TeachingItem: React.FC<{ item: GroupedCombination }> = ({ item }) => (
  <Box mt='0.5rem'>
    <Text fontSize='0.75rem' mb='0.5rem'>
      {`${item.country.name} - ${item.system.name}`}
    </Text>
    <Flex flexWrap='wrap'>
      {item.teachingLevels.map(level => (
        <Box
          key={level.id}
          mr='0.5rem'
          mb='0.5rem'
          px='1rem'
          py='0.5rem'
          bgColor='rgba(139, 206, 255, 0.5)'
          rounded='full'
        >
          <Text>{`${item.subject.name}: ${level.name}`}</Text>
        </Box>
      ))}
    </Flex>
  </Box>
)

const CentreDetail: React.FC<CentreDetailProps> = ({ data }) => {
  const { _t } = useLanguages()
  const [userProfile] = useAtom(userProfileAtom)

  const isGuest = getData(storageKey.loginType) === 'guest'
  const isLearnerMemo = useMemo(() => isLearner(userProfile), [userProfile])

  const {
    data: subjectData,
    isLoading: subjectDataLoading,
  } = useCentreTeachingSubjectList({
    centreId: data?.id || '',
  })

  const renderItem = useCallback(
    (label, value, capitalize = false, preserveLines = false) => {
      return (
        <Box>
          <Text fontSize='0.75rem' color='gray.300' textTransform='uppercase'>
            {label}
          </Text>
          <Text
            {...(capitalize ? { textTransform: 'capitalize' } : {})}
            {...(preserveLines ? { whiteSpace: 'pre-wrap' } : {})}
          >
            {value || '-'}
          </Text>
        </Box>
      )
    },
    []
  )

  const getTeachingLanguages = (languages: Language[] = []) => {
    return languages?.map(item => item.name).join(', ')
  }

  if (!data) {
    return <LoadingSpinner />
  }

  return (
    <Box>
      <Box>
        <Box mb='1.25rem'>
          {renderItem(
            _t('centre.profile.description'),
            data.description,
            false,
            true
          )}
        </Box>
        {data.youtubeLink && (
          <Box mb='1.25rem'>
            <Text fontSize='0.75rem' color='gray.300' textTransform='uppercase'>
              {_t('centre.profile.video')}
            </Text>
            <Link
              href={data.youtubeLink}
              alignItems='center'
              mt='0.5rem'
              display={{ base: 'flex', sm: 'none' }}
              isExternal
            >
              <YouTubePlay />
              <Text ml='0.25rem' fontSize='1rem'>
                {_t('centre.profile.play_video')}
              </Text>
            </Link>
            <Box
              as='iframe'
              src={getYoutubeUrl(data.youtubeLink || '')}
              allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
              allowFullScreen
              w={{ base: '24rem', md: '30rem' }}
              h={{ base: '13.5rem', md: '16.875rem' }}
              my='0.5rem'
              borderRadius='0.25rem'
              display={{ base: 'none', sm: 'block' }}
            />
          </Box>
        )}
        <Box mb='1.25rem'>
          {renderItem(_t('centre.profile.enterprise'), data?.enterprise?.name)}
        </Box>
        <SimpleGrid columns={{ base: 1, lg: 2 }} spacingY='1.25rem'>
          {!isGuest && renderItem(_t('centre.profile.email'), data?.email)}
          {!isGuest &&
            renderItem(_t('centre.profile.contact'), data?.contactNumber)}
          {renderItem(
            _t('centre.profile.teaching_language'),
            getTeachingLanguages(data?.teachingLanguages)
          )}
          {renderItem(_t('centre.profile.address'), data?.address)}
        </SimpleGrid>
      </Box>

      <Box mt='2rem'>
        <Text fontWeight='600' textTransform='uppercase'>
          {_t('centre.profile.teaching_subjects')}
        </Text>
        <Box mt='1rem'>
          {subjectDataLoading && <LoadingSpinner />}
          {subjectData?.data?.map((combine, index) => (
            <TeachingItem key={`item-${index}`} item={combine} />
          ))}
        </Box>
      </Box>

      {isLearnerMemo && data.id && data.id !== userProfile?.centers?.[0].id && (
        <CentreInstructorSection data={data} />
      )}
    </Box>
  )
}

export default CentreDetail

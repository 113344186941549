import { useLocation } from 'react-router-dom'

import qs from 'query-string'

const useRedirectPath = (isDecode = true) => {
  const { pathname, search } = useLocation()
  const { redirectPath } = qs.parse(search, { decode: isDecode })

  return redirectPath && (pathname === '/sign-in' || pathname === '/sign-up')
    ? (redirectPath as string)
    : null
}

export default useRedirectPath

import React, { useMemo } from 'react'

import EnterpriseLayout from '../Layout'
import {
  EnterpriseAbout,
  EnterpriseHeader,
  EnterpriseSubscription,
} from './containers'
import { useQueryParams } from 'hooks'
import { EnterpriseDetail } from 'types'

interface EnterpriseProfileContainerProps {
  data: EnterpriseDetail
  tabData: any[]
}

type TabId = 'about' | 'connection' | 'subscription'

const EnterpriseProfileContainer: React.FC<EnterpriseProfileContainerProps> = ({
  data,
  tabData,
}) => {
  const query = useQueryParams()

  const currentTab = useMemo<TabId>(() => (query.tab || 'about') as TabId, [
    query,
  ])

  return (
    <EnterpriseLayout
      topElement={
        <EnterpriseHeader
          data={data}
          tabData={tabData}
          currentTab={currentTab}
        />
      }
      tabData={tabData}
      currentTab={currentTab}
    >
      {currentTab === 'about' && <EnterpriseAbout data={data} />}
      {currentTab === 'subscription' && (
        <EnterpriseSubscription enterpriseId={data.id} />
      )}
    </EnterpriseLayout>
  )
}

export default EnterpriseProfileContainer

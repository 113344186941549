import { UseToastOptions } from '@chakra-ui/react'
import { AxiosResponse } from 'axios'
import { format, formatDistance, getUnixTime } from 'date-fns'
import { format as formatTZ, utcToZonedTime } from 'date-fns-tz'

import { Option } from 'components'
import {
  ClassSessionItem,
  ClassType,
  FileInfo,
  MeetingType,
  NotificationOnApp,
  ParseNotificationTimeType,
  RedirectActionType,
  UserProfile,
} from 'types'
import { Connection } from 'types/connection'

export const formatSelection = (value: string | undefined) => {
  if (!value) return undefined

  return {
    value,
    label: value,
  }
}

export const getFullName = (user?: UserProfile | Connection) => {
  const fullName = `${user?.firstName} ${user?.lastName}`
    .replaceAll('undefined', '')
    .trim()
  const name = `${user?.name}`.replaceAll('undefined', '').trim()

  const email = user?.email || ''

  return fullName || name || email || '-'
}

export const getDisplayName = (firstName: string, lastName: string) => {
  const fullName = `${firstName} ${lastName}`.replaceAll('undefined', '').trim()

  return fullName || '-'
}

export const getErrorMessage = (error: any): string => {
  if (error?.data?.error?.message) {
    const err = error.data.error
    if (!err.details?.length) {
      return err.message
    }

    return err.details.join(', ')
  }

  return 'GeneralError'
}

export const getErrorData = (error: AxiosResponse): any => {
  const err = error?.data?.error
  return err?.data || {}
}

export const getFormatDefaultValue = <
  T extends {
    id?: string
    name: string
    code?: string
  }
>(
  raw: T
) => {
  if (raw?.id) {
    return {
      label: raw.name,
      value: raw.id,
    }
  }

  return {
    label: '',
    value: '',
  }
}

export const formatAmount = (amount: number) => {
  return amount.toLocaleString('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })
}

export const formatCombinationOption = <
  T extends {
    id: string
    name: string
    code: string
  }
>(
  raw: T[]
) => {
  return raw.map(item => ({
    label: item.name,
    value: item.id,
  }))
}

export const getSelectedOptions = (options: Option[], value: unknown) => {
  if (value) {
    return options.find(opt => opt.value === value) || options[0]
  }

  return options[0]
}

export const getDefaultOptions = (options: Option[], value: any) => {
  return value ? options.find(option => option.value === value) : undefined
}

export const getFileInfo = (file: File): FileInfo => {
  const fileTypes = file.type.split('/')
  const extension = fileTypes[fileTypes.length - 1]

  return {
    fileType: extension,
    contentType: file.type,
  }
}

export const getFileBaseName = (file: File): string => {
  return file.name.replace(/\.[^/.]+$/, '') || ''
}

export const getFileExtension = (file: File): string => {
  return file.name.split('.').pop() || ''
}

const suffixes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
export const getBytes = (bytes: number) => {
  const i = Math.floor(Math.log(bytes) / Math.log(1024))

  return (
    (!bytes && '0 Bytes') ||
    (bytes / Math.pow(1024, i)).toFixed(2) + ' ' + suffixes[i]
  )
}

export const formatOption = <
  T extends {
    id: string
    name: string
  }
>(
  raw: T[]
) => {
  return raw.map(item => ({
    label: item.name,
    value: item.id,
  }))
}

export const formatUserOption = <
  T extends {
    id: string
    firstName: string
    lastName: string
    email?: string
  }
>(
  raw: T[]
) => {
  return raw.map(item => ({
    label:
      item.firstName || item.lastName
        ? `${item.firstName} ${item.lastName}`
        : item.email,
    value: item.id,
  }))
}

export const formattingDate = (time?: number) => {
  return time ? format(new Date(time), 'dd/MM/yyyy') : '-'
}

export const getYoutubeUrl = (url: string) => {
  try {
    if (url) {
      // eslint-disable-next-line
      const pattern = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/
      const matches = url.match(pattern)
      if (matches && matches[2].length === 11) {
        return `https://www.youtube.com/embed/${matches[2]}`
      } else {
        return 'https://www.youtube.com/'
      }
    }
  } catch (ex) {}
  return 'https://www.youtube.com/'
}

export const parseTimeDistance = (timestamp?: number) => {
  const currentDate = new Date()
  if (timestamp) {
    const timing = new Date(timestamp)
    return formatDistance(timing, currentDate, { addSuffix: true })
  }
  return ''
}

export const getLanguages = (data: UserProfile) => {
  const languages = data?.languages || []
  return languages.map(l => l.name).join(', ')
}

export const getCentres = (data: UserProfile) => {
  const centers = data?.centers || []
  return centers.map(l => l.name).join(', ')
}

export const getQueryParams = (params: any) => {
  return Object.keys(params)
    .map(key => {
      return `${encodeURIComponent(key)}=${encodeURIComponent(
        params[key] as string
      )}`
    })
    .join('&')
}

export const generateDuration = (
  startTime?: string | number,
  endTime?: string | number
) => {
  if (startTime && endTime) {
    const formatStartTime = new Date(startTime).getTime()
    const formatEndTime = new Date(endTime).getTime()

    const durationHour = ((formatEndTime - formatStartTime) / 3600000).toFixed(
      1
    )

    return `${durationHour} hours`
  }
  return '-'
}

export const formatDateWithZone = (
  date: number,
  {
    formatStr = 'DD/MM/YYYY',
    tz = 'Asia/Singapore',
  }: { formatStr?: string; tz?: string }
) => {
  const dateWithZone = utcToZonedTime(date, tz)

  return formatTZ(dateWithZone, formatStr, { timeZone: tz })
    .replace('AM', 'am')
    .replace('PM', 'pm')
}

export const formatDate = (
  date: number,
  { formatStr = 'DD/MM/YYYY' }: { formatStr?: string }
) => {
  return format(new Date(date), formatStr)
    .replace('AM', 'am')
    .replace('PM', 'pm')
}

export const getUpcomingSession = (
  sessions: ClassSessionItem[]
): ClassSessionItem | undefined => {
  const currentTime = new Date().getTime()
  let resultList: ClassSessionItem[] = []

  const list = sessions
    .filter(s => currentTime < s.endDateTime + 1800000)
    .sort((a, b) => {
      if (a.startDateTime < b.startDateTime) return -1
      if (a.startDateTime > b.startDateTime) return 1
      return 0
    })

  // this is for the case: a package have 2 sessions
  // the session[1] has (endDateTime + 1800000) > (startDateTime) of the session[2]
  if (list.length > 1 && currentTime >= list[1].startDateTime) {
    resultList = list.slice(1)
  } else {
    resultList = list
  }

  if (resultList.length === 0) {
    return undefined
  }

  return resultList[0]
}

export const getClassType = (classType?: string) => {
  if (!classType) return ''

  if (
    classType === ClassType.PRIVATE_REGULAR ||
    classType === ClassType.PUBLIC_REGULAR ||
    classType === ClassType.ENTERPRISE_REGULAR ||
    classType === ClassType.ENTERPRISE_PACKAGE ||
    classType === ClassType.PUBLIC_PACKAGE ||
    classType === ClassType.PRIVATE_PACKAGE
  ) {
    return 'class'
  }

  if (
    classType === ClassType.PRIVATE_CONTENT ||
    classType === ClassType.PUBLIC_CONTENT ||
    classType === ClassType.ENTERPRISE_CONTENT
  ) {
    return 'content'
  }

  if (
    classType === ClassType.ENTERPRISE_LESSON ||
    classType === ClassType.PUBLIC_LESSON
  ) {
    return 'lesson'
  }

  if (classType === ClassType.ADHOC_REQUEST) {
    return 'adhocClass'
  }
}

export const parseTimeNotification = (time: string | undefined) => {
  if (time) {
    const hasDotAtEnd = time[time.length - 1].includes('.')
    const timeArr = hasDotAtEnd
      ? time.replace('.', '').split('_')
      : time.split('_')
    const value = Number(timeArr[0])
    const parseType = timeArr[1]
    let returnedValue = ''

    switch (parseType) {
      case ParseNotificationTimeType.DATE:
        returnedValue = format(new Date(value), 'dd/MM/yyyy')
        break
      case ParseNotificationTimeType.DATE_TIME:
        returnedValue = format(new Date(value), 'dd/MM/yyyy, h:mma')
        break
      case ParseNotificationTimeType.TIME:
        returnedValue = format(new Date(value), 'h:mma')
        break

      default:
        break
    }
    return hasDotAtEnd ? returnedValue.concat('.') : returnedValue
  }

  return ''
}

export const getNumber = (num: number, position: number) => {
  let decNum = num.toString().split('.')[1]?.length || 0

  if (decNum <= position) {
    return num
  }

  return num.toFixed(position)
}

export const getUrlForNotification = (
  notification: NotificationOnApp,
  isMSTAdmin: boolean = false
) => {
  const redirectType = notification?.actionType

  if (redirectType) {
    const classId = notification?.classId
    const classType = notification?.classType

    switch (redirectType) {
      //----- STUDENT URL
      case RedirectActionType.INVITED_STUDENT:
        if (classType && classId) {
          const type = getClassType(classType)
          if (type === 'class') {
            return `/product/class/${classId}`
          }
          if (type === 'content') {
            return `/product/content/${classId}`
          }
          if (type === 'lesson') {
            return `/product/lesson/${classId}`
          }
        }
        return ''

      case RedirectActionType.TUTOR_BID_ADHOC:
        const { requestID } = notification

        if (requestID) {
          return `/my-courses/request-detail/${requestID}`
        }
        return ''

      //----- TUTOR URL
      case RedirectActionType.PURCHASED_CLASS:
        if (classType && classId) {
          const type = getClassType(classType)
          if (type === 'class') {
            return isMSTAdmin
              ? `/admin/session-management/preview/class/${classId}`
              : `/product/class/${classId}`
          }
          if (type === 'content') {
            return isMSTAdmin
              ? `/admin/session-management/preview/content/${classId}`
              : `/product/content/${classId}`
          }
          if (type === 'lesson') {
            return isMSTAdmin
              ? `/admin/session-management/preview/lesson/${classId}`
              : `/product/lesson/${classId}`
          }
        }
        return ''

      case RedirectActionType.LEARNER_CANCEL_CLASS:
        return '/wallet?tab=refund'

      case RedirectActionType.STUDENT_SUBMIT_ASSIGNMENT:
        if (notification.assignmentId) {
          return `/my-courses/assignments/${notification.assignmentId}`
        }
        return '/my-courses/assignments'

      default:
        return ''
    }
  }
}

export const parseMeetingType = (type: string) => {
  switch (type) {
    case MeetingType.PHYSICAL:
      return 'In-Person'
    case MeetingType.VIRTUAL_DEFAULT:
    case MeetingType.VIRTUAL_CUSTOM:
      return 'Virtual'
    default:
      return '-'
  }
}

export const parseMeetingTypes = <T extends { meetingType?: string }>(
  sessions: T[]
) => {
  if (!sessions || sessions?.length === 0) {
    return ''
  }

  const physicalSession = sessions.filter(
    (session: T) => session?.meetingType === MeetingType.PHYSICAL
  )

  const virtualSession = sessions.filter(
    (session: T) => session?.meetingType !== MeetingType.PHYSICAL
  )

  if (physicalSession.length > 0 && virtualSession.length > 0) {
    return 'Virtual & In-Person'
  } else if (physicalSession.length > 0) {
    return 'In-Person'
  } else if (virtualSession.length > 0) {
    return 'Virtual'
  }
  return ''
}

export const parseClassType = (type: string) => {
  switch (type) {
    case ClassType.PRIVATE_REGULAR:
    case ClassType.PRIVATE_PACKAGE:
      return 'Private Class'
    case ClassType.PUBLIC_REGULAR:
    case ClassType.PUBLIC_PACKAGE:
      return 'Public Class'
    case ClassType.ENTERPRISE_REGULAR:
    case ClassType.ENTERPRISE_PACKAGE:
      return 'Enterprise Class'
    case ClassType.PRIVATE_CONTENT:
      return 'Private Content'
    case ClassType.PUBLIC_CONTENT:
      return 'Public Content'
    case ClassType.ENTERPRISE_CONTENT:
      return 'Enterprise Content'
    case ClassType.ADHOC_REQUEST:
      return 'Adhoc Class'
    case ClassType.ENTERPRISE_LESSON:
      return 'Enterprise Lesson'
    case ClassType.PUBLIC_LESSON:
      return 'Public Lesson'
    default:
      return 'Unknown'
  }
}

export const parseClassAccess = (type: string) => {
  switch (type) {
    case ClassType.PRIVATE_REGULAR:
    case ClassType.PRIVATE_PACKAGE:
    case ClassType.PRIVATE_CONTENT:
      return 'Private'
    case ClassType.PUBLIC_REGULAR:
    case ClassType.PUBLIC_PACKAGE:
    case ClassType.PUBLIC_CONTENT:
    case ClassType.PUBLIC_LESSON:
      return 'Public'
    case ClassType.ENTERPRISE_REGULAR:
    case ClassType.ENTERPRISE_PACKAGE:
    case ClassType.ENTERPRISE_CONTENT:
    case ClassType.ENTERPRISE_LESSON:
      return 'Enterprise'
    default:
      return ''
  }
}

export const buildMeetingLink = (data: any) => {
  const meetingNumber = data.meetingId
  const signature = data.signature

  return `/meeting?id=${meetingNumber}&signature=${signature}`
}

export const parseErrorToastMsg = (msg: string): UseToastOptions => ({
  title: 'Error!',
  description: msg,
  status: 'error',
})

export const parseDateTimeUnix = (date?: Date, time?: Date) => {
  const today = getUnixTime(new Date().setHours(0, 0, 0, 0))
  const dateUnix = date ? getUnixTime(new Date(date).setHours(0, 0, 0, 0)) : 0
  const timeUnix = time ? getUnixTime(time) - today : 0
  return (dateUnix + timeUnix) * 1000
}

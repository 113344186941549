import { useMutation } from 'react-query'

import { AxiosResponse } from 'axios'

import { useToastError } from './helpers'
import { adminPurchase, payment, purchase } from 'apis/order'
import { bid, walletPath } from 'configs/apiPath'
import { useInvalidateUrl, useLanguages, useLoading, useToast } from 'hooks'
import { WalletInfoRequest } from 'types'
import { sleep } from 'utils/helper'

export const usePurchaseOrder = () => {
  const { setLoading } = useLoading()

  return useMutation(purchase, {
    onMutate: () => setLoading(true),
    onSettled: () => setLoading(false),
    onSuccess: () => {},
    onError: useToastError(),
  })
}

export const useAdminPurchaseOrder = (requestId: string) => {
  const toast = useToast()
  const { setLoading } = useLoading()
  const invalidateUrl = useInvalidateUrl(bid.adminList(requestId))
  const { _t } = useLanguages()

  return useMutation(adminPurchase, {
    onMutate: () => setLoading(true),
    onSettled: () => setLoading(false),
    onSuccess: () => {
      invalidateUrl()
      toast({
        title: 'Success!',
        description: _t('message.success.bid_admin_accept'),
        status: 'success',
      })
    },
    onError: useToastError(),
  })
}

export const usePurchasePayment = ({ type, id }: WalletInfoRequest) => {
  const toast = useToast()
  const invalidateWallet = useInvalidateUrl(walletPath.show({ type, id }))
  const { setLoading } = useLoading()
  const { _t } = useLanguages()

  return useMutation(payment, {
    onMutate: () => setLoading(true),
    onSettled: () => setLoading(false),
    onSuccess: async (response: AxiosResponse) => {
      const { success, payUrl } = response?.data?.data
      if (success && payUrl) {
        invalidateWallet()
      } else {
        await sleep(5000)
        invalidateWallet()
        toast({
          title: 'Success!',
          description: _t('message.success.wallet_payment'),
          status: 'success',
        })
      }
    },
    onError: useToastError(),
  })
}

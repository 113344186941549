import React from 'react'

import { Box, Button, Flex, Text, useDisclosure } from '@chakra-ui/react'

import {
  EditTeachingSubjectModal,
  TeachingSubjectLevelItemGroup,
} from 'components'
import { useLanguages } from 'hooks'
import { GroupedCombination } from 'types'

interface CreateTeachingSubjectSectionProps {
  combinations: GroupedCombination[]
  handleUpdateCombination: (newCombinations: GroupedCombination[]) => void
}

const CreateTeachingSubjectSection: React.FC<CreateTeachingSubjectSectionProps> = ({
  combinations,
  handleUpdateCombination,
}) => {
  const { _t } = useLanguages()
  const { onOpen, isOpen, onClose } = useDisclosure()

  const handleRemoveCombinationItem = (subjectId: string) => {
    const newCombinations = combinations.map(combination => ({
      ...combination,
      teachingLevels: combination.teachingLevels.filter(
        subject => subject.subjectCombinationId !== subjectId
      ),
    }))

    handleUpdateCombination(newCombinations)
  }

  return (
    <>
      <Box w='100%'>
        <Flex justifyContent='space-between' alignItems='center'>
          <Text textStyle='authText'>
            {_t('user.profile.teaching_subjects_and_levels')}
          </Text>
          <Button
            variant='outlinePrimary'
            py='0.5rem'
            px='1rem'
            size='md'
            onClick={onOpen}
          >
            {_t('common.add')}
          </Button>
        </Flex>
        <Flex
          direction='column'
          justifyContent='center'
          mt='1rem'
          p='1rem'
          h='auto'
          minHeight='32rem'
          bg='#F2FBFF'
          borderRadius='4px'
        >
          {combinations.length > 0 ? (
            <Box mb='auto'>
              <TeachingSubjectLevelItemGroup
                data={combinations}
                onRemoveItem={handleRemoveCombinationItem}
              />
            </Box>
          ) : (
            <Text
              fontSize='0.75rem'
              fontWeight='400'
              color='gray.300'
              textAlign='center'
            >
              {_t('user.profile.teaching_subjects_and_levels_empty')}
            </Text>
          )}
        </Flex>
      </Box>
      <EditTeachingSubjectModal
        isOpen={isOpen}
        onClose={onClose}
        userId={''}
        initData={combinations}
        isNoNeedUpdateForUser
        handleReturnData={handleUpdateCombination}
      />
    </>
  )
}

export default CreateTeachingSubjectSection

import React, { useCallback, useState } from 'react'

import { useAtom } from 'jotai'

import { Period } from 'components/Calendar'
import DayCalendar from 'components/DayCalendar'
import { useCalendar } from 'queries/calendar'
import { userProfileAtom } from 'store/authAtom'

interface CalendarRequestControllerProps {
  tutorId?: string
  roleName: string
}

const CalendarRequestController = ({
  tutorId,
  roleName,
}: CalendarRequestControllerProps) => {
  const [userProfile] = useAtom(userProfileAtom)
  const role = userProfile?.roles?.[0]
  const isTutor = role?.name?.toLowerCase()?.includes('tutor')
  const [period, setPeriod] = useState<Period>()
  const { data: calendarData } = useCalendar({
    tutorId,
    roleName,
    fromDate: period?.from?.getTime(),
    toDate: period?.to?.getTime(),
  })

  const handleChangePeriod = useCallback((newPeriod: Period) => {
    setPeriod(newPeriod)
  }, [])

  console.log(calendarData?.data)
  console.log(calendarData?.data?.[0].data)

  return (
    <DayCalendar
      events={calendarData?.data?.[0]?.data?.map(o => ({
        id: parseInt(o.id),
        start: new Date(o.startDateTime),
        end: new Date(o.endDateTime),
        title: o.name,
      }))}
      onChangePeriod={handleChangePeriod}
      isTutor={isTutor}
    />
  )
}

export default CalendarRequestController

import React, { useMemo } from 'react'
import { Control, Controller, DeepMap, FieldError } from 'react-hook-form'

import { Box, Flex, Grid, Switch, Text } from '@chakra-ui/react'

import {
  CreateCentreAdminForm,
  CreateEnterpriseAdminForm,
  CreateMstAdminForm,
  EditCentreAdminForm,
  EditEnterpriseAdminForm,
  EditMstAdminForm,
} from '../forms'
import ProfileLayout from '../ProfileLayout'
import {
  Avatar,
  AvatarRef,
  ContentContainer,
  DateTimePicker,
  Input,
  LoadingSpinner,
  Select,
} from 'components'
import { enterprise } from 'configs/apiPath'
import { useLanguages } from 'hooks'
import { UserProfile } from 'types'
import { countryOptions } from 'utils/constant'
import { formatOption } from 'utils/parser'
import { EMAIL_PATTERN, MOBILE_NUMBER_PATTERN } from 'utils/regexp'

interface EditAdminProfileProps {
  enterpriseId?: string
  toBackLabel?: string
  title: string
  handleToBack: () => void
  handleSubmit: (sh: any, seh?: any) => any
  handleSubmitCall: (values?: any) => any
  profileWatch?: any
  isLoading: boolean
  avatarRef?: React.MutableRefObject<AvatarRef | undefined>
  userDetail?: UserProfile
  control:
    | Control<CreateCentreAdminForm>
    | Control<CreateEnterpriseAdminForm>
    | Control<CreateMstAdminForm>
    | Control<EditCentreAdminForm>
    | Control<EditEnterpriseAdminForm>
    | Control<EditMstAdminForm>
  formErrors:
    | DeepMap<CreateCentreAdminForm, FieldError>
    | DeepMap<CreateEnterpriseAdminForm, FieldError>
    | DeepMap<CreateMstAdminForm, FieldError>
    | DeepMap<EditCentreAdminForm, FieldError>
    | DeepMap<EditEnterpriseAdminForm, FieldError>
    | DeepMap<EditMstAdminForm, FieldError>
  isEdit?: boolean
  showEnterprise?: boolean
  showCentre?: boolean
  showStatus?: boolean
}

const EditAdminProfileContainer: React.FC<EditAdminProfileProps> = ({
  enterpriseId,
  toBackLabel,
  title,
  handleToBack,
  handleSubmit,
  handleSubmitCall,
  profileWatch,
  isLoading,
  avatarRef,
  userDetail,
  control,
  formErrors,
  isEdit = false,
  showEnterprise = false,
  showCentre = false,
  showStatus = false,
}) => {
  const { _t } = useLanguages()

  const selectedEnterprise = useMemo(
    () => profileWatch && profileWatch('enterprise'),
    [profileWatch]
  )

  return (
    <ProfileLayout
      title={title}
      backLabel={toBackLabel}
      handleCancel={handleToBack}
      handleSubmit={handleSubmit(handleSubmitCall)}
      isCreate={!isEdit}
      isAdminView
    >
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <ContentContainer title={_t('profile.heading.about')}>
            <Flex
              mt='1.5rem'
              flexDirection={{ base: 'column', md: 'row' }}
              alignItems={{ base: 'center', md: 'flex-start' }}
            >
              {avatarRef && (
                <Avatar
                  ref={avatarRef as React.Ref<AvatarRef>}
                  src={userDetail?.avatar || ''}
                  alt='user avatar'
                  objectFit='cover'
                  w='5rem'
                  h='5rem'
                  editable
                  mr={{ base: '0', md: '2rem' }}
                />
              )}

              <Box w={avatarRef ? '90%' : '100%'} mt='1rem'>
                <Grid
                  gridTemplateColumns={{ base: '1fr', md: 'repeat(2, 1fr)' }}
                  gap='1.5rem'
                >
                  <Controller
                    as={Input}
                    control={control}
                    name='firstName'
                    aria-label='First Name'
                    label={_t('user.profile.first_name')}
                    error={formErrors?.firstName?.message}
                    rules={{
                      required: _t('user.profile.validate_first_name_required'),
                    }}
                  />
                  <Controller
                    as={Input}
                    control={control}
                    name='lastName'
                    aria-label='Last Name'
                    label={_t('user.profile.last_name')}
                    error={formErrors?.lastName?.message}
                    rules={{
                      required: _t('user.profile.validate_last_name_required'),
                    }}
                  />
                  <Controller
                    as={Input}
                    control={control}
                    name='email'
                    aria-label='Email'
                    label={_t('user.profile.email')}
                    error={formErrors?.email?.message}
                    rules={{
                      validate: {
                        email: value => {
                          if (!value?.trim()) {
                            return _t('user.profile.validate_email_required')
                          }
                          return true
                        },
                      },
                      pattern: {
                        value: EMAIL_PATTERN,
                        message: _t('user.profile.validate_email_invalid'),
                      },
                    }}
                  />
                  <Controller
                    as={Input}
                    control={control}
                    name='recoveryEmail'
                    aria-label='Recovery Email'
                    label={_t('user.profile.recovery_email')}
                    error={formErrors?.recoveryEmail?.message}
                    rules={{
                      pattern: {
                        value: EMAIL_PATTERN,
                        message: _t(
                          'user.profile.validate_recovery_email_invalid'
                        ),
                      },
                    }}
                  />
                  <Controller
                    control={control}
                    name='birthday'
                    render={({ onChange, value }) => (
                      <DateTimePicker
                        optional
                        label={_t('user.profile.date_of_birth')}
                        onChange={onChange}
                        selected={value}
                        placeholderText={_t(
                          'user.profile.placeholder_date_of_birth'
                        )}
                        maxDate={new Date()}
                        showMonthDropdown
                        showYearDropdown
                      />
                    )}
                  />
                  {showEnterprise && (
                    <Controller
                      as={Select}
                      control={control}
                      name='enterprise'
                      label={_t('user.profile.enterprise')}
                      aria-label='Enterprise'
                      placeholder={_t('user.profile.placeholder_enterprise')}
                      optionUrl={enterprise.list({ page: 1, limit: 80 })}
                      formatOption={formatOption}
                      {...('enterprise' in formErrors && {
                        error: formErrors?.enterprise?.message,
                      })}
                      rules={{
                        required: _t(
                          'user.profile.validate_enterprise_required'
                        ),
                      }}
                    />
                  )}
                  {showCentre && (
                    <Controller
                      as={
                        <Select
                          optionUrl={enterprise.centresForEnterprise(
                            selectedEnterprise?.value ||
                              (!showEnterprise && enterpriseId) ||
                              '',
                            1,
                            80
                          )}
                          formatOption={formatOption}
                          containerProps={{
                            mt: 0,
                          }}
                          isDisabled={
                            showEnterprise && !selectedEnterprise?.value
                          }
                        />
                      }
                      control={control}
                      name='centre'
                      label={_t('user.profile.centre')}
                      {...('centre' in formErrors && {
                        error: formErrors?.centre?.message,
                      })}
                      rules={{
                        required: _t('user.profile.validate_centre_required'),
                      }}
                    />
                  )}
                  {showStatus && isEdit && (
                    <>
                      <Flex flexDirection='column'>
                        <Text fontSize='0.875rem' fontWeight='600'>
                          {_t('user.profile.confirmation_status')}
                        </Text>
                        <Text mt='0.25rem'>
                          {userDetail?.confirmationStatus}
                        </Text>
                      </Flex>
                      <Flex direction='column' alignItems='flex-start'>
                        <Text fontSize='0.875rem' fontWeight='600'>
                          {_t('user.profile.account_status')}
                        </Text>
                        <Flex alignItems='center' mt='0.5rem'>
                          <Controller
                            control={control}
                            name='accountStatus'
                            render={({ onChange, value }) => {
                              return (
                                <>
                                  <Switch
                                    onChange={e => onChange(e.target.checked)}
                                    isChecked={value}
                                  />
                                  <Text ml='0.5rem' fontSize='0.875rem'>
                                    {value
                                      ? _t('user.profile.account_activated')
                                      : _t('user.profile.account_deactivated')}
                                  </Text>
                                </>
                              )
                            }}
                          />
                        </Flex>
                      </Flex>
                    </>
                  )}
                </Grid>
              </Box>
            </Flex>
          </ContentContainer>
          <ContentContainer
            title={_t('profile.heading.contact_detail')}
            mt='2rem'
          >
            <Grid
              gridTemplateColumns={{ base: '1fr', md: 'repeat(2, 1fr)' }}
              gap='1.5rem'
            >
              <Controller
                as={Input}
                control={control}
                name='address1'
                label={_t('user.profile.address_line_1')}
              />
              <Controller
                as={Input}
                control={control}
                name='address2'
                label={_t('user.profile.address_line_2')}
              />
              <Controller
                as={Input}
                control={control}
                name='city'
                label={_t('user.profile.city')}
              />
              <Controller
                as={Input}
                control={control}
                name='state'
                label={_t('user.profile.state')}
              />
              <Controller
                as={Select}
                control={control}
                name='country'
                label={_t('user.profile.country')}
                options={countryOptions}
              />
              <Controller
                as={Input}
                control={control}
                name='zipCode'
                label={_t('user.profile.zip_code')}
              />
              <Controller
                as={Input}
                control={control}
                name='contactNumber'
                label={_t('user.profile.mobile')}
                error={formErrors?.contactNumber?.message}
                rules={{
                  pattern: {
                    value: MOBILE_NUMBER_PATTERN,
                    message: _t('user.profile.validate_mobile_invalid'),
                  },
                }}
              />
            </Grid>
          </ContentContainer>
        </>
      )}
    </ProfileLayout>
  )
}

export default EditAdminProfileContainer

import React from 'react'

import {
  CentreManagement,
  CreateCentreAdmin,
  CreateInstructorUser,
  CreateLearnerUser,
  CreateSessionLanding,
  EditCentreAdmin,
  EditEnterpriseAdminProfile,
  EditEnterpriseProfile,
  EditLearnerUser,
  EnterpriseAdminNotifications,
  EnterpriseAdminProfile,
  EnterpriseCreateClass,
  EnterpriseCreateContent,
  EnterpriseCreateLesson,
  EnterpriseViewCentre,
  EnterpriseEditCentre,
  EnterpriseEditClass,
  EnterpriseEditContent,
  EnterpriseInstructorProfile,
  EnterprisePaymentSubscribe,
  EnterprisePreviewClass,
  EnterprisePreviewContent,
  EnterprisePreviewLesson,
  SessionManagement,
  TransactionApproval,
  UserManagement,
  ViewEnterpriseAdmin,
  ViewEnterpriseProfile,
  ViewMyTransaction,
  Wallet,
} from 'containers/EnterpriseAdmin'
import { SidebarRoute, UserRole } from 'types'

export const enterpriseAdminRoutes: SidebarRoute[] = [
  // {
  //   name: 'admin.sidebar.dashboard',
  //   link: '/enterprise-admin'
  // },
  {
    name: '',
    link: '/enterprise-admin/my-profile',
    element: <EnterpriseAdminProfile />,
    hidden: true,
  },
  {
    name: '',
    link: '/enterprise-admin/my-profile/edit',
    element: <EditEnterpriseAdminProfile />,
    hidden: true,
  },
  {
    name: '',
    link: '/enterprise-admin/enterprise-profile',
    element: <ViewEnterpriseProfile />,
    hidden: true,
  },
  {
    name: '',
    link: '/enterprise-admin/payment/subscribe',
    element: <EnterprisePaymentSubscribe />,
    hidden: true,
  },
  {
    name: '',
    link: '/enterprise-admin/enterprise-profile/edit',
    element: <EditEnterpriseProfile />,
    hidden: true,
  },
  {
    name: 'admin.sidebar.user_management',
    link: '/enterprise-admin/user-management',
    children: [
      {
        name: 'admin.sidebar.learners',
        link: '/enterprise-admin/user-management/learners',
        element: (
          <UserManagement
            tableType='Learners'
            type={UserRole.AdminManageStudent}
          />
        ),
      },
      {
        name: '',
        link: '/enterprise-admin/user-management/learners/create',
        element: <CreateLearnerUser />,
        hidden: true,
      },
      {
        name: '',
        link: '/enterprise-admin/user-management/learners/:id',
        element: <EditLearnerUser />,
        hidden: true,
      },
      {
        name: 'admin.sidebar.instructors',
        link: '/enterprise-admin/user-management/instructors',
        element: (
          <UserManagement
            tableType='Instructors'
            type={UserRole.AdminManageTutor}
          />
        ),
      },
      {
        name: '',
        link: '/enterprise-admin/user-management/instructors/create',
        element: <CreateInstructorUser />,
        hidden: true,
      },
      {
        name: '',
        link: '/enterprise-admin/user-management/instructors/:id',
        element: <EnterpriseInstructorProfile />,
        hidden: true,
      },
      {
        name: 'admin.sidebar.enterprise_admin',
        link: '/enterprise-admin/user-management/enterprise-admin',
        element: (
          <UserManagement
            tableType='Enterprise Admin'
            type={UserRole.AdminManageEnterpriseAdmin}
          />
        ),
      },
      {
        name: '',
        link: '/enterprise-admin/user-management/enterprise-admin/view/:id',
        element: <ViewEnterpriseAdmin />,
        hidden: true,
      },
      {
        name: '',
        link: '/enterprise-admin/user-management/enterprise-admin/edit/:id',
        element: <EditEnterpriseAdminProfile />,
        hidden: true,
      },
      {
        name: 'admin.sidebar.centre_admin',
        link: '/enterprise-admin/user-management/centre-admin',
        element: (
          <UserManagement
            tableType='Centre Admin'
            type={UserRole.AdminManageCenterAdmin}
          />
        ),
      },
      {
        name: '',
        link: '/enterprise-admin/user-management/centre-admin/create',
        element: <CreateCentreAdmin />,
        hidden: true,
      },
      {
        name: '',
        link: '/enterprise-admin/user-management/centre-admin/:id',
        element: <EditCentreAdmin />,
        hidden: true,
      },
    ],
  },
  {
    name: 'admin.sidebar.session_management',
    link: '/enterprise-admin/session-management',
    children: [
      {
        name: 'Data Table',
        link: '/enterprise-admin/session-management/data',
        element: <SessionManagement />,
        hidden: true,
      },
      {
        name: 'admin.sidebar.create_class',
        link: '/enterprise-admin/session-management/create',
        element: <CreateSessionLanding />,
      },
      {
        name: '',
        link: '/enterprise-admin/session-management/create/class',
        element: <EnterpriseCreateClass />,
        hidden: true,
      },
      {
        name: '',
        link: '/enterprise-admin/session-management/create/content',
        element: <EnterpriseCreateContent />,
        hidden: true,
      },
      {
        name: '',
        link: '/enterprise-admin/session-management/create/lesson',
        element: <EnterpriseCreateLesson />,
        hidden: true,
      },
      {
        name: '',
        link: '/enterprise-admin/session-management/edit/class/:id',
        element: <EnterpriseEditClass />,
        hidden: true,
      },
      {
        name: '',
        link: '/enterprise-admin/session-management/edit/content/:id',
        element: <EnterpriseEditContent />,
        hidden: true,
      },
      {
        name: '',
        link: '/enterprise-admin/session-management/preview/class/:id',
        element: <EnterprisePreviewClass />,
        hidden: true,
      },
      {
        name: '',
        link: '/enterprise-admin/session-management/preview/content/:id',
        element: <EnterprisePreviewContent />,
        hidden: true,
      },
      {
        name: '',
        link: '/enterprise-admin/session-management/preview/lesson/:id',
        element: <EnterprisePreviewLesson />,
        hidden: true,
      },
    ],
  },
  {
    name: 'admin.sidebar.centre_management',
    link: '/enterprise-admin/centre-management',
    children: [
      {
        name: 'Data Table',
        link: '/enterprise-admin/centre-management/data',
        element: <CentreManagement />,
        hidden: true,
      },
      {
        name: 'View Centre',
        link:
          '/enterprise-admin/centre-management/:enterpriseId/centre/:centreId',
        element: <EnterpriseViewCentre />,
        hidden: true,
      },
      {
        name: 'Edit Centre',
        link:
          '/enterprise-admin/centre-management/:enterpriseId/centre/edit/:centreId',
        element: <EnterpriseEditCentre />,
        hidden: true,
      },
    ],
  },
  {
    name: 'admin.sidebar.wallet_management',
    link: '/enterprise-admin/wallet-management',
    children: [
      {
        name: 'admin.sidebar.wallet_management',
        link: '/enterprise-admin/wallet-management/manage',
        element: <Wallet />,
        hidden: true,
      },
      {
        name: 'Transaction Detail',
        link: '/enterprise-admin/wallet-management/view/:id',
        element: <ViewMyTransaction />,
        hidden: true,
      },
    ],
  },
  {
    name: 'admin.sidebar.transaction_approval_management',
    link: '/enterprise-admin/transaction-approval-management',
    children: [
      {
        name: 'admin.sidebar.transaction_approval_management',
        link: '/enterprise-admin/transaction-approval-management/manage',
        element: <TransactionApproval />,
        hidden: true,
      },
    ],
  },
  {
    name: 'admin.sidebar.my_notifications',
    link: '/enterprise-admin/all-notifications',
    children: [
      {
        name: '',
        link: '/enterprise-admin/all-notifications/list',
        element: <EnterpriseAdminNotifications />,
        hidden: true,
      },
      {
        name: '',
        link: '/enterprise-admin/all-notifications/:id',
        element: <EnterpriseAdminNotifications />,
        hidden: true,
      },
    ],
  },
]

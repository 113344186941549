import React, { useCallback } from 'react'

import { Box, Flex, Link, Text } from '@chakra-ui/react'

import {
  downloadAllClassAttachments,
  downloadClassAttachment,
} from 'apis/class'
import { MaterialItem } from 'components'
import { useLanguages, useLoading, useToast } from 'hooks'
import { Attachment, AttachmentsType, Material } from 'types'
import { getErrorMessage } from 'utils/parser'

interface MaterialSectionProps {
  classId: string | undefined
  attachments: AttachmentsType[]
  materials?: Material[]
}

const MaterialSection: React.FC<MaterialSectionProps> = ({
  classId,
  attachments,
  materials = [],
}) => {
  const { setLoading } = useLoading()
  const { _t } = useLanguages()
  const toast = useToast()

  const formatAttachment = (item: AttachmentsType) => {
    return {
      name: item.name,
      size: item.size,
      createdAt: item.createdAt,
      key: item.key || '',
    } as Attachment
  }

  const handleDownloadAll = async () => {
    if (!!classId) {
      setLoading(true)
      try {
        const res = await downloadAllClassAttachments(classId)
        window.open(res.data.data)
      } catch (error) {
        toast({
          title: 'Error',
          description: _t(`message.error.${getErrorMessage(error)}`),
          status: 'error',
        })
      } finally {
        setLoading(false)
      }
    }
  }

  const handleDownloadAttachment = async (key: string) => {
    if (!!classId) {
      setLoading(true)
      try {
        const res = await downloadClassAttachment({ classId, key })
        window.open(res.data.data)
      } catch (error) {
        toast({
          title: 'Error',
          description: _t(`message.error.${getErrorMessage(error)}`),
          status: 'error',
        })
      } finally {
        setLoading(false)
      }
    }
  }

  const renderMaterial = useCallback((name, url) => {
    const regex = /^https?:\/\//
    const link = regex.test(url) ? url : `https://${url}`
    return (
      <Link
        href={link}
        alignItems='center'
        mt='0.5rem'
        _hover={{ textDecoration: 'none' }}
        isExternal
      >
        <Box
          py='1rem'
          px={{ base: '1rem', md: '2rem' }}
          rounded='lg'
          boxShadow='lg'
          border='0.8px solid #DEDEDE'
          _hover={{ bg: 'primaryLight' }}
        >
          <Flex justify='space-between'>
            <Text fontWeight='600'>{name}</Text>
          </Flex>
        </Box>
      </Link>
    )
  }, [])

  return (
    <Box my='2rem'>
      <Text as='h2' mb='1rem'>
        {_t('product.class.materials_title')}
      </Text>
      <Flex justify='space-between' mb='1rem'>
        <Text fontSize='0.875rem'>
          {_t('product.class.material_attachments_count', {
            count: `${attachments.length}`,
          })}
        </Text>
        {!!attachments.length && (
          <Text
            fontSize='0.875rem'
            color='primary'
            cursor='pointer'
            textTransform='uppercase'
            onClick={handleDownloadAll}
            _hover={{
              textDecoration: 'underline',
            }}
          >
            {_t('product.class.download')}
          </Text>
        )}
      </Flex>
      <Flex direction='column' mb='1.5rem'>
        {!!attachments.length ? (
          attachments.map(item => (
            <Box key={item.name} mb='0.7rem'>
              <MaterialItem
                data={formatAttachment(item)}
                onDownloadAttachment={handleDownloadAttachment}
              />
            </Box>
          ))
        ) : (
          <Flex justify='center'>
            <Text fontSize='0.75rem' color='gray.300'>
              {_t('product.class.material_empty')}
            </Text>
          </Flex>
        )}
      </Flex>
      {!!materials.length && (
        <>
          <Box mb='1rem'>
            <Text fontSize='0.875rem'>
              {_t('product.class.material_links_count', {
                count: `${materials.length}`,
              })}
            </Text>
          </Box>
          {materials.map(item => (
            <Box key={item.name} mb='0.7rem'>
              {renderMaterial(item.name, item.url)}
            </Box>
          ))}
        </>
      )}
    </Box>
  )
}

export default MaterialSection

import React, { memo, useEffect, useState } from 'react'

import * as ics from 'ics'
import { Box, Tooltip } from '@chakra-ui/react'
import { format } from 'date-fns'

import { ClassSessionItem } from 'types'

import { ReactComponent as CalendarAdd } from 'assets/images/calendar-add.svg'

interface AddToCalendarProps {
  sessions: ClassSessionItem[] | undefined
}

const AddToCalendar = ({ sessions = [] }: AddToCalendarProps) => {
  const [calendarUri, setCalendarUri] = useState<string>('')

  const formatDateTimeArray = (dateTime: number) => {
    return [
      +format(dateTime, 'yyyy'),
      +format(dateTime, 'M'),
      +format(dateTime, 'd'),
      +format(dateTime, 'H'),
      +format(dateTime, 'm'),
    ]
  }

  useEffect(() => {
    const sessionEvents: ics.EventAttributes[] = sessions.map(
      (session: any) => {
        return {
          title: session.name,
          description: session.description,
          start: formatDateTimeArray(session.startDateTime),
          end: formatDateTimeArray(session.endDateTime),
        } as ics.EventAttributes
      }
    )

    const { error, value } = ics.createEvents(sessionEvents)
    if (!error && value) {
      const uri = encodeURI(`data:text/calendar;charset=utf8,${value}`)
      setCalendarUri(uri)
    }
  }, [sessions])

  const handleOpenCalendar = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault()
    if (calendarUri.startsWith('BEGIN')) {
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(calendarUri)
      link.setAttribute('download', 'calendar.ics')
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    } else {
      window.open(calendarUri, '_blank')
    }
  }

  return (
    <Tooltip label='Add to calendar' bg='primary' hasArrow>
      <Box onClick={handleOpenCalendar}>
        <Box m='0.5rem'>
          <CalendarAdd height='2.2rem' width='2.2rem' />
        </Box>
      </Box>
    </Tooltip>
  )
}

export default memo(AddToCalendar)

import React, { useCallback, useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'

import {
  Box,
  Button,
  Flex,
  InputGroup,
  InputLeftElement,
  Text,
} from '@chakra-ui/react'

import { Input, Modal, ModalProps, Select, Textarea } from 'components'
import LoadingSpinner from 'components/LoadingSpinner'
import { Option } from 'components/Select'
import { useUserWallet, useWriteOff } from 'queries/wallet'
import { ApiUserType, PaymentMethod } from 'types'
import { formatAmount } from 'utils/parser'

const OPTIONS = [
  {
    value: 'deduct',
    label: 'Deduct',
  },
  {
    value: 'add',
    label: 'Add',
  },
]

interface WalletModalProps extends Omit<ModalProps, 'children'> {
  type: ApiUserType
  id: string
}

interface WalletForm {
  paymentType: Option
  remarks: string
  value: number
}

const WalletModal = ({ id, type, isOpen, onClose }: WalletModalProps) => {
  const { control, handleSubmit, watch, errors } = useForm<WalletForm>({})
  const paymentType = useMemo(() => watch('paymentType')?.value, [watch])
  const value = useMemo(() => watch('value') || 0, [watch])
  const walletRequest = useMemo(() => ({ type, id }), [id, type])

  const { data: walletInfo, isLoading } = useUserWallet(walletRequest)
  const { mutate: writeOff } = useWriteOff(walletRequest)

  const finalWallet = useMemo(
    () =>
      paymentType === 'add'
        ? (walletInfo?.data.balance || 0) / 100 + Number(value)
        : (walletInfo?.data.balance || 0) / 100 - Number(value),
    [paymentType, value, walletInfo]
  )

  const handleConfirm = useCallback(
    (values: WalletForm) => {
      const { paymentType: paymentTypeOption, remarks, value: amount } = values
      const formattedData = {
        method:
          paymentTypeOption.value === 'add'
            ? PaymentMethod.CREDIT
            : PaymentMethod.DEBIT,
        amount: Math.round((amount || 0) * 100),
        remarks,
        ...(type === 'user' && { userId: id }),
        ...(type === 'center' && { centerId: id }),
      }

      writeOff({ data: formattedData, onClose })
    },
    [type, id, writeOff, onClose]
  )

  return (
    <Modal size='xl' isOpen={isOpen} onClose={onClose}>
      <Box as='h1' textAlign='center' mb='2rem'>
        Edit Wallet
      </Box>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <Flex
            justifyContent='space-between'
            alignItems='center'
            pb='2rem'
            borderBottom='1px solid #828282'
          >
            <Box>
              <Text fontWeight='600' fontSize='0.875rem' mb='0.25rem'>
                Wallet Balance
              </Text>
              <Text fontWeight='400' fontSize='0.875rem' color='#828282'>
                Before Editing
              </Text>
            </Box>
            <Text fontSize='1rem' fontWeight='600' color='primary'>
              ${formatAmount((walletInfo?.data.balance || 0) / 100)}
            </Text>
          </Flex>
          <Box mt='2rem' borderBottom='1px solid #828282' pb='2rem'>
            <Flex justifyContent='space-between' alignItems='center'>
              <Text fontWeight='600' fontSize='0.875rem' mb='0.25rem'>
                Admin Wallet Edit
              </Text>
              <Box>
                <InputGroup width='14rem'>
                  <InputLeftElement
                    h='80%'
                    mt='3px'
                    width='auto'
                    zIndex='10'
                    children={
                      <Flex alignItems='center'>
                        <Controller
                          control={control}
                          as={<Select />}
                          variant='transparent'
                          containerProps={{
                            zIndex: 10,
                            borderRight: '1px solid #E0E0E0',
                          }}
                          name='paymentType'
                          defaultOptions={OPTIONS}
                          defaultValue={OPTIONS[0]}
                        />
                        <Text pl='1rem'>$</Text>
                      </Flex>
                    }
                  />
                  <Controller
                    name='value'
                    as={Input}
                    type='number'
                    control={control}
                    borderColor='gray.300'
                    fontSize='0.875rem'
                    borderRadius='0.5rem'
                    _hover={{
                      borderColor: 'primary',
                    }}
                    paddingLeft='10.5rem'
                    placeholder='10'
                    color='primary'
                    rules={{
                      required: 'Value is required',
                      pattern: {
                        value: /^\d*(\.\d{0,2})?$/,
                        message:
                          'Value should be numeric and have 2 decimal places',
                      },
                    }}
                  />
                </InputGroup>
                {errors.value?.message && (
                  <Text fontSize='0.75rem' color='error'>
                    {errors.value.message}
                  </Text>
                )}
              </Box>
            </Flex>

            <Controller
              control={control}
              name='remarks'
              render={({ onChange, value: remarkData }) => (
                <Textarea
                  aria-label='Remarks'
                  label='Remarks'
                  onChange={onChange}
                  value={remarkData}
                  placeholder='Please share the reason for modification of transaction'
                  fontSize='0.75rem'
                  error={errors.remarks?.message}
                />
              )}
              rules={{
                required: 'Remarks is required',
              }}
            />
          </Box>
          <Box mt='2rem' bg='#F2FBFF' borderRadius='0.5rem' p='2rem'>
            <Flex justifyContent='space-between' alignItems='center'>
              <Text fontSize='1.125rem' fontWeight='600'>
                Final Wallet Balance
              </Text>
              <Text fontSize='2rem' fontWeight='600'>
                {`$${formatAmount(finalWallet)}`}
              </Text>
            </Flex>
          </Box>
          <Flex mt='3rem'>
            <Button
              variant='solidPrimary'
              flex='1'
              mr='0.5rem'
              onClick={handleSubmit(handleConfirm)}
              isDisabled={finalWallet < 0}
            >
              CONFIRM
            </Button>
            <Button variant='transparent' flex='1' onClick={onClose}>
              CANCEL
            </Button>
          </Flex>
        </>
      )}
    </Modal>
  )
}

export default WalletModal

import { getUploadAvatarUrl, uploadAvatar } from 'apis/user'
import { AvatarRef } from 'components'
import { CredentialRef } from 'types'
import { checkValidFilesName } from 'utils/helper'

export const uploadUserAvatar = async (
  avatarRef: React.MutableRefObject<AvatarRef | undefined>,
  userId: string
) => {
  const updatedAvatar = avatarRef.current?.getUpdatedAvatar?.()
  if (updatedAvatar?.avatar) {
    const { avatar, fileType } = updatedAvatar

    const isFileNameValid = checkValidFilesName([
      { file: updatedAvatar.avatar as File },
    ])

    if (!isFileNameValid) {
      throw new Error(
        'File name should not contain any special characters ' +
          'except for hyphens (-), underscores (_) or spaces.'
      )
    }

    const { data } = await getUploadAvatarUrl(userId, fileType as string)
    const { fields, url } = data.data

    const formData = new FormData()
    Object.entries(fields).forEach(([key, value]) => {
      formData.append(key, value)
    }, [])
    formData.append('file', avatar as File)

    await uploadAvatar(url, formData)
  }
}

export const uploadCredentials = async (
  credentialRef: React.MutableRefObject<CredentialRef | undefined>,
  userId: string
) => {
  if (credentialRef.current?.upload) {
    await credentialRef.current.upload(userId)
  }
}

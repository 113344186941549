import React from 'react'

import { Flex } from '@chakra-ui/react'

import { Cell } from '../../components'
import { maxNumberLength } from 'configs/game'

const EmptyRow = () => {
  const emptyCells = Array.from(Array(maxNumberLength))

  return (
    <Flex justifyContent='center' mb='0.5rem'>
      {emptyCells.map((_, i) => (
        <Cell key={i} />
      ))}
    </Flex>
  )
}

export default EmptyRow

import { useEffect, useState } from 'react'

import { useAtom } from 'jotai'

import Auth from 'configs/auth'
import { userAtom } from 'store/authAtom'

export default () => {
  const [userId, setUserId] = useState('')
  const [user] = useAtom(userAtom)

  useEffect(() => {
    const getUserId = async () => {
      if (user) {
        const id = Auth.currentUserId()

        setUserId(id)
      }
    }

    getUserId()
  }, [user])

  return userId
}

import React from 'react'
import { Control, Controller } from 'react-hook-form'

import { Box, Flex, Radio, RadioGroup, Stack, Text } from '@chakra-ui/react'

import { WithdrawalCreateForm } from '../form'
import { Input, Textarea } from 'components'
import { useLanguages } from 'hooks'
import { PaymentType } from 'types'

interface CreateWithdrawalFormProps {
  control: Control<WithdrawalCreateForm>
  errors: any
  availableLimit: number
}

const CreateWithdrawalForm: React.FC<CreateWithdrawalFormProps> = ({
  control,
  errors,
  availableLimit,
}) => {
  const { _t } = useLanguages()

  return (
    <Box>
      <Flex direction={{ base: 'column', md: 'row' }} justify='space-between'>
        <Box flex={1}>
          <Box mb='1rem'>
            <Controller
              as={Input}
              control={control}
              name='amount'
              label={_t('transaction.detail.amount')}
              aria-label='Amount'
              placeholder={_t('transaction.detail.placeholder_amount')}
              rules={{
                validate: {
                  amount: value => {
                    if (!value) {
                      return _t('transaction.detail.validate_amount_required')
                    }
                    if (
                      Math.round((value || 0) * 100) > availableLimit ||
                      value <= 0
                    ) {
                      return _t('transaction.detail.validate_amount_invalid')
                    }
                    return true
                  },
                },
              }}
              error={errors.amount?.message}
            />
          </Box>
          <Box mb='1rem'>
            <Text fontSize='0.875rem' fontWeight='600'>
              {_t('transaction.detail.payment_type')}
            </Text>
            <Controller
              control={control}
              name='paymentType'
              render={({ onChange, value }) => (
                <RadioGroup onChange={onChange} value={value}>
                  <Stack direction='row' spacing='1.5rem'>
                    <Radio value={PaymentType.BANK}>
                      <Box noOfLines={1} textTransform='capitalize'>
                        {_t('transaction.payment_type.bank')}
                      </Box>
                    </Radio>
                    <Radio value={PaymentType.CASH}>
                      <Box noOfLines={1} textTransform='capitalize'>
                        {_t('transaction.payment_type.cash')}
                      </Box>
                    </Radio>
                    <Radio value={PaymentType.CHEQUE}>
                      <Box noOfLines={1} textTransform='capitalize'>
                        {_t('transaction.payment_type.cheque')}
                      </Box>
                    </Radio>
                  </Stack>
                </RadioGroup>
              )}
              rules={{
                required: _t(
                  'transaction.detail.validate_payment_type_required'
                ),
              }}
              error={errors.paymentType?.message}
            />
          </Box>
        </Box>
        <Box flex={1}></Box>
      </Flex>
      <Box mb='1rem'>
        <Controller
          as={Textarea}
          control={control}
          name='remarks'
          label={_t('transaction.detail.remarks')}
          aria-label='Remarks'
          placeholder={_t('transaction.detail.placeholder_remarks')}
          rules={{
            required: _t('transaction.detail.validate_remarks_required'),
          }}
          error={errors.remarks?.message}
        />
      </Box>
    </Box>
  )
}

export default CreateWithdrawalForm

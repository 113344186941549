import React from 'react'

import { Stat, StatGroup, StatLabel, StatNumber } from '@chakra-ui/react'

import { useLanguages } from 'hooks'
import { GameStats } from 'types'

interface StatBarProps {
  gameStats: GameStats
}

const StatsBar: React.FC<StatBarProps> = ({ gameStats }) => {
  const { _t } = useLanguages()

  return (
    <StatGroup mb='1rem'>
      <Stat>
        <StatLabel>{_t('games.knovole.total_tries')}</StatLabel>
        <StatNumber>{gameStats.totalGames}</StatNumber>
      </Stat>
      <Stat>
        <StatLabel>{_t('games.knovole.success_rate')}</StatLabel>
        <StatNumber>{gameStats.successRate}%</StatNumber>
      </Stat>
      <Stat>
        <StatLabel>{_t('games.knovole.current_streak')}</StatLabel>
        <StatNumber>{gameStats.currentStreak}</StatNumber>
      </Stat>
      <Stat>
        <StatLabel>{_t('games.knovole.best_streak')}</StatLabel>
        <StatNumber>{gameStats.bestStreak}</StatNumber>
      </Stat>
    </StatGroup>
  )
}

export default StatsBar

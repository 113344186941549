export interface Campaign {
  id: string
  title: string
  enterpriseId: string
  audience: string
  isDefault: boolean
  locations: any
  expiredAt: number
}

export interface CampaignItem {
  purchase: boolean
  type?: string
  sessionId?: string
  pageURL?: string
  effectiveDate?: number
  expiryDate?: number
  headline?: string
  description?: string
  image?: string
  altText?: string
  impressionCount?: number
  engagementCount?: number
}

export interface CampaignTable {
  id: string
  title: string
  enterpriseId: string
  audience: string
  isDefault: boolean
  startedAt: number
  expiredAt: number
  impressionCount: number
  engagementCount: number
}

export interface CampaignUploadUrl {
  data: {
    fields: {
      key: string
      'Content-Type': string
      bucket: string
      'X-Amz-Algorithm': string
      'X-Amz-Credential': string
      'X-Amz-Date': string
      'X-Amz-Security-Token': string
      Policy: string
      'X-Amz-Signature': string
      'x-amz-meta-userid': string
      'x-amz-meta-id': string
      'x-amz-meta-name': string
      'x-amz-meta-status': string
    }
    url: string
  }
}

export type CampaignDisplayParams = {
  location?: string
  enterpriseId?: string
  audience?: string
}

export enum CampaignAudienceType {
  ALL = 'all',
  LEARNER = 'learner',
  INSTRUCTOR = 'instructor',
}

export enum CampaignLocationType {
  DASHBOARD = 'dashboard',
  SEARCH_BANNER = 'searchBanner',
  EXPLORE_BANNER = 'exploreBanner',
  EXPLORE_SPOTLIGHT = 'exploreSpotlight',
  POPUP = 'popup',
}

import { useCallback, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { Hub } from 'aws-amplify'
import firebase from 'firebase/app'
import { useAtom } from 'jotai'

import {
  getProfile, // updateProfile,
  // updateRole,
} from '../apis/profile'
import { useToast } from './index'
import { getFirebaseToken } from 'apis/token'
import Auth from 'configs/auth'
import storageKey from 'configs/storageKey'
import {
  authRequestAtom,
  derivedFirebaseAuthAtom,
  derivedFirebaseUserAtom,
  derivedUserAtom,
  derivedUserProfileAtom,
  setAuthStatusAtom,
  setProfileCheckedStatusAtom,
} from 'store/authAtom'
import { Role, UserProfile } from 'types'
import { getData, removeData } from 'utils/helper'

export default () => {
  const [authRequest] = useAtom(authRequestAtom)
  const [, setUser] = useAtom(derivedUserAtom)
  const [, setUserProfile] = useAtom(derivedUserProfileAtom)
  const [, setAuthenticated] = useAtom(setAuthStatusAtom)
  const [, setProfileChecked] = useAtom(setProfileCheckedStatusAtom)
  const [, setFirebaseAuth] = useAtom(derivedFirebaseAuthAtom)
  const [, setFirebaseUser] = useAtom(derivedFirebaseUserAtom)
  const navigate = useNavigate()
  const toast = useToast()

  const setUserInfo = useCallback(
    async userId => {
      const [profilePromise] = await Promise.allSettled([getProfile(userId)])

      let profile: UserProfile = {}

      if (profilePromise.status === 'fulfilled') {
        if (profilePromise.value.data.confirmationStatus === 'RESET_REQUIRED') {
          setUser(null)
          setUserProfile(null)
          setProfileChecked(false)

          // ** clear data
          removeData(storageKey.accessToken)
          removeData(storageKey.refreshToken)
          removeData(storageKey.expiresAt)
          removeData(storageKey.userId)
          removeData(storageKey.loginType)
          removeData(storageKey.roles)

          toast({
            title: 'Reset Password!',
            description:
              'Your account has been reset by Admin. Please check your email for information.',
            status: 'info',
          })
          // go to sign-in page
          return navigate('/sign-in')
        }
        profile = {
          ...profile,
          ...profilePromise.value.data,
        }
      }

      const roles: Role[] = JSON.parse(getData(storageKey.roles) || '[]')

      profile = {
        ...profile,
        roles,
      }

      setUserProfile(profile)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setUserProfile, setUser, setProfileChecked, navigate]
  )

  const setFirebase = useCallback(async () => {
    try {
      const response = await getFirebaseToken()
      setFirebaseAuth(response.data.data.token)
    } catch (err) {
      console.log(err)
    }
  }, [setFirebaseAuth])

  const logoutFirebase = useCallback(() => {
    firebase.auth().signOut()
    setFirebaseAuth('')
    setFirebaseUser(null)
  }, [setFirebaseAuth, setFirebaseUser])

  useEffect(() => {
    Hub.listen('customAuth', async ({ payload: { event, data } }) => {
      switch (event) {
        // case 'cognitoHostedUI':
        //   const amplifyAuthUserInfo1 = await AuthAmplify.currentUserInfo()
        //   const userId = amplifyAuthUserInfo1.attributes.sub
        //   const userEmail = amplifyAuthUserInfo1.attributes.email
        //   const role = localStorage.getItem(storageKey.role) as string

        //   await updateRole(userId, role)
        //   await updateProfile(userId, { email: userEmail })
        //   break
        case 'signIn':
          setUser(data.user)
          setAuthenticated(true)
          setUserProfile({ roles: data.roles })
          setFirebase()
          break
        case 'guestSignIn':
          setAuthenticated(true)
          setProfileChecked(true)
          break
        case 'signUp':
          break
        case 'signOut':
          // not set authenticated to trigger redirection
          setUser(null)
          setUserProfile(null)
          setProfileChecked(false)
          logoutFirebase()

          // ** clear data
          removeData(storageKey.accessToken)
          removeData(storageKey.refreshToken)
          removeData(storageKey.expiresAt)
          removeData(storageKey.userId)
          removeData(storageKey.loginType)
          removeData(storageKey.roles)

          // go to sign-in page
          navigate('/sign-in')
          break
        case 'customOAuthState':
          console.log('customOAuthState', data)
          break
      }
    })
  }, [
    authRequest,
    setAuthenticated,
    setUser,
    setUserProfile,
    setProfileChecked,
    navigate,
    setFirebase,
    logoutFirebase,
  ])

  useEffect(() => {
    if (Auth.currentlyGuest()) {
      setProfileChecked(true)
      setAuthenticated(true)
    } else {
      Auth.currentAuthenticatedUser()
        .then(async ({ data }) => {
          const user = data.data
          const userId = user.id
          setUser(user)
          await setUserInfo(userId)
          setProfileChecked(true)
          await setFirebase()
        })
        .catch(() => console.log('Not signed in'))
        .finally(() => setAuthenticated(true))
    }
  }, [setUser, setAuthenticated, setProfileChecked, setFirebase, setUserInfo])
}

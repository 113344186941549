/* eslint-disable jsx-a11y/no-static-element-interactions */
import * as React from 'react'
import { getTrackBackground, Range } from 'react-range'

import { Flex } from '@chakra-ui/react'

const TwoThumbs: React.FC<{
  rtl?: boolean
  step?: number
  min?: number
  max?: number
  defaultValue?: number[]
  isMoney?: boolean
  values?: number[]
  onChange?: (values: number[]) => void
}> = ({
  rtl = false,
  step = 1,
  min = 0,
  max = 100,
  defaultValue = [min, max],
  isMoney,
  values = [min, max],
  onChange = () => {},
}) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        marginBottom: '1rem',
      }}
    >
      <Range
        values={values || defaultValue}
        step={step}
        min={min}
        max={max}
        rtl={rtl}
        onChange={onChange}
        renderTrack={({ props, children }) => (
          <div
            onMouseDown={props.onMouseDown}
            onTouchStart={props.onTouchStart}
            style={{
              ...props.style,
              height: '36px',
              display: 'flex',
              width: '100%',
              padding: '0 8px 0 8px',
            }}
          >
            <div
              ref={props.ref}
              style={{
                height: '2px',
                width: '100%',
                borderRadius: '4px',
                background: getTrackBackground({
                  values,
                  colors: ['#ccc', '#548BF4', '#ccc'],
                  min,
                  max,
                  rtl,
                }),
                alignSelf: 'center',
              }}
            >
              {children}
            </div>
          </div>
        )}
        renderThumb={({ props }) => (
          <div
            {...props}
            style={{
              ...props.style,
              height: '10px',
              width: '10px',
              borderRadius: '50%',
              backgroundColor: '#548BF4',
            }}
          />
        )}
      />
      <Flex w='100%' justifyContent='space-between' id='output'>
        <div>
          {isMoney && '$'}
          {values[0].toFixed(0)}
        </div>
        <div>
          {isMoney && '$'}
          {values[1].toFixed(0)}
        </div>
      </Flex>
    </div>
  )
}

export default TwoThumbs

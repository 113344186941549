import React from 'react'

import {
  Modal as ChakraModal,
  ModalProps as ChakraModalProps,
  ModalBody,
  ModalBodyProps,
  ModalCloseButton,
  ModalContent,
  ModalContentProps,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'

export interface ModalProps extends ChakraModalProps {
  title?: string
  size?: string
  Footer?: React.ReactNode
  modalBodyProps?: ModalBodyProps
  modalContentProps?: ModalContentProps
}

const Modal: React.FC<ModalProps> = ({
  title,
  isOpen,
  onClose,
  Footer,
  children,
  size = '2xl',
  modalBodyProps,
  modalContentProps,
  ...modalProps
}) => {
  return (
    <ChakraModal
      isOpen={isOpen}
      onClose={onClose}
      size={size}
      scrollBehavior='inside'
      isCentered
      closeOnOverlayClick={false}
      closeOnEsc={false}
      {...modalProps}
    >
      <ModalOverlay />
      <ModalContent {...modalContentProps}>
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />

        <ModalBody pb='2rem' px='3rem' {...modalBodyProps}>
          {children}
        </ModalBody>

        {Footer}
      </ModalContent>
    </ChakraModal>
  )
}

export default Modal

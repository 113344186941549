import React from 'react'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'

import {
  Explore,
  ForgotEmail,
  ForgotPassword,
  Meeting,
  ResetPassword,
  SearchResult,
  SignIn,
  SignUp,
} from 'containers'
import {
  GuestCentreProfileView,
  GuestClassProduct,
  GuestContentProduct,
  GuestInstructorView,
  GuestLessonProduct,
} from 'containers/Guest'
import { GameLibrary, GamePage } from 'shared/containers'

const GuestRoutes = () => {
  const location = useLocation()
  return (
    <Routes>
      <Route path='/sign-in' element={<SignIn />} />
      <Route path='/sign-up' element={<SignUp />} />
      <Route path='/forgot-password' element={<ForgotPassword />} />
      <Route path='/forgot-email' element={<ForgotEmail />} />
      <Route path='/reset-password' element={<ResetPassword />} />
      <Route path='/search-results' element={<SearchResult />} />
      <Route path='/explore' element={<Explore />} />
      <Route path='/product'>
        <Route path='/class/:id' element={<GuestClassProduct />} />
        <Route path='/content/:id' element={<GuestContentProduct />} />
        <Route path='/lesson/:id' element={<GuestLessonProduct />} />
      </Route>
      <Route path='/instructors/:id' element={<GuestInstructorView />} />
      <Route path='/centre/:id' element={<GuestCentreProfileView />} />
      <Route path='/games'>
        <Route path='/' element={<GameLibrary />} />
        <Route path='/knovole' element={<GamePage />} />
      </Route>
      <Route path='/meeting' element={<Meeting />} />
      <Route
        path='/*'
        element={<Navigate replace to='/sign-in' state={{ from: location }} />}
      />
    </Routes>
  )
}

export default GuestRoutes

import React from 'react'

import {
  Box,
  Button,
  Flex,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
} from '@chakra-ui/react'

import { useLanguages } from 'hooks'
import { SubscriptionInfo } from 'types'
import { getNumber } from 'utils/parser'

import { ReactComponent as Checked } from 'assets/images/checked.svg'
import { ReactComponent as Note } from 'assets/images/note_icon.svg'

interface SubscriptionCardProps {
  data?: SubscriptionInfo
  type?: 'privateTutor' | 'enterprise'
  onUpgrade?: (id?: string, packagePrice?: number) => void
  onDowngrade?: (id?: string) => void
}

type PlanDetail = {
  content: string
  highlight?: boolean
  note?: string
}

const defaultDetail = [
  { content: 'subscription.common.item_assignment' },
  { content: 'subscription.common.item_classroom' },
  { content: 'subscription.common.item_payment' },
]

const getPlanDetails = ({
  planType = 'default',
  defaultLicense,
  type,
}: {
  planType?: string
  defaultLicense?: number
  type?: 'privateTutor' | 'enterprise'
}): {
  description: string
  details: PlanDetail[]
} => {
  if (type === 'privateTutor' && planType === 'starterPlan') {
    return {
      description: 'subscription.basic.description',
      details: [
        {
          highlight: true,
          content: 'subscription.basic.item_license',
        },
        {
          highlight: true,
          content: 'subscription.basic.item_participant',
        },
        {
          content: 'subscription.basic.item_scheduling',
        },
        ...defaultDetail,
      ],
    }
  }

  if (type === 'privateTutor' && planType === 'personalPlan') {
    return {
      description: 'subscription.pro.description',
      details: [
        {
          highlight: true,
          content: 'subscription.pro.item_license',
        },
        {
          highlight: true,
          content: 'subscription.pro.item_participant',
        },
        {
          content: 'subscription.pro.item_scheduling',
        },
        ...defaultDetail,
      ],
    }
  }

  if (type === 'enterprise' && planType === 'enterpriseStarterPlan') {
    return {
      description: 'subscription.enterprise_basic.description',
      details: [
        {
          highlight: true,
          content: 'subscription.enterprise_basic.item_license',
          note: 'subscription.enterprise_basic.item_license_notes',
        },
        {
          highlight: true,
          content: 'subscription.enterprise_basic.item_participant',
        },
        {
          content: 'subscription.enterprise_basic.item_user_management',
        },
        {
          content: 'subscription.enterprise_basic.item_class_management',
        },
        ...defaultDetail,
      ],
    }
  }

  if (type === 'enterprise' && planType === 'wolf_pack') {
    return {
      description: 'subscription.enterprise_pro.description',
      details: [
        {
          highlight: true,
          content: 'subscription.enterprise_pro.item_license',
          note: 'subscription.enterprise_pro.item_license_notes',
        },
        {
          highlight: true,
          content: 'subscription.enterprise_pro.item_participant',
        },
        {
          content: 'subscription.enterprise_pro.item_user_management',
        },
        {
          content: 'subscription.enterprise_pro.item_class_management',
        },
        ...defaultDetail,
      ],
    }
  }

  return {
    description: '',
    details: [],
  }
}

const SubscriptionCard = ({
  data,
  type,
  onUpgrade,
  onDowngrade,
}: SubscriptionCardProps) => {
  const { _t } = useLanguages()
  const planDetail = getPlanDetails({
    planType: data?.type,
    defaultLicense: data?.defaultLicense,
    type,
  })

  return (
    <Box
      overflow='hidden'
      width='100%'
      boxShadow='lg'
      rounded='lg'
      minWidth='400px'
      bg='secondaryLightBlue'
    >
      <Box width='100%' p='1.5rem 2rem 0.75rem' bg='white'>
        <Flex justify='center' align='center' mb='0.5rem'>
          <Text fontSize='1.625rem' fontWeight='600'>
            {data?.name}
          </Text>
        </Flex>
        <Flex alignItems='center' justifyContent='space-between'>
          <Flex flex='1' justify='center' align='center'>
            {data?.price ? (
              <>
                <Text fontSize='3.75rem' fontWeight='600'>
                  ${data?.price}
                </Text>
                <Text fontSize='1.5rem' ml='0.5rem'>
                  {`/ ${_t('subscription.card.month')}`}
                </Text>
              </>
            ) : (
              <>
                <Text fontSize='3.75rem' fontWeight='600'>
                  {(data?.commission && getNumber(data.commission * 100, 2)) ||
                    15}
                  %
                </Text>
                <Text fontSize='1.5rem' ml='0.5rem'>
                  {`/ ${_t('subscription.card.session')}`}
                </Text>
              </>
            )}
          </Flex>
        </Flex>
      </Box>
      <Box p='2rem' width='100%'>
        <Flex justify='center' textAlign='center' mb='2rem'>
          {_t(planDetail.description)}
        </Flex>
        <Box mb='2rem'>
          {planDetail.details.map(o => {
            return (
              <Flex key={o.content} mb='1rem' align='center'>
                <Box width='1.125rem' pt='0.2rem'>
                  <Checked />
                </Box>
                <Text
                  flex='1'
                  lineHeight='1.125rem'
                  fontSize='1.125rem'
                  ml='2rem'
                  color={o.highlight ? 'primary' : 'black'}
                >
                  {_t(o.content)}
                </Text>
                {!!o.note && (
                  <Popover trigger='hover' placement='right'>
                    <PopoverTrigger>
                      <Note cursor='pointer' stroke='primary' />
                    </PopoverTrigger>
                    <PopoverContent>
                      <PopoverBody
                        whiteSpace='pre-line'
                        backgroundColor='primary'
                        color='white'
                      >
                        {_t(o.note)}
                      </PopoverBody>
                    </PopoverContent>
                  </Popover>
                )}
              </Flex>
            )
          })}
        </Box>
        {data &&
          !data?.subscribed &&
          ['personalPlan', 'wolf_pack'].includes(data?.type) &&
          onUpgrade && (
            <Button
              w='100%'
              onClick={() => onUpgrade && onUpgrade(data?.id, data?.price)}
            >
              {_t('subscription.card.upgrade')}
            </Button>
          )}
        {data &&
          data?.subscribed &&
          ['personalPlan', 'wolf_pack'].includes(data?.type) &&
          onDowngrade && (
            <Button
              variant='outlinePrimary'
              w='100%'
              onClick={() => onDowngrade && onDowngrade(data?.id)}
            >
              {_t('subscription.card.cancel')}
            </Button>
          )}
      </Box>
    </Box>
  )
}

export default SubscriptionCard

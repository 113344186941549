import React from 'react'

import { Button, Flex, SimpleGrid, Text } from '@chakra-ui/react'
import { useAtom } from 'jotai'

import { Modal } from 'components'
import Auth from 'configs/auth'
import storageKey from 'configs/storageKey'
import { userProfileAtom } from 'store/authAtom'
import { UserRole } from 'types'

type SwitchAccountModalProps = {
  isOpen: boolean
  description?: string
  onClickCancel: () => void
}

export const SwitchAccountModal = ({
  isOpen,
  description = '',
  onClickCancel,
}: SwitchAccountModalProps) => {
  const [userProfile] = useAtom(userProfileAtom)

  const handleLogout = async () => {
    try {
      const roleTypes = userProfile?.roles?.map(item => item.type)

      if (roleTypes?.includes(UserRole.Tutor)) {
        localStorage.setItem(storageKey.lastRole, 'PrivateTutor')
      } else if (roleTypes?.includes(UserRole.Student)) {
        localStorage.setItem(storageKey.lastRole, 'Student')
      }
      await Auth.signOut()
    } catch (error) {
      console.error('error signing out: ', error)
    }
  }

  return (
    <Modal
      size='xl'
      isOpen={isOpen}
      onClose={onClickCancel}
      modalBodyProps={{ px: { base: '2.5rem', md: '4.5rem' } }}
    >
      <Flex alignItems='center' direction='column'>
        <Text
          as='h1'
          fontSize='2rem'
          fontWeight='600'
          lineHeight='2.5rem'
          mb='2rem'
          color='primary'
          textAlign='center'
        >
          Switch to Learner’s Account
        </Text>
        <Text as='h4' fontSize='0.875' fontWeight='400' lineHeight='1.125rem'>
          {description}
        </Text>

        <SimpleGrid columns={[1, 2, 2]} spacing='1rem' mt='2rem'>
          <Button variant='solidPrimary' onClick={handleLogout}>
            LOG IN AS A LEARNER
          </Button>
          <Button variant='transparent' onClick={onClickCancel}>
            CANCEL
          </Button>
        </SimpleGrid>
      </Flex>
    </Modal>
  )
}

import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { Box, Button, Flex, Text } from '@chakra-ui/react'

import { getCredential } from 'apis/user'
import { LoadingSpinner } from 'components'
import Modal, { ModalProps } from 'components/Modal'
import { useLanguages, useToast } from 'hooks'
import { Attachment } from 'types'
import { getErrorMessage } from 'utils/parser'

export interface PreviewCredentialModalProps
  extends Omit<ModalProps, 'children'> {
  userId: string
  file?: Attachment
  downloadable?: boolean
}

const PreviewCredentialModal: React.FC<PreviewCredentialModalProps> = ({
  isOpen,
  onClose,
  file,
  userId,
  downloadable = true,
}) => {
  const toast = useToast()
  const { _t } = useLanguages()
  const key = useMemo(() => file?.key || '', [file])

  const [isPreviewing, setPreviewing] = useState(false)
  const [isDownloading, setDownloading] = useState(false)
  const [previewUrl, setPreviewUrl] = useState('')

  const handleGetReview = useCallback(
    async (userKey: string) => {
      setPreviewUrl('')
      setPreviewing(true)
      const url = await getCredential(userId, userKey, 'preview')
      setPreviewing(false)
      setPreviewUrl(url.data.data)
    },
    [userId]
  )

  const handleDownload = useCallback(async () => {
    try {
      setDownloading(true)
      const url = await getCredential(userId, key)
      window.open(url.data.data)
    } catch (error) {
      toast({
        title: 'Error',
        description: _t(`message.error.${getErrorMessage(error)}`),
        status: 'error',
      })
    } finally {
      setDownloading(false)
    }
  }, [_t, key, toast, userId])

  useEffect(() => {
    if (isOpen && userId && key) {
      handleGetReview(key)
    }
  }, [handleGetReview, isOpen, key, userId])

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      modalContentProps={{ maxH: 'calc(100vh - 5rem)' }}
    >
      <Text textStyle='headingModalText' textAlign='center' mb='1rem'>
        {_t('user.profile.file_preview')}
      </Text>

      <Box
        h='30rem'
        p='0.75rem'
        mt='1rem'
        overflowY='auto'
        borderColor='lightBlue'
        borderRadius='0.25rem'
      >
        {isPreviewing && <LoadingSpinner />}
        {previewUrl && <Box as='iframe' src={previewUrl} w='100%' h='100%' />}
      </Box>

      {downloadable && (
        <Flex justifyContent='center' alignItems='center' mt='1rem'>
          <Button
            variant='solidPrimary'
            mr='1rem'
            onClick={handleDownload}
            isLoading={isDownloading}
            textTransform='uppercase'
          >
            {_t('common.download')}
          </Button>
        </Flex>
      )}
    </Modal>
  )
}

export default PreviewCredentialModal

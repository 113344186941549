import Axios from 'axios'

import { file, request } from 'configs/apiPath'
import { AddRequest, AdminAddRequest, AvatarUrl } from 'types'

export const createUploadUrl = async ({
  userId,
  requestId,
  data,
}: {
  userId: string
  requestId: string
  data: { contentType: string; fileName: string }
}) => {
  return Axios.post<AvatarUrl>(file.createUrl(userId, requestId), data)
}

export const getAttachmentUrl = ({
  userId,
  requestId,
  key,
}: {
  userId: string
  requestId: string
  key: string
}) => {
  return Axios.get(file.getUrl(userId, requestId, key))
}

export const uploadFile = async (url: string, formData: FormData) => {
  return Axios.post(url, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      'No-Need-Authorization': true,
    },
  })
}

export const addRequest = async ({
  userId,
  data,
  attachFile,
  handleCloseModal,
}: {
  userId: string
  data: AddRequest
  attachFile?: File
  handleCloseModal: () => void
}) => {
  return Axios.post(request.create(userId), data)
}

export const cancelRequest = async ({
  userId,
  requestId,
  handleCloseViewDetail,
}: {
  userId: string
  requestId: string
  handleCloseViewDetail?: () => void
}) => {
  return Axios.delete(request.delete(userId, requestId))
}

// for tutor
export const tutorDeclineRequest = ({ requestId }: { requestId: string }) => {
  return Axios.delete(request.tutorDecline(requestId))
}

// for admin
export const adminAddRequest = async ({
  userId,
  data,
}: {
  userId: string
  data: AdminAddRequest
}) => {
  return Axios.post(request.adminCreate(), data)
}

export const adminCancelRequest = async ({
  requestId,
  handleCloseViewDetail,
}: {
  requestId: string
  handleCloseViewDetail?: () => void
}) => {
  return Axios.delete(request.adminDelete(requestId))
}

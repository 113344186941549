import React from 'react'

import {
  Avatar,
  Badge,
  Box,
  Button,
  ButtonGroup,
  Flex,
  Text,
} from '@chakra-ui/react'

import { useLanguages } from 'hooks'
import { TransactionApprovalInfo, TransactionApprovalStatus } from 'types'
import { getDateTime } from 'utils/date'
import { formatAmount } from 'utils/parser'

interface RefundCardProps {
  data: TransactionApprovalInfo
  onOpenRefundModal?: (refund: TransactionApprovalInfo) => void
  onOpenDetailModal?: (refund: TransactionApprovalInfo) => void
}

const RefundCard: React.FC<RefundCardProps> = ({
  data,
  onOpenRefundModal,
  onOpenDetailModal,
}: RefundCardProps) => {
  const { _t } = useLanguages()

  const generateStatusGroup = (status: TransactionApprovalStatus) => {
    switch (status) {
      case TransactionApprovalStatus.APPROVED: {
        return (
          <Badge variant='solid' colorScheme='green'>
            {_t('refund.completed.refunded')}
          </Badge>
        )
      }
      case TransactionApprovalStatus.REJECTED: {
        return (
          <Badge
            onClick={() => onOpenDetailModal && onOpenDetailModal(data)}
            variant='solid'
            colorScheme='red'
            cursor='pointer'
          >
            {_t('refund.completed.rejected')}
          </Badge>
        )
      }
      default: {
        return <></>
      }
    }
  }
  return (
    <Box bg='#fff' boxShadow='lg' rounded='lg' p='2rem 2.5rem' my='1rem'>
      <Flex mb='0.5rem'>
        <Flex align='center' flex={1}>
          <Avatar
            size='md'
            name={`${data.learner?.firstName ?? ''} ${
              data.learner?.lastName ?? ''
            }`}
            src={data.learner?.avatar}
            key={data.learner?.avatar || 'default-avatar'}
          />
          <Text ml='1rem' fontWeight='600'>
            {`${data?.learner?.firstName || ''} ${
              data?.learner?.lastName || ''
            }`}
          </Text>
        </Flex>
        <Box justifyContent='flex-end'>{generateStatusGroup(data.status)}</Box>
      </Flex>

      <Flex direction={{ base: 'column', md: 'row' }}>
        <Box flex='1'>
          <Flex align='center' mb='0.5rem'>
            <Box
              flex={1}
              textStyle='detailTitle'
              textTransform='uppercase'
              color='gray.300'
            >
              {_t('refund.common.id')}
            </Box>
            <Text flex={2} noOfLines={1} fontSize='0.875rem'>
              {data?.id}
            </Text>
          </Flex>
          <Flex align='center' mb='0.5rem'>
            <Box
              flex={1}
              textStyle='detailTitle'
              textTransform='uppercase'
              color='gray.300'
            >
              {_t('refund.common.date_time')}
            </Box>
            <Text flex={2} noOfLines={1} fontSize='0.875rem'>
              {getDateTime(new Date(data.createdAt))}
            </Text>
          </Flex>
          <Flex align='center' mb='0.5rem'>
            <Box
              flex={1}
              textStyle='detailTitle'
              textTransform='uppercase'
              color='gray.300'
            >
              {_t('refund.common.class')}
            </Box>
            <Text flex={2} noOfLines={1} fontSize='0.875rem'>
              {data?.description}
            </Text>
          </Flex>
          <Flex align='center' mb='0.5rem'>
            <Box
              flex={1}
              textStyle='detailTitle'
              textTransform='uppercase'
              noOfLines={2}
              color='gray.300'
            >
              {_t('refund.common.fee')}
            </Box>
            <Text flex={2} noOfLines={1} fontSize='0.875rem'>{`$${formatAmount(
              (data?.transaction.amount || 0) / 100
            )}`}</Text>
          </Flex>
        </Box>

        <Box flex='1'>
          <Flex mb='0.5rem'>
            <Box
              flex={1}
              textStyle='detailTitle'
              textTransform='uppercase'
              color='gray.300'
            >
              {_t('refund.common.reason')}
            </Box>
            <Text flex={2} noOfLines={4} fontSize='0.875rem'>
              {data?.reason}
            </Text>
          </Flex>
        </Box>
      </Flex>

      {onOpenRefundModal && (
        <Flex justify={{ base: 'center', md: 'flex-end' }}>
          <ButtonGroup display='flex' spacing='1rem'>
            <Button
              variant='solidPrimary'
              textTransform='uppercase'
              onClick={() => onOpenRefundModal(data)}
            >
              {_t('refund.pending.approval')}
            </Button>
          </ButtonGroup>
        </Flex>
      )}
    </Box>
  )
}

export default RefundCard

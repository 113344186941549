import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'

import {
  CreateProductLanding,
  Dashboard,
  Explore,
  InstructorCourses,
  InstructorCreateClass,
  InstructorCreateContent,
  InstructorCreateLesson,
  InstructorEditClass,
  InstructorEditContent,
  InstructorEditDraftClass,
  InstructorEditProfile,
  InstructorInstructorView,
  InstructorLearnerView,
  InstructorNotifications,
  InstructorPreviewAdhocClass,
  InstructorPreviewAssignment,
  InstructorPreviewClass,
  InstructorPreviewContent,
  InstructorPreviewDraftClass,
  InstructorPreviewLesson,
  InstructorViewProfile,
  InstructorWallet,
  Meeting,
  SearchResult,
} from 'containers'
import { useProfile } from 'hooks'
import { Notification, Payment } from 'shared/containers'
import { Login } from 'types'

const TutorRoutes = () => {
  useProfile()
  return (
    <Routes>
      <Route path='/' element={<Dashboard />} />
      <Route path='/my-courses'>
        <Route path='/' element={<InstructorCourses />} />
        <Route
          path='/assignments/:assignmentId'
          element={<InstructorPreviewAssignment />}
        />
      </Route>
      <Route path='/create'>
        <Route path='/' element={<CreateProductLanding />} />
        <Route path='/class' element={<InstructorCreateClass />} />
        <Route path='/content' element={<InstructorCreateContent />} />
        <Route path='/lesson' element={<InstructorCreateLesson />} />
      </Route>
      <Route path='/explore' element={<Explore loginType={Login.UserName} />} />
      <Route path='/my-profile' element={<InstructorViewProfile />} />
      <Route path='/my-profile/edit' element={<InstructorEditProfile />} />
      <Route path='/wallet' element={<InstructorWallet />} />
      <Route path='/product'>
        <Route path='/class/:id' element={<InstructorPreviewClass />} />
        <Route path='/class/edit/:id' element={<InstructorEditClass />} />
        <Route path='/content/:id' element={<InstructorPreviewContent />} />
        <Route path='/content/edit/:id' element={<InstructorEditContent />} />
        <Route path='/lesson/:id' element={<InstructorPreviewLesson />} />
        <Route
          path='/adhoc-class/:id'
          element={<InstructorPreviewAdhocClass />}
        />
      </Route>
      <Route path='/notifications' element={<InstructorNotifications />} />
      <Route path='/notifications/:id' element={<Notification />} />
      <Route
        path='/search-results'
        element={<SearchResult loginType={Login.UserName} />}
      />
      <Route path='/learner/:id' element={<InstructorLearnerView />} />
      <Route path='/instructors/:id' element={<InstructorInstructorView />} />
      <Route path='/draft-class'>
        <Route
          path='/preview/class/:id'
          element={<InstructorPreviewDraftClass />}
        />
        <Route path='/edit/class/:id' element={<InstructorEditDraftClass />} />
      </Route>
      <Route path='/meeting' element={<Meeting />} />
      <Route path='/payment/topup' element={<Payment type='topup' />} />
      <Route path='/payment/subscribe' element={<Payment type='subscribe' />} />
      <Route path='/*' element={<Navigate replace to='/' />} />
    </Routes>
  )
}

export default TutorRoutes

export default {
  role: 'mysavvytutor_role',
  lastRole: 'mysavvytutor_lastRole',
  accessToken: 'access_token',
  refreshToken: 'refresh_token',
  expiresAt: 'expires_at',
  userId: 'user_id',
  loginType: 'login_type',
  roles: 'roles',
  signUpRole: 'sign_up_role',
  language: 'language',
  game: 'game',
  gameStats: 'game_stats',
  gameUser: 'game_user',
  cart: 'cart',
}

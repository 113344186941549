import Axios from 'axios'

import { learnerPath } from 'configs/apiPath'

export interface AdHocRequest {
  subjectId: string
  languageId: string
  teachingLevelId: string
  startDateTime: number
  endDateTime: number
  message: string
  title: string
}

export interface AdminAdhocRequest extends AdHocRequest {
  userId: string
}

export const createAdHocRequest = async ({
  userId,
  request,
}: {
  userId: string
  request: AdHocRequest
}): Promise<any> => {
  return Axios.post(learnerPath.adHocRequest(userId), request)
}

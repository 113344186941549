import React from 'react'

import { Avatar, Box, Flex, Text } from '@chakra-ui/react'

import { Learner, UserRole } from 'types'

interface PreviewLearnerItemProps {
  learner: Learner
  index: number
}

const PreviewLearnerItem: React.FC<PreviewLearnerItemProps> = ({
  learner,
  index,
}) => {
  const isEnterpriseLearner =
    learner?.accountType?.name === UserRole.EnterpriseStudent

  return (
    <Flex fontSize='0.875rem' alignItems='center' py='0.8rem' w='100%'>
      <Text>{index}.</Text>
      <Box w='3rem' ml='1rem' mr='0.5rem'>
        <Avatar
          src={learner.avatar}
          size='md'
          name={`${learner.firstName} ${learner.lastName}`}
        />
      </Box>
      <Box w='calc(100% - 5.5rem)'>
        <Text noOfLines={1} title={`${learner.firstName} ${learner.lastName}`}>
          {learner.firstName} {learner.lastName}
        </Text>
        <Text
          color='gray.300'
          textOverflow='ellipsis'
          overflow='hidden'
          whiteSpace='nowrap'
          title={`${isEnterpriseLearner ? 'Enterprise |' : ''} ${
            learner.email || ''
          }`}
        >
          {`${isEnterpriseLearner ? 'Enterprise |' : ''} ${
            learner.email || ''
          }`}
        </Text>
      </Box>
    </Flex>
  )
}

export default PreviewLearnerItem

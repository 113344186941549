import Axios from 'axios'

import { subscriptionPath } from 'configs/apiPath'
import { SubscriptionCancelRequest, SubscriptionSubscribeRequest } from 'types'

export const subscribe = async (data: SubscriptionSubscribeRequest) => {
  return Axios.post(subscriptionPath.order(), data)
}

export const cancel = async (data: SubscriptionCancelRequest) => {
  return Axios.delete(subscriptionPath.cancel(), { data })
}

import React from 'react'

import { Badge } from '@chakra-ui/react'

import { useLanguages } from 'hooks'
import { TransactionStatus } from 'types'

interface TransactionStatusBadgeProps {
  status?: TransactionStatus
}

const TransactionStatusBadge: React.FC<TransactionStatusBadgeProps> = ({
  status,
}) => {
  const { _t } = useLanguages()

  switch (status) {
    case TransactionStatus.SUCCESS:
    case TransactionStatus.PROCESSING:
    case TransactionStatus.COMPLETED:
      return (
        <Badge variant='solid' colorScheme='green'>
          {_t('wallet.transaction.status_success')}
        </Badge>
      )
    case TransactionStatus.FAILED:
    case TransactionStatus.REJECTED:
      return (
        <Badge variant='solid' colorScheme='red'>
          {_t('wallet.transaction.status_failed')}
        </Badge>
      )
    case TransactionStatus.REFUNDED:
      return (
        <Badge variant='solid' colorScheme='yellow'>
          {_t('wallet.transaction.status_refunded')}
        </Badge>
      )
    case TransactionStatus.PENDING_APPROVAL:
      return (
        <Badge variant='solid'>
          {_t('wallet.transaction.status_pending_approval')}
        </Badge>
      )
    default:
      return (
        <Badge variant='solid'>{_t('wallet.transaction.status_pending')}</Badge>
      )
  }
}

export default TransactionStatusBadge

import Axios from 'axios'

import { refundPath } from './../configs/apiPath'
import { RefundApprovalRequest, RefundRequestRequest } from 'types'

export const refundRequest = ({
  data,
  onClose,
}: {
  data: RefundRequestRequest
  onClose?: () => void
}) => {
  return Axios.post(refundPath.request(), data)
}

export const refundApproval = ({
  data,
  onClose,
}: {
  data: RefundApprovalRequest
  onClose?: () => void
}) => {
  return Axios.put(refundPath.approval(), data)
}

import React from 'react'

import { Box, Flex, Text } from '@chakra-ui/react'
import { useAtom } from 'jotai'
import debounce from 'lodash.debounce'

import { filter, filterAtom } from '../atom'
import { Input } from 'components'
import { useLanguages } from 'hooks'

export default () => {
  const { _t } = useLanguages()
  const [, setFilter] = useAtom(filterAtom)
  const [filterValues] = useAtom(filter)

  const onMinChange = debounce((e: React.ChangeEvent<HTMLInputElement>) => {
    setFilter({
      ...filterValues,
      price: {
        ...(filterValues?.price || {}),
        from: Number.parseInt(e.target.value || '0'),
      },
    })
  }, 250)

  const onMaxChange = debounce((e: React.ChangeEvent<HTMLInputElement>) => {
    setFilter({
      ...filterValues,
      price: {
        ...(filterValues?.price || {}),
        to: Number.parseInt(e.target.value || '999999999'),
      },
    })
  }, 250)

  return (
    <Flex direction='column' w='100%'>
      <Text as='h4' fontWeight='400' fontSize='0.75rem'>
        {_t('search.filter.price_range')}
      </Text>
      <Flex direction='row' alignItems='center' w='100%'>
        <Input
          placeholder={_t('search.filter.placeholder_min_price_range')}
          type='number'
          onChange={onMinChange}
          defaultValue={filterValues?.price?.from}
        />
        <Box
          as='span'
          w='30px'
          h='1px'
          borderTop='1px solid #828282'
          m='0 1.125rem'
        />
        <Input
          placeholder={_t('search.filter.placeholder_max_price_range')}
          type='number'
          onChange={onMaxChange}
          defaultValue={filterValues?.price?.to}
        />
      </Flex>
    </Flex>
  )
}

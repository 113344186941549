import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'

import EditProfileContainer from '../../containers/EditProfile'
import ProfileLayout from '../../containers/ProfileLayout'
import { AvatarRef, LoadingSpinner, TeachingSubjectSection } from 'components'
import { useLanguages, useUserId } from 'hooks'
import {
  useUpdateUser,
  useUpdateUserProfile,
  useUserDetail,
  useUserProfile,
} from 'queries/user'
import { CredentialRef, UserEditForm, UserProfileRef } from 'types'
import { getErrorMessage } from 'utils/parser'
import { uploadCredentials, uploadUserAvatar } from 'utils/upload'

interface EditInstructorContainerProps {
  centerId?: string
  enterpriseId?: string
  backLabel?: string
  handleCancel?: () => void
  isPrivate?: boolean
  isAdmin?: boolean
}

const EditInstructorContainer: React.FC<EditInstructorContainerProps> = ({
  centerId,
  enterpriseId,
  backLabel,
  handleCancel,
  isPrivate = false,
  isAdmin = false,
}) => {
  const { _t } = useLanguages()
  const params = useParams()
  const selfUserId = useUserId()
  const [error, setError] = useState<string>()

  const userId = useMemo(() => (isAdmin ? params.id : selfUserId), [
    isAdmin,
    params.id,
    selfUserId,
  ])
  const { data: userProfile } = useUserDetail(userId, isAdmin)
  const { data: selfProfile } = useUserProfile(userId)

  const profileData = isAdmin ? userProfile?.data : selfProfile?.data
  const userRef = useRef<UserProfileRef>()
  const credentialRef = useRef<CredentialRef>()
  const avatarRef = useRef<AvatarRef>()

  const updateCall = isAdmin ? useUpdateUser : useUpdateUserProfile
  const { mutate: updateUser, isSuccess } = updateCall(userId)

  useEffect(() => {
    if (isSuccess) {
      handleCancel && handleCancel()
    }
  }, [isSuccess, handleCancel])

  const handleSave = async () => {
    try {
      await uploadUserAvatar(avatarRef, userId)
      await uploadCredentials(credentialRef, userId)

      const formattedData = (await userRef.current?.getData()) as UserEditForm
      if (!formattedData) {
        return
      }

      updateUser({ userId, data: formattedData })
    } catch (err) {
      setError(getErrorMessage(err))
    }
  }

  const title = isAdmin
    ? _t('user_management.instructor.edit_title')
    : _t('profile.edit.title')

  return (
    <ProfileLayout
      title={title}
      backLabel={backLabel}
      handleCancel={handleCancel}
      handleSubmit={handleSave}
      isAdminView={isAdmin}
    >
      {profileData ? (
        <EditProfileContainer
          userId={userId}
          centerId={centerId}
          enterpriseId={enterpriseId}
          profileData={profileData}
          avatarRef={avatarRef}
          credentialRef={credentialRef}
          userRef={userRef}
          subjectSection={
            <TeachingSubjectSection
              userId={userId}
              centerId={centerId || profileData.centers?.[0].id}
              isAdmin={isAdmin}
            />
          }
          toastError={error}
          setToastError={setError}
          isInstructor
          isPrivate={isPrivate}
          isAdmin={isAdmin}
        />
      ) : (
        <LoadingSpinner />
      )}
    </ProfileLayout>
  )
}

export default EditInstructorContainer

import React from 'react'

import { Box, Flex, Text } from '@chakra-ui/react'

import { ConfirmAlert } from 'components'
import { SubjectCombination } from 'types'

import { ReactComponent as CloseIcon } from '../../assets/images/close_gray_icon.svg'

interface TeachingSubjectLevelItemProps {
  data: Omit<SubjectCombination, 'description' | 'code'>
  showTitle: boolean
  onRemoveItem: (subjectId: string) => void
}

const TeachingSubjectLevelItem: React.FC<TeachingSubjectLevelItemProps> = ({
  data,
  showTitle = true,
  onRemoveItem,
}) => {
  const handleCloseItem = () => {
    return onRemoveItem(data.id)
  }

  return (
    <Box>
      {showTitle && (
        <Text ml='0.5rem' mb='0.5rem' fontSize='0.875rem'>
          {data.country.name}-{data.system.name}
        </Text>
      )}
      <Box
        w='100%'
        p='0.75rem'
        bg='#fff'
        my='4px'
        boxShadow='0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)'
      >
        <Flex justifyContent='space-between' alignItems='center'>
          <Flex>
            <Text
              pr='0.5rem'
              mr='0.5rem'
              fontSize='0.875rem'
              borderRight='1px solid #E0E0E0'
            >
              {data.subject.name}
            </Text>
            <Text fontSize='0.875rem'>{data.teachingLevel.name}</Text>
          </Flex>
          <ConfirmAlert
            title='Do you want to DELETE this subject?'
            onSubmit={handleCloseItem}
            immediatelyCloseOnSubmit
          >
            {({ showAlert }) => (
              <CloseIcon cursor='pointer' onClick={showAlert} />
            )}
          </ConfirmAlert>
        </Flex>
      </Box>
    </Box>
  )
}

export default TeachingSubjectLevelItem

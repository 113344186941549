import React, { useCallback, useState } from 'react'

import { useAtom } from 'jotai'

import FullCalendar, { Event } from './index'
import { Period } from 'components/Calendar'
import { useCalendar } from 'queries/calendar'
import { userProfileAtom } from 'store/authAtom'
import { getFullName } from 'utils/parser'

const FullCalendarController = () => {
  const [userProfile] = useAtom(userProfileAtom)
  const role = userProfile?.roles?.[0]
  const isTutor = role?.name?.toLowerCase()?.includes('tutor')
  const [period, setPeriod] = useState<Period>()
  const { data: calendarData } = useCalendar({
    roleName: role?.name.replace(/ /g, ''),
    fromDate: period?.from?.getTime(),
    toDate: period?.to?.getTime(),
  })

  const formattedEvents = calendarData?.data.reduce((res: Event[], cur) => {
    if (cur.data.length) {
      return [
        ...res,
        ...cur.data.map(o => {
          return ({
            id: o.class?.id,
            title: o.name,
            type: o.class?.type,
            description: isTutor
              ? o.class?.name || ''
              : o.tutor && `with ${getFullName(o.tutor)}`,
            start: new Date(o.startDateTime),
            end: new Date(o.endDateTime),
          } as unknown) as Event
        }),
      ]
    }

    return res
  }, [])

  const handleChangePeriod = useCallback((newPeriod: Period) => {
    setPeriod(newPeriod)
  }, [])

  return (
    <FullCalendar
      events={formattedEvents}
      onChangePeriod={handleChangePeriod}
    />
  )
}
export default FullCalendarController

import React from 'react'

import { mstAdminRoutes } from './mstAdminRoutes'
import {
  AddNotification,
  AdminPreviewCentre,
  AdminCreateCentre,
  AdminEditCentre,
  BidsAndRequests,
  Campaign,
  CashFlow,
  Connections,
  CreateCampaign,
  CreateCentreAdminProfile,
  CreateEnterprise,
  CreateEnterpriseAdminProfile,
  CreateInstructor,
  CreateLearner,
  CreateMstAdmin,
  CreatePromoCode,
  CreateSession,
  CreateSessionClass,
  CreateSessionContent,
  CreateSessionLesson,
  CreateWithdrawalTransaction,
  EditCentreAdmin,
  EditEnterprise,
  EditEnterpriseAdminProfile,
  EditMstAdmin,
  EditNotification,
  EditPromoCode,
  EditRoleResponsibility,
  EditSessionClass,
  EditSessionContent,
  EditWithdrawalTransaction,
  EnterpriseManagement,
  ExploreManagement,
  InstructorProfile,
  LearnerProfile,
  Multilingualism,
  Notification,
  Notifications,
  PreviewSessionClass,
  PreviewSessionContent,
  PreviewSessionLesson,
  ProfileEnterprise,
  PromoCodes,
  RoleAndResponsibility,
  SessionCostSettings,
  SessionManagement,
  SubjectLibrarySettings,
  SubjectMatrixSettings,
  TeachingLanguage,
  Transaction,
  TransactionApproval,
  TransactionWithdrawal,
  UserManagement,
  ViewCampaign,
  ViewCashFlow,
  ViewMyTransaction,
  ViewPromoCode,
  ViewTransaction,
  ViewWithdrawalTransaction,
  Wallet,
} from 'containers/Admin'
import { SidebarRoute, UserRole } from 'types'

export const adminRoutes: (isMstAdmin: boolean) => SidebarRoute[] = (
  isMstAdmin: boolean
) => {
  if (isMstAdmin) {
    return mstAdminRoutes
  }

  return [
    {
      name: 'admin.sidebar.user_management',
      link: '/admin/user-management',
      children: [
        {
          name: 'admin.sidebar.learners',
          link: '/admin/user-management/learner',
          element: (
            <UserManagement
              tableType='Learners'
              type={UserRole.AdminManageStudent}
            />
          ),
        },
        {
          name: '',
          link: '/admin/user-management/learner/create',
          element: <CreateLearner />,
          hidden: true,
        },
        {
          name: '',
          link: '/admin/user-management/learner/:id',
          element: <LearnerProfile />,
          hidden: true,
        },
        {
          name: 'admin.sidebar.instructors',
          link: '/admin/user-management/instructor',
          element: (
            <UserManagement
              tableType='Instructors'
              type={UserRole.AdminManageTutor}
            />
          ),
        },
        {
          name: '',
          link: '/admin/user-management/instructor/create',
          element: <CreateInstructor />,
          hidden: true,
        },
        {
          name: '',
          link: '/admin/user-management/instructor/:id',
          element: <InstructorProfile />,
          hidden: true,
        },
        {
          name: 'admin.sidebar.enterprise_admin',
          link: '/admin/user-management/enterprise-admin',
          element: (
            <UserManagement
              tableType='Enterprise Admin'
              type={UserRole.AdminManageEnterpriseAdmin}
            />
          ),
        },
        {
          name: '',
          link: '/admin/user-management/enterprise-admin/create',
          element: <CreateEnterpriseAdminProfile />,
          hidden: true,
        },
        {
          name: '',
          link: '/admin/user-management/enterprise-admin/:id',
          element: <EditEnterpriseAdminProfile />,
          hidden: true,
        },
        {
          name: 'admin.sidebar.centre_admin',
          link: '/admin/user-management/center-admin',
          element: (
            <UserManagement
              tableType='Centre Admin'
              type={UserRole.AdminManageCenterAdmin}
            />
          ),
        },
        {
          name: '',
          link: '/admin/user-management/center-admin/create',
          element: <CreateCentreAdminProfile />,
          hidden: true,
        },
        {
          name: '',
          link: '/admin/user-management/center-admin/:id',
          element: <EditCentreAdmin />,
          hidden: true,
        },
        {
          name: 'admin.sidebar.mst_admin',
          link: '/admin/user-management/super-admin/create',
          element: <CreateMstAdmin />,
          hidden: true,
        },
        {
          name: 'admin.sidebar.mst_admin',
          link: '/admin/user-management/super-admin/:id',
          element: <EditMstAdmin />,
          hidden: true,
        },
        {
          name: 'admin.sidebar.mst_admin',
          link: '/admin/user-management/super-admin',
          element: (
            <UserManagement
              tableType='Knovo Super Admin'
              type={UserRole.MSTSuperAdmin}
            />
          ),
        },
        {
          name: 'admin.sidebar.connections',
          link: '/admin/user-management/connections',
          element: <Connections />,
        },
      ],
    },
    {
      name: 'admin.sidebar.role_responsibilities',
      link: '/admin/role-responsibility',
      element: <RoleAndResponsibility />,
    },
    {
      name: 'Edit Role',
      link: '/role-responsibility/:roleId/edit',
      element: <EditRoleResponsibility />,
      hidden: true,
    },
    {
      name: 'admin.sidebar.session_management',
      link: '/admin/session-management',
      children: [
        {
          name: 'admin.sidebar.session_management',
          link: '/admin/session-management/dashboard',
          element: <SessionManagement />,
          hidden: true,
        },
        {
          name: 'admin.sidebar.create_class',
          link: '/admin/session-management/create',
          element: <CreateSession />,
        },
        {
          name: 'admin.sidebar.create_session_class',
          link: '/admin/session-management/create/class',
          element: <CreateSessionClass />,
          hidden: true,
        },
        {
          name: 'admin.sidebar.create_session_content',
          link: '/admin/session-management/create/content',
          element: <CreateSessionContent />,
          hidden: true,
        },
        {
          name: 'admin.sidebar.create_session_lesson',
          link: '/admin/session-management/create/lesson',
          element: <CreateSessionLesson />,
          hidden: true,
        },
        {
          name: 'admin.sidebar.preview_session_class',
          link: '/admin/session-management/preview/class/:id',
          element: <PreviewSessionClass />,
          hidden: true,
        },
        {
          name: 'admin.sidebar.preview_session_content',
          link: '/admin/session-management/preview/content/:id',
          element: <PreviewSessionContent />,
          hidden: true,
        },
        {
          name: 'admin.sidebar.preview_session_lesson',
          link: '/admin/session-management/preview/lesson/:id',
          element: <PreviewSessionLesson />,
          hidden: true,
        },
        {
          name: 'admin.sidebar.edit_session_class',
          link: '/admin/session-management/edit/class/:id',
          element: <EditSessionClass />,
          hidden: true,
        },
        {
          name: 'admin.sidebar.edit_session_content',
          link: '/admin/session-management/edit/content/:id',
          element: <EditSessionContent />,
          hidden: true,
        },
        {
          name: 'admin.sidebar.bids_and_requests',
          link: '/admin/session-management/bids-and-requests',
          element: <BidsAndRequests />,
        },
      ],
    },
    {
      name: 'admin.sidebar.enterprise_management',
      link: '/admin/enterprise-management',
      children: [
        {
          name: '',
          link: '/admin/enterprise-management/data',
          element: <EnterpriseManagement />,
          hidden: true,
        },
        {
          name: '',
          link: '/admin/enterprise-management/profile/:enterpriseId',
          element: <ProfileEnterprise />,
          hidden: true,
        },
        {
          name: '',
          link: '/admin/enterprise-management/enterprise/create',
          element: <CreateEnterprise />,
          hidden: true,
        },
        {
          name: '',
          link: '/admin/enterprise-management/enterprise/edit/:enterpriseId',
          element: <EditEnterprise />,
          hidden: true,
        },
        {
          name: '',
          link: '/admin/enterprise-management/centre/create',
          element: <AdminCreateCentre />,
          hidden: true,
        },
        {
          name: '',
          link: '/admin/enterprise-management/centre/:centreId',
          element: <AdminPreviewCentre />,
          hidden: true,
        },
        {
          name: '',
          link: '/admin/enterprise-management/centre/edit/:centreId',
          element: <AdminEditCentre />,
          hidden: true,
        },
      ],
    },
    {
      name: 'admin.sidebar.wallet_management',
      link: '/admin/wallet-management',
      children: [
        {
          name: 'admin.sidebar.wallet_management',
          link: '/admin/wallet-management/manage',
          element: <Wallet />,
          hidden: true,
        },
        {
          name: 'Transaction Detail',
          link: '/admin/wallet-management/view/:id',
          element: <ViewMyTransaction />,
          hidden: true,
        },
      ],
    },
    {
      name: 'admin.sidebar.cash_flow_management',
      link: '/admin/cash-flow-management',
      children: [
        {
          name: 'admin.sidebar.cash_flow_management',
          link: '/admin/cash-flow-management/manage',
          element: <CashFlow />,
          hidden: true,
        },
        {
          name: 'Cash Flow Detail',
          link: '/admin/cash-flow-management/view/:id',
          element: <ViewCashFlow />,
          hidden: true,
        },
      ],
    },
    {
      name: 'admin.sidebar.transaction_management',
      link: '/admin/transaction-management',
      children: [
        {
          name: 'admin.sidebar.transaction_management',
          link: '/admin/transaction-management/manage',
          element: <Transaction />,
          hidden: true,
        },
        {
          name: 'Transaction Detail',
          link: '/admin/transaction-management/view/:id',
          element: <ViewTransaction />,
          hidden: true,
        },
        {
          name: 'admin.sidebar.withdrawal_management',
          link: '/admin/transaction-management/withdrawal',
          element: <TransactionWithdrawal />,
        },
        {
          name: 'Withdrawal Transaction Detail',
          link: '/admin/transaction-management/withdrawal/view/:id',
          element: <ViewWithdrawalTransaction />,
          hidden: true,
        },
        {
          name: 'Create Withdrawal Transaction',
          link: '/admin/transaction-management/withdrawal/create',
          element: <CreateWithdrawalTransaction />,
          hidden: true,
        },
        {
          name: 'Edit Withdrawal Transaction',
          link: '/admin/transaction-management/withdrawal/edit/:id',
          element: <EditWithdrawalTransaction />,
          hidden: true,
        },
      ],
    },
    {
      name: 'admin.sidebar.transaction_approval_management',
      link: '/admin/transaction-approval-management',
      children: [
        {
          name: 'admin.sidebar.transaction_approval_management',
          link: '/admin/transaction-approval-management/manage',
          element: <TransactionApproval />,
          hidden: true,
        },
      ],
    },
    {
      name: 'admin.sidebar.mst_settings',
      link: '/admin/settings',
      children: [
        {
          name: 'admin.sidebar.session_cost',
          link: '/admin/settings/session-cost',
          element: <SessionCostSettings />,
        },
        // {
        //   name: 'Request Bid Settings',
        //   link: '/admin/settings/request-bid'
        // },
        // {
        //   name: 'Cancellation & Refunds Settings',
        //   link: '/admin/settings/refund'
        // },
        {
          name: 'admin.sidebar.subject_library',
          link: '/admin/settings/subject-library',
          element: <SubjectLibrarySettings />,
        },
        {
          name: 'admin.sidebar.subject_matrix',
          link: '/admin/settings/subject-matrix',
          element: <SubjectMatrixSettings />,
        },
        {
          name: 'admin.sidebar.teaching_language',
          link: '/admin/settings/teaching_language',
          element: <TeachingLanguage />,
        },

        {
          name: 'admin.sidebar.multilingualism',
          link: '/admin/settings/multilingualism',
          element: <Multilingualism />,
        },
      ],
    },
    {
      name: 'admin.sidebar.promo_codes',
      link: '/admin/promo-codes',
      children: [
        {
          name: '',
          link: '/admin/promo-codes/data',
          element: <PromoCodes />,
          hidden: true,
        },
        {
          name: '',
          link: '/admin/promo-codes/add',
          element: <CreatePromoCode />,
          hidden: true,
        },
        {
          name: '',
          link: '/admin/promo-codes/edit/:promoId',
          element: <EditPromoCode />,
          hidden: true,
        },
        {
          name: '',
          link: '/admin/promo-codes/view/:promoId',
          element: <ViewPromoCode />,
          hidden: true,
        },
      ],
    },
    {
      name: 'admin.sidebar.notifications',
      link: '/admin/notification',
      children: [
        {
          name: '',
          link: '/admin/notification/data',
          element: <Notification />,
          hidden: true,
        },
        {
          name: '',
          link: '/admin/notification/add',
          element: <AddNotification />,
          hidden: true,
        },
        {
          name: '',
          link: '/admin/notification/:notificationId',
          element: <EditNotification />,
          hidden: true,
        },
      ],
    },
    {
      name: 'admin.sidebar.campaigns',
      link: '/admin/campaigns',
      children: [
        {
          name: '',
          link: '/admin/campaigns/manage',
          element: <Campaign />,
          hidden: true,
        },
        {
          name: '',
          link: '/admin/campaigns/add',
          element: <CreateCampaign />,
          hidden: true,
        },
        {
          name: '',
          link: '/admin/campaigns/:campaignId',
          element: <ViewCampaign />,
          hidden: true,
        },
      ],
    },
    {
      name: 'admin.sidebar.explore_management',
      link: '/admin/explore-management',
      element: <ExploreManagement />,
    },
    {
      name: 'admin.sidebar.my_notifications',
      link: '/admin/all-notifications',
      children: [
        {
          name: '',
          link: '/admin/all-notifications/list',
          element: <Notifications />,
          hidden: true,
        },
        {
          name: '',
          link: '/admin/all-notifications/:id',
          element: <Notifications />,
          hidden: true,
        },
      ],
    },
  ]
}

import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { Box, Grid, Tab, TabList, Tabs, Text } from '@chakra-ui/react'
import { useAtom } from 'jotai'

import {
  CardList,
  FilterBox,
  LoadingSpinner,
  SearchBar,
  Visible,
} from 'components'
import storageKey from 'configs/storageKey'
import { filter } from 'containers/SearchResult/atom'
import {
  PriceRangeFilter,
  SortByFilter,
  SubjectFilter,
  TeachingLanguageFilter,
  TeachingLevelFilter,
} from 'containers/SearchResult/components'
import { useLanguages, useQueryParams } from 'hooks'
import { useGuestSearchClass, useGuestSearchContent } from 'queries/guest'
import { useLearnerSearchClass, useLearnerSearchContent } from 'queries/learner'
import { ClassCard } from 'shared/containers'
import { ClassSearchResult, ContentSearchResult, ResultType } from 'types'
import { getData } from 'utils/helper'

interface CentreLessonProps {
  centreName?: string
}

const CentreLesson: React.FC<CentreLessonProps> = ({ centreName = '' }) => {
  const { _t } = useLanguages()
  const queryParams = useQueryParams()
  const navigate = useNavigate()
  const params = useParams()
  const [filterValue] = useAtom(filter)
  const centreId = useMemo(() => params.id, [params])

  // ** States
  const [tab, setTab] = useState<number>(0)
  const [classList, setClassList] = useState<ClassSearchResult[]>([])
  const [classTotal, setClassTotal] = useState<any>(0)
  const [classPage, setClassPage] = useState(1)
  const [contentList, setContentList] = useState<ContentSearchResult[]>([])
  const [contentTotal, setContentTotal] = useState<any>(0)
  const [contentPage, setContentPage] = useState(1)
  const [limit] = useState(20)
  const [search, setSearch] = useState('')
  const isGuest = getData(storageKey.loginType) === 'guest'
  const queryClass = isGuest ? useGuestSearchClass : useLearnerSearchClass
  const queryContent = isGuest ? useGuestSearchContent : useLearnerSearchContent

  useEffect(() => {
    setClassPage(1)
    setContentPage(1)
  }, [])

  const { data: classResult, isLoading: isClassLoading } = queryClass({
    search: search,
    limit: limit,
    page: classPage,
    centerId: centreId,
    subjectId: filterValue.subjectId,
    languageId: filterValue.languageId,
    teachingLevelId: filterValue.teachingLevelId,
    minPrice: filterValue?.price?.from,
    maxPrice: filterValue?.price?.to,
    order: filterValue?.order || 'asc',
    orderBy: filterValue?.orderBy || 'date',
  })

  const { data: contentResult, isLoading: isContentLoading } = queryContent({
    search: search,
    limit: limit,
    page: contentPage,
    centerId: centreId,
    subjectId: filterValue.subjectId,
    languageId: filterValue.languageId,
    teachingLevelId: filterValue.teachingLevelId,
    minPrice: filterValue?.price?.from,
    maxPrice: filterValue?.price?.to,
    order: filterValue?.order || 'asc',
    orderBy: filterValue?.orderBy || 'date',
  })

  useEffect(() => {
    const { type = 'class' } = queryParams
    setTab(type === 'class' ? 0 : 1)
  }, [queryParams])

  useEffect(() => {
    const allResults = classResult?.data || []
    if (classResult) {
      const count = classResult?.meta?.total || 0

      setClassTotal(count)

      if (classPage > 1) {
        setClassList(old => [...old, ...allResults])
      } else {
        setClassList([...allResults])
      }
    }
  }, [isClassLoading, classResult, classPage])

  useEffect(() => {
    const allResults = contentResult?.data || []
    if (contentResult) {
      const count = contentResult?.meta?.total || 0

      setContentTotal(count)

      if (contentPage > 1) {
        setContentList(old => [...old, ...allResults])
      } else {
        setContentList([...allResults])
      }
    }
  }, [isContentLoading, contentResult, contentPage])

  const handleTabChange = (index: any) => {
    const parameters = { ...queryParams }

    parameters['type'] = index === 0 ? 'class' : 'content'

    const queryString = Object.keys(parameters)
      .map(key => {
        return `${encodeURIComponent(key)}=${encodeURIComponent(
          parameters[key] as string
        )}`
      })
      .join('&')

    const pathname = window.location.pathname

    navigate(`${pathname}?${queryString}`)
  }

  const handleLoadMoreClass = useCallback(() => {
    const lastPage = Math.ceil(classTotal / limit)

    if (!isClassLoading && classPage <= lastPage) {
      setClassPage(oldPage => oldPage + 1)
    }
  }, [isClassLoading, limit, classPage, classTotal])

  const handleLoadMoreContent = useCallback(() => {
    const lastPage = Math.ceil(contentTotal / limit)

    if (!isContentLoading && contentPage <= lastPage) {
      setContentPage(oldPage => oldPage + 1)
    }
  }, [isContentLoading, limit, contentPage, contentTotal])

  const handleSearch = (val: any) => {
    setSearch(val)
    setContentPage(1)
    setClassPage(1)
  }

  const renderClassResults = useCallback(() => {
    return classList.map((item: any, idx: number) => (
      <ClassCard key={`s-class-result-${idx}`} data={item} />
    ))
  }, [classList])

  const renderContentResult = useCallback(() => {
    return contentList.map((item: any, idx: number) => (
      <ClassCard key={`s-content-result-${idx}`} data={item} />
    ))
  }, [contentList])

  return (
    <Tabs index={tab} onChange={handleTabChange}>
      <TabList borderBottom='none'>
        <Tab
          _focus={{ outline: 'none' }}
          _selected={{ backgroundColor: 'rgba(57, 88, 191, 0.1)' }}
          _active={{
            background: 'none',
            borderBottom: 'none',
            outline: 'none',
          }}
          background='white'
          borderBottom='none'
          fontSize='1rem'
          fontWeight='600'
          height='60px'
          flex='1'
        >
          <Text>{_t('centre.lessons.class')}</Text>
        </Tab>
        <Tab
          _focus={{ outline: 'none' }}
          _selected={{ backgroundColor: 'rgba(57, 88, 191, 0.1)' }}
          _active={{
            background: 'none',
            borderBottom: 'none',
            outline: 'none',
          }}
          background='white'
          borderBottom='none'
          fontSize='1rem'
          fontWeight='600'
          height='60px'
          flex='1'
        >
          <Text>{_t('centre.lessons.content')}</Text>
        </Tab>
      </TabList>

      <Box as='section' pt='2rem' pb='2rem'>
        <FilterBox>
          <>
            <Grid
              templateColumns='repeat(3, 1fr)'
              gap={'2.438rem'}
              mb='1.875rem'
            >
              <SubjectFilter isEnabled={true} />
              <TeachingLevelFilter
                label={_t('centre.lessons.subject_level')}
                placeholder={_t('centre.lessons.placeholder_subject_level')}
                isEnabled={true}
              />
              <TeachingLanguageFilter />
            </Grid>
            <Grid templateColumns='repeat(3, 1fr)' gap={'2.438rem'}>
              <PriceRangeFilter />
              <SortByFilter />
            </Grid>
          </>
        </FilterBox>
      </Box>
      <Box as='section' pb='2rem' maxWidth='332px'>
        <SearchBar
          placeholder={_t('centre.lessons.placeholder_search')}
          minChar={3}
          onSearch={handleSearch}
        />
      </Box>
      <Box as='section' minH='400px'>
        {tab === 0 && (
          <>
            <CardList
              dataList={(classList as []) || []}
              emptyMessage={_t('centre.lessons.empty_title', {
                centre: `${centreName}`,
              })}
              emptySubMessage={_t('centre.lessons.empty_description')}
              isLoading={isClassLoading}
              resultType={ResultType.CLASS}
              wrap
            >
              {renderClassResults()}
            </CardList>
            {isClassLoading && classTotal > limit && <LoadingSpinner />}
            <Visible onVisible={handleLoadMoreClass} />
          </>
        )}
        {tab === 1 && (
          <>
            <CardList
              dataList={(contentList as []) || []}
              emptyMessage={_t('centre.lessons.empty_title', {
                centre: `${centreName}`,
              })}
              emptySubMessage={_t('centre.lessons.empty_description')}
              isLoading={isContentLoading}
              resultType={ResultType.CLASS}
              wrap
            >
              {renderContentResult()}
            </CardList>
            {isContentLoading && contentTotal > limit && <LoadingSpinner />}
            <Visible onVisible={handleLoadMoreContent} />
          </>
        )}
      </Box>
    </Tabs>
  )
}

export default CentreLesson

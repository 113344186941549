import React from 'react'

import { Box, BoxProps, Flex, Text } from '@chakra-ui/react'

interface ContentContainerProps extends BoxProps {
  title?: string
  rightElement?: React.ReactNode
}

const ContentContainer: React.FC<ContentContainerProps> = ({
  title,
  rightElement,
  children,
  ...boxProps
}) => {
  return (
    <Box
      py='2rem'
      px='2.5rem'
      bgColor='#fff'
      boxShadow='lg'
      rounded='lg'
      {...boxProps}
    >
      {(title || rightElement) && (
        <Flex
          direction='row'
          alignContent='center'
          justifyContent='space-between'
          flexWrap='wrap'
          mb='1rem'
          gridColumnGap='1rem'
          gridRowGap='0.25rem'
        >
          {title ? <Text textStyle='contentHeader'>{title}</Text> : <Box />}
          {rightElement}
        </Flex>
      )}
      {children}
    </Box>
  )
}

export default ContentContainer

import { useMutation, useQuery } from 'react-query'

import { useToastError } from './helpers'
import { forgetEmail } from 'apis/profile'
import {
  addUser,
  deleteUser,
  deleteUsers,
  resetUserPassword,
  updateUser,
  updateUserProfile,
} from 'apis/user'
import { adminPath, profilePath } from 'configs/apiPath'
import { useInvalidateUrl, useLanguages, useLoading, useToast } from 'hooks'
import {
  GroupedCombination,
  Paginate,
  QueryDetailResponse,
  QueryListResponse,
  UserExportRequest,
  UserExportResponse,
  UserProfile,
} from 'types'

export const useUsers = ({
  page,
  limit,
  search,
  searchBy,
  type,
  enterpriseId,
  centerId,
  filterBy,
  accountType,
  accountStatus,
  fromCreatedAt,
  toCreatedAt,
}: Paginate & {
  search: string
  searchBy?: string
  type: string
  filterBy?: string
  enterpriseId?: string
  centerId?: string
  accountType?: string
  accountStatus?: string
  fromCreatedAt?: number
  toCreatedAt?: number
}) => {
  return useQuery<QueryListResponse<UserProfile>>(
    adminPath.getListUsers({
      page,
      limit,
      search,
      searchBy,
      type,
      enterpriseId,
      centerId,
      filterBy,
      accountType,
      accountStatus,
      fromCreatedAt,
      toCreatedAt,
    })
  )
}

export const useUserProfiles = ({ userIds }: { userIds: string[] }) => {
  return useQuery<QueryDetailResponse<UserProfile[]>>(
    adminPath.userProfiles(userIds),
    { enabled: userIds.length > 0 }
  )
}

// for admin user management
export const useUserDetail = (userId?: string, isAdmin: boolean = true) => {
  return useQuery<QueryDetailResponse<UserProfile>>(
    adminPath.singleUser(userId),
    {
      enabled: !!userId && !!isAdmin,
    }
  )
}

export const useAddUser = () => {
  const invalidate = useInvalidateUrl(adminPath.getListUsers())
  const toast = useToast()
  const { setLoading } = useLoading()
  const { _t } = useLanguages()

  return useMutation(addUser, {
    onMutate: () => setLoading(true),
    onSettled: () => setLoading(false),
    onSuccess: async () => {
      await invalidate()
      toast({
        title: 'Success!',
        description: _t('message.success.user_add'),
        status: 'success',
      })
    },
    onError: useToastError(),
  })
}

export const useUpdateUser = (userId?: string) => {
  const toast = useToast()
  const invalidate = useInvalidateUrl(adminPath.singleUser(userId))
  const invalidateSubject = useInvalidateUrl(
    profilePath.getSubjectCombinations(userId)
  )
  const { setLoading } = useLoading()
  const { _t } = useLanguages()

  return useMutation(updateUser, {
    onMutate: () => setLoading(true),
    onSettled: () => setLoading(false),
    onSuccess: async () => {
      await Promise.all([invalidate(), invalidateSubject()])
      toast({
        title: 'Success!',
        description: _t('message.success.user_update'),
        status: 'success',
      })
    },
    onError: useToastError(),
  })
}

export const useResetUserPassword = () => {
  const toast = useToast()
  const { setLoading } = useLoading()
  const { _t } = useLanguages()

  return useMutation(resetUserPassword, {
    onMutate: () => setLoading(true),
    onSettled: () => setLoading(false),
    onSuccess: async () => {
      toast({
        title: 'Success!',
        description: _t('message.success.user_reset_password'),
        status: 'success',
      })
    },
    onError: useToastError(),
  })
}

export const useDeleteUser = () => {
  const toast = useToast()
  const invalidate = useInvalidateUrl(adminPath.getListUsers())
  const { _t } = useLanguages()

  return useMutation(deleteUser, {
    onSuccess: async () => {
      await invalidate()
      toast({
        title: 'Success',
        description: _t('message.success.user_remove'),
        status: 'success',
      })
    },
    onError: useToastError(),
  })
}

export const useDeleteUsers = () => {
  const toast = useToast()
  const invalidate = useInvalidateUrl(adminPath.getListUsers())
  const { _t } = useLanguages()

  return useMutation(deleteUsers, {
    onSuccess: async () => {
      await invalidate()
      toast({
        title: 'Success',
        description: _t('message.success.users_remove'),
        status: 'success',
      })
    },
    onError: useToastError(),
  })
}

export const useUserSubjectCombination = (userId: string) => {
  return useQuery<QueryListResponse<GroupedCombination>>(
    profilePath.getSubjectCombinations(userId),
    {
      enabled: !!userId,
    }
  )
}

// for normal user (student | tutor)
export const useUserProfile = (userId: string) => {
  return useQuery<QueryDetailResponse<UserProfile>>(
    profilePath.profile(userId),
    {
      enabled: !!userId,
    }
  )
}

export const useUpdateUserProfile = (userId: string) => {
  const invalidateProfile = useInvalidateUrl(profilePath.profile(userId))
  const toast = useToast()
  const { setLoading } = useLoading()
  const { _t } = useLanguages()

  return useMutation(updateUserProfile, {
    onMutate: () => setLoading(true),
    onSettled: () => setLoading(false),
    onSuccess: async (_, { onInvalidateUrl }) => {
      onInvalidateUrl && onInvalidateUrl()
      invalidateProfile()
      toast({
        title: 'Success!',
        description: _t('message.success.user_update_profile'),
        status: 'success',
      })
    },
    onError: useToastError(),
  })
}

export const useCenterUsers = ({
  page,
  limit,
  search,
  filterBy,
  centerId,
}: Paginate & {
  search?: string
  filterBy?: string
  centerId: string
}) => {
  return useQuery<QueryListResponse<UserProfile>>(
    adminPath.getCenterListUsers({ centerId, page, limit, search, filterBy })
  )
}

export const useForgetEmail = () => {
  const toast = useToast()
  const { setLoading } = useLoading()
  const { _t } = useLanguages()

  return useMutation(forgetEmail, {
    onMutate: () => setLoading(true),
    onSettled: () => setLoading(false),
    onSuccess: (_, variables) => {
      if (variables.isResend) {
        toast({
          title: 'Success',
          description: _t('message.success.user_forget_email'),
          status: 'success',
        })
      }
    },
    onError: useToastError(),
  })
}

export const useUserExport = (params: UserExportRequest) => {
  const options = { enabled: false }
  return useQuery<UserExportResponse>(adminPath.export(params), options)
}

import React, { useCallback, useMemo } from 'react'

import { Badge, Box, Button, Divider, Flex, Text } from '@chakra-ui/react'
import {
  compareAsc,
  format,
  formatDuration,
  intervalToDuration,
} from 'date-fns'

import { AddToCalendar, ImageWrapper } from 'components'
import { useLanguages, useToast, useUserId } from 'hooks'
import { AttachmentsType, ClassPreview, UserProfile } from 'types'
import { isContentClassType, isLessonClassType } from 'utils/helper'
import { formatAmount, parseMeetingTypes } from 'utils/parser'

import { ReactComponent as LabelDocument } from 'assets/images/label-attachment.svg'
import { ReactComponent as LabelDate } from 'assets/images/label-date.svg'
import { ReactComponent as LabelLanguage } from 'assets/images/label-language.svg'
import { ReactComponent as LabelLocation } from 'assets/images/label-location.svg'
import { ReactComponent as LabelMaterial } from 'assets/images/label-material.svg'
import { ReactComponent as LabelSubjectLevel } from 'assets/images/label-subject-level.svg'
import { ReactComponent as LabelSubject } from 'assets/images/label-subject.svg'
import { ReactComponent as LabelTime } from 'assets/images/label-time.svg'
import { ReactComponent as LabelUserGroup } from 'assets/images/label-user-group.svg'
import defaultBanner from 'assets/images/mapping.png'
import { ReactComponent as CopyLinkButton } from 'assets/images/share-copy-link.svg'
import { ReactComponent as FacebookShareButton } from 'assets/images/share-facebook.svg'
import { ReactComponent as TelegramShareButton } from 'assets/images/share-telegram.svg'
import { ReactComponent as TwitterShareButton } from 'assets/images/share-twitter.svg'
import { ReactComponent as WhatsappShareButton } from 'assets/images/share-whatsapp.svg'

const style: any = {
  labelGroup: {
    mb: '0.5rem',
    w: { base: '100%', md: '50%', lg: '100%' },
  },
  labelTitle: {
    fontSize: '0.75rem',
    ml: '0.5rem',
    color: 'gray.300',
  },
}

interface OverviewSectionProps {
  data: ClassPreview | undefined
  previewBanner?: string
  attachments?: AttachmentsType[]
  pricing: number
  usualPricing?: number
  instructor: UserProfile | undefined
  onJoinSession?: () => void
  onEditSession?: () => void
  onCancelSession?: () => void
  onBuySession?: () => void
  isVirtual?: boolean
  isPreview?: boolean
}

const OverviewSection: React.FC<OverviewSectionProps> = ({
  data,
  previewBanner,
  attachments = [],
  pricing,
  usualPricing,
  instructor,
  onJoinSession,
  onEditSession,
  onCancelSession,
  onBuySession,
  isVirtual,
  isPreview,
}) => {
  const { _t } = useLanguages()
  const personalId = useUserId()
  const canEdit = personalId === instructor?.id
  const now = new Date()
  const postUrl = encodeURI(window.location.href)
  const shareMessage = useMemo(
    () => `I’ve come across this class on Knovo, do check it out! ${postUrl}`,
    [postUrl]
  )

  const firstSession = useMemo(
    () =>
      data?.sessions && data?.sessions.length > 0
        ? data?.sessions[0]
        : { startDateTime: 0, endDateTime: 0 },
    [data]
  )
  const isMultiSessionMemo = useMemo(() => {
    if (data?.sessions) {
      return data?.sessions?.length > 1 ? true : false
    }
    return false
  }, [data])
  const isContentClassMemo = useMemo(() => isContentClassType(data?.type), [
    data,
  ])
  const isLessonClassMemo = useMemo(() => isLessonClassType(data?.type), [data])
  const availableSlot = useMemo(() => {
    const max = data?.maxStudent || 0

    return max - (data?.numOfConfirmedStudents || 0)
  }, [data])

  const isExpired = useMemo(() => {
    if (data?.sessions && data?.sessions.length > 0) {
      for (const session of data.sessions) {
        const isMore30MinsBeforeClass =
          compareAsc(
            now,
            new Date(session?.startDateTime || 0).getTime() - 1800000
          ) === -1
        const isMore30MinsAfterClass =
          compareAsc(
            now,
            new Date(session?.endDateTime || 0).getTime() + 1800000
          ) === 1
        if (!isMore30MinsBeforeClass && !isMore30MinsAfterClass) {
          return false
        }
      }
    }
    return true
  }, [data, now])

  const isEditExpired = useMemo(() => {
    const startDateTime = new Date(firstSession?.startDateTime || 0).getTime()
    if (startDateTime) {
      const diff = startDateTime - now.getTime()
      return diff <= 3600000
    }
    return false
  }, [firstSession, now])

  const meetingType = useMemo(() => {
    const sessions = data?.sessions
    if (!sessions || sessions?.length === 0) {
      return '-'
    }
    return parseMeetingTypes(sessions)
  }, [data])

  const generateDuration = (startTime?: number, endTime?: number) => {
    if (startTime && endTime) {
      const duration = intervalToDuration({
        start: new Date(startTime),
        end: new Date(endTime),
      })
      return ` (${formatDuration(duration)})`
    }
  }

  const lessonExpiredMemo = useMemo(() => {
    if (isLessonClassMemo) {
      for (const pack of data!.packages!) {
        if (pack.sessions[0].startDateTime > Date.now()) {
          return false
        }
      }
      return true
    }
  }, [data, isLessonClassMemo])

  const toast = useToast()
  const copyLink = useCallback(() => {
    navigator.clipboard.writeText(window.location.href)

    toast({
      title: _t('common.copied'),
      description: _t('common.copied_to_clipboard'),
      status: 'success',
    })
  }, [_t, toast])

  const openNewTab = useCallback(url => {
    window.open(url, '_blank')
  }, [])

  const renderPricing = () => {
    return (
      <>
        {!!usualPricing && (
          <Flex>
            <Text
              fontSize={{ base: '0.75rem', md: '1rem' }}
              textDecoration='line-through'
              color='gray.300'
              mx='0.2rem'
            >
              ${formatAmount(usualPricing)}
            </Text>
            <Badge
              px={2}
              fontSize={{ base: '0.75rem', md: '1rem' }}
              fontWeight='400'
              background='secondaryLightBlue'
            >
              -{Math.round(((usualPricing - pricing) * 100) / usualPricing)}%
              off
            </Badge>
          </Flex>
        )}
        <Flex align='center' mb='0.5rem'>
          {isLessonClassMemo && (
            <Flex align='end'>
              <Text as='h1' noOfLines={1}>
                {pricing === 0 ? 'Free' : `$${formatAmount(pricing)}`}
              </Text>
              <Text noOfLines={1} ml='0.5rem'>
                {`/ package`}
              </Text>
            </Flex>
          )}
          {!isLessonClassMemo && (
            <Text as='h1' noOfLines={1}>
              {pricing === 0 ? 'Free' : `$${formatAmount(pricing)}`}
            </Text>
          )}
        </Flex>
      </>
    )
  }

  const renderAction = () => {
    if (data?.status === 'cancelled') {
      if (isContentClassMemo) {
        if (canEdit && onEditSession) {
          return (
            <>
              {renderPricing()}
              <Box
                w='full'
                bgColor='gray.100'
                py='0.8rem'
                px='1rem'
                rounded='lg'
                color='#fff'
                textAlign='center'
                fontWeight='600'
                textTransform='uppercase'
              >
                {_t('common.cancelled')}
              </Box>
            </>
          )
        }
        return <></>
      }
      return (
        <>
          {renderPricing()}
          <Box
            w='full'
            bgColor='gray.100'
            py='0.8rem'
            px='1rem'
            rounded='lg'
            color='#fff'
            textAlign='center'
            fontWeight='600'
            textTransform='uppercase'
          >
            {_t('common.cancelled')}
          </Box>
        </>
      )
    }

    if (canEdit && onEditSession) {
      if (isLessonClassMemo) {
        return (
          <Flex justify='space-between'>
            <Button
              flex='1'
              variant='outlinePrimary'
              onClick={onCancelSession}
              disabled={isEditExpired}
              textTransform='uppercase'
            >
              {_t('product.class.remove_listing')}
            </Button>
          </Flex>
        )
      }
      return (
        <>
          {!isContentClassMemo && (
            <Flex direction='column' mb='0.3rem'>
              <Flex justify='space-between'>
                {isVirtual && (
                  <Button
                    variant='solidAction'
                    width='full'
                    disabled={isExpired}
                    onClick={onJoinSession}
                    textTransform='uppercase'
                  >
                    {_t('product.class.join')}
                  </Button>
                )}
                <Flex alignItems='center'>
                  <AddToCalendar sessions={data?.sessions} />
                  {!isVirtual && <Text>Export to your Calendar</Text>}
                </Flex>
              </Flex>
            </Flex>
          )}
          <Flex justify='space-between'>
            <Button
              flex='1'
              variant='primary'
              disabled={isEditExpired}
              onClick={onEditSession}
              textTransform='uppercase'
            >
              {_t('common.edit')}
            </Button>
            <Box mx='0.2rem' />
            <Button
              flex='1'
              variant='outlinePrimary'
              onClick={onCancelSession}
              disabled={isEditExpired}
              textTransform='uppercase'
            >
              {isContentClassMemo ? _t('common.delete') : _t('common.cancel')}
            </Button>
          </Flex>
        </>
      )
    }

    if (data?.purchased) {
      return (
        <Flex direction='column' mb='0.3rem'>
          <Flex justify='space-between'>
            {isVirtual && (
              <Button
                variant='solidAction'
                width='full'
                disabled={isExpired}
                onClick={onJoinSession}
                textTransform='uppercase'
              >
                {_t('product.class.join')}
              </Button>
            )}
            {!isContentClassMemo && (
              <Flex alignItems='center'>
                <AddToCalendar sessions={data?.sessions} />
                {!isVirtual && <Text>Export to your Calendar</Text>}
              </Flex>
            )}
          </Flex>
        </Flex>
      )
    }

    if (!isContentClassMemo && !isLessonClassMemo && availableSlot <= 0) {
      return (
        <>
          {renderPricing()}
          <Box
            w='full'
            bgColor='gray.100'
            py='0.8rem'
            px='1rem'
            rounded='lg'
            color='#fff'
            textAlign='center'
            fontWeight='600'
            textTransform='uppercase'
          >
            {_t('product.class.full')}
          </Box>
        </>
      )
    }

    if (isLessonClassMemo && lessonExpiredMemo) {
      return (
        <>
          {renderPricing()}
          <Box
            w='full'
            bgColor='gray.100'
            py='0.8rem'
            px='1rem'
            rounded='lg'
            color='#fff'
            textAlign='center'
            fontWeight='600'
            textTransform='uppercase'
          >
            {_t('product.lesson.expired')}
          </Box>
        </>
      )
    }

    return (
      <>
        {renderPricing()}
        <Button
          variant='solidAction'
          w='full'
          onClick={onBuySession}
          textTransform='uppercase'
        >
          {pricing === 0 ? _t(`common.free`) : _t(`common.buy_now`)}
        </Button>
      </>
    )
  }

  return (
    <>
      <ImageWrapper
        h={{ base: '8rem', md: '10rem' }}
        w='full'
        roundedTop='lg'
        bg='primary'
        src={previewBanner || data?.banner || defaultBanner}
        key={data?.banner || 'default-banner'}
        objectFit={'cover'}
        display={{ base: 'none', lg: 'inline' }}
      />
      <Flex p={{ base: '0', lg: '1rem' }} direction='column'>
        <Box display={{ base: 'none', lg: 'inline' }}>
          <Text as='h2' lineHeight='1.5rem' mb='1rem' noOfLines={2}>
            {data?.name}
          </Text>
        </Box>
        <Box mb='1rem'>{renderAction()}</Box>
        <Flex px='1rem' wrap='wrap'>
          <Box {...style.labelGroup}>
            <Flex align='center'>
              <LabelSubject />
              <Text {...style.labelTitle}>{_t('product.class.subject')}</Text>
            </Flex>
            <Text>{data?.subject?.label}</Text>
          </Box>
          <Box {...style.labelGroup}>
            <Flex align='center'>
              <LabelSubjectLevel />
              <Text {...style.labelTitle}>
                {_t('product.class.subject_level')}
              </Text>
            </Flex>
            <Text>{data?.teachingLevel?.label}</Text>
          </Box>
          {!isLessonClassMemo && !isContentClassMemo && (
            <Box {...style.labelGroup}>
              <Flex align='center'>
                <LabelLocation />
                <Text {...style.labelTitle}>
                  {_t('product.class.mode_of_class')}
                </Text>
              </Flex>
              <Text>{meetingType}</Text>
            </Box>
          )}
          {!isMultiSessionMemo && !isLessonClassMemo && !isContentClassMemo && (
            <>
              <Box {...style.labelGroup}>
                <Flex align='center'>
                  <LabelDate />
                  <Text {...style.labelTitle}>{_t('product.class.date')}</Text>
                </Flex>
                <Text>
                  {firstSession && firstSession?.startDateTime
                    ? format(
                        new Date(firstSession.startDateTime),
                        'dd MMM yyyy, eeee'
                      )
                    : 'Not add yet'}
                </Text>
              </Box>
              {firstSession && (
                <Box {...style.labelGroup}>
                  <Flex align='center'>
                    <LabelTime />
                    <Text {...style.labelTitle}>
                      {_t('product.class.time')}
                    </Text>
                    <Text fontSize='0.75rem' ml='0.5rem'>
                      {generateDuration(
                        firstSession?.startDateTime,
                        firstSession?.endDateTime
                      )}
                    </Text>
                  </Flex>
                  <Text>
                    {firstSession?.startDateTime
                      ? format(new Date(firstSession.startDateTime), 'h:mma')
                      : 'Not add yet'}
                    {firstSession?.endDateTime
                      ? format(new Date(firstSession.endDateTime), ' - h:mma')
                      : ''}
                  </Text>
                </Box>
              )}
            </>
          )}
          <Box {...style.labelGroup}>
            <Flex align='center'>
              <LabelLanguage />
              <Text {...style.labelTitle}>
                {_t('product.class.language_of_instruction')}
              </Text>
            </Flex>
            <Text>{data?.language?.label}</Text>
          </Box>
          <Box {...style.labelGroup}>
            <Flex align='center'>
              <LabelDocument />
              <Text {...style.labelTitle}>
                {_t('product.class.material_attachments')}
              </Text>
            </Flex>
            <Text>{attachments.length}</Text>
          </Box>
        </Flex>
        {isLessonClassMemo && (
          <Flex px='1rem' wrap='wrap'>
            <Box {...style.labelGroup}>
              <Flex align='center'>
                <LabelUserGroup />
                <Text {...style.labelTitle}>
                  {_t('product.class.min_to_start')}
                </Text>
              </Flex>
              <Text>{data?.minStudent || 0}</Text>
            </Box>
            <Box {...style.labelGroup}>
              <Flex align='center'>
                <LabelUserGroup />
                <Text {...style.labelTitle}>
                  {_t('product.class.max_slots_package')}
                </Text>
              </Flex>
              <Text>{data?.maxStudent || 0}</Text>
            </Box>
          </Flex>
        )}
        {isContentClassMemo && (
          <Flex px='1rem' wrap='wrap'>
            <Box {...style.labelGroup}>
              <Flex align='center'>
                <LabelMaterial />
                <Text {...style.labelTitle}>
                  {_t('product.class.material_links')}
                </Text>
              </Flex>
              <Text>{data?.materials?.length || 0}</Text>
            </Box>
          </Flex>
        )}
        {!isLessonClassMemo && !isContentClassMemo && (
          <Flex px='1rem' wrap='wrap'>
            <Box {...style.labelGroup}>
              <Flex align='center'>
                <LabelUserGroup />
                <Text {...style.labelTitle}>
                  {_t('product.class.available_slots')}
                </Text>
              </Flex>
              <Text>{availableSlot}</Text>
            </Box>
            <Box {...style.labelGroup}>
              <Flex align='center'>
                <LabelUserGroup />
                <Text {...style.labelTitle}>
                  {_t('product.class.max_slots')}
                </Text>
              </Flex>
              <Text>{data?.maxStudent || 0}</Text>
            </Box>
          </Flex>
        )}
        {!isPreview && (
          <>
            <Divider mt='1rem' mb='1.5rem' />
            <Box {...style.labelGroup}>
              <Text mb='0.5rem'>{_t('product.class.share')}</Text>
              <Flex justify='space-around'>
                <WhatsappShareButton
                  cursor='pointer'
                  width='40'
                  height='40'
                  onClick={() => {
                    openNewTab(
                      `https://api.whatsapp.com/send?text=${shareMessage}`
                    )
                  }}
                />
                <TelegramShareButton
                  cursor='pointer'
                  width='40'
                  height='40'
                  onClick={() => {
                    openNewTab(`https://t.me/share/url?url=${shareMessage}`)
                  }}
                />
                <TwitterShareButton
                  cursor='pointer'
                  width='40'
                  height='40'
                  onClick={() => {
                    openNewTab(
                      `https://twitter.com/intent/tweet?text=${shareMessage}`
                    )
                  }}
                />
                <FacebookShareButton
                  cursor='pointer'
                  width='40'
                  height='40'
                  onClick={() => {
                    openNewTab(
                      `https://www.facebook.com/sharer/sharer.php?u=${postUrl}`
                    )
                  }}
                />
                <Flex
                  align='center'
                  justify='center'
                  w='2.3rem'
                  h='2.3rem'
                  rounded='full'
                  cursor='pointer'
                  bg='primaryDark'
                  onClick={() => {
                    copyLink()
                  }}
                >
                  <CopyLinkButton width='25' height='25' />
                </Flex>
              </Flex>
            </Box>
          </>
        )}
      </Flex>
    </>
  )
}

export default OverviewSection

import { useMutation, useQuery } from 'react-query'

import {
  acceptFriend,
  adminAddConnection,
  adminDeleteConnection,
  declineFriend,
  unBlockUser,
  unFriend,
} from './../apis/connection'
import { blockPath } from './../configs/apiPath'
import { QueryDetailResponse } from './../types/index'
import { useToastError } from './helpers'
import { addFriend, blockUser } from 'apis/connection'
import { connectionPath, profilePath } from 'configs/apiPath'
import { useLanguages, useLoading, useToast } from 'hooks'
import { QueryListResponse } from 'types'
import {
  Blocker,
  Connection,
  ConnectionFilter,
  ConnectionStatus,
  FollowingFilter,
  FollowingModel,
  FriendRequest,
} from 'types/connection'

export const useConnectionListFriend = (
  userId: string,
  params: ConnectionFilter
) => {
  return useQuery<QueryListResponse<Connection>>(
    connectionPath.list(userId, {
      ...params,
    }),
    {
      enabled: !!userId,
    }
  )
}

export const useUserListFollowing = (userId: string, params: FollowingFilter) =>
  useQuery<QueryListResponse<FollowingModel>>(
    profilePath.listFollowing(userId, {
      ...params,
    }),
    {
      enabled: !!userId,
    }
  )

export const useAddConnection = (userId: string, friendId: string) => {
  const toast = useToast()
  const { _t } = useLanguages()

  return useMutation(() => addFriend({ userId, friendId }), {
    onSuccess: () => {
      toast({
        title: 'Success!',
        description: _t('message.success.connection_add'),
        status: 'success',
      })
    },
    onError: useToastError(),
  })
}

export const useAcceptConnection = () => {
  const toast = useToast()
  const { _t } = useLanguages()

  return useMutation(
    ({ userId, requestId }: any) => acceptFriend({ userId, requestId }),
    {
      onSuccess: () => {
        toast({
          title: 'Success!',
          description: _t('message.success.connection_accept'),
          status: 'success',
        })
      },
      onError: useToastError(),
    }
  )
}

export const useBlockUser = (userId: string, blockerId: string) => {
  const toast = useToast()
  const { _t } = useLanguages()

  return useMutation(() => blockUser({ userId, blockerId }), {
    onSuccess: () => {
      toast({
        title: 'Success!',
        description: _t('message.success.connection_block_user'),
        status: 'success',
      })
    },
    onError: useToastError(),
  })
}

export const useUnBlockUser = (blockId: string) => {
  const toast = useToast()
  const { _t } = useLanguages()

  return useMutation(() => unBlockUser({ blockId }), {
    onSuccess: () => {
      toast({
        title: 'Success!',
        description: _t('message.success.connection_unblock_user'),
        status: 'success',
      })
    },
    onError: useToastError(),
  })
}

export const useListBlocker = (userId: string) =>
  useQuery<QueryListResponse<Blocker>>(blockPath.list(userId))

export const useDeclineConnection = () => {
  const toast = useToast()
  const { _t } = useLanguages()

  return useMutation(
    ({ userId, requestId }: any) => declineFriend({ userId, requestId }),
    {
      onSuccess: () => {
        toast({
          title: 'Success!',
          description: _t('message.success.connection_decline'),
          status: 'success',
        })
      },
      onError: useToastError(),
    }
  )
}

export const useUnFriend = () => {
  const toast = useToast()
  const { _t } = useLanguages()

  return useMutation(
    ({ userId, requestId }: any) => unFriend({ userId, requestId }),
    {
      onSuccess: () => {
        toast({
          title: 'Success!',
          description: _t('message.success.connection_remove'),
          status: 'success',
        })
      },
      onError: useToastError(),
    }
  )
}

export const useListFriendRequest = (userId: string) => {
  return useQuery<QueryListResponse<FriendRequest>>(
    profilePath.friendRequests(userId)
  )
}

export const useFriendStatus = (userId: string, targetId: string) => {
  return useQuery<QueryDetailResponse<ConnectionStatus>>(
    profilePath.friendStatus(userId, targetId),
    { enabled: !!userId }
  )
}

export const useAdminDeleteConnection = (onInvalidateListUrl: () => void) => {
  const toast = useToast()
  const { setLoading } = useLoading()
  const { _t } = useLanguages()

  return useMutation(adminDeleteConnection, {
    onMutate: () => setLoading(true),
    onSettled: () => setLoading(false),
    onSuccess: () => {
      onInvalidateListUrl()
      toast({
        title: 'Success!',
        description: _t('message.success.connection_admin_remove'),
        status: 'success',
      })
    },
    onError: useToastError(),
  })
}

export const useAdminAddConnection = (onInvalidateListUrl: () => void) => {
  const toast = useToast()
  const { setLoading } = useLoading()
  const { _t } = useLanguages()

  return useMutation(adminAddConnection, {
    onMutate: () => setLoading(true),
    onSettled: () => setLoading(false),
    onSuccess: () => {
      onInvalidateListUrl()
      toast({
        title: 'Success!',
        description: _t('message.success.connection_admin_add'),
        status: 'success',
      })
    },
    onError: useToastError(),
  })
}

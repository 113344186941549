import { useCallback, useEffect, useState } from 'react'

import { AxiosResponse } from 'axios'

import { UploadFile } from './useUploadClassFile'
import { createUploadAttachmentSession } from 'apis/classSession'
import { uploadFile } from 'apis/upload'
import { Attachment, SessionUploadUrl } from 'types'

interface DefaultUploadFilesType {
  index: number
  files: Attachment[]
}

export interface UploadedFilesType {
  index: number
  uploadFiles: UploadFile[]
}

const useUploadSessionFile = (
  defaultUploadedFiles: DefaultUploadFilesType[] = []
) => {
  const [uploadFiles, handleChangeFiles] = useState<UploadedFilesType[]>(() =>
    defaultUploadedFiles.map<UploadedFilesType>(uploadedFile => ({
      index: uploadedFile.index,
      uploadFiles: uploadedFile.files.map<UploadFile>(file => ({
        file: file,
        status: file.status || 'public',
        uploadStatus: file?.createdAt ? 'uploaded' : 'pending',
      })),
    }))
  )

  const [isUploading, setUploading] = useState(false)
  const [filesError, setFilesError] = useState<string[]>([])
  const [isSuccess, setIsSuccess] = useState(false)
  const [isError, setIsError] = useState(false)
  const [isSettled, setIsSettled] = useState(false)

  useEffect(() => {
    if (isSettled) {
      handleChangeFiles(oldFiles => {
        return oldFiles.map<UploadedFilesType>(oldFile => ({
          ...oldFile,
          uploadFiles: oldFile.uploadFiles.map(uploadedFile => ({
            ...uploadedFile,
            uploadStatus: filesError.includes(uploadedFile.file.name)
              ? 'failure'
              : 'uploaded',
          })),
        }))
      })
    }
  }, [isSettled, filesError])

  // automatically set not settled
  useEffect(() => {
    if (isSettled) {
      setTimeout(() => setIsSettled(false), 0)
    }
  }, [isSettled])

  const handleUpload = useCallback(
    async (classId, sessionId, itemIndex) => {
      setUploading(true)
      setIsSettled(false)

      const promises: Promise<AxiosResponse<SessionUploadUrl>>[] = []

      const newUploadFiles = uploadFiles.map(upload => ({
        ...upload,
        uploadFiles: upload.uploadFiles.filter(
          file => file.uploadStatus !== 'uploaded'
        ),
      }))

      const uploadingItem = newUploadFiles.find(
        item => item.index === itemIndex
      )

      if (uploadingItem) {
        uploadingItem.uploadFiles.forEach(file => {
          promises.push(
            createUploadAttachmentSession({
              classId,
              sessionId,
              status: file.status,
              file: file.file,
            })
          )
        })

        try {
          const uploadUrlRes = await Promise.allSettled(promises)

          const uploadStatus = await Promise.allSettled(
            uploadUrlRes.map((uploadUrl, index) => {
              if (uploadUrl.status === 'fulfilled') {
                return uploadFile(
                  uploadingItem.uploadFiles[index].file,
                  uploadUrl.value.data
                )
              }

              return Promise.reject({
                fileName: uploadingItem.uploadFiles[index].file.name,
              })
            })
          )

          let errorNames: string[] = []

          uploadStatus.forEach(upload => {
            if (upload.status === 'rejected') {
              errorNames.push(upload.reason?.fileName)
            }
          })

          setFilesError(errorNames)
          setIsSuccess(true)
          setIsError(false)
        } catch {
          setIsSuccess(false)
          setIsError(true)
        } finally {
          setUploading(false)
          setIsSettled(true)
        }
      }
    },
    [uploadFiles]
  )

  return {
    uploadFiles,
    isUploading,
    isError,
    isSuccess,
    isSettled,
    filesError,
    handleUpload,
    handleChangeFiles,
  }
}

export default useUploadSessionFile

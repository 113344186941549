import React from 'react'

import { Flex, Text } from '@chakra-ui/react'

import { Cell } from '../../components'
import { Modal } from 'components'
import { useLanguages } from 'hooks'

interface InstructionModalProps {
  isOpen: boolean
  onClose: () => void
}

const InstructionModal: React.FC<InstructionModalProps> = ({
  isOpen,
  onClose,
}) => {
  const { _t } = useLanguages()

  return (
    <Modal title='How To Play' isOpen={isOpen} onClose={onClose}>
      <Text as='p'>{_t('games.knovole.instruction_overview')}</Text>
      <Flex justifyContent='center' mb='0.5rem' mt='2rem'>
        <Cell value='0' status='correct' />
        <Cell value='6' />
        <Cell value='3' />
        <Cell value='9' />
        <Cell value='1' />
      </Flex>
      <Text as='p'>{_t('games.knovole.instruction_sample_a')}</Text>

      <Flex justifyContent='center' mb='0.5rem' mt='2rem'>
        <Cell value='9' />
        <Cell value='3' />
        <Cell value='4' status='present' />
        <Cell value='1' />
        <Cell value='0' />
      </Flex>
      <Text as='p'>{_t('games.knovole.instruction_sample_b')}</Text>

      <Flex justifyContent='center' mb='0.5rem' mt='2rem'>
        <Cell value='2' />
        <Cell value='6' />
        <Cell value='9' />
        <Cell value='3' status='absent' />
        <Cell value='8' />
      </Flex>
      <Text as='p' mb='1rem'>
        {_t('games.knovole.instruction_sample_c')}
      </Text>
    </Modal>
  )
}

export default InstructionModal

import React from 'react'

import { Box, Flex, Text } from '@chakra-ui/react'

import { ReactComponent as RateStar } from 'assets/images/rate_star.svg'
import { ReactComponent as RateStarEmpty } from 'assets/images/rate_star_empty.svg'

interface RatingProps {
  rate: number
  scale?: number
  size?: string
  showRate?: boolean
}

const Rating: React.FC<RatingProps> = ({
  rate = 0,
  scale = 5,
  size = '0.825rem',
  showRate = true,
}) => {
  const renderRate = () => {
    const stars = []
    for (let i = 0; i < scale; i++) {
      if (rate >= i + 1) {
        stars.push(
          <Box key={`full-star-${i}`} mx='0.1rem'>
            <RateStar height={size} width={size} />
          </Box>
        )
      } else {
        stars.push(
          <Box key={`empty-star-${i}`} mx='0.1rem'>
            <RateStarEmpty height={size} width={size} />
          </Box>
        )
      }
    }
    return stars
  }

  return (
    <Flex direction='row' align='center'>
      {renderRate()}
      {showRate && (
        <Text ml='5px' fontSize={size}>
          ({rate})
        </Text>
      )}
    </Flex>
  )
}

export default Rating

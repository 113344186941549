import React, { useCallback } from 'react'
import { Controller, useForm } from 'react-hook-form'

import { Box, Button, Flex, SimpleGrid, Text } from '@chakra-ui/react'

import { Input, Select, Textarea, Tooltip } from 'components'
import { useLanguages } from 'hooks'
import { BankDetailForm, UserWalletInfo } from 'types'
import { bankAccountCountryOptions } from 'utils/constant'
import { getSelectedOptions } from 'utils/parser'

import { ReactComponent as NoteIcon } from 'assets/images/note_icon.svg'

interface EditBankAccountInfoProps {
  initData?: UserWalletInfo
  onClose: () => void
  onSubmitUpdate: (value: BankDetailForm) => void
}

const EditBankAccountInfo: React.FC<EditBankAccountInfoProps> = ({
  initData,
  onClose,
  onSubmitUpdate,
}) => {
  const { _t } = useLanguages()
  const { control, handleSubmit, errors } = useForm<BankDetailForm>({
    defaultValues: {
      bankAccountName: initData?.bankAccountName || '',
      bankName: initData?.bankName || '',
      bankAccountNumber: initData?.bankAccountNumber || '',
      bankRemarks: initData?.bankRemarks || '',
      country: getSelectedOptions(bankAccountCountryOptions, initData?.country),
    },
  })

  const renderLabel = useCallback((label: string, tooltip?: string) => {
    return (
      <Flex align='center'>
        <Text
          fontSize='0.75rem'
          color='gray.300'
          textTransform='uppercase'
          mr='0.5rem'
        >
          {label}
        </Text>
        {tooltip && (
          <Tooltip label={tooltip}>
            <NoteIcon width='12px' height='12px' />
          </Tooltip>
        )}
      </Flex>
    )
  }, [])

  return (
    <Box>
      <Flex alignItems='center' mb='1rem' h='3rem'>
        <Text fontWeight='600' mr='0.5rem'>
          {_t('user.bank_account.title')}
        </Text>
        <Tooltip label={_t('user.bank_account.tooltip_title')}>
          <NoteIcon />
        </Tooltip>
      </Flex>
      <SimpleGrid mb='1rem' columns={[1, 1, 2, 2]} spacing='1rem'>
        <Box>
          {renderLabel(
            _t('user.bank_account.name'),
            _t('user.bank_account.tooltip_name')
          )}
          <Controller
            as={Input}
            control={control}
            name='bankAccountName'
            placeholder={_t('user.bank_account.placeholder_name')}
            error={errors?.bankAccountName?.message || ''}
          />
        </Box>
        <Box>
          {renderLabel(
            _t('user.bank_account.bank_name'),
            _t('user.bank_account.tooltip_bank_name')
          )}
          <Controller
            as={Input}
            control={control}
            name='bankName'
            placeholder={_t('user.bank_account.placeholder_bank_name')}
            error={errors?.bankName?.message || ''}
          />
        </Box>
        <Box>
          {renderLabel(
            _t('user.bank_account.number'),
            _t('user.bank_account.tooltip_number')
          )}
          <Controller
            as={Input}
            control={control}
            name='bankAccountNumber'
            placeholder={_t('user.bank_account.placeholder_number')}
            error={errors?.bankAccountNumber?.message || ''}
          />
        </Box>
        <Box>
          {renderLabel(
            _t('user.bank_account.country'),
            _t('user.bank_account.tooltip_country')
          )}
          <Controller
            as={Select}
            control={control}
            name='country'
            placeholder={_t('user.bank_account.placeholder_country')}
            defaultOptions={bankAccountCountryOptions}
            selectProps={{
              fontSize: '1rem',
            }}
            error={errors?.country?.message || ''}
            rules={{
              required: _t('user.bank_account.validate_country_required'),
            }}
          />
        </Box>
      </SimpleGrid>
      <Box>
        {renderLabel(
          _t('user.bank_account.remarks'),
          _t('user.bank_account.tooltip_remarks')
        )}
        <Controller
          as={Textarea}
          control={control}
          name='bankRemarks'
          placeholder={_t('user.bank_account.placeholder_remarks')}
          error={errors?.bankRemarks?.message || ''}
        />
      </Box>
      <Flex
        direction={{ base: 'column', md: 'row' }}
        justify='flex-end'
        mt='1rem'
      >
        <Button
          variant='solidPrimary'
          textTransform='uppercase'
          onClick={handleSubmit(onSubmitUpdate)}
        >
          {_t('common.save')}
        </Button>
        <Button variant='transparent' onClick={onClose}>
          {_t('common.cancel')}
        </Button>
      </Flex>
    </Box>
  )
}

export default EditBankAccountInfo

import React from 'react'
import { useNavigate } from 'react-router-dom'

import { SimpleGrid } from '@chakra-ui/react'

import { GameCard } from '../containers'
import { GuestLayout, HeadingSection, Layout } from 'components'
import storageKey from 'configs/storageKey'
import { useLanguages } from 'hooks'
import { getData } from 'utils/helper'

import KnovoWheel from 'assets/images/knovo_wheel.png'
import Knovole from 'assets/images/knovole.svg'

const GameLibrary = () => {
  const navigate = useNavigate()
  const { _t } = useLanguages()
  const isGuest = getData(storageKey.loginType) === 'guest'
  const gamesData = [
    {
      name: _t('games.knovole.title'),
      description: _t('games.knovole.description'),
      route: 'knovole',
      thumbnail: Knovole,
    },
    {
      name: _t('games.wheel.title'),
      description: _t('games.wheel.description'),
      route: 'wheel',
      thumbnail: KnovoWheel,
    },
  ]

  const handleClick = (route: string) => {
    navigate(`/games/${route}`, { state: { gameName: route } })
  }

  const renderLibrary = () => {
    return (
      <SimpleGrid
        columns={[1, 1, 2, 2]}
        columnGap={['0rem', '1rem', '2rem']}
        px={['0rem', '0rem', '0rem', '5rem']}
        pb='2rem'
      >
        {gamesData.map(product => (
          <GameCard
            title={product.name}
            description={product.description}
            thumbnail={product.thumbnail}
            route={product.route}
            onClick={() => handleClick(product.route)}
          />
        ))}
      </SimpleGrid>
    )
  }

  const headingSection = (
    <HeadingSection
      title={_t('games.title')}
      description={_t('games.description')}
      widthType='narrow'
    />
  )

  return (
    <>
      {isGuest ? (
        <GuestLayout as='main' topElement={headingSection} showFooter>
          {renderLibrary()}
        </GuestLayout>
      ) : (
        <Layout as='main' topElement={headingSection} showFooter>
          {renderLibrary()}
        </Layout>
      )}
    </>
  )
}

export default GameLibrary

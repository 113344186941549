import { useEffect } from 'react'
import { useQuery } from 'react-query'

import { useAtom } from 'jotai'

import useUserId from './useUserId'
import { profilePath } from 'configs/apiPath'
import { derivedUserProfileAtom } from 'store/authAtom'
import { UserProfile } from 'types'

interface ProfileQuery {
  data: UserProfile
}

export default () => {
  const [, setUserProfile] = useAtom(derivedUserProfileAtom)
  const userId = useUserId()

  const { data, error, ...props } = useQuery<ProfileQuery>(
    profilePath.profile(userId),
    {
      enabled: !!userId,
      retry: 1,
    }
  )

  useEffect(() => {
    if (error) {
      console.log('--- set profile error ---')
      console.error(error)
      return
    }

    if (data) {
      const profile = data.data
      if (profile) {
        setUserProfile(profile)
      }
    }
  }, [data, error, setUserProfile])

  return { data, error, ...props }
}

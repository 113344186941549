import React, { useMemo } from 'react'
import { Column, Row } from 'react-table'

import { Text } from '@chakra-ui/react'
import format from 'date-fns/format'

import {
  Link,
  PaymentTypeText,
  Table,
  TransactionStatusBadge,
  TransactionTypeText,
} from 'components'
import { useLanguages } from 'hooks'
import { TransactionInfo, UserRole, UserRoleType } from 'types'
import { formatAmount } from 'utils/parser'

interface TransactionDataTableProps {
  role: UserRoleType
  isLoading: boolean
  colorScheme?: string
  data: TransactionInfo[]
  handleView?: (id: string) => string
}

const TransactionDataTable = ({
  role,
  isLoading,
  colorScheme = 'primaryDarker',
  data,
  handleView,
}: TransactionDataTableProps) => {
  const { _t } = useLanguages()
  const isAdmin = useMemo(
    () =>
      [
        UserRole.MSTSuperAdmin,
        UserRole.MSTAdmin,
        UserRole.EnterpriseSuperAdmin,
        UserRole.EnterpriseAdmin,
        UserRole.CenterAdmin,
      ].includes(role),
    [role]
  )
  const columns = useMemo<Column<TransactionInfo>[]>(
    () => [
      ...(isAdmin
        ? [
            {
              Header: _t('wallet.transaction.transaction_id'),
              id: 'transactionId',
              Cell: ({ row }: { row: Row<TransactionInfo> }) => {
                const link = handleView ? handleView(row.original.id) : '#'
                return (
                  <Link to={link} color='primary'>
                    <Text fontWeight={600} noOfLines={1}>
                      {row.original.id}
                    </Text>
                  </Link>
                )
              },
              width: '260',
            },
          ]
        : []),
      {
        Header: _t('wallet.transaction.date_and_time'),
        id: 'dateTime',
        Cell: ({ row }: { row: Row<TransactionInfo> }) => (
          <Text noOfLines={1}>
            {format(new Date(row.original.createdAt), 'dd MMM yyyy, h:mm a')}
          </Text>
        ),
        width: '200',
      },
      {
        Header: _t('wallet.transaction.description'),
        id: 'description',
        Cell: ({ row }: { row: Row<TransactionInfo> }) => (
          <Text noOfLines={1}>{row.original.description}</Text>
        ),
        width: '260',
      },
      ...(isAdmin
        ? [
            {
              Header: _t('wallet.transaction.transaction_type'),
              id: 'type',
              Cell: ({ row }: { row: Row<TransactionInfo> }) => (
                <TransactionTypeText type={row.original.type} />
              ),
              width: '200',
            },
          ]
        : []),
      {
        Header: _t('wallet.transaction.payment_type'),
        id: 'paymentType',
        Cell: ({ row }: { row: Row<TransactionInfo> }) => (
          <PaymentTypeText type={row.original.paymentType} />
        ),
        width: '145',
      },
      {
        Header: _t('wallet.transaction.amount'),
        id: 'amount',
        Cell: ({ row }: { row: Row<TransactionInfo> }) => {
          const { amount, method } = row.original
          const amountSign = method === 'credit' ? '+' : '-'
          const textColor = method === 'credit' ? 'actionGreen' : 'error'
          return (
            <Text noOfLines={1} color={textColor} fontWeight={600}>
              {`${amountSign}$${formatAmount((amount || 0) / 100)}`}
            </Text>
          )
        },
        width: '145',
      },
      {
        Header: _t('wallet.transaction.status'),
        id: 'status',
        Cell: ({ row }: { row: Row<TransactionInfo> }) => (
          <TransactionStatusBadge status={row.original.status} />
        ),
        width: '200',
      },
    ],
    [_t, handleView, isAdmin]
  )

  return (
    <Table<TransactionInfo>
      columns={columns}
      data={data}
      loading={isLoading}
      colorScheme={colorScheme}
    />
  )
}

export default TransactionDataTable

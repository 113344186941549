import React, { useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { Flex } from '@chakra-ui/react'
import qs from 'query-string'

import Arrow from '../Arrow'
import Link from '../Link'

interface PaginateProps {
  currentPage?: number
  perPage: number
  totalItems: number
  pagePerSlide?: number
}

const Paginate: React.FC<PaginateProps> = ({
  totalItems,
  perPage,
  currentPage = 1,
  pagePerSlide = 4,
}) => {
  const location = useLocation()

  const [currentSlide, setCurrentSlide] = useState(1)

  // number of page count
  const numberOfPages = useMemo(() => {
    return Math.ceil(totalItems / perPage)
  }, [totalItems, perPage])

  // number of page index per viewport
  const numberOfSlide = useMemo(() => {
    return Math.ceil(numberOfPages / pagePerSlide)
  }, [numberOfPages, pagePerSlide])

  const pathname = useMemo(() => {
    return location.pathname
  }, [location])

  useEffect(() => {
    if (currentPage % pagePerSlide === 0) {
      return setCurrentSlide(currentPage / pagePerSlide)
    }

    const offset = Math.floor(currentPage / pagePerSlide)
    return setCurrentSlide(offset + 1)
    // only run first time
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const goToPage = (page: number) => {
    const currentQuery = qs.parse(location.search)
    const newQuery = {
      ...currentQuery,
      page,
    }

    return pathname + '?' + qs.stringify(newQuery)
  }

  const handleClickPrev = () => {
    if (currentSlide === 1) {
      return
    }

    return setCurrentSlide(cur => cur - 1)
  }

  const handleClickNext = () => {
    if (currentSlide === numberOfSlide) {
      return
    }

    return setCurrentSlide(cur => cur + 1)
  }

  const renderPages = () => {
    let startOffset = 0
    if (currentSlide > 1) {
      startOffset = (currentSlide - 1) * pagePerSlide
      if (startOffset > 0 && startOffset + pagePerSlide > totalItems) {
        const diff = startOffset + pagePerSlide - totalItems
        startOffset = startOffset - diff
      }
    }

    const pageInViewPort = Math.min(numberOfPages, pagePerSlide)

    return new Array(pageInViewPort).fill(1).map((_, index) => {
      const pageNumber = startOffset + index + 1
      const isCurrent = currentPage === pageNumber
      if (pageNumber <= numberOfPages) {
        return (
          <Link
            key={index}
            to={goToPage(pageNumber)}
            ml='1.25rem'
            fontWeight={isCurrent ? '700' : '400'}
          >
            {pageNumber}
          </Link>
        )
      }

      return null
    })
  }

  return (
    <Flex>
      <Arrow
        transform='rotate(135deg)'
        onClick={handleClickPrev}
        disabled={currentSlide === 1}
      />
      {renderPages()}
      <Arrow
        ml='1.25rem'
        transform='rotate(-45deg)'
        onClick={handleClickNext}
        disabled={currentSlide === numberOfSlide}
      />
    </Flex>
  )
}

export default Paginate

import React, { useEffect, useMemo, useState } from 'react'

import { Accordion, Box, Flex, Text } from '@chakra-ui/react'

import { ActionButton, Preview, SessionItem } from '../containers'
import { CreateClassForm } from '../containers/form'
import { CreateClassDataType } from './Create'
import { useLanguages, useToast, useUploadSessionFile, useUserId } from 'hooks'
import {
  Attachment,
  ClassDetailItem,
  Learner,
  MeetingType,
  UserRole,
} from 'types'

interface SessionCreateProps {
  role: string
  data?: CreateClassForm
  bannerFile: File | undefined
  attachments: Attachment[]
  invitedLearners: Learner[]
  confirmedLearners: Learner[]
  createdData: any
  goBack: () => void
  handleSubmit: any
  handleCreateClass: (
    creatingStatus: 'public' | 'draft'
  ) => (createData: CreateClassDataType) => void
  handleCancel: () => void
}

const SessionCreate: React.FC<SessionCreateProps> = ({
  role,
  data,
  bannerFile,
  attachments = [],
  invitedLearners = [],
  confirmedLearners = [],
  createdData,
  goBack,
  handleSubmit,
  handleCreateClass,
  handleCancel,
}) => {
  const { _t } = useLanguages()
  const userId = useUserId()
  const toast = useToast()
  const [classArr, setClassArr] = useState<ClassDetailItem[]>([])
  const [allowUpload, setAllowUpload] = useState(true)
  const [previewClass, setPreviewClass] = useState<boolean>(false)

  const roleIsTutor = useMemo(
    () => role === UserRole.Tutor || role === UserRole.EnterpriseTutor,
    [role]
  )

  const {
    uploadFiles,
    isUploading,
    isSettled,
    handleChangeFiles,
    handleUpload,
  } = useUploadSessionFile()

  const handleAddNewClassSession = () => {
    setClassArr(oldData => {
      return [
        ...oldData,
        {
          index: oldData.length + 1,
          name: '',
          description: '',
          startDateTime: 0,
          endDateTime: 0,
          sessionAttachments: [],
          meetingLink: '',
          meetingPassword: '',
          meetingType: MeetingType.VIRTUAL_DEFAULT,
          meetingLocation: undefined,
        },
      ]
    })
    handleChangeFiles(oldData => {
      return [
        ...oldData,
        {
          index: oldData.length + 1,
          uploadFiles: [],
        },
      ]
    })
  }

  const handleDeleteClass = (index: number) => {
    const deletedItemArr = classArr.filter(item => item.index !== index)
    // reset index in each item in the new array
    const formattedArr = deletedItemArr.map((item, i) => ({
      ...item,
      index: i + 1,
    }))
    setClassArr(formattedArr)

    const deletedSessionAttachments = uploadFiles.filter(
      item => item.index !== index
    )
    const formattedAttachments = deletedSessionAttachments.map((item, i) => ({
      ...item,
      index: i + 1,
    }))
    handleChangeFiles(formattedAttachments)
  }

  const handleChangeClass = (index: number, classData: ClassDetailItem) => {
    const newClassArr = [...classArr]
    const changeIndex = newClassArr.findIndex(item => item.index === index)
    newClassArr[changeIndex] = classData

    setClassArr(newClassArr)
  }

  const handleCreate = (creatingStatus: 'public' | 'draft') => () => {
    if (creatingStatus === 'public') {
      if (classArr.length === 0) {
        toast({
          title: 'Error!',
          description: _t('product.class.validate_session_required'),
          status: 'error',
        })
        return
      }

      const notValid = classArr.some(cls => {
        return (
          !cls.name ||
          !cls.description ||
          !cls.startDateTime ||
          !cls.endDateTime
        )
      })

      if (notValid) {
        toast({
          title: 'Error!',
          description: _t('product.class.validate_session_invalid'),
          status: 'error',
        })
        return
      }
    }

    const createData = {
      data: data,
      bannerFile: bannerFile,
      invitedLearners: invitedLearners,
      confirmedLearners: confirmedLearners,
      classSessions: classArr,
    } as CreateClassDataType

    handleCreateClass(creatingStatus)(createData)
  }

  const handleOpenPreview = () => (value: CreateClassForm) => {
    setPreviewClass(true)
  }

  useEffect(() => {
    if (createdData?.data && createdData.data.data.id && allowUpload) {
      const createdClassId = createdData.data.data.id

      createdData.data.data.sessions.forEach(
        (session: { id: string; index: number }) => {
          handleUpload(createdClassId, session.id, session.index)
        }
      )
    }
  }, [handleUpload, allowUpload, createdData])

  useEffect(() => {
    if (isSettled) {
      setAllowUpload(false)
    }
  }, [isSettled])

  // add preview component here
  if (previewClass) {
    return (
      <Preview
        userId={roleIsTutor ? userId : (data?.tutor.value as string)}
        data={data ? { ...data, sessions: classArr } : undefined}
        previewBanner={bannerFile ? URL.createObjectURL(bannerFile) : ''}
        attachments={attachments}
        sessionData={classArr}
        onClose={() => setPreviewClass(false)}
        handleCreateClass={handleCreate}
      />
    )
  }

  return (
    <Box as='section' pt='1.5rem' pb='3rem'>
      <Box as='h1' mb='2rem' className={'blue-purple-gradient-text'}>
        {_t('product.class.create_session_title')}
      </Box>
      <Flex direction={{ base: 'column', md: 'row' }} justify='space-between'>
        <Box w='100%'>
          <Accordion allowToggle w='100%'>
            {classArr.map((item, index) => {
              return (
                <Flex key={index}>
                  <Box
                    w='3rem'
                    mr='0.75rem'
                    display={{ base: 'none', md: 'inline' }}
                  >
                    <Flex
                      alignItems='center'
                      justifyContent='center'
                      w='3rem'
                      h='4rem'
                      fontSize='1.5rem'
                    >
                      {index + 1}
                    </Flex>
                  </Box>
                  <Box w='100%' flex='1' mb='1.25rem'>
                    <SessionItem
                      classId={''}
                      classItem={item}
                      handleChangeClass={handleChangeClass}
                      handleDeleteClass={handleDeleteClass}
                      uploadFiles={
                        uploadFiles.find(file => file.index === item.index)
                          ?.uploadFiles || []
                      }
                      handleChangeFiles={handleChangeFiles}
                      isUploading={isUploading}
                    />
                  </Box>
                </Flex>
              )
            })}
          </Accordion>
          <Flex alignItems='flex-end'>
            <Box w='3rem' mr='0.75rem'>
              <Flex
                bg='primary'
                alignItems='center'
                justifyContent='center'
                color='#fff'
                w='3rem'
                height='3rem'
                borderRadius='50%'
                fontSize='1.125rem'
                cursor='pointer'
                onClick={handleAddNewClassSession}
              >
                <span style={{ fontSize: '2rem' }}>&#43;</span>
              </Flex>
            </Box>
            <Text
              textStyle='assignUserName'
              color='primary'
              mb='0.75rem'
              cursor='pointer'
              textTransform='uppercase'
              onClick={handleAddNewClassSession}
            >
              {_t('product.class.add')}
            </Text>
          </Flex>
        </Box>
      </Flex>

      <ActionButton
        handleSubmit={handleSubmit}
        handleCreate={handleCreate}
        handleOpenPreview={handleOpenPreview}
        handleBack={goBack}
        handleClose={handleCancel}
      />
    </Box>
  )
}

export default SessionCreate

import React, { useCallback, useEffect, useState } from 'react'

import { Box, Button, Flex } from '@chakra-ui/react'

import Modal from '../Modal'
import { useLanguages } from 'hooks'

interface ConfirmAlertProps {
  title?: string
  description?: React.ReactElement
  children?: ({ showAlert }: { showAlert: () => void }) => React.ReactNode
  submitTitle?: string
  onSubmit?: () => void
  isLoading?: boolean
  isSettled?: boolean
  immediatelyCloseOnSubmit?: boolean
  onClose?: () => void
}

const ConfirmAlert: React.FC<ConfirmAlertProps> = ({
  title,
  description,
  children,
  submitTitle,
  onSubmit,
  isLoading,
  isSettled,
  immediatelyCloseOnSubmit = false,
  onClose,
}) => {
  const { _t } = useLanguages()
  const [open, setOpen] = useState(false)

  const handleToggle = useCallback(() => {
    setOpen(state => {
      if (state) {
        onClose?.()
      }
      return !state
    })
  }, [onClose])

  const handleSubmit = () => {
    handleToggle()
    onSubmit?.()
    if (immediatelyCloseOnSubmit) {
      setOpen(false)
    }
  }

  useEffect(() => {
    if (isSettled) {
      setOpen(false)
    }
  }, [isSettled])

  return (
    <>
      {children?.({
        showAlert: handleToggle,
      })}
      <Modal isOpen={open} onClose={handleToggle} size='xl'>
        <Box as='h1' textAlign='center' color='primary'>
          {title || _t('common.delete_description')}
        </Box>
        {description && <Box my='1rem'>{description}</Box>}
        <Flex mt='3rem'>
          <Button
            flex={1}
            variant='solidPrimary'
            mr='1rem'
            textTransform='uppercase'
            onClick={handleSubmit}
            disabled={isLoading}
          >
            {submitTitle || _t('common.delete')}
          </Button>
          <Button flex={1} variant='transparent' onClick={handleToggle}>
            {_t('common.cancel')}
          </Button>
        </Flex>
      </Modal>
    </>
  )
}

export default ConfirmAlert

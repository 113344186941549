import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Avatar, Box, Flex, Grid, Text } from '@chakra-ui/react'
import { useAtom } from 'jotai'

import { ActionButton, ConnectionButton } from './components'
import { Arrow, ReadAllNotifications } from 'components'
import { useLanguages } from 'hooks'
import { useNotificationList, useReadNotification } from 'queries/notification'
import { userProfileAtom } from 'store/authAtom'
import { NotificationOnApp } from 'types'
import { isMSTAdmin } from 'utils/helper'
import { parseTimeDistance, parseTimeNotification } from 'utils/parser'

import defaultAvatar from 'assets/images/default_avatar_grayscale.svg'
import { ReactComponent as Logo } from 'assets/images/knovo_logo_grayscale.svg'

interface NotificationListProps {
  type: string
  userId: string
}

const limit = 10

const NotificationList: React.FC<NotificationListProps> = ({
  type,
  userId,
}) => {
  const navigate = useNavigate()
  const { _t } = useLanguages()
  const [userProfile] = useAtom(userProfileAtom)
  const [currentPage, setCurrentPage] = useState(1)
  const [unreadNotifications, setUnreadNotifications] = useState<string[]>([])
  const isMSTAdminMemo = useMemo(() => isMSTAdmin(userProfile), [userProfile])

  const {
    list: notificationList,
    getPrev,
    getNext,
    isStart,
    isEnd,
  } = useNotificationList({
    userId,
    limit,
    type,
  })

  // ** Queries
  const { mutate: readNotification } = useReadNotification()
  const { list: data } = useNotificationList({
    userId,
  })

  useEffect(() => {
    const unreadNotices = data.filter(item => !item.isRead).map(item => item.id)
    setUnreadNotifications(unreadNotices)
  }, [data])

  const generateMessage = useCallback(
    (notification: NotificationOnApp, content: any) => {
      if (notification?.isParse) {
        const regexDate = /\d{13}_Date/i
        const regexTime = /\d{13}_Time/i
        const regexDateTime = /\d{13}_DateTime/i
        let message = notification.message.replace(
          '{{name}}',
          `${content.firstName} ${content.lastName}`
        )

        const formattedMessageArr = message.split(' ').map(item => {
          const isMatchDate = item.match(regexDate)
          const isMatchTime = item.match(regexTime)
          const isMatchDateTime = item.match(regexDateTime)

          if (isMatchDate || isMatchTime || isMatchDateTime) {
            return parseTimeNotification(item)
          }

          return item
        })

        return formattedMessageArr.join(' ')
      }

      return notification.message.replace(
        '{{name}}',
        `${content.firstName} ${content.lastName}`
      )
    },
    []
  )

  const handleClickItem = (item: any) => {
    !item.isRead && readNotification(item.id)
    if (isMSTAdminMemo) {
      navigate(`/admin/all-notifications/${item.id}`)
    } else {
      navigate(`/notifications/${item.id}?tab=${type}`)
    }
  }

  const handleClickPrev = useCallback(() => {
    if (!isStart) {
      setCurrentPage(old => old - 1)
      getPrev()
    }
  }, [getPrev, isStart])

  const handleClickNext = useCallback(() => {
    if (!isEnd) {
      setCurrentPage(old => old + 1)
      getNext()
    }
  }, [getNext, isEnd])

  return (
    <Box>
      <Flex pt='1rem'>
        {unreadNotifications.length > 0 && <ReadAllNotifications />}
        <Flex ml='auto'>
          <Arrow
            transform='rotate(135deg)'
            onClick={handleClickPrev}
            disabled={isStart || currentPage === 1}
            mr='1rem'
          />
          <Box>{currentPage}</Box>
          <Arrow
            ml='1rem'
            transform='rotate(-45deg)'
            onClick={handleClickNext}
            disabled={isEnd}
          />
        </Flex>
      </Flex>

      {notificationList.length > 0 ? (
        <Box mt='1rem'>
          {notificationList.map(item => {
            let content: any = {}
            if (item.data) {
              content = JSON.parse(item.data)
            }

            return (
              <Box
                p='0.8rem 1.5rem'
                color='#000'
                borderLeft='3px solid'
                borderLeftColor={!item.isRead ? 'primary' : 'white'}
                bgColor='#fff'
                mb='0.375rem'
                key={item.id}
                cursor='pointer'
                onClick={() => {
                  handleClickItem(item)
                }}
              >
                <Grid gridTemplateColumns='1fr 13fr 1fr'>
                  <Flex mr='1rem' alignItems='center'>
                    <Avatar src={content.avatar || defaultAvatar} size='md' />
                  </Flex>
                  <Flex
                    flexDirection={{ base: 'column', md: 'row' }}
                    justifyContent='space-between'
                    alignItems={{ base: 'flex-start', md: 'center' }}
                  >
                    <Box flex='2.5' mr='0.5rem'>
                      <Box as='h2'>{item.title || 'Connection Request'}</Box>
                      <Text fontSize='0.75rem'>
                        {generateMessage(item, content)}
                      </Text>
                      <Text fontSize='0.625rem' color='primary' mt='6px'>
                        {parseTimeDistance(item.createdAt)}
                      </Text>
                    </Box>
                    <Flex
                      pt={{ base: '0.5rem', md: '0' }}
                      justifyContent='flex-end'
                      w={{ base: 'full', md: 'auto' }}
                    >
                      <ActionButton data={item} isMSTAdmin={isMSTAdminMemo} />
                      <ConnectionButton data={item} userId={userId} />
                    </Flex>
                  </Flex>
                </Grid>
              </Box>
            )
          })}
        </Box>
      ) : (
        <Flex mt='5rem' justifyContent='center' alignItems='center'>
          <Flex direction='column' alignItems='center'>
            <Logo />
            <Text color='gray.300' my='1rem'>
              {_t('notifications.empty_list')}
            </Text>
          </Flex>
        </Flex>
      )}
    </Box>
  )
}

export default NotificationList

export enum RefundApprovalStatus {
  APPROVE = 'approve',
  REJECT = 'reject',
}

export interface RefundRequestRequest {
  orderId: string
  sessionId: string
  reason: string
}

export interface RefundApprovalRequest {
  transactionApprovalIds: string[]
  status: RefundApprovalStatus
  comment?: string
}

import React, { useCallback } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import { Box, Button, Flex, Grid, Text } from '@chakra-ui/react'

import { DashboardUserItem, LoadingSpinner } from 'components'
import { useLanguages } from 'hooks'
import { useConnectionListFriend } from 'queries/connection'
import { Connection, ConnectionStatusType, ConnectionType } from 'types'

interface FavoriteLearnersProps {
  userId: string
  isLarge?: boolean
}

const FavoriteLearners: React.FC<FavoriteLearnersProps> = ({
  userId = '',
  isLarge = true,
}) => {
  const navigate = useNavigate()
  const { _t } = useLanguages()
  const { data, isLoading } = useConnectionListFriend(userId, {
    search: '',
    type: ConnectionType.STUDENT,
    status: ConnectionStatusType.CONFIRM,
    page: 1,
    limit: isLarge ? 6 : 3,
  })

  const handleClick = useCallback(
    (item: Connection) => {
      navigate(`/learner/${item.id}`)
    },
    [navigate]
  )

  const renderContent = useCallback(() => {
    if (data?.data?.length) {
      return (
        <Grid
          py='1rem'
          px='1.5rem'
          gridTemplateColumns='repeat(3, 1fr)'
          gridTemplateRows={isLarge ? '1fr 1fr' : '1fr'}
          columnGap='2rem'
          rowGap='1rem'
        >
          {data.data.map(learner => (
            <DashboardUserItem
              key={learner.id}
              data={learner}
              onClick={handleClick}
            />
          ))}
        </Grid>
      )
    }
    return (
      <Flex direction='column' alignItems='center' textAlign='center' px='1rem'>
        <Text textStyle='detailText' pb='0.625rem'>
          {_t('dashboard.favourite_learners.empty')}.
        </Text>
        <Button
          as={Link}
          to='/search-results?category=learner'
          variant='outlinePrimary'
          w='100px'
          size='md'
        >
          {_t('common.browse')}
        </Button>
      </Flex>
    )
  }, [_t, data, handleClick, isLarge])

  return (
    <Flex
      boxShadow='lg'
      rounded='lg'
      h={{ base: '10rem', sm: '5rem', lg: isLarge ? '20rem' : '8.5rem' }}
      w='100%'
      bg='white'
      position='relative'
    >
      <Flex
        as={Link}
        to={'/my-profile?tab=connection&type=learner'}
        direction={{ base: 'row', lg: 'column' }}
        justify={{ base: 'flex-start', lg: 'center' }}
        align={{ base: 'flex-start', sm: 'center', lg: 'flex-start' }}
        p='1rem'
        w={{ base: '100%', lg: '10rem' }}
        _hover={{
          color: 'primary',
        }}
        _before={{
          content: '""',
          display: 'block',
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          cursor: 'pointer',
        }}
      >
        <Text
          className={'blue-purple-gradient-text'}
          display={{ base: 'inline', lg: 'none' }}
        >
          View Connected Learners
        </Text>
        <Text
          className={'blue-purple-gradient-text'}
          display={{ base: 'none', lg: 'inline' }}
        >
          {_t('dashboard.favourite_learners.title')}
        </Text>
        <Box
          fontSize='1.6rem'
          lineHeight='1rem'
          color='primaryDark'
          display={{ base: 'none', lg: 'inline' }}
        >
          &#8594;
        </Box>
      </Flex>
      <Flex
        justifyContent='space-around'
        alignItems='center'
        borderTopRightRadius='0.5rem'
        borderBottomRightRadius='0.5rem'
        w='16rem'
        display={{ base: 'none', lg: 'inherit' }}
      >
        {isLoading && <LoadingSpinner />}
        {!isLoading ? renderContent() : null}
      </Flex>
    </Flex>
  )
}

export default FavoriteLearners

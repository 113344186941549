import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'

import { Box, Button, Flex, Text } from '@chakra-ui/react'
import groupBy from 'lodash.groupby'
import uniqBy from 'lodash.uniqby'

import {
  Checkbox,
  LoadingSpinner,
  Modal,
  ModalProps,
  Option,
  Select,
  TeachingSubjectLevelItemGroup,
} from 'components'
import { country, subject, system } from 'configs/apiPath'
import { useLanguages, useToast } from 'hooks'
import { useFilterSubjectCombination } from 'queries/subjectCombination'
import { useUpdateUser } from 'queries/user'
import { GroupedCombination, SubjectCombination, TeachingLevel } from 'types'
import { formatCombinationOption, getErrorMessage } from 'utils/parser'

interface InputFields {
  country: Option
  system: Option
  subject: Option
}

interface EditTeachingSubjectModalProps extends Omit<ModalProps, 'children'> {
  userId: string
  isNoNeedUpdateForUser?: boolean
  handleReturnData?: (data: GroupedCombination[]) => void
  initData?: GroupedCombination[]
  centerId?: string
}

type SubjectCombinationType = Omit<SubjectCombination, 'description' | 'code'>

const EditTeachingSubjectModal: React.FC<EditTeachingSubjectModalProps> = ({
  isOpen,
  onClose,
  userId,
  initData = [],
  isNoNeedUpdateForUser = false,
  handleReturnData,
  centerId,
}) => {
  const toast = useToast()
  const { _t } = useLanguages()
  const [selectedLevels, setSelectedLevels] = useState<string[]>([])
  const [selectedCombinations, setSelectedCombinations] = useState<
    SubjectCombinationType[]
  >([])

  // groupedCombination whenever list subject combinations changed
  const groupedCombinations = useMemo(() => {
    const formatData = selectedCombinations.map(item => ({
      groupName: `${item.country.id}-${item.system.id}-${item.subject.id}`,
      ...item,
    }))

    const groupData = groupBy(formatData, 'groupName')

    // dunno why ts don't understand these type :|
    // @ts-ignore
    return Object.entries(groupData)
      .filter(([_, data]) => data.length)
      .reduce<GroupedCombination[]>(
        // @ts-ignore
        (acc, [_, data]) => {
          const representData = data[0]
          const {
            country: countryData,
            subject: subjectData,
            system: systemData,
          } = representData

          return [
            ...acc,
            {
              country: countryData,
              subject: subjectData,
              system: systemData,
              teachingLevels: data.map(item => ({
                ...item.teachingLevel,
                subjectCombinationId: item.id,
              })),
            },
          ]
        },
        []
      ) as GroupedCombination[]
  }, [selectedCombinations])

  useEffect(() => {
    if (isOpen) {
      const initSubjectItems = initData.reduce<SubjectCombinationType[]>(
        (acc, cur) => {
          return [
            ...acc,
            ...cur.teachingLevels.map(item => ({
              id: item.subjectCombinationId as string,
              country: cur.country,
              subject: cur.subject,
              system: cur.system,
              teachingLevel: item,
            })),
          ]
        },
        []
      )
      setSelectedCombinations(initSubjectItems)
    }
  }, [initData, isOpen])

  const { mutate: updateUser, isSuccess } = useUpdateUser(userId)

  useEffect(() => {
    if (isSuccess) {
      onClose()
    }
  }, [isSuccess, onClose])

  const { handleSubmit, control, watch, reset } = useForm<InputFields>()

  const countryId = useMemo(() => watch('country')?.value, [watch])
  const systemId = useMemo(() => watch('system')?.value, [watch])
  const subjectId = useMemo(() => watch('subject')?.value, [watch])

  useEffect(() => {
    if (!isOpen) {
      setSelectedLevels([])
      setSelectedCombinations([])
      reset()
    }
  }, [isOpen, reset])

  const {
    data: subjectCombinations,
    isLoading: loadingCombination,
  } = useFilterSubjectCombination({
    countryId,
    systemId,
    subjectId,
  })

  const levels = useMemo(
    () =>
      subjectCombinations?.data.map(combination => combination.teachingLevel),
    [subjectCombinations]
  )

  const canBeAdded = useMemo(() => selectedLevels.length > 0, [selectedLevels])

  const allLevelsChecked = useMemo(() => {
    return selectedLevels.length === levels?.length
  }, [selectedLevels, levels])

  const isIndeterminateChecked = useMemo(() => {
    return selectedLevels.length >= 1 && !allLevelsChecked
  }, [selectedLevels, allLevelsChecked])

  const isChecked = useCallback(
    (item: TeachingLevel) => {
      return selectedLevels.includes(item.id)
    },
    [selectedLevels]
  )

  const toggleCheckBox = useCallback(
    (value: TeachingLevel) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const checked = event.target.checked

      setSelectedLevels(oldLevels => {
        if (checked) {
          return [...oldLevels, value.id]
        }
        return oldLevels.filter(x => x !== value.id)
      })
    },
    []
  )

  const toggleAllCheckBox = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked) {
        setSelectedLevels(() => levels?.map(level => level.id) || [])
        return
      }

      setSelectedLevels([])
    },
    [levels]
  )

  const handleAddCombinationItem = () => {
    if (subjectCombinations?.data) {
      const combinations = subjectCombinations.data.filter(combination =>
        selectedLevels.includes(combination.teachingLevel.id)
      )

      setSelectedCombinations(oldCombinations =>
        uniqBy([...oldCombinations, ...combinations], 'id')
      )
    }
  }

  const handleRemoveCombinationItem = (id: string) => {
    setSelectedCombinations(oldCombinations =>
      oldCombinations.filter(combination => combination.id !== id)
    )
  }

  const handleUpdateCombination = async () => {
    if (isNoNeedUpdateForUser && !!handleReturnData) {
      handleReturnData(groupedCombinations)
      onClose()
    } else {
      try {
        const changedCombinationIds = selectedCombinations.map(combination => {
          return combination.id
        })

        const initCombinationIds = initData.reduce<string[]>((acc, cur) => {
          return [
            ...acc,
            ...cur.teachingLevels.map(
              item => item.subjectCombinationId as string
            ),
          ]
        }, [])

        const deletedCombinations = initCombinationIds
          .filter(id => !changedCombinationIds.includes(id))
          .map(item => ({
            opt: 'remove',
            id: item,
          }))
        const addedCombinations = changedCombinationIds
          .filter(id => !initCombinationIds.includes(id))
          .map(item => ({
            opt: 'add',
            id: item,
          }))
        const totalChangedCombinations = [
          ...deletedCombinations,
          ...addedCombinations,
        ]

        if (totalChangedCombinations.length) {
          updateUser({
            userId,
            data: {
              centerId,
              subjectCombinations: totalChangedCombinations,
            },
          })
        }
      } catch (error) {
        toast({
          title: 'Error',
          description: _t(`message.error.${getErrorMessage(error)}`),
          status: 'error',
        })
      }
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size='4xl'>
      <Box textStyle='headingModalText' textAlign='center' mb='30px'>
        Add Teaching Subjects and Levels
      </Box>
      <Flex>
        <Box w='35rem' pr='50px' borderRight='1px solid #E0E0E0'>
          <Flex mb='20px' pb='20px' borderBottom='1px solid #E5E5E5'>
            <Controller
              as={Select}
              control={control}
              containerProps={{
                width: '10rem',
                marginRight: '1rem',
              }}
              name='country'
              label='Country'
              rules={{
                required: 'Country is required!',
              }}
              optionUrl={country.list({ page: 1, limit: 20 })}
              formatOption={formatCombinationOption}
            />

            <Controller
              as={Select}
              control={control}
              containerProps={{
                flex: 1,
                width: '100%',
              }}
              name='system'
              label='System'
              rules={{
                required: 'System is required!',
              }}
              optionUrl={system.list({ page: 1, limit: 20 })}
              formatOption={formatCombinationOption}
            />
          </Flex>
          <Controller
            as={Select}
            control={control}
            containerProps={{
              width: '100%',
            }}
            name='subject'
            label='Subject'
            rules={{
              required: 'Subject is required!',
            }}
            optionUrl={subject.list({ page: 1, limit: 100 })}
            formatOption={formatCombinationOption}
          />
          {levels && (
            <>
              <Flex p='1.25rem 1.5rem 1rem' justifyContent='space-between'>
                <Text>Levels</Text>
                <Checkbox
                  isChecked={allLevelsChecked}
                  isIndeterminate={isIndeterminateChecked}
                  onChange={toggleAllCheckBox}
                />
              </Flex>
              <Flex
                flexDirection='column'
                bg='#F2FBFF'
                borderRadius='4px'
                mb='1.875rem'
                p='0.625rem'
                maxHeight='13rem'
                overflowY='scroll'
              >
                {levels.map(level => (
                  <Flex
                    key={level.id}
                    px='0.625rem'
                    py='1rem'
                    justifyContent='space-between'
                    alignItems='center'
                    borderBottom='1px solid rgba(229, 229, 229, 0.6)'
                    _last={{ borderBottom: 'none' }}
                  >
                    <Text>{level.name}</Text>
                    <Checkbox
                      isChecked={isChecked(level)}
                      onChange={toggleCheckBox(level)}
                    />
                  </Flex>
                ))}
              </Flex>
            </>
          )}
          {!!!levels && (
            <Box my='2rem' fontSize='0.875rem' textAlign='center'>
              {loadingCombination ? (
                <LoadingSpinner />
              ) : (
                'Choose Subject, System and Country to show Level'
              )}
            </Box>
          )}

          <Button
            variant='outlinePrimary'
            onClick={handleSubmit(handleAddCombinationItem)}
            disabled={!canBeAdded}
          >
            + ADD TEACHING SUBJECT AND LEVEL
          </Button>
        </Box>
        <Box w='27rem' ml='50px'>
          <Text textStyle='tableRow'>
            Selected Teaching Subjects and Levels
          </Text>
          <Box
            w='100%'
            h='95%'
            maxH='400px'
            bg='#F2FBFF'
            p='1rem'
            borderRadius='4px'
            overflowY='scroll'
          >
            {groupedCombinations.length > 0 ? (
              <TeachingSubjectLevelItemGroup
                data={groupedCombinations}
                onRemoveItem={handleRemoveCombinationItem}
              />
            ) : (
              <Flex
                justifyContent='center'
                alignItems='center'
                h='100%'
                fontSize='0.875rem'
              >
                You have not added any Teaching Subjects and Levels
              </Flex>
            )}
          </Box>
        </Box>
      </Flex>
      <Box mt='2rem' textAlign='right'>
        <Button
          variant='solidPrimary'
          disabled={!groupedCombinations.length}
          onClick={handleUpdateCombination}
        >
          DONE
        </Button>
        <Button variant='transparent' ml='10px' onClick={onClose}>
          CANCEL
        </Button>
      </Box>
    </Modal>
  )
}

export default EditTeachingSubjectModal

import React, { useCallback, useEffect, useRef } from 'react'

interface VisibleProps {
  data?: any
  onVisible?: (data: any) => void
  onHidden?: (data: any) => void
}

const Visible: React.FC<VisibleProps> = ({ data, onVisible, onHidden }) => {
  const element = useRef<HTMLDivElement>(null)

  const handleIntersect = useCallback(
    e => {
      const entry = e[0]

      if (entry.isIntersecting) {
        onVisible && onVisible(data)
      } else {
        onHidden && onHidden(data)
      }
    },
    [data, onHidden, onVisible]
  )

  useEffect(() => {
    const intersectionObserver = new IntersectionObserver(handleIntersect)

    if (element.current) {
      intersectionObserver.observe(element.current)
    }

    return () => {
      if (intersectionObserver) {
        intersectionObserver.disconnect()
      }
    }
  }, [handleIntersect])

  return <div ref={element}></div>
}

export default Visible

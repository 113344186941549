import React, { useCallback, useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'

import { Box, Button, Flex, Text } from '@chakra-ui/react'

import { Textarea } from 'components'
import Modal, { ModalProps } from 'components/Modal'
import Select, { Option } from 'components/Select'
import { useLanguages } from 'hooks'
import { reasonLearnerCancelClassOptions } from 'utils/constant'

export interface CancelClassProps extends Omit<ModalProps, 'children'> {
  userRole: 'learner' | 'instructor'
  isNoBookedLearner?: boolean
  onSubmit: (reason: string) => void
  className?: string
  startDateTime?: number
}

interface CancelForm {
  reasonSelect: Option
  reasonText: string
}

const CancelClassModal: React.FC<CancelClassProps> = ({
  userRole,
  isOpen,
  onClose,
  isNoBookedLearner = false,
  onSubmit,
  className,
  startDateTime,
}) => {
  const { _t } = useLanguages()

  const { handleSubmit, control, watch } = useForm<CancelForm>({
    defaultValues: {
      reasonSelect: {
        value: '',
        label: '',
      },
      reasonText: '',
    },
  })

  const isCanBeCancelled = useMemo(() => {
    if (startDateTime) {
      const now = new Date().getTime()
      const diff = startDateTime - now

      return diff > 3600000 ? true : false
    }
    return true
  }, [startDateTime])

  const isDisableConfirm = useMemo(
    () => (watch('reasonText').length > 15 ? false : true),
    [watch]
  )
  const isDisabledBtnStatus = useMemo(() => {
    if (!isCanBeCancelled) {
      return true
    }

    if (userRole === 'instructor' && !!isNoBookedLearner) {
      return false
    }

    return isDisableConfirm
  }, [isCanBeCancelled, isDisableConfirm, isNoBookedLearner, userRole])

  const handleConfirm = useCallback(
    (value: CancelForm) => {
      const { reasonSelect, reasonText } = value
      let formatData = 'No content'

      if (reasonSelect) {
        formatData = `${reasonSelect.value} - ${reasonText}`
      }

      onSubmit(formatData)
    },
    [onSubmit]
  )

  const generateContent = () => {
    if (!isCanBeCancelled) {
      return <Text fontSize='0.875rem'>{_t('product.class.cancel_block')}</Text>
    }

    if (userRole === 'learner') {
      return (
        <>
          <Text fontSize='0.875rem'>
            {_t('product.class.cancel_learner_description')}
          </Text>
          <Box my='1.5rem' w='100%'>
            <Controller
              as={Select}
              control={control}
              name='reasonSelect'
              label={_t('product.class.cancel_reason')}
              defaultOptions={reasonLearnerCancelClassOptions}
              placeholder={_t('product.class.placeholder_cancel_reason')}
              labelProps={{ fontSize: '0.75rem' }}
            />
          </Box>
          <Box w='100%'>
            <Controller
              as={Textarea}
              control={control}
              name='reasonText'
              placeholder={_t('product.class.placeholder_cancel_reason_text')}
              fontSize='0.875rem'
            />
          </Box>
          <Text w='100%' color='gray.300' fontSize='0.625rem' textAlign='right'>
            {_t('product.class.cancel_reason_text_limit')}
          </Text>
        </>
      )
    }

    if (userRole === 'instructor') {
      if (isNoBookedLearner) {
        return (
          <Box textAlign='center'>
            <Text fontSize='0.875rem'>
              {_t('product.class.cancel_instructor_description', {
                name: className || '',
              })}
            </Text>
          </Box>
        )
      } else {
        return (
          <>
            <Text fontSize='0.875rem'>
              {_t('product.class.cancel_instructor_description', {
                name: className || '',
              })}
            </Text>
            <Text fontSize='0.875rem' my='1rem'>
              {_t('product.class.cancel_instructor_note')}
            </Text>
            <Box mb='1.5rem' w='100%'>
              <Controller
                as={Select}
                control={control}
                name='reasonSelect'
                label={_t('product.class.cancel_reason')}
                defaultOptions={reasonLearnerCancelClassOptions}
                placeholder={_t('product.class.placeholder_cancel_reason')}
                labelProps={{ fontSize: '0.75rem' }}
              />
            </Box>
            <Box w='100%'>
              <Controller
                as={Textarea}
                control={control}
                name='reasonText'
                fontSize='0.875rem'
                placeholder={_t('product.class.placeholder_cancel_reason_text')}
              />
            </Box>
            <Text
              w='100%'
              color='gray.300'
              fontSize='0.625rem'
              textAlign='right'
            >
              {_t('product.class.cancel_reason_text_limit')}
            </Text>
          </>
        )
      }
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size='xl'>
      <Flex direction='column' alignItems='center' w='100%'>
        <Text textStyle='headingModalText' align='center' mb='2rem'>
          {_t('product.class.cancel_title')}
        </Text>

        {generateContent()}

        <Flex mt='2rem'>
          <Button
            variant='solidPrimary'
            mr='0.5rem'
            onClick={handleSubmit(handleConfirm)}
            isDisabled={isDisabledBtnStatus}
            textTransform='uppercase'
          >
            {_t('common.confirm')}
          </Button>
          <Button variant='transparent' ml='0.5rem' onClick={onClose}>
            {_t('common.cancel')}
          </Button>
        </Flex>
      </Flex>
    </Modal>
  )
}

export default CancelClassModal

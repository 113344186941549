import React from 'react'

import { Box, Flex } from '@chakra-ui/react'

type LabelType = 'horizontal' | 'vertical'

interface LabelProps {
  label: string
  children: React.ReactNode
  type?: LabelType
  mb?: string | string[]
}

const Label: React.FC<LabelProps> = ({
  label,
  children,
  type = 'vertical',
  mb,
}: LabelProps) => {
  if (type === 'horizontal') {
    return (
      <Flex justifyContent='space-between' mb={mb}>
        <Box fontSize='0.875rem' fontWeight='600'>
          {label}
        </Box>
        {children}
      </Flex>
    )
  }

  return (
    <Box mb={mb}>
      <Box fontSize='0.875rem' fontWeight='600' mb='0.25rem'>
        {label}
      </Box>
      {children}
    </Box>
  )
}

export default Label

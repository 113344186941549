import React from 'react'

import { Box, BoxProps } from '@chakra-ui/react'

import Container from '../Container'
import { Footer, Header } from 'shared/containers'
import { Login } from 'types'

interface LayoutProps extends BoxProps {
  containerProps?: BoxProps
  topElement?: React.ReactNode
  showFooter?: boolean
}

const Layout: React.FC<LayoutProps> = ({
  children,
  containerProps,
  topElement,
  showFooter = false,
  ...boxProps
}) => {
  return (
    <Box {...boxProps}>
      <Header loginType={Login.UserName} />
      <Box w='100%'>
        {topElement && <Box w='100%'>{topElement}</Box>}
        <Container {...containerProps}>{children}</Container>
      </Box>
      {showFooter && <Footer />}
    </Box>
  )
}

export default Layout

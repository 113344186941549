import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Control, Controller, useFieldArray } from 'react-hook-form'

import { Box, Button, Flex, SimpleGrid, Text } from '@chakra-ui/react'

import { CreateClassForm, MaterialForm } from '../form'
import { AttachmentFile, ConfirmAlert, FileUpload, Input } from 'components'
import { useLanguages } from 'hooks'
import {
  Attachment,
  AttachmentFileStatus,
  Material,
  UploadFileStatus,
} from 'types'
import { FILES_LIMIT } from 'utils/constant'

import { ReactComponent as TrashIcon } from 'assets/images/color_trash_icon.svg'

type ProductType = 'class' | 'content' | 'lesson'

interface UploadClass {
  file: Attachment
  status: AttachmentFileStatus
  uploadStatus: UploadFileStatus
}

interface AttachmentSectionProps {
  control: Control<CreateClassForm>
  watch: any
  errors: any
  uploadFiles: UploadClass[]
  isUploading: boolean
  handleChangeFiles: any
  handleReUpload?: (item: UploadClass) => () => Promise<void>
  handleDeleteAttachments?: (id: string) => void
  handleAddMaterial?: (item: Material) => void
  handleUpdateMaterial?: (item: Material) => void
  handleDeleteMaterial?: (item: Material) => void
  type?: ProductType
}

const AttachmentSection: React.FC<AttachmentSectionProps> = ({
  control,
  watch,
  errors,
  uploadFiles,
  isUploading,
  handleChangeFiles,
  handleReUpload,
  handleDeleteAttachments,
  handleAddMaterial,
  handleUpdateMaterial,
  handleDeleteMaterial,
  type,
}) => {
  const { _t } = useLanguages()
  const [reachLimit, setReachLimit] = useState(false)

  const attachments = useMemo(() => watch('attachments') as [], [watch])

  const { fields, append, remove } = useFieldArray<MaterialForm>({
    control,
    name: 'materials',
  })

  const handleMaterialAdd = useCallback(() => {
    append({ name: '', url: '' })
  }, [append])

  const handleMaterialDelete = useCallback((index: number) => remove(index), [
    remove,
  ])

  useEffect(() => {
    if (attachments?.length) {
      setReachLimit(false)
      const newAttachments = attachments.map<UploadClass>(attachment => ({
        file: attachment,
        status: 'public',
        uploadStatus: 'pending',
      }))

      handleChangeFiles((oldAttachments: UploadClass[]) => {
        const newFiles = [...oldAttachments, ...newAttachments]
        if (newFiles.length > FILES_LIMIT) {
          setReachLimit(true)
          return oldAttachments
        }

        return newFiles
      })
    }
  }, [attachments, handleChangeFiles])

  const handleDeleteAttachment = useCallback(
    (name: string, status?: string, id?: string) => {
      if (handleDeleteAttachments && status === 'uploaded' && id) {
        handleDeleteAttachments(id)
      }
      handleChangeFiles((oldAttachments: UploadClass[]) =>
        oldAttachments.filter(attachment => attachment.file.name !== name)
      )
    },
    [handleChangeFiles, handleDeleteAttachments]
  )

  return (
    <Box>
      <Flex direction={{ base: 'column', lg: 'row' }}>
        <Box flex='1' mt='1.2rem'>
          <Controller
            control={control}
            render={({ onChange }) => (
              <FileUpload
                onChange={onChange}
                maxFiles={FILES_LIMIT}
                isReachLimit={reachLimit}
              />
            )}
            name='attachments'
          />
        </Box>
        <Box w='4rem' />
        <Box flex='1' maxW={{ base: '100%', lg: '50%' }}>
          {!!uploadFiles?.length && (
            <Box mt='1.2rem' p='0.5rem' pb='1rem' overflowY='auto'>
              {uploadFiles.map(({ file, uploadStatus, status }) => (
                <AttachmentFile
                  key={file.key || file.name}
                  item={file}
                  deletable
                  uploadStatus={isUploading ? 'uploading' : uploadStatus}
                  onDelete={handleDeleteAttachment}
                  withStatus={false}
                  onReUpload={
                    handleReUpload
                      ? handleReUpload({
                          file,
                          uploadStatus,
                          status,
                        })
                      : undefined
                  }
                />
              ))}
            </Box>
          )}
        </Box>
      </Flex>
      {type === 'content' && (
        <Box>
          <Box mt='1.2rem'>
            <Button
              variant='primary'
              textTransform='uppercase'
              size='sm'
              onClick={handleMaterialAdd}
            >
              <span style={{ fontSize: '2rem' }}>&#43;</span>
              <Text ml='0.5rem'>{_t('product.content.add_material')}</Text>
            </Button>
          </Box>
          {fields.map((item: MaterialForm, index: number) => (
            <Flex key={item.id} mt='1.2rem' align='center' justify='center'>
              <Box w='2rem' fontSize='1.5rem' mr='1rem'>
                {index + 1}
                <Controller
                  control={control}
                  name={`materials[${index}].id`}
                  defaultValue={item.id}
                  render={({ value }) => <Input value={value} type='hidden' />}
                />
              </Box>
              <SimpleGrid columns={[1, 1, 2]} spacing='1.2rem' flex='1'>
                <Controller
                  control={control}
                  name={`materials[${index}].name`}
                  defaultValue={item.name}
                  render={({ onChange, value }) => (
                    <Input
                      label={_t('product.content.material_name')}
                      aria-label='Material Name'
                      placeholder={_t(
                        'product.content.placeholder_material_name'
                      )}
                      onChange={onChange}
                      value={value}
                      fontSize='0.875rem'
                      labelProps={{ fontSize: '0.75rem' }}
                      error={errors?.materials?.[index]?.name?.message || ''}
                    />
                  )}
                  rules={{
                    required: _t(
                      'product.content.validate_material_name_required'
                    ),
                  }}
                />
                <Controller
                  control={control}
                  name={`materials[${index}].url`}
                  defaultValue={item.url}
                  render={({ onChange, value }) => (
                    <Input
                      label={_t('product.content.material_url')}
                      aria-label='Material URL'
                      placeholder={_t(
                        'product.content.placeholder_material_url'
                      )}
                      onChange={onChange}
                      value={value}
                      fontSize='0.875rem'
                      labelProps={{ fontSize: '0.75rem' }}
                      error={errors?.materials?.[index]?.url?.message || ''}
                    />
                  )}
                  rules={{
                    required: _t(
                      'product.content.validate_material_url_required'
                    ),
                  }}
                />
              </SimpleGrid>
              <ConfirmAlert
                onSubmit={() => handleMaterialDelete(index)}
                description={
                  <Text textAlign='center'>
                    {_t('product.content.material_remove_description')}
                  </Text>
                }
                immediatelyCloseOnSubmit
              >
                {({ showAlert }) => (
                  <Box w='2rem' ml='1rem'>
                    <TrashIcon cursor='pointer' onClick={showAlert} />
                  </Box>
                )}
              </ConfirmAlert>
            </Flex>
          ))}
        </Box>
      )}
    </Box>
  )
}

export default AttachmentSection

import { UserRole } from '.'
import { Option } from 'components'

export enum ExploreType {
  STATIC = 'static',
  DYNAMIC = 'dynamic',
  CUSTOM = 'custom',
}

export enum DisplayCard {
  CLASS = 'class',
  USER = 'user',
  CENTER = 'center',
  CLASS_BANNER = 'classBanner',
}

export enum ExploreMeetingType {
  ALL = 'all',
  VIRTUAL = 'virtual',
  PHYSICAL = 'physical',
}

export enum ExploreOrderBy {
  PRICE = 'price',
  DATE = 'date',
}

export enum ExploreOrder {
  ASC = 'asc',
  DESC = 'desc',
}

export enum ExploreSection {
  TOP_SUBJECT = 'top_subject',
}

export interface Filter {
  search?: string
  filterBy?: string
  teachingLevel?: Option
  subject?: Option
  language?: Option
  meetingType?: ExploreMeetingType
  minPrice?: number
  maxPrice?: number
  orderBy?: string
  order?: string
}

export interface Explore {
  id?: string
  title: string
  description: string
  type: ExploreType
  displayCard?: DisplayCard
  section?: string
  filter?: Filter
  customIds?: string[]
  role?: UserRole[]
  sequence: number
}

export type ExploreDisplayParams = {
  role?: UserRole
}

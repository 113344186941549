import {
  Centre,
  Enterprise,
  Meta,
  OrderItem,
  PaymentType,
  TransactionInfo,
  UserProfile,
} from 'types'

export enum CashFlowStatus {
  INITIATE = 'initiate',
  ONHOLD = 'onhold',
  UNRELEASED = 'unreleased',
  RELEASED = 'released',
  CANCELLED = 'cancelled',
}

export enum CashFlowType {
  INSTRUCTOR = 'instructor',
  ENTERPRISE = 'enterprise',
  COMMISSION = 'commission',
}

export interface CashFlowHistory {
  id: string
  cashFlowId: string
  status: CashFlowStatus
  freeze: boolean
  remarks: string
  createdAt: number
}

export interface CashFlowInfo {
  id: string
  orderItemId: string
  orderItem: OrderItem
  transactionId: string
  transaction: TransactionInfo
  recipientTransactionId: string
  recipientTransaction: TransactionInfo
  refundTransactionId: string
  refundTransaction: TransactionInfo
  sessionId: string
  name: string
  status: CashFlowStatus
  freeze: boolean
  type: CashFlowType
  amount: number
  expenseAmount: number
  currency: string
  remarks: string
  sender: UserProfile
  centerSender: Centre
  enterpriseSender: Enterprise
  recipient: UserProfile
  centerRecipient: Centre
  enterpriseRecipient: Enterprise
  createdAt: number
  updatedAt: number
  history?: CashFlowHistory[]
}

export interface CashFlowExportUrl {
  url: string
}

export interface CashFlowListResponse extends Meta {
  data: CashFlowInfo[]
}

export interface CashFlowInfoRequest {
  cashFlowId: string
}

export interface CashFlowInfoResponse {
  data: CashFlowInfo
}

export interface CashFlowExportRequest {
  order?: string
  orderBy?: string
  fileType?: string
  status: CashFlowStatus
}

export interface CashFlowExportResponse {
  data: CashFlowExportUrl
}

export interface CashFlowFreezeRequest {
  cashFlowIds: string[]
  isFreeze: boolean
}

export interface CashFlowUpdateRequest {
  cashFlowIds: string[]
  status: CashFlowStatus
  withdrawalType?: PaymentType
  remarks?: string
}

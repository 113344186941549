import React, { useEffect } from 'react'

import { Box, Flex } from '@chakra-ui/react'

import Key from './Key'
import { getStatuses } from 'utils/game/status'

interface KeyboardProps {
  onChar: (value: string) => void
  onDelete: () => void
  onEnter: () => void
  guesses: string[]
  isRevealing?: boolean
}

const Keyboard: React.FC<KeyboardProps> = ({
  onChar,
  onDelete,
  onEnter,
  guesses,
  isRevealing,
}) => {
  const charStatuses = getStatuses(guesses)

  const onClick = (value: string) => {
    if (value === 'ENTER') {
      onEnter()
    } else if (value === 'DELETE') {
      onDelete()
    } else {
      onChar(value)
    }
  }

  useEffect(() => {
    const listener = (e: KeyboardEvent) => {
      if (e.code === 'Enter') {
        onEnter()
      } else if (e.code === 'Backspace') {
        onDelete()
      } else {
        const key = e.key
        if (key.length === 1 && key >= '0' && key <= '9') {
          onChar(key)
        }
      }
    }
    window.addEventListener('keyup', listener)
    return () => {
      window.removeEventListener('keyup', listener)
    }
  }, [onEnter, onDelete, onChar])

  return (
    <Flex direction='column'>
      <Flex
        justifyContent='center'
        mb='0.5rem'
        direction='row'
        display={{ base: 'none', md: 'flex' }}
      >
        {['1', '2', '3', '4', '5'].map(key => (
          <Key
            value={key}
            key={key}
            onClick={onClick}
            status={charStatuses[key]}
            isRevealing={isRevealing}
          />
        ))}
      </Flex>
      <Flex
        justifyContent='center'
        direction='row'
        display={{ base: 'none', md: 'flex' }}
      >
        {['6', '7', '8', '9', '0'].map(key => (
          <Key
            value={key}
            key={key}
            onClick={onClick}
            status={charStatuses[key]}
            isRevealing={isRevealing}
          />
        ))}
      </Flex>
      <Flex
        justifyContent='center'
        mb='0.5rem'
        direction='row'
        display={{ base: 'flex', md: 'none' }}
      >
        {['1', '2', '3'].map(key => (
          <Key
            value={key}
            key={key}
            onClick={onClick}
            status={charStatuses[key]}
            isRevealing={isRevealing}
          />
        ))}
      </Flex>
      <Flex
        justifyContent='center'
        mb='0.5rem'
        direction='row'
        display={{ base: 'flex', md: 'none' }}
      >
        {['4', '5', '6'].map(key => (
          <Key
            value={key}
            key={key}
            onClick={onClick}
            status={charStatuses[key]}
            isRevealing={isRevealing}
          />
        ))}
      </Flex>
      <Flex
        justifyContent='center'
        mb='0.5rem'
        direction='row'
        display={{ base: 'flex', md: 'none' }}
      >
        {['7', '8', '9'].map(key => (
          <Key
            value={key}
            key={key}
            onClick={onClick}
            status={charStatuses[key]}
            isRevealing={isRevealing}
          />
        ))}
      </Flex>
      <Flex
        justifyContent='center'
        alignItems='center'
        direction='row'
        mt='0.5rem'
      >
        <Key value='DELETE' onClick={onClick} />
        <Key value='DELETE' display='mobile' onClick={onClick} />
        <Box display={{ base: 'inline-flex', md: 'none' }}>
          <Key
            value='0'
            onClick={onClick}
            status={charStatuses['0']}
            isRevealing={isRevealing}
          />
        </Box>
        <Key value='ENTER' display='mobile' onClick={onClick} />
        <Key value='ENTER' onClick={onClick} />
      </Flex>
    </Flex>
  )
}

export default Keyboard

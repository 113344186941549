import storageKey from 'configs/storageKey'
import {
  Attachment,
  AttachmentFileStatus,
  ClassDetail,
  ClassPreview,
  ClassType,
  UploadFileStatus,
  UserProfile,
  UserRole,
} from 'types'

export const downloadFileWithUrl = (url: string, target?: string) => {
  const link = document.createElement('a')
  link.setAttribute('download', url)

  if (target) {
    link.setAttribute('target', target)
  }

  link.href = url
  document.body.appendChild(link)
  link.click()
  link.remove()
}

export const calculateStripeFee = (amount: number) => {
  const fixedFee = 0 // stripe fixed fee: 0.50
  const percentFee = 0 // stripe percent fee: 3.4

  if (amount === 0) {
    return 0
  }
  const totalValue = (amount + fixedFee) / (1 - percentFee / 100)

  return Number((totalValue - amount).toFixed(2))
}

export const validateMultiple = (validations: any[]) => {
  for (const i of validations) {
    if (i !== true) {
      return i
    }
  }

  return true
}

export const validatePositiveFloat = (value: any) => {
  if (value) {
    const pattern = /^-?\d*\.?\d*$/
    const isValid = pattern.test(value.toString())
    const isLessThan0 = Number.parseInt(value.toString() || '0') < 0
    const isGreaterThan0 = Number.parseInt(value.toString() || '0') > 0

    if (isLessThan0) {
      return 'Value can not be less than 0'
    }

    if ((isGreaterThan0 && Number(value.toString()[0]) === 0) || !isValid) {
      return 'Invalid value'
    }

    return true
  }
}

export const validatePositiveInt = (value: any) => {
  if (!value) return

  const pattern = /^\d+$/
  const isValid = pattern.test(value.toString())
  const isGreaterThan0 = Number.parseInt(value.toString() || '0') > 0

  if (!isGreaterThan0) {
    return 'Value must be greater than 0'
  }

  if ((isGreaterThan0 && Number(value.toString()[0]) === 0) || !isValid) {
    return 'Invalid value'
  }

  return true
}

export const validateNonNegativeDecimal = (value: any, places: number = 2) => {
  if (!value) return

  const validPattern = /^-?(0|[1-9][0-9]*)(\.\d*)?$/
  const isValid = validPattern.test(value.toString())
  if (!isValid) {
    return 'Invalid value'
  }

  const isNonNegative = !isNaN(value) && Number(value) >= 0
  if (!isNonNegative) {
    return 'Value must not be negative'
  }

  const placesPattern = new RegExp(`^-?\\d*\\.?\\d{0,${places}}$`)
  const withinPlaces = placesPattern.test(value.toString())
  if (!withinPlaces) {
    return `Value cannot have more than ${places} decimal place${
      places === 1 ? '' : 's'
    }`
  }

  return true
}

export const validateMaxStudent = (value: any) => {
  const pattern = /^\d+$/
  const isValid = pattern.test(value.toString())
  const isGreaterThan0 = Number.parseInt(value.toString() || '0') > 0

  if (!isGreaterThan0) {
    return 'Max number of learners must be greater than 0'
  }

  if ((isGreaterThan0 && Number(value.toString()[0]) === 0) || !isValid) {
    return 'Invalid value'
  }

  return true
}

export const storeData = (key: string, value: any) => {
  try {
    localStorage.setItem(key, value)
  } catch (error) {
    console.error('storeData', error)
  }
}

export const getData = (key: string) => {
  let res = undefined
  try {
    res = localStorage.getItem(key)
  } catch (error) {
    console.error('getData', error)
  }
  return res
}

export const removeData = (key: string) => {
  try {
    localStorage.removeItem(key)
  } catch (error) {
    console.error('removeData', error)
  }
}

export const isLearner = (userProfile: UserProfile | null) => {
  const loggedInRole = localStorage.getItem(storageKey.role)
  if (
    (loggedInRole === UserRole.Student ||
      loggedInRole === UserRole.Enterprise) &&
    userProfile
  ) {
    return userProfile.roles?.some(
      role =>
        role.type === UserRole.EnterpriseStudent ||
        role.type === UserRole.Student
    )
  }
  return false
}

export const isInstructor = (userProfile: UserProfile | null) => {
  const loggedInRole = localStorage.getItem(storageKey.role)
  if (
    (loggedInRole === UserRole.Tutor || loggedInRole === UserRole.Enterprise) &&
    userProfile
  ) {
    return userProfile.roles?.some(
      role =>
        role.type === UserRole.EnterpriseTutor || role.type === UserRole.Tutor
    )
  }
  return false
}

export const isEnterprise = (userProfile: UserProfile | null) => {
  if (!userProfile) return false
  return userProfile.roles?.some(
    role =>
      role.type === UserRole.EnterpriseStudent ||
      role.type === UserRole.EnterpriseTutor
  )
}

export const isAdmin = (userProfile: UserProfile | null) => {
  if (!userProfile) return false
  return userProfile.roles?.some(
    role =>
      role.type === UserRole.MSTAdmin ||
      role.type === UserRole.MSTSuperAdmin ||
      role.type === UserRole.EnterpriseAdmin ||
      role.type === UserRole.EnterpriseSuperAdmin ||
      role.type === UserRole.CenterAdmin
  )
}

export const isMSTAdmin = (userProfile: UserProfile | null) => {
  if (!userProfile) return false
  return userProfile.roles?.some(
    role =>
      role.type === UserRole.MSTAdmin || role.type === UserRole.MSTSuperAdmin
  )
}

export const isEnterpriseAdmin = (userProfile: UserProfile | null) => {
  if (!userProfile) return false
  return userProfile.roles?.some(
    role =>
      role.type === UserRole.EnterpriseAdmin ||
      role.type === UserRole.EnterpriseSuperAdmin
  )
}

export const isCenterAdmin = (userProfile: UserProfile | null) => {
  if (!userProfile) return false
  return userProfile.roles?.some(role => role.type === UserRole.CenterAdmin)
}

export const isInstructorAccount = (name: string) => {
  if (!name) return false
  return name === UserRole.Tutor || name === UserRole.EnterpriseTutor
}

export const isLearnerAccount = (name: string) => {
  if (!name) return false
  return name === UserRole.Student || name === UserRole.EnterpriseStudent
}

export const isEnterpriseAccount = (name: string) => {
  if (!name) return false
  return (
    name === UserRole.EnterpriseStudent || name === UserRole.EnterpriseTutor
  )
}

export const isPackageClass = (
  classDetail: ClassDetail | ClassPreview | null
) => {
  if (!classDetail) return false
  return (
    classDetail.type === ClassType.PRIVATE_PACKAGE ||
    classDetail.type === ClassType.PUBLIC_PACKAGE ||
    classDetail.type === ClassType.ENTERPRISE_PACKAGE
  )
}

export const isRegularClass = (
  classDetail: ClassDetail | ClassPreview | null
) => {
  if (!classDetail) return false
  return (
    classDetail.type === ClassType.PRIVATE_REGULAR ||
    classDetail.type === ClassType.PUBLIC_REGULAR ||
    classDetail.type === ClassType.ENTERPRISE_REGULAR
  )
}

export const isContentClass = (
  classDetail: ClassDetail | ClassPreview | null
) => {
  if (!classDetail) return false
  return (
    classDetail.type === ClassType.PRIVATE_CONTENT ||
    classDetail.type === ClassType.PUBLIC_CONTENT ||
    classDetail.type === ClassType.ENTERPRISE_CONTENT
  )
}

export const isAdhocClass = (classDetail: ClassDetail | null) => {
  if (!classDetail) return false
  return classDetail.type === ClassType.ADHOC_REQUEST
}

export const isRegularClassType = (type: string | undefined) => {
  if (!type) return false
  return (
    type === ClassType.PRIVATE_REGULAR ||
    type === ClassType.PUBLIC_REGULAR ||
    type === ClassType.ENTERPRISE_REGULAR
  )
}

export const isPackageClassType = (type: string | undefined) => {
  if (!type) return false
  return (
    type === ClassType.PRIVATE_PACKAGE ||
    type === ClassType.PUBLIC_PACKAGE ||
    type === ClassType.ENTERPRISE_PACKAGE
  )
}

export const isContentClassType = (type: string | undefined) => {
  if (!type) return false
  return (
    type === ClassType.PRIVATE_CONTENT ||
    type === ClassType.PUBLIC_CONTENT ||
    type === ClassType.ENTERPRISE_CONTENT
  )
}

export const isLessonClassType = (type: string | undefined) => {
  if (!type) return false
  return (
    type === ClassType.PUBLIC_LESSON || type === ClassType.ENTERPRISE_LESSON
  )
}

export const checkValidFilesName = (
  files: {
    file: Attachment
    status?: AttachmentFileStatus
    uploadStatus?: UploadFileStatus
  }[] = []
) => {
  if (!files.length) return true

  const regex = /^[a-z0-9 _-]+\.[^.]+$/i
  return files.every(file => regex.test(file.file.name) === true)
}

export const mask = (text: string | undefined, showLastChar: number) => {
  if (text) {
    const lastChar = text.substring(text.length - showLastChar)
    return (
      text.substring(0, text.length - showLastChar).replace(/\d/g, '*') +
      lastChar
    )
  }
  return ''
}

export const isFileSizeOver = (fileSize: number, maxSize: number) => {
  return fileSize > maxSize
}

export const sleep = (m: number) => new Promise(r => setTimeout(r, m))

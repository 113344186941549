export * from './user'

export type Paginate = {
  page?: number
  limit?: number
}

export type ListingParams = Paginate & {
  search?: string
  status?: string
  type?: string
  filterBy?: string
  filter?: string
  active?: boolean
  searchBy?: string
  orderBy?: string
  order?: string
  learners?: string
  instructors?: string
  admin?: string
  wallet?: string
  fromDate?: number
  toDate?: number
  timezone?: string
  roleName?: string
  tutorId?: string
  centerId?: string
  startDateTime?: number
  enterpriseId?: string
  location?: string
  centers?: string
  transactionId?: string
  learnerId?: string
  assignment?: string
  fromDeadline?: number
  toDeadline?: number
  completion?: string
  fromPublishDate?: number
  toPublishDate?: number
  transactionType?: string
  role?: string
}

export type Meta = {
  meta: {
    total: number
    next?: number
  }
}

export type QueryListResponse<T> = {
  data: T[]
} & Meta

export type QueryDetailResponse<T> = {
  data: T
}

export interface TabRef {
  submit: () => void
}

export interface AvatarUrl {
  data: {
    fields: {
      key: string
      'Content-Type': string
      Policy: string
      bucket: string
      'X-Amz-Algorithm': string
      'X-Amz-Credential': string
      'X-Amz-Security-Token': string
      'X-Amz-Signature': string
      'x-amz-meta-userid': string
      'X-Amz-Date': string
    }
    url: string
  }
}

export interface UploadFileUrl extends AvatarUrl {}

export interface FileInfo {
  fileType: string
  contentType: string
  fileName?: string
  title?: string
  index?: number
  show?: boolean
  type?: string
}

export interface Message {
  [key: string]: string
}

export interface Error {
  message: string
  detail?: string[]
}

export interface CreatedItem {
  data: {
    id: string
  }
}

export * from './advertisement'
export * from './sidebarRoute'
export * from './role'
export * from './permission'
export * from './centre'
export * from './country'
export * from './result'
export * from './subject'
export * from './system'
export * from './teachingLevel'
export * from './subjectCombination'
export * from './multilingualism'
export * from './request'
export * from './bid'
export * from './enterprise'
export * from './classSession'
export * from './session'
export * from './class'
export * from './assignment'
export * from './wallet'
export * from './search'
export * from './notification'
export * from './subscription'
export * from './chat'
export * from './token'
export * from './refund'
export * from './withdrawal'
export * from './review'
export * from './connection'
export * from './campaign'
export * from './promo'
export * from './game'
export * from './cart'
export * from './payment'
export * from './explore'
export * from './order'
export * from './transaction'
export * from './cashflow'
export * from './fileUrl'

import { ConnectionStatus } from './connection'
import { Language } from './multilingualism'
import { Subject } from './subject'
import { AccountType, LessonPackage, Paginate } from 'types'

export enum Order {
  ASC = 'asc',
  DESC = 'desc',
}

export enum SearchOrderBy {
  DEFAULT = '',
  PRICE = 'price',
  DATE = 'date',
}

export enum SearchFilterBy {
  DEFAULT = '',
  ALL = 'all',
  USER = 'user',
  STUDENT = 'student',
  TUTOR = 'tutor',
  CLASS = 'class',
  CONTENT = 'content',
  LESSON = 'lesson',
  CENTRE = 'center',
}

export type DefaultResult = {
  id: string
}

export type AllSearchResult = {
  type: string
  data: AllSearchResultItem
}

export type AllSearchResultItem = {
  data: []
  total: number
}

export interface UserInSearch {
  id: string
  firstName: string
  lastName: string
  avatar: string
  centers?: CentreSearchResult[]
  rating: number
}

export interface Session {
  id: string
  name: string
  startDateTime: number
  endDateTime: number
  meetingType?: string
}

export type ClassSearchResult = DefaultResult & {
  name: string
  description: string
  banner?: string
  price: number
  usualPrice?: number
  type: string
  user: UserInSearch
  sessions: Session[]
  isPurchasedClass?: boolean
}

export type ContentSearchResult = DefaultResult & {
  name: string
  description: string
  banner?: string
  price: number
  usualPrice?: number
  type: string
  user: UserInSearch
  isPurchasedClass?: boolean
}

export type LessonSearchResult = DefaultResult & {
  name: string
  description: string
  banner?: string
  price: number
  usualPrice?: number
  type: string
  user: UserInSearch
  isPurchasedClass?: boolean
}

export type SessionSearchResult = DefaultResult & {
  name: string
  description: string
  banner?: string
  price: number
  usualPrice?: number
  type: string
  user: UserInSearch
  sessions?: Session[]
  totalSession?: number
  centers?: CentreSearchResult[]
  packages?: LessonPackage[]
  isPurchasedClass?: boolean
}

export type UserSearchResult = DefaultResult & {
  firstName: string
  lastName: string
  avatar: string
  accountType: AccountType
  rating?: string
  country?: string
  subjects?: Subject[]
  languages?: Language[]
  centers?: CentreSearchResult[]
  connection?: ConnectionStatus
}

export type CentreSearchResult = DefaultResult & {
  name: string
  avatar: string
  banner: string
  rating: number
  connection?: ConnectionStatus
}

export type SearchParam = Paginate & {
  search?: string
  searchIds?: string
  order?: string
  orderBy?: string
  filterBy?: SearchFilterBy
  subjectId?: string
  centerId?: string
  subjectLevelId?: string
  languageId?: string
  teachingLevelId?: string
  minPrice?: number
  maxPrice?: number
  meetingType?: string
}

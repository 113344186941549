import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'

import Auth from 'configs/auth'
import storageKey from 'configs/storageKey'
import { Login } from 'types'
import { getData, storeData } from 'utils/helper'

axios.defaults.baseURL = process.env.REACT_APP_API_URL

const setupAxiosInterceptors = () => {
  const onRequestSuccess = async (config: AxiosRequestConfig) => {
    if (config.headers['No-Need-Authorization']) {
      delete config.headers['No-Need-Authorization']
      return config
    }

    const accessToken = Auth.getAccessToken()
    if (accessToken) {
      config.headers.Authorization = accessToken
    }

    return config
  }

  const onResponseSuccess = (response: AxiosResponse) => response
  const onResponseError = (err: AxiosError) => {
    const status = err?.response?.status
    const msg = err?.response?.data?.error?.message

    if (status === 403 || status === 401) {
      console.error('Unauthenticated')

      const originalReq = err.config

      return getRefreshToken(
        getData(storageKey.accessToken) || '',
        getData(storageKey.refreshToken) || '',
        getData(storageKey.expiresAt) || ''
      )
        .then(response => {
          if (response.status === 400) {
            return Auth.signOut()
          }
          return response.json()
        })
        .then(({ data }) => {
          const { accessToken, expiresIn } = data

          storeData(storageKey.accessToken, accessToken)
          storeData(
            storageKey.expiresAt,
            new Date().getTime() + expiresIn * 1000
          )

          originalReq.headers['Authorization'] = accessToken

          return axios(originalReq)
        })
        .catch(error => {
          if (error?.response?.status === 400) {
            return Auth.signIn({ type: Login.Guest })
          }
        })
    }

    return Promise.reject(err?.response || err)
  }

  axios.interceptors.request.use(onRequestSuccess)
  axios.interceptors.response.use(onResponseSuccess, onResponseError)
}

const getRefreshToken = (
  accessToken: string,
  refreshToken: string,
  expiredAtStr: string
): Promise<any> => {
  if (!expiredAtStr || !refreshToken || !accessToken) {
    return Promise.reject('Empty token')
  }

  const now = new Date().getTime()
  const expiredAt = expiredAtStr ? parseInt(expiredAtStr) : 0

  if (expiredAt - now > 0) {
    return Promise.reject()
  }

  const myHeaders = new Headers()
  myHeaders.append('Authorization', accessToken)
  myHeaders.append('Content-Type', 'application/json')

  const raw = JSON.stringify({ refreshToken })

  const requestOptions: RequestInit = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow',
    referrer: 'no-referrer',
  }

  return fetch(
    `${process.env.REACT_APP_API_URL}/user/v1/refresh-token`,
    requestOptions
  )
}

export { setupAxiosInterceptors }

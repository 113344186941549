import React, { useMemo } from 'react'

import { Box, BoxProps } from '@chakra-ui/react'

interface MapProps extends BoxProps {
  query?: string
  lat?: string
  lng?: string
}

const Map: React.FC<MapProps> = ({ query = '', lat, lng, ...props }) => {
  const source = useMemo(() => {
    const url = `https://www.google.com/maps/embed/v1/place?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`
    const searchQuery = lat && lng ? `&q=${lat},${lng}` : `&q=${query}`
    return `${url}${searchQuery}`
  }, [lat, lng, query])

  return (
    <Box
      as='iframe'
      src={source}
      allow='accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
      {...props}
    />
  )
}

export default Map

import React, { useCallback, useState } from 'react'
import DatePicker from 'react-datepicker'

import { Box, Button, Flex, Text } from '@chakra-ui/react'
import { add, endOfDay, min } from 'date-fns'

import { Input, Modal, ModalProps } from 'components'
import { getDateOnly } from 'utils/date'

interface ExportModalProps extends Omit<ModalProps, 'children'> {
  onSubmit: (data: [Date, Date]) => void
  loading?: boolean
  maxDays?: number
}

const today = new Date()

const ExportModal = ({
  isOpen,
  onClose,
  onSubmit,
  loading = false,
  maxDays = 6,
}: ExportModalProps) => {
  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([
    null,
    null,
  ])
  const [startDate, endDate] = dateRange

  const handleSubmit = useCallback(() => {
    if (startDate && endDate) {
      onSubmit([startDate, endOfDay(endDate)])
    }
  }, [endDate, onSubmit, startDate])

  const onRangeChange = useCallback(
    (range: [Date, Date]) => {
      if (maxDays && range[1]) {
        const maxDate = add(range[0], { days: maxDays })
        range[1] = min([maxDate, range[1]])
      }

      setDateRange(range)
    },
    [maxDays]
  )

  const CustomInput = ({ _, onClick }: any) => (
    <Input
      aria-label='Date range'
      label='Date range'
      placeholder='Select Date range'
      defaultValue={
        dateRange[0]
          ? `${getDateOnly(startDate)} - ${getDateOnly(endDate)}`
          : ''
      }
      fontSize='0.75rem'
      labelProps={{ fontSize: '0.75rem' }}
      onClick={onClick}
    />
  )

  return (
    <Modal isOpen={isOpen} onClose={onClose} size='xl'>
      <Box textStyle='headingModalText' textAlign='center' mb='30px'>
        Export Records
      </Box>
      <Text fontSize='0.75rem' fontWeight='400' textAlign='center'>
        Select the date range of records that you wish to export.
      </Text>
      <Flex justifyContent='center'>
        <DatePicker
          shouldCloseOnSelect={!(!startDate || Boolean(startDate && endDate))}
          showPopperArrow={false}
          selectsRange={true}
          startDate={startDate}
          endDate={endDate}
          onChange={onRangeChange}
          maxDate={today}
          customInput={<CustomInput />}
        />
      </Flex>
      <Flex mt='2rem'>
        <Button
          mr='2rem'
          flex='1'
          disabled={
            dateRange.length !== 2 || !dateRange[0] || !dateRange[1] || loading
          }
          // @ts-ignore
          onClick={handleSubmit}
        >
          Export
        </Button>
        <Button flex='1' variant='transparent' onClick={onClose}>
          Cancel
        </Button>
      </Flex>
    </Modal>
  )
}

export default ExportModal

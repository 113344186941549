import React from 'react'

import {
  CheckboxProps as ChakraCheckboxProps,
  Checkbox,
  Flex,
  Text,
} from '@chakra-ui/react'

interface CheckboxProps extends ChakraCheckboxProps {
  error?: string | undefined
}

const CustomCheckbox: React.FC<CheckboxProps> = ({
  children,
  error,
  ...checkboxProps
}) => {
  return (
    <Flex alignItems='center' wrap='wrap'>
      <Checkbox {...checkboxProps}>{children}</Checkbox>
      {error && <Text textStyle='error'>{error}</Text>}
    </Flex>
  )
}

export default CustomCheckbox

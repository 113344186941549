import React, { useCallback } from 'react'

import { Box, Button, Flex, Text } from '@chakra-ui/react'

import { Modal } from 'components'
import { useLanguages, useToast } from 'hooks'
import { GameReward } from 'types'

interface RewardModalProps {
  isOpen: boolean
  handleClose: () => void
  gameReward: GameReward
}

const RewardModal: React.FC<RewardModalProps> = ({
  isOpen,
  handleClose,
  gameReward,
}) => {
  const { _t } = useLanguages()
  const toast = useToast()

  const handleClick = useCallback(() => {
    navigator.clipboard.writeText(gameReward?.promoCode)

    toast({
      title: 'Copied!',
      description: _t('games.copied_to_clipboard'),
      status: 'success',
    })
  }, [_t, gameReward, toast])

  return (
    <Modal isOpen={isOpen} onClose={handleClose} size={'lg'}>
      <Flex direction='column' align='center'>
        <Box w='8rem'>{gameReward?.icon}</Box>
        <Text
          my='1.5rem'
          fontSize='1.25rem'
          fontWeight='semibold'
          lineHeight='1.25rem'
          textAlign='center'
        >
          {gameReward?.title}
        </Text>
        {gameReward?.promoCode && (
          <Flex w='full' rounded='lg' bgColor='lightgrey' align='center'>
            <Box
              flex='1'
              textAlign={'center'}
              fontSize={'20px'}
              color={'darkblue'}
            >
              {gameReward?.promoCode}
            </Box>
            <Button
              variant='solidPrimary'
              onClick={handleClick}
              marginRight={'auto'}
            >
              {_t('games.copy')}
            </Button>
          </Flex>
        )}
      </Flex>
    </Modal>
  )
}

export default RewardModal

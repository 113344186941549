import React, { useCallback, useEffect, useState } from 'react'

import { useAtom } from 'jotai'

import { RatingModal } from 'components'
import { joinSessionAtom } from 'store/joinSessionAtom'

const Review: React.FC = () => {
  const [isShowingModal, setShowingModal] = useState(false)
  const [joinSessionData, setJoinedSessionData] = useAtom(joinSessionAtom)
  const [reviewInfo, setReviewInfo] = useState({
    tutorId: joinSessionData?.tutorId || '',
    sessionId: joinSessionData?.sessionId || '',
  })

  const handleCloseModal = useCallback(() => {
    setShowingModal(false)
    setJoinedSessionData(null)
  }, [setJoinedSessionData])

  useEffect(() => {
    if (joinSessionData) {
      setReviewInfo({
        tutorId: joinSessionData.tutorId,
        sessionId: joinSessionData.sessionId,
      })

      if (joinSessionData.endDateTime < Date.now()) {
        setShowingModal(true)
      } else {
        setTimeout(() => {
          setShowingModal(true)
        }, joinSessionData.endDateTime - Date.now())
      }
    }
  }, [joinSessionData])

  if (
    joinSessionData?.endDateTime &&
    joinSessionData?.endDateTime < Date.now()
  ) {
    return (
      <RatingModal
        reviewInfo={reviewInfo}
        isOpen={isShowingModal}
        onClose={handleCloseModal}
      />
    )
  }

  return null
}

export default Review

import { useCallback, useEffect } from 'react'
import { useMutation, useQuery } from 'react-query'

import { useAtom } from 'jotai'

import { useToastError } from './helpers'
import { addMessage, deleteMessage, getMessages } from 'apis/message'
import { language, messageUrl } from 'configs/apiPath'
import { useLanguages, useToast } from 'hooks'
import { availableLanguagesAtom } from 'store/languageAtom'
import { Language, Message, Multilingual } from 'types'

export const useAvailableLanguages = () => {
  const [, setLanguages] = useAtom(availableLanguagesAtom)
  const { data, isSuccess, ...query } = useQuery<{
    data: Language[]
  }>(language.list({ page: 1, limit: 20 }))

  useEffect(() => {
    if (isSuccess) {
      setLanguages(data?.data || [])
    }
  }, [data, isSuccess, setLanguages])

  return { data, isSuccess, ...query }
}

export const getAvailableLanguageNames = (
  availableLanguages: Language[]
): {
  [key: string]: string
} => {
  return availableLanguages.reduce((acc, { code, name }) => {
    return {
      ...acc,
      [code]: name,
    }
  }, {})
}

export const useMessages = (lang: string) => {
  return useQuery<Message>(`${messageUrl}/messages/${lang}.json`, async () => {
    const res = await getMessages(lang)

    return res.data
  })
}

const fetchAllMessages = async ({
  availableLanguages,
  search = '',
}: {
  availableLanguages: Language[]
  search?: string
}) => {
  const promises: Promise<{
    data: Message
  }>[] = []

  availableLanguages.forEach(lang => {
    promises.push(getMessages(lang.code))
  })

  const res = await Promise.allSettled(promises)
  const languages = res.map(result => {
    if (result.status === 'fulfilled' && result.value) {
      return result.value.data
    }

    return {}
  })
  const languageKeys = Object.keys(languages[0])

  const formatLanguages = availableLanguages.reduce<{
    [key: string]: {
      [key: string]: string
    }
  }>((acc, { code }, curIndex) => {
    return {
      ...acc,
      [code]: languages[curIndex],
    }
  }, {})
  //formatLanguages: {
  //   "en": {
  //     key1: "value 1",
  //     key2: "value 2"
  //   },
  //   "vi": {
  //     key1: "value 3",
  //     key2: "value 4"
  //   }
  // }

  const groupByKey: Multilingual[] = languageKeys.map(langKey => {
    return {
      key: langKey,
      translations: availableLanguages.reduce(
        (acc, { code }) => ({
          ...acc,
          [code]: formatLanguages?.[code]?.[langKey] || '',
        }),
        {}
      ),
    }
  })
  // groupByKey: [
  //   {
  //     key: 'key1',
  //     translation: {
  //       en: 'value1',
  //       vi: 'value2'
  //     }
  //   }
  // ]

  if (!!search) {
    return groupByKey.filter(item =>
      item.key.toLowerCase().includes(`${search.toLowerCase()}`)
    )
  }

  return groupByKey
}

export const useAllMessages = (messageKey: string, search?: string) => {
  const [languages] = useAtom(availableLanguagesAtom)

  return useQuery(
    messageKey,
    () => fetchAllMessages({ availableLanguages: languages, search }),
    {
      enabled: languages?.length > 0,
    }
  )
}

export const useAddMessage = ({
  onInvalidateMessageUrl,
  onCloseModal,
}: {
  onInvalidateMessageUrl: () => void
  onCloseModal?: () => void
}) => {
  const toast = useToast()
  const [languages] = useAtom(availableLanguagesAtom)
  const { _t } = useLanguages()

  const addAllMessage = useCallback(
    (values: { [key: string]: string }) => {
      const promises: Promise<any>[] = []
      languages.forEach(lang => {
        promises.push(
          addMessage({
            locale: lang.code,
            key: values.key,
            value: values[lang.code],
          })
        )
      })

      return Promise.all(promises)
    },
    [languages]
  )

  return useMutation(addAllMessage, {
    onSuccess: () => {
      onInvalidateMessageUrl()
      onCloseModal && onCloseModal()
      toast({
        title: 'Success!',
        description: _t('message.success.message_add'),
        status: 'success',
      })
    },
    onError: useToastError(),
  })
}

export const useDeleteMessage = (onInvalidateMessageUrl: () => void) => {
  const toast = useToast()
  const [languages] = useAtom(availableLanguagesAtom)
  const { _t } = useLanguages()

  const deleteAllMessage = useCallback(
    (key: string) => {
      const promises: Promise<any>[] = []
      languages.forEach(lang => {
        promises.push(
          deleteMessage({
            locale: lang.code,
            key,
          })
        )
      })

      return Promise.all(promises)
    },
    [languages]
  )

  return useMutation(deleteAllMessage, {
    onSuccess: () => {
      onInvalidateMessageUrl()
      toast({
        title: 'Success!',
        description: _t('message.success.message_remove'),
        status: 'success',
      })
    },
    onError: useToastError(),
  })
}

import React, { useCallback, useState } from 'react'
import { useNavigate } from 'react-router'

import { Box, Button, Flex, Text } from '@chakra-ui/react'

import { Modal } from 'components'
import { ModalProps } from 'components/Modal'
import { useLanguages, useUserId } from 'hooks'
import { useCancelSubscription } from 'queries/subscription'

import { ReactComponent as KnovoLogo } from 'assets/images/knovo_logo.svg'

interface DowngradeSubscriptionModalProps extends Omit<ModalProps, 'children'> {
  cancelSubscriptionId: string
  createPackageId: string
  type: 'privateTutor' | 'enterprise'
  id: string
}

const DowngradeSubscriptionModal: React.FC<DowngradeSubscriptionModalProps> = ({
  isOpen,
  onClose,
  cancelSubscriptionId,
  createPackageId,
  type,
  id, // it will be enterpriseId when type = enterprise, userId when type = privateTutor
}) => {
  const userId = useUserId()
  const navigate = useNavigate()
  const { _t } = useLanguages()
  const [step, setStep] = useState<number>(1) // 1:Confirmation , 2:Success
  const { mutate: cancelSubscription } = useCancelSubscription({
    userId,
    ...(type === 'enterprise' && { enterpriseId: id }),
  })

  const handleStartDowngrade = useCallback(async () => {
    await cancelSubscription({
      ...(type === 'enterprise' && { enterpriseId: id }),
    })
    setStep(2)
  }, [cancelSubscription, id, type])

  const handleClose = useCallback(() => {
    setStep(1)
    onClose()
  }, [onClose])

  const handleNavigaToAccount = () => {
    navigate('/wallet')
    handleClose()
  }

  const generateContent = () => {
    if (step === 1) {
      return (
        <Box>
          <Box as='h1' textAlign='center' mb='1.8rem'>
            {_t('subscription.downgrade.title')}
          </Box>

          <Box fontSize='0.875rem' textAlign='center'>
            <Text>
              {_t('subscription.downgrade.description')}
              <Text display='inline' fontSize='1rem' fontWeight='600'>
                {_t('subscription.downgrade.plan')}
              </Text>
              ?
            </Text>
            <Text my='0.5rem'>{_t('subscription.downgrade.notes')}</Text>
            <Text>{_t('subscription.downgrade.confirmation')}</Text>
          </Box>

          <Flex alignItems='center' justifyContent='space-between' mt='1rem'>
            <Button
              flex='1'
              variant='solidPrimary'
              textTransform='uppercase'
              onClick={handleStartDowngrade}
            >
              {_t('common.confirm')}
            </Button>
            <Button
              flex='1'
              variant='transparent'
              textTransform='uppercase'
              onClick={handleClose}
            >
              {_t('common.cancel')}
            </Button>
          </Flex>
        </Box>
      )
    }

    return (
      <Box textAlign='center'>
        <Flex justifyContent='center' mb='1.5rem'>
          <KnovoLogo width='6rem' height='auto' />
        </Flex>
        <Box as='h2'>{_t('subscription.downgrade.complete_title')}</Box>
        <Text fontSize='0.875rem' mt='1rem' mb='2rem'>
          {_t('subscription.downgrade.complete_description')}
        </Text>
        <Flex justifyContent='center' alignItems='center'>
          <Button
            mr='0.5rem'
            variant='solidPrimary'
            textTransform='uppercase'
            onClick={handleNavigaToAccount}
          >
            {_t('subscription.downgrade.go_to_account')}
          </Button>
          <Button
            variant='outlinePrimary'
            textTransform='uppercase'
            onClick={() => navigate('/')}
          >
            {_t('subscription.downgrade.go_to_dashboard')}
          </Button>
        </Flex>
      </Box>
    )
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      size='xl'
      modalBodyProps={{ px: '4rem' }}
    >
      {generateContent()}
    </Modal>
  )
}

export default DowngradeSubscriptionModal

import React, { lazy, Suspense, useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'

import { LoadingSpinner } from 'components'
import { useQueryParams } from 'hooks'
import { useClassDetail } from 'queries/class'
import { UserRole, UserRoleType } from 'types'

const tabData = (role: UserRole, id: string) => {
  switch (role) {
    case UserRole.EnterpriseSuperAdmin:
    case UserRole.EnterpriseAdmin:
      return [
        {
          id: 'class',
          title: 'Edit Class',
          path: `/enterprise-admin/session-management/edit/class/${id}?tab=class`,
        },
        {
          id: 'classSession',
          title: 'Edit Class Session',
          path: `/enterprise-admin/session-management/edit/class/${id}?tab=classSession`,
        },
      ]

    case UserRole.CenterAdmin:
      return [
        {
          id: 'class',
          title: 'Edit Class',
          path: `/center-admin/session-management/edit/class/${id}?tab=class`,
        },
        {
          id: 'classSession',
          title: 'Edit Class Session',
          path: `/center-admin/session-management/edit/class/${id}?tab=classSession`,
        },
      ]

    case UserRole.EnterpriseTutor:
    case UserRole.Tutor:
      return [
        {
          id: 'class',
          title: 'Edit Class',
          path: `?tab=class`,
        },
        {
          id: 'classSession',
          title: 'Edit Class Session',
          path: `?tab=classSession`,
        },
      ]

    default:
      return [
        {
          id: 'class',
          title: 'Edit Class',
          path: `/admin/session-management/edit/class/${id}?tab=class`,
        },
        {
          id: 'classSession',
          title: 'Edit Class Session',
          path: `/admin/session-management/edit/class/${id}?tab=classSession`,
        },
      ]
  }
}

type TabId = 'class' | 'classSession'

const ClassEdit = lazy(() => import('../../Product/Class/Edit'))
const SessionEdit = lazy(() => import('../../Product/Class/SessionEdit'))

interface EditClassContainerProps {
  role: UserRoleType
  handleClose: () => void
}

const EditClassContainer = ({ role, handleClose }: EditClassContainerProps) => {
  const params = useParams()
  const classId = params.id
  const query = useQueryParams()
  const currentTab = useMemo<TabId>(() => (query.tab || 'class') as TabId, [
    query,
  ])
  const { data: classDetail } = useClassDetail({ classId })

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <Suspense fallback={<LoadingSpinner />}>
        {classDetail?.data ? (
          <>
            {currentTab === 'class' && (
              <ClassEdit
                role={role}
                createdData={classDetail.data}
                handleClose={handleClose}
                tabData={tabData(role, classId).find(
                  item => item.id === 'classSession'
                )}
              />
            )}
            {currentTab === 'classSession' && (
              <SessionEdit
                role={role}
                handleClose={handleClose}
                createdData={classDetail.data}
              />
            )}
          </>
        ) : (
          <LoadingSpinner />
        )}
      </Suspense>
    </>
  )
}

export default EditClassContainer

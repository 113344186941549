import React, { useCallback, useEffect, useState } from 'react'

import { Button, Flex, Grid, GridItem, Text } from '@chakra-ui/react'

import {
  IconBox,
  Link,
  LoadingSpinner,
  SingleReview,
  Visible,
} from 'components'
import { useLanguages } from 'hooks'
import { useReviewList } from 'queries/review'
import { CreatedReview } from 'types'

import { ReactComponent as StarIcon } from 'assets/images/star_grayscale.svg'

interface TutorReviewsProps {
  rating?: number
  tutorId?: string
  centerId?: string
  type: 'instructor' | 'center'
  isPreview?: boolean
  limit?: number
}

const TutorReviews: React.FC<TutorReviewsProps> = ({
  rating,
  tutorId,
  centerId,
  type,
  isPreview = false,
  limit = 10,
}) => {
  const { _t } = useLanguages()
  const [loadingPage, setLoadingPage] = useState<number>(1)
  const [savedReviewsData, setSavedReviewsData] = useState<CreatedReview[]>([])

  const { data: reviews, isLoading } = useReviewList({
    page: loadingPage,
    type: type === 'instructor' ? 'tutor' : type,
    limit,
    order: 'desc',
    ...(tutorId && { tutorId }),
    ...(centerId && { centerId }),
  })

  useEffect(() => {
    const allResults = reviews?.data || []

    if (loadingPage > 1) {
      setSavedReviewsData(oldData => [...oldData, ...allResults])
    } else {
      window.scrollTo(0, 0)
      setSavedReviewsData([...allResults])
    }
  }, [loadingPage, reviews])

  const handleLoadMore = useCallback(() => {
    if (reviews?.meta.total) {
      const lastPage = Math.ceil(reviews?.meta.total / limit)

      if (!isLoading && loadingPage <= lastPage) {
        setLoadingPage(oldPage => oldPage + 1)
      }
    }
  }, [reviews, limit, isLoading, loadingPage])

  const renderReviews = useCallback(() => {
    return (
      <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(6, 1fr)' }}>
        {rating && (
          <GridItem colSpan={1}>
            <Flex flexDirection='column' mr={{ base: '0.75rem' }}>
              <Text fontSize='3.2rem' color='primaryDark'>
                {rating.toFixed(1)}
              </Text>
              <Text fontSize='0.8rem'>{`(${_t('user.review.count', {
                total: `${reviews?.meta.total || 0}`,
              })})`}</Text>
            </Flex>
          </GridItem>
        )}
        <GridItem colSpan={5} mt={{ base: '1rem', md: '0rem' }}>
          {savedReviewsData.map(item => (
            <SingleReview key={item.id} item={item} isPreview={isPreview} />
          ))}
          {isLoading && <LoadingSpinner />}
          {(reviews?.meta.total || 0) > limit && (
            <>
              {isPreview ? (
                <Button variant='outlinePrimary' as={Link} to='?tab=reviews'>
                  {_t('user.review.more')}
                </Button>
              ) : (
                <Visible onVisible={handleLoadMore} />
              )}
            </>
          )}
        </GridItem>
      </Grid>
    )
  }, [
    _t,
    handleLoadMore,
    isLoading,
    isPreview,
    limit,
    rating,
    reviews,
    savedReviewsData,
  ])

  const renderContent = useCallback(() => {
    return !savedReviewsData.length ? (
      <Text fontSize='0.875rem' color='gray.300'>
        {_t('user.review.empty', { type })}
      </Text>
    ) : (
      renderReviews()
    )
  }, [_t, renderReviews, savedReviewsData.length, type])

  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <IconBox icon={StarIcon} title={_t('user.review.heading', { type })}>
          {renderContent()}
        </IconBox>
      )}
    </>
  )
}

export default TutorReviews

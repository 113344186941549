import React, { useCallback, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { Box, Button, Flex, Text } from '@chakra-ui/react'

import {
  FilterForm,
  paymentTypeOptions,
  statusOptions,
  typeOptions,
} from '../../form'
import { Checkbox, Modal, ModalProps } from 'components'
import { useLanguages } from 'hooks'

interface FilterModalProps extends Omit<ModalProps, 'children'> {
  onSubmit: (options: FilterForm) => void
  isFilterType?: boolean
}

const FilterModal = ({
  isOpen,
  onClose,
  onSubmit,
  isFilterType = true,
}: FilterModalProps) => {
  const { _t } = useLanguages()
  const navigate = useNavigate()
  const location = useLocation()
  const [transactionType, setTransactionType] = useState<string>('all')
  const [status, setStatus] = useState<string>('all')
  const [paymentType, setPaymentType] = useState<string>('all')

  const handleChangeTransactionType = useCallback(
    (value: string) => setTransactionType(value),
    []
  )
  const handleChangeStatus = useCallback(
    (value: string) => setStatus(value),
    []
  )
  const handleChangePaymentType = useCallback(
    (value: string) => setPaymentType(value),
    []
  )

  const handleSubmit = useCallback(() => {
    const newFilters = {
      type: transactionType !== 'all' ? transactionType : undefined,
      status: status !== 'all' ? status : undefined,
      paymentType: paymentType !== 'all' ? paymentType : undefined,
    }
    onClose()
    navigate(location.pathname, { replace: true })
    onSubmit(newFilters)
  }, [
    location.pathname,
    navigate,
    onSubmit,
    onClose,
    paymentType,
    status,
    transactionType,
  ])

  const handleReset = useCallback(() => {
    setTransactionType('all')
    setStatus('all')
    setPaymentType('all')
    onClose()
    navigate(location.pathname, { replace: true })
    onSubmit({})
  }, [location.pathname, navigate, onSubmit, onClose])

  const renderFilterOption = useCallback(
    (
      name: string,
      options: { value: string; label: string }[],
      selectedValue: string,
      handler: (value: string) => void
    ) => {
      return (
        <Box fontWeight='600'>
          <Text textTransform='uppercase' color='primary'>
            {_t(name)}
          </Text>
          <Box>
            <Flex
              justifyContent='space-between'
              py='1rem'
              borderBottom='1px solid #E0E0E0'
            >
              <Text>{_t('common.all')}</Text>
              <Checkbox
                isChecked={selectedValue === 'all'}
                onChange={() => handler('all')}
              />
            </Flex>
            {options.map(option => {
              return (
                <Flex
                  key={option.value}
                  justifyContent='space-between'
                  alignItems='center'
                  py='1rem'
                  _notLast={{ borderBottom: '1px solid #E0E0E0' }}
                >
                  <Text>{_t(option.label)}</Text>
                  <Checkbox
                    isChecked={
                      selectedValue !== 'all' && selectedValue === option.value
                    }
                    onChange={() => handler(option.value)}
                  />
                </Flex>
              )
            })}
          </Box>
        </Box>
      )
    },
    [_t]
  )

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size='lg'
      modalBodyProps={{ pt: 0 }}
    >
      <Flex
        fontSize='0.875rem'
        justifyContent='space-between'
        backgroundColor='#fff'
        alignItems='center'
        pb='2rem'
        zIndex={1}
        position='sticky'
        top='0'
      >
        <Box as='h1'>{_t('common.filters')}</Box>
        <Flex alignItems='center'>
          <Button
            variant='solidPrimary'
            mr='0.5rem'
            py='1rem'
            px='1.5rem'
            textTransform='uppercase'
            onClick={handleSubmit}
          >
            {_t('common.apply')}
          </Button>
          <Button
            variant='outlinePrimary'
            p='1rem'
            textTransform='uppercase'
            onClick={handleReset}
          >
            {_t('common.clear_all')}
          </Button>
        </Flex>
      </Flex>

      {isFilterType && (
        <Box mb='2rem'>
          {renderFilterOption(
            'wallet.management.filter_transaction_type',
            typeOptions,
            transactionType,
            handleChangeTransactionType
          )}
        </Box>
      )}
      <Box mb='2rem'>
        {renderFilterOption(
          'wallet.management.filter_status',
          statusOptions,
          status,
          handleChangeStatus
        )}
      </Box>
      <Box mb='2rem'>
        {renderFilterOption(
          'wallet.management.filter_payment_type',
          paymentTypeOptions,
          paymentType,
          handleChangePaymentType
        )}
      </Box>
    </Modal>
  )
}

export default FilterModal

import React from 'react'

import { Avatar, Box, Flex, Grid, Text } from '@chakra-ui/react'

import { Learner } from 'types'

import { ReactComponent as CloseIcon } from 'assets/images/close_gray_icon.svg'

interface LearnersSectionProps {
  learners: Learner[]
  handleDeleteLearner: (learnerId: string) => void
}

const LearnersSection = ({
  learners = [],
  handleDeleteLearner,
}: LearnersSectionProps) => {
  return (
    <>
      {learners.length ? (
        <>
          {learners.map((item, i) => (
            <Box key={i} _notLast={{ mb: '1rem' }} overflow='auto'>
              <Flex
                bg='#fff'
                py='0.5rem'
                px='1rem'
                alignItems='center'
                justifyContent='space-between'
                maxHeight='5rem'
                overflowY='auto'
                boxShadow='0px 10px 20px rgb(0 0 0 / 4%), 0px 2px 6px rgb(0 0 0 / 4%), 0px 0px 1px rgb(0 0 0 / 4%)'
              >
                <Grid
                  templateColumns='0.8rem 3rem minmax(6rem, 30%) 1fr'
                  alignItems='center'
                  fontSize='0.75rem'
                  overFlow='hidden'
                  w='calc(100% - 1rem)'
                >
                  <Text color='gray.300'>{i + 1}.</Text>
                  <Avatar
                    src={item?.avatar}
                    name={`${item?.firstName} ${item?.lastName}`}
                    size='sm'
                  />
                  <Text
                    isTruncated
                  >{`${item.firstName} ${item.lastName}`}</Text>
                  <Text color='gray.300' pl='0.6rem' isTruncated>
                    {item.email}
                  </Text>
                </Grid>
                <Box w='1rem'>
                  <CloseIcon
                    cursor='pointer'
                    onClick={() => handleDeleteLearner(item.id)}
                  />
                </Box>
              </Flex>
            </Box>
          ))}
        </>
      ) : (
        <Flex
          justifyContent='center'
          alignItems='center'
          h='100%'
          overFlow='auto'
        >
          <Text fontSize='0.75rem'>
            You have not added any Learners to your class.
          </Text>
        </Flex>
      )}
    </>
  )
}

export default LearnersSection

import React from 'react'

import { Avatar, Box, Flex, Grid, GridItem, Text } from '@chakra-ui/react'

import { HeadingSection, ImageWrapper } from 'components'
import { useLanguages } from 'hooks'
import { EnterpriseDetail } from 'types'
import { responsivePadding } from 'utils/constant'

import replaceBanner from 'assets/images/replace_banner.png'

interface EnterpriseHeaderProps {
  data: EnterpriseDetail
  tabData: any[]
  currentTab: string
}

const EnterpriseHeader: React.FC<EnterpriseHeaderProps> = ({
  data,
  tabData,
  currentTab,
}) => {
  const { _t } = useLanguages()

  return (
    <Box as='section' backgroundColor='secondaryLightBlue'>
      <ImageWrapper
        h='auto'
        maxH='14rem'
        w='full'
        objectFit='cover'
        src={data?.banner}
        defaultSrc={replaceBanner}
      />
      <Grid
        templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(6, 1fr)' }}
        px={responsivePadding}
      >
        <GridItem colSpan={1}>
          <Flex mt={{ base: -30, md: -15 }} justify='center'>
            <Avatar
              size='2xl'
              src={data?.avatar}
              name={data?.name || ''}
              alt='User avatar'
              objectFit='cover'
            />
          </Flex>
        </GridItem>
        <GridItem
          colSpan={{ base: 1, md: 5 }}
          mt='1rem'
          textAlign={{ base: 'center', md: 'left' }}
          ml={{ base: 0, md: '1rem' }}
        >
          <Text fontSize='0.85rem' fontWeight='600' textTransform='uppercase'>
            {_t('common.enterprise')}
          </Text>
          <Text as='h1'>{data?.name || ''}</Text>
        </GridItem>
      </Grid>

      <HeadingSection
        title=''
        tabData={tabData}
        currentTab={currentTab}
        widthType='narrow'
      />
    </Box>
  )
}

export default EnterpriseHeader

import Geocoding, {
  GeocodeRequest,
} from '@mapbox/mapbox-sdk/services/geocoding'

import { MeetingLocation } from 'types'

const useGeocode = () => {
  const getGeocode = async (query: string): Promise<MeetingLocation[]> => {
    const geocodingService = Geocoding({
      accessToken: process.env.REACT_APP_MAPBOX_API_KEY || '',
    })
    const response = await geocodingService
      .forwardGeocode({
        query,
        mode: 'mapbox.places',
      } as GeocodeRequest)
      .send()
    const features = response?.body?.features

    return features.map((feature: any) => {
      return {
        address: feature.place_name,
        lng: feature.center[0].toString(),
        lat: feature.center[1].toString(),
      }
    })
  }
  return { getGeocode }
}

export default useGeocode

import React, { useCallback } from 'react'
import { Controller, useForm } from 'react-hook-form'

import { Box, Button, Flex, Text } from '@chakra-ui/react'

import { Textarea } from 'components'
import Modal, { ModalProps } from 'components/Modal'
import { useLanguages } from 'hooks'
import { TransactionApprovalInfo } from 'types'
import { formatAmount } from 'utils/parser'

export interface ApprovalModalProps extends Omit<ModalProps, 'children'> {
  data: TransactionApprovalInfo
  onAccept: (refundId: string) => void
  onReject: (refundId: string, comment: string) => void
}

const ApprovalModal: React.FC<ApprovalModalProps> = ({
  isOpen,
  onClose,
  data,
  onAccept,
  onReject,
}) => {
  const { _t } = useLanguages()

  const { control, handleSubmit, errors } = useForm<{ comment: string }>({
    defaultValues: {
      comment: '',
    },
  })

  const handleApprove = useCallback(() => {
    onAccept(data.id)
  }, [onAccept, data])

  const handleReject = useCallback(
    ({ comment }: { comment: string }) => {
      onReject(data.id, comment)
    },
    [onReject, data]
  )

  return (
    <Modal isOpen={isOpen} onClose={onClose} size='xl'>
      <Flex mx='auto' direction='column' alignItems='center'>
        <Box as='h1'>{_t('refund.modal.approval_title')}</Box>

        <Text fontSize='0.75rem' my='1rem'>
          {_t('refund.modal.approval_description', {
            amount: `${formatAmount((data.transaction.amount || 0) / 100)}`,
            name: `${data.learner.firstName} ${data.learner.lastName}`,
          })}
        </Text>

        <Flex mb='1rem' direction='column' alignItems='center'>
          <Text fontWeight={600}>{_t('refund.modal.reason')}</Text>
          <Text>{data.reason}</Text>
        </Flex>

        <Controller
          control={control}
          name='comment'
          rules={{
            required: _t('refund.modal.validate_comment_required'),
          }}
          error={errors.comment?.message}
          as={Textarea}
          label={_t('refund.modal.comment')}
          placeholder={_t('refund.modal.placeholder_comment')}
          fontSize='0.75rem'
        />

        <Text mt='2rem' fontSize='0.75rem'>
          {_t('refund.modal.notes')}
        </Text>
        <Flex mt='0.5rem' w='90%' justifyContent='center'>
          <Button
            flex='1'
            variant='solidPrimary'
            mr='0.5rem'
            textTransform='uppercase'
            onClick={() => handleApprove()}
          >
            {_t('common.approve')}
          </Button>
          <Button
            flex='1'
            variant='solidPrimary'
            mr='0.5rem'
            textTransform='uppercase'
            onClick={handleSubmit(handleReject)}
          >
            {_t('common.reject')}
          </Button>
        </Flex>
        <Flex w='90%' justifyContent='center'>
          <Button
            flex='1'
            variant='transparent'
            ml='0.5rem'
            textTransform='uppercase'
            onClick={onClose}
          >
            {_t('common.cancel')}
          </Button>
        </Flex>
      </Flex>
    </Modal>
  )
}

export default ApprovalModal

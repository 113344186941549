import Axios from 'axios'

import { adminPath } from 'configs/apiPath'
import { Role, UpdateRoleInfo } from 'types'

export const updateRoleInfo = ({
  roleId,
  name,
  permissions,
}: {
  roleId: string
} & UpdateRoleInfo) => {
  return Axios.put(adminPath.getRoleDetail(roleId), {
    name,
    permissions,
  })
}

/* define page and limit to invidate cache data on query cache */

export const assignUsersToRole = ({
  roleId,
  userIds,
  centerId,
  enterpriseId,
}: {
  roleId: string
  userIds: string[]
  centerId?: string
  enterpriseId?: string
  page?: number
  limit?: number
}) => {
  return Axios.patch(adminPath.getUsersBelongToRole({ roleId }), {
    userIds,
    centerId,
    enterpriseId,
  })
}

export const removeUsersToRole = ({
  roleId,
  userIds,
}: {
  roleId: string
  userIds: string[]
  page?: number
  limit?: number
}) => {
  return Axios.delete(adminPath.getUsersBelongToRole({ roleId }), {
    data: {
      userIds,
    },
  })
}

export const createRole = ({
  name,
  permissions,
  userIds,
}: {
  name: string
  permissions: string[]
  userIds?: string[]
}) => {
  return Axios.post<{
    data: Role
  }>(adminPath.createRole(), {
    name,
    permissions,
    ...(userIds && { userIds }),
  })
}

export const deleteRole = (roleId: string) => {
  return Axios.delete(adminPath.getRoleDetail(roleId))
}

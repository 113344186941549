import React, { useCallback } from 'react'

import {
  Avatar,
  Box,
  Flex,
  Grid,
  GridItem,
  Text,
  Textarea,
} from '@chakra-ui/react'

import { ItemDetail, RatingSmall } from 'components'
import { useLanguages } from 'hooks'
import { useUserProfile } from 'queries/user'
import { Bid } from 'types'
import { formatDate, getFullName } from 'utils/parser'

import { ReactComponent as LocationIcon } from 'assets/images/label-location.svg'

interface TutorBidItemProps {
  bid: Bid
  requestDate: number
  onCancelRequest?: React.ReactElement
  onAcceptRequest?: React.ReactElement
}

const TutorBidItem: React.FC<TutorBidItemProps> = ({
  bid,
  requestDate,
  onCancelRequest,
  onAcceptRequest,
}) => {
  const { _t } = useLanguages()
  const { data: TutorProfile } = useUserProfile(bid.user?.id || '')
  const getDateTime = useCallback(() => {
    return formatDate(bid.startDateTime || 0, {
      formatStr: 'eeee, MMMM d, yyyy h:mma',
    })
  }, [bid])
  const isClassCollide = bid?.startDateTime !== requestDate
  return (
    <Box rounded='lg' bg='white' p='2rem 2.5rem'>
      <Flex alignItems='center' mb='1rem'>
        <Avatar
          size='lg'
          name={getFullName(bid?.user || {})}
          src={bid.user?.avatar}
        />
        <Box ml='1rem'>
          <Flex mb='0.25rem'>
            <Text mr='1rem' fontSize='14px'>
              {getFullName(bid?.user || {})}
            </Text>
            {TutorProfile?.data.rating && (
              <RatingSmall rate={TutorProfile.data.rating} />
            )}
          </Flex>
          {/* The flex below shows the instructor subject tags, uncomment to re-enable in the future */}
          {/* <Flex mb='0.25rem'>
            <Tag
              p='8px'
              rounded='12px'
              color='green'
              fontSize='9px'
              mr='0.25rem'
              size='sm'
              bgColor='transparent'
              border='0.5px solid #C2E3FC'
            >
              {TutorProfile?.data.educationQualification}
            </Tag>
            <Tag
              p='8px'
              borderRadius='6px'
              color='green'
              fontSize='9px'
              mr='0.25rem'
              size='sm'
              bgColor='transparent'
              border='0.5px solid #C2E3FC'
            >
              {TutorProfile?.data.educationSystem?.teachingLevel?.name}
            </Tag>
          </Flex> */}
          <Flex alignItems='center'>
            <LocationIcon />
            <Text ml='0.25rem' fontSize='10px' color='gray.100'>
              {TutorProfile?.data.country
                ? TutorProfile.data.country
                : _t('job.request.validate_instructor_location')}
            </Text>
          </Flex>
        </Box>
      </Flex>
      <Grid
        templateColumns='repeat(3, 1fr)'
        gap='0.5rem'
        mb='1rem'
        overflowY='hidden'
      >
        <GridItem colSpan={1}>
          <ItemDetail
            isVertical
            title={_t('job.bid.price')}
            text={'$' + bid.price.toString()}
          />
        </GridItem>
        <GridItem colSpan={2}>
          <ItemDetail
            isVertical
            title={_t('job.bid.date_and_time')}
            text={getDateTime()}
            isWarning={isClassCollide}
            tooltipText={
              isClassCollide
                ? _t('job.request.validate_instructor_schedule')
                : undefined
            }
          />
        </GridItem>
      </Grid>
      <Box mb='1rem'>
        <Text
          textStyle='detailTitle'
          color='gray.100'
          lineHeight='1.125rem'
          mb='0.5rem'
          textTransform='uppercase'
        >
          {_t('job.request.description')}
        </Text>
        <Textarea fontSize='12px' value={bid.message} readOnly />
      </Box>
      <Flex
        justifyContent='flex-end'
        flexDir={{ base: 'column-reverse', md: 'row' }}
      >
        {onCancelRequest}
        {onAcceptRequest}
      </Flex>
    </Box>
  )
}

export default TutorBidItem

import { useEffect } from 'react'

import firebase from 'firebase/app'
import 'firebase/auth'
import { useAtom } from 'jotai'

import {
  derivedFirebaseAuthAtom,
  derivedFirebaseUserAtom,
} from 'store/authAtom'

export const useFirebase = () => {
  const [firebaseToken] = useAtom(derivedFirebaseAuthAtom)
  const [firebaseUser, setFirebaseAuth] = useAtom(derivedFirebaseUserAtom)

  useEffect(() => {
    if (firebaseToken && !firebaseUser) {
      firebase
        .auth()
        .signInWithCustomToken(firebaseToken)
        .then(userCredential => {
          setFirebaseAuth(userCredential)
        })
        .catch(error => {
          console.error(error)
        })
    }
  }, [firebaseToken, firebaseUser, setFirebaseAuth])

  return {
    firebaseToken,
    firebaseUser,
  }
}

import React, { useCallback, useMemo } from 'react'
import { useParams } from 'react-router-dom'

import { Box, Flex, Image, Text, useDisclosure } from '@chakra-ui/react'

import { getSessionLink } from 'apis/classSession'
import { CancelClassModal } from 'components'
import { useLanguages, useLoading, useToast, useUserId } from 'hooks'
import {
  useClassDetail,
  useLearnerCancelClass,
  useTutorCancelClass,
} from 'queries/class'
import { PreviewClass } from 'shared/containers'
import { ClassDetail, ClassDetailItem, ClassPreview, ReviewInfo } from 'types'
import { buildMeetingLink, getErrorMessage } from 'utils/parser'

import ArrowBackIcon from 'assets/images/arrowBack_icon.svg'
import { ReactComponent as ClassEmptyIcon } from 'assets/images/empty_classes_icon.svg'

interface PreviewClassContainerProps {
  userRoleCancellation?: 'learner' | 'instructor'
  handleToBack?: () => void
  onBuyClass?: (data: ClassPreview) => void
  onEditClass?: () => void
  onJoinSession?: (props: ReviewInfo) => void
}

const PreviewClassContainer = ({
  handleToBack,
  onBuyClass,
  userRoleCancellation,
  onEditClass,
  onJoinSession,
}: PreviewClassContainerProps) => {
  const params = useParams()
  const userId = useUserId()
  const toast = useToast()
  const { setLoading } = useLoading()
  const { _t } = useLanguages()
  const classId = useMemo(() => params.id, [params])
  const { isOpen, onOpen, onClose } = useDisclosure()

  const { data: classDetail, isError } = useClassDetail({ classId })
  const { mutate: learnerCancelClass } = useLearnerCancelClass(classId)
  const { mutate: tutorCancelClass } = useTutorCancelClass(classId)

  const formatData = (data?: ClassDetail) => {
    if (data) {
      const { teachingLevel, language, confirmedStudents, subject } = data

      return {
        ...data,
        teachingLevel: {
          value: teachingLevel.id,
          label: teachingLevel.name,
        },
        subject: { value: subject.id, label: subject.name },
        language: { value: language.id, label: language.name },
        numOfConfirmedStudents: confirmedStudents.length,
      }
    }
  }

  const formatClassedData = (data?: ClassDetail) => {
    if (data) {
      const { sessions } = data

      return sessions.map(
        ({
          description,
          endDateTime,
          id,
          index,
          name,
          startDateTime,
          attachments,
          meetingType,
          meetingLocation,
        }) => ({
          name,
          description,
          id,
          index,
          startDateTime,
          endDateTime,
          sessionAttachments:
            attachments?.map(item => ({
              file: item,
              status: item.status || 'public',
              uploadStatus: item.createdAt ? 'uploaded' : 'failure',
            })) || [],
          meetingType,
          meetingLocation,
        })
      ) as ClassDetailItem[]
    }
  }

  const handleCancelClass = useCallback(
    (reason: string) => {
      if (userRoleCancellation === 'learner') {
        learnerCancelClass({ userId, classId, data: { reason }, onClose })
      } else {
        tutorCancelClass({ userId, classId, data: { reason }, onClose })
      }
    },
    [
      classId,
      learnerCancelClass,
      onClose,
      tutorCancelClass,
      userId,
      userRoleCancellation,
    ]
  )

  const handleOpenCancelClass = useCallback(() => {
    onOpen()
  }, [onOpen])

  const handleJoinSession = useCallback(
    async dataItem => {
      const fetchLink = async () => {
        try {
          setLoading(true)
          const res = await getSessionLink(dataItem.classId, dataItem.sessionId)

          if (res.data) {
            onJoinSession &&
              onJoinSession({
                tutorId: classDetail?.data?.user?.id || '',
                sessionId: dataItem.sessionId,
                endDateTime:
                  classDetail?.data.sessions.find(
                    s => s.id === dataItem.sessionId
                  )?.endDateTime || 0,
              })

            const { meetingType, meetingLink } = res.data.data
            const link =
              meetingType === 'zoom'
                ? buildMeetingLink(res.data.data)
                : meetingLink
            const openLink = window.open(link, '_blank') as Window
            openLink.focus()
          }
        } catch (error) {
          toast({
            title: 'Error!',
            description: _t(`message.error.${getErrorMessage(error)}`),
            status: 'error',
          })
        } finally {
          setLoading(false)
        }
      }

      await fetchLink()
    },
    [_t, classDetail, onJoinSession, setLoading, toast]
  )

  return (
    <>
      {!!handleToBack && (
        <Box
          as='section'
          pt='1.5rem'
          px={['1.5rem', '6rem', '7rem']}
          bg={isError ? 'transparent' : 'secondaryLightBlue'}
        >
          <Box as='button' onClick={handleToBack}>
            <Image src={ArrowBackIcon} d='inline' mr='0.5rem' />
            {_t('product.class.session_management')}
          </Box>
        </Box>
      )}

      {isError ? (
        <Flex minH='calc(100vh - 4.5rem)' justify='center'>
          <Flex direction='column' justify='center' align='center'>
            <ClassEmptyIcon />
            <Text color='gray.300' mt='1rem'>
              {_t('product.class.not_found')}
            </Text>
          </Flex>
        </Flex>
      ) : (
        <PreviewClass
          data={formatData(classDetail?.data)}
          userId={classDetail?.data.user.id as string}
          attachments={classDetail?.data.attachments || []}
          sessionData={formatClassedData(classDetail?.data)}
          onBuyClass={onBuyClass}
          onCancelClass={handleOpenCancelClass}
          onJoinSession={handleJoinSession}
          onEditClass={onEditClass}
        />
      )}

      {userRoleCancellation && (
        <CancelClassModal
          userRole={userRoleCancellation}
          className={classDetail?.data.name}
          startDateTime={
            classDetail?.data?.sessions &&
            classDetail?.data?.sessions.length > 0
              ? classDetail?.data.sessions[0].startDateTime
              : undefined
          }
          isOpen={isOpen}
          onClose={onClose}
          onSubmit={handleCancelClass}
          isNoBookedLearner={!classDetail?.data.confirmedStudents.length}
        />
      )}
    </>
  )
}

export default PreviewClassContainer

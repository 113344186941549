import React, { forwardRef, useEffect, useMemo, useRef } from 'react'
import {
  Column,
  Row,
  useExpanded,
  useFlexLayout,
  useRowSelect,
  useTable,
} from 'react-table'
import { useSticky } from 'react-table-sticky'

import { Box, Flex } from '@chakra-ui/react'

import { LoadingSpinner } from 'components'

interface CollapsibleRowTableProps<T extends object> {
  columns: Column<T>[]
  data: T[]
  loading: boolean
  onSelectRows?: (rowIds: T[]) => void
  colorScheme?: string
}

const IndeterminateCheckbox = forwardRef(
  ({ indeterminate, ...rest }: any, ref) => {
    const defaultRef = useRef()
    const resolvedRef = (ref || defaultRef) as any

    useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])

    return (
      <input
        className='table__user-selectionInput'
        type='checkbox'
        ref={resolvedRef}
        {...rest}
      />
    )
  }
)

function CollapsibleRowTable<T extends object>({
  columns,
  data,
  loading,
  onSelectRows,
  colorScheme = 'primaryDarker',
}: CollapsibleRowTableProps<T>) {
  const defaultColumn = useMemo(
    () => ({
      width: 165,
    }),
    []
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
  } = useTable<T>(
    { columns: columns, data: data, defaultColumn },
    useFlexLayout,
    useExpanded,
    useRowSelect,
    useSticky,
    hooks => {
      if (onSelectRows) {
        // @ts-ignore
        const sticky = columns[0].sticky === 'left' ? 'left' : null
        hooks.visibleColumns.push(innerColumns => [
          // Let's make a column for selection
          {
            id: 'selection',
            // The header can use the table's getToggleAllRowsSelectedProps method
            // to render a checkbox
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            ),
            // The cell can use the individual row's getToggleRowSelectedProps method
            // to the render a checkbox
            Cell: ({ row }: { row: Row<T> }) =>
              row.depth === 0 && (
                <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
              ),
            sticky,
            width: '45',
          },
          ...innerColumns,
        ])
      }
    }
  )

  useEffect(() => {
    if (onSelectRows) {
      const innerRows = selectedFlatRows.map(d => d.original)

      onSelectRows(innerRows)
    }
  }, [selectedFlatRows, onSelectRows])

  return (
    <Box
      w='100%'
      className='sticky'
      {...getTableProps()}
      sx={{
        '[data-sticky-td]': {
          position: 'sticky',
        },
        '[data-sticky-last-left-td]': {
          boxShadow: '3px 0px 0px #ccc',
        },

        '[data-sticky-first-right-td]': {
          boxShadow: '-3px 0px 0px #ccc',
        },
      }}
      overflowX='scroll'
    >
      <Box>
        {headerGroups.map(headerGroup => (
          <Flex {...headerGroup.getHeaderGroupProps()} width='fit-content'>
            {headerGroup.headers.map(column => (
              <Box
                fontSize='0.875rem'
                bgColor={colorScheme}
                color='#fff'
                _first={{
                  borderTopLeftRadius: '0.5rem',
                  pl: '1.6rem',
                  pr: '1rem',
                }}
                _last={{
                  borderTopRightRadius: '0.5rem',
                  pr: '1.6rem',
                }}
                px='0.5rem'
                py='1rem'
                {...column.getHeaderProps({
                  // @ts-ignore
                  className: column.className,
                })}
              >
                {column.render('Header')}
              </Box>
            ))}
          </Flex>
        ))}
      </Box>
      <Box {...getTableBodyProps()} position='relative' width='fit-content'>
        {loading ? (
          <LoadingSpinner />
        ) : (
          <>
            {rows.map((row, rowIndex) => {
              const isLastRow = rowIndex === rows.length - 1
              prepareRow(row)
              return (
                <Box {...row.getRowProps()} width='fit-content'>
                  {row.cells.map(cell => {
                    return (
                      <Box
                        _first={{
                          borderBottomLeftRadius: isLastRow ? '0.5rem' : '0',
                          pl: '1.6rem',
                          pr: '1rem',
                        }}
                        _last={{
                          borderBottomRightRadius: isLastRow ? '0.5rem' : '0',
                          pr: '1.6rem',
                        }}
                        bgColor={row.depth === 1 ? '#ebebeb' : 'tBody'}
                        px='0.5rem'
                        py='1rem'
                        borderY={row.depth === 0 ? '0.6px solid' : ''}
                        borderColor='gray.200'
                        {...cell.getCellProps({
                          // @ts-ignore
                          className: cell.column.className,
                        })}
                      >
                        <Box h='100%' textStyle='tableRow'>
                          {cell.render('Cell')}
                        </Box>
                      </Box>
                    )
                  })}
                </Box>
              )
            })}
          </>
        )}
      </Box>
    </Box>
  )
}

export default CollapsibleRowTable

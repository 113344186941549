import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'

import { Layout } from 'components/Admin'
import { adminRoutes } from 'configs/adminRoutes'
import { useProfile } from 'hooks'
import { SidebarRoute } from 'types'

const getRoutes = (
  routes: SidebarRoute[],
  replacePath = '/admin'
): React.ReactNode => {
  return routes.map(route => {
    if (!route.children) {
      return (
        <Route
          key={route.link}
          path={route.link.replace(replacePath, '')}
          element={route.element || <Layout>Hello from {route.name}</Layout>}
        />
      )
    }

    return (
      <Route key={route.link} path={route.link.replace(replacePath, '')}>
        <Route
          path='/'
          element={<Navigate replace to={route.children[0].link} />}
        />
        {getRoutes(route.children, route.link)}
      </Route>
    )
  })
}

interface AdminRoutesProps {
  isMstAdmin: boolean
}

const AdminRoutes = ({ isMstAdmin }: AdminRoutesProps) => {
  useProfile()

  return (
    <Routes>
      <Route path='/admin'>
        <Route
          path='/'
          element={<Navigate replace to='/admin/user-management/learner' />}
        />
        {getRoutes(adminRoutes(isMstAdmin))}
      </Route>
      <Route path='/*' element={<Navigate replace to='/admin' />} />
    </Routes>
  )
}

export default AdminRoutes

import React, { useCallback, useMemo } from 'react'

import { Box, SimpleGrid, Text } from '@chakra-ui/react'

import EnterpriseCentreSection from './CentreSection'
import { LoadingSpinner } from 'components'
import storageKey from 'configs/storageKey'
import { useLanguages } from 'hooks'
import { EnterpriseDetail } from 'types'
import { getData } from 'utils/helper'

interface EnterpriseAboutProps {
  data: EnterpriseDetail
}

const EnterpriseAbout: React.FC<EnterpriseAboutProps> = ({ data }) => {
  const { _t } = useLanguages()

  const isGuest = getData(storageKey.loginType) === 'guest'

  const renderItem = useCallback(
    (label, value, capitalize = false, preserveLines = false) => {
      return (
        <Box>
          <Text fontSize='0.75rem' color='gray.300' textTransform='uppercase'>
            {label}
          </Text>
          <Text
            {...(capitalize ? { textTransform: 'capitalize' } : {})}
            {...(preserveLines ? { whiteSpace: 'pre-wrap' } : {})}
          >
            {value || '-'}
          </Text>
        </Box>
      )
    },
    []
  )

  const enterpriseAddress = useMemo(() => {
    const address = []
    if (data?.address1) {
      address.push(data.address1)
    }
    if (data?.address2) {
      address.push(data.address2)
    }
    if (data?.state) {
      address.push(data.state)
    }
    if (data?.country) {
      address.push(data.country)
    }
    return address.join(', ')
  }, [data])

  if (!data) {
    return <LoadingSpinner />
  }

  return (
    <Box>
      <Box>
        <SimpleGrid columns={{ base: 1, lg: 2 }} spacingY='1.25rem'>
          {!isGuest && renderItem(_t('enterprise.profile.email'), data?.email)}
          {!isGuest &&
            renderItem(_t('enterprise.profile.contact'), data?.contactNumber)}
          {renderItem(_t('enterprise.profile.address'), enterpriseAddress)}
        </SimpleGrid>
      </Box>

      <EnterpriseCentreSection data={data} />
    </Box>
  )
}

export default EnterpriseAbout

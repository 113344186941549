import React, { useEffect, useState } from 'react'

import { Box } from '@chakra-ui/react'

export const ImageWrapper = ({ src = '', defaultSrc = '', ...props }) => {
  const [imgSrc, setImgSrc] = useState(src)

  const handleError = () => {
    setImgSrc(defaultSrc)
  }

  useEffect(() => {
    setImgSrc(src)
  }, [src])

  return <Box as='img' src={imgSrc} onError={handleError} {...props} />
}

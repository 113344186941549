import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import Select, { Props as SelectProps, StylesConfig } from 'react-select'

import { Box, BoxProps, Text } from '@chakra-ui/react'
import theme from 'customTheme'
import qs from 'query-string'

import { useSearch } from 'hooks'

export type Option = {
  label: string
  value: string
  [key: string]: string
}

interface CustomLoadMoreSelectProps extends SelectProps {
  label?: string
  error?: string
  variant?: 'flushed' | 'outline' | 'solid' | 'transparent'
  optionUrl?: string
  defaultOptions?: Option[]
  formatOption?: (data: unknown[]) => Option[]
  labelProps?: BoxProps
  containerProps?: BoxProps
  isDisabled?: boolean
  placeholder?: string
  menuHeight?: string
  fontSize?: string
  needToLoadMore?: boolean
}

interface CustomStyleParams {
  menuHeight?: string
  fontSize?: string
}

export const customStyles: StylesConfig = {
  control: base => ({
    ...base,
    height: '2.5rem',
    minHeight: '2.5rem',
    backgroundColor: 'transparent',
    border: 0,
    borderRadius: 0,
    borderBottom: '1px solid #828282',
    boxShadow: 'none',
    '&:hover': {
      borderColor: theme.colors.primary,
    },
  }),
  valueContainer: (base, _props) => ({
    ...base,
    padding: 0,
  }),
  singleValue: (base, props) => ({
    ...base,
    margin: 0,
    fontSize: props.selectProps?.selectProps?.fontSize || '0.875rem',
  }),
  menuList: (base, props) => ({
    ...base,
    height: props.selectProps?.selectProps?.menuHeight || '8.5rem',
    fontSize: props.selectProps?.selectProps?.fontSize || '0.875rem',
  }),
  placeholder: (base, props) => ({
    ...base,
    margin: 0,
    fontSize: props.selectProps?.selectProps?.fontSize || '0.875rem',
  }),
  indicatorSeparator: () => ({
    width: 0,
  }),
}

export const solidStyles: StylesConfig = {
  control: base => ({
    ...base,
    height: '2.5rem',
    minHeight: '2.5rem',
    backgroundColor: theme.colors.primary,
    borderRadius: '0.5rem',
    borderColor: theme.colors.primary,
    '&:hover': {
      borderColor: theme.colors.primary,
    },
  }),
  valueContainer: (base, _props) => ({
    ...base,
    padding: 0,
    paddingLeft: '0.5rem',
  }),
  singleValue: base => ({
    ...base,
    margin: 0,
    color: '#fff',
  }),
  menuList: (base, props) => ({
    ...base,
    height: props.selectProps?.selectProps?.menuHeight || '10rem',
  }),
  placeholder: (base, props) => ({
    ...base,
    margin: 0,
    fontSize: props.selectProps?.selectProps?.fontSize || '0.875rem',
    color: '#fff',
  }),
  input: (_, props) => ({
    fontSize: props.selectProps?.selectProps?.fontSize || '0.875rem',
    color: '#fff',
  }),
  indicatorSeparator: () => ({
    width: 0,
  }),
  dropdownIndicator: () => ({
    paddingRight: '0.5rem',
    paddingBottom: '0.125rem',
    color: '#fff',
  }),
}

export const outlineStyles: StylesConfig = {
  control: base => ({
    ...base,
    height: '2.5rem',
    minHeight: '2.5rem',
    borderRadius: '0.5rem',
    '&:hover': {
      borderColor: theme.colors.primary,
    },
  }),
  valueContainer: (base, _props) => ({
    ...base,
    padding: 0,
    paddingLeft: '0.5rem',
  }),
  singleValue: base => ({
    ...base,
    margin: 0,
    color: '#000',
  }),
  menuList: (base, props) => ({
    ...base,
    height: props.selectProps?.selectProps?.menuHeight || '10rem',
  }),
  placeholder: (base, props) => ({
    ...base,
    margin: 0,
    fontSize: props.selectProps?.selectProps?.fontSize || '0.875rem',
    color: '#828282',
  }),
  input: (_, props) => ({
    fontSize: props.selectProps?.selectProps?.fontSize || '0.875rem',
    color: '#000',
  }),
  indicatorSeparator: () => ({
    width: 0,
  }),
  dropdownIndicator: () => ({
    paddingRight: '0.5rem',
    paddingBottom: '0.125rem',
    color: '#000',
  }),
}

export const transparentStyles: StylesConfig = {
  control: base => ({
    ...base,
    height: '2.5rem',
    minHeight: '2.5rem',
    backgroundColor: 'transparent',
    border: 0,
    borderRadius: 0,
    boxShadow: 'none',
  }),
  valueContainer: (base, _props) => ({
    ...base,
    padding: 0,
  }),
  singleValue: (base, props) => ({
    ...base,
    margin: 0,
    fontSize: props.selectProps?.selectProps?.fontSize || '0.875rem',
  }),
  menuList: (base, props) => ({
    ...base,
    height: props.selectProps?.selectProps?.menuHeight || '8.5rem',
    fontSize: props.selectProps?.selectProps?.fontSize || '0.875rem',
  }),
  placeholder: (base, props) => ({
    ...base,
    margin: 0,
    fontSize: props.selectProps?.selectProps?.fontSize || '0.875rem',
  }),
  indicatorSeparator: () => ({
    width: 0,
  }),
}

const CustomLoadMoreSelect = React.forwardRef<
  HTMLSelectElement,
  CustomLoadMoreSelectProps
>(
  (
    {
      label,
      error,
      isDisabled = false,
      placeholder = 'Select...',
      variant = 'flushed',
      defaultOptions = [],
      optionUrl,
      formatOption,
      labelProps,
      containerProps,
      defaultValue,
      _menuHeight,
      _fontSize,
      _needToLoadMore = false,
      ...selectProps
    },
    _
  ) => {
    const [options, setOptions] = useState<Option[]>(defaultOptions)
    const { search, handleSearch } = useSearch()
    const [loadingPage, setLoadingPage] = useState<number>(
      Number(qs.parse(optionUrl || '')?.page) || 1
    )

    const styles = useMemo(() => {
      switch (variant) {
        case 'flushed':
          return customStyles

        case 'outline':
          return outlineStyles

        case 'solid':
          return solidStyles

        case 'transparent':
          return transparentStyles

        default:
          return selectProps.styles || {}
      }
    }, [variant, selectProps.styles])

    const optionQueryUrl = useMemo(() => {
      if (!optionUrl) {
        return ''
      }

      const parsedUrl = qs.parse(optionUrl.split('?')[1])
      parsedUrl.page = `${loadingPage}`
      const newOptionUrl = qs.stringifyUrl({ url: optionUrl, query: parsedUrl })

      if (!search) {
        return newOptionUrl
      }

      return `${newOptionUrl}${
        newOptionUrl.includes('?') ? '&' : '?'
      }search=${search}&searchBy=all`
    }, [loadingPage, optionUrl, search])

    const { data, isLoading } = useQuery<{
      data: unknown[]
      meta: {
        total: number
      }
    }>(optionQueryUrl, {
      enabled: !!optionQueryUrl,
    })

    useEffect(() => {
      if (data && formatOption) {
        const allResults = formatOption(data?.data)

        if (loadingPage > 1) {
          setOptions(oldData => [...oldData, ...allResults])
        } else {
          setOptions([...allResults])
        }
      }
    }, [data, formatOption, loadingPage])

    useEffect(() => {
      setLoadingPage(1)
    }, [search])

    const handleLoadMoreOption = useCallback(() => {
      if (data?.meta.total && optionUrl) {
        const limit = Number(qs.parse(optionUrl).limit) || 10
        const lastPage = Math.ceil(data.meta.total / limit)

        if (!isLoading && loadingPage <= lastPage) {
          setLoadingPage(oldPage => oldPage + 1)
        }
      }
    }, [data, isLoading, loadingPage, optionUrl])

    return (
      <Box minW='5.5rem' opacity={isDisabled ? 0.3 : 1} {...containerProps}>
        {label && (
          <Box as='p' fontSize='0.875rem' fontWeight='600' {...labelProps}>
            {label}
          </Box>
        )}
        <Select
          styles={styles}
          options={options}
          placeholder={placeholder}
          isDisabled={isDisabled}
          isLoading={isLoading}
          onInputChange={handleSearch}
          defaultValue={defaultValue}
          onMenuScrollToBottom={handleLoadMoreOption}
          {...selectProps}
        />
        {error && <Text textStyle='error'>{error}</Text>}
      </Box>
    )
  }
)

export default CustomLoadMoreSelect

import Axios from 'axios'

import { adminPath } from 'configs/apiPath'
import { CombinationData, SubjectCombination } from 'types'

export const createCombination = (data: CombinationData) => {
  return Axios.post<{
    data: Pick<SubjectCombination, 'id'>
  }>(adminPath.subjectCombination.single(), data)
}

export const editCombination = ({
  id,
  data,
}: {
  id: string
  data: CombinationData
}) => {
  return Axios.put(adminPath.subjectCombination.single(id), data)
}

export const deleteCombination = (id: string) => {
  return Axios.delete(adminPath.subjectCombination.single(id))
}

import React from 'react'
import { Control, Controller } from 'react-hook-form'

import { Box, Flex, SimpleGrid, Switch, Text } from '@chakra-ui/react'

import { Input, Select, Textarea } from 'components'
import { language } from 'configs/apiPath'
import { useLanguages } from 'hooks'
import { formatOption } from 'utils/parser'

interface DetailEditSectionProps {
  control: Control<any>
  isAllowUpdateStatus: boolean
}

const DetailEditSection: React.FC<DetailEditSectionProps> = ({
  control,
  isAllowUpdateStatus,
}) => {
  const { _t } = useLanguages()

  return (
    <Box
      bg='white'
      py='1.5rem'
      px='2rem'
      boxShadow='lg'
      rounded='lg'
      mt='1.5rem'
    >
      <SimpleGrid mt='1rem' columns={[1, 1, 2, 2]} spacing='2rem'>
        <Controller
          as={Input}
          control={control}
          name='name'
          aria-label='Name'
          label={_t('centre.profile.name')}
          placeholder={_t('centre.profile.placeholder_name')}
        />
        {isAllowUpdateStatus && (
          <Flex justify='space-between' align='center'>
            <Text width='100%' textStyle='tableRow' fontWeight='600'>
              {_t('centre.profile.account_status')}
            </Text>
            <Controller
              control={control}
              name='active'
              render={({ onChange, value }) => (
                <Switch
                  onChange={e => onChange(e.target.checked)}
                  isChecked={value}
                />
              )}
            />
          </Flex>
        )}
      </SimpleGrid>
      <SimpleGrid mt='2rem' columns={[1, 1, 2, 2]} spacing='2rem'>
        <Controller
          as={Input}
          type='email'
          control={control}
          name='email'
          label={_t('centre.profile.email')}
          placeholder={_t('centre.profile.placeholder_email')}
        />
        <Controller
          as={Input}
          control={control}
          name='contactNumber'
          label={_t('centre.profile.contact')}
          placeholder={_t('centre.profile.placeholder_contact')}
          optional
        />
        <Controller
          as={<Select isMulti closeMenuOnSelect={false} />}
          control={control}
          name='languages'
          label={_t('centre.profile.teaching_language')}
          optionUrl={language.list({ page: 1, limit: 30 })}
          formatOption={formatOption}
        />
        <Controller
          as={Input}
          control={control}
          name='address'
          label={_t('centre.profile.address')}
          placeholder={_t('centre.profile.placeholder_address')}
        />
        <Controller
          as={Textarea}
          control={control}
          name='description'
          label={_t('centre.profile.description')}
          placeholder={_t('centre.profile.placeholder_description')}
        />
        <Controller
          as={Input}
          control={control}
          name='youtubeLink'
          label={_t('centre.profile.youtube_link')}
          placeholder={_t('centre.profile.placeholder_youtube_link')}
        />
      </SimpleGrid>
    </Box>
  )
}

export default DetailEditSection

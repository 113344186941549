import React from 'react'

import { Box } from '@chakra-ui/react'

import CompletedRow from './CompletedRow'
import CurrentRow from './CurrentRow'
import EmptyRow from './EmptyRow'
import { maxChallenges } from 'configs/game'

interface BoardProps {
  guesses: string[]
  currentGuess: string
  isRevealing?: boolean
}

const Board: React.FC<BoardProps> = ({
  guesses,
  currentGuess,
  isRevealing,
}) => {
  const empties =
    guesses.length < maxChallenges - 1
      ? Array.from(Array(maxChallenges - 1 - guesses.length))
      : []

  return (
    <Box pb='3rem'>
      {guesses.map((guess, i) => (
        <CompletedRow
          key={i}
          guess={guess}
          isRevealing={isRevealing && guesses.length - 1 === i}
        />
      ))}
      {guesses.length < maxChallenges && <CurrentRow guess={currentGuess} />}
      {empties.map((_, i) => (
        <EmptyRow key={i} />
      ))}
    </Box>
  )
}

export default Board

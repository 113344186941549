import React, { useCallback, useMemo } from 'react'

import { Badge, Flex, Text } from '@chakra-ui/react'

import { useLanguages } from 'hooks'
import { formatAmount } from 'utils/parser'

interface PricingProps {
  price: number
  usualPrice?: number
  size?: string
}

const Pricing: React.FC<PricingProps> = ({
  price,
  usualPrice,
  size = 'md',
}) => {
  const { _t } = useLanguages()

  const isValidUsualPrice = useMemo(
    () => typeof usualPrice === 'number' && !!usualPrice && usualPrice > price,
    [price, usualPrice]
  )

  const getFontSize = useCallback(
    (type: string) => {
      if (size === 'lg') {
        return type === 'badge' ? { base: '1rem', md: '1.2rem' } : '1rem'
      }
      return type === 'badge' ? { base: '0.75rem', md: '1rem' } : '0.75rem'
    },
    [size]
  )

  return (
    <Flex direction='column' justify='flex-end' align='flex-end'>
      <Flex align='center' flexWrap='nowrap'>
        <Text
          fontSize={getFontSize('promo')}
          textDecoration='line-through'
          color='gray.300'
        >
          {usualPrice && isValidUsualPrice && `$${formatAmount(usualPrice)}`}
        </Text>
        <Text
          paddingLeft={usualPrice ? '2px' : '0'}
          fontSize={getFontSize('promo')}
          color='gray.800'
        >
          {usualPrice &&
            isValidUsualPrice &&
            ` -${Math.round(((usualPrice - price) * 100) / usualPrice)}%`}
        </Text>
      </Flex>
      <Badge
        marginLeft={4}
        px={2}
        fontSize={getFontSize('badge')}
        fontWeight='400'
        background='secondaryLightBlue'
        textTransform='uppercase'
      >
        {price ? `$${formatAmount(price)}` : _t('common.free')}
      </Badge>
    </Flex>
  )
}

export default Pricing

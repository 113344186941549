import React, { useCallback, useMemo } from 'react'

import { Box, Text } from '@chakra-ui/react'

import { Map } from 'components'
import { useLanguages } from 'hooks'
import { MeetingLocation, MeetingType } from 'types'
import { isLessonClassType } from 'utils/helper'

interface LocationSectionProps {
  classType?: string
  meetingType?: string
  meetingLocation?: MeetingLocation
}

const LocationSection: React.FC<LocationSectionProps> = ({
  classType,
  meetingType,
  meetingLocation,
}) => {
  const { _t } = useLanguages()
  const isLessonClassMemo = useMemo(() => isLessonClassType(classType), [
    classType,
  ])
  const renderMeeting = useCallback(() => {
    const message = isLessonClassMemo
      ? _t('product.lesson.online_description')
      : _t('product.class.online_description')
    return (
      <Text fontSize='0.75rem' whiteSpace='pre-wrap'>
        {meetingType === MeetingType.PHYSICAL
          ? meetingLocation?.address
          : message}
      </Text>
    )
  }, [_t, isLessonClassMemo, meetingLocation, meetingType])

  return (
    <Box my='2rem'>
      <Text as='h2' mb='1rem'>
        {_t('product.class.location_title')}
      </Text>
      {renderMeeting()}
      {meetingLocation?.lat && meetingLocation?.lng && (
        <Box mt='1rem'>
          <Map
            lat={meetingLocation?.lat}
            lng={meetingLocation?.lng}
            w={{ base: 'full', md: '30rem' }}
            h='16.875rem'
          />
        </Box>
      )}
    </Box>
  )
}

export default LocationSection

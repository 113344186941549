import Axios from 'axios'

import { orderPath } from 'configs/apiPath'
import {
  OrderAdminPurchaseRequest,
  OrderPaymentRequest,
  OrderPurchaseRequest,
} from 'types'

export const purchase = async (data: OrderPurchaseRequest) => {
  return Axios.post(orderPath.purchase(), data)
}

export const adminPurchase = async (data: OrderAdminPurchaseRequest) => {
  return Axios.post(orderPath.adminPurchase(), data)
}

export const payment = async (data: OrderPaymentRequest) => {
  return Axios.post(orderPath.payment(), data)
}

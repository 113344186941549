import React, { useCallback, useMemo } from 'react'
import { Link } from 'react-router-dom'

import { Avatar, Box, Flex, Text } from '@chakra-ui/react'
import format from 'date-fns/format'

import { Pricing } from '../components'
import { ImageWrapper, Rating } from 'components'
import { useLanguages } from 'hooks'
import { SessionSearchResult, UserInSearch } from 'types'
import { isContentClassType, isLessonClassType } from 'utils/helper'
import { getDisplayName, parseMeetingTypes } from 'utils/parser'

import { ReactComponent as CoordinateIcon } from 'assets/images/coordinate_icon.svg'
import bg from 'assets/images/mapping.png'

interface ClassCardProps {
  data: SessionSearchResult
}

const ClassCard: React.FC<ClassCardProps> = ({ data }) => {
  const { _t } = useLanguages()
  const { user = {} as UserInSearch } = data

  const isLesson = useMemo(() => isLessonClassType(data.type), [data])
  const isContent = useMemo(() => isContentClassType(data.type), [data])
  const isMultiSession = useMemo(() => {
    const { sessions } = data
    if (sessions) {
      return sessions.length > 1 ? true : false
    }
    return false
  }, [data])

  const meetingType = useMemo(() => {
    const sessions = data.sessions
    if (!sessions || sessions?.length === 0) {
      return ''
    }
    return parseMeetingTypes(sessions)
  }, [data])

  const getLink = useCallback(() => {
    const { id } = data
    if (isLesson) {
      return `/product/lesson/${id}`
    } else if (isContent) {
      return `/product/content/${id}`
    }
    return `/product/class/${id}`
  }, [data, isContent, isLesson])

  const getCentre = useCallback(() => {
    const { centers } = data
    if (centers && centers.length > 0) {
      const centre = centers[0]
      return centre.name
    }
    return ''
  }, [data])

  const getStartDate = useCallback(() => {
    const sessions = data.sessions

    if (!sessions || sessions?.length === 0) {
      return ''
    }

    return sessions[0].startDateTime
      ? format(new Date(sessions[0].startDateTime), 'dd MMM yyyy, eee')
      : 'n/a'
  }, [data.sessions])

  const getTime = useCallback(() => {
    const sessions = data.sessions

    if (!sessions || sessions?.length === 0) {
      return ''
    }

    const formatStr = 'h:mma'

    const start = sessions[0].startDateTime
      ? format(new Date(sessions[0].startDateTime), formatStr)
      : 'n/a'
    const end = sessions[0].endDateTime
      ? format(new Date(sessions[0].endDateTime), formatStr)
      : 'n/a'

    return `${start} - ${end}`
  }, [data.sessions])

  return (
    <Flex
      h={{ base: '135px', md: '360px' }}
      w={{ base: '92%', md: '250px' }}
      minW={{ base: '92%', md: '250px' }}
      direction='column'
      mx='0.7rem'
      mt='0.5rem'
      mb='0.7rem'
      boxShadow='lg'
      rounded='lg'
      position='relative'
      bg='white'
    >
      <ImageWrapper
        h={{ base: '0', md: '135px' }}
        w='full'
        roundedTop='lg'
        bg='primary'
        src={data.banner || bg}
        key={data.banner || 'default-banner'}
        objectFit={'cover'}
      />
      <Flex justify='flex-end' mt={{ base: '0.5rem', md: -3 }}>
        {isMultiSession && (
          <Text
            fontSize='0.75rem'
            color='white'
            bg={'#EB975E'}
            px={3}
            py={1}
            position={'absolute'}
          >
            {_t('explore.product.package')}
          </Text>
        )}
        {isLesson && (
          <Text
            fontSize='0.75rem'
            color='white'
            bg={'primary'}
            px={3}
            py={1}
            position={'absolute'}
          >
            {_t('explore.product.lesson')}
          </Text>
        )}
        {isContent && (
          <Text
            fontSize='0.75rem'
            color='white'
            bg={'actionGreen'}
            px={3}
            py={1}
            position={'absolute'}
          >
            {_t('explore.product.content')}
          </Text>
        )}
      </Flex>
      <Flex
        flex='1'
        direction='column'
        px='1rem'
        py={{ base: '0.5rem', md: '1rem' }}
        pt={{ base: '0', md: '1.75rem' }}
      >
        <Flex
          flex='1'
          direction='column'
          justifyContent='flex-start'
          cursor='pointer'
        >
          {!isLesson && !isContent && (
            <Box fontWeight='600' fontSize='0.75rem' color='primary'>
              {!isMultiSession && (
                <Text noOfLines={1} mb='0.1rem'>
                  {`${getStartDate()} | ${getTime()}`}
                </Text>
              )}
              {isMultiSession && (
                <>
                  <Box display={{ base: 'none', md: 'inherit' }}>
                    <Text
                      mb='0.1rem'
                      pr={{ base: '4rem', md: 0 }}
                      noOfLines={1}
                    >
                      {`${getStartDate()} | ${getTime()}`}
                    </Text>
                    <Text mb='0.25rem'>
                      {`${data?.sessions?.length || 0} ${_t(
                        'common.sessions'
                      )}`}
                    </Text>
                  </Box>
                  <Box display={{ base: 'inherit', md: 'none' }}>
                    <Text
                      pr={{ base: '4rem', md: 0 }}
                      mb='0.25rem'
                      noOfLines={1}
                    >
                      {`${data?.sessions?.length || 0} ${_t(
                        'common.sessions'
                      )} | ${getStartDate()} | ${getTime()}`}
                    </Text>
                  </Box>
                </>
              )}
            </Box>
          )}
          <Box
            as={Link}
            to={getLink() || '#'}
            fontSize={{ base: '1rem', md: '0.875rem' }}
            lineHeight={{ base: '1.1rem', md: '1.3rem' }}
            fontWeight='600'
            my={{ base: '0', md: '0.25rem' }}
            pr={isMultiSession || isLesson || isContent ? '4rem' : '0'}
            _hover={{
              color: 'primary',
            }}
            _before={{
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
            }}
            noOfLines={{ base: 2, md: 4 }}
          >
            {data.name || ''}
          </Box>
          {meetingType && (
            <Flex alignItems='center' justify='flex-start' width='100%'>
              <CoordinateIcon height='13px' width='13px' />
              <Text
                fontSize='0.75rem'
                color='gray.300'
                lineHeight='0.875rem'
                m='0.3rem'
                textAlign='left'
              >
                {meetingType}
              </Text>
            </Flex>
          )}
        </Flex>
        <Flex direction='row' justify='space-between'>
          <Box
            position='relative'
            alignSelf='flex-end'
            zIndex='1'
            cursor={'pointer'}
          >
            <Flex direction='row' alignItems='center'>
              <Avatar
                size='sm'
                name={getDisplayName(user.firstName, user.lastName)}
                src={user.avatar}
                key={user.avatar || 'default-avatar'}
              />
              <Box flex='1' ml='0.75rem'>
                <Flex direction='column' justifyContent='center'>
                  <Box>
                    {user.rating && (
                      <Rating rate={user.rating} size={'0.5rem'} />
                    )}
                  </Box>
                  <Box
                    as={Link}
                    to={`/instructors/${user.id}`}
                    fontSize='0.75rem'
                    lineHeight='0.825rem'
                    fontWeight='600'
                    _hover={{
                      color: 'primary',
                    }}
                    _before={{
                      content: '""',
                      display: 'block',
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                      cursor: 'pointer',
                    }}
                    noOfLines={1}
                  >
                    {getDisplayName(user.firstName, user.lastName)}
                  </Box>
                  <Text
                    fontSize='0.75rem'
                    color='gray.500'
                    lineHeight='0.875rem'
                    fontWeight='500'
                    noOfLines={1}
                  >
                    {getCentre()}
                  </Text>
                </Flex>
              </Box>
            </Flex>
          </Box>
          <Pricing price={data?.price || 0} usualPrice={data?.usualPrice} />
        </Flex>
      </Flex>
    </Flex>
  )
}

export default ClassCard

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router'

import { useAtom } from 'jotai'

import EditAdminProfileContainer from '../../containers/EditAdminProfile'
import { EditCentreAdminForm } from '../../containers/forms'
import { updateProfile } from 'apis/profile'
import { getUploadAvatarUrl, uploadAvatar } from 'apis/user'
import { AvatarRef } from 'components'
import { useLanguages, useLoading, useToast } from 'hooks'
import { useUpdateUser, useUserDetail } from 'queries/user'
import { userProfileAtom } from 'store/authAtom'
import { isMSTAdmin } from 'utils/helper'
import { getErrorMessage } from 'utils/parser'

interface EditCentreAdminContainerProps {
  enterpriseId?: string
  centerId?: string
  handleToBack: () => void
  selfUpdate?: boolean
  isOnProfileAdmin?: boolean
}

const EditCentreAdminContainer = ({
  enterpriseId,
  centerId,
  handleToBack,
  selfUpdate = false,
  isOnProfileAdmin = false,
}: EditCentreAdminContainerProps) => {
  const { _t } = useLanguages()
  const params = useParams()
  const { setLoading } = useLoading()
  const [error, setError] = useState<string>()
  const toast = useToast()
  const userId = useMemo(() => params.id, [params])
  const { data: userProfile, isLoading } = useUserDetail(userId)

  const userDetail = useMemo(() => userProfile?.data || undefined, [
    userProfile,
  ])

  const { mutate: updateUser, isSuccess } = useUpdateUser(userId)

  const [selfProfile] = useAtom(userProfileAtom)
  const isKnovoAdmin = isMSTAdmin(selfProfile)

  const {
    control,
    formState: { errors: formErrors },
    handleSubmit,
    watch: profileWatch,
    reset,
  } = useForm<EditCentreAdminForm>({
    defaultValues: {
      ...({} as EditCentreAdminForm),
      accountStatus: userDetail?.accountStatus || false,
    },
  })

  // set init data
  useEffect(() => {
    if (userProfile?.data) {
      const initData = {
        firstName: userDetail?.firstName || '',
        lastName: userDetail?.lastName || '',
        email: userDetail?.email || '',
        enterprise: userDetail?.enterprises
          ? {
              value: userDetail?.enterprises?.[0].id || '',
              label: userDetail?.enterprises?.[0].name || '',
            }
          : undefined,
        centre: {
          value: userDetail?.centers?.[0].id || '',
          label: userDetail?.centers?.[0].name || '',
        },
        recoveryEmail: userDetail?.recoveryEmail || '',
        address1: userDetail?.address1 || '',
        address2: userDetail?.address2 || '',
        city: userDetail?.city || '',
        state: userDetail?.state || '',
        country: userDetail?.country || '',
        zipCode: userDetail?.zipCode || '',
        contactNumber: userDetail?.contactNumber || '',
        accountStatus: userDetail?.accountStatus || false,
      }

      reset(initData)
    }
  }, [reset, userDetail, userProfile])

  const avatarRef = useRef<AvatarRef>()

  const handleUpdate = useCallback(
    async (values: EditCentreAdminForm) => {
      try {
        setLoading(true)
        const updatedAvatar = avatarRef.current?.getUpdatedAvatar?.()
        if (updatedAvatar?.avatar) {
          const { avatar, fileType } = updatedAvatar
          const { data } = await getUploadAvatarUrl(userId, fileType as string)
          const { fields, url } = data.data

          const formData = new FormData()
          Object.entries(fields).forEach(([key, value]) => {
            formData.append(key, value)
          }, [])
          formData.append('file', avatar as File)

          await uploadAvatar(url, formData)
        }

        const formattedDataUser = {
          ...values,
          centerId: centerId || values.centre?.value,
          birthday: values.birthday
            ? new Date(values.birthday).getTime()
            : undefined,
        }
        delete formattedDataUser.centre
        delete formattedDataUser.enterprise

        if (!selfUpdate) {
          updateUser({
            userId,
            data: formattedDataUser,
          })
        } else {
          await updateProfile(userId, formattedDataUser)
          window.location.reload()
        }
      } catch (err) {
        setError(getErrorMessage(err))
      }
    },
    [setLoading, centerId, updateUser, userId, selfUpdate]
  )

  useEffect(() => {
    if (isSuccess) {
      setLoading(false)
    }
  }, [setLoading, isSuccess])

  useEffect(() => {
    if (error) {
      setLoading(false)
      toast({
        title: _t('message.error.title'),
        description: error,
        status: 'error',
      })
      setError('')
    }
  }, [_t, error, setLoading, toast])

  return (
    <EditAdminProfileContainer
      enterpriseId={enterpriseId}
      toBackLabel={
        isOnProfileAdmin ? undefined : _t('user_management.centre_admin.title')
      }
      title={_t('user_management.centre_admin.edit_title')}
      handleToBack={handleToBack}
      handleSubmit={handleSubmit}
      handleSubmitCall={handleUpdate}
      profileWatch={profileWatch}
      isLoading={isLoading}
      avatarRef={avatarRef}
      userDetail={userDetail}
      control={control}
      formErrors={formErrors}
      isEdit
      showEnterprise={isKnovoAdmin}
      showCentre={!selfUpdate}
    />
  )
}

export default EditCentreAdminContainer

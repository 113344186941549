import React, { lazy, Suspense, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { LoadingSpinner } from 'components'
import { useClassDetail } from 'queries/class'
import { UserRoleType } from 'types'

const ClassEdit = lazy(() => import('../../Product/Content/Edit'))

interface EditContentContainerProps {
  role: UserRoleType
  handleClose: () => void
}

const EditContentContainer = ({
  role,
  handleClose,
}: EditContentContainerProps) => {
  const params = useParams()
  const classId = params.id

  const { data: classDetail } = useClassDetail({ classId })

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <Suspense fallback={<LoadingSpinner />}>
      {classDetail?.data ? (
        <ClassEdit
          role={role}
          createdData={classDetail.data}
          handleClose={handleClose}
        />
      ) : (
        <LoadingSpinner />
      )}
    </Suspense>
  )
}

export default EditContentContainer

import React, { useCallback, useMemo, useState } from 'react'

import { Box, Flex, useDisclosure } from '@chakra-ui/react'

import ApprovalDataTable from './ApprovalDataTable'
import DataTableHeader from './containers/DataTableHeader'
import FilterModal from './containers/FilterModal'
import { FilterForm, searchOptions } from './form'
import { HeadingSection, Option, Paginate } from 'components'
import {
  useCenterId,
  useCurrentPage,
  useLanguages,
  useUserId,
  useUtilsForEnterpriseId,
} from 'hooks'
import { useRefundApproval } from 'queries/refund'
import { useTransactionApprovals } from 'queries/transaction'
import { ApiUserType, UserRole, UserRoleType } from 'types'

const limit = 10

interface TransactionApprovalManagementProps {
  role: UserRoleType
}

const TransactionApprovalManagement: React.FC<TransactionApprovalManagementProps> = ({
  role,
}) => {
  const { _t } = useLanguages()
  const userId = useUserId()
  const centerId = useCenterId()
  const { enterpriseId } = useUtilsForEnterpriseId()
  const currentPage = useCurrentPage()
  const [search, setSearch] = useState<string>('')
  const [searchBy, setSearchBy] = useState<Option>(searchOptions[0])
  const [orderBy, setOrderBy] = useState<string>('createdAt')
  const [filters, setFilters] = useState<FilterForm>({})
  const {
    isOpen: isOpenFilter,
    onOpen: onOpenFilter,
    onClose: onCloseFilter,
  } = useDisclosure()

  const type = useMemo(() => {
    switch (role) {
      case UserRole.MSTAdmin:
      case UserRole.MSTSuperAdmin:
        return { user: 'admin', id: userId }
      case UserRole.EnterpriseAdmin:
      case UserRole.EnterpriseSuperAdmin:
        return { user: 'enterprise', id: enterpriseId }
      case UserRole.CenterAdmin:
        return { user: 'center', id: centerId }
      default:
        return { user: 'user', id: userId }
    }
  }, [role, userId, enterpriseId, centerId])

  const {
    data: approvals,
    isLoading: isApprovalLoading,
  } = useTransactionApprovals(
    {
      type: type.user as ApiUserType,
      id: type.id,
    },
    {
      page: currentPage,
      limit,
      order: 'desc',
      orderBy,
      ...(search && { search }),
      ...(search && {
        searchBy: searchBy.value !== 'all' ? searchBy.value : undefined,
      }),
      ...filters,
    }
  )

  const { mutate: refundApproval } = useRefundApproval(
    {
      type: type.user as ApiUserType,
      id: type.id,
    },
    {
      page: currentPage,
      search,
      limit,
    }
  )

  const handleSearch = useCallback((value: string) => {
    setSearch(value)
  }, [])
  const handleSearchBy = useCallback((value: Option) => {
    setSearchBy(value)
  }, [])
  const handleSort = useCallback((sortBy: string, onCloseSort: () => void) => {
    setOrderBy(sortBy)
    onCloseSort()
  }, [])
  const handleFilter = useCallback((options: FilterForm) => {
    setFilters(options)
  }, [])

  return (
    <Box>
      <HeadingSection
        title={_t('transaction_approval.management.title')}
        description={_t('transaction_approval.management.description')}
        isAdminStyle
      />
      <Box p='2rem 5rem 0'>
        <DataTableHeader
          count={approvals?.meta.total || 0}
          orderBy={orderBy}
          handleOpenFilter={onOpenFilter}
          handleSearch={handleSearch}
          handleSearchBy={handleSearchBy}
          handleSort={handleSort}
        />
        <Box mt='1.5rem'>
          <ApprovalDataTable
            data={approvals?.data || []}
            isLoading={isApprovalLoading}
            refundApproval={refundApproval}
          />
        </Box>
        <Flex justifyContent='center' my='1.5rem'>
          {!!approvals?.meta.total && !isApprovalLoading && (
            <Paginate
              currentPage={currentPage}
              perPage={limit}
              totalItems={approvals?.meta.total || 0}
            />
          )}
        </Flex>
      </Box>
      <FilterModal
        isOpen={isOpenFilter}
        onClose={onCloseFilter}
        onSubmit={handleFilter}
      />
    </Box>
  )
}

export default TransactionApprovalManagement

import React, { useCallback, useEffect, useState } from 'react'

import { Box, Flex, Grid, Text } from '@chakra-ui/react'

import { LoadingSpinner, Visible } from 'components'
import { UserDisplayCard } from 'shared/containers'
import { useLanguages } from 'hooks'
import { useCenterUsers } from 'queries/user'
import { Centre, UserProfile } from 'types'

interface CentreConnectionProps {
  data: Centre
}

const CentreConnection: React.FC<CentreConnectionProps> = ({ data }) => {
  const { _t } = useLanguages()

  const [list, setList] = useState<UserProfile[]>([])
  const [total, setTotal] = useState<number>(0)
  const [limit] = useState(6)
  const [page, setPage] = useState(1)

  const { data: connectedUsers, isLoading } = useCenterUsers({
    page,
    limit,
    centerId: data.id || '',
    filterBy: 'student',
  })

  useEffect(() => {
    if (connectedUsers?.data.length) {
      const allResults = connectedUsers?.data || []
      const count = connectedUsers?.meta?.total || 0

      setTotal(count)

      if (page > 1) {
        setList(old => [...old, ...allResults])
      } else {
        setList([...allResults])
      }
    }
  }, [isLoading, connectedUsers, page])

  const handleLoadMore = useCallback(() => {
    const lastPage = Math.ceil(total / limit)

    if (!isLoading && page <= lastPage) {
      setPage(oldPage => oldPage + 1)
    }
  }, [isLoading, limit, page, total])

  return (
    <Flex flexDirection='column'>
      <Box mb='1rem' backgroundColor='#DDF2FF' rounded='lg' p='1rem' w='full'>
        {_t('centre.alert.connection')}
      </Box>
      <Text textStyle='subHeadingModalText' my='1rem'>
        {_t('centre.connection.learner_title')} ({total})
      </Text>
      <Grid gridTemplateColumns='repeat(4,1fr)' gridGap='1.5rem'>
        {list.map(item => <UserDisplayCard key={item.id} data={item} />)}
      </Grid>
      {isLoading && total > limit && <LoadingSpinner />}
      <Visible onVisible={handleLoadMore} />
    </Flex>
  )
}

export default CentreConnection

import { useCallback, useState } from 'react'

import debounce from 'lodash.debounce'

export default (delay = 1000) => {
  const [search, setSearch] = useState('')

  const handleSearch = useCallback(
    debounce((value: string) => {
      if (!value || value.length >= 1) {
        setSearch(value)
      }
    }, delay),
    []
  )

  return { search, handleSearch }
}

import React, { useCallback, useEffect, useState } from 'react'

import { Button, Flex } from '@chakra-ui/react'

import { useLanguages, useLoading } from 'hooks'
import { useAcceptConnection, useDeclineConnection } from 'queries/connection'
import { NotificationActionType } from 'types'

interface ConnectionButtonProps {
  data: any
  userId: string
}

const ConnectionButton: React.FC<ConnectionButtonProps> = ({
  data,
  userId,
}) => {
  const { setLoading } = useLoading()
  const { _t } = useLanguages()
  const [itemHidden, setItemHidden] = useState<Map<string, boolean>>(new Map())
  const [selectedConnection, setSelectedConnection] = useState<string>('')

  const {
    mutate: acceptConnection,
    isSuccess: acceptConnectionIsSuccess,
    isError: acceptConnectionIsError,
  } = useAcceptConnection()
  const {
    mutate: declineConnection,
    isSuccess: declineConnectionIsSuccess,
    isError: declineConnectionIsError,
  } = useDeclineConnection()

  useEffect(() => {
    if (
      (acceptConnectionIsSuccess || declineConnectionIsSuccess) &&
      selectedConnection
    ) {
      setLoading(false)
      setItemHidden(old => {
        old?.set(selectedConnection, true)
        return old
      })
    }

    if (acceptConnectionIsError || declineConnectionIsError) {
      setLoading(false)
    }
  }, [
    acceptConnectionIsSuccess,
    declineConnectionIsSuccess,
    acceptConnectionIsError,
    declineConnectionIsError,
    selectedConnection,
    setLoading,
  ])

  const handleAcceptConnection = useCallback(
    (item: any) => {
      setLoading(true)
      setSelectedConnection(item.connectionId)
      acceptConnection({ userId, requestId: item.connectionId })
    },
    [acceptConnection, setLoading, userId]
  )

  const handleDeclineConnection = useCallback(
    (item: any) => {
      setLoading(true)
      setSelectedConnection(item.connectionId)
      declineConnection({ userId, requestId: item.connectionId })
    },
    [declineConnection, setLoading, userId]
  )

  const isNotJustSelectedAndHidden =
    selectedConnection === data.connectionId
      ? !itemHidden?.has(selectedConnection)
      : true

  if (
    data.action === NotificationActionType.ADD_FRIEND &&
    isNotJustSelectedAndHidden &&
    !data?.isHiddenAccept
  ) {
    return (
      <Flex>
        <Button
          variant='solidPrimary'
          py='1rem'
          px='1.5rem'
          mr='0.5rem'
          onClick={e => {
            e?.stopPropagation?.()
            handleAcceptConnection(data)
          }}
        >
          {_t('notifications.friend_request_accept')}
        </Button>
        <Button
          variant='outlinePrimary'
          py='1rem'
          px='1.5rem'
          onClick={e => {
            e?.stopPropagation?.()
            handleDeclineConnection(data)
          }}
        >
          {_t('notifications.friend_request_decline')}
        </Button>
      </Flex>
    )
  }

  return null
}

export default ConnectionButton

import React from 'react'

import { Box, Flex, useRadio } from '@chakra-ui/react'

const RadioCard = (props: any) => {
  const { getInputProps, getCheckboxProps } = useRadio(props)
  const input = getInputProps()
  const checkbox = getCheckboxProps()

  return (
    <Box as='label' width='100%'>
      <input {...input} />
      <Flex
        {...checkbox}
        cursor='pointer'
        bgColor='#F3F6FE'
        color='primary'
        borderRadius='1.25rem'
        _checked={{
          bgColor: 'primary',
          color: 'white',
        }}
        _focus={{
          boxShadow: 'outline',
        }}
        px={{ base: '7.5rem', md: '2.5rem' }}
        py={{ base: '1rem', md: '2.5rem' }}
        justifyContent='center'
        alignItems='center'
      >
        <Box as='h1'>{props.children}</Box>
      </Flex>
    </Box>
  )
}

export default RadioCard

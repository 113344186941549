import React, { useEffect, useState } from 'react'
import { Control, Controller } from 'react-hook-form'

import { Box, Flex, Grid } from '@chakra-ui/react'

import {
  AttachmentFile,
  ConfirmAlert,
  DateTimePicker,
  FileUpload,
  Input,
} from 'components'
import { useLanguages } from 'hooks'
import { GroupUploadFile, UploadFile } from 'hooks/useUploadCredential'
import { CredentialDetailForm } from 'shared/containers/User/containers/forms'
import { Attachment } from 'types'

import { ReactComponent as CrossIcon } from 'assets/images/cross_icon.svg'

interface CredentialItemProps {
  index: number
  control: Control<any>
  setValue: any
  errors: any
  data: CredentialDetailForm
  handleRemove?: (index: number) => void
  handleViewFile?: (file: Attachment) => void
  uploadFiles?: UploadFile[]
  isUploadingFile?: boolean
  handleChangeFile?: React.Dispatch<React.SetStateAction<GroupUploadFile[]>>
}

const CredentialEditItem: React.FC<CredentialItemProps> = ({
  index,
  control,
  setValue,
  errors,
  data,
  handleRemove,
  handleViewFile,
  uploadFiles = [],
  isUploadingFile,
  handleChangeFile,
}) => {
  const { _t } = useLanguages()
  const [attachFile, setAttachFile] = useState<File>()

  useEffect(() => {
    if (attachFile) {
      handleChangeFile &&
        handleChangeFile((groupFiles: GroupUploadFile[]) => {
          const newData = [...groupFiles]
          newData[index] = {
            index: newData[index].index,
            uploadFiles: [
              {
                file: attachFile,
                uploadStatus: 'pending',
              },
            ],
          }
          return newData
        })
    }
  }, [attachFile, handleChangeFile, index])

  const handleRemoveAttachment = (name: string) => {
    let fileId = ''
    const newFiles = uploadFiles.filter(item => {
      if (item.file.name === name) {
        fileId = item.file.id || ''
        return false
      }
      return true
    })

    if (fileId) {
      setValue(`credentials[${index}].attachmentKey`, '')
      setValue(`credentials[${index}].attachment`, {
        opt: 'remove',
        resourceId: fileId,
      })
    }

    handleChangeFile &&
      handleChangeFile((groupFiles: GroupUploadFile[]) => {
        const newData = [...groupFiles]
        const changeItemIndex = groupFiles.findIndex(
          item => item.index === data.id
        )
        newData[changeItemIndex].uploadFiles = [...newFiles]
        return newData
      })
  }

  return (
    <Box>
      <Flex justifyContent='space-between' alignItems='center'>
        <Controller
          control={control}
          name={`credentials[${index}].id`}
          defaultValue={data.id}
          render={({ value, onChange }) => (
            <Input type='hidden' value={value} onChange={onChange} />
          )}
        />
        <Controller
          control={control}
          name={`credentials[${index}].opt`}
          defaultValue={data.opt || 'update'}
          render={({ value, onChange }) => (
            <Input type='hidden' value={value} onChange={onChange} />
          )}
        />
        <ConfirmAlert
          title={_t('user.profile.remove_credential_title')}
          onSubmit={() => handleRemove && handleRemove(index)}
        >
          {({ showAlert }) => (
            <Box onClick={showAlert}>
              <CrossIcon height='32px' width='32px' cursor='pointer' />
            </Box>
          )}
        </ConfirmAlert>
      </Flex>
      <Grid
        gridTemplateColumns={{ base: '1fr', md: 'repeat(2, 1fr)' }}
        gap='1.5rem'
      >
        <Controller
          as={Input}
          control={control}
          name={`credentials[${index}].name`}
          defaultValue={data.name}
          aria-label='Name'
          label={_t('user.profile.credential_name')}
          placeholder={_t('user.profile.placeholder_credential_name')}
          fontSize='0.875rem'
          error={errors?.credentials?.[index]?.name?.message || ''}
          rules={{
            required: _t('user.profile.validate_credential_name_required'),
          }}
        />
        <Controller
          as={Input}
          control={control}
          name={`credentials[${index}].issueName`}
          defaultValue={data.issueName}
          aria-label='Issuer Name'
          label={_t('user.profile.credential_issuer_name')}
          placeholder={_t('user.profile.placeholder_credential_issuer_name')}
          fontSize='0.875rem'
          error={errors?.credentials?.[index]?.issueName?.message || ''}
        />
        <Controller
          control={control}
          name={`credentials[${index}].issueDate`}
          defaultValue={data.issueDate}
          render={({ onChange, value }) => (
            <DateTimePicker
              label={_t('user.profile.credential_issue_date')}
              onChange={onChange}
              selected={value}
              placeholderText={_t(
                'user.profile.placeholder_credential_issue_date'
              )}
              maxDate={new Date()}
              showMonthDropdown
              showYearDropdown
              yearDropdownItemNumber={15}
              scrollableYearDropdown
            />
          )}
        />
        <Controller
          control={control}
          name={`credentials[${index}].expiryDate`}
          defaultValue={data.expiryDate}
          render={({ onChange, value }) => (
            <DateTimePicker
              label={_t('user.profile.credential_expiry_date')}
              onChange={onChange}
              selected={value}
              placeholderText={_t(
                'user.profile.placeholder_credential_expiry_date'
              )}
              showMonthDropdown
              showYearDropdown
              yearDropdownItemNumber={15}
              scrollableYearDropdown
            />
          )}
        />
        <Controller
          as={Input}
          control={control}
          name={`credentials[${index}].referenceId`}
          defaultValue={data.referenceId}
          aria-label='Credential ID'
          label={_t('user.profile.credential_id')}
          placeholder={_t('user.profile.placeholder_credential_id')}
          fontSize='0.875rem'
          error={errors?.credentials?.[index]?.referenceId?.message || ''}
        />
        <Controller
          as={Input}
          control={control}
          name={`credentials[${index}].referenceUrl`}
          defaultValue={data.referenceUrl}
          aria-label='Credential URL'
          label={_t('user.profile.credential_url')}
          placeholder={_t('user.profile.placeholder_credential_url')}
          fontSize='0.875rem'
          error={errors?.credentials?.[index]?.referenceUrl?.message || ''}
        />
      </Grid>
      <Box mt='1.5rem'>
        <Controller
          control={control}
          name={`credentials[${index}].attachmentKey`}
          defaultValue={data.attachmentKey}
          render={({ value, onChange }) => (
            <Input type='hidden' value={value} onChange={onChange} />
          )}
        />
        <Controller
          control={control}
          name={`credentials[${index}].attachment`}
          defaultValue={data.attachment}
          render={({ value, onChange }) => (
            <Input type='hidden' value={value} onChange={onChange} />
          )}
        />
        {uploadFiles.length > 0 ? (
          uploadFiles.map(({ file, uploadStatus }) => (
            <AttachmentFile
              key={file.name}
              item={file}
              deletable
              uploadStatus={isUploadingFile ? 'uploading' : uploadStatus}
              onDelete={handleRemoveAttachment}
              withStatus={false}
              onViewFile={handleViewFile}
            />
          ))
        ) : (
          <FileUpload
            maxFiles={1}
            onChange={files => {
              setAttachFile(files[0])
            }}
          />
        )}
      </Box>
    </Box>
  )
}

export default CredentialEditItem

import React, { Suspense, useMemo } from 'react'

import { Box, BoxProps } from '@chakra-ui/react'

import { HeadingSection, LoadingScreen } from 'components'
import { useLanguages } from 'hooks'
import { responsivePadding } from 'utils/constant'

interface EnterpriseLayoutProps {
  title?: string
  backLabel?: string
  topBoxProps?: BoxProps
  mainBoxProps?: BoxProps
  topElement?: React.ReactNode
  handleCancel?: () => void
  handleSubmit?: () => void
  tabData?: any
  currentTab?: string
  isCreate?: boolean
  isEdit?: boolean
  showSubmitButton?: boolean
  isAdminView?: boolean
}

const EnterpriseLayout: React.FC<EnterpriseLayoutProps> = ({
  children,
  title,
  backLabel,
  topBoxProps,
  mainBoxProps,
  topElement,
  handleCancel,
  handleSubmit,
  tabData,
  currentTab,
  isCreate = false,
  isEdit = false,
  showSubmitButton = true,
  isAdminView = false,
}) => {
  const { _t } = useLanguages()

  const submitLabel =
    (isCreate && _t('common.create')) ||
    (isEdit && _t('common.edit')) ||
    _t('common.save')

  const buttonData = useMemo(() => {
    const result = []

    if (showSubmitButton && handleSubmit) {
      result.push({
        label: submitLabel,
        onClick: handleSubmit,
      })
    }

    if (handleCancel) {
      result.push({
        label: _t('common.cancel'),
        isSecondary: true,
        onClick: handleCancel,
      })
    }

    return result
  }, [showSubmitButton, handleSubmit, handleCancel, submitLabel, _t])

  return (
    <>
      <Box
        bg='secondaryLightBlue'
        pb={tabData && currentTab ? '0' : '1rem'}
        {...topBoxProps}
      >
        {topElement ? (
          topElement
        ) : (
          <HeadingSection
            title={title || ''}
            backLabel={backLabel}
            handleToBack={handleCancel}
            tabData={tabData}
            currentTab={currentTab}
            buttonData={buttonData}
            isAdminStyle={isAdminView}
            widthType='narrow'
          />
        )}
      </Box>
      <Box
        as='section'
        py='2rem'
        w='full'
        px={responsivePadding}
        {...mainBoxProps}
      >
        <Suspense fallback={<LoadingScreen />}>{children}</Suspense>
      </Box>
    </>
  )
}

export default EnterpriseLayout

import React from 'react'

import { Badge } from '@chakra-ui/react'

import { useLanguages } from 'hooks'
import { TransactionApprovalStatus } from 'types'

interface TransactionApprovalStatusBadgeProps {
  status?: TransactionApprovalStatus
}

const TransactionApprovalStatusBadge: React.FC<TransactionApprovalStatusBadgeProps> = ({
  status,
}) => {
  const { _t } = useLanguages()

  switch (status) {
    case TransactionApprovalStatus.APPROVED:
      return (
        <Badge variant='solid' colorScheme='green'>
          {_t('transaction_approval.status.approved')}
        </Badge>
      )
    case TransactionApprovalStatus.REJECTED:
      return (
        <Badge variant='solid' colorScheme='red'>
          {_t('transaction_approval.status.rejected')}
        </Badge>
      )
    default:
      return (
        <Badge variant='solid'>
          {_t('transaction_approval.status.pending')}
        </Badge>
      )
  }
}

export default TransactionApprovalStatusBadge

import React, { useMemo } from 'react'

import { Box, Flex, Switch, Text } from '@chakra-ui/react'
import format from 'date-fns/format'

import { CashFlowStatusText, CashFlowTypeText, Link } from 'components'
import { useLanguages } from 'hooks'
import { CashFlowInfo } from 'types'
import { formatAmount } from 'utils/parser'

import { ReactComponent as ExternalLinkIcon } from 'assets/images/external_link_icon.svg'

interface DetailProps {
  data: CashFlowInfo
}

const Detail: React.FC<DetailProps> = ({ data }) => {
  const { _t } = useLanguages()

  const handleProductLink = useMemo(
    () =>
      `/admin/session-management/preview/class/${data?.orderItem?.productId}`,
    [data]
  )
  const handleRefundLink = useMemo(
    () => `/admin/transaction-management/view/${data?.refundTransactionId}`,
    [data]
  )

  return (
    <>
      <Flex direction={{ base: 'column', md: 'row' }} justify='space-between'>
        <Box flex={1}>
          <Box mb='1rem'>
            <Text textStyle='detailTitle' color='gray.300' mb='0.25rem'>
              {_t('cash_flow.detail.id')}
            </Text>
            <Text>{data?.id}</Text>
          </Box>
          <Box mb='1rem'>
            <Text textStyle='detailTitle' color='gray.300' mb='0.25rem'>
              {_t('cash_flow.detail.date_time')}
            </Text>
            <Text>
              {data?.createdAt
                ? format(new Date(data?.createdAt), 'dd MMM yyyy, h:mm a')
                : '-'}
            </Text>
          </Box>
          <Box mb='1rem'>
            <Text textStyle='detailTitle' color='gray.300' mb='0.25rem'>
              {_t('cash_flow.detail.product_name')}
            </Text>
            <Text>{data?.orderItem?.description || '-'}</Text>
          </Box>
          <Box mb='1rem'>
            <Text textStyle='detailTitle' color='gray.300' mb='0.25rem'>
              {_t('cash_flow.detail.product_id')}
            </Text>
            {data?.transaction.type === 'purchase' ? (
              <Link to={handleProductLink} color='primary' isExternal>
                <Flex align='center'>
                  <Text noOfLines={1} mr='0.5rem'>
                    {data?.orderItem?.productId}
                  </Text>
                  <ExternalLinkIcon height='15' width='15' />
                </Flex>
              </Link>
            ) : (
              <Text>{data?.orderItem?.productId || '-'}</Text>
            )}
          </Box>
          <Box mb='1rem'>
            <Text textStyle='detailTitle' color='gray.300' mb='0.25rem'>
              {_t('cash_flow.detail.name')}
            </Text>
            <Text>{data?.name || '-'}</Text>
          </Box>
          <Box mb='1rem'>
            <Text textStyle='detailTitle' color='gray.300' mb='0.25rem'>
              {_t('cash_flow.detail.session_id')}
            </Text>
            <Text>{data?.sessionId || '-'}</Text>
          </Box>
          <Box mb='1rem'>
            <Text textStyle='detailTitle' color='gray.300' mb='0.25rem'>
              {_t('cash_flow.detail.amount')}
            </Text>
            <Text>{`$${formatAmount((data?.amount || 0) / 100)}`}</Text>
          </Box>
          <Box mb='1rem'>
            <Text textStyle='detailTitle' color='gray.300' mb='0.25rem'>
              {_t('cash_flow.detail.expense_amount')}
            </Text>
            <Text>{`$${formatAmount((data?.expenseAmount || 0) / 100)}`}</Text>
          </Box>
        </Box>
        <Box flex={1}>
          <Box mb='1rem'>
            <Text textStyle='detailTitle' color='gray.300' mb='0.25rem'>
              {_t('cash_flow.detail.status')}
            </Text>
            <CashFlowStatusText status={data?.status} />
          </Box>
          <Box mb='1rem'>
            <Text textStyle='detailTitle' color='gray.300' mb='0.25rem'>
              {_t('cash_flow.detail.type')}
            </Text>
            <CashFlowTypeText type={data?.type} />
          </Box>
          <Box mb='1rem'>
            <Text textStyle='detailTitle' color='gray.300' mb='0.25rem'>
              {_t('cash_flow.detail.freeze')}
            </Text>
            <Switch
              size='sm'
              isChecked={data?.freeze}
              cursor='default'
              disabled
            />
          </Box>
          <Box mb='1rem'>
            <Text textStyle='detailTitle' color='gray.300' mb='0.25rem'>
              {_t('cash_flow.detail.refund_transaction_id')}
            </Text>
            {data?.refundTransactionId ? (
              <Link to={handleRefundLink} color='primary' isExternal>
                <Flex align='center'>
                  <Text noOfLines={1} mr='0.5rem'>
                    {data?.refundTransactionId}
                  </Text>
                  <ExternalLinkIcon height='15' width='15' />
                </Flex>
              </Link>
            ) : (
              <Text>{'-'}</Text>
            )}
          </Box>
          <Box mb='1rem'>
            <Text textStyle='detailTitle' color='gray.300' mb='0.25rem'>
              {_t('cash_flow.detail.last_update')}
            </Text>
            <Text>
              {data?.updatedAt
                ? format(new Date(data?.updatedAt), 'dd MMM yyyy, h:mm a')
                : '-'}
            </Text>
          </Box>
        </Box>
      </Flex>
      <Box mb='1rem'>
        <Text textStyle='detailTitle' color='gray.300' mb='0.25rem'>
          {_t('cash_flow.detail.remarks')}
        </Text>
        <Text>{data?.remarks || '-'}</Text>
      </Box>
    </>
  )
}

export default Detail

import { ClassSessionItem, Language, Subject, TeachingLevel } from '.'
import { Attachment } from './assignment'
import { MeetingLocation } from './classSession'
import { AccountType, UserRole } from './user'
import { Option } from 'components'
import { UploadFile } from 'hooks/useUploadClassFile'

export interface Learner {
  avatar?: string
  email?: string
  firstName: string
  id: string
  lastName: string
  middleName?: string
  accountType?: AccountType
}

export interface AttachmentsType {
  createdAt?: number
  key?: string
  size: number
  name: string
  status?: string
  type?: string
}

export interface GetClassesParams {
  page: number
  limit: number
  search?: string
  searchBy?: string
  order?: 'asc' | 'desc'
  orderBy?: string
  filterBy?: string
  statusSession?: string
  startCreatedAt?: number
  endCreatedAt?: number
  startDateTime?: number
  endDateTime?: number
  type?: string
  openTo?: string
  minFeeEnterprise?: number
  maxFeeEnterprise?: number
  minNumberEnterpriseLearners?: number
  maxNumberEnterpriseLearners?: number
  minFee?: number
  maxFee?: number
  minNumberLearners?: number
  maxNumberLearners?: number
  startPublishDate?: number
  endPublishDate?: number
  status?: 'public' | 'draft'
  enterpriseId?: string
  centerId?: string
}

export interface LessonPackage {
  id: string
  name: string
  description: string
  sessions: ClassSessionItem[]
  maxStudent: number
  minStudent: number
  type?: string
  status?: string
  confirmedStudents: Learner[]
}

export interface Material {
  id?: string
  name?: string
  url?: string
}

export interface Class {
  center?: {
    id: string
    name: string
  }
  enterprise?: {
    id: string
    name: string
  }
  description: string
  id: string
  maxStudent: number
  minStudent: number
  name: string
  price: number
  enterprisePrice?: number
  usualPrice?: number
  usualEnterprisePrice?: number
  meetingType?: string
  sessions?: ClassSessionItem[]
  status: 'public' | 'draft'
  totalEnterpriseStudents: number
  totalNonEnterpriseStudents: number
  type: string
  updatedAt: number
  user: {
    avatar: string
    firstName: string
    id: string
    lastName: string
  }
  // set type for properties of session to get in collapsible table
  startDateTime?: number
  endDateTime?: number
}

export interface ClassDetail {
  attachments: Attachment[]
  confirmedStudents: Learner[]
  description: string
  id: string
  invitedStudents: Learner[]
  language: Language
  maxStudent: number
  minStudent: number
  name: string
  price: number
  requireMinimum: boolean
  enterprisePrice?: number
  usualPrice?: number
  usualEnterprisePrice?: number
  sessions: ClassSessionItem[]
  upcomingSession: ClassSessionItem
  status: string
  subject: Subject
  teachingLevel: Omit<TeachingLevel, 'subjectCombinationId'>
  type: string
  user: {
    avatar: string
    firstName: string
    id: string
    lastName: string
    rating?: number
  }
  center?: { id: string; name: string }
  banner?: string
  tutorTypes: { name: UserRole.Tutor | UserRole.EnterpriseTutor }[]
  purchased?: boolean
  bid?: {
    id: string
    message?: string
  }
  publishDate?: number
  updatedAt: number
  packages?: LessonPackage[]
  materials?: Material[]
}

export interface DraftClass {
  confirmedStudents: string[]
  description: string
  enterprisePrice: number
  id: string
  maxStudent: number
  minStudent: number
  name: string
  price: number
  usualPrice?: number
  usualEnterprisePrice?: number
  requireMinimum: boolean
  status: string
  totalSession: number
  type: string
  updatedAt: number
  banner?: string
  attachments?: Attachment[]
  meetingType: string
}

export interface PackageDetail {
  attachments: Attachment[]
  confirmedStudents: Learner[]
  description: string
  id: string
  invitedStudents: Learner[]
  language: Language
  maxStudent: number
  minStudent: number
  name: string
  price: number
  requireMinimum: boolean
  enterprisePrice?: number
  usualPrice?: number
  usualEnterprisePrice?: number
  sessions: ClassSessionItem[]
  upcomingSession: ClassSessionItem
  status: string
  subject: Subject
  teachingLevel: Omit<TeachingLevel, 'subjectCombinationId'>
  type: string
  totalSession?: number
  totalSessionCompleted?: number
  user: {
    avatar: string
    firstName: string
    id: string
    lastName: string
    rating?: number
  }
  center?: { id: string; name: string }
  banner?: string
  tutorTypes: { name: UserRole.Tutor | UserRole.EnterpriseTutor }[]
}

export interface ClassDetailItem {
  id?: string
  index: number
  name: string
  description: string
  startDateTime: number
  endDateTime: number
  meetingType: string
  sessionAttachments?: UploadFile[]
  meetingLink?: string
  meetingPassword?: string
  attachmentIds?: string[]
  meetingLocation?: MeetingLocation
}

export interface ClassPreview {
  id?: string
  name?: string
  description?: string
  banner?: string
  price?: number
  enterprisePrice?: number
  usualPrice?: number
  usualEnterprisePrice?: number
  teachingLevel?: Option
  subject?: Option
  language?: Option
  purchased?: boolean
  maxStudent?: number
  minStudent?: number
  numOfConfirmedStudents?: number
  sessions?: ClassSessionItem[]
  type?: string
  tutorTypes?: { name: UserRole.Tutor | UserRole.EnterpriseTutor }[]
  status?: string
  confirmedStudents?: Learner[]
  invitedStudents?: Learner[]
  center?: { id: string; name: string }
  packages?: LessonPackage[]
  requireMinimum?: boolean
  materials?: Material[]
  user?: {
    id: string
    firstName: string
    lastName: string
    middleName?: string
    avatar?: string
  }
}

export interface ClassUploadUrl {
  data: {
    fields: {
      key: string
      'Content-Type': string
      bucket: string
      'X-Amz-Algorithm': string
      'X-Amz-Credential': string
      'X-Amz-Date': string
      'X-Amz-Security-Token': string
      Policy: string
      'X-Amz-Signature': string
      'x-amz-meta-userid': string
      'x-amz-meta-id': string
      'x-amz-meta-name': string
      'x-amz-meta-status': string
    }
    url: string
  }
}

export interface ClassHistory {
  id: string
  name: string
  status?: string
  isRating: boolean
  orderId?: string
  user: {
    id: string
    firstName: string
    lastName: string
    middleName?: string
    avatar?: string
  }
  session: {
    id: string
    name: string
    startDateTime: any
    endDateTime: any
    meetingType: string
  }
  center?: {
    id: string
    name: string
  }
  type?: string
}

export interface ClassPaymentInfo {
  id: string
  name: string
  price: number
  type: 'class' | 'adhoc-class'
  tutor?: {
    id?: string
    firstName?: string
    lastName?: string
    email?: string
  }
}

export interface CalendarClass {
  id: string
  name: string
  startDateTime: number
  endDateTime: number
  meetingType?: string
  class?: {
    id?: string
    name?: string
    type?: string
  }
  tutor?: {
    id?: string
    firstName?: string
    lastName?: string
    email?: string
  }
  isConfirmedStudent?: boolean
}

export enum ClassType {
  PRIVATE_REGULAR = 'privateRegular',
  PUBLIC_REGULAR = 'publicRegular',
  ENTERPRISE_REGULAR = 'enterpriseRegular',
  ENTERPRISE_PACKAGE = 'enterprisePackage',
  PUBLIC_PACKAGE = 'publicPackage',
  PRIVATE_PACKAGE = 'privatePackage',
  PRIVATE_CONTENT = 'privateContent',
  PUBLIC_CONTENT = 'publicContent',
  ENTERPRISE_CONTENT = 'enterpriseContent',
  ADHOC_REQUEST = 'adhocRequest',
  PUBLIC_LESSON = 'publicLesson',
  ENTERPRISE_LESSON = 'enterpriseLesson',
}

export enum ClassColor {
  PACKAGE_COLOR = '#5743AC',
  PRIVATE_COLOR = '#5C96D6',
}

export enum MeetingType {
  PHYSICAL = 'physical',
  VIRTUAL_DEFAULT = 'zoom',
  VIRTUAL_CUSTOM = 'other',
}

export interface ExportClassData {
  type?: string
  fileType: 'xlsx' | 'csv'
  centerId?: string
  enterpriseId?: string
  status: 'public' | 'draft'
}

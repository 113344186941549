import React from 'react'
import { useNavigate } from 'react-router'

import { Box, Button, Flex, Text } from '@chakra-ui/react'
import { useAtom } from 'jotai'

import { Modal, ModalProps } from 'components'
import { useLanguages } from 'hooks'
import { cartAtom } from 'store/cartAtom'
import { formatAmount } from 'utils/parser'

interface PaySubscriptionModalProps extends Omit<ModalProps, 'children'> {
  cancelSubscriptionId: string
  createPackageId: string
  type: 'privateTutor' | 'enterprise'
  id: string
  packagePrice: number
}

export enum SubscriptionStep {
  Confirmation = 'Confirmation',
  Input = 'Input',
  Payment = 'Payment',
  Success = 'Success',
}
export type SubscriptionStepType =
  | SubscriptionStep.Confirmation
  | SubscriptionStep.Input
  | SubscriptionStep.Payment
  | SubscriptionStep.Success

const PaySubscriptionModal = ({
  isOpen,
  onClose,
  cancelSubscriptionId,
  createPackageId,
  type,
  packagePrice,
  id, // it will be enterpriseId when type = enterprise, userId when type = privateTutor
}: PaySubscriptionModalProps) => {
  const { _t } = useLanguages()
  const [, setCart] = useAtom(cartAtom)
  const navigate = useNavigate()

  const handleClose = () => {
    onClose()
  }

  return (
    <Modal isOpen={isOpen} onClose={handleClose} size={'xl'}>
      <Box px='4rem'>
        <Box as='h1' textAlign='center'>
          {_t('subscription.upgrade.title')}
        </Box>
        <Box fontSize='0.875rem' my='2rem' textAlign='center'>
          <Text mb='0.5rem'>
            {_t('subscription.upgrade.description')}
            <Text display='inline' fontSize='1rem' fontWeight='600'>
              '{type === 'enterprise' ? 'Enterprise Pro' : 'Knovo Pro'}'
            </Text>
            ?
          </Text>
          <Text>{_t('subscription.upgrade.notes')}</Text>
        </Box>
        <Flex justifyContent='space-between' alignItems='center'>
          <Button
            flex='1'
            variant='solidPrimary'
            onClick={() => {
              setCart([
                {
                  title: _t('subscription.title'),
                  subtitle: `${
                    type === 'enterprise' ? 'Enterprise Pro' : 'Knovo Pro'
                  }`,
                  extra: `Monthly payment - $${formatAmount(packagePrice)}`,
                  quantity: 1,
                  price: Math.round((packagePrice || 0) * 100),
                  userSubscriptionId: cancelSubscriptionId,
                  subscription: {
                    id: createPackageId,
                    name:
                      type === 'enterprise' ? 'Enterprise Pro' : 'Knovo Pro',
                  },
                },
              ])
              navigate(
                type === 'enterprise'
                  ? '/enterprise-admin/payment/subscribe?callback=/enterprise-admin/enterprise-profile'
                  : '/payment/subscribe?callback=/wallet'
              )
            }}
            textTransform='uppercase'
          >
            {_t('common.confirm')}
          </Button>
          <Button
            flex='1'
            variant='transparent'
            textTransform='uppercase'
            onClick={handleClose}
          >
            {_t('common.cancel')}
          </Button>
        </Flex>
      </Box>
    </Modal>
  )
}

export default PaySubscriptionModal

import React, { useCallback, useMemo, useState } from 'react'

import {
  Avatar,
  Box,
  Flex,
  Grid,
  Link,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { format } from 'date-fns'
import { useAtom } from 'jotai'

import {
  IconBox,
  IconText,
  PreviewCredentialModal,
  ProfileProgress,
  TutorReviews,
} from 'components'
import { useLanguages } from 'hooks'
import { CentrePreviewCard } from 'shared/containers'
import ViewCredentials from 'shared/containers/User/containers/ViewCredentials'
import { userProfileAtom } from 'store/authAtom'
import { Attachment, GroupedCombination, UserProfile } from 'types'
import { isInstructor as profileIsInstructor } from 'utils/helper'
import { getLanguages, getYoutubeUrl } from 'utils/parser'

import { ReactComponent as BioIcon } from 'assets/images/bio.svg'
import { ReactComponent as ContactsIcon } from 'assets/images/contact_icon.svg'
import { ReactComponent as DetailsIcon } from 'assets/images/details_icon.svg'
import { ReactComponent as SubjectIcon } from 'assets/images/label-subject.svg'
import { ReactComponent as YouTubePlay } from 'assets/images/play.svg'
import { ReactComponent as SchoolIcon } from 'assets/images/school.svg'
import { ReactComponent as ShieldIcon } from 'assets/images/shield.svg'

interface ViewProfileProps {
  userId?: string
  profileData: UserProfile
  teachingCombinations?: GroupedCombination[]
  handleEditProfile?: () => void
  isInstructor?: boolean
  isLearner?: boolean
  isPublicView?: boolean
  isGuestView?: boolean
}

const TeachingItem: React.FC<{ item: GroupedCombination }> = ({ item }) => (
  <Box mt='0.5rem'>
    <Text fontSize='0.75rem' mb='0.5rem'>
      {`${item.country.name} - ${item.system.name}`}
    </Text>
    <Flex flexWrap='wrap'>
      {item.teachingLevels.map(level => (
        <Box
          key={level.id}
          mr='0.5rem'
          mb='0.5rem'
          px='1rem'
          py='0.5rem'
          bgColor='rgba(139, 206, 255, 0.5)'
          rounded='full'
        >
          <Text>{`${item.subject.name}: ${level.name}`}</Text>
        </Box>
      ))}
    </Flex>
  </Box>
)

const ViewProfileContainer: React.FC<ViewProfileProps> = ({
  userId,
  profileData,
  teachingCombinations,
  handleEditProfile,
  isInstructor = false,
  isLearner = false,
  isPublicView = false,
  isGuestView = false,
}) => {
  const { _t } = useLanguages()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const profileId = useMemo(() => userId || profileData?.id, [
    userId,
    profileData,
  ])
  const isEnterprise = !!profileData?.enterprises || !!profileData?.centers

  const [selfProfile] = useAtom(userProfileAtom)
  const selfEnterpriseId = selfProfile?.enterprises?.[0].id

  // Instructors cannot view centres for now.
  // This may change in the future.
  const canViewCentre =
    !profileIsInstructor(selfProfile) &&
    ((!selfProfile?.enterprises && !selfProfile?.centers) ||
      profileData.enterprises?.[0].id === selfEnterpriseId)

  const canRenderCentre = !!(
    isInstructor &&
    isEnterprise &&
    isPublicView &&
    profileData.centers &&
    profileData.centers[0].id
  )

  const privateIndicator = isPublicView ? undefined : (
    <IconText
      icon={ShieldIcon}
      text={_t('common.private')}
      tooltipText={_t('profile.alert.edit_detail')}
      textProps={{ textColor: 'gray.500' }}
    />
  )

  const dividerLineProps = {
    mt: '2rem',
    pt: '2rem',
    borderTopWidth: '1px',
    borderTopColor: 'gray.200',
  }

  const [previewFile, setPreviewFile] = useState<Attachment | undefined>(
    undefined
  )
  const credentials = useMemo(() => profileData.credentials || [], [
    profileData,
  ])
  const handleOpenPreviewFile = (file: Attachment) => {
    setPreviewFile(file)
    onOpen()
  }

  const handleClosePreviewFile = () => {
    setPreviewFile(undefined)
    onClose()
  }

  const renderItem = useCallback(
    (label, value, capitalize = false, preserveLines = false) => {
      return (
        <Box>
          <Text fontSize='0.75rem' color='gray.300' textTransform='uppercase'>
            {label}
          </Text>
          <Text
            {...(capitalize ? { textTransform: 'capitalize' } : {})}
            {...(preserveLines ? { whiteSpace: 'pre-wrap' } : {})}
          >
            {value || '-'}
          </Text>
        </Box>
      )
    },
    []
  )

  return (
    <Flex direction='column' mt='1rem' overflowX='hidden'>
      {!isPublicView && (
        <ProfileProgress handleEditProfile={handleEditProfile} />
      )}
      <IconBox icon={BioIcon} title={_t('profile.heading.about')}>
        <Box mb='1.25rem'>
          {renderItem(
            _t('user.profile.self_description'),
            profileData.description,
            false,
            true
          )}
        </Box>
        {isInstructor && profileData.youtubeLink && (
          <Box mb='1.25rem'>
            <Text fontSize='0.75rem' color='gray.300' textTransform='uppercase'>
              {_t('user.profile.video')}
            </Text>
            <Link
              href={profileData.youtubeLink}
              alignItems='center'
              mt='0.5rem'
              display={{ base: 'flex', sm: 'none' }}
              isExternal
            >
              <YouTubePlay />
              <Text ml='0.25rem' fontSize='1rem'>
                {_t('user.profile.video_description')}
              </Text>
            </Link>
            <Box
              as='iframe'
              src={getYoutubeUrl(profileData.youtubeLink || '')}
              allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
              allowFullScreen
              w={{ base: '24rem', md: '30rem' }}
              h={{ base: '13.5rem', md: '16.875rem' }}
              my='0.5rem'
              borderRadius='0.25rem'
              display={{ base: 'none', sm: 'block' }}
            />
          </Box>
        )}
        <Grid
          gridTemplateColumns={{ base: '1fr', lg: 'repeat(2, 1fr)' }}
          gap={4}
        >
          {renderItem(_t('user.profile.gender'), profileData?.gender, true)}
          {isInstructor &&
            renderItem(
              _t('user.profile.country_of_residence'),
              profileData?.countryOfResidence
            )}
          {isInstructor &&
            renderItem(
              _t('user.profile.nationality'),
              profileData?.nationality
            )}
          {renderItem(
            _t('user.profile.language_of_instruction'),
            getLanguages(profileData)
          )}
          {isInstructor &&
            renderItem(
              _t('user.profile.highest_qualification'),
              profileData?.educationQualification
            )}
          {isLearner &&
            renderItem(
              _t('user.profile.sessions_completed'),
              profileData?.countCompletedSessions
            )}
          {isLearner &&
            renderItem(
              _t('user.profile.education_system'),
              profileData?.educationSystem?.country?.name &&
                profileData?.educationSystem?.system?.name &&
                `${profileData?.educationSystem?.country?.name} - ${profileData?.educationSystem?.system?.name}`
            )}
          {isLearner &&
            renderItem(
              _t('user.profile.current_education_level'),
              profileData?.educationSystem?.teachingLevel?.name
            )}
        </Grid>
      </IconBox>

      {!isPublicView && (
        <IconBox
          icon={DetailsIcon}
          title={_t('profile.heading.personal_detail')}
          {...dividerLineProps}
          rightElement={privateIndicator}
        >
          <Grid
            gridTemplateColumns={{ base: '1fr', lg: 'repeat(2, 1fr)' }}
            gap={4}
          >
            {renderItem(
              _t('user.profile.date_of_birth'),
              profileData?.birthday &&
                format(profileData.birthday, 'dd MMMM yyyy')
            )}
            {renderItem(_t('user.profile.email_address'), profileData?.email)}
            {renderItem(
              _t('user.profile.recovery_email'),
              profileData?.recoveryEmail
            )}
            {renderItem(_t('user.profile.mobile'), profileData?.contactNumber)}
            {isLearner &&
              renderItem(
                _t('user.profile.country_of_residence'),
                profileData?.countryOfResidence
              )}
            {isLearner &&
              renderItem(
                _t('user.profile.nationality'),
                profileData?.nationality
              )}
          </Grid>
        </IconBox>
      )}

      {isInstructor && teachingCombinations && (
        <IconBox
          icon={SubjectIcon}
          title={_t('profile.heading.teaching_subjects_and_levels')}
          {...dividerLineProps}
        >
          <Box>
            {teachingCombinations.length > 0 ? (
              teachingCombinations.map((combine, index) => (
                <TeachingItem key={index} item={combine} />
              ))
            ) : (
              <Text fontSize='0.875rem' color='gray.300'>
                {_t('user.profile.teaching_subjects_and_levels_display_empty')}
              </Text>
            )}
          </Box>
        </IconBox>
      )}

      {!isGuestView && (
        <IconBox
          icon={SchoolIcon}
          title={_t('profile.heading.credential_detail')}
          {...dividerLineProps}
        >
          {credentials && credentials.length > 0 ? (
            <ViewCredentials
              credentials={credentials}
              userId={profileId}
              handleOpenPreviewFile={handleOpenPreviewFile}
            />
          ) : (
            <Text fontSize='0.875rem' color='gray.300'>
              {_t('user.profile.credential_display_empty')}
            </Text>
          )}
        </IconBox>
      )}

      {canRenderCentre && (
        <Box
          mt='1.2rem'
          pt='1.2rem'
          borderTopWidth='1px'
          borderTopColor='gray.200'
        >
          <Text fontWeight='600' textTransform='uppercase'>
            {_t('profile.about.center')}
          </Text>
          {canViewCentre ? (
            <CentrePreviewCard centreId={profileData?.centers?.[0].id!} />
          ) : (
            <Flex flexDirection='row' alignItems='center' mt='1rem'>
              <Avatar
                size='sm'
                name={profileData.centers?.[0].name}
                src={profileData.centers?.[0].avatar}
              />
              <Text fontSize='1rem' ml='1rem'>
                {profileData?.centers?.[0].name}
              </Text>
            </Flex>
          )}
        </Box>
      )}

      {!isPublicView && (
        <IconBox
          icon={ContactsIcon}
          title={_t('profile.heading.address')}
          {...dividerLineProps}
          rightElement={privateIndicator}
        >
          <Grid
            gridTemplateColumns={{ base: '1fr', lg: 'repeat(2, 1fr)' }}
            gap={4}
          >
            {renderItem(
              _t('user.profile.address_line_1'),
              profileData?.address1
            )}
            {renderItem(
              _t('user.profile.address_line_2'),
              profileData?.address2
            )}
            {renderItem(_t('user.profile.city'), profileData?.city)}
            {renderItem(_t('user.profile.state'), profileData?.state)}
            {renderItem(_t('user.profile.country'), profileData?.country)}
            {renderItem(_t('user.profile.zip_code'), profileData?.zipCode)}
          </Grid>
        </IconBox>
      )}

      {isInstructor && !isGuestView && profileId && (
        <Box {...dividerLineProps}>
          <TutorReviews
            rating={profileData?.rating}
            tutorId={profileId}
            type='instructor'
            isPreview={true}
            limit={3}
          />
        </Box>
      )}

      {profileId && (
        <PreviewCredentialModal
          isOpen={isOpen}
          onClose={handleClosePreviewFile}
          userId={profileId}
          file={previewFile}
          downloadable={false}
        />
      )}
    </Flex>
  )
}

export default ViewProfileContainer

import React from 'react'

import {
  Box,
  Button,
  Flex,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
} from '@chakra-ui/react'

import { PopoverItem } from 'components'
import { Option } from 'components/Select'

import { ReactComponent as CheckIcon } from 'assets/images/checked_icon.svg'
import { ReactComponent as SortIcon } from 'assets/images/sort_icon.svg'

interface SortButtonProps {
  sortOptions: Option[]
  activeSortOption: string
  onSort: (value: Option) => void
}
const SortButton: React.FC<SortButtonProps> = ({
  sortOptions,
  activeSortOption,
  onSort,
}) => {
  const handleSort = (value: Option, onClose: () => void) => {
    onSort(value)
    onClose()
  }

  return (
    <Popover>
      {({ onClose }) => (
        <>
          <PopoverTrigger>
            <Button variant='outlinePrimary' color='gray.300' px='1rem'>
              <Box mr='5px'>
                <SortIcon />
              </Box>
              Sort
            </Button>
          </PopoverTrigger>
          <PopoverContent
            bgColor='#fff'
            boxShadow='lg'
            maxW='15rem'
            transform='translateX(1140px)'
            _focus={{
              outline: 0,
            }}
          >
            <PopoverBody>
              {sortOptions.map(option => (
                <PopoverItem
                  onClick={() => handleSort(option, onClose)}
                  key={option.value}
                  color='#000'
                  py='0.6rem'
                  px='0'
                  borderColor='gray.200'
                >
                  <Flex
                    w='100%'
                    justifyContent='space-between'
                    alignItems='center'
                  >
                    <Box>{option.label}</Box>
                    {activeSortOption === option.value ? <CheckIcon /> : ''}
                  </Flex>
                </PopoverItem>
              ))}
            </PopoverBody>
          </PopoverContent>
        </>
      )}
    </Popover>
  )
}

export default SortButton

import React from 'react'
import { Control, Controller } from 'react-hook-form'

import { Box, SimpleGrid } from '@chakra-ui/react'

import { Select } from 'components'
import { useLanguages } from 'hooks'
import { Enterprise } from 'types'

interface EnterpriseEditSectionProps {
  control: Control<any>
  list: Enterprise[]
}

const EnterpriseEditSection: React.FC<EnterpriseEditSectionProps> = ({
  control,
  list,
}) => {
  const { _t } = useLanguages()
  const formatEnterpriseOptions = (enterprises: Enterprise[]) => {
    return enterprises.map(enterprise => ({
      label: enterprise.name,
      value: enterprise.id,
    }))
  }

  return (
    <Box bg='white' py='1.5rem' px='2rem' boxShadow='lg' rounded='lg'>
      <SimpleGrid mt='1rem' columns={[1, 1, 2, 2]} spacing='2rem'>
        <Controller
          as={Select}
          control={control}
          defaultOptions={formatEnterpriseOptions(list)}
          name='enterprise'
          label={_t('centre.profile.enterprise')}
          rules={{
            required: _t('centre.profile.validate_enterprise_required'),
          }}
        />
      </SimpleGrid>
    </Box>
  )
}

export default EnterpriseEditSection

import { countries, nationalities } from './countryList'
import { Recipient } from 'types'

export const LICENSE_PRICE = 15

export const filterTypes = [
  {
    label: 'All',
    value: '',
  },
  {
    label: 'Private',
    value: 'private',
  },
  {
    label: 'Enterprise',
    value: 'enterprise',
  },
]

export const educationQualificationOptions = [
  {
    label: 'Doctoral degree',
    value: 'Doctoral degree',
  },
  {
    label: "Master's degree",
    value: "Master's degree",
  },
  {
    label: 'Professional Certification',
    value: 'Professional Certification',
  },
  {
    label: "Bachelor's degree",
    value: "Bachelor's degree",
  },
  {
    label: "Associate's degree",
    value: "Associate's degree",
  },
  {
    label: "College's diploma or equivalent",
    value: "College's diploma or equivalent",
  },
  {
    label: "High school's diploma /Junior college's diploma or equivalent",
    value: "High school's diploma /Junior college's diploma or equivalent",
  },
  {
    label: 'Others',
    value: 'Others',
  },
]

export const filterAccountStatus = [
  {
    label: 'All',
    value: '',
  },
  {
    label: 'Activated',
    value: 'activated',
  },
  {
    label: 'Deactivated',
    value: 'deactivated',
  },
]

export const sortRequestOptions = [
  { key: '1', label: 'All', value: '' },
  { key: '2', label: 'Subject', value: 'subject' },
  { key: '3', label: 'Request Date', value: 'startDateTime' },
]

export const genderOptions = [
  { value: 'male', label: 'Male' },
  { value: 'female', label: 'Female' },
]

export const typeOptions = [
  { value: 'enterprise', label: 'Enterprise' },
  { value: 'private', label: 'Private' },
]

export const durationOptions = [
  {
    value: 1,
    label: '1 Hour',
  },
  {
    value: 2,
    label: '2 Hours',
  },
  {
    value: 3,
    label: '3 Hours',
  },
  {
    value: 4,
    label: '4 Hours',
  },
]

export const countryOptions = countries.map(country => ({
  value: country,
  label: country,
}))

export const nationalityOptions = nationalities.map(national => ({
  value: national,
  label: national,
}))

export const sortAssignmentOptions = (_t: (key: string) => string) => [
  {
    label: _t('action.sortby.all'),
    value: '',
  },
  {
    label: _t('action.sortby.week'),
    value: 'week',
  },
  {
    label: _t('action.sortby.month'),
    value: 'month',
  },
]

export const FILES_LIMIT = 3
export const MAX_FILE_SIZE = 25 * 1024 * 1024 // 25MB

export const filterHistoryStatus = [
  {
    label: 'All',
    value: '',
  },
  {
    label: 'Completed',
    value: 'completed',
  },
  {
    label: 'Cancelled',
    value: 'cancelled',
  },
]

export const STRIPE_ELEMENT_OPTION_STYLE = {
  style: {
    base: {
      fontSize: '16px',
      color: '#000',
      fontWeight: '300',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    invalid: {
      color: '#9e2146',
    },
  },
}

export const reasonLearnerRequestRefundOptions = [
  {
    label: `Instructor couldn't teach well`,
    value: `Instructor couldn't teach well`,
  },
  {
    label: 'Instructor did not show up',
    value: 'Instructor did not show up',
  },
  {
    label: 'A lot of disruptive technical difficulties',
    value: 'A lot of disruptive technical difficulties',
  },
  {
    label: 'Lesson was inappropriate',
    value: 'Lesson was inappropriate',
  },
  {
    label: 'Others',
    value: 'Others',
  },
]

export const reasonLearnerCancelClassOptions = [
  {
    label: 'Can no longer make this class',
    value: 'Can no longer make this class',
  },
  {
    label: 'Found another alternative',
    value: 'Found another alternative',
  },
  {
    label: 'No longer interested in class',
    value: 'No longer interested in class',
  },
  {
    label: 'Others',
    value: 'Others',
  },
]

export const reasonTutorCancelClassOptions = [
  {
    label: 'Unable to attend as scheduled',
    value: 'Unable to attend as scheduled',
  },
  {
    label: 'No longer teaching this subject',
    value: 'No longer teaching this subject',
  },
  {
    label: 'Others',
    value: 'Others',
  },
]

export const bankAccountCountryOptions = [
  {
    label: 'Philippines',
    value: 'PH',
  },
  {
    label: 'Singapore',
    value: 'SG',
  },
]

export const filterRecipientOptions = [
  {
    label: 'All',
    value: 'All',
  },
  {
    label: 'Private Tutor',
    value: Recipient.PrivateTutor,
  },
  {
    label: 'Enterprise Tutor',
    value: Recipient.EnterpriseTutor,
  },
  {
    label: 'Private Student',
    value: Recipient.PrivateStudent,
  },
  {
    label: 'Enterprise Student',
    value: Recipient.EnterpriseStudent,
  },
  {
    label: 'MST SuperAdmin',
    value: Recipient.MSTSuperAdmin,
  },
  {
    label: 'Enterprise Admin',
    value: Recipient.EnterpriseAdmin,
  },
  {
    label: 'Center Admin',
    value: Recipient.CenterAdmin,
  },
  {
    label: 'MSTAdmin',
    value: Recipient.MSTAdmin,
  },
]

export const promoCodeSearchOptions = [
  { value: 'all', label: 'All' },
  { value: 'name', label: 'Name' },
  { value: 'code', label: 'Promo Code' },
]

export const sortPromoOptions = [
  { value: 'dateRange', label: 'Date Range - Latest first' },
  { value: 'dateCreated', label: 'Date Created' },
  { value: 'discount', label: 'Discount' },
  { value: 'usageCount', label: 'Usage Count' },
]

export const sortTutorAssignmentOptions = [
  { label: 'Alphabetical (Assignment)', value: 'alphabetical' },
  { label: 'Latest First (Deadline)', value: 'latestDeadline' },
  { label: 'Oldest First (Deadline)', value: 'oldestDeadline' },
  { label: 'All Submitted First (Completion)', value: 'allSubmitted' },
  { label: 'Latest First (Publish Date)', value: 'latestPublish' },
  { label: 'Oldest First (Publish Date)', value: 'oldestPublish' },
]

export const dayOfWeek = [
  { label: 'Sunday', value: 'sunday' },
  { label: 'Monday', value: 'monday' },
  { label: 'Tuesday', value: 'tuesday' },
  { label: 'Wednesday', value: 'wednesday' },
  { label: 'Thursday', value: 'thursday' },
  { label: 'Friday', value: 'friday' },
  { label: 'Saturday', value: 'saturday' },
]

export const responsivePadding = ['5%', '10%', '10%', '15%']

import React from 'react'

import { BoxProps, Flex, Text } from '@chakra-ui/react'
import { useAtom } from 'jotai'

import { filter, filterAtom } from '../atom'
import { Select } from 'components'
import { subject } from 'configs/apiPath'
import { useLanguages } from 'hooks'
import { formatCombinationOption } from 'utils/parser'

interface SubjectFilterProps {
  isEnabled?: boolean
  selectContainerProps?: BoxProps
}

export default ({
  isEnabled = false,
  selectContainerProps,
}: SubjectFilterProps) => {
  const { _t } = useLanguages()
  const [, setFilter] = useAtom(filterAtom)
  const [filterValues] = useAtom(filter)

  const onChange = (item: any) => {
    setFilter({
      ...filterValues,
      subject: {
        id: item?.value || '',
        name: item?.label || '',
      },
    })
  }

  return (
    <Flex direction='column' w='100%'>
      <Text as='h4' fontWeight='400' fontSize='0.75rem'>
        {_t('search.filter.subject')}
      </Text>
      <Select
        optionUrl={subject.list({ page: 1, limit: 100 })}
        formatOption={formatCombinationOption}
        isClearable={true}
        placeholder={_t('search.filter.placeholder_subject')}
        selectProps={{
          menuHeight: '8rem',
        }}
        defaultOptions={[]}
        defaultValue={
          filterValues?.subject?.id
            ? {
                value: filterValues?.subject?.id,
                label: filterValues?.subject?.name,
              }
            : undefined
        }
        containerProps={{
          mt: '0',
          width: '100%',
          ...selectContainerProps,
        }}
        onChange={onChange}
      />
    </Flex>
  )
}

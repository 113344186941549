import { AccountType, Language, Paginate, Subject } from 'types'

export type ConnectionFilter = Paginate & {
  search?: string
  type?: string
  status?: string
}

export interface ConnectionResult {
  data: Connection[]
}

export enum ConnectionType {
  STUDENT = 'Student',
  CENTRE = 'Center',
  PENDING = 'Pending',
  TUTOR = 'Tutor',
}

export enum ConnectionStatusType {
  NONE = '',
  UN_FRIEND = 'unfriend',
  PENDING = 'pending',
  IM_WAITING = 'im_waiting',
  CONFIRM = 'confirm',
  DECLINE = 'decline',
}

export interface Connection {
  id?: string
  name?: string
  firstName?: string
  lastName?: string
  email?: string
  avatar?: string
  role?: string
  rating?: number
  country?: string
  subjects?: Subject[]
  languages?: Language[]
  connection?: ConnectionStatus
  accountType?: AccountType
  accountTypes?: AccountType[]
  type?: string
}

export type FollowingFilterType = 'Tutor' | ''
export type FollowingFilter = Paginate & {
  type?: FollowingFilterType
}

export interface FollowingResult {
  data: FollowingModel[]
}

export interface FollowingModel {
  id?: string
}

export interface FriendRequest {
  id?: string
  firstName?: string
  lastName?: string
  avatar?: string
}

export interface Blocker {
  id?: string
  userId?: string
  blockerId?: string
}

export interface ConnectionStatus {
  status: ConnectionStatusType
  connectionId?: string
  id?: string
  idRequestFriend: string
}

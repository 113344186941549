import qs from 'query-string'

import {
  ApiUserType,
  CashFlowExportRequest,
  CombinationData,
  ExportClassData,
  UserExportRequest,
  FileInfo,
  FilterPromoOption,
  GetClassesParams,
  ListingParams,
  Paginate,
  SearchParam,
  SubscriptionInfoRequest,
  SubscriptionPackageRequest,
  TransactionAdminExportRequest,
  TransactionAdminInfoRequest,
  TransactionApprovalListRequest,
  TransactionInfoRequest,
  TransactionListRequest,
  UploadAssignmentAttachment,
  WalletInfoRequest,
} from 'types'
import { ConnectionFilter, FollowingFilter } from 'types/connection'

const version = 'v1'

const getListUrl = (baseUrl: string, params: ListingParams) => {
  if (!Object.keys(params).length) {
    return baseUrl
  }

  const { search, ...others } = params

  return qs.stringifyUrl({
    url: baseUrl,
    query: {
      ...others,
      ...(search && {
        search,
      }),
    },
  })
}

export const requestAccount = {
  create: () => `/${version}/user/registration`,
}

export const passwordPath = {
  confirmForgotPassword: () => `/user/${version}/user/confirm-forget-password`,
}

export const profilePath = {
  submitRole: (userId: string) => `/${version}/user/${userId}/role`,
  getRoles: (userId: string) => `/${version}/user/${userId}/roles`,
  profile: (userId: string) => `/user/${version}/user/${userId}/profile`,
  getUploadAvatarUrl: (userId: string, fileType: string) =>
    `/user/${version}/user/${userId}/avatar?fileType=${fileType}&contentType=image/${fileType}`,
  getUploadCredentialUrl: (userId: string, params: FileInfo) => {
    const url = `/user/${version}/user/${userId}/credential`
    return qs.stringifyUrl({
      url,
      query: {
        ...params,
      },
    })
  },
  getCredential: (userId: string, key: string, type: string) =>
    `/classroom/${version}/user/${userId}/resource-url?key=${key}&type=${type}`,
  getSubjectCombinations: (userId?: string) =>
    `/user/${version}/user/${userId}/subject-combinations?page=1&limit=50`,
  forgetPassword: () => `/user/${version}/user/forget-password`,
  forgetEmail: `/user/${version}/user/forget-email`,
  listFollowing: (userId: string, filter: FollowingFilter) =>
    `/user/${version}/user/${userId}/follows?type=${filter?.type || ''}&page=${
      filter?.page || 1
    }&limit=${filter?.limit || 20}`,
  friendRequests: (userId: string) =>
    `/user/${version}/user/${userId}/friend-requests`,
  friendStatus: (userId: string, targetId: string) =>
    `/user/${version}/user/${userId}/friend-status?id=${targetId}`,
}

export const adminPath = {
  export: ({
    userType,
    fileType,
    type,
    centerId,
    enterpriseId,
  }: UserExportRequest) => {
    let url = `/${version}/admin/users/export?userType=${userType}&fileType=${fileType}`

    if (type) {
      url = `${url}&type=${type}`
    }
    if (centerId) {
      url = `${url}&centerId=${centerId}`
    }
    if (enterpriseId) {
      url = `${url}&enterpriseId=${enterpriseId}`
    }

    return url
  },
  getListRole: (params?: ListingParams) => {
    if (!params) return `/${version}/roles`

    const { page, limit, search } = params
    let url = `/${version}/roles?page=${page}&limit=${limit}`
    if (search) {
      url = url + `&search=${search}`
    }

    return url
  },
  getRoleDetail: (roleId: string) => `/${version}/role/${roleId}`,
  getUsersBelongToRole: ({
    roleId,
    page,
    limit,
  }: { roleId?: string } & Paginate) => {
    if (!page && !limit) {
      return `/${version}/role/${roleId}/users`
    }

    return `/${version}/role/${roleId}/users?page=${page}&limit=${limit}`
  },
  createRole: () => `/${version}/role`,
  getListPermission: () => `/${version}/permissions`,
  getListUsers: (
    params?: ListingParams & {
      accountType?: string
      accountStatus?: string
      fromCreatedAt?: number
      toCreatedAt?: number
    }
  ) => {
    if (!params) return `/${version}/admin/users`

    const {
      page,
      limit,
      search,
      searchBy,
      type,
      enterpriseId,
      centerId,
      filterBy,
      accountType,
      accountStatus,
      fromCreatedAt,
      toCreatedAt,
    } = params
    let url = `/${version}/admin/users?page=${page}&limit=${limit}`
    if (search) {
      url = url + `&search=${search}`
    }
    if (type) {
      url = url + `&type=${type}`
    }
    if (filterBy) {
      url = url + `&filterBy=${filterBy}`
    }
    if (accountType) {
      url = url + `&accountType=${accountType}`
    }
    if (accountStatus) {
      url = url + `&accountStatus=${accountStatus}`
    }
    if (fromCreatedAt) {
      url = url + `&fromCreatedAt=${fromCreatedAt}`
    }
    if (toCreatedAt) {
      url = url + `&toCreatedAt=${toCreatedAt}`
    }
    if (search && searchBy) {
      url = url + `&searchBy=${searchBy}`
    }
    if (enterpriseId) {
      url = `${url}&enterpriseId=${enterpriseId}`
    }
    if (centerId) {
      url = `${url}&centerId=${centerId}`
    }

    return url
  },
  addUser: () => `/${version}/admin/user`,
  singleUser: (userId?: string) => `/${version}/admin/user/${userId}`,
  resetUserPassword: () => `/${version}/admin/user/resetpassword`,
  subjectCombination: {
    list: (params: ListingParams = {}) =>
      getListUrl(`/classroom/${version}/subject-combinations`, params),

    filterDetailList: (params: Omit<CombinationData, 'teachingLevelId'>) =>
      qs.stringifyUrl({
        url: `/classroom/${version}/subject-combinations`,
        query: {
          ...params,
          page: 1,
          limit: 50,
        },
      }),

    single: (id?: string) => {
      if (!id) {
        return `/classroom/${version}/subject-combination`
      }

      return `/classroom/${version}/subject-combination/${id}`
    },
  },
  message: {
    update: (locale: string) => `/user/${version}/message/${locale}`,
  },
  center: {
    list: (params: ListingParams = {}) =>
      getListUrl(`/classroom/${version}/admin/centers`, params),
  },
  getCenterListUsers: (
    params: ListingParams & {
      centerId: string
    }
  ) => {
    const { page, limit, search, filterBy, centerId } = params
    let url = `/classroom/${version}/center/${centerId}/users?page=${page}&limit=${limit}`

    switch (filterBy) {
      case 'admin':
        url = `/classroom/${version}/center/${centerId}/admins?page=${page}&limit=${limit}`
        break
      case 'student':
      case 'tutor':
        url = url + `&filterBy=${filterBy}`
        break
      default:
        break
    }

    if (search) {
      url = url + `&search=${search}`
    }

    return url
  },
  userProfiles: (userIds: string[]) =>
    `/user/${version}/user/profiles?userIds=${userIds.join(',')}`,
}

export const messageUrl = process.env.REACT_APP_BUCKET_URL

export const country = {
  create: () => `/${version}/country`,
  list: (params: ListingParams = {}) =>
    getListUrl(`/${version}/countries`, params),
  update: (id: string) => `/${version}/country/${id}`,
}

export const system = {
  create: () => `/${version}/system`,
  list: (params: ListingParams = {}) =>
    getListUrl(`/${version}/systems`, params),
  update: (id: string) => `/${version}/system/${id}`,
}

export const subject = {
  create: () => `/classroom/${version}/subject`,
  list: (params: ListingParams = {}) =>
    getListUrl(`/classroom/${version}/subjects`, params),
  update: (id: string) => `/classroom/${version}/subject/${id}`,
  userList: (userId: string, params: ListingParams = {}) =>
    getListUrl(`/classroom/${version}/user/${userId}/subjects`, params),
}

export const teachingLevel = {
  create: () => `/classroom/${version}/teachinglevel`,
  list: (params: ListingParams = {}) =>
    getListUrl(`/classroom/${version}/teachinglevels`, params),
  update: (id: string) => `/classroom/${version}/teachinglevel/${id}`,
  userList: (userId: string, params: ListingParams = {}) =>
    getListUrl(`/classroom/${version}/user/${userId}/teachinglevels`, params),
}

export const centre = {
  create: () => `/classroom/${version}/center`,
  list: (enterpriseId: string, listingParams: ListingParams = {}) =>
    getListUrl(
      `/classroom/${version}/centers?enterpriseId=${enterpriseId}`,
      listingParams
    ),
  listByAdmin: (params: ListingParams = {}) =>
    getListUrl(`/classroom/${version}/admin/centers`, params),
  detail: (centreId: string) => `/classroom/${version}/center/${centreId}`,
  update: (centreId: string) => `/classroom/${version}/center/${centreId}`,
  delete: (centreId: string) => `/classroom/${version}/center/${centreId}`,
  getUrlUploadAvatar: (centreId: string) =>
    `/classroom/${version}/center/${centreId}/avatar?fileType=jpg&contentType=image/jpg`,
  getUrlUploadBanner: (centreId: string) =>
    `/classroom/${version}/center/${centreId}/banner?fileType=jpg&contentType=image/jpg`,
  teachingSubjectList: (centreId: string) =>
    `/classroom/${version}/center/${centreId}/subject-combinations`,
  getListUser: (centreId: string, params: ListingParams = {}) =>
    getListUrl(`/classroom/${version}/center/${centreId}/users`, params),
  profile: (centerId: string) =>
    `/classroom/${version}/center/${centerId}/profile`,
}

export const enterprise = {
  create: () => `/${version}/enterprise`,
  update: (enterpriseId: string) => `/${version}/enterprise/${enterpriseId}`,
  delete: (enterpriseId: string) => `/${version}/enterprise/${enterpriseId}`,
  list: (params: ListingParams = {}) =>
    getListUrl(`/${version}/enterprises`, params),
  detail: (userId: string) =>
    `/${version}/enterprise/detail/enterprise?userId=${userId}`,
  detailForSuperAdmin: (enterpriseId: string) =>
    `/${version}/enterprise/${enterpriseId}`,
  getUrlUploadAvatar: (enterpriseId: string) =>
    `/${version}/enterprise/${enterpriseId}/avatar?fileType=jpg&contentType=image/jpg`,
  getUrlUploadBanner: (enterpriseId: string) =>
    `/${version}/enterprise/${enterpriseId}/banner?fileType=jpg&contentType=image/jpg`,
  profile: (enterpriseId: string) =>
    `/${version}/enterprise/${enterpriseId}/profile`,
  centresForEnterprise: (enterpriseId: string, page: number, limit: number) =>
    `/${version}/enterprise/${enterpriseId}/centers?page=${page}&limit=${limit}`,
  export: (fileType: string) =>
    `/${version}/enterprise/export?fileType=${fileType}`,
}

export const request = {
  create: (userId: string) =>
    `/classroom/${version}/user/${userId}/adhoc-request`,
  list: (userId: string, params: ListingParams = {}) =>
    getListUrl(`/classroom/${version}/user/${userId}/adhoc-requests`, params),
  details: (userId: string, requestId: string) =>
    `/classroom/${version}/user/${userId}/adhoc-request/${requestId}`,
  delete: (userId: string, requestId: string) =>
    `/classroom/${version}/user/${userId}/adhoc-request/${requestId}`,

  // for tutor
  tutorList: (userId: string, params: ListingParams = {}) =>
    getListUrl(`/classroom/${version}/tutor/${userId}/adhoc-requests`, params),
  tutorDetail: (userId: string, requestId: string) =>
    `/classroom/${version}/tutor/${userId}/adhoc-request/${requestId}`,
  tutorDecline: (requestId: string) =>
    `/classroom/${version}/tutor/adhoc-request/${requestId}/decline`,

  //for admin
  adminCreate: () => `/classroom/${version}/admin/adhoc-request`,
  adminList: (params: ListingParams = {}) =>
    getListUrl(`/classroom/${version}/admin/adhoc-requests`, params),
  adminDetails: (requestId: string) =>
    `/classroom/${version}/admin/adhoc-request/${requestId}`,
  adminDelete: (requestId: string) =>
    `/classroom/${version}/admin/adhoc-request/${requestId}`,
}

export const language = {
  create: () => `/${version}/language`,
  list: (params: ListingParams = {}) =>
    getListUrl(`/${version}/languages`, params),
  detail: (id: string) => `/${version}/language/${id}`,
  userList: (userId: string, params: ListingParams = {}) =>
    getListUrl(`/${version}/user/${userId}/languages`, params),
}

export const bid = {
  // for user
  list: (userId: string, requestId: string, params: ListingParams = {}) =>
    getListUrl(
      `/classroom/${version}/user/${userId}/adhoc-request/${requestId}/adhoc-bids`,
      params
    ),
  decline: (userId: string, requestId: string, bidId: string) =>
    `/classroom/${version}/user/${userId}/adhoc-request/${requestId}/adhoc-bid/${bidId}/decline`,
  tutorCreate: (userId: string, requestId: string) =>
    `/classroom/${version}/user/${userId}/adhoc-request/${requestId}/adhoc-bid/`,
  myBid: (userId: string, params: ListingParams = {}) =>
    getListUrl(`/classroom/${version}/user/${userId}/adhoc-bids`, params),
  detail: (userId: string, requestId: string, bidId: string) =>
    `/classroom/${version}/user/${userId}/adhoc-request/${requestId}/adhoc-bid/${bidId}`,
  delete: (userId: string, requestId: string, bidId: string) =>
    `/classroom/${version}/user/${userId}/adhoc-request/${requestId}/adhoc-bid/${bidId}`,
  // for admin
  adminList: (requestId: string, params: ListingParams = {}) =>
    getListUrl(
      `/classroom/${version}/admin/adhoc-request/${requestId}/adhoc-bids`,
      params
    ),
  adminDecline: (requestId: string, bidId: string) =>
    `/classroom/${version}/admin/adhoc-request/${requestId}/adhoc-bid/${bidId}/decline`,
  adminDetail: (requestId: string, bidId: string) =>
    `/classroom/${version}/admin/adhoc-request/${requestId}/adhoc-bid/${bidId}`,
  adminDelete: (requestId: string, bidId: string) =>
    `/classroom/${version}/admin/adhoc-request/${requestId}/adhoc-bid/${bidId}`,
}

export const file = {
  createUrl: (userId: string, requestId: string) =>
    `/classroom/${version}/user/${userId}/adhoc-request/${requestId}/attachment`,
  getUrl: (userId: string, requestId: string, key: string) =>
    `/classroom/${version}/user/${userId}/adhoc-request/${requestId}/attachment-url?key=adhoc-request-attachments/${key}`,
}

export const classSessionPath = {
  list: (params: ListingParams = {}) =>
    getListUrl(`/classroom/${version}/class-sessions`, params),
  create: (classId: string) =>
    `/classroom/${version}/class/${classId}/class-sessions`,
  update: (classId: string) =>
    `/classroom/${version}/class/${classId}/class-sessions`,
  delete: (classId: string) =>
    `/classroom/${version}/class/${classId}/class-sessions`,
  getLink: (classId: string, sessionId: string) =>
    `/classroom/${version}/class/${classId}/class-session/${sessionId}/class-session-link`,
  createUploadAttachment: (classId: string, sessionId: string) =>
    `/classroom/${version}/class/${classId}/class-session/${sessionId}/attachment`,
  downloadAllAttachments: (classId: string, sessionId: string) =>
    `/classroom/${version}/class/${classId}/class-session/${sessionId}/download-attachments`,
  downloadAttachment: (classId: string, sessionId: string, key: string) =>
    `/classroom/${version}/class/${classId}/class-session/${sessionId}/attachment-url?key=${key}`,
}

export const classPath = {
  create: () => `/classroom/${version}/class`,
  list: (params: GetClassesParams) => {
    if (!params) return `/classroom/${version}/classes`

    const {
      page,
      limit,
      search,
      searchBy,
      order,
      orderBy,
      filterBy,
      statusSession,
      startCreatedAt,
      endCreatedAt,
      startDateTime,
      endDateTime,
      type,
      openTo,
      minFeeEnterprise,
      maxFeeEnterprise,
      minFee,
      maxFee,
      minNumberEnterpriseLearners,
      maxNumberEnterpriseLearners,
      minNumberLearners,
      maxNumberLearners,
      startPublishDate,
      endPublishDate,
      status,
      enterpriseId,
      centerId,
    } = params
    let url = `/classroom/${version}/classes?page=${page}&limit=${limit}`

    if (search) {
      url = `${url}&search=${search}`
    }
    if (searchBy) {
      url = `${url}&searchBy=${searchBy}`
    }
    if (order) {
      url = `${url}&order=${order}`
    }
    if (orderBy) {
      url = `${url}&orderBy=${orderBy}`
    }
    if (filterBy) {
      url = `${url}&filterBy=${filterBy}`
    }
    if (statusSession) {
      url = `${url}&statusSession=${statusSession}`
    }
    if (startCreatedAt) {
      url = `${url}&startCreatedAt=${startCreatedAt}&endCreatedAt=${endCreatedAt}`
    }
    if (startDateTime) {
      url = `${url}&startDateTime=${startDateTime}&endDateTime=${endDateTime}`
    }
    if (type) {
      url = `${url}&type=${type}`
    }
    if (openTo) {
      url = `${url}&openTo=${openTo}`
    }
    if (minFeeEnterprise || maxFeeEnterprise) {
      url = `${url}&minFeeEnterprise=${minFeeEnterprise}&maxFeeEnterprise=${maxFeeEnterprise}`
    }
    if (minFee || maxFee) {
      url = `${url}&minFee=${minFee}&maxFee=${maxFee}`
    }
    if (minNumberEnterpriseLearners || maxNumberEnterpriseLearners) {
      url = `${url}&minNumberEnterpriseLearners=${minNumberEnterpriseLearners}&maxNumberEnterpriseLearners=${maxNumberEnterpriseLearners}`
    }
    if (minNumberLearners || maxNumberLearners) {
      url = `${url}&minNumberLearners=${minNumberLearners}&maxNumberLearners=${maxNumberLearners}`
    }
    if (startPublishDate) {
      url = `${url}&startPublishDate=${startPublishDate}&endPublishDate=${endPublishDate}`
    }
    if (status) {
      url = `${url}&status=${status}`
    }
    if (enterpriseId) {
      url = `${url}&enterpriseId=${enterpriseId}`
    }
    if (centerId) {
      url = `${url}&centerId=${centerId}`
    }

    return url
  },
  detail: (classId: string) => `/classroom/${version}/class/${classId}`,
  delete: (classId: string) => `/classroom/${version}/class/${classId}`,
  update: (classId: string) => `/classroom/${version}/class/${classId}`,
  listLearner: (userId: string, params: ListingParams) =>
    getListUrl(`/user/${version}/user/${userId}/learners`, params),
  listTutor: (userId: string, params: ListingParams) =>
    getListUrl(`/user/${version}/user/${userId}/tutors`, params),
  createUploadBanner: (classId: string) =>
    `/classroom/${version}/class/${classId}/banner`,
  createUploadAttachment: (classId: string) =>
    `/classroom/${version}/class/${classId}/attachment`,
  listLearnerUpcomingClasses: (
    userId: string,
    params: ListingParams & { startDateTime: number; days: number }
  ) =>
    getListUrl(
      `/classroom/${version}/learner/${userId}/upcoming-classes`,
      params
    ),
  listLearnerHistoryClasses: (userId: string, params: ListingParams = {}) =>
    getListUrl(
      `/classroom/${version}/learner/${userId}/history-classes`,
      params
    ),
  learnerCancelClass: (userId: string, classId: string) =>
    `/classroom/${version}/learner/${userId}/cancel-class/${classId}`,
  listTutorUpcomingClasses: (
    userId: string,
    params: ListingParams & { startDateTime: number; days: number }
  ) =>
    getListUrl(
      `/classroom/${version}/tutor/${userId}/upcoming-classes`,
      params
    ),
  listTutorHistoryClasses: (userId: string, params: ListingParams = {}) =>
    getListUrl(`/classroom/${version}/tutor/${userId}/history-classes`, params),
  tutorCancelClass: (userId: string, classId: string) =>
    `/classroom/${version}/tutor/${userId}/cancel-class/${classId}`,
  listDraftClasses: (userId: string, params: ListingParams = {}) =>
    getListUrl(`/classroom/${version}/tutor/${userId}/draft-classes`, params),
  downloadAllAttachments: (classId: string) =>
    `/classroom/${version}/class/${classId}/download-attachments`,
  downloadAttachment: (classId: string, key: string) =>
    `/classroom/${version}/class/${classId}/attachment-url?key=${key}`,
  export: ({
    type,
    fileType,
    centerId,
    enterpriseId,
    status,
  }: ExportClassData) => {
    let url = `/classroom/${version}/class/export?status=${status}&fileType=${fileType}`

    if (type) {
      url = `${url}&type=${type}`
    }
    if (centerId) {
      url = `${url}&centerId=${centerId}`
    }
    if (enterpriseId) {
      url = `${url}&enterpriseId=${enterpriseId}`
    }

    return url
  },
  importClasses: `/classroom/${version}/class/upload`,
  getImportClassesUrl: (fileType: string, contentType: string) =>
    `/classroom/${version}/class/excel/upload?fileType=${fileType}&contentType=application/vnd.openxmlformats-officedocument.spreadsheetml.sheet`,
  initMeeting: () => `/classroom/${version}/class/init`,
  listMyLesson: (userId: string, params: ListingParams = {}) =>
    getListUrl(`/classroom/${version}/user/${userId}/lessons`, params),
  listMyContent: (userId: string, params: ListingParams = {}) =>
    getListUrl(`/classroom/${version}/user/${userId}/contents`, params),
  recurrence: () => `/classroom/${version}/class/recurrence`,
}

export const assignment = {
  learnerList: (params: ListingParams = {}) =>
    getListUrl(`/classroom/${version}/learner-assignments`, params),
  tutorList: (params: ListingParams = {}) =>
    getListUrl(`/classroom/${version}/assignments`, params),
  create: () => `/classroom/${version}/assignment`,
  detail: (id: string) => `/classroom/${version}/assignment/${id}`,
  learnerDetail: (id: string) =>
    `/classroom/${version}/learner-assignment/${id}`,
  update: (id: string) => `/classroom/${version}/assignment/${id}`,
  delete: (id: string) => `/classroom/${version}/assignment/${id}`,
  uploadAttachment: ({
    id,
    status,
    fileName,
    contentType,
  }: UploadAssignmentAttachment) =>
    `/classroom/${version}/assignment/${id}/attachment?status=${status}&fileName=${fileName}&contentType=${contentType}`,
  downloadAllAttachments: (assignmentId: string) =>
    `/classroom/${version}/assignment/${assignmentId}/download-attachments`,
  getAttachmentUrl: (
    assignmentId: string,
    key: string,
    type: 'download' | 'preview'
  ) =>
    `/classroom/${version}/assignment/${assignmentId}/attachment-url?key=${key}&type=${type}`,
  downloadAllSubmissions: (assignmentId: string) =>
    `/classroom/${version}/assignment/${assignmentId}/download-submissions`,
  getSubmissionAttachmentUrl: (assignmentId: string, key: string) =>
    `/classroom/${version}/assignment/${assignmentId}/submission-url?key=${key}`,
  uploadSubmission: ({
    id,
    fileName,
    contentType,
  }: UploadAssignmentAttachment) =>
    `/classroom/${version}/assignment/${id}/submission?fileName=${fileName}&contentType=${contentType}`,
  deleteSubmissions: (assignmentId: string) =>
    `/classroom/${version}/assignment/${assignmentId}/attachments`,
}

export const tutor = {
  learnerList: (userId: string, params: ListingParams = {}) =>
    getListUrl(`/user/${version}/user/${userId}/friends`, {
      type: 'Student',
      ...params,
    }),
  sessionList: (params: ListingParams = {}) =>
    getListUrl(`/classroom/${version}/tutor-class-sessions`, params),
  search: (params: SearchParam = {}) =>
    getListUrl(`/${version}/tutor/search`, params),
}

export const walletPath = {
  show: ({ type, id }: { type: ApiUserType; id: string }) =>
    `/transaction/${version}/${type}/${id}/wallet`,
  card: () => `/${version}/payment/card`,
  updateBankAccount: ({ type, id }: WalletInfoRequest) =>
    `/transaction/${version}/${type}/${id}/wallet/bank`,
  topup: () => `/transaction/${version}/payment/topup`,
  writeOff: () => `/transaction/${version}/wallet`,
}

export const orderPath = {
  purchase: () => `/transaction/${version}/order/purchase`,
  adminPurchase: () => `/transaction/${version}/admin/order/purchase`,
  payment: () => `/transaction/${version}/payment/purchase`,
}

export const transactionPath = {
  list: ({ type, id }: TransactionListRequest, params: ListingParams) =>
    getListUrl(`/transaction/${version}/${type}/${id}/transaction`, params),
  show: ({ type, id, transactionId }: TransactionInfoRequest) =>
    `/transaction/${version}/${type}/${id}/transaction/${transactionId}`,
  adminList: (params: ListingParams) =>
    getListUrl(`/transaction/${version}/admin/transaction`, params),
  adminShow: ({ transactionId }: TransactionAdminInfoRequest) =>
    `/transaction/${version}/admin/transaction/${transactionId}`,
  adminExport: (params: TransactionAdminExportRequest) =>
    getListUrl(`/transaction/${version}/admin/transaction/export`, params),
  adminCreateWithdrawal: () =>
    `/transaction/${version}/admin/transaction/withdrawal`,
  adminUpdateWithdrawal: (transactionId: string) =>
    `/transaction/${version}/admin/transaction/withdrawal/${transactionId}`,
  approvalList: (
    { type, id }: TransactionApprovalListRequest,
    params: ListingParams
  ) =>
    getListUrl(
      `/transaction/${version}/${type}/${id}/transaction/approval`,
      params
    ),
}

export const refundPath = {
  request: () => `/transaction/${version}/refund/request`,
  approval: () => `/transaction/${version}/refund/approval`,
}

export const learnerPath = {
  search: (params: SearchParam = {}) =>
    getListUrl(`/${version}/student/search`, params),
  adHocRequest: (userId: string) =>
    `/classroom/${version}/user/${userId}/adhoc-request`,
}

export const calendarPath = {
  get: (params: ListingParams = {}) =>
    getListUrl(`/classroom/${version}/calendar`, {
      ...params,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    }),
}

export const campaignPath = {
  list: (params: ListingParams = {}) =>
    getListUrl(`/${version}/admin/campaigns`, params),
  detail: (id: string) => `/${version}/admin/campaigns/${id}`,
  create: () => `/${version}/admin/campaigns`,
  display: (params: ListingParams = {}) =>
    getListUrl(`/${version}/campaigns/display`, params),
  engage: (id: string) => `/${version}/campaigns/${id}/engage`,
  createUploadBannerUrl: (id: string) =>
    `/${version}/admin/campaigns/${id}/banner`,
  update: (id: string) => `/${version}/admin/campaigns/${id}`,
  delete: (id: string) => `/${version}/admin/campaigns/${id}`,
}

export const cashFlowPath = {
  list: (params: ListingParams = {}) =>
    getListUrl(`/transaction/${version}/cash-flow`, params),
  show: (cashFlowId: string) =>
    `/transaction/${version}/cash-flow/${cashFlowId}`,
  export: (params: CashFlowExportRequest) =>
    getListUrl(`/transaction/${version}/cash-flow/export`, params),
  freeze: () => `/transaction/${version}/cash-flow/freeze`,
  update: () => `/transaction/${version}/cash-flow`,
}

export const subscriptionPath = {
  show: ({ userId, enterpriseId }: SubscriptionInfoRequest) => {
    if (enterpriseId) {
      return `/transaction/${version}/user/${userId}/subscription?enterpriseId=${enterpriseId}`
    }
    return `/transaction/${version}/user/${userId}/subscription`
  },
  package: ({
    type,
    enterpriseId,
    adminId,
    tutorId,
  }: SubscriptionPackageRequest) => {
    const url = `/${version}/payment/subscription-packages?type=${type}`
    if (enterpriseId) {
      return `${url}&enterpriseId=${enterpriseId}`
    }
    if (tutorId) {
      return `${url}&tutorId=${tutorId}`
    }
    if (adminId) {
      return `${url}&adminId=${adminId}`
    }
    return url
  },
  order: () => `/transaction/${version}/order/subscription`,
  cancel: () => `/transaction/${version}/order/subscription`,
}

export const connectionPath = {
  list: (userId: string, params: ConnectionFilter = {}) =>
    getListUrl(`/user/${version}/user/${userId}/friends`, params),
  add: (userId: string) => `/user/${version}/user/${userId}/friend`,
  del: (userId: string) => `/user/${version}/user/${userId}/friend`,
  accept: (userId: string) => `/user/${version}/user/${userId}/friend-request`,
  decline: (userId: string) => `/user/${version}/user/${userId}/friend-request`,
  adminDelete: () => `/${version}/admin/connection`,
  adminAdd: () => `/${version}/admin/connections`,
}

export const blockPath = {
  block: () => `/user/${version}/user/block`,
  unBlock: () => `/user/${version}/user/unblock`,
  list: (userId: string) => `/user/${version}/user/blocks?userId=${userId}`,
}

export const tokenPath = {
  getFirebaseToken: () => `/${version}/firebase-auth`,
}

export const notification = {
  create: () => `/${version}/notification`,
  update: (notificationId: string) =>
    `/${version}/notification/${notificationId}`,
  delete: (notificationId: string) =>
    `/${version}/notification/${notificationId}`,
  getTriggerPoints: () => `/${version}/notification/trigger-points`,
  getTriggerPointDetail: (triggerPointId: string) =>
    `/${version}/notification/trigger-point/${triggerPointId}`,
  list: (param: ListingParams = {}) =>
    getListUrl(`/${version}/notifications`, param),
  read: (id: string) => `/${version}/notification/mark-read/${id}`,
  readAll: () => `/${version}/notification/mark-all-read`,
  detail: (id: string) => `/${version}/notification/${id}`,
}

export const commissionPath = {
  list: (params: SearchParam = {}) =>
    getListUrl(`/${version}/commissions`, params),
  update: () => `/${version}/commission`,
}

export const adhocRequestPath = {
  createUploadAttachment: (userId: string, requestId: string) =>
    `/classroom/${version}/user/${userId}/adhoc-request/${requestId}/attachment`,
}

export const exportPath = {
  session: (params: ListingParams = {}) =>
    getListUrl(`/${version}/report/session/downloaded`, {
      ...params,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    }),
}

export const authPath = {
  signIn: `/user/${version}/signin`,
  signUp: `/user/${version}/signup`,
  changePassword: `/user/${version}/change-password`,
}

export const guestPath = {
  search: (params: SearchParam = {}) =>
    getListUrl(`/${version}/guest/search`, params),
}

export const conversationPath = {
  create: () => `/user/${version}/conversation`,
  update: (id: string) => `/user/${version}/conversation/${id}`,
  updateLastSeen: (id: string) =>
    `/user/${version}/conversation/${id}/last-seen`,
}

export const chatMessagePath = {
  create: () => `/user/${version}/chat`,
  search: (userId: string, params: ListingParams = {}) =>
    getListUrl(`/user/${version}/user/${userId}/chat`, params),
  downloadAttachment: (chatId: string, type: 'preview' | 'download') =>
    `/user/${version}/chat/${chatId}/attachment-url?type=${type}`,
}

export const promoPath = {
  list: (params: ListingParams & FilterPromoOption = {}) =>
    getListUrl(`/${version}/promo`, params),
  create: () => `/${version}/promo`,
  detail: (promoId: string) => `/${version}/promo/${promoId}`,
  update: (promoId: string) => `/${version}/promo/${promoId}`,
  delete: (promoId: string) => `/${version}/promo/${promoId}`,
  userList: (params: ListingParams = {}) =>
    getListUrl(`/${version}/user/promo`, params),
  export: (fileType: string) => `/${version}/promo/export?fileType=${fileType}`,
}
export const reviewPath = {
  create: () => `/classroom/${version}/review`,
  list: (params: ListingParams = {}) =>
    getListUrl(`/classroom/${version}/reviews`, params),
}

export const explorePath = {
  display: (params: ListingParams = {}) =>
    getListUrl(`/${version}/explore`, params),
  list: (params: ListingParams = {}) =>
    getListUrl(`/${version}/admin/explore`, params),
  detail: (id: string) => `/${version}/admin/explore/${id}`,
  create: () => `/${version}/admin/explore`,
  update: (id: string) => `/${version}/admin/explore/${id}`,
  delete: (id: string) => `/${version}/admin/explore/${id}`,
}

import { useCallback } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'

import { useToastError } from './helpers'
import { defaultFetcher } from 'apis/fetcher'
import {
  createRole,
  deleteRole,
  removeUsersToRole,
  updateRoleInfo,
} from 'apis/role'
import { adminPath } from 'configs/apiPath'
import { useInvalidateUrl, useLanguages, useToast } from 'hooks'
import {
  AdminRoleInfo,
  Meta,
  Paginate,
  Role,
  UpdateRoleInfo,
  UserProfile,
} from 'types'

type RoleQuery = {
  data: Role[]
} & Meta

export const useAvailableRoles = ({
  page,
  limit,
  search,
}: Paginate & {
  search?: string
}) => {
  return useQuery<RoleQuery>(adminPath.getListRole({ page, limit, search }))
}

export type RoleDetailQuery = {
  data: AdminRoleInfo
}

export const useRoleDetail = (roleId: string) => {
  return useQuery<RoleDetailQuery>(adminPath.getRoleDetail(roleId), {
    enabled: !!roleId,
  })
}

type UsersBelongToRole = {
  data: UserProfile[]
} & Meta

export const useUsersBelongToRole = ({
  roleId,
  page,
  limit,
}: {
  roleId?: string
} & Paginate) => {
  return useQuery<UsersBelongToRole>(
    adminPath.getUsersBelongToRole({ roleId, page, limit }),
    {
      enabled: !!roleId,
    }
  )
}

export const getTotalUsersBelongToRole = async ({
  roleIds,
  page,
  limit,
}: {
  roleIds: string[]
} & Paginate): Promise<{
  [key: string]: number
}> => {
  const promises: Promise<UsersBelongToRole>[] = []
  roleIds.forEach(roleId =>
    promises.push(
      defaultFetcher<UsersBelongToRole>(
        adminPath.getUsersBelongToRole({ roleId, page, limit })
      )
    )
  )

  const data = await Promise.all(promises)

  return roleIds.reduce(
    (acc, roleId, index) => ({
      ...acc,
      [roleId]: data[index].meta.total,
    }),
    {}
  )
}

export const totalUsersBelongToRoleQueryKey = 'totalUsersBelongToRole'

export const useUpdateRole = () => {
  const queryClient = useQueryClient()

  return useMutation(updateRoleInfo, {
    // run before mutate function fired
    onMutate: ({
      roleId,
      name,
      permissions,
    }: UpdateRoleInfo & {
      roleId: string
    }) => {
      const oldRoleDetail = queryClient.getQueryData<RoleDetailQuery>(
        adminPath.getRoleDetail(roleId)
      )

      queryClient.setQueryData(adminPath.getRoleDetail(roleId), {
        data: {
          ...oldRoleDetail?.data,
          name: name || oldRoleDetail?.data.name,
          permissions: permissions || oldRoleDetail?.data.permissions,
        },
      })

      return { oldRoleDetail }
    },
    onError: (_error, values, context) => {
      queryClient.setQueryData(
        adminPath.getRoleDetail(values.roleId),
        context?.oldRoleDetail
      )
    },
    onSuccess: (_, { roleId }) => {
      queryClient.invalidateQueries(adminPath.getRoleDetail(roleId))
    },
  })
}

export const useInvalidateUsersToRole = ({
  roleId,
  page,
  limit,
}: {
  roleId: string
} & Paginate) => {
  const queryClient = useQueryClient()

  return useCallback(
    () =>
      queryClient.invalidateQueries(
        adminPath.getUsersBelongToRole({ roleId, page, limit })
      ),
    [queryClient, roleId, page, limit]
  )
}

export const useRemoveUsersToRole = () => {
  const queryClient = useQueryClient()

  return useMutation(removeUsersToRole, {
    onSuccess: (_, { roleId, page, limit }) => {
      queryClient.invalidateQueries(
        adminPath.getUsersBelongToRole({ roleId, page, limit })
      )
    },
  })
}

export const useCreateRole = () => {
  const toast = useToast()
  const invalidate = useInvalidateUrl(adminPath.getListRole())
  const { _t } = useLanguages()

  return useMutation(createRole, {
    onSuccess: async () => {
      await invalidate()
      toast({
        title: 'Success!',
        description: _t('message.success.role_add'),
        status: 'success',
      })
    },
    onError: useToastError(),
  })
}

export const useDeleteRole = () => {
  const toast = useToast()
  const invalidate = useInvalidateUrl(adminPath.getListRole())
  const { _t } = useLanguages()

  return useMutation(deleteRole, {
    onSuccess: async () => {
      await invalidate()
      toast({
        title: 'Success!',
        description: _t('message.success.role_remove'),
        status: 'success',
      })
    },
    onError: useToastError(),
  })
}

import React from 'react'

import { Box, Button, Flex, Text } from '@chakra-ui/react'
import format from 'date-fns/format'

import UpcomingClassItem from './UpcomingClassItem'
import { useLanguages } from 'hooks'
import { CalendarDay } from 'queries/calendar'
import { ReviewInfo } from 'types'

import { ReactComponent as ChevronLeftIcon } from 'assets/images/chevron-left.svg'
import { ReactComponent as ChevronRightIcon } from 'assets/images/chevron-right.svg'

interface UpcomingClassesProps {
  date?: Date
  data?: CalendarDay
  contentHeight?: string
  onAddClass?: () => void
  onJoinSession?: (props: ReviewInfo) => void
  onNextDate?: () => void
  onPreviousDate?: () => void
}

const UpcomingClasses: React.FC<UpcomingClassesProps> = ({
  date = new Date(),
  data = {},
  contentHeight,
  onAddClass,
  onJoinSession,
  onNextDate,
  onPreviousDate,
}) => {
  const { data: classes = [] } = data

  const { _t } = useLanguages()

  return (
    <Box backgroundColor='#fff' py='1rem' height='100%'>
      <Flex justify='space-between' align='center'>
        <Box
          cursor='pointer'
          display={{ base: 'inherit', md: 'none' }}
          onClick={onPreviousDate}
        >
          <ChevronLeftIcon />
        </Box>
        <Box>
          <Box as='h1' className={'blue-purple-gradient-text'}>
            {format(date, 'd MMM yyyy')}
          </Box>
        </Box>
        <Box
          cursor='pointer'
          display={{ base: 'inherit', md: 'none' }}
          onClick={onNextDate}
        >
          <ChevronRightIcon />
        </Box>
      </Flex>
      <Box as='p' mb='0.5rem'>
        {_t('dashboard.upcoming_classes.title')} ({classes.length})
      </Box>

      <Box height={contentHeight} overflow='auto'>
        {classes.map((item: any, index: number) => (
          <UpcomingClassItem
            key={index}
            data={item}
            onJoinSession={onJoinSession}
          />
        ))}
        {!classes.length && (
          <Text
            display='flex'
            justifyContent='center'
            alignItems='center'
            textAlign='center'
            height='100%'
            textStyle='detailText'
            flexDirection='column'
          >
            {_t('dashboard.upcoming_classes.empty')}
            {!!onAddClass && (
              <Button mt='1rem' variant='outlinePrimary' onClick={onAddClass}>
                ADD CLASS
              </Button>
            )}
          </Text>
        )}
      </Box>
    </Box>
  )
}

export default UpcomingClasses

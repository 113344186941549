import React from 'react'

import { Avatar, Flex, Text } from '@chakra-ui/react'
import { format } from 'date-fns'

import { Rating } from 'components'
import { CreatedReview } from 'types'
import { getFullName } from 'utils/parser'

interface SingleReviewProps {
  item: CreatedReview
  isPreview?: boolean
}

const SingleReview: React.FC<SingleReviewProps> = ({
  item,
  isPreview = false,
}) => {
  return (
    <Flex key={item.id} flexDirection='row' mb='2rem'>
      {!isPreview && (
        <Avatar
          src={item.confirmedStudent.avatar}
          name={getFullName(item.confirmedStudent)}
          mr='0.75rem'
        />
      )}
      <Flex flexDirection='column' width={isPreview ? '95%' : '90%'}>
        <Rating rate={item.rate} showRate={false} />
        <Flex flexDirection='row' mt='0.25rem'>
          <Text fontSize='0.85rem' color='gray.500' mr='0.5rem' mb='0.5rem'>
            by {item.confirmedStudent?.firstName}
            {item.confirmedStudent?.lastName}
          </Text>
          <Text fontSize='0.85rem' as='i' color='gray.300'>
            on {format(item.createdAt, 'dd MMM yyyy')}
          </Text>
        </Flex>
        <Text as='h6' fontWeight='600'>
          {item.session.name}
        </Text>
        <Text as='h4' mt='0.425rem'>
          {item.review}
        </Text>
      </Flex>
    </Flex>
  )
}

export default SingleReview

import React, { useEffect, useState } from 'react'

import { Box, Button, Flex, Text } from '@chakra-ui/react'

import { CardList, Link } from 'components'
import { useLanguages } from 'hooks'
import { useLearnerSearchInstructor } from 'queries/learner'
import { UserCard } from 'shared/containers'
import { Centre, UserSearchResult } from 'types'

interface CentreInstructorSectionProps {
  data: Centre
}

const CentreInstructorSection: React.FC<CentreInstructorSectionProps> = ({
  data,
}) => {
  const { _t } = useLanguages()

  const [list, setList] = useState<UserSearchResult[]>([])
  const [total, setTotal] = useState<number>(0)
  const [limit] = useState<number>(5)
  const [page] = useState<number>(1)

  const { data: instructorData, isLoading } = useLearnerSearchInstructor({
    centerId: data?.id || '',
    page,
    limit,
  })

  useEffect(() => {
    if (instructorData?.data.length) {
      const allResults = instructorData?.data || []
      const count = instructorData?.meta?.total || 0

      setTotal(count)

      if (page > 1) {
        setList(old => [...old, ...allResults])
      } else {
        setList([...allResults])
      }
    }
  }, [isLoading, instructorData, page])

  return (
    <Box my='2rem'>
      <Text fontWeight='600' textTransform='uppercase'>
        {_t('centre.profile.instructors')}
      </Text>
      <CardList
        dataList={(list as []) || []}
        emptyMessage={_t('centre.profile.instructor_empty_title')}
        emptySubMessage={_t('centre.profile.instructor_empty_description')}
        wrap
      >
        {list.map((item: UserSearchResult) => (
          <UserCard
            key={item.id}
            data={{
              ...item,
              accountType: {
                id: '',
                name: 'EnterpriseTutor',
              },
            }}
          />
        ))}
      </CardList>
      {(total || 0) > limit && (
        <Flex justify='center'>
          <Button
            variant='outlinePrimary'
            size='md'
            as={Link}
            to='?tab=instructors'
          >
            {_t('centre.profile.more_instructor')}
          </Button>
        </Flex>
      )}
    </Box>
  )
}

export default CentreInstructorSection

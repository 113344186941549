import React, { useMemo } from 'react'

import { Box, Button, Flex, Text, useDisclosure } from '@chakra-ui/react'

import { AddLearnerModal, LearnersSection, Tooltip } from 'components'
import { useLanguages, useUserId } from 'hooks'
import { Learner, UserRole } from 'types'

import { ReactComponent as InfoIcon } from 'assets/images/info_outline_icon.svg'

interface InvitationSectionProps {
  role: string
  watch: any
  invitedLearners: Learner[]
  confirmedLearners: Learner[]
  handleAddInvitedLearner: (data: Learner[]) => void
  handleDeleteInvitedLearner: (learnerId: string) => void
  handleAddConfirmedLearner: (data: Learner[]) => void
  handleDeleteConfirmedLearner: (learnerId: string) => void
  confirmedLearnersError?: string
}

const InvitationSection: React.FC<InvitationSectionProps> = ({
  role,
  watch,
  invitedLearners,
  confirmedLearners,
  handleAddInvitedLearner,
  handleDeleteInvitedLearner,
  handleAddConfirmedLearner,
  handleDeleteConfirmedLearner,
  confirmedLearnersError,
}) => {
  const { _t } = useLanguages()
  const userId = useUserId()
  const tutor = useMemo(() => watch('tutor'), [watch])
  const roleIsAdmin = useMemo(
    () =>
      role === UserRole.MSTSuperAdmin ||
      role === UserRole.EnterpriseAdmin ||
      role === UserRole.CenterAdmin,
    [role]
  )

  const {
    onClose: onCloseInvited,
    isOpen: isOpenInvited,
    onOpen: onOpenInvited,
  } = useDisclosure()
  const {
    onClose: onCloseConfirm,
    isOpen: isOpenConfirm,
    onOpen: onOpenConfirm,
  } = useDisclosure()

  return (
    <Flex direction={{ base: 'column', md: 'row' }}>
      <Box
        flex='1'
        bg='secondaryLightBlue'
        rounded='lg'
        p='0.75rem'
        mt='1.2rem'
      >
        <Flex
          justifyContent='space-between'
          alignItems='left'
          mb='0.75rem'
          pl='0.4rem'
        >
          <Flex alignItems='center' fontSize='0.75rem'>
            <Text fontSize='0.75rem' fontWeight='600' mr='0.5rem'>
              {_t('product.class.invited_learners')}
            </Text>
            <Tooltip label={_t('product.class.tooltip_invited_learners')}>
              <InfoIcon />
            </Tooltip>
          </Flex>
          <Flex justifyContent='flex-end'>
            <Button
              variant='outlinePrimary'
              fontSize='0.75rem'
              p='0.5rem'
              size='md'
              textTransform='uppercase'
              onClick={onOpenInvited}
            >
              {_t('product.class.add_learners')}
            </Button>
          </Flex>
        </Flex>
        <Flex
          alignContent='flex-start'
          p='0.75rem'
          mb='1rem'
          direction='column'
          overflowY='auto'
        >
          <LearnersSection
            learners={invitedLearners}
            handleDeleteLearner={handleDeleteInvitedLearner}
          />
        </Flex>
      </Box>
      <Box w='4rem' />
      <Box
        flex='1'
        bg='secondaryLightBlue'
        rounded='lg'
        p='0.75rem'
        mt='1.2rem'
      >
        <Flex
          justifyContent='space-between'
          alignItems='left'
          mb='0.75rem'
          pl='0.4rem'
        >
          <Flex alignItems='center' fontSize='0.75rem'>
            <Text fontSize='0.75rem' fontWeight='600' mr='0.5rem'>
              {_t('product.class.confirmed_learners')}
            </Text>
            <Tooltip label={_t('product.class.tooltip_confirmed_learners')}>
              <InfoIcon />
            </Tooltip>
          </Flex>
          <Flex justifyContent='flex-end'>
            <Button
              variant='outlinePrimary'
              fontSize='0.75rem'
              p='0.5rem'
              size='md'
              textTransform='uppercase'
              onClick={onOpenConfirm}
            >
              {_t('product.class.add_learners')}
            </Button>
          </Flex>
        </Flex>
        <Text textStyle='error'>{confirmedLearnersError}</Text>
        <Flex
          alignContent='flex-start'
          p='0.75rem'
          mb='1rem'
          direction='column'
          overflowY='auto'
        >
          <LearnersSection
            learners={confirmedLearners}
            handleDeleteLearner={handleDeleteConfirmedLearner}
          />
        </Flex>
      </Box>
      <AddLearnerModal
        isOpen={isOpenInvited}
        onClose={onCloseInvited}
        learners={invitedLearners}
        handleAddLearner={handleAddInvitedLearner}
        userId={roleIsAdmin ? tutor.value : userId}
        type='Tutor'
      />
      <AddLearnerModal
        isOpen={isOpenConfirm}
        onClose={onCloseConfirm}
        learners={confirmedLearners}
        handleAddLearner={handleAddConfirmedLearner}
        userId={roleIsAdmin ? tutor.value : userId}
        type='Tutor'
      />
    </Flex>
  )
}

export default InvitationSection

import React, { useCallback, useState } from 'react'

import { Flex, Modal, ModalOverlay, Spinner } from '@chakra-ui/react'

import { ReactComponent as KnovoLogo } from '../../assets/images/knovo_logo.svg'

const LoadingScreen: React.FC = () => {
  const [loading, setLoading] = useState(true)

  const handleClose = useCallback(() => setLoading(false), [])

  return (
    <Modal isOpen={loading} onClose={handleClose}>
      <ModalOverlay background='rgba(244, 251, 255, 0.6)' />
      <Flex
        position='fixed'
        top='0'
        left='0'
        zIndex='2000'
        justifyContent='center'
        alignItems='center'
        width='100vw'
        height='100vh'
        direction='column'
      >
        <KnovoLogo />
        <Spinner size='xl' color='teal.200' />
      </Flex>
    </Modal>
  )
}

export default LoadingScreen

import React, { useCallback, useEffect, useState } from 'react'

import { Box, Grid, Text } from '@chakra-ui/react'
import { useAtom } from 'jotai'

import { LoadingSpinner, Result, SearchBar, Visible } from 'components'
import { connectionPath } from 'configs/apiPath'
import { useInvalidateUrl, useLanguages } from 'hooks'
import { useConnectionListFriend } from 'queries/connection'
import { UserCard } from 'shared/containers'
import { hasNewAcceptedNotificationAtom } from 'store/notificationAtom'
import { ResultType } from 'types'
import {
  Connection,
  ConnectionStatusType,
  ConnectionType,
} from 'types/connection'

export const ConnectedTutor = ({ userId }: any) => {
  const { _t } = useLanguages()
  // ** States
  const [list, setList] = useState<Connection[]>([])
  const [total, setTotal] = useState(0)
  const [page, setPage] = useState(1)
  const [limit] = useState(20)
  const [search, setSearch] = useState('')
  const [hasNewAcceptedNotification] = useAtom(hasNewAcceptedNotificationAtom)

  // ** Invalidate URL when has new accepted notification
  const invalidateConnectionUrl = useInvalidateUrl(
    connectionPath.list(userId, {
      search,
      limit: limit,
      page: page,
      type: ConnectionType.TUTOR,
      status: ConnectionStatusType.CONFIRM,
    })
  )

  // ** Queries
  const { data, isLoading } = useConnectionListFriend(userId, {
    search,
    type: ConnectionType.TUTOR,
    status: ConnectionStatusType.CONFIRM,
    page,
    limit,
  })

  // ** Effects
  useEffect(() => {
    if (search) {
      setList([])
      setPage(1)
    }
  }, [search])

  useEffect(() => {
    const allResults = data?.data || []
    if (allResults) {
      const count = data?.meta?.total || 0

      setTotal(count)

      if (page > 1) {
        setList(old => [...old, ...allResults])
      } else {
        setList([...allResults])
      }
    }
  }, [isLoading, data, page])

  useEffect(() => {
    if (hasNewAcceptedNotification) {
      invalidateConnectionUrl()
    }
  }, [hasNewAcceptedNotification, invalidateConnectionUrl])

  // ** Events
  const handleLoadMore = useCallback(() => {
    const lastPage = Math.ceil(total / limit)

    if (total < limit) {
      return
    }

    if (!isLoading && page <= lastPage) {
      setPage(oldPage => oldPage + 1)
    }
  }, [isLoading, limit, page, total])

  // ** Renders
  const showEmpty = useCallback(() => {
    if (list.length === 0 && !isLoading) {
      return (
        <Box mt='10rem'>
          <Result
            title={_t('user.connection.instructor_empty_title')}
            subTitle={_t('user.connection.instructor_empty_description')}
            type={ResultType.USER}
          />
        </Box>
      )
    }
  }, [_t, isLoading, list])

  const renderResults = useCallback(() => {
    return list.map((item: any, idx: number) => (
      <UserCard key={`c-instructor-${idx}`} data={item} />
    ))
  }, [list])

  return (
    <Box>
      <Box as='section' pb='1.5rem' maxWidth='332px'>
        <SearchBar
          color='#828282'
          placeholder={_t('user.connection.instructor_search_placeholder')}
          minChar={3}
          onSearch={setSearch}
        />
      </Box>
      <Box>
        <Text
          as='h2'
          fontWeight='600'
          fontSize='1.125rem'
          lineHeight='1.313rem'
        >
          {_t('user.connection.instructor_title', { total: `${total}` })}
        </Text>
      </Box>
      <Box mt='2rem'>
        {showEmpty()}
        <Grid templateColumns='repeat(4, 1fr)' gap={'1.25rem'}>
          {renderResults()}
        </Grid>
        {isLoading && <LoadingSpinner />}
        {total > limit && <Visible onVisible={handleLoadMore} />}
      </Box>
    </Box>
  )
}

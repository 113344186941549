import React, { useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router'

import { Box, Button, Flex, Text } from '@chakra-ui/react'
import { useAtom } from 'jotai'

import { LoadingSpinner } from 'components'
import { useLanguages } from 'hooks'
import { useUserWallet } from 'queries/wallet'
import { userProfileAtom } from 'store/authAtom'
import { ApiUserType } from 'types'
import { isCenterAdmin, isEnterpriseAdmin, isMSTAdmin } from 'utils/helper'
import { formatAmount } from 'utils/parser'

interface WalletSectionProps {
  userType: string
  typeId: string
}

const WalletSection: React.FC<WalletSectionProps> = ({ userType, typeId }) => {
  const { _t } = useLanguages()
  const navigate = useNavigate()
  const [userProfile] = useAtom(userProfileAtom)

  const { data: walletInfo, isLoading } = useUserWallet({
    type: userType as ApiUserType,
    id: typeId,
  })

  const walletData = useMemo(() => walletInfo?.data, [walletInfo])

  const isEnterpriseAdminMemo = useMemo(() => isEnterpriseAdmin(userProfile), [
    userProfile,
  ])
  const isCentreAdminMemo = useMemo(() => isCenterAdmin(userProfile), [
    userProfile,
  ])
  const isKnovoAdminMemo = useMemo(() => isMSTAdmin(userProfile), [userProfile])

  const handleViewWallet = useCallback(() => {
    if (isEnterpriseAdminMemo) {
      return navigate('/enterprise-admin/wallet-management/manage')
    }
    if (isCentreAdminMemo) {
      return navigate('/center-admin/wallet-management/manage')
    }
    if (isKnovoAdminMemo) {
      return navigate('/admin/wallet-management/manage')
    }
    return navigate('/wallet')
  }, [navigate, isEnterpriseAdminMemo, isCentreAdminMemo, isKnovoAdminMemo])

  return (
    <Box px='1.5rem' py='1rem'>
      {isLoading && <LoadingSpinner />}
      {!isLoading && walletData && (
        <>
          <Flex justify='space-between' py='0.5rem'>
            <Text as='h2'>{_t('header.wallet.title')}</Text>
            <Text as='h2' color='primary'>{`$${formatAmount(
              (walletData.balance || 0) / 100
            )}`}</Text>
          </Flex>
          <Flex justify='space-between' py='0.25rem'>
            <Text color='gray.300' fontSize='0.875rem'>
              {_t('wallet.balance.available')}
            </Text>
            <Text color='primary' fontSize='0.875rem'>{`$${formatAmount(
              ((walletData.wallet?.availableBalance || 0) +
                (walletData.wallet?.topUpBalance || 0)) /
                100
            )}`}</Text>
          </Flex>
          <Flex justify='space-between' py='0.25rem'>
            <Text color='gray.300' fontSize='0.875rem'>
              {_t('wallet.balance.holding')}
            </Text>
            <Text color='primary' fontSize='0.875rem'>
              {`$${formatAmount(
                (walletData.wallet?.holdingBalance || 0) / 100
              )}`}
            </Text>
          </Flex>
          <Flex justify='space-between' py='0.25rem'>
            <Text color='gray.300' fontSize='0.875rem'>
              {_t('wallet.balance.payout')}
            </Text>
            <Text color='primary' fontSize='0.875rem'>
              {`$${formatAmount(
                (walletData.wallet?.payoutBalance || 0) / 100
              )}`}
            </Text>
          </Flex>
          <Box py='0.5rem'>
            <Button
              size='md'
              variant='outlinePrimary'
              w='full'
              rounded='full'
              fontSize='0.875rem'
              onClick={handleViewWallet}
              _focus={{ outline: 'none' }}
            >
              {_t('header.wallet.view')}
            </Button>
          </Box>
        </>
      )}
    </Box>
  )
}

export default WalletSection

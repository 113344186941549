import React, { useCallback, useEffect, useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'

import { Box, Button, Flex, Grid, GridItem, Text } from '@chakra-ui/react'

import { AttachmentFile, FileUpload, Modal, ModalProps } from 'components'
import { useImportClassesFile, useLoading } from 'hooks'
import { Attachment } from 'types'

interface ImportClassesForm {
  attachment: Attachment[]
}
interface ImportClassesModalProps extends Omit<ModalProps, 'children'> {
  onImportSuccess: () => void
  onUpdateTotalClass: (value: number) => void
}

const FILES_LIMIT = 1

const ImportClassesModal = ({
  isOpen,
  onClose,
  onImportSuccess,
  onUpdateTotalClass,
}: ImportClassesModalProps) => {
  const { loading } = useLoading()
  const { handleSubmit, watch, control, reset } = useForm<ImportClassesForm>({
    defaultValues: {
      attachment: [],
    },
  })

  const {
    attachments,
    setAttachments: handleUpdateAttachments,
    handleImportFile,
    isError,
    setIsError: handleChangeIsError,
    errorData,
    setErrorData: handleChangeErrorData,
    isSuccess,
  } = useImportClassesFile(onUpdateTotalClass)

  const [isReachLimit, setIsReachLimit] = useState<boolean>(false)

  const handleDownloadTemplate = useCallback(() => {
    window.open(
      `${process.env.REACT_APP_BUCKET_URL}/${process.env.REACT_APP_CLASSES_TEMPLATE_KEY}`
    )
  }, [])

  const watchAttachment = watch('attachment')

  const onSubmit: SubmitHandler<ImportClassesForm> = async data =>
    handleImportFile(data.attachment[0])

  const handleDeleteAttachment = useCallback(
    (name: string) =>
      handleUpdateAttachments(cur =>
        cur.filter(attachment => attachment.name !== name)
      ),
    [handleUpdateAttachments]
  )

  const handleClickReUpload = useCallback(() => {
    reset()
    handleUpdateAttachments([])
    handleChangeIsError(false)
    handleChangeErrorData([])
  }, [
    reset,
    handleUpdateAttachments,
    handleChangeIsError,
    handleChangeErrorData,
  ])

  // Effect
  useEffect(() => {
    if (watchAttachment.length) handleUpdateAttachments(watchAttachment)

    if (watchAttachment.length > FILES_LIMIT) {
      setIsReachLimit(true)
    } else {
      setIsReachLimit(false)
    }
  }, [watchAttachment, handleUpdateAttachments])

  useEffect(() => {
    if (isSuccess) {
      onClose()
      onImportSuccess()
    }
  }, [isSuccess, onClose, onImportSuccess])

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={isError ? 'xl' : '2xl'}>
      <Box as='form' onSubmit={handleSubmit(onSubmit)}>
        <Text textAlign='center' textStyle='headingModalText' mb='2rem'>
          Import Classes
        </Text>

        {!isError && (
          <>
            <Controller
              control={control}
              name='attachment'
              render={({ onChange }) => (
                <FileUpload
                  maxFiles={1}
                  maxSize={5242880}
                  onChange={onChange}
                  accept='.csv, .xlsx'
                  isReachLimit={isReachLimit}
                />
              )}
            />

            <Text
              mt='0.625rem'
              mb='1.25rem'
              fontSize='0.75rem'
              lineHeight='0.875rem'
            >
              Any pictures, attachments and other non-importable items can only
              be added while editing the class drafts.
            </Text>

            <Box
              minH='4.625rem'
              px='1rem'
              py='0.75rem'
              mb='1.75rem'
              bgColor='lightBlue'
              borderRadius='4px'
            >
              {attachments.map((attachment, idx) => (
                <AttachmentFile
                  key={idx}
                  item={attachment}
                  withStatus={false}
                  deletable
                  onDelete={handleDeleteAttachment}
                />
              ))}
            </Box>

            <Flex alignItems='center' mb='3rem'>
              <Button
                variant='outlinePrimary'
                p='1.25rem'
                mr='1.25rem'
                onClick={handleDownloadTemplate}
              >
                Download template
              </Button>
              <Text fontSize='0.75rem' lineHeight='0.875rem'>
                Download the required format for your reference
              </Text>
            </Flex>

            <Flex justifyContent='flex-end'>
              <Button
                variant='solidPrimary'
                type='submit'
                isDisabled={attachments.length === 0}
                isLoading={loading}
              >
                Import
              </Button>
              <Button variant='transparent' onClick={onClose}>
                Cancel
              </Button>
            </Flex>
          </>
        )}

        {isError && (
          <>
            <Box textAlign='center' fontSize='0.875rem'>
              <Text mb='1.5rem'>
                The document was not successfully uploaded as the data did not
                meet the following requirements.
              </Text>
              <Text mb='1.25rem'>
                Please make the necessary changes and re-upload your updated
                template.
              </Text>
            </Box>
            <Grid
              padding='1rem'
              mb='3rem'
              backgroundColor='rgba(204, 41, 49, 0.1)'
              borderRadius='10px'
              templateColumns={errorData?.length <= 2 ? '1fr' : '1fr 1fr'}
              gap='0.5rem'
            >
              {errorData.length &&
                errorData.map((error, idx) => (
                  <GridItem
                    as='li'
                    key={idx + error}
                    fontSize='0.875rem'
                    lineHeight='1rem'
                  >
                    {error}
                  </GridItem>
                ))}
            </Grid>
            <Flex>
              <Button
                w='50%'
                variant='solidPrimary'
                onClick={handleClickReUpload}
              >
                RE-UPLOAD
              </Button>
              <Button w='50%' variant='transparent' onClick={onClose}>
                Cancel
              </Button>
            </Flex>
          </>
        )}
      </Box>
    </Modal>
  )
}

export default ImportClassesModal

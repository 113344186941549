import React, { useMemo } from 'react'

import {
  Box,
  Button,
  CircularProgress,
  CircularProgressLabel,
  Flex,
  Progress,
  Text,
} from '@chakra-ui/react'
import { useAtom } from 'jotai'

import { ContentContainer } from 'components'
import { useLanguages, useUserId } from 'hooks'
import { useUserSubjectCombination } from 'queries/user'
import { useUserWallet } from 'queries/wallet'
import { userProfileAtom } from 'store/authAtom'
import { UserRole } from 'types'
import { isInstructor, isLearner } from 'utils/helper'

interface ProfileProgressProps {
  handleEditProfile?: () => void
}

const ProfileProgress: React.FC<ProfileProgressProps> = ({
  handleEditProfile,
}) => {
  const { _t } = useLanguages()
  const userId = useUserId()
  const [userProfile] = useAtom(userProfileAtom)
  const { data: walletInfo } = useUserWallet({ type: 'user', id: userId })
  const { data: subjects } = useUserSubjectCombination(userId)
  const isLearnerMemo = useMemo(() => isLearner(userProfile), [userProfile])
  const isInstructorMemo = useMemo(() => isInstructor(userProfile), [
    userProfile,
  ])

  const profileItems = [
    userProfile?.description,
    userProfile?.yearOfExperience,
    subjects?.data.length,
    userProfile?.educationQualification,
    userProfile?.birthday,
    userProfile?.gender,
    userProfile?.countryOfResidence,
    userProfile?.nationality,
    userProfile?.languages,
    userProfile?.recoveryEmail,
    userProfile?.address1,
    userProfile?.country,
    userProfile?.city,
    userProfile?.zipCode,
  ]

  if (isInstructorMemo && userProfile?.accountType?.name === UserRole.Tutor) {
    profileItems.push(
      walletInfo?.data.bankAccountName,
      walletInfo?.data.bankAccountNumber,
      walletInfo?.data.bankName,
      walletInfo?.data.country
    )
  }

  if (isLearnerMemo) {
    profileItems.splice(1, 3)
    if (!userProfile?.educationSystem?.isCompleted) {
      profileItems.push(
        userProfile?.educationSystem?.country?.name,
        userProfile?.educationSystem?.system?.name,
        userProfile?.educationSystem?.teachingLevel?.name,
        userProfile?.educationSystem?.startDateTime,
        userProfile?.educationSystem?.endDateTime
      )
    }
  }

  const getCompletion = useMemo(() => {
    const totalCount = profileItems.length
    const emptyCount = profileItems.filter(
      item => item === undefined || item === '' || item === 0
    ).length
    const completion = ((totalCount - emptyCount) / totalCount) * 100
    return parseFloat(completion.toFixed(0))
  }, [profileItems])

  return (
    <>
      {getCompletion !== 100 ? (
        <ContentContainer mb='2rem' p={0}>
          <Flex
            direction={{ base: 'column', md: 'row' }}
            alignItems='center'
            gridGap={{ base: '0', md: '2rem' }}
          >
            <Flex
              ml='2rem'
              my='1.5rem'
              maxH='12.5rem'
              maxW='12.5rem'
              display={{ base: 'none', md: 'inherit' }}
            >
              <CircularProgress
                value={getCompletion}
                thickness='5px'
                size='100%'
                color='primary'
                capIsRound
              >
                <CircularProgressLabel
                  fontSize={{ base: '2rem', md: '2.5rem' }}
                >
                  {getCompletion}%
                </CircularProgressLabel>
              </CircularProgress>
            </Flex>
            <Flex
              direction='column'
              ml={{ base: '1.5rem', md: '0' }}
              mr={{ base: '1.5rem', md: '2rem' }}
              mt={{ base: '1rem', md: '1.5rem' }}
              mb='1.5rem'
              justifyContent='center'
              alignItems='flex-start'
            >
              <Text fontSize={{ base: '1.5rem', lg: '2rem' }} fontWeight={400}>
                {_t('profile.completion.title')}
              </Text>
              <Text fontWeight={700}>
                {_t('profile.completion.progress', {
                  completion: `${getCompletion}`,
                })}
              </Text>
              <Progress
                h='4px'
                width='100%'
                mb='0.5rem'
                colorScheme='progressPurple'
                rounded='lg'
                value={getCompletion}
                display={{ base: 'inherit', md: 'none' }}
              />
              {handleEditProfile && (
                <>
                  <Text>{_t('profile.completion.prompt')}</Text>
                  <Button
                    variant='primary'
                    onClick={handleEditProfile}
                    px='1.5rem'
                    mt='1rem'
                    textTransform='uppercase'
                  >
                    {_t('profile.completion.button')}
                  </Button>
                </>
              )}
            </Flex>
          </Flex>
        </ContentContainer>
      ) : (
        <Box />
      )}
    </>
  )
}

export default ProfileProgress

import React from 'react'

import { Box, BoxProps } from '@chakra-ui/react'

interface ContainerProps extends BoxProps {}

const Container: React.FC<ContainerProps> = ({ children, ...boxProps }) => {
  return (
    <Box backgroundColor='containerBg'>
      <Box
        maxWidth='1600px'
        minHeight='calc(100vh - 4.5rem)'
        margin=' 0 auto'
        px={{
          base: '1.5rem',
          md: '4rem',
          lg: '6rem',
        }}
        {...boxProps}
      >
        {children}
      </Box>
    </Box>
  )
}

export default Container

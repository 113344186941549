import React, { useCallback, useEffect, useMemo, useState } from 'react'

import {
  Box,
  Divider,
  Flex,
  Grid,
  Radio,
  RadioGroup,
  Text,
} from '@chakra-ui/react'
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js'
import { StripeCardNumberElement } from '@stripe/stripe-js'
import { useAtom } from 'jotai'

import { Checkbox, Input } from 'components'
import { useLanguages, useUserId } from 'hooks'
import { cartAtom } from 'store/cartAtom'
import { CartItem, Method, PaymentOption, UserWalletInfo } from 'types'
import { STRIPE_ELEMENT_OPTION_STYLE } from 'utils/constant'
import { formatAmount } from 'utils/parser'

import { ReactComponent as GCash } from 'assets/images/gcash.svg'
import { ReactComponent as Mastercard } from 'assets/images/mastercard.svg'
import { ReactComponent as Visa } from 'assets/images/visacard.svg'

interface PaymentMethodProps {
  walletInfo?: { data: UserWalletInfo }
  paymentOption?: PaymentOption
  paymentMethod?: Method[]
  payMethod?: Method
  handleChangePayMethod?: (value: Method) => void
  handleChangeCardDetail?: (value: any) => void
}

const PaymentMethod: React.FC<PaymentMethodProps> = ({
  walletInfo,
  paymentOption = 'topup',
  paymentMethod = ['credit', 'newCard', 'savedCard', 'gcash'],
  payMethod = 'newCard',
  handleChangePayMethod,
  handleChangeCardDetail,
}) => {
  const userId = useUserId()
  const elements = useElements()
  const stripe = useStripe()
  const { _t } = useLanguages()
  const [cart] = useAtom(cartAtom)
  const [cardDetail, setCardInfo] = useState<any>({
    type: 'card',
    card: undefined,
    billing_details: { name: '' },
    metadata: { userId },
    setDefault: paymentOption === 'subscription' ? true : false,
  })

  const handleUpdateNewCard = useCallback(() => {
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return
    }
    const cardElement = elements.getElement(
      CardNumberElement
    ) as StripeCardNumberElement
    setCardInfo({ ...cardDetail, card: cardElement })
  }, [cardDetail, elements, stripe])

  useEffect(() => {
    handleUpdateNewCard()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (handleChangeCardDetail) {
      handleChangeCardDetail(cardDetail)
    }
  }, [cardDetail, handleChangeCardDetail])

  const itemPricing = useMemo(
    () =>
      cart.reduce(
        (prev: number, curr: CartItem) =>
          prev + (curr?.price || 0) * (curr?.quantity || 0),
        0
      ),
    [cart]
  )

  if (cart.length === 0 || itemPricing === 0) {
    return <></>
  }

  return (
    <Box bg='white' rounded='lg' boxShadow='lg' p='1.5rem'>
      <Text as='h2' pb='1rem'>
        {_t('payment.method.title')}
      </Text>
      <RadioGroup value={payMethod} onChange={handleChangePayMethod}>
        <Flex align='center' direction={'row'} flexWrap='wrap'>
          {paymentMethod.includes('credit') && (
            <Radio value='credit' pr='2rem' pt='1rem'>
              {`Credits $${formatAmount(
                (walletInfo?.data.balance || 0) / 100
              )}`}
            </Radio>
          )}

          {paymentMethod.includes('newCard') && (
            <Radio value='newCard' pr='2rem' pt='1rem'>
              <Flex
                className='card-icon-group'
                alignItems='center'
                justifyContent='space-between'
              >
                <Mastercard height='1.5rem' />
                <Visa height='1.2rem' />
              </Flex>
            </Radio>
          )}

          {paymentMethod.includes('savedCard') && walletInfo?.data.card && (
            <Radio value='savedCard' pr='2rem' pt='1rem'>
              {`************${walletInfo?.data.card.last4}`}
            </Radio>
          )}

          {paymentMethod.includes('gcash') && (
            <Radio value='gcash' pr='2rem' pt='1rem'>
              <GCash height='1.75rem' />
            </Radio>
          )}
        </Flex>
      </RadioGroup>

      {payMethod === 'newCard' && (
        <>
          <Divider my='2rem' />
          <Flex
            direction='column'
            transition='0.2s'
            overflow='hidden'
            mt={'1rem'}
            maxW='20rem'
          >
            <Box mb='1.8rem'>
              <Text fontSize='0.875rem' fontWeight='600'>
                Card Number
              </Text>
              <CardNumberElement
                options={STRIPE_ELEMENT_OPTION_STYLE}
                onChange={handleUpdateNewCard}
              />
            </Box>
            <Grid gridTemplateColumns='1fr 1fr' columnGap='1.5rem' mb='1.8rem'>
              <Box>
                <Text fontSize='0.875rem' fontWeight='600'>
                  Expiry
                </Text>
                <CardExpiryElement
                  options={STRIPE_ELEMENT_OPTION_STYLE}
                  onChange={handleUpdateNewCard}
                />
              </Box>
              <Box>
                <Text fontSize='0.875rem' fontWeight='600'>
                  CVC
                </Text>
                <CardCvcElement
                  options={STRIPE_ELEMENT_OPTION_STYLE}
                  onChange={handleUpdateNewCard}
                />
              </Box>
            </Grid>
            <Box mb='1.8rem'>
              <Input
                value={cardDetail?.billing_details?.name}
                onChange={e =>
                  setCardInfo({
                    ...cardDetail,
                    billing_details: { name: e.target.value },
                  })
                }
                label='Name on card'
                placeholder='Name'
                fontSize='1rem'
              />
            </Box>
            <Checkbox
              mb='0.5rem'
              isChecked={cardDetail.setDefault}
              onChange={e => {
                if (paymentOption !== 'subscription') {
                  setCardInfo({ ...cardDetail, setDefault: e.target.checked })
                }
              }}
            >
              {_t('payment.method.set_default')}
            </Checkbox>
          </Flex>
        </>
      )}
    </Box>
  )
}

export default PaymentMethod

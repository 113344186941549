import React from 'react'
import { useForm } from 'react-hook-form'

import EditAdminProfileContainer from '../../containers/EditAdminProfile'
import { CreateMstAdminForm } from '../../containers/forms'
import { useLanguages } from 'hooks'
import { useAddUser } from 'queries/user'
import { AddUserData, UserRole } from 'types'

interface CreateKnovoAdminContainerProps {
  handleToBack: () => void
}

const CreateKnovoAdminContainer = ({
  handleToBack,
}: CreateKnovoAdminContainerProps) => {
  const { _t } = useLanguages()

  const {
    control,
    formState: { errors: formErrors },
    getValues,
    handleSubmit,
  } = useForm<CreateMstAdminForm>({
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      recoveryEmail: '',
      address1: '',
      address2: '',
      city: '',
      state: '',
      country: '',
      zipCode: '',
      contactNumber: '',
    },
  })

  const { mutate: createUser } = useAddUser()

  const handleCreateUser = () => {
    let createData = {} as AddUserData
    const roleName = UserRole.MSTAdmin

    const values = getValues()
    const formattedDataUser = {
      ...values,
      birthday: values.birthday
        ? new Date(values.birthday).getTime()
        : undefined,
    }

    createData = {
      roleName,
      ...formattedDataUser,
    }

    createUser(createData)
  }

  return (
    <EditAdminProfileContainer
      toBackLabel={_t('user_management.knovo_admin.title')}
      title={_t('user_management.knovo_admin.create_title')}
      handleToBack={handleToBack}
      handleSubmit={handleSubmit}
      handleSubmitCall={handleCreateUser}
      isLoading={false}
      control={control}
      formErrors={formErrors}
    />
  )
}

export default CreateKnovoAdminContainer

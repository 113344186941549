import React, { memo, useEffect, useState } from 'react'

import { Box, Flex, Grid } from '@chakra-ui/react'
import addDays from 'date-fns/addDays'
import addMonths from 'date-fns/addMonths'
import format from 'date-fns/format'
import getDay from 'date-fns/getDay'
import isLeapYear from 'date-fns/isLeapYear'
import isSameDay from 'date-fns/isSameDay'
import startOfMonth from 'date-fns/startOfMonth'
import subDays from 'date-fns/subDays'
import subMonths from 'date-fns/subMonths'

import Arrow from '../Arrow'
import { CalendarDay } from 'queries/calendar'
import { ClassColor } from 'types'

export type Period = {
  from: Date
  to: Date
}

interface CalendarProps {
  data?: CalendarDay[]
  initDate?: Date
  onClickDate?: (clickedDate: Date) => void
  onChangePeriod?: (newPeriod: Period) => void
}

type CalendarEvent = {
  id: string
  data: string
}

type CalendarItem = {
  value: Date
  isPastMonth?: boolean
  events?: CalendarEvent[] | null
}

type DayOfWeeks = {
  [key: number]: CalendarItem[]
}

type MapDayOfWeeks = {
  [key: number]: string
}

const mapDayOfWeekName: MapDayOfWeeks = {
  0: 'S',
  1: 'M',
  2: 'T',
  3: 'W',
  4: 'T',
  5: 'F',
  6: 'S',
}

const dayOfMonths = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]

const subWeekDay = (weekDay: number) => {
  return weekDay - 1 < 0 ? 6 : weekDay - 1
}

const Calendar: React.FC<CalendarProps> = ({
  data = [],
  initDate = new Date(),
  onChangePeriod,
  onClickDate,
}) => {
  const now = new Date()
  const dayOfWeeks: DayOfWeeks = {
    0: [],
    1: [],
    2: [],
    3: [],
    4: [],
    5: [],
    6: [],
  }

  const [currentDate, setCurrentDate] = useState(initDate)

  const year = currentDate.getFullYear()
  const month = currentDate.getMonth()

  if (isLeapYear(year)) {
    dayOfMonths[1] = 29
  }

  const firstDayOfMonth = startOfMonth(currentDate)
  let weekDay = getDay(firstDayOfMonth) as number
  let endPastMonthWeekDay = weekDay
  let dayOfPastMonth = firstDayOfMonth

  // first day of month is not Sunday
  while (endPastMonthWeekDay !== 0) {
    dayOfPastMonth = subDays(dayOfPastMonth, 1)
    endPastMonthWeekDay = subWeekDay(endPastMonthWeekDay)
    dayOfWeeks[endPastMonthWeekDay].push({
      value: dayOfPastMonth,
      isPastMonth: true,
    })
  }

  // get week day of all day of current month
  for (let i = 0; i < dayOfMonths[month]; i++) {
    const dayOfCurrentMonth = addDays(firstDayOfMonth, i)
    dayOfWeeks[weekDay].push({
      value: dayOfCurrentMonth,
      isPastMonth: false,
    })

    weekDay = weekDay + 1
    if (weekDay > 6) {
      weekDay = 0
    }
  }

  useEffect(() => {
    const newPeriod = {
      from: startOfMonth(new Date()),
      to: startOfMonth(addMonths(new Date(), 1)),
    }

    onChangePeriod?.(newPeriod)
  }, [onChangePeriod])

  const handleClickDate = (date: Date) => () => {
    onClickDate?.(date)
    setCurrentDate(date)
  }

  const handleClickPrev = () => {
    const dayOfOldMonth = subMonths(currentDate, 1)
    const newPeriod = {
      from: startOfMonth(subMonths(currentDate, 1)),
      to: startOfMonth(currentDate),
    }

    onChangePeriod?.(newPeriod)
    onClickDate?.(dayOfOldMonth)
    return setCurrentDate(dayOfOldMonth)
  }

  const handleClickNext = () => {
    const dayOfNewMonth = addMonths(currentDate, 1)
    const newPeriod = {
      from: startOfMonth(addMonths(currentDate, 1)),
      to: startOfMonth(addMonths(currentDate, 2)),
    }

    onChangePeriod?.(newPeriod)
    onClickDate?.(dayOfNewMonth)
    return setCurrentDate(dayOfNewMonth)
  }

  return (
    <Box pt='1.75rem' backgroundColor='#fff' borderRadius='0.5rem'>
      <Grid
        gridTemplateColumns='repeat(7, 1fr)'
        justifyItems='center'
        mb='1.5rem'
      >
        <Arrow transform='rotate(135deg)' onClick={handleClickPrev} />
        <Box
          className={'blue-purple-gradient-text'}
          gridColumn='3 / span 3'
          fontWeight='600'
        >
          {format(currentDate, 'MMM yyyy')}
        </Box>
        <Arrow
          gridColumnStart='7'
          transform='rotate(-45deg)'
          onClick={handleClickNext}
        />
      </Grid>

      <Grid
        gridTemplateColumns='repeat(7, 1fr)'
        justifyItems='center'
        mb='1rem'
      >
        {Object.keys(dayOfWeeks).map(day => (
          <Box key={day} as='p' color='primary'>
            {mapDayOfWeekName[+day]}
          </Box>
        ))}
      </Grid>

      <Grid gridTemplateColumns='repeat(7, 1fr)' justifyItems='center'>
        {Object.keys(dayOfWeeks).map(day => (
          <Box key={day}>
            {dayOfWeeks[+day].map(({ value, isPastMonth }) => {
              const dateKey = format(value, 'MM/dd/yyyy')
              const events = data.find(o => o.date === dateKey)?.data || []
              const getColor = () => {
                if (isPastMonth) {
                  return 'rgba(34, 33, 91, 0.3)'
                } else {
                  return isSameDay(currentDate, value) && !isSameDay(now, value)
                    ? 'white'
                    : '#000'
                }
              }
              const getBg = () => {
                if (isSameDay(now, value)) {
                  return 'transparent'
                } else {
                  return isSameDay(currentDate, value)
                    ? 'primary'
                    : 'transparent'
                }
              }

              return (
                <Flex
                  key={value.getTime()}
                  position='relative'
                  justifyContent='center'
                  alignItems='center'
                >
                  <Flex
                    justifyContent='center'
                    padding='0.3rem'
                    mb='0.25rem'
                    color={getColor()}
                    border='2px solid'
                    borderColor={
                      isSameDay(now, value) || isSameDay(currentDate, value)
                        ? 'primary'
                        : 'transparent'
                    }
                    bg={getBg()}
                    borderRadius='50%'
                    cursor='pointer'
                    width='37.5px'
                    onClick={handleClickDate(value)}
                  >
                    <Box as='p'>{value.getDate()}</Box>
                  </Flex>

                  {!!events?.length && (
                    <Flex
                      position='absolute'
                      bottom={
                        isSameDay(now, value) || isSameDay(currentDate, value)
                          ? '-0.25rem'
                          : '0.5rem'
                      }
                    >
                      {!!events.length && (
                        <Box
                          width='0.3125rem'
                          height='0.3125rem'
                          mx='0.125rem'
                          backgroundColor={ClassColor.PRIVATE_COLOR}
                          borderRadius='50%'
                        />
                      )}
                    </Flex>
                  )}
                </Flex>
              )
            })}
          </Box>
        ))}
      </Grid>
    </Box>
  )
}

export default memo(Calendar)

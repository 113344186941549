import React, { useCallback, useMemo } from 'react'
import { useParams } from 'react-router-dom'

import { Box, Flex, Image, Text, useDisclosure } from '@chakra-ui/react'

import { CancelLessonModal } from 'components'
import { useLanguages, useUserId } from 'hooks'
import {
  useClassDetail,
  useLearnerCancelClass,
  useTutorCancelClass,
} from 'queries/class'
import { PreviewClass } from 'shared/containers'
import { ClassDetail, ClassPreview } from 'types'

import ArrowBackIcon from 'assets/images/arrowBack_icon.svg'
import { ReactComponent as ClassEmptyIcon } from 'assets/images/empty_classes_icon.svg'

interface PreviewCreatedLessonProps {
  userRoleCancellation?: 'learner' | 'instructor'
  handleToBack?: () => void
  onBuyClass?: (data: ClassPreview) => void
  onEditClass?: () => void
}

const PreviewCreatedLesson = ({
  handleToBack,
  onBuyClass,
  userRoleCancellation,
  onEditClass,
}: PreviewCreatedLessonProps) => {
  const { _t } = useLanguages()
  const params = useParams()
  const userId = useUserId()

  const classId = useMemo(() => params.id, [params])
  const { isOpen, onOpen, onClose } = useDisclosure()

  const { data: classDetail, isError } = useClassDetail({ classId })
  const { mutate: learnerCancel } = useLearnerCancelClass(classId)
  const { mutate: instructorCancel } = useTutorCancelClass(classId)

  const formatData = (data?: ClassDetail) => {
    if (data) {
      const { teachingLevel, language, subject } = data

      return {
        ...data,
        teachingLevel: {
          value: teachingLevel.id,
          label: teachingLevel.name,
        },
        subject: { value: subject.id, label: subject.name },
        language: { value: language.id, label: language.name },
      }
    }
  }

  const onCancel = useCallback(() => {
    onOpen()
  }, [onOpen])

  const handleCancel = useCallback(
    (reason: string, packageIds: string[] | undefined) => {
      if (userRoleCancellation === 'learner') {
        learnerCancel({ userId, classId, data: { reason }, onClose })
      } else {
        if (packageIds) {
          for (const id of packageIds) {
            instructorCancel({
              userId,
              classId: id,
              data: { reason },
              onClose,
            })
          }
        }
        instructorCancel({ userId, classId, data: { reason }, onClose })
      }
    },
    [
      classId,
      learnerCancel,
      onClose,
      instructorCancel,
      userId,
      userRoleCancellation,
    ]
  )

  return (
    <>
      {!!handleToBack && (
        <Box
          as='section'
          pt='1.5rem'
          px={['1.5rem', '6rem', '7rem']}
          bg={isError ? 'transparent' : 'secondaryLightBlue'}
        >
          <Box as='button' onClick={handleToBack}>
            <Image src={ArrowBackIcon} d='inline' mr='0.5rem' />
            {_t('product.class.session_management')}
          </Box>
        </Box>
      )}

      {isError ? (
        <Flex minH='calc(100vh - 4.5rem)' justify='center'>
          <Flex direction='column' justify='center' align='center'>
            <ClassEmptyIcon />
            <Text color='gray.300' mt='1rem'>
              {_t('product.lesson.not_found')}
            </Text>
          </Flex>
        </Flex>
      ) : (
        <PreviewClass
          data={formatData(classDetail?.data)}
          userId={classDetail?.data.user.id as string}
          attachments={classDetail?.data.attachments || []}
          onBuyClass={onBuyClass}
          onCancelClass={onCancel}
          onEditClass={onEditClass}
        />
      )}

      {userRoleCancellation && (
        <CancelLessonModal
          data={formatData(classDetail?.data)}
          isOpen={isOpen}
          onClose={onClose}
          onSubmit={handleCancel}
        />
      )}
    </>
  )
}

export default PreviewCreatedLesson

export type ChatUser = {
  id?: string
  firstName?: string
  lastName?: string
  avatar?: string
  opt?: string
}

export enum MessageType {
  TEXT = 'text',
  FILE = 'file',
}

export type Conversation = {
  id?: string
  name?: string
  users?: { opt?: string; id?: string }[]
  isGroup?: boolean
  userIds?: string[]
  createdAt?: number
  createdBy?: string
  lastSeen?: number
  order?: number
}

export type SearchConversation = {
  id: string
  conversationId: string
  content: string
  type: string
  conversation: Conversation
  createdAt: number
}

export type ChatMessage = {
  id?: string
  conversationId?: string
  type: string
  content: string
  caption?: string
  contentType?: string
  createdAt?: number
  isSending?: boolean
  createdBy?: string
  file?: File
  fileName?: string
}

export type UnreadConversation = {
  userId: string
  conversationId: string
  lastSeen: number
}

export interface ChatResponse {
  id: string
  data?: {
    fields: {
      key: string
      'Content-Type': string
      bucket: string
      'X-Amz-Algorithm': string
      'X-Amz-Credential': string
      'X-Amz-Date': string
      'X-Amz-Security-Token': string
      Policy: string
      'X-Amz-Signature': string
      'x-amz-meta-centerid': string
    }
    url: string
  }
}

import React from 'react'

import { Box, Flex } from '@chakra-ui/react'

interface ProgressProps {
  index: number
  size: number
  label: string
}

const Progress: React.FC<ProgressProps> = ({ index, size, label }) => {
  return (
    <Flex justifyContent='left' m='0.5rem'>
      <Box alignItems='center' justifyContent='center' w='1rem'>
        {index + 1}
      </Box>
      <Box borderRadius='50%' w='100%' ml='1rem'>
        <Box
          w={`${5 + size}%`}
          background='#ABB2B9'
          fontSize='medium'
          color='#FFF'
          textAlign='center'
          p='0.05rem'
        >
          {label}
        </Box>
      </Box>
    </Flex>
  )
}

export default Progress

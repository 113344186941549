import React from 'react'

import {
  Box,
  Button,
  Flex,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
} from '@chakra-ui/react'

import { searchOptions, sortOptions } from '../../form'
import { Option, PopoverItem, SearchBarWithOption } from 'components'
import { useLanguages } from 'hooks'

import { ReactComponent as CheckIcon } from 'assets/images/checked_icon.svg'
import { ReactComponent as FilterIcon } from 'assets/images/shape_gray.svg'
import { ReactComponent as SortIcon } from 'assets/images/sort_icon.svg'

interface DataTableHeaderProps {
  count: number
  orderBy: string
  handleOpenFilter: () => void
  handleSearch: (value: string) => void
  handleSearchBy: (value: Option) => void
  handleSort: (sortBy: string, onCloseSort: () => void) => void
}

const DataTableHeader: React.FC<DataTableHeaderProps> = ({
  count,
  orderBy,
  handleOpenFilter,
  handleSearch,
  handleSearchBy,
  handleSort,
}: DataTableHeaderProps) => {
  const { _t } = useLanguages()

  return (
    <Flex justifyContent='space-between' alignItems='center'>
      <Flex alignItems='center' zIndex={5}>
        <Box width='22rem'>
          <SearchBarWithOption
            searchOptions={searchOptions}
            onSearch={handleSearch}
            onSearchOption={handleSearchBy}
            color='gray.300'
          />
        </Box>

        <Popover>
          {({ onClose: onCloseSort }) => (
            <>
              <PopoverTrigger>
                <Button
                  variant='outlinePrimary'
                  color='gray.300'
                  px='1rem'
                  mx='1rem'
                >
                  <Box mr='5px'>
                    <SortIcon />
                  </Box>
                  {_t('common.sort')}
                </Button>
              </PopoverTrigger>
              <PopoverContent
                bgColor='#fff'
                boxShadow='lg'
                minW='17rem'
                transform='translateX(1140px)'
                _focus={{
                  outline: 0,
                }}
              >
                <PopoverBody>
                  {sortOptions.map(option => (
                    <PopoverItem
                      onClick={() => handleSort(option.value, onCloseSort)}
                      key={option.value}
                      color='#000'
                      py='1rem'
                      px='0'
                      borderColor='gray.200'
                    >
                      <Flex
                        w='100%'
                        justifyContent='space-between'
                        alignItems='center'
                      >
                        <Box>{option.label}</Box>
                        {orderBy === option.value ? <CheckIcon /> : ''}
                      </Flex>
                    </PopoverItem>
                  ))}
                </PopoverBody>
              </PopoverContent>
            </>
          )}
        </Popover>
        <Button
          variant='outlinePrimary'
          color='gray.300'
          px='1rem'
          mr='1rem'
          onClick={handleOpenFilter}
        >
          <Box mr='5px'>
            <FilterIcon />
          </Box>
          {_t('common.filter')}
        </Button>
        <Text textStyle='authText' color='gray.300'>
          {_t('transaction_approval.management.no_of_records', {
            count: `${count || 0}`,
          })}
        </Text>
      </Flex>
      {/* {(role === UserRole.MSTSuperAdmin ||
        role === UserRole.EnterpriseAdmin) && (
        <Button
          variant='outlinePrimary'
          textTransform='uppercase'
          onClick={onOpenExport}
        >
          {_t('common.export')}
        </Button>
      )} */}
    </Flex>
  )
}

export default DataTableHeader

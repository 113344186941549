import React from 'react'

import {
  Box,
  BoxProps,
  TextareaProps as ChakraTextareaProps,
  Text,
  Textarea,
} from '@chakra-ui/react'

import { useLanguages } from 'hooks'

interface TextareaProps extends ChakraTextareaProps {
  error?: string | undefined
  customErrorMessages?: React.ReactNode
  containerBoxProps?: BoxProps
  labelProps?: BoxProps
  label?: string
  optional?: boolean
  disabled?: boolean
}

const CustomTextarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  (
    {
      error,
      customErrorMessages,
      containerBoxProps,
      children,
      label,
      variant,
      optional,
      labelProps,
      disabled,
      ...textareaProps
    },
    ref
  ) => {
    const { _t } = useLanguages()

    return (
      <Box width='100%' {...containerBoxProps}>
        {label && (
          <Box
            color={disabled ? 'gray.300' : 'black'}
            fontSize='0.875rem'
            fontWeight='600'
            {...labelProps}
          >
            {label}{' '}
            {optional && (
              <Text
                display='inline'
                fontSize={labelProps?.fontSize || '0.875rem'}
                fontStyle='italic'
                color='gray.300'
              >
                - {_t('common.optional')}
              </Text>
            )}
          </Box>
        )}
        <Textarea
          ref={ref}
          variant={variant}
          resize='none'
          borderBottom='1px solid'
          borderBottomColor={variant === 'flushed' ? 'gray.300' : 'gray.100'}
          errorBorderColor='error'
          disabled={disabled}
          {...textareaProps}
        />
        {customErrorMessages ||
          (error && <Text textStyle='error'>{error}</Text>)}
      </Box>
    )
  }
)

export default CustomTextarea

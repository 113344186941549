import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
} from 'react'
import { useForm } from 'react-hook-form'

import { LoadingSpinner } from 'components'
import { useUpdateBankAccount, useUserWallet } from 'queries/wallet'
import { BankAccountEdit } from 'shared/containers/BankAccount'
import { BankDetailForm, TabRef, WalletBankRequest } from 'types'
import { bankAccountCountryOptions } from 'utils/constant'
import { getSelectedOptions } from 'utils/parser'

interface EditPaymentDetailsSectionProps {
  userId: string
}

const EditPaymentDetailsSection: React.ForwardRefRenderFunction<
  TabRef,
  EditPaymentDetailsSectionProps
> = ({ userId }, ref) => {
  const { data: walletInfo, isLoading } = useUserWallet({
    type: 'user',
    id: userId,
  })
  const { mutate: updateUserBankAccount } = useUpdateBankAccount({
    type: 'user',
    id: userId,
  })

  const walletData = useMemo(() => walletInfo?.data, [walletInfo])

  const { handleSubmit, control, reset, errors } = useForm<BankDetailForm>({
    defaultValues: {
      bankAccountName: walletData?.bankAccountName || '',
      bankName: walletData?.bankName || '',
      bankAccountNumber: walletData?.bankAccountNumber || '',
      country: getSelectedOptions(
        bankAccountCountryOptions,
        walletData?.country
      ),
      bankRemarks: walletData?.bankRemarks || '',
    },
  })

  useEffect(() => {
    if (walletInfo?.data) {
      const resetData = {
        bankAccountName: walletInfo?.data?.bankAccountName || '',
        bankName: walletInfo?.data?.bankName || '',
        bankAccountNumber: walletInfo?.data?.bankAccountNumber || '',
        country: getSelectedOptions(
          bankAccountCountryOptions,
          walletInfo?.data?.country
        ),
        bankRemarks: walletInfo?.data?.bankRemarks || '',
      }

      reset({ ...resetData })
    }
  }, [reset, walletInfo])

  const handleUpdate = useCallback(
    (value: BankDetailForm) => {
      const {
        bankAccountName,
        bankName,
        bankAccountNumber,
        country,
        bankRemarks,
      } = value
      const formattedData = {
        type: 'user',
        id: userId,
        bankAccountName,
        bankName,
        bankAccountNumber,
        bankRemarks,
        country: country.value,
      } as WalletBankRequest

      updateUserBankAccount({ data: formattedData })
    },
    [updateUserBankAccount, userId]
  )

  useImperativeHandle(
    ref,
    () => ({
      submit: handleSubmit(handleUpdate),
    }),
    [handleSubmit, handleUpdate]
  )

  return isLoading ? (
    <LoadingSpinner />
  ) : (
    <BankAccountEdit control={control} errors={errors} />
  )
}

export default React.forwardRef(EditPaymentDetailsSection)

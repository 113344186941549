import { useMutation, useQuery } from 'react-query'

import { useToastError } from './helpers'
import { addLanguage, deleteLanguage, updateLanguage } from 'apis/language'
import { language } from 'configs/apiPath'
import {
  useInvalidateUrl,
  useLanguages as useLanguagesHook,
  useLoading,
  useToast,
} from 'hooks'
import { Language, ListingParams, QueryListResponse } from 'types'

export const useLanguages = ({
  page,
  limit,
  search,
  active,
}: ListingParams) => {
  return useQuery<QueryListResponse<Language>>(
    language.list({
      page,
      limit,
      search,
      ...(typeof active === 'boolean' && { active }),
    })
  )
}

export const useAddLanguage = () => {
  const toast = useToast()
  const invalidateUrl = useInvalidateUrl(language.list())
  const { setLoading } = useLoading()
  const { _t } = useLanguagesHook()

  return useMutation(addLanguage, {
    onMutate: () => setLoading(true),
    onSettled: () => setLoading(false),
    onSuccess: (_, { name }) => {
      invalidateUrl()
      toast({
        title: 'Success!',
        description: _t('message.success.language_add', {
          name,
        }),
        status: 'success',
      })
    },
    onError: useToastError(),
  })
}

export const useEditLanguage = () => {
  const toast = useToast()
  const invalidateUrl = useInvalidateUrl(language.list())
  const { setLoading } = useLoading()
  const { _t } = useLanguagesHook()

  return useMutation(updateLanguage, {
    onMutate: () => setLoading(true),
    onSettled: () => setLoading(false),
    onSuccess: (_, { name }) => {
      invalidateUrl()
      toast({
        title: 'Success!',
        description: _t('message.success.language_update', {
          name,
        }),
        status: 'success',
      })
    },
    onError: useToastError(),
  })
}

export const useDeleteLanguage = () => {
  const toast = useToast()
  const invalidateUrl = useInvalidateUrl(language.list())
  const { setLoading } = useLoading()
  const { _t } = useLanguagesHook()

  return useMutation(deleteLanguage, {
    onMutate: () => setLoading(true),
    onSettled: () => setLoading(false),
    onSuccess: () => {
      invalidateUrl()
      toast({
        title: 'Success!',
        description: _t('message.success.language_remove'),
        status: 'success',
      })
    },
    onError: useToastError(),
  })
}

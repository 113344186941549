import React from 'react'

import { Box, BoxProps, Flex } from '@chakra-ui/react'

import { IconText } from 'components'

interface IconBoxProps extends BoxProps {
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
  title: string
  rightElement?: React.ReactNode
}

const IconBox: React.FC<IconBoxProps> = ({
  icon,
  title,
  rightElement,
  children,
  ...boxProps
}) => {
  return (
    <Box {...boxProps}>
      <Flex
        direction='row'
        alignContent='center'
        justifyContent='space-between'
        flexWrap='wrap'
        mb='1rem'
        gridColumnGap='1rem'
        gridRowGap='0.25'
      >
        <IconText
          icon={icon}
          text={title}
          textProps={{
            fontWeight: '600',
            textTransform: 'uppercase',
          }}
        />
        {rightElement}
      </Flex>
      {children}
    </Box>
  )
}

export default IconBox

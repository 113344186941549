import React, { ReactNode } from 'react'

import { Box, Button } from '@chakra-ui/react'

import { useLanguages } from 'hooks'
import { CharStatus } from 'utils/game/status'

import { ReactComponent as DeleteIcon } from 'assets/images/del_icon.svg'
import { ReactComponent as EnterIcon } from 'assets/images/enter_icon.svg'

interface KeyProps {
  children?: ReactNode
  value: string
  width?: string
  status?: CharStatus
  onClick: (value: string) => void
  isRevealing?: boolean
  display?: string
}

const Key: React.FC<KeyProps> = ({
  children,
  status,
  width = '1rem',
  value,
  display,
  onClick,
  isRevealing,
}) => {
  const { _t } = useLanguages()
  const handleClick: React.MouseEventHandler<HTMLButtonElement> = event => {
    onClick(value)
    event.currentTarget.blur()
  }

  switch (status) {
    case 'absent':
      return (
        <Button
          w={width}
          color='#FFF'
          background='#64748b'
          border='#64748b'
          boxShadow='2xl'
          onClick={handleClick}
          mx='0.25rem'
        >
          {children || value}
        </Button>
      )
    case 'correct':
      return (
        <Button
          w={width}
          color='#FFF'
          background='#78BD88'
          border='#78BD88'
          boxShadow='2xl'
          onClick={handleClick}
          mx='0.25rem'
        >
          {children || value}
        </Button>
      )
    case 'present':
      return (
        <Button
          w={width}
          color='#FFF'
          background='#EAB184'
          border='#EAB184'
          boxShadow='2xl'
          onClick={handleClick}
          mx='0.25rem'
        >
          {children || value}
        </Button>
      )
    default:
      if (value === 'DELETE') {
        if (display === 'mobile') {
          return (
            <Button
              fontSize='1.5rem'
              color='primaryDark'
              variant='outlinePrimary'
              display={{ base: 'inline-flex', md: 'none' }}
              onClick={handleClick}
              mx='0.25rem'
              w={width}
            >
              <Box>
                <DeleteIcon />
              </Box>
            </Button>
          )
        } else {
          return (
            <Button
              w='13.5rem'
              fontSize='1.5rem'
              color='#3A3A3A'
              border='1px solid #837BD8'
              variant='outlinePrimary'
              display={{ base: 'none', md: 'inline-flex' }}
              onClick={handleClick}
              mx='0.25rem'
            >
              {_t('games.knovole.key_delete')}
            </Button>
          )
        }
      } else if (value === 'ENTER') {
        if (display === 'mobile') {
          return (
            <Button
              fontSize='1.5rem'
              color='#FFF'
              display={{ base: 'inline-flex', md: 'none' }}
              onClick={handleClick}
              mx='0.25rem'
              w={width}
            >
              <Box>
                <EnterIcon />
              </Box>
            </Button>
          )
        } else {
          return (
            <Button
              w='13.5rem'
              fontSize='1.5rem'
              color='#FFF'
              display={{ base: 'none', md: 'inline-flex' }}
              border='none'
              onClick={handleClick}
              mx='0.25rem'
            >
              {_t('games.knovole.key_enter')}
            </Button>
          )
        }
      } else {
        return (
          <Button
            w={width}
            color='#000'
            border='none'
            boxShadow='2xl'
            background='#FFF'
            onClick={handleClick}
            mx='0.25rem'
          >
            {children || value}
          </Button>
        )
      }
  }
}

export default Key

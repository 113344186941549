import { useCallback, useEffect, useState } from 'react'

import { AxiosResponse } from 'axios'

import { getUploadAttachmentAdhocRequestUrl } from 'apis/adhocRequest'
import { uploadFile } from 'apis/upload'
import { useUserId } from 'hooks'
import {
  Attachment,
  AttachmentFileStatus,
  ClassUploadUrl,
  UploadFileStatus,
} from 'types'

interface UploadFile {
  file: Attachment
  status: AttachmentFileStatus
  uploadStatus: UploadFileStatus
}

export const useUploadAdhocRequestFile = (
  defaultUploadedFiles: Attachment[] = []
) => {
  const [uploadFiles, handleChangeFiles] = useState<UploadFile[]>(() =>
    defaultUploadedFiles.map<UploadFile>(uploadedFile => ({
      file: uploadedFile,
      status: uploadedFile.status || 'public',
      uploadStatus: uploadedFile?.createdAt ? 'uploaded' : 'pending',
    }))
  )
  const userId = useUserId()
  const [isUploading, setUploading] = useState(false)
  const [filesError, setFilesError] = useState<string[]>([])
  const [isSuccess, setIsSuccess] = useState(false)
  const [isError, setIsError] = useState(false)
  const [isSettled, setIsSettled] = useState(false)

  useEffect(() => {
    if (isSettled) {
      handleChangeFiles(oldFiles => {
        return oldFiles.map<UploadFile>(uploadedFile => ({
          ...uploadedFile,
          uploadStatus: filesError.includes(uploadedFile.file.name)
            ? 'failure'
            : 'uploaded',
        }))
      })
    }
  }, [isSettled, filesError])

  // automatically set not settled
  useEffect(() => {
    if (isSettled) {
      setTimeout(() => setIsSettled(false), 0)
    }
  }, [isSettled])

  const handleUpload = useCallback(
    async (requestId, learnerId?) => {
      setUploading(true)
      setIsSettled(false)
      const promises: Promise<AxiosResponse<ClassUploadUrl>>[] = []

      const newUploadFiles = uploadFiles.filter(
        file => file.uploadStatus !== 'uploaded'
      )

      newUploadFiles?.forEach(file =>
        promises.push(
          getUploadAttachmentAdhocRequestUrl({
            userId: learnerId ? learnerId : userId,
            requestId,
            file: file.file,
          })
        )
      )

      try {
        const uploadUrlRes = await Promise.allSettled(promises)

        const uploadStatus = await Promise.allSettled(
          uploadUrlRes.map((uploadUrl, index) => {
            if (uploadUrl.status === 'fulfilled') {
              return uploadFile(
                newUploadFiles[index].file,
                uploadUrl.value.data
              )
            }

            return Promise.reject({
              fileName: newUploadFiles[index].file.name,
            })
          })
        )

        let errorNames: string[] = []

        uploadStatus.forEach(upload => {
          if (upload.status === 'rejected') {
            errorNames.push(upload.reason?.fileName)
          }
        })

        setFilesError(errorNames)
        setIsSuccess(true)
        setIsError(false)
      } catch {
        setIsSuccess(false)
        setIsError(true)
      } finally {
        setUploading(false)
        setIsSettled(true)
      }
    },
    [uploadFiles, userId]
  )

  return {
    uploadFiles,
    isUploading,
    isError,
    isSuccess,
    isSettled,
    filesError,
    handleUpload,
    handleChangeFiles,
  }
}

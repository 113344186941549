import React from 'react'

import { Flex, Text } from '@chakra-ui/react'

import { ReactComponent as RateStar } from 'assets/images/rate_star.svg'

interface RatingSmallProps {
  rate: number
}

const RatingSmall: React.FC<RatingSmallProps> = ({ rate }) => {
  return (
    <Flex alignItems='center'>
      <RateStar />
      <Text ml='0.5rem' fontSize='12px' color='gray.400'>
        {rate}
      </Text>
    </Flex>
  )
}

export default RatingSmall

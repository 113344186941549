import React, { useMemo } from 'react'

import { Alert, Box, Flex, Icon } from '@chakra-ui/react'

import BankAccountInfo from './BankAccountInfo'
import CreditBalance from './CreditBalance'
import MyCard from './MyCard'
import TransactionDataTable from './TransactionDataTable'
import { Paginate } from 'components'
import { useCurrentPage, useLanguages, useUserId } from 'hooks'
import { useTransactions } from 'queries/transaction'
import { useUserWallet } from 'queries/wallet'
import { UserRole, UserRoleType } from 'types'

import { ReactComponent as KnovoIcon } from 'assets/images/knovo_logo_grayscale.svg'

interface OverviewTabProps {
  role: UserRoleType
  currentSubscriptionName?: string
}

const limit = 10

const OverviewTab: React.FC<OverviewTabProps> = ({
  role,
  currentSubscriptionName,
}) => {
  const { _t } = useLanguages()
  const userId = useUserId()
  const currentPage = useCurrentPage()

  const userType = useMemo(() => {
    switch (role) {
      case UserRole.MSTAdmin:
      case UserRole.MSTSuperAdmin:
        return 'admin'
      case UserRole.EnterpriseAdmin:
      case UserRole.EnterpriseSuperAdmin:
        return 'enterprise'
      case UserRole.CenterAdmin:
        return 'center'
      default:
        return 'user'
    }
  }, [role])

  const { data: transactions, isLoading } = useTransactions(
    {
      type: userType,
      id: userId,
    },
    {
      page: currentPage,
      limit,
      order: 'desc',
    }
  )

  const { data: walletInfo } = useUserWallet({
    type: userType,
    id: userId,
  })

  return (
    <Flex direction='column'>
      {role === UserRole.Tutor && currentSubscriptionName && (
        <Alert status='info' rounded='lg' mb='1rem'>
          <Icon as={KnovoIcon} w='30px' h='30px' mr='1rem' />
          {_t('wallet.subscription.description', {
            name: currentSubscriptionName,
          })}
        </Alert>
      )}
      <Flex
        direction={{ base: 'column', md: 'row' }}
        justify='space-between'
        mb='2rem'
        flexWrap='wrap'
      >
        <CreditBalance role={role} walletInfo={walletInfo?.data} />
        {role === UserRole.Tutor && (
          <BankAccountInfo role={role} walletInfo={walletInfo?.data} />
        )}
        {[
          UserRole.Student,
          UserRole.Tutor,
          UserRole.EnterpriseStudent,
        ].includes(role) && (
          <MyCard role={role} walletInfo={walletInfo?.data} />
        )}
      </Flex>
      <Flex direction='column'>
        <Box as='h1'>{_t('wallet.transactions.title')}</Box>
        <Flex alignItems='center' justifyContent='flex-end'>
          <Paginate
            currentPage={currentPage}
            perPage={limit}
            totalItems={transactions?.meta.total || 0}
          />
        </Flex>

        <Box mt='1.5rem'>
          <TransactionDataTable
            role={role}
            data={transactions?.data || []}
            isLoading={isLoading}
          />
        </Box>
      </Flex>
    </Flex>
  )
}

export default OverviewTab

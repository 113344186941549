import { Option } from 'components'

export enum Recipient {
  PrivateTutor = 'PrivateTutor',
  EnterpriseTutor = 'EnterpriseTutor',
  PrivateStudent = 'Student',
  EnterpriseStudent = 'EnterpriseStudent',
  MSTSuperAdmin = 'MSTSuperAdmin',
  EnterpriseAdmin = 'EnterpriseAdmin',
  CenterAdmin = 'CenterAdmin',
  MSTAdmin = 'MSTAdmin',
}

export type RecipientType =
  | Recipient.MSTAdmin
  | Recipient.PrivateTutor
  | Recipient.EnterpriseTutor
  | Recipient.PrivateStudent
  | Recipient.EnterpriseStudent
  | Recipient.MSTSuperAdmin
  | Recipient.EnterpriseAdmin
  | Recipient.CenterAdmin

export interface Notification {
  id?: string
  name: string
  triggerPoint: TriggerPoint
  recipients: RecipientType[]
  sendByInApp: boolean
  sendByEmail: boolean
  notificationTitle?: string
  notificationMessage?: string
  emailSubject?: string
  emailContent?: string
  type?: string
}

export interface TriggerPoint {
  group: string
  id: string
  name: string
}

export interface AddNotificationForm {
  name: string
  triggerPoint: Option
  recipients: RecipientType[]
  sendByInApp: boolean
  sendByEmail: boolean
  notificationTitle?: string
  notificationMessage?: string
  emailSubject?: string
  emailContent?: string
}

export interface NotificationOnApp {
  id: string
  createdAt: number
  data: string
  deletedAt: number
  isRead: boolean
  message: string
  userId: string
  connectionId?: string
  action?: NotificationActionType
  title: string
  type: string
  classDate?: string
  classId?: string
  className?: string
  classTime?: string
  classType?: string
  classURL?: string
  isParse?: boolean
  actionType?: string
  bidId?: string
  requestID?: string
  assignmentId?: string
  assignmentName?: string
}

export enum NotificationActionType {
  ADD_FRIEND = 'AddFriend',
  ACCEPTED_FRIEND = 'AcceptedFriend',
  DECLINED_FRIEND = 'AcceptedFriend',
  CREATE_CLASS = 'CreateClass',
  UPDATE_CLASS = 'UpdateClass',
  CREATE_ACCOUNT = 'CreateAccount',
  REGISTER_ACCOUNT = 'RegisterAccount',
}

export enum NotificationType {
  All = 'All',
  General = 'General',
  Request = 'Request',
  Connection = 'Connection',
  Class = 'Class',
}

export enum ParseNotificationTimeType {
  DATE = 'Date',
  DATE_TIME = 'DateTime',
  TIME = 'Time',
}

export enum RedirectActionType {
  LEARNER_CANCEL_CLASS = 'LearnerCancelClass',
  TUTOR_BID_ADHOC = 'TutorBidAdhoc',
  BID_EXPIRED = 'BidExpired',
  BID_EXPIRED_BEFORE_5_MINUTES = 'BidExpiredBefore5Minutes',
  INVITED_STUDENT = 'InvitedStudent',
  CONFIRMED_STUDENT = 'ConfirmedStudent',
  CLASS_CREATED_BY_ADMIN = 'ClassCreatedByAdmin',
  SESSION_UPDATED_TIME = 'SessionUpdatedTime',
  ACCOUNT_CREATION = 'AccountCreation',
  PURCHASED_CLASS = 'PurchasedClass',
  USER_ACCEPTED_FRIEND = 'UserAcceptFriend',
  USER_SENT_FRIEND_USER = 'UserSentFriendToUser',
  USER_SENT_FRIEND_CENTER = 'UserSentFriendToCenter',
  USER_SIGN_UP = 'UserSignUp',
  ADMIN_CREATED_CONNECTIONS = 'AdminCreatedConnections',
  ADMIN_DESTROYED_CONNECTIONS = 'AdminDestroyedConnections',
  STUDENT_SUBMIT_ASSIGNMENT = 'StudentSubmitAssignment',
}

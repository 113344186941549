import React, { useMemo } from 'react'

import {
  Box,
  Center,
  Link as ChakraLink,
  Divider,
  Flex,
  Icon,
  Text,
} from '@chakra-ui/react'
import format from 'date-fns/format'

import { useLanguages } from 'hooks'

import { ReactComponent as ContactIcon } from 'assets/images/contact_icon.svg'
import { ReactComponent as CoordinateIcon } from 'assets/images/coordinate_icon.svg'
import { ReactComponent as EnvelopeIcon } from 'assets/images/envelope_icon.svg'
import { ReactComponent as Logo } from 'assets/images/logo_large.svg'
import { ReactComponent as FacebookIcon } from 'assets/images/social_facebook_grayscale.svg'
import { ReactComponent as InstagramIcon } from 'assets/images/social_instagram_grayscale.svg'
import { ReactComponent as LinkedinIcon } from 'assets/images/social_linkedin_grayscale.svg'
import { ReactComponent as TwitterIcon } from 'assets/images/social_twitter_grayscale.svg'
import { ReactComponent as YoutubeIcon } from 'assets/images/social_youtube_grayscale.svg'

interface FooterProps {}

const homeURL = 'https://www.knovo.io/'

const Footer: React.FC<FooterProps> = () => {
  const year = useMemo(() => format(new Date(), 'yyyy'), [])
  const { _t } = useLanguages()
  return (
    <Box pt='3rem'>
      <Flex
        flexDirection={{ base: 'column', md: 'row' }}
        px={['2rem', '5rem', '5rem', '10rem']}
        justify={'space-between'}
        pb={'3rem'}
      >
        <Flex
          flexDirection={{ base: 'column', sm: 'row' }}
          px={{ base: '0.5rem', md: '0' }}
          flexGrow={{ base: 1, sm: 2 }}
          justify={'space-evenly'}
        >
          <Flex
            flexDirection={{ base: 'column', lg: 'row' }}
            flexGrow={{ base: 1, lg: 2 }}
            justify={{ base: 'flex-start', lg: 'space-evenly' }}
          >
            <Box pb='2rem' minW='10rem'>
              <Text
                fontSize='2xl'
                color='primaryDarker'
                mb='0.5rem'
                fontWeight={600}
              >
                {_t('common.knovo')}
              </Text>
              <Box mb='0.5rem'>
                <ChakraLink href={'https://www.knovo.io/about-us/'} isExternal>
                  {_t('footer.company.about_us')}
                </ChakraLink>
              </Box>
              <Box mb='0.5rem'>
                <ChakraLink href={homeURL} isExternal>
                  {_t('footer.company.hear_from_users')}
                </ChakraLink>
              </Box>
              <ChakraLink href={'https://www.knovo.io/contact-us/'} isExternal>
                {_t('footer.company.contact_us')}
              </ChakraLink>
            </Box>

            <Box pb='2rem' minW='10rem'>
              <Text
                fontSize='2xl'
                color='primaryDarker'
                mb='0.5rem'
                fontWeight={600}
              >
                {_t('footer.join.title')}
              </Text>
              <Box mb='0.5rem'>
                <ChakraLink href={'https://www.knovo.io/learners/'} isExternal>
                  {_t('footer.join.learners')}
                </ChakraLink>
              </Box>
              <Box mb='0.5rem'>
                <ChakraLink
                  href={'https://www.knovo.io/enterprises/'}
                  isExternal
                >
                  {_t('footer.join.enterprises')}
                </ChakraLink>
              </Box>
              <ChakraLink href={'https://www.knovo.io/instructors/'} isExternal>
                {_t('footer.join.instructors')}
              </ChakraLink>
            </Box>
          </Flex>
          <Box pb='2rem' minW='10rem' flexGrow={{ base: 1, xl: 2 }}>
            <Text
              fontSize='2xl'
              color='primaryDarker'
              mb='0.5rem'
              fontWeight={600}
            >
              {_t('footer.learn.title')}
            </Text>
            <Flex flexDirection={{ base: 'column', xl: 'row' }}>
              <Box mr='2rem'>
                <Box mb='0.5rem'>
                  <ChakraLink href={'/search-results?s=debate'}>
                    {_t('footer.learn.debate')}
                  </ChakraLink>
                </Box>
                <Box mb='0.5rem'>
                  <ChakraLink href={'/search-results?s=programming'}>
                    {_t('footer.learn.programming')}
                  </ChakraLink>
                </Box>
                <Box mb='0.5rem'>
                  <ChakraLink href={'/search-results?s=chinese'}>
                    {_t('footer.learn.chinese')}
                  </ChakraLink>
                </Box>
                <Box mb='0.5rem'>
                  <ChakraLink href={'/search-results?s=mathematics'}>
                    {_t('footer.learn.mathematics')}
                  </ChakraLink>
                </Box>
              </Box>
              <Box>
                <Box mb='0.5rem'>
                  <ChakraLink href={'/search-results?s=science'}>
                    {_t('footer.learn.science')}
                  </ChakraLink>
                </Box>
                <Box mb='0.5rem'>
                  <ChakraLink href={'/search-results?s=amath'}>
                    {_t('footer.learn.a_maths')}
                  </ChakraLink>
                </Box>
                <Box mb='0.5rem'>
                  <ChakraLink href={'/search-results?s=english'}>
                    {_t('footer.learn.english')}
                  </ChakraLink>
                </Box>
                <Box mb='0.5rem'>
                  <ChakraLink href={'/search-results?s=mental%20wellness'}>
                    {_t('footer.learn.mental_wellness')}
                  </ChakraLink>
                </Box>
              </Box>
            </Flex>
          </Box>
        </Flex>
        <Box flexGrow={1}>
          <Logo width='192px' height='80px' />
          <Box ml='0.5rem'>
            <Flex mb='0.5rem'>
              <Box w='1.5rem' mt='0.2rem'>
                <CoordinateIcon height='20px' width='20px' />
              </Box>
              <Box>
                <Text>350 Orchard Rd, #15-08/10</Text>
                <Text>Singapore 238868</Text>
              </Box>
            </Flex>
            <Box mb='0.5rem'>
              <Flex flexDirection='row' align='center'>
                <Box w='1.5rem'>
                  <EnvelopeIcon />
                </Box>
                <Text>hello@knovo.io</Text>
              </Flex>
            </Box>
            <Flex flexDirection='row' align='center'>
              <Box w='1.5rem'>
                <ContactIcon />
              </Box>
              <Text>+65 9670 7326</Text>
            </Flex>
          </Box>
        </Box>
      </Flex>
      <Divider />
      <Box mt='0.5rem' mb='1.5rem' px={'2rem'}>
        <Flex
          flexDirection={{ base: 'column', lg: 'row' }}
          justify={'space-between'}
        >
          <Center mt='1rem'>
            <Flex flexDirection='row'>
              <Box mr='3rem'>
                <ChakraLink href={'https://www.knovo.io/terms/'} isExternal>
                  {_t('common.terms')}
                </ChakraLink>
              </Box>
              <Box>
                <ChakraLink href={'https://www.knovo.io/privacy/'} isExternal>
                  {_t('common.policy')}
                </ChakraLink>
              </Box>
            </Flex>
          </Center>
          <Center mt='1rem'>
            <Text>{_t('footer.copyright', { year: year })}</Text>
          </Center>
          <Center mt='1rem'>
            <Flex flexDirection='row'>
              <ChakraLink
                href={'https://www.facebook.com/knovo.official'}
                isExternal
              >
                <Icon as={FacebookIcon} w='1.9rem' h='1.9rem' />
              </ChakraLink>
              <ChakraLink
                href={'https://www.instagram.com/knovo.official/'}
                isExternal
                ml={'1rem'}
              >
                <Icon as={InstagramIcon} w='1.9rem' h='1.9rem' />
              </ChakraLink>
              <ChakraLink
                href={'https://twitter.com/knovo_official'}
                isExternal
                ml={'1rem'}
              >
                <Icon as={TwitterIcon} w='1.9rem' h='1.9rem' />
              </ChakraLink>
              <ChakraLink
                href={'https://www.youtube.com/c/knovo_official'}
                isExternal
                ml={'1rem'}
              >
                <Icon as={YoutubeIcon} w='1.9rem' h='1.9rem' />
              </ChakraLink>
              <ChakraLink
                href={'https://www.linkedin.com/company/knovo-official/'}
                isExternal
                ml={'1rem'}
              >
                <Icon as={LinkedinIcon} w='1.9rem' h='1.9rem' />
              </ChakraLink>
            </Flex>
          </Center>
        </Flex>
      </Box>
    </Box>
  )
}

export default Footer

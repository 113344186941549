import React, { useCallback, useEffect, useState } from 'react'

import { Box, Text } from '@chakra-ui/react'

import { CardList, LoadingSpinner, Visible } from 'components'
import { useLanguages } from 'hooks'
import { useCentresForEnterprise } from 'queries/enterprise'
import { CentreDisplay } from 'shared/containers'
import { Centre, EnterpriseDetail } from 'types'

interface EnterpriseCentreSectionProps {
  data: EnterpriseDetail
}

const EnterpriseCentreSection: React.FC<EnterpriseCentreSectionProps> = ({
  data,
}) => {
  const { _t } = useLanguages()

  const [list, setList] = useState<Centre[]>([])
  const [total, setTotal] = useState<number>(0)
  const [limit] = useState(20)
  const [page, setPage] = useState(1)

  const { data: centreData, isLoading } = useCentresForEnterprise(
    data.id,
    page,
    limit
  )

  useEffect(() => {
    if (centreData?.data.length) {
      const allResults = centreData?.data || []
      const count = centreData?.meta?.total || 0

      setTotal(count)

      if (page > 1) {
        setList(old => [...old, ...allResults])
      } else {
        setList([...allResults])
      }
    }
  }, [isLoading, centreData, page])

  const handleLoadMore = useCallback(() => {
    const lastPage = Math.ceil(total / limit)

    if (!isLoading && page <= lastPage) {
      setPage(oldPage => oldPage + 1)
    }
  }, [isLoading, limit, page, total])

  return (
    <Box mt='2rem'>
      <Text fontWeight='600' textTransform='uppercase'>
        {_t('enterprise.profile.centres_title')}
      </Text>
      <CardList
        dataList={(list as []) || []}
        emptyMessage={_t('enterprise.profile.centre_empty_title')}
        emptySubMessage={_t('enterprise.profile.centre_empty_description')}
        wrap
      >
        {list.map((item: Centre) => (
          <CentreDisplay key={item.id} data={item} />
        ))}
      </CardList>
      {isLoading && total > limit && <LoadingSpinner />}
      <Visible onVisible={handleLoadMore} />
    </Box>
  )
}

export default EnterpriseCentreSection

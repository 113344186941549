import React from 'react'

import { Flex } from '@chakra-ui/react'

import { Cell } from '../../components'
import { getGuessStatuses } from 'utils/game/status'

interface CompletedRowProps {
  guess: string
  isRevealing?: boolean
}

const CompletedRow: React.FC<CompletedRowProps> = ({ guess, isRevealing }) => {
  const statuses = getGuessStatuses(guess)

  return (
    <Flex justifyContent='center' mb='0.5rem'>
      {guess.split('').map((letter, i) => (
        <Cell key={i} value={letter} status={statuses[i]} />
      ))}
    </Flex>
  )
}

export default CompletedRow

import React, { useCallback, useMemo } from 'react'

import { Badge, Box, Flex, Text } from '@chakra-ui/react'
import format from 'date-fns/format'

import {
  PaymentTypeText,
  TransactionStatusBadge,
  TransactionTypeText,
} from 'components'
import { useLanguages } from 'hooks'
import { CashFlowInfo, Centre, Enterprise, UserProfile } from 'types'
import { formatAmount, getFullName } from 'utils/parser'

interface SenderTransactionProps {
  data: CashFlowInfo
}

const SenderTransaction: React.FC<SenderTransactionProps> = ({ data }) => {
  const { _t } = useLanguages()
  const transactionData = useMemo(() => data?.transaction, [data])
  const senderData = useMemo(() => data?.sender, [data])
  const centerSenderData = useMemo(() => data?.centerSender, [data])
  const enterpriseSenderData = useMemo(() => data?.enterpriseSender, [data])

  const renderName = useCallback(
    (user: UserProfile, center: Centre, enterprise: Enterprise) => {
      if (user) {
        return <Text noOfLines={1}>{getFullName(user)}</Text>
      }
      return (
        <Flex align='center'>
          {center && (
            <Text mr='0.5rem' noOfLines={1}>
              {center?.name || ''}
            </Text>
          )}
          <Badge variant='solid' colorScheme='blue' noOfLines={1}>
            {enterprise?.name || ''}
          </Badge>
        </Flex>
      )
    },
    []
  )

  return (
    <>
      <Box as='h2' mb='1rem'>
        {_t('cash_flow.management.sender_title')}
      </Box>
      <Box mb='1rem'>
        <Text textStyle='detailTitle' color='gray.300' mb='0.25rem'>
          {_t('cash_flow.detail.sender_id')}
        </Text>
        <Text>
          {senderData ? (
            senderData?.id
          ) : (
            <Flex align='center'>
              {centerSenderData && (
                <Text mr='0.5rem' noOfLines={1}>
                  {centerSenderData?.id || ''}
                </Text>
              )}
              <Badge variant='solid' colorScheme='blue' noOfLines={1}>
                {enterpriseSenderData?.id || ''}
              </Badge>
            </Flex>
          )}
        </Text>
      </Box>
      <Box mb='1rem'>
        <Text textStyle='detailTitle' color='gray.300' mb='0.25rem'>
          {_t('cash_flow.detail.sender_name')}
        </Text>
        <Text>
          {renderName(senderData, centerSenderData, enterpriseSenderData)}
        </Text>
      </Box>
      <Box mb='1rem'>
        <Text textStyle='detailTitle' color='gray.300' mb='0.25rem'>
          {_t('cash_flow.detail.sender_email')}
        </Text>
        <Text>
          {senderData ? (
            senderData?.email || ''
          ) : (
            <Flex align='center'>
              {centerSenderData && (
                <Text mr='0.5rem' noOfLines={1}>
                  {centerSenderData?.email || ''}
                </Text>
              )}
              <Badge variant='solid' colorScheme='blue' noOfLines={1}>
                {enterpriseSenderData?.email || ''}
              </Badge>
            </Flex>
          )}
        </Text>
      </Box>
      <Box mb='1rem'>
        <Text textStyle='detailTitle' color='gray.300' mb='0.25rem'>
          {_t('transaction.detail.id')}
        </Text>
        <Text>{transactionData?.id}</Text>
      </Box>
      <Box mb='1rem'>
        <Text textStyle='detailTitle' color='gray.300' mb='0.25rem'>
          {_t('transaction.detail.date_time')}
        </Text>
        <Text>
          {transactionData?.createdAt
            ? format(
                new Date(transactionData?.createdAt),
                'dd MMM yyyy, h:mm a'
              )
            : '-'}
        </Text>
      </Box>
      <Box mb='1rem'>
        <Text textStyle='detailTitle' color='gray.300' mb='0.25rem'>
          {_t('transaction.detail.description')}
        </Text>
        <Text>{transactionData?.description || '-'}</Text>
      </Box>
      <Box mb='1rem'>
        <Text textStyle='detailTitle' color='gray.300' mb='0.25rem'>
          {_t('transaction.detail.amount')}
        </Text>
        <Text
          fontWeight={600}
          color={transactionData?.method === 'credit' ? 'actionGreen' : 'error'}
        >
          {`${transactionData?.method === 'credit' ? '+' : '-'}$${formatAmount(
            (transactionData?.amount || 0) / 100
          )}`}
        </Text>
      </Box>
      <Box mb='1rem'>
        <Text textStyle='detailTitle' color='gray.300' mb='0.25rem'>
          {_t('transaction.detail.status')}
        </Text>
        <TransactionStatusBadge status={transactionData?.status} />
      </Box>
      <Box mb='1rem'>
        <Text textStyle='detailTitle' color='gray.300' mb='0.25rem'>
          {_t('transaction.detail.type')}
        </Text>
        <TransactionTypeText type={transactionData?.type} />
      </Box>
      <Box mb='1rem'>
        <Text textStyle='detailTitle' color='gray.300' mb='0.25rem'>
          {_t('transaction.detail.payment_type')}
        </Text>
        <PaymentTypeText type={transactionData?.paymentType} />
      </Box>
      <Box mb='1rem'>
        <Text textStyle='detailTitle' color='gray.300' mb='0.25rem'>
          {_t('transaction.detail.payment_intent_id')}
        </Text>
        <Text>{transactionData?.paymentIntentId || '-'}</Text>
      </Box>
      <Box mb='1rem'>
        <Text textStyle='detailTitle' color='gray.300' mb='0.25rem'>
          {_t('transaction.detail.refund_session_id')}
        </Text>
        <Text>{transactionData?.refundSessionId || '-'}</Text>
      </Box>
      <Box mb='1rem'>
        <Text textStyle='detailTitle' color='gray.300' mb='0.25rem'>
          {_t('transaction.detail.last_update')}
        </Text>
        <Text>
          {transactionData?.updatedAt
            ? format(
                new Date(transactionData?.updatedAt),
                'dd MMM yyyy, h:mm a'
              )
            : '-'}
        </Text>
      </Box>
      <Box mb='1rem'>
        <Text textStyle='detailTitle' color='gray.300' mb='0.25rem'>
          {_t('transaction.detail.remarks')}
        </Text>
        <Text>{transactionData?.remarks || '-'}</Text>
      </Box>
    </>
  )
}

export default SenderTransaction

import React, { useImperativeHandle } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'

import { Box, BoxProps, Flex } from '@chakra-ui/react'

import { useAvatar } from 'hooks'

import { ReactComponent as CameraIcon } from 'assets/images/camera.svg'

interface AvatarProps extends BoxProps {
  src: string | undefined
  alt: string
  editable?: boolean
}

export interface AvatarRef {
  getUpdatedAvatar?: () => EditableAvatarInfo
}

export interface EditableAvatarInfo {
  avatar: File | undefined
  fileType: string | undefined
}

const Avatar: React.ForwardRefRenderFunction<AvatarRef, AvatarProps> = (
  { src, alt, w, h, editable, ...props },
  ref
) => {
  const { avatar, preview, fileType, handleUpdateAvatar } = useAvatar(src)

  useImperativeHandle(
    ref,
    () => ({
      getUpdatedAvatar: () => ({
        avatar,
        fileType,
      }),
    }),
    [avatar, fileType]
  )

  return (
    <Box position='relative'>
      {preview && (
        <Box
          as={LazyLoadImage}
          src={preview}
          alt={alt || "user's avatar"}
          w={w}
          h={h}
          borderRadius='50%'
          objectFit='cover'
          {...props}
        />
      )}
      {editable && (
        <Flex
          position='absolute'
          top={0}
          justifyContent='center'
          alignItems='center'
          w={w}
          h={h}
          borderRadius='50%'
          className='overlay-linear-gradient'
        >
          <Box as='label' htmlFor='avatar'>
            <CameraIcon cursor='pointer' />
          </Box>

          <Box
            id='avatar'
            as='input'
            type='file'
            accept='.png, .jpg, .jpeg'
            w='0.1px'
            h='0.1px'
            opacity={0}
            zIndex={-1}
            onChange={handleUpdateAvatar}
          />
        </Flex>
      )}
    </Box>
  )
}

export default React.forwardRef(Avatar)

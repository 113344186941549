import { atomWithStorage } from 'jotai/utils'

import storageKey from 'configs/storageKey'
import { Game, GameStats, GameUser } from 'types'

export const gameAtom = atomWithStorage<Game | null>(storageKey.game, null)

export const gameStatsAtom = atomWithStorage<GameStats | null>(
  storageKey.gameStats,
  null
)

export const gameUserAtom = atomWithStorage<GameUser | null>(
  storageKey.gameUser,
  null
)

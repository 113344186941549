import React, { useMemo } from 'react'

import {
  Box,
  Input,
  InputGroup,
  InputLeftElement,
  InputProps,
  InputRightElement,
  Text,
} from '@chakra-ui/react'
import debounce from 'lodash.debounce'

import { useLanguages } from 'hooks'

import { ReactComponent as SearchIcon } from 'assets/images/search_icon.svg'

interface SearchBarProps extends InputProps {
  autoFocus?: boolean
  minChar?: number
  delay?: number
  totalResult?: number
  showResult?: boolean
  onSearch?: (value: string) => void
}

const SearchBar: React.FC<SearchBarProps> = ({
  placeholder = 'common.search',
  color = 'gray.300',
  onSearch,
  minChar = 3,
  delay = 250,
  totalResult = 0,
  showResult = false,
  ...inputProps
}) => {
  const { _t } = useLanguages()
  const handleChangeValue = useMemo(
    () =>
      debounce((e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value
        if (!value || value.length >= 1) {
          onSearch?.(value)
        }
      }, delay),
    [delay, onSearch]
  )

  return (
    <InputGroup>
      <InputLeftElement
        pointerEvents='none'
        width='auto'
        children={
          <Box mb='0.2rem'>
            <SearchIcon fill={color.toString()} />
          </Box>
        }
      />
      <Input
        placeholder={_t(placeholder)}
        fontSize='0.875rem'
        borderRadius='0.5rem'
        pr={showResult ? '5.5rem' : '2.5rem'}
        color={color}
        _placeholder={{
          color,
        }}
        _hover={{
          borderColor: 'primary',
        }}
        onChange={handleChangeValue}
        {...inputProps}
      />
      {showResult && (
        <InputRightElement
          width='auto'
          children={
            <Box>
              <Text
                fontWeight='400'
                fontSize='0.875rem'
                lineHeight='1.313rem'
                color='#828282'
                whiteSpace='nowrap'
              >
                {`${totalResult} Results`}
              </Text>
            </Box>
          }
        />
      )}
    </InputGroup>
  )
}

export default SearchBar

import React from 'react'

import { Box, Flex } from '@chakra-ui/react'

import { AuthIntro } from 'components'

interface LayoutAuthProps {}

const LayoutAuth: React.FC<LayoutAuthProps> = ({ children }) => {
  return (
    <Flex
      direction={['column', 'column', 'column', 'row']}
      textStyle='authText'
    >
      <AuthIntro
        w={{ base: 'auto', lg: '50%' }}
        minH={{ base: '0', lg: '100vh' }}
      />
      <Box
        as='section'
        w={{ base: 'auto', lg: '50%' }}
        minH={{ base: 'auto', lg: '100vh' }}
        pt={{ base: '1rem', lg: '2rem' }}
        px={['1rem', '3rem', '5rem']}
        m='auto'
      >
        {children}
      </Box>
    </Flex>
  )
}

export default LayoutAuth

import React, { useCallback, useMemo } from 'react'

import { Badge, Box, Flex, Text } from '@chakra-ui/react'
import format from 'date-fns/format'

import {
  PaymentTypeText,
  TransactionStatusBadge,
  TransactionTypeText,
} from 'components'
import { useLanguages } from 'hooks'
import { CashFlowInfo, Centre, Enterprise, UserProfile } from 'types'
import { formatAmount, getFullName } from 'utils/parser'

interface RecipientTransactionProps {
  data: CashFlowInfo
}

const RecipientTransaction: React.FC<RecipientTransactionProps> = ({
  data,
}) => {
  const { _t } = useLanguages()
  const transactionData = useMemo(() => data?.recipientTransaction, [data])
  const recipientData = useMemo(() => data?.recipient, [data])
  const centerRecipientData = useMemo(() => data?.centerRecipient, [data])
  const enterpriseRecipientData = useMemo(() => data?.enterpriseRecipient, [
    data,
  ])

  const renderName = useCallback(
    (user: UserProfile, center: Centre, enterprise: Enterprise) => {
      if (user) {
        return <Text noOfLines={1}>{getFullName(user)}</Text>
      }
      return (
        <Flex align='center'>
          {center && (
            <Text mr='0.5rem' noOfLines={1}>
              {center?.name || ''}
            </Text>
          )}
          <Badge variant='solid' colorScheme='blue' noOfLines={1}>
            {enterprise?.name || ''}
          </Badge>
        </Flex>
      )
    },
    []
  )

  return (
    <>
      <Box as='h2' mb='1rem'>
        {_t('cash_flow.management.recipient_title')}
      </Box>
      <Box mb='1rem'>
        <Text textStyle='detailTitle' color='gray.300' mb='0.25rem'>
          {_t('cash_flow.detail.recipient_id')}
        </Text>
        <Text>
          {recipientData ? (
            recipientData?.id
          ) : (
            <Flex align='center'>
              {centerRecipientData && (
                <Text mr='0.5rem' noOfLines={1}>
                  {centerRecipientData?.id || ''}
                </Text>
              )}
              <Badge variant='solid' colorScheme='blue' noOfLines={1}>
                {enterpriseRecipientData?.id || ''}
              </Badge>
            </Flex>
          )}
        </Text>
      </Box>
      <Box mb='1rem'>
        <Text textStyle='detailTitle' color='gray.300' mb='0.25rem'>
          {_t('cash_flow.detail.recipient_name')}
        </Text>
        <Text>
          {renderName(
            recipientData,
            centerRecipientData,
            enterpriseRecipientData
          )}
        </Text>
      </Box>
      <Box mb='1rem'>
        <Text textStyle='detailTitle' color='gray.300' mb='0.25rem'>
          {_t('cash_flow.detail.recipient_email')}
        </Text>
        <Text>
          {recipientData ? (
            recipientData?.email || ''
          ) : (
            <Flex align='center'>
              {centerRecipientData && (
                <Text mr='0.5rem' noOfLines={1}>
                  {centerRecipientData?.email || ''}
                </Text>
              )}
              <Badge variant='solid' colorScheme='blue' noOfLines={1}>
                {enterpriseRecipientData?.email || ''}
              </Badge>
            </Flex>
          )}
        </Text>
      </Box>
      <Box mb='1rem'>
        <Text textStyle='detailTitle' color='gray.300' mb='0.25rem'>
          {_t('transaction.detail.id')}
        </Text>
        <Text>{transactionData?.id}</Text>
      </Box>
      <Box mb='1rem'>
        <Text textStyle='detailTitle' color='gray.300' mb='0.25rem'>
          {_t('transaction.detail.date_time')}
        </Text>
        <Text>
          {transactionData?.createdAt
            ? format(
                new Date(transactionData?.createdAt),
                'dd MMM yyyy, h:mm a'
              )
            : '-'}
        </Text>
      </Box>
      <Box mb='1rem'>
        <Text textStyle='detailTitle' color='gray.300' mb='0.25rem'>
          {_t('transaction.detail.description')}
        </Text>
        <Text>{transactionData?.description || '-'}</Text>
      </Box>
      <Box mb='1rem'>
        <Text textStyle='detailTitle' color='gray.300' mb='0.25rem'>
          {_t('transaction.detail.amount')}
        </Text>
        <Text
          fontWeight={600}
          color={transactionData?.method === 'credit' ? 'actionGreen' : 'error'}
        >
          {`${transactionData?.method === 'credit' ? '+' : '-'}$${formatAmount(
            (transactionData?.amount || 0) / 100
          )}`}
        </Text>
      </Box>
      <Box mb='1rem'>
        <Text textStyle='detailTitle' color='gray.300' mb='0.25rem'>
          {_t('transaction.detail.status')}
        </Text>
        <TransactionStatusBadge status={transactionData?.status} />
      </Box>
      <Box mb='1rem'>
        <Text textStyle='detailTitle' color='gray.300' mb='0.25rem'>
          {_t('transaction.detail.type')}
        </Text>
        <TransactionTypeText type={transactionData?.type} />
      </Box>
      <Box mb='1rem'>
        <Text textStyle='detailTitle' color='gray.300' mb='0.25rem'>
          {_t('transaction.detail.payment_type')}
        </Text>
        <PaymentTypeText type={transactionData?.paymentType} />
      </Box>
      <Box mb='1rem'>
        <Text textStyle='detailTitle' color='gray.300' mb='0.25rem'>
          {_t('transaction.detail.payment_intent_id')}
        </Text>
        <Text>{transactionData?.paymentIntentId || '-'}</Text>
      </Box>
      <Box mb='1rem'>
        <Text textStyle='detailTitle' color='gray.300' mb='0.25rem'>
          {_t('transaction.detail.refund_session_id')}
        </Text>
        <Text>{transactionData?.refundSessionId || '-'}</Text>
      </Box>
      <Box mb='1rem'>
        <Text textStyle='detailTitle' color='gray.300' mb='0.25rem'>
          {_t('transaction.detail.last_update')}
        </Text>
        <Text>
          {transactionData?.updatedAt
            ? format(
                new Date(transactionData?.updatedAt),
                'dd MMM yyyy, h:mm a'
              )
            : '-'}
        </Text>
      </Box>
      <Box mb='1rem'>
        <Text textStyle='detailTitle' color='gray.300' mb='0.25rem'>
          {_t('transaction.detail.remarks')}
        </Text>
        <Text>{transactionData?.remarks || '-'}</Text>
      </Box>
    </>
  )
}

export default RecipientTransaction

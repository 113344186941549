import React, { useCallback, useEffect, useMemo, useState } from 'react'
import TagManager from 'react-gtm-module'
import { useNavigate } from 'react-router-dom'

import { Box, Button, Divider, Flex, Text } from '@chakra-ui/react'
import { useStripe } from '@stripe/react-stripe-js'
import { useAtom } from 'jotai'

import PromoCode from './PromoCode'
import {
  useLanguages,
  useQueryParams,
  useUserId,
  useUtilsForEnterpriseId,
} from 'hooks'
import { usePurchaseOrder, usePurchasePayment } from 'queries/order'
import { useSubscribeSubscription } from 'queries/subscription'
import { useAddUserCard, useTopUp } from 'queries/wallet'
import { cartAtom } from 'store/cartAtom'
import {
  CartItem,
  Method,
  PaymentOption,
  PaymentType,
  UserAddCardType,
  UserPromoDetail,
} from 'types'
import { formatAmount } from 'utils/parser'

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_ID || '',
  dataLayer: {
    event: 'event_buy_class',
    purchase_class: '',
  },
}

const sendTagManagerArgs = (type: string) => {
  tagManagerArgs.dataLayer.purchase_class = type

  TagManager.dataLayer(tagManagerArgs)
}

interface SummaryProps {
  paymentOption?: PaymentOption
  payMethod?: Method
  allowPromo?: boolean
  cardDetail?: any
  userType?: UserAddCardType
}

interface Payment {
  paymentType: PaymentType
  methodId?: string
  callbackUrl?: string
}

const Summary: React.FC<SummaryProps> = ({
  paymentOption = 'topup',
  payMethod,
  allowPromo = false,
  cardDetail,
  userType = 'student',
}) => {
  const userId = useUserId()
  const navigate = useNavigate()
  const query = useQueryParams()
  const stripe = useStripe()
  const { _t } = useLanguages()
  const { enterpriseId } = useUtilsForEnterpriseId()
  const [cart, setCart] = useAtom(cartAtom)
  const [errorMessage, setErrorMessage] = useState<string | undefined>('')
  const [activeCodes, setActiveCodes] = useState<UserPromoDetail[]>([])

  const callbackPath = useMemo<string>(
    () => (query.callback || '/') as string,
    [query]
  )
  const { mutateAsync: addCard } = useAddUserCard({
    type: userType === 'enterprise' ? userType : 'user',
    id: userType === 'enterprise' ? enterpriseId : userId,
  })
  const {
    mutateAsync: purchaseOrder,
    isSuccess: isOrderSuccess,
  } = usePurchaseOrder()
  const {
    mutate: purchasePayment,
    data: purchaseData,
    isSuccess: isPurchaseSuccess,
  } = usePurchasePayment({
    type: userType === 'enterprise' ? userType : 'user',
    id: userType === 'enterprise' ? enterpriseId : userId,
  })
  const {
    mutate: topUpWallet,
    data: topUpData,
    isSuccess: isTopUpSuccess,
  } = useTopUp({
    type: userType === 'enterprise' ? userType : 'user',
    id: userType === 'enterprise' ? enterpriseId : userId,
  })
  const {
    mutate: subscribe,
    isSuccess: isSubscribeSuccess,
  } = useSubscribeSubscription({
    userId,
    ...(userType === 'enterprise' && { enterpriseId }),
  })

  const getTransactionFee = useCallback(
    (amount: number) => {
      if (amount === 0) {
        return 0
      }
      switch (payMethod) {
        case 'newCard':
        case 'savedCard':
          const fixed = 0 // stripe fixed fee: 0.50
          const percent = 0 // stripe percent fee: 3.4
          const totalValue = (amount + fixed) / (1 - percent / 100)
          return Number((totalValue - amount).toFixed(2))
        case 'gcash':
          return 0
        default:
          return 0
      }
    },
    [payMethod]
  )

  const itemPricing = useMemo(
    () =>
      cart.reduce(
        (prev: number, curr: CartItem) =>
          prev + (curr?.price || 0) * (curr?.quantity || 0),
        0
      ),
    [cart]
  )
  const discountAmount = useMemo(() => {
    const totalDiscount = activeCodes.reduce(
      (prev: number, curr: UserPromoDetail) => {
        if (curr.discountUnit === 'flatRate') {
          return prev + curr.discountValue * 100
        }
        return prev + (curr.discountValue * 100 * itemPricing) / 100
      },
      0
    )
    if (totalDiscount > itemPricing) {
      return itemPricing
    }
    return totalDiscount
  }, [activeCodes, itemPricing])
  const transactionFee = useMemo(
    () => getTransactionFee(itemPricing - discountAmount),
    [getTransactionFee, discountAmount, itemPricing]
  )
  const totalPayment = useMemo(
    () => itemPricing - discountAmount + transactionFee,
    [itemPricing, discountAmount, transactionFee]
  )
  const payUrl = useMemo(
    () =>
      purchaseData?.data?.data?.payUrl || topUpData?.data?.data?.payUrl || '',
    [purchaseData, topUpData]
  )

  useEffect(() => {
    if (
      (isOrderSuccess && isPurchaseSuccess) ||
      isTopUpSuccess ||
      isSubscribeSuccess
    ) {
      setCart([])
      if (payMethod === 'gcash') {
        window.location.href = payUrl
      } else {
        navigate(callbackPath, { replace: true })
      }
    }
  }, [
    callbackPath,
    isOrderSuccess,
    isPurchaseSuccess,
    isTopUpSuccess,
    isSubscribeSuccess,
    navigate,
    payMethod,
    payUrl,
    setCart,
  ])

  const handleActiveCodes = (value: UserPromoDetail[]) => {
    setActiveCodes(value)
  }

  const paymentSubscription = async (item: CartItem) => {
    const { userSubscriptionId, subscription } = item
    if (userSubscriptionId && subscription) {
      subscribe({
        orderItems: [subscription?.id],
        ...(userType === 'enterprise' && { enterpriseId }),
        description: 'Subscription',
      })
    }
  }

  const paymentClass = async (item: CartItem, payment: Payment) => {
    const { classInfo, bidInfo } = item
    const { paymentType, methodId, callbackUrl } = payment
    if (classInfo) {
      const orderResponse = await purchaseOrder({
        description: 'Payment',
        ...(!!classInfo?.id && {
          orderItems: [classInfo.id],
        }),
        ...(!!activeCodes.length && {
          promoCodes: activeCodes.map(promo => promo.code),
        }),
      })
      if (orderResponse?.data && orderResponse?.data?.data) {
        const { orderId } = orderResponse.data.data
        purchasePayment({
          orderId,
          paymentType,
          paymentMethodId: methodId,
          callbackUrl,
        })
      }
    } else if (bidInfo) {
      const orderResponse = await purchaseOrder({
        description: 'Payment',
        ...(!!bidInfo?.id && {
          orderItems: [bidInfo?.id],
        }),
        ...(!!activeCodes.length && {
          promoCodes: activeCodes.map(promo => promo.code),
        }),
        isAdhoc: true,
      })
      if (orderResponse?.data && orderResponse?.data?.data) {
        const { orderId } = orderResponse.data.data
        purchasePayment({
          orderId,
          paymentType,
          paymentMethodId: methodId,
          callbackUrl,
        })
      }
    }
  }

  const handlePaymentProcess = async (payment: Payment) => {
    if (paymentOption === 'subscription') {
      for (const item of cart) {
        await paymentSubscription(item)
      }
    } else if (paymentOption === 'class') {
      for (const item of cart) {
        await paymentClass(item, payment)
      }
    } else {
      const { paymentType, methodId, callbackUrl } = payment
      topUpWallet({
        amount: totalPayment,
        paymentType,
        paymentMethodId: methodId,
        callbackUrl,
      })
    }
  }

  const newCardPayment = async () => {
    if (stripe && cardDetail) {
      const setDefaultCard = cardDetail.setDefault
      const payload = await stripe.createPaymentMethod({
        type: cardDetail.type,
        card: cardDetail.card,
        billing_details: cardDetail.billing_details,
        metadata: { userId },
      })
      if (payload.error) {
        setErrorMessage(payload.error.message)
      } else {
        const paymentMethodId = payload.paymentMethod.id
        if (setDefaultCard) {
          await addCard({
            id: paymentMethodId,
            type: userType,
            ...(userType === 'enterprise' && { enterpriseId }),
          })
          handlePaymentProcess({ paymentType: PaymentType.CARD })
        } else {
          handlePaymentProcess({
            paymentType: PaymentType.CARD,
            methodId: paymentMethodId,
          })
        }
      }
    }
  }

  const handlePayment = async () => {
    setErrorMessage(undefined)
    sendTagManagerArgs('purchase_class')
    switch (payMethod) {
      case 'newCard':
        await newCardPayment()
        break
      case 'gcash':
        handlePaymentProcess({
          paymentType: PaymentType.GCASH,
          callbackUrl: window.location.href,
        })
        break
      case 'credit':
        handlePaymentProcess({ paymentType: PaymentType.CREDIT })
        break
      default:
        handlePaymentProcess({ paymentType: PaymentType.CARD })
    }
  }

  return (
    <Box
      bg='white'
      rounded='lg'
      boxShadow='lg'
      p='1.5rem'
      minW='23rem'
      maxW={{ base: 'full', lg: '23rem' }}
    >
      <Text as='h2' pb='1.5rem'>
        {_t('payment.summary.title')}
      </Text>
      <Divider mb='1.5rem' />
      <Flex justify='space-between' mb='0.5rem'>
        <Box>{`${_t('payment.summary.items')} (${cart.length})`}</Box>
        <Box>${formatAmount(itemPricing / 100)}</Box>
      </Flex>
      <Flex justify='space-between'>
        <Box>{_t('payment.summary.transaction_fees')}</Box>
        <Box>${formatAmount(transactionFee / 100)}</Box>
      </Flex>
      {allowPromo && itemPricing !== 0 && (
        <PromoCode
          pricing={itemPricing}
          activeCodes={activeCodes}
          handleActiveCodes={handleActiveCodes}
        />
      )}
      <Divider my='1.5rem' />
      <Flex justify='space-between' align='center' mb='1.5rem'>
        <Box>{_t('payment.summary.amount_total')}</Box>
        <Text as='h1'>${formatAmount(totalPayment / 100)}</Text>
      </Flex>
      <Button
        variant='solidAction'
        w='full'
        onClick={handlePayment}
        disabled={cart.length === 0}
        textTransform='uppercase'
      >
        {_t('payment.summary.button_pay')}
      </Button>
      {errorMessage && (
        <Box mt='1rem'>
          <Text color='error'>{errorMessage}</Text>
        </Box>
      )}
    </Box>
  )
}

export default Summary

import React, { useMemo } from 'react'

import { Box, Button, Flex, Text, useDisclosure } from '@chakra-ui/react'

import TopUpModal from './TopUpModal'
import { Tooltip } from 'components'
import { useLanguages } from 'hooks'
import { UserRole, UserRoleType, UserWalletInfo } from 'types'
import { formatAmount } from 'utils/parser'

import { ReactComponent as MoneyIcon } from 'assets/images/money_icon.svg'
import { ReactComponent as NoteIcon } from 'assets/images/note_icon_white.svg'

interface CreditBalanceProps {
  role: UserRoleType
  walletInfo?: UserWalletInfo
}

const CreditBalance: React.FC<CreditBalanceProps> = ({ role, walletInfo }) => {
  const { _t } = useLanguages()
  const {
    isOpen: isTopUpOpen,
    onClose: onTopUpClose,
    onOpen: onTopUpOpen,
  } = useDisclosure()
  const availableBalance = useMemo(
    () =>
      (walletInfo?.wallet?.availableBalance || 0) +
      (walletInfo?.wallet?.topUpBalance || 0),
    [walletInfo]
  )
  const holdingBalance = useMemo(
    () => walletInfo?.wallet?.holdingBalance || 0,
    [walletInfo]
  )
  const payoutBalance = useMemo(() => walletInfo?.wallet?.payoutBalance || 0, [
    walletInfo,
  ])

  return (
    <>
      <Flex
        position='relative'
        bg='primaryDarker'
        color='white'
        py='1.5rem'
        px='2rem'
        mx={{ base: '0', md: '0.5rem' }}
        my='0.5rem'
        minW={{ base: '100%', md: '20rem' }}
        maxW={{ base: '100%', md: '20rem' }}
        minH='15rem'
        boxShadow='lg'
        rounded='lg'
        overflow='hidden'
        direction='column'
        justifyContent='space-between'
      >
        <Box position='absolute' top='-0.6rem' right='-1.3rem'>
          <MoneyIcon width='120px' height='120px' />
        </Box>
        <Box>
          <Box fontWeight='600'>{_t('wallet.balance.title')}</Box>
          <Box as='h1' pb='1rem'>
            ${formatAmount((walletInfo?.balance || 0) / 100) || '0.00'}
          </Box>
          <Box>
            <Flex justify='space-between'>
              <Flex align='center'>
                <Box mr='0.5rem'>{_t('wallet.balance.available')}</Box>
                <Tooltip label={_t('wallet.balance.tooltip_available')}>
                  <NoteIcon />
                </Tooltip>
              </Flex>
              <Box>
                ${formatAmount((availableBalance || 0) / 100) || '0.00'}
              </Box>
            </Flex>
            <Flex justify='space-between'>
              <Flex align='center'>
                <Box mr='0.5rem'>{_t('wallet.balance.holding')}</Box>
                <Tooltip label={_t('wallet.balance.tooltip_holding')}>
                  <NoteIcon />
                </Tooltip>
              </Flex>
              <Box>${formatAmount((holdingBalance || 0) / 100) || '0.00'}</Box>
            </Flex>
            <Flex justify='space-between'>
              <Flex align='center'>
                <Box mr='0.5rem'>{_t('wallet.balance.payout')}</Box>
                <Tooltip label={_t('wallet.balance.tooltip_payout')}>
                  <NoteIcon />
                </Tooltip>
              </Flex>
              <Box>${formatAmount((payoutBalance || 0) / 100) || '0.00'}</Box>
            </Flex>
          </Box>
        </Box>
        <Flex direction='column'>
          {role === UserRole.Tutor && (
            <Text fontSize='0.75rem' mt='2rem' noOfLines={3}>
              {_t('wallet.balance.description')}
            </Text>
          )}
          {[UserRole.Student, UserRole.EnterpriseStudent].includes(role) && (
            <Button
              variant='solidPrimary'
              mt='0.5rem'
              width='100%'
              textTransform='uppercase'
              onClick={onTopUpOpen}
              border='0'
            >
              {_t('wallet.balance.top_up')}
            </Button>
          )}
        </Flex>
      </Flex>
      <TopUpModal isOpen={isTopUpOpen} onClose={onTopUpClose} />
    </>
  )
}

export default CreditBalance

import React, { useCallback, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Box, Button, Flex, Stack } from '@chakra-ui/react'
import { useAtom } from 'jotai'

import CalendarPopover from './CalendarPopover'
import ChatPopover from './ChatPopover'
import MenuItem from './MenuItem'
import NotificationPopover from './NotificationPopover'
import SearchInput from './SearchInput'
import WalletPopover from './WalletPopover'
import { Link, UserMenu } from 'components'
import Auth from 'configs/auth'
import storageKey from 'configs/storageKey'
import { useChatNavigator } from 'containers/ChatBox/hooks/chatRouterHook'
import {
  useCenterId,
  useLanguages,
  useUserId,
  useUtilsForEnterpriseId,
} from 'hooks'
import { derivedUserProfileAtom, userProfileAtom } from 'store/authAtom'
import { Login, LoginType, UserRole } from 'types'
import {
  isAdmin,
  isCenterAdmin,
  isEnterpriseAdmin,
  isInstructor,
  isLearner,
  isMSTAdmin,
} from 'utils/helper'

import { ReactComponent as CrossIcon } from 'assets/images/cross_icon.svg'
import { ReactComponent as Logo } from 'assets/images/logo.svg'
import { ReactComponent as MenuIcon } from 'assets/images/menu_icon.svg'

interface HeaderProps {
  loginType?: LoginType
  sideBarOpen?: boolean
  toggleSidebar?: () => void
}

const Header: React.FC<HeaderProps> = ({
  loginType = Login.Guest,
  sideBarOpen,
  toggleSidebar,
}) => {
  const navigate = useNavigate()
  const { _t } = useLanguages()
  const userId = useUserId()
  const centerId = useCenterId()
  const { enterpriseId } = useUtilsForEnterpriseId()
  const [userProfile] = useAtom(userProfileAtom)
  const [, setUserProfile] = useAtom(derivedUserProfileAtom)
  const [isOpen, setIsOpen] = useState(false)
  const { navigator } = useChatNavigator()

  const toggle = () => setIsOpen(!isOpen)

  const { resetEnterpriseId } = useUtilsForEnterpriseId()

  const handleSignIn = useCallback(async () => {
    navigate('/sign-in')
  }, [navigate])

  const handleSignUp = useCallback(async () => {
    navigate('/sign-up')
  }, [navigate])

  const handleLogout = useCallback(async () => {
    try {
      const roleTypes = userProfile?.roles?.map(item => item.type)

      if (roleTypes?.includes(UserRole.Tutor)) {
        localStorage.setItem(storageKey.lastRole, 'PrivateTutor')
      } else if (roleTypes?.includes(UserRole.Student)) {
        localStorage.setItem(storageKey.lastRole, 'Student')
      } else {
        resetEnterpriseId()
        localStorage.setItem(storageKey.lastRole, 'Enterprise')
      }
      await Auth.signOut()
      navigator.reset()
      setUserProfile(null)
    } catch (error) {
      console.error('error signing out: ', error)
    }
  }, [navigator, resetEnterpriseId, setUserProfile, userProfile])

  const isInstructorMemo = useMemo(
    () => isInstructor(userProfile) && !isAdmin(userProfile),
    [userProfile]
  )
  const isLearnerMemo = useMemo(
    () => isLearner(userProfile) && !isAdmin(userProfile),
    [userProfile]
  )
  const isEnterpriseAdminMemo = useMemo(() => isEnterpriseAdmin(userProfile), [
    userProfile,
  ])
  const isCentreAdminMemo = useMemo(() => isCenterAdmin(userProfile), [
    userProfile,
  ])
  const isKnovoAdminMemo = useMemo(() => isMSTAdmin(userProfile), [userProfile])

  const hasWallet = useMemo(() => {
    let check = false
    const loggedInRole = localStorage.getItem(storageKey.role)

    if (userProfile) {
      check =
        (userProfile.roles?.some(
          role =>
            role.type === UserRole.EnterpriseStudent ||
            role.type === UserRole.Student ||
            role.type === UserRole.Tutor
        ) &&
          !userProfile.roles?.some(
            role => role.type === UserRole.EnterpriseTutor
          )) ||
        false
    }

    // this is for the case: when sign up & auto login, role does not update immediately
    if (
      (loggedInRole === UserRole.Student || loggedInRole === UserRole.Tutor) &&
      !check
    ) {
      return true
    }
    return check
  }, [userProfile])

  const type = useMemo(() => {
    if (isKnovoAdminMemo) {
      return { user: 'admin', id: userId }
    }
    if (isEnterpriseAdminMemo) {
      return { user: 'enterprise', id: enterpriseId }
    }
    if (isCentreAdminMemo) {
      return { user: 'center', id: centerId }
    }
    return { user: 'user', id: userId }
  }, [
    isKnovoAdminMemo,
    isEnterpriseAdminMemo,
    isCentreAdminMemo,
    userId,
    enterpriseId,
    centerId,
  ])

  const renderMenu = useCallback(() => {
    return (
      <Stack
        spacing={[8, 8, 8, 2]}
        align='center'
        justify={['center', 'center', 'center', 'flex-end']}
        direction={['column', 'column', 'column', 'row']}
        pt={[4, 4, 4, 0]}
        pb={[4, 4, 4, 0]}
      >
        {loginType === Login.Guest && (
          <>
            <Box width='20rem'>
              <SearchInput />
            </Box>
            <Box>
              <MenuItem to='/explore'>{_t('header.explore')}</MenuItem>
            </Box>
          </>
        )}
        {loginType !== Login.Guest && (
          <>
            {(isInstructorMemo || isLearnerMemo) && (
              <Box width='20rem'>
                <SearchInput />
              </Box>
            )}
            {isLearnerMemo && (
              <Box>
                <MenuItem to='/my-courses'>{_t('header.learning')}</MenuItem>
              </Box>
            )}
            {isInstructorMemo && (
              <Box>
                <MenuItem to='/my-courses'>{_t('header.courses')}</MenuItem>
              </Box>
            )}
            {isInstructorMemo && (
              <Box>
                <MenuItem to='/create'>{_t('header.create')}</MenuItem>
              </Box>
            )}
            {(isInstructorMemo || isLearnerMemo) && (
              <Box>
                <MenuItem to='/explore'>{_t('header.explore')}</MenuItem>
              </Box>
            )}
          </>
        )}
      </Stack>
    )
  }, [_t, isInstructorMemo, isLearnerMemo, loginType])

  const renderUserMenu = useCallback(() => {
    if (isCentreAdminMemo) {
      return (
        <UserMenu
          profileUrl='/center-admin/my-profile'
          hasCenterProfile
          userProfile={userProfile}
          onLogout={handleLogout}
        />
      )
    }

    if (isEnterpriseAdminMemo) {
      return (
        <UserMenu
          profileUrl='/enterprise-admin/my-profile'
          hasEnterpriseProfile
          userProfile={userProfile}
          onLogout={handleLogout}
        />
      )
    }

    return (
      <UserMenu
        hasWallet={hasWallet}
        userProfile={userProfile}
        onLogout={handleLogout}
      />
    )
  }, [
    isCentreAdminMemo,
    isEnterpriseAdminMemo,
    hasWallet,
    userProfile,
    handleLogout,
  ])

  return (
    <Flex
      as='nav'
      className='white'
      align='center'
      position='sticky'
      top='0'
      zIndex='header'
      justify='space-between'
      wrap='wrap'
      w='100%'
      px={[2, 2, 4, 8]}
      py={2}
    >
      <Flex w='auto' align='center'>
        <Box
          cursor='pointer'
          w={
            isInstructorMemo || isLearnerMemo || loginType === Login.Guest
              ? { base: 'auto', lg: '0' }
              : '0'
          }
          visibility={
            isInstructorMemo || isLearnerMemo || loginType === Login.Guest
              ? { base: 'visible', lg: 'hidden' }
              : 'hidden'
          }
          onClick={toggle}
        >
          {isOpen ? <CrossIcon id='closeIcon' /> : <MenuIcon id='menuIcon' />}
        </Box>
        <Box
          cursor='pointer'
          w={
            isInstructorMemo || isLearnerMemo || loginType === Login.Guest
              ? '0'
              : 'auto'
          }
          visibility={
            isInstructorMemo || isLearnerMemo || loginType === Login.Guest
              ? 'hidden'
              : 'visible'
          }
          onClick={toggleSidebar}
        >
          <MenuIcon />
        </Box>
        <Box as={Link} to='/' mb='0.125rem' mr='1rem' cursor='pointer'>
          <Logo />
        </Box>
        <Box
          display={{ base: 'none', lg: 'block' }}
          flexBasis={{ base: '100%', lg: 'auto' }}
        >
          {renderMenu()}
        </Box>
      </Flex>

      {loginType === Login.Guest && (
        <Flex alignItems='center'>
          <Button
            variant='transparent'
            py='0.5rem'
            px='0.5rem'
            mr='0.5rem'
            onClick={handleSignIn}
          >
            LOG IN
          </Button>

          <Button
            variant='outlinePrimary'
            py='0.5rem'
            px='0.5rem'
            onClick={handleSignUp}
          >
            SIGN UP
          </Button>
        </Flex>
      )}
      {loginType !== Login.Guest && (
        <Flex align='center'>
          <Box mr={{ base: '0', lg: '0.25rem' }}>
            <WalletPopover userType={type.user} typeId={type.id} />
          </Box>

          {(isInstructorMemo || isLearnerMemo) && (
            <Box
              mr={{ base: '0', lg: '0.25rem' }}
              w={{ base: '0', lg: 'auto' }}
              visibility={{ base: 'hidden', lg: 'visible' }}
            >
              <CalendarPopover />
            </Box>
          )}

          <Box mr={{ base: '0', lg: '0.25rem' }}>
            <ChatPopover />
          </Box>

          <Box mr={{ base: '0', lg: '0.25rem' }}>
            <NotificationPopover />
          </Box>

          <Box mr={'1.25rem'} />
          {renderUserMenu()}
        </Flex>
      )}

      <Box
        display={{ base: isOpen ? 'block' : 'none', lg: 'none' }}
        flexBasis={{ base: '100%', lg: 'auto' }}
      >
        {renderMenu()}
      </Box>
    </Flex>
  )
}

export default Header

import React, { useEffect, useState } from 'react'

import { Flex, Radio, RadioGroup, Stack, Text } from '@chakra-ui/react'
import { useAtom } from 'jotai'

import { filter, filterAtom } from '../atom'
import { useLanguages } from 'hooks'

export default () => {
  const { _t } = useLanguages()
  const [, setFilter] = useAtom(filterAtom)
  const [filterValues] = useAtom(filter)
  const [meetingType, setMeetingType] = useState<string>('all')

  const onChange = (item: string) => {
    setMeetingType(item)
    setFilter({
      ...filterValues,
      meetingType: item || 'all',
    })
  }

  useEffect(() => {
    if (filterValues?.meetingType) {
      setMeetingType(filterValues.meetingType)
    }
  }, [filterValues])

  return (
    <Flex flex='1' direction='column' w='100%'>
      <Text as='h4' fontWeight='400' fontSize='0.75rem' mb='0.125rem'>
        {_t('search.filter.location')}
      </Text>
      <RadioGroup
        colorScheme='blue'
        defaultValue={
          filterValues?.meetingType ? filterValues.meetingType : undefined
        }
        onChange={onChange}
        value={meetingType}
      >
        <Stack spacing={0.5} direction='column'>
          <Radio value='all' checked={meetingType === 'all'}>
            {_t('search.filter.location_all')}
          </Radio>
          <Radio value='virtual' checked={meetingType === 'virtual'}>
            {_t('search.filter.location_virtual')}
          </Radio>
          <Radio value='physical' checked={meetingType === 'physical'}>
            {_t('search.filter.location_in_person')}
          </Radio>
        </Stack>
      </RadioGroup>
    </Flex>
  )
}

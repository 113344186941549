import React, { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import { Box, Flex, Text } from '@chakra-ui/react'

import { Rating } from 'components'
import { ClassColor, ClassDetail } from 'types'
import { isAdhocClass } from 'utils/helper'

type SimpleClassCardProps = {
  data: ClassDetail
  classPath: string
  adhocPath: string
}

const SimpleClassCard: React.FC<SimpleClassCardProps> = ({
  data,
  classPath = '',
  adhocPath = '',
}: SimpleClassCardProps) => {
  const navigate = useNavigate()

  const isAdhocClassMemo = useMemo(() => isAdhocClass(data), [data])
  const isMultiSessionMemo = useMemo(
    () => (data.sessions.length > 1 ? true : false),
    [data]
  )

  const handleClick = () => {
    if (isAdhocClassMemo) {
      navigate(`${adhocPath}/${data.id}`)
    }
    navigate(`${classPath}/${data.id}`)
  }

  return (
    <Flex
      h='11rem'
      w='11rem'
      minW='11rem'
      direction='column'
      mx='0.7rem'
      mt='0.5rem'
      mb='0.7rem'
      boxShadow='lg'
      rounded='lg'
      position='relative'
      bg='white'
    >
      <Flex
        direction='column'
        bg={
          isMultiSessionMemo
            ? ClassColor.PACKAGE_COLOR
            : ClassColor.PRIVATE_COLOR
        }
        color='#fff'
        h='8.5rem'
        p='1rem'
        roundedTop='lg'
      >
        <Box flex='1'>
          <Text as='h2' noOfLines={2}>
            {data?.name || ''}
          </Text>
        </Box>
        <Flex justify='space-between'>
          <Box>
            {data?.user?.rating && <Rating rate={data?.user?.rating} />}
            <Text fontSize='0.75rem'>
              {`${data?.user?.firstName} ${data?.user?.lastName}`
                .replaceAll('undefined', '')
                .trim()}
            </Text>
          </Box>
          {isMultiSessionMemo && (
            <Text fontSize='0.75rem' alignSelf='flex-end'>
              {`${data?.upcomingSession?.index || 0}/${
                data?.sessions?.length || 0
              }`}
            </Text>
          )}
        </Flex>
      </Flex>
      <Flex alignItems='center' onClick={handleClick}>
        <Box
          as='h2'
          color='primary'
          flex='1'
          textAlign='center'
          py='0.625rem'
          cursor='pointer'
          textTransform='uppercase'
          fontSize='0.875rem'
          fontWeight='600'
        >
          view more
        </Box>
      </Flex>
    </Flex>
  )
}

export default SimpleClassCard

import React, { useCallback, useMemo, useState } from 'react'

import {
  Box,
  BoxProps,
  Flex,
  Grid,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from '@chakra-ui/react'

import LearnerTabHeader from './LearnerTabHeader'
import PreviewLearnerItem from './PreviewLearnerItem'
import SearchBar from 'components/SearchBar'
import { ClassPreview, ClassType, Learner, UserRole } from 'types'

import { ReactComponent as CheckedIcon } from 'assets/images/checked_icon.svg'

interface PreviewLearnerInClassProps {
  data?: ClassPreview
}

const BoxContent: React.FC<{ label: string; value?: string }> = ({
  label,
  value,
}) => {
  return (
    <Box fontWeight='600' pr='1rem'>
      <Text fontSize='0.625rem' textTransform='uppercase' color='gray.300'>
        {label}
      </Text>
      <Text noOfLines={1} title={value}>
        {value || '-'}
      </Text>
    </Box>
  )
}

const containerStyle: BoxProps = {
  p: '2rem',
  bgColor: '#fff',
  boxShadow:
    '0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04)',
  borderRadius: '1rem',
}

const PreviewLearnerInClass: React.FC<PreviewLearnerInClassProps> = ({
  data,
}) => {
  const [search, setSearch] = useState<string>('')

  const invitedLearnersAreBooked = useMemo(
    () =>
      data?.invitedStudents?.filter(
        item =>
          data?.confirmedStudents?.findIndex(st => st.id === item.id) !== -1
      ) || [],
    [data]
  )

  const totalLearners = useMemo(() => {
    const filterInvitedLearners =
      data?.invitedStudents?.filter(
        item =>
          data?.confirmedStudents?.findIndex(st => st.id === item.id) === -1
      ) || []
    return [...(data?.confirmedStudents || []), ...filterInvitedLearners]
  }, [data])

  const numOfEnterpriseLearner = useMemo(() => {
    return totalLearners.filter(
      item => item?.accountType?.name === UserRole.EnterpriseStudent
    ).length
  }, [totalLearners])

  const getTypeOfClass = useCallback((classType?: string) => {
    switch (classType) {
      case ClassType.ENTERPRISE_PACKAGE:
      case ClassType.ENTERPRISE_REGULAR:
      case ClassType.ENTERPRISE_CONTENT:
      case ClassType.ENTERPRISE_LESSON:
        return 'Enterprise'

      case ClassType.PRIVATE_PACKAGE:
      case ClassType.PRIVATE_REGULAR:
      case ClassType.PRIVATE_CONTENT:
        return 'Private'

      default:
        return 'Public'
    }
  }, [])

  const filterListWithSearch = useCallback((list: Learner[], key: string) => {
    return list.filter(
      item => item.firstName.includes(key) || item.lastName.includes(key)
    )
  }, [])

  const checkInvitedLearnerIsBooked = useCallback(
    learnerId => {
      return (
        data?.confirmedStudents?.findIndex(item => item.id === learnerId) !== -1
      )
    },
    [data]
  )

  return (
    <Box mt='1rem'>
      <Box
        w='100%'
        px='1.3rem'
        py='1rem'
        bg='secondaryLightBlue'
        fontSize='0.875rem'
        borderRadius='0.5rem'
      >
        This tab is not shown to Learners.
      </Box>

      <Flex
        justifyContent='space-between'
        alignItems='center'
        w='80%'
        my='1.8rem'
      >
        <BoxContent label='OPEN TO' value={getTypeOfClass(data?.type)} />
        <BoxContent
          label='MINIMUM LEARNERS'
          value={`${data?.minStudent || '-'}`}
        />
        <BoxContent
          label='MAXIMUM LEARNERS'
          value={`${data?.maxStudent || '-'}`}
        />
        <Box pl='2rem' borderLeft='1px solid #BDBDBD'>
          <BoxContent
            label='Enterprise LEARNERS'
            value={`${numOfEnterpriseLearner}`}
          />
        </Box>
        <BoxContent
          label='Non-Enterprise LEARNERS'
          value={`${totalLearners.length - numOfEnterpriseLearner}`}
        />
      </Flex>

      <Box w='100%' borderTop='0.8px solid #BDBDBD' pt='1.8rem'>
        <Tabs variant='unstyled'>
          <Flex justifyContent='space-between' alignItems='center'>
            <TabList>
              <LearnerTabHeader>
                ADDED AND BOOKED ({totalLearners.length || 0})
              </LearnerTabHeader>
            </TabList>
            <Box w='35%'>
              <SearchBar
                onSearch={setSearch}
                placeholder="Search Learner's Name"
              />
            </Box>
          </Flex>

          <TabPanels mt='1.6rem'>
            <TabPanel p='0'>
              <Grid gridTemplateColumns='1fr 1.6fr' columnGap='1.5rem'>
                <Box {...containerStyle}>
                  <Text color='primary' fontWeight='600' mb='1rem'>
                    CONFIRMED LEARNERS ({data?.confirmedStudents?.length || 0})
                  </Text>

                  {data?.confirmedStudents?.length ? (
                    <Box h='31rem' overflowY='scroll'>
                      {filterListWithSearch(data.confirmedStudents, search).map(
                        (item, index) => (
                          <Box key={item.id}>
                            <PreviewLearnerItem
                              index={index + 1}
                              learner={item}
                            />
                          </Box>
                        )
                      )}
                    </Box>
                  ) : (
                    <Flex
                      h='31rem'
                      justifyContent='center'
                      alignItems='center'
                      fontSize='0.875rem'
                      color='gray.300'
                    >
                      There are no learners.
                    </Flex>
                  )}
                </Box>
                <Box {...containerStyle} h='100%'>
                  <Text color='primary' fontWeight='600' mb='1rem'>
                    INVITED LEARNERS/OTHERS
                  </Text>
                  <Box w='100%'>
                    <Grid
                      gridTemplateColumns='66% 12% 12%'
                      columnGap='1rem'
                      fontSize='0.875rem'
                      borderBottom='2px solid #E0E0E0'
                      pb='0.5rem'
                    >
                      <Text pl='2rem'>Learner</Text>
                      <Text textAlign='center'>Invited</Text>
                      <Text textAlign='center'>Booked</Text>
                    </Grid>
                    {data?.invitedStudents?.length ? (
                      <Box h='28rem' overflowY='scroll'>
                        {filterListWithSearch(data.invitedStudents, search).map(
                          (item, index) => (
                            <Grid
                              key={item.id}
                              gridTemplateColumns='66% 12% 12%'
                              columnGap='1rem'
                              _notLast={{ borderBottom: '1px solid #E0E0E0' }}
                            >
                              <Box>
                                <PreviewLearnerItem
                                  index={index + 1}
                                  learner={item}
                                />
                              </Box>
                              <Flex justifyContent='center' alignItems='center'>
                                <CheckedIcon />
                              </Flex>
                              <Flex justifyContent='center' alignItems='center'>
                                {checkInvitedLearnerIsBooked(item.id) && (
                                  <CheckedIcon />
                                )}
                              </Flex>
                            </Grid>
                          )
                        )}
                      </Box>
                    ) : (
                      <Flex
                        h='28rem'
                        justifyContent='center'
                        alignItems='center'
                        fontSize='0.875rem'
                        color='gray.300'
                      >
                        There are no learners.
                      </Flex>
                    )}
                    <Grid
                      gridTemplateColumns='66% 12% 12%'
                      columnGap='1rem'
                      fontSize='0.875rem'
                      borderTop='2px solid #E0E0E0'
                      pt='0.5rem'
                    >
                      <Text pl='2rem'>Total</Text>
                      <Text textAlign='center'>
                        {data?.invitedStudents?.length || 0}
                      </Text>
                      <Text textAlign='center'>
                        {invitedLearnersAreBooked.length}
                      </Text>
                    </Grid>
                  </Box>
                </Box>
              </Grid>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </Box>
  )
}

export default PreviewLearnerInClass

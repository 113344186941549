import React, { ChangeEvent, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'

import { Box } from '@chakra-ui/react'

import { DetailEditSection } from '../containers'
import {
  AvatarBannerGroup,
  HeadingSection,
  LoadingSpinner,
  Option,
} from 'components'
import { useLanguages, useUserId } from 'hooks'
import {
  useEnterpriseDetail,
  useEnterpriseDetailSuperAdmin,
  useUpdateEnterprise,
} from 'queries/enterprise'
import { UserRole, UserRoleType } from 'types'
import { responsivePadding } from 'utils/constant'

interface EditEnterpriseProfileContainerProps {
  enterpriseId: string
  backLabel: string
  handleToBack: () => void
  role?: UserRoleType
}

interface EditEnterpriseForm {
  name: string
  email: string
  active: boolean
  contactNumber: string
  address1: string
  country?: Option
  city: string
  zipCode: string
}

const EditEnterpriseProfileContainer: React.FC<EditEnterpriseProfileContainerProps> = ({
  enterpriseId,
  backLabel,
  handleToBack,
  role,
}) => {
  const { _t } = useLanguages()
  const userId = useUserId()
  const [avatar, setAvatar] = useState<string>()
  const [avatarFile, setAvatarFile] = useState<File | undefined>(undefined)
  const [banner, setBanner] = useState<string>()
  const [bannerFile, setBannerFile] = useState<File | undefined>(undefined)

  const isSuperAdmin = useMemo(
    () => role === UserRole.MSTSuperAdmin || role === UserRole.MSTAdmin,
    [role]
  )
  const useData = useMemo(
    () => (isSuperAdmin ? useEnterpriseDetailSuperAdmin : useEnterpriseDetail),
    [isSuperAdmin]
  )

  const {
    handleSubmit,
    control,
    formState: { isDirty },
    getValues,
    setValue,
  } = useForm<EditEnterpriseForm>({
    defaultValues: {
      name: '',
      email: '',
      active: false,
      contactNumber: '',
      address1: '',
      country: { value: '', label: '' },
      city: '',
      zipCode: '',
    },
  })

  const { data: enterpriseData, isLoading } = useData(
    isSuperAdmin ? enterpriseId : userId
  )
  const { mutate: updateEnterprise } = useUpdateEnterprise(enterpriseId)

  useEffect(() => {
    if (enterpriseData?.data) {
      const detailKeys = Object.keys(
        getValues()
      ) as (keyof EditEnterpriseForm)[]
      const loadedData = enterpriseData.data
      detailKeys.forEach(key => {
        if (key === 'country') {
          setValue(`${key}`, {
            value: loadedData[key]?.id,
            label: loadedData[key]?.name,
          })
        } else {
          setValue(`${key}`, loadedData[key])
        }
      })
      setAvatar(loadedData.avatar)
      setBanner(loadedData.banner)
    }
  }, [enterpriseData, getValues, setValue])

  const handleResetFile = () => {
    setAvatarFile(undefined)
    setBannerFile(undefined)
  }

  const handleUpdateEnterprise = async (value: EditEnterpriseForm) => {
    let formattedData = {
      ...value,
      countryId: value.country?.value,
    }
    delete formattedData.country

    updateEnterprise({
      id: enterpriseId,
      data: formattedData,
      avatarFile: avatarFile,
      bannerFile: bannerFile,
      resetFile: handleResetFile,
    })
  }

  const handleUpdateAvatar = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0]
    if (file) {
      setAvatar(URL.createObjectURL(file))
      setAvatarFile(file)
    }
  }

  const handleUpdateBanner = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0]
    if (file) {
      setBanner(URL.createObjectURL(file))
      setBannerFile(file)
    }
  }

  const enableSaveBtn = () => {
    if (
      isDirty ||
      typeof avatarFile !== 'undefined' ||
      typeof bannerFile !== 'undefined'
    ) {
      return false
    }
    return true
  }

  return (
    <>
      <HeadingSection
        title={_t('enterprise.profile.edit_title')}
        backLabel={backLabel}
        handleToBack={handleToBack}
        buttonData={[
          {
            label: _t('common.save'),
            isDisabled: enableSaveBtn(),
            onClick: handleSubmit(handleUpdateEnterprise),
          },
          {
            label: _t('common.cancel'),
            isSecondary: true,
            onClick: handleToBack,
          },
        ]}
        isAdminStyle
      />
      <Box pb='2rem'>
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <>
            <AvatarBannerGroup
              name={enterpriseData?.data.name}
              avatar={avatar}
              banner={banner}
              handleUpdateAvatar={handleUpdateAvatar}
              handleUpdateBanner={handleUpdateBanner}
            />
            <Box px={responsivePadding}>
              <Box mt='1.5rem'>
                <DetailEditSection
                  control={control}
                  isAllowUpdateStatus={isSuperAdmin}
                />
              </Box>
            </Box>
          </>
        )}
      </Box>
    </>
  )
}

export default EditEnterpriseProfileContainer

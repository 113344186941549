import React, { useCallback, useMemo, useState } from 'react'

import { Box, Flex, useDisclosure } from '@chakra-ui/react'

import CashFlowDataTable from './CashFlowDataTable'
import DataTableHeader from './containers/DataTableHeader'
import FilterModal from './containers/FilterModal'
import { FilterForm, searchOptions } from './form'
import { HeadingSection, Option, Paginate } from 'components'
import { useCurrentPage, useLanguages, useQueryParams } from 'hooks'
import {
  useCashFlowFreeze,
  useCashFlows,
  useCashFlowUpdate,
} from 'queries/cashflow'
import { CashFlowInfo, CashFlowStatus, PaymentType } from 'types'

const tabData = [
  {
    id: 'onhold',
    title: 'cash_flow.status.on_hold',
    path: '/admin/cash-flow-management/manage?tab=onhold',
  },
  {
    id: 'unreleased',
    title: 'cash_flow.status.unreleased',
    path: '/admin/cash-flow-management/manage?tab=unreleased',
  },
  {
    id: 'released',
    title: 'cash_flow.status.released',
    path: '/admin/cash-flow-management/manage?tab=released',
  },
]
const limit = 10

interface CashFlowManagementProps {
  handleView: (id: string, tab: string) => string
}

const CashFlowManagement: React.FC<CashFlowManagementProps> = ({
  handleView,
}) => {
  const query = useQueryParams()
  const { _t } = useLanguages()
  const currentPage = useCurrentPage()
  const [search, setSearch] = useState<string>('')
  const [searchBy, setSearchBy] = useState<Option>(searchOptions[0])
  const [orderBy, setOrderBy] = useState<string>('createdAt')
  const [filters, setFilters] = useState<FilterForm>({})
  const [selectedIds, setSelectedIds] = useState<string[]>([])
  const {
    isOpen: isOpenFilter,
    onOpen: onOpenFilter,
    onClose: onCloseFilter,
  } = useDisclosure()

  const currentTab = useMemo<CashFlowStatus>(
    () => (query.tab || 'onhold') as CashFlowStatus,
    [query]
  )

  const handleSearch = useCallback((value: string) => {
    setSearch(value)
  }, [])
  const handleSearchBy = useCallback((value: Option) => {
    setSearchBy(value)
  }, [])
  const handleSort = useCallback((sortBy: string, onCloseSort: () => void) => {
    setOrderBy(sortBy)
    onCloseSort()
  }, [])
  const handleFilter = useCallback((options: FilterForm) => {
    setFilters(options)
  }, [])

  const params = {
    page: currentPage,
    limit,
    order: 'desc',
    orderBy,
    status: currentTab,
    ...(search && { search }),
    ...(search && {
      searchBy: searchBy.value !== 'all' ? searchBy.value : undefined,
    }),
    ...filters,
  }
  const { data: cashFlows, isLoading: isCashFlowLoading } = useCashFlows(params)
  const { mutate: freezeCashFlows } = useCashFlowFreeze(selectedIds, params)
  const { mutate: updateCashFlows } = useCashFlowUpdate(selectedIds, params)

  const getStatusName = useCallback(
    (status: CashFlowStatus) => {
      const names = {
        [CashFlowStatus.INITIATE]: _t('cash_flow.status.initiate'),
        [CashFlowStatus.ONHOLD]: _t('cash_flow.status.on_hold'),
        [CashFlowStatus.UNRELEASED]: _t('cash_flow.status.unreleased'),
        [CashFlowStatus.RELEASED]: _t('cash_flow.status.released'),
        [CashFlowStatus.CANCELLED]: _t('cash_flow.status.cancelled'),
      }
      return names[status]
    },
    [_t]
  )
  const handleSelect = useCallback(
    (values: CashFlowInfo[]) => {
      if (values.length !== selectedIds.length) {
        setSelectedIds(values.map(o => o?.id || '').filter(id => id))
      }
    },
    [selectedIds.length]
  )
  const handleFreeze = useCallback(
    (id: string, freeze = true) => {
      freezeCashFlows({ cashFlowIds: [id], isFreeze: freeze })
    },
    [freezeCashFlows]
  )
  const handleExecute = useCallback(
    (
      id: string,
      status: CashFlowStatus,
      withdrawalType?: PaymentType,
      remarks?: string
    ) => {
      updateCashFlows({ cashFlowIds: [id], status, withdrawalType, remarks })
    },
    [updateCashFlows]
  )
  const handleMultipleExecute = useCallback(() => {
    switch (currentTab) {
      case CashFlowStatus.UNRELEASED:
        return updateCashFlows({
          cashFlowIds: selectedIds,
          status: CashFlowStatus.RELEASED,
        })
      case CashFlowStatus.RELEASED:
        return updateCashFlows({
          cashFlowIds: selectedIds,
          status: CashFlowStatus.UNRELEASED,
        })
      default:
        return
    }
  }, [currentTab, selectedIds, updateCashFlows])

  const buttonData = useMemo(() => {
    const buttons: any[] = []
    if (!selectedIds?.length) {
      return buttons
    }
    if (currentTab === CashFlowStatus.UNRELEASED) {
      buttons.push({
        label: _t('common.execute'),
        showAlert: true,
        alertTitle: _t('cash_flow.management.update_title'),
        alertDescription: _t('cash_flow.management.update_count_description', {
          status: getStatusName(CashFlowStatus.RELEASED),
          count: `${selectedIds.length}`,
        }),
        onClick: handleMultipleExecute,
      })
    } else if (currentTab === CashFlowStatus.RELEASED) {
      buttons.push({
        label: _t('common.reverse'),
        showAlert: true,
        alertTitle: _t('cash_flow.management.update_title'),
        alertDescription: _t('cash_flow.management.reverse_count_description', {
          status: getStatusName(CashFlowStatus.UNRELEASED),
          count: `${selectedIds.length}`,
        }),
        onClick: handleMultipleExecute,
      })
    }

    return buttons
  }, [selectedIds, currentTab, _t, getStatusName, handleMultipleExecute])

  return (
    <Box>
      <HeadingSection
        title={_t('cash_flow.management.title')}
        description={_t('cash_flow.management.description')}
        tabData={tabData}
        currentTab={currentTab}
        buttonData={buttonData}
        isAdminStyle
      />
      <Box p='2rem 5rem 0'>
        <DataTableHeader
          currentTab={currentTab}
          count={cashFlows?.meta.total || 0}
          orderBy={orderBy}
          handleOpenFilter={onOpenFilter}
          handleSearch={handleSearch}
          handleSearchBy={handleSearchBy}
          handleSort={handleSort}
        />
        <Box mt='1.5rem'>
          <CashFlowDataTable
            statusTab={currentTab}
            data={cashFlows?.data || []}
            isLoading={isCashFlowLoading}
            handleView={handleView}
            handleSelect={handleSelect}
            handleFreeze={handleFreeze}
            handleExecute={handleExecute}
          />
        </Box>
        <Flex justifyContent='center' my='1.5rem'>
          {!!cashFlows?.meta.total && !isCashFlowLoading && (
            <Paginate
              currentPage={currentPage}
              perPage={limit}
              totalItems={cashFlows?.meta.total || 0}
            />
          )}
        </Flex>
      </Box>
      <FilterModal
        isOpen={isOpenFilter}
        onClose={onCloseFilter}
        onSubmit={handleFilter}
      />
    </Box>
  )
}

export default CashFlowManagement

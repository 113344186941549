import React from 'react'

import { Box, Flex, Text } from '@chakra-ui/react'
import format from 'date-fns/format'

import { Attachment } from 'types'
import { getBytes } from 'utils/parser'

import { ReactComponent as AttachedIcon } from 'assets/images/attached_icon.svg'
import { ReactComponent as DownloadIcon } from 'assets/images/download_icon.svg'

interface AttachedItemProps {
  data: Attachment
  onDownloadAttachment?: (key: string) => void
}

const AttachedItem: React.FC<AttachedItemProps> = ({
  data,
  onDownloadAttachment,
}) => {
  return (
    <Box
      maxHeight='5rem'
      py='0.325rem'
      px='0.5rem'
      bgColor='#fff'
      borderRadius='0.325rem'
    >
      <Flex alignItems='center'>
        <AttachedIcon />
        <Box ml='0.625rem'>
          <Text fontWeight='600'>{data.name}</Text>
          {data.createdAt && (
            <Text fontSize='0.625rem' color='gray.300'>
              Uploaded: {format(data.createdAt, 'dd MMM yyyy, h:mma')}
            </Text>
          )}
        </Box>
      </Flex>
      <Flex mt='0.5rem' justifyContent='flex-end'>
        <Text mr='0.5rem' fontSize='0.625rem' color='primary'>
          {getBytes(data.size)}
        </Text>
        {!!onDownloadAttachment && (
          <DownloadIcon
            cursor='pointer'
            color='#3959BF'
            onClick={() => onDownloadAttachment(data.key as string)}
          />
        )}
      </Flex>
    </Box>
  )
}

export default AttachedItem

import Axios from 'axios'

import { classSessionPath } from '../configs/apiPath'
import {
  AttachmentFileStatus,
  CreateClassSession,
  SessionUploadUrl,
  UpdateClassSessionType,
} from 'types'
import { getFileInfo } from 'utils/parser'

export const getSessionLink = (classId: string, sessionId: string) => {
  return Axios.get(classSessionPath.getLink(classId, sessionId))
}

export const createClassSession = ({
  data,
  classId,
}: {
  data: CreateClassSession
  classId: string
}) => {
  return Axios.post(classSessionPath.create(classId), data)
}

export const updateClassSession = ({
  data,
  classId,
}: {
  data: UpdateClassSessionType
  classId: string
}) => {
  return Axios.patch(classSessionPath.update(classId), data)
}

export const deleteClassSession = ({
  sessionIds,
  classId,
}: {
  sessionIds: string[]
  classId: string
}) => {
  return Axios.delete(classSessionPath.update(classId), {
    data: {
      sessionIds,
    },
  })
}

export const createUploadAttachmentSession = ({
  classId,
  sessionId,
  status,
  file,
}: {
  classId: string
  sessionId: string
  status: AttachmentFileStatus
  file: File
}) => {
  const { contentType } = getFileInfo(file)
  return Axios.post<SessionUploadUrl>(
    classSessionPath.createUploadAttachment(classId, sessionId),
    {
      status: status,
      fileName: file.name,
      contentType: contentType,
    }
  )
}

export const downloadAllSessionAttachments = (
  classId: string,
  sessionId: string
) => {
  return Axios.get(classSessionPath.downloadAllAttachments(classId, sessionId))
}

export const downloadSessionAttachment = (
  classId: string,
  sessionId: string,
  key: string
) => {
  return Axios.get(classSessionPath.downloadAttachment(classId, sessionId, key))
}

import React from 'react'

import { Box, BoxProps } from '@chakra-ui/react'

import Link from 'components/Link'
import { useLanguages } from 'hooks'

type TabData = {
  id: string
  title: string
  defaultTitle?: string
  path: string
}

interface TabProps {
  tabData: TabData[]
  currentTab: string | undefined
  tabProps?: BoxProps
}

const Tab: React.FC<TabProps> = ({ tabData, currentTab, tabProps }) => {
  const { _t } = useLanguages()

  return (
    <Box as='ul' mt='0.75rem'>
      {tabData.map(data => {
        const isSelected = data.id === currentTab

        return (
          <Box
            as={Link}
            to={data.path}
            textStyle='headingTab'
            key={data.id}
            display='inline-block'
            mr='2.25rem'
            pb='0.5rem'
            borderBottom='2px solid'
            borderColor={isSelected ? 'primary' : 'transparent'}
            cursor='pointer'
            {...tabProps}
          >
            {_t(data.title) === data.title
              ? data.defaultTitle || data.title
              : _t(data.title)}
          </Box>
        )
      })}
    </Box>
  )
}

export default Tab

import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'

import { Layout } from 'components/EnterpriseAdmin'
import { enterpriseAdminRoutes } from 'configs/enterpriseAdminRoutes'
import { useProfile } from 'hooks'
import { SidebarRoute } from 'types'

const getRoutes = (
  routes: SidebarRoute[],
  replacePath = '/enterprise-admin'
): React.ReactNode => {
  return routes.map(route => {
    if (!route.children) {
      return (
        <Route
          key={route.link}
          path={route.link.replace(replacePath, '')}
          element={route.element || <Layout>Hello from {route.name}</Layout>}
        />
      )
    }

    return (
      <Route key={route.link} path={route.link.replace(replacePath, '')}>
        <Route
          path='/'
          element={<Navigate replace to={route.children[0].link} />}
        />
        {getRoutes(route.children, route.link)}
      </Route>
    )
  })
}

const EnterpriseAdminRoutes = () => {
  useProfile()

  return (
    <Routes>
      <Route path='/enterprise-admin'>
        <Route
          path='/'
          element={
            <Navigate replace to='/enterprise-admin/user-management/learners' />
          }
        />
        {getRoutes(enterpriseAdminRoutes)}
      </Route>
      <Route path='/*' element={<Navigate replace to='/enterprise-admin' />} />
    </Routes>
  )
}

export default EnterpriseAdminRoutes

import React, { useCallback, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { Box, Button, Flex, Text } from '@chakra-ui/react'

import { FilterForm, freezeOptions, typeOptions } from '../../form'
import { Checkbox, Modal, ModalProps, RangeSlider } from 'components'
import { useLanguages } from 'hooks'

interface FilterModalProps extends Omit<ModalProps, 'children'> {
  onSubmit: (options: FilterForm) => void
}

const FilterModal = ({ isOpen, onClose, onSubmit }: FilterModalProps) => {
  const { _t } = useLanguages()
  const navigate = useNavigate()
  const location = useLocation()
  const [cashFlowType, setCashFlowType] = useState<string>('all')
  const [freeze, setFreeze] = useState<string>('all')
  const [amounts, setAmounts] = useState<number[]>([0, 580])

  const handleChangeCashFlowType = useCallback(
    (value: string) => setCashFlowType(value),
    []
  )
  const handleChangeFreeze = useCallback(
    (value: string) => setFreeze(value),
    []
  )

  const handleSubmit = useCallback(() => {
    const newFilters = {
      type: cashFlowType !== 'all' ? cashFlowType : undefined,
      freeze: freeze !== 'all' ? freeze : undefined,
      minAmount:
        amounts[0] !== 0 ? Math.round((amounts[0] || 0) * 100) : undefined,
      maxAmount:
        amounts[1] !== 580 ? Math.round((amounts[1] || 0) * 100) : undefined,
    }
    onClose()
    onSubmit(newFilters)
  }, [onSubmit, onClose, cashFlowType, freeze, amounts])

  const handleReset = useCallback(() => {
    setCashFlowType('all')
    setFreeze('all')
    setAmounts([0, 580])
    onClose()
    navigate(location.pathname, { replace: true })
    onSubmit({})
  }, [location.pathname, navigate, onSubmit, onClose])

  const renderFilterOption = useCallback(
    (
      name: string,
      options: { value: string; label: string }[],
      selectedValue: string,
      handler: (value: string) => void
    ) => {
      return (
        <Box fontWeight='600'>
          <Text textTransform='uppercase' color='primary'>
            {_t(name)}
          </Text>
          <Box>
            <Flex
              justifyContent='space-between'
              py='1rem'
              borderBottom='1px solid #E0E0E0'
            >
              <Text>{_t('common.all')}</Text>
              <Checkbox
                isChecked={selectedValue === 'all'}
                onChange={() => handler('all')}
              />
            </Flex>
            {options.map(option => {
              return (
                <Flex
                  key={option.value}
                  justifyContent='space-between'
                  alignItems='center'
                  py='1rem'
                  _notLast={{ borderBottom: '1px solid #E0E0E0' }}
                >
                  <Text>{_t(option.label)}</Text>
                  <Checkbox
                    isChecked={
                      selectedValue !== 'all' && selectedValue === option.value
                    }
                    onChange={() => handler(option.value)}
                  />
                </Flex>
              )
            })}
          </Box>
        </Box>
      )
    },
    [_t]
  )

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size='lg'
      modalBodyProps={{ pt: 0 }}
    >
      <Flex
        fontSize='0.875rem'
        justifyContent='space-between'
        backgroundColor='#fff'
        alignItems='center'
        pb='2rem'
        zIndex={1}
        position='sticky'
        top='0'
      >
        <Box as='h1'>{_t('common.filters')}</Box>
        <Flex alignItems='center'>
          <Button
            variant='solidPrimary'
            mr='0.5rem'
            py='1rem'
            px='1.5rem'
            textTransform='uppercase'
            onClick={handleSubmit}
          >
            {_t('common.apply')}
          </Button>
          <Button
            variant='outlinePrimary'
            p='1rem'
            textTransform='uppercase'
            onClick={handleReset}
          >
            {_t('common.clear_all')}
          </Button>
        </Flex>
      </Flex>

      <Box mb='2rem'>
        {renderFilterOption(
          'cash_flow.management.filter_cash_flow_type',
          typeOptions,
          cashFlowType,
          handleChangeCashFlowType
        )}
      </Box>
      <Box fontWeight='600' mb='2rem'>
        <Text textTransform='uppercase' color='primary'>
          {_t('cash_flow.management.filter_amount')}
        </Text>
        <Box>
          <RangeSlider
            min={0}
            max={580}
            isMoney
            values={amounts}
            onChange={setAmounts}
          />
        </Box>
      </Box>
      <Box mb='2rem'>
        {renderFilterOption(
          'cash_flow.management.filter_freeze',
          freezeOptions,
          freeze,
          handleChangeFreeze
        )}
      </Box>
    </Modal>
  )
}

export default FilterModal

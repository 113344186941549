import { useQuery } from 'react-query'

import { calendarPath } from 'configs/apiPath'
import { CalendarClass, ListingParams } from 'types'

export interface CalendarDay {
  date: string
  data: CalendarClass[]
}

export interface CalendarResponse {
  data: CalendarDay[]
}

export const useCalendar = ({
  roleName,
  fromDate,
  toDate,
  tutorId,
}: ListingParams) => {
  return useQuery<CalendarResponse>(
    calendarPath.get({ roleName, fromDate, toDate, tutorId }),
    {
      enabled: !!fromDate && !!toDate,
    }
  )
}

import { useCallback, useEffect, useState } from 'react'

import { AxiosResponse } from 'axios'

import { getUploadAttachmentUrl } from 'apis/assignment'
import { uploadFile } from 'apis/upload'
import { assignment } from 'configs/apiPath'
import { useInvalidateUrl } from 'hooks'
import {
  Attachment,
  AttachmentFileStatus,
  UploadFileStatus,
  UploadFileUrl,
} from 'types'

interface UploadFile {
  file: Attachment
  status: AttachmentFileStatus
  uploadStatus: UploadFileStatus
}

const useUploadAssignmentFile = (defaultUploadedFiles: Attachment[] = []) => {
  const [uploadFiles, handleChangeFiles] = useState<UploadFile[]>(() =>
    defaultUploadedFiles.map<UploadFile>(uploadedFile => ({
      file: uploadedFile,
      status: uploadedFile.status || 'public',
      uploadStatus: uploadedFile?.createdAt ? 'uploaded' : 'pending',
    }))
  )

  const [isUploading, setUploading] = useState(false)
  const [filesError, setFilesError] = useState<string[]>([])
  const [isSuccess, setIsSuccess] = useState(false)
  const [isError, setIsError] = useState(false)
  const [isSettled, setIsSettled] = useState(false)

  const invalidate = useInvalidateUrl(assignment.tutorList())

  useEffect(() => {
    if (isSettled) {
      handleChangeFiles(oldFiles => {
        return oldFiles.map<UploadFile>(uploadedFile => ({
          ...uploadedFile,
          uploadStatus: filesError.includes(uploadedFile.file.name)
            ? 'failure'
            : 'uploaded',
        }))
      })
    }
  }, [isSettled, filesError])

  // automatically set not settled
  useEffect(() => {
    if (isSettled) {
      setTimeout(() => setIsSettled(false), 0)
    }
  }, [isSettled])

  const handleUpload = useCallback(
    async assignmentId => {
      setUploading(true)
      setIsSettled(false)
      const promises: Promise<AxiosResponse<UploadFileUrl>>[] = []

      const newUploadFiles = uploadFiles.filter(
        file => file.uploadStatus !== 'uploaded'
      )

      newUploadFiles?.forEach(file =>
        promises.push(
          getUploadAttachmentUrl({
            id: assignmentId,
            status: file.status,
            file: file.file,
          })
        )
      )

      try {
        const uploadUrlRes = await Promise.allSettled(promises)

        const uploadStatus = await Promise.allSettled(
          uploadUrlRes.map((uploadUrl, index) => {
            if (uploadUrl.status === 'fulfilled') {
              return uploadFile(
                newUploadFiles[index].file,
                uploadUrl.value.data
              )
            }

            return Promise.reject({
              fileName: newUploadFiles[index].file.name,
            })
          })
        )

        let errorNames: string[] = []

        uploadStatus.forEach(upload => {
          if (upload.status === 'rejected') {
            errorNames.push(upload.reason?.fileName)
          }
        })

        setFilesError(errorNames)
        setIsSuccess(true)
        setIsError(false)
      } catch {
        setIsSuccess(false)
        setIsError(true)
      } finally {
        invalidate()
        setUploading(false)
        setIsSettled(true)
      }
    },
    [uploadFiles, invalidate]
  )

  return {
    uploadFiles,
    isUploading,
    isError,
    isSuccess,
    isSettled,
    filesError,
    handleUpload,
    handleChangeFiles,
  }
}

export default useUploadAssignmentFile

import React from 'react'
import { Link } from 'react-router-dom'

import { Badge, Box, Flex } from '@chakra-ui/react'
import { compareAsc } from 'date-fns'
import format from 'date-fns/format'

import ItemDetail from 'components/ItemDetail'
import { useLanguages } from 'hooks'
import { ClassHistory, MeetingType } from 'types'
import { parseMeetingType } from 'utils/parser'

interface HistoryItemProps {
  data: ClassHistory
  navigateTo?: string | undefined
}

const HistoryItem = ({
  children,
  data,
  navigateTo,
}: React.PropsWithChildren<HistoryItemProps>) => {
  const { _t } = useLanguages()
  const now = new Date()

  const isCompleted = (date: number) => {
    const duration = 2 * 24 * 60 * 60 * 1000
    const afterClassDateTime = new Date(date || 0).getTime() + duration
    return compareAsc(now, afterClassDateTime) === 1
  }

  return (
    <Box
      bg='white'
      boxShadow='lg'
      rounded='lg'
      p='2rem 2.5rem'
      my='1.5rem'
      position='relative'
    >
      <Box>
        <Flex
          flexDirection={{ base: 'column', lg: 'row' }}
          justifyContent='space-between'
          alignItems='flex-start'
        >
          <Box
            as={navigateTo ? Link : 'text'}
            to={navigateTo}
            fontSize='1.25rem'
            fontWeight='600'
            _hover={{
              color: 'primary',
            }}
            _before={{
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
            }}
            noOfLines={2}
          >
            {data.name || ''}
          </Box>
          {isCompleted(data.session.endDateTime) ? (
            <Badge colorScheme='green'>{_t('history.status.completed')}</Badge>
          ) : (
            <Badge>{_t('history.status.open_refund')}</Badge>
          )}
        </Flex>
        <Flex flexDirection='column' mt='0.5rem'>
          <ItemDetail
            title={_t('history.detail.date')}
            text={`${format(data.session.startDateTime || 0, 'dd MMM yyyy')}`}
          />
          <ItemDetail
            title={_t('history.detail.time')}
            text={`${format(data.session.startDateTime || 0, 'h:mma')}`}
          />
          <ItemDetail
            title={_t('history.detail.mode_of_delivery')}
            text={parseMeetingType(
              data.session.meetingType || MeetingType.VIRTUAL_DEFAULT
            )}
          />
        </Flex>
      </Box>
      <Box justifyContent='flex-end' alignItems='flex-end'>
        {children}
      </Box>
    </Box>
  )
}

export default HistoryItem

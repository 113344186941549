import React from 'react'

import {
  Box,
  Button,
  Flex,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  useMediaQuery,
} from '@chakra-ui/react'

import { CreateClassForm, EditClassForm } from '../form'
import { ButtonPublish, ConfirmAlert, PopoverItem } from 'components'
import { Dot } from 'components/ActionDot'
import { useLanguages } from 'hooks'

type ProductType = 'class' | 'content' | 'lesson'

interface ActionButtonProps {
  isHidePublish?: boolean
  handleSubmit: any
  handleCreate?: (
    creatingStatus: 'public' | 'draft'
  ) => (value: CreateClassForm) => void
  handleEdit?: (value: EditClassForm) => void
  handleDelete?: () => void
  handleNext?: (value: CreateClassForm) => void
  handleNextEdit?: () => void
  handleBack?: () => void
  handleOpenPreview?: () => (value: CreateClassForm) => void
  handleClose: () => void
  type?: ProductType
}

const ActionButton: React.FC<ActionButtonProps> = ({
  isHidePublish = false,
  handleSubmit,
  handleCreate,
  handleEdit,
  handleDelete,
  handleNext,
  handleNextEdit,
  handleBack,
  handleOpenPreview,
  handleClose,
  type = 'class',
}) => {
  const { _t } = useLanguages()
  const [isLargerThan1024] = useMediaQuery('(min-width: 1024px)')

  const renderMainButtons = () => {
    return (
      <>
        {handleCreate && !isHidePublish && (
          <ButtonPublish onSubmit={handleSubmit(handleCreate('public'))} />
        )}
        {handleEdit && (
          <Button
            variant='solidPrimary'
            textTransform='uppercase'
            onClick={handleSubmit(handleEdit)}
          >
            {_t('common.save_changes')}
          </Button>
        )}
        {handleNext && (
          <Button
            variant='solidPrimary'
            textTransform='uppercase'
            onClick={handleSubmit(handleNext)}
          >
            {_t('common.next')}
          </Button>
        )}
      </>
    )
  }

  const renderDesktopView = () => {
    return (
      <Flex justifyContent='flex-end' mt='2.5rem'>
        {renderMainButtons()}
        {handleNextEdit && (
          <ConfirmAlert
            submitTitle={_t('common.leave')}
            title={_t('product.class.leave_title')}
            onSubmit={handleNextEdit}
          >
            {({ showAlert }) => (
              <Button
                variant='outlinePrimary'
                ml='1rem'
                textTransform='uppercase'
                onClick={showAlert}
              >
                {_t('product.class.edit_sessions')}
              </Button>
            )}
          </ConfirmAlert>
        )}
        {handleOpenPreview && (
          <Button
            variant='solidPrimary'
            ml='1rem'
            textTransform='uppercase'
            onClick={handleSubmit(handleOpenPreview())}
          >
            {_t('product.class.preview')}
          </Button>
        )}
        {handleCreate && type !== 'lesson' && (
          <Button
            variant='outlinePrimary'
            ml='1rem'
            textTransform='uppercase'
            onClick={handleSubmit(handleCreate('draft'))}
          >
            {_t('product.class.save_draft')}
          </Button>
        )}
        {handleDelete && (
          <ConfirmAlert
            description={
              <Box textAlign='center'>
                {_t('product.class.remove_description')}
              </Box>
            }
            onSubmit={handleDelete}
            immediatelyCloseOnSubmit
          >
            {({ showAlert }) => (
              <Button
                variant='outlinePrimary'
                ml='1rem'
                textTransform='uppercase'
                onClick={showAlert}
              >
                {_t('common.delete')}
              </Button>
            )}
          </ConfirmAlert>
        )}
        {handleBack && (
          <ConfirmAlert
            onSubmit={handleBack}
            submitTitle={_t('common.leave')}
            title={_t('product.class.leave_title')}
          >
            {({ showAlert }) => (
              <Button
                variant='outlinePrimary'
                ml='1rem'
                textTransform='uppercase'
                onClick={showAlert}
              >
                {_t('common.back')}
              </Button>
            )}
          </ConfirmAlert>
        )}
        <Button
          variant='transparent'
          textTransform='uppercase'
          onClick={handleClose}
        >
          {_t('common.cancel')}
        </Button>
      </Flex>
    )
  }

  const renderMobileView = () => {
    return (
      <Flex justifyContent='flex-end' mt='2.5rem'>
        {renderMainButtons()}
        <Popover>
          <PopoverTrigger>
            <Flex
              flexDirection='column'
              justifyContent='space-evenly'
              px='1rem'
              mx='1rem'
              cursor='pointer'
            >
              <Dot w='0.4rem' h='0.4rem' />
              <Dot w='0.4rem' h='0.4rem' />
              <Dot w='0.4rem' h='0.4rem' />
            </Flex>
          </PopoverTrigger>

          <PopoverContent
            bgColor='#fff'
            boxShadow='lg'
            rounded='lg'
            w='15rem'
            _focus={{
              outline: 0,
            }}
          >
            <PopoverBody p='0'>
              {handleNextEdit && (
                <ConfirmAlert
                  submitTitle={_t('common.leave')}
                  title={_t('product.class.leave_title')}
                  onSubmit={handleNextEdit}
                >
                  {({ showAlert }) => (
                    <PopoverItem
                      color='#000'
                      borderColor='gray.200'
                      onClick={showAlert}
                    >
                      <Box>{_t('product.class.edit_sessions')}</Box>
                    </PopoverItem>
                  )}
                </ConfirmAlert>
              )}
              {handleOpenPreview && (
                <PopoverItem
                  color='#000'
                  borderColor='gray.200'
                  onClick={handleSubmit(handleOpenPreview())}
                >
                  <Box>{_t('product.class.preview')}</Box>
                </PopoverItem>
              )}
              {handleCreate && type !== 'lesson' && (
                <PopoverItem
                  color='#000'
                  borderColor='gray.200'
                  onClick={handleSubmit(handleCreate('draft'))}
                >
                  <Box>{_t('product.class.save_draft')}</Box>
                </PopoverItem>
              )}
              {handleDelete && (
                <ConfirmAlert
                  description={
                    <Box textAlign='center'>
                      {_t('product.class.remove_description')}
                    </Box>
                  }
                  onSubmit={handleDelete}
                  immediatelyCloseOnSubmit
                >
                  {({ showAlert }) => (
                    <PopoverItem
                      color='#000'
                      borderColor='gray.200'
                      onClick={showAlert}
                    >
                      <Box>{_t('common.delete')}</Box>
                    </PopoverItem>
                  )}
                </ConfirmAlert>
              )}
              {handleBack && (
                <ConfirmAlert
                  onSubmit={handleBack}
                  submitTitle={_t('common.leave')}
                  title={_t('product.class.leave_title')}
                >
                  {({ showAlert }) => (
                    <PopoverItem
                      color='#000'
                      borderColor='gray.200'
                      onClick={showAlert}
                    >
                      <Box>{_t('common.back')}</Box>
                    </PopoverItem>
                  )}
                </ConfirmAlert>
              )}
              <PopoverItem
                color='#000'
                borderColor='gray.200'
                onClick={handleClose}
              >
                <Box>{_t('common.cancel')}</Box>
              </PopoverItem>
            </PopoverBody>
          </PopoverContent>
        </Popover>
      </Flex>
    )
  }

  return isLargerThan1024 ? renderDesktopView() : renderMobileView()
}

export default ActionButton

import React, { lazy } from 'react'

import { Flex } from '@chakra-ui/react'

import { Attachment, CredentialDetail } from 'types'

interface ViewCredentialsProps {
  credentials: CredentialDetail[]
  userId?: string
  handleOpenPreviewFile: (file: Attachment) => void
}

const CredentialCard = lazy(() => import('./CredentialCard'))

const ViewCredentials: React.FC<ViewCredentialsProps> = ({
  credentials,
  userId,
  handleOpenPreviewFile,
}) => {
  return (
    <Flex
      w='full'
      pb='1rem'
      flexWrap='nowrap'
      direction='column'
      overflowX='hidden'
      overflowY='auto'
    >
      {credentials.map(item => (
        <CredentialCard
          key={item.id}
          userId={userId}
          data={item}
          handleOpenPreviewFile={handleOpenPreviewFile}
        />
      ))}
    </Flex>
  )
}

export default ViewCredentials

import { atom } from 'jotai'

import { Connection } from 'types/connection'

export const routeAtom = atom<string[]>(['CHAT_LIST_PAGE'])
export const routeParamAtom = atom<any>({})

export const selectedConnectionAtom = atom<Connection[]>([])
export const selectedConnections = atom(get => get(selectedConnectionAtom))

export const conversationNameAtom = atom<string>('')
export const conversationName = atom(get => get(conversationNameAtom))

// ** Group
export const groupAtom = atom<{
  isGroup?: boolean
  name?: string
  isUpdate?: boolean
  conversationId?: string
}>({
  isUpdate: false,
  isGroup: true,
  name: '',
  conversationId: '',
})
export const group = atom(get => get(groupAtom))

export const selectedConversationAtom = atom<{
  isGroup?: boolean
  name?: string
  id?: string
}>({
  id: '',
  isGroup: true,
  name: '',
})
export const selectedConversation = atom(get => get(selectedConversationAtom))

import { useMutation, useQuery } from 'react-query'

import { useInvalidateUrl, useLanguages, useLoading, useToast } from '../hooks'
import { sleep } from './centre'
import { useToastError } from './helpers'
import {
  addClass,
  deleteClass,
  duplicateClass,
  initMeeting,
  learnerCancelClass,
  recurrence,
  tutorCancelClass,
  updateClass,
} from 'apis/class'
import { classPath } from 'configs/apiPath'
import {
  Class,
  ClassDetail,
  ClassHistory,
  DraftClass,
  ExportClassData,
  GetClassesParams,
  Learner,
  ListingParams,
  Meta,
  QueryListResponse,
} from 'types'

export type ListLearnerForTutorQuery = {
  data: Learner[]
} & Meta

export type ListTutorQuery = {
  data: any[]
} & Meta

export type ClassesList = {
  data: Class[]
} & Meta

export const useClasses = (params: GetClassesParams) => {
  return useQuery<ClassesList>(classPath.list(params))
}

export const useClassExport = (params: ExportClassData) => {
  const options = { enabled: false }
  return useQuery<any>(classPath.export(params), options)
}

export const useListLearnerForTutor = ({
  userId,
  page,
  limit,
  search,
}: ListingParams & { userId: string }) => {
  return useQuery<ListLearnerForTutorQuery>(
    classPath.listLearner(userId, { page, limit, search })
  )
}

export const useListTutor = ({
  userId,
  page,
  limit,
  search,
}: ListingParams & { userId: string }) => {
  return useQuery<ListLearnerForTutorQuery>(
    classPath.listTutor(userId, { page, limit, search })
  )
}

export const useAddClass = () => {
  const toast = useToast()
  const { setLoading } = useLoading()
  const { _t } = useLanguages()

  return useMutation(addClass, {
    onMutate: () => setLoading(true),
    onSettled: () => setLoading(false),
    onSuccess: (_, { data, onInvalidateUrl }) => {
      onInvalidateUrl && onInvalidateUrl()
      if (data.status === 'draft') {
        toast({
          title: 'Success!',
          description: _t('message.success.class_draft_add'),
          status: 'success',
        })
        return
      }

      toast({
        title: 'Success!',
        description: _t('message.success.class_publish_add'),
        status: 'success',
      })
    },
    onError: useToastError(),
  })
}

export const useDuplicateClass = () => {
  const toast = useToast()
  const { setLoading } = useLoading()
  const { _t } = useLanguages()

  return useMutation(duplicateClass, {
    onMutate: () => setLoading(true),
    onSettled: () => setLoading(false),
    onSuccess: async (_, { onInvalidateUrl }) => {
      onInvalidateUrl && onInvalidateUrl()
      toast({
        title: 'Success!',
        description: _t('message.success.class_duplicate'),
        status: 'success',
      })
    },
    onError: useToastError(),
  })
}

export const useDeleteClass = ({
  page,
  limit,
  status,
}: {
  page?: number
  limit?: number
  status?: 'public' | 'draft'
}) => {
  const toast = useToast()
  const { setLoading } = useLoading()
  const { _t } = useLanguages()

  return useMutation(deleteClass, {
    onMutate: () => setLoading(true),
    onSettled: () => setLoading(false),
    onSuccess: async (_, { onClose, onInvalidateUrl }) => {
      onClose && onClose()
      onInvalidateUrl && onInvalidateUrl()
      toast({
        title: 'Success!',
        description: _t('message.success.class_remove'),
        status: 'success',
      })
    },
    onError: useToastError(),
  })
}

export const useUpdateClass = () => {
  const toast = useToast()
  const { setLoading } = useLoading()
  const { _t } = useLanguages()

  return useMutation(updateClass, {
    onMutate: () => setLoading(true),
    onSettled: () => setLoading(false),
    onSuccess: async (_, { onInvalidateUrl }) => {
      onInvalidateUrl &&
        setTimeout(() => {
          onInvalidateUrl()
        }, 1000)
      toast({
        title: 'Success!',
        description: _t('message.success.class_update'),
        status: 'success',
      })
    },
    onError: useToastError(),
  })
}

export const useClassDetail = ({ classId }: { classId: string }) => {
  return useQuery<{ data: ClassDetail }>(classPath.detail(classId))
}

export const useLearnerUpcomingClasses = ({
  userId,
  startDateTime,
  days,
}: ListingParams & { userId: string; startDateTime: number; days: number }) => {
  return useQuery<QueryListResponse<ClassDetail>>(
    classPath.listLearnerUpcomingClasses(userId, { startDateTime, days }),
    {
      enabled: !!userId,
    }
  )
}

export const useTutorUpcomingClasses = ({
  userId,
  startDateTime,
  days,
}: ListingParams & { userId: string; startDateTime: number; days: number }) => {
  return useQuery<QueryListResponse<ClassDetail>>(
    classPath.listTutorUpcomingClasses(userId, { startDateTime, days }),
    {
      enabled: !!userId,
    }
  )
}

export const useTutorHistoryClasses = ({
  userId,
  status,
  page,
  limit,
  search,
}: ListingParams & { userId: string }) => {
  return useQuery<QueryListResponse<ClassHistory>>(
    classPath.listTutorHistoryClasses(userId, { status, page, limit, search }),
    {
      enabled: !!userId,
    }
  )
}

export const useLearnerHistoryClasses = ({
  userId,
  status,
  page,
  limit,
  search,
}: ListingParams & { userId: string }) => {
  return useQuery<QueryListResponse<ClassHistory>>(
    classPath.listLearnerHistoryClasses(userId, {
      status,
      page,
      limit,
      search,
    }),
    {
      enabled: !!userId,
    }
  )
}

export const useLearnerCancelClass = (classId: string) => {
  const toast = useToast()
  const invalidateUrl = useInvalidateUrl(classPath.detail(classId))
  const { setLoading } = useLoading()
  const { _t } = useLanguages()

  return useMutation(learnerCancelClass, {
    onMutate: () => setLoading(true),
    onSettled: () => setLoading(false),
    onSuccess: async (_, { onClose }) => {
      await sleep(3000)
      onClose()
      invalidateUrl()
      toast({
        title: 'Success!',
        description: _t('message.success.class_learner_cancel'),
        status: 'success',
      })
    },
    onError: useToastError(),
  })
}

export const useTutorCancelClass = (classId: string) => {
  const toast = useToast()
  const invalidateUrl = useInvalidateUrl(classPath.detail(classId))
  const { setLoading } = useLoading()
  const { _t } = useLanguages()

  return useMutation(tutorCancelClass, {
    onMutate: () => setLoading(true),
    onSettled: () => setLoading(false),
    onSuccess: async (_, { onClose }) => {
      await sleep(3000)
      onClose()
      invalidateUrl()
      toast({
        title: 'Success!',
        description: _t('message.success.class_instructor_cancel'),
        status: 'success',
      })
    },
    onError: useToastError(),
  })
}

export const useDraftClasses = ({
  userId,
  page,
  limit,
  order,
}: ListingParams & { userId: string }) => {
  return useQuery<QueryListResponse<DraftClass>>(
    classPath.listDraftClasses(userId, {
      page,
      limit,
      ...(order && { order }),
    }),
    {
      enabled: !!userId,
    }
  )
}

export const useInitMeeting = () => {
  const invalidateUrl = useInvalidateUrl(classPath.initMeeting())
  const { setLoading } = useLoading()

  return useMutation(initMeeting, {
    onMutate: () => setLoading(true),
    onSettled: () => setLoading(false),
    onSuccess: async () => {
      invalidateUrl()
    },
    onError: useToastError(),
  })
}

export const useMyLesson = ({ userId }: ListingParams & { userId: string }) => {
  return useQuery<QueryListResponse<ClassDetail>>(
    classPath.listMyLesson(userId),
    { enabled: !!userId }
  )
}

export const useMyContent = ({
  userId,
}: ListingParams & { userId: string }) => {
  return useQuery<QueryListResponse<ClassDetail>>(
    classPath.listMyContent(userId),
    { enabled: !!userId }
  )
}

export const useRecurrence = () => {
  const { setLoading } = useLoading()

  return useMutation(recurrence, {
    onMutate: () => setLoading(true),
    onSettled: () => setLoading(false),
    onError: useToastError(),
  })
}

import React from 'react'
import 'react-datepicker/dist/react-datepicker.css'

import Routes from './routes'
import { LoadingScreen } from 'components'
import storageKey from 'configs/storageKey'
import { LanguageProvider } from 'contexts/LanguageContext'
import {
  useAuthListener,
  useAutoLoginAsGuest,
  useFirebase,
  useLoading,
} from 'hooks'
import { Review } from 'shared/containers'
import { getData } from 'utils/helper'

import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'

const locale =
  localStorage.getItem(storageKey.language) ||
  (navigator.languages[0] || navigator.language || 'en').split(/[-_]/)[0]

function App() {
  const loginType = getData(storageKey.loginType)
  useAutoLoginAsGuest()
  useAuthListener()
  useFirebase()
  const { loading } = useLoading()

  return (
    <LanguageProvider locale={locale}>
      {loading && <LoadingScreen />}
      {/*
        need to check loginType to return Route cuz
        we have to wait for login as Guest when loading any pages for the first time without cache,
        avoid the case of calling other api without authorization
      */}
      {loginType ? <Routes /> : <LoadingScreen />}
      <Review />
    </LanguageProvider>
  )
}

export default App

import { useMutation, useQuery } from 'react-query'

import { useToastError } from './helpers'
import { freeze, update } from 'apis/cashflow'
import { cashFlowPath } from 'configs/apiPath'
import { useInvalidateUrl, useLanguages, useLoading, useToast } from 'hooks'
import {
  CashFlowExportRequest,
  CashFlowExportResponse,
  CashFlowInfoRequest,
  CashFlowInfoResponse,
  CashFlowListResponse,
  ListingParams,
} from 'types'

export const useCashFlows = (params: ListingParams) => {
  return useQuery<CashFlowListResponse>(cashFlowPath.list(params))
}

export const useCashFlow = ({ cashFlowId }: CashFlowInfoRequest) => {
  const options = { enabled: !!cashFlowId }
  return useQuery<CashFlowInfoResponse>(cashFlowPath.show(cashFlowId), options)
}

export const useCashFlowExport = (params: CashFlowExportRequest) => {
  const options = { enabled: false }
  return useQuery<CashFlowExportResponse>(cashFlowPath.export(params), options)
}

export const useCashFlowFreeze = (
  ids: string[] = [],
  params?: ListingParams
) => {
  const toast = useToast()
  const invalidateUrl = useInvalidateUrl(cashFlowPath.list(params))
  const invalidateDetailUrl = useInvalidateUrl(
    cashFlowPath.show(ids?.[0] || '')
  )
  const { setLoading } = useLoading()
  const { _t } = useLanguages()

  return useMutation(freeze, {
    onMutate: () => setLoading(true),
    onSettled: () => setLoading(false),
    onSuccess: () => {
      invalidateUrl()
      invalidateDetailUrl()
      toast({
        title: 'Success!',
        description: _t('message.success.cash_flow_freeze'),
        status: 'success',
      })
    },
    onError: useToastError(),
  })
}

export const useCashFlowUpdate = (
  ids: string[] = [],
  params?: ListingParams
) => {
  const toast = useToast()
  const invalidateUrl = useInvalidateUrl(cashFlowPath.list(params))
  const invalidateDetailUrl = useInvalidateUrl(
    cashFlowPath.show(ids?.[0] || '')
  )
  const { setLoading } = useLoading()
  const { _t } = useLanguages()

  return useMutation(update, {
    onMutate: () => setLoading(true),
    onSettled: () => setLoading(false),
    onSuccess: () => {
      invalidateUrl()
      invalidateDetailUrl()
      toast({
        title: 'Success!',
        description: _t('message.success.cash_flow_update'),
        status: 'success',
      })
    },
    onError: useToastError(),
  })
}

import React, { useMemo } from 'react'

import CentreLayout from '../Layout'
import {
  CentreConnection,
  CentreDetail,
  CentreHeader,
  CentreInstructor,
  CentreLesson,
} from './containers'
import { TutorReviews } from 'components'
import { useQueryParams } from 'hooks'
import { Centre } from 'types'

interface CentreProfileContainerProps {
  data: Centre
  tabData: any[]
}

type TabId = 'about' | 'instructors' | 'lessons' | 'reviews' | 'connection'

const CentreProfileContainer: React.FC<CentreProfileContainerProps> = ({
  data,
  tabData,
}) => {
  const query = useQueryParams()

  const currentTab = useMemo<TabId>(() => (query.tab || 'about') as TabId, [
    query,
  ])

  return (
    <CentreLayout
      topElement={
        <CentreHeader data={data} tabData={tabData} currentTab={currentTab} />
      }
      tabData={tabData}
      currentTab={currentTab}
    >
      {currentTab === 'about' && <CentreDetail data={data} />}
      {currentTab === 'instructors' && <CentreInstructor data={data} />}
      {currentTab === 'lessons' && <CentreLesson centreName={data.name} />}
      {currentTab === 'reviews' && (
        <TutorReviews centerId={data.id} type='center' />
      )}
      {currentTab === 'connection' && <CentreConnection data={data} />}
    </CentreLayout>
  )
}

export default CentreProfileContainer

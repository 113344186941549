import React, { useMemo } from 'react'

import { Box, Divider, Flex, Text } from '@chakra-ui/react'

import { formatAmount } from 'utils/parser'

interface OrderItemProps {
  title: string
  subtitle?: string
  extra?: string
  quantity?: number
  price?: number
}

const OrderItem: React.FC<OrderItemProps> = ({
  title,
  subtitle,
  extra,
  quantity = 1,
  price = 0,
}) => {
  const pricing = useMemo(
    () => (price === 0 ? 'FREE' : `$${formatAmount(price / 100)}`),
    [price]
  )

  return (
    <Box>
      <Divider mb='1.5rem' />
      <Flex justify={'space-between'}>
        <Box flex={4}>
          <Text fontWeight='600' noOfLines={1}>
            {title}
          </Text>
          {subtitle && (
            <Text mt='0.5rem' mb='0.5rem' noOfLines={2}>
              {subtitle}
            </Text>
          )}
          {extra && (
            <Text mt='0.5rem' fontSize='0.875rem' color='primary'>
              {extra}
            </Text>
          )}
        </Box>
        <Box flex={1}>Qty: {quantity}</Box>
        <Box flex={1}>{pricing}</Box>
      </Flex>
    </Box>
  )
}

export default OrderItem

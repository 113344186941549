import React, { useCallback, useEffect, useState } from 'react'

import { Box } from '@chakra-ui/react'

import { CardList, LoadingSpinner, Visible } from 'components'
import { useLanguages } from 'hooks'
import { useLearnerSearchInstructor } from 'queries/learner'
import { UserCard } from 'shared/containers'
import { Centre, UserSearchResult } from 'types'

interface CentreInstructorProps {
  data: Centre
}

const CentreInstructor: React.FC<CentreInstructorProps> = ({ data }) => {
  const { _t } = useLanguages()

  const [list, setList] = useState<UserSearchResult[]>([])
  const [total, setTotal] = useState<number>(0)
  const [limit] = useState<number>(20)
  const [page, setPage] = useState<number>(1)

  const { data: instructorData, isLoading } = useLearnerSearchInstructor({
    centerId: data?.id || '',
    page,
    limit,
  })

  useEffect(() => {
    if (instructorData?.data.length) {
      const allResults = instructorData?.data || []
      const count = instructorData?.meta?.total || 0

      setTotal(count)

      if (page > 1) {
        setList(old => [...old, ...allResults])
      } else {
        setList([...allResults])
      }
    }
  }, [isLoading, instructorData, page])

  const handleLoadMore = useCallback(() => {
    const lastPage = Math.ceil(total / limit)

    if (!isLoading && page <= lastPage) {
      setPage(oldPage => oldPage + 1)
    }
  }, [isLoading, limit, page, total])

  return (
    <Box>
      <CardList
        dataList={(list as []) || []}
        emptyMessage={_t('centre.profile.instructor_empty_title')}
        emptySubMessage={_t('centre.profile.instructor_empty_description')}
        wrap
      >
        {list.map((item: UserSearchResult) => (
          <UserCard
            key={item.id}
            data={{
              ...item,
              accountType: {
                id: '',
                name: 'EnterpriseTutor',
              },
            }}
          />
        ))}
      </CardList>
      {isLoading && total > limit && <LoadingSpinner />}
      <Visible onVisible={handleLoadMore} />
    </Box>
  )
}

export default CentreInstructor

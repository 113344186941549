import React from 'react'

import ProfileLayout from '../../containers/ProfileLayout'
import ViewAdminProfileContainer from '../../containers/ViewAdminProfile'
import { LoadingSpinner, ProfileHeaderInfo } from 'components'
import { UserProfile } from 'types'

interface PreviewAdminProfileContainerProps {
  userId?: string
  profileData: UserProfile | undefined | null
  backLabel?: string
  handleCancel?: () => void
  handleEditProfile?: () => void
  showCenter?: boolean
  showEdit?: boolean
}

const PreviewAdminProfileContainer: React.FC<PreviewAdminProfileContainerProps> = ({
  userId,
  profileData,
  backLabel,
  handleCancel,
  handleEditProfile,
  showCenter = false,
  showEdit = false,
}) => {
  if (!profileData) {
    return <LoadingSpinner />
  }

  return (
    <ProfileLayout
      backLabel={backLabel}
      topElement={<ProfileHeaderInfo data={profileData} />}
      handleCancel={handleCancel}
      handleSubmit={handleEditProfile}
      isEdit
      showSubmitButton={showEdit}
      isAdminView
    >
      <ViewAdminProfileContainer
        userId={userId}
        profileData={profileData}
        showCenter={showCenter}
      />
    </ProfileLayout>
  )
}

export default PreviewAdminProfileContainer

import React, { useCallback, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { Row } from 'react-table'

import { Avatar, Box, Divider, Flex, Text } from '@chakra-ui/react'
import format from 'date-fns/format'

import {
  HeadingSection,
  LoadingSpinner,
  PaymentTypeText,
  Table,
  TransactionStatusBadge,
  TransactionTypeText,
} from 'components'
import { useLanguages } from 'hooks'
import { useAdminTransaction } from 'queries/transaction'
import { TransactionHistory } from 'types'
import { formatAmount, getFullName } from 'utils/parser'

interface PreviewTransactionProps {
  backTitle?: string
  handleBack?: () => void
}

const PreviewTransaction: React.FC<PreviewTransactionProps> = ({
  backTitle,
  handleBack,
}) => {
  const { _t } = useLanguages()
  const params = useParams()
  const transactionId = useMemo(() => params.id, [params])

  const { data: transaction, isLoading } = useAdminTransaction({
    transactionId,
  })
  const transactionData = useMemo(() => transaction?.data, [transaction])

  const renderHistory = useCallback(() => {
    const columns = [
      {
        Header: _t('transaction.detail.date_time'),
        id: 'dateTime',
        Cell: ({ row }: { row: Row<TransactionHistory> }) => (
          <Text noOfLines={1}>
            {format(new Date(row.original.createdAt), 'dd MMM yyyy, h:mm a')}
          </Text>
        ),
        width: '200',
      },
      {
        Header: _t('transaction.detail.status'),
        id: 'status',
        Cell: ({ row }: { row: Row<TransactionHistory> }) => (
          <TransactionStatusBadge status={row.original.status} />
        ),
        width: '200',
      },
      {
        Header: _t('transaction.detail.payment_type'),
        id: 'paymentType',
        Cell: ({ row }: { row: Row<TransactionHistory> }) => (
          <PaymentTypeText type={row.original.paymentType} />
        ),
        width: '200',
      },
      {
        Header: _t('transaction.detail.remarks'),
        id: 'remarks',
        Cell: ({ row }: { row: Row<TransactionHistory> }) => (
          <Text noOfLines={1}>{row.original.remarks || '-'}</Text>
        ),
        width: '200',
      },
    ]
    return (
      <Box>
        <Text as='h2' mb='1rem'>
          {_t('transaction.management.history_title')}
        </Text>
        <Table<TransactionHistory>
          columns={columns}
          data={transactionData?.history || []}
          loading={isLoading}
        />
      </Box>
    )
  }, [_t, isLoading, transactionData])

  return (
    <Box>
      <HeadingSection
        title={_t('transaction.management.detail_title')}
        description={_t('transaction.management.detail_description')}
        backLabel={backTitle}
        handleToBack={handleBack}
        isAdminStyle
      />
      <Box p='2rem 5rem 0'>
        {isLoading && <LoadingSpinner />}
        {!isLoading && !transactionData && (
          <Flex justify='center'>{_t('transaction.detail.empty_title')}</Flex>
        )}
        {!isLoading && transactionData && (
          <>
            <Flex
              direction={{ base: 'column', md: 'row' }}
              justify='space-between'
            >
              <Box flex={1}>
                <Box mb='1rem'>
                  <Text textStyle='detailTitle' color='gray.300' mb='0.25rem'>
                    {_t('transaction.detail.id')}
                  </Text>
                  <Text>{transactionData?.id}</Text>
                </Box>
                <Box mb='1rem'>
                  <Text textStyle='detailTitle' color='gray.300' mb='0.25rem'>
                    {_t('transaction.detail.date_time')}
                  </Text>
                  <Text>
                    {transactionData?.createdAt
                      ? format(
                          new Date(transactionData?.createdAt),
                          'dd MMM yyyy, h:mm a'
                        )
                      : '-'}
                  </Text>
                </Box>
                <Box mb='1rem'>
                  <Text textStyle='detailTitle' color='gray.300' mb='0.25rem'>
                    {_t('transaction.detail.description')}
                  </Text>
                  <Text>{transactionData?.description || '-'}</Text>
                </Box>
                <Box mb='1rem'>
                  <Text textStyle='detailTitle' color='gray.300' mb='0.25rem'>
                    {_t('transaction.detail.amount')}
                  </Text>
                  <Text
                    fontWeight={600}
                    color={
                      transactionData?.method === 'credit'
                        ? 'actionGreen'
                        : 'error'
                    }
                  >
                    {`${transactionData?.method === 'credit' ? '+' : '-'}$${
                      transactionData?.amount
                        ? formatAmount(transactionData?.amount / 100)
                        : '0.00'
                    }`}
                  </Text>
                </Box>
                <Box mb='1rem'>
                  <Text textStyle='detailTitle' color='gray.300' mb='0.25rem'>
                    {_t('transaction.detail.status')}
                  </Text>
                  <TransactionStatusBadge status={transactionData?.status} />
                </Box>
              </Box>
              <Box flex={1}>
                <Box mb='1rem'>
                  <Text textStyle='detailTitle' color='gray.300' mb='0.25rem'>
                    {_t('transaction.detail.type')}
                  </Text>
                  <TransactionTypeText type={transactionData?.type} />
                </Box>
                <Box mb='1rem'>
                  <Text textStyle='detailTitle' color='gray.300' mb='0.25rem'>
                    {_t('transaction.detail.payment_type')}
                  </Text>
                  <PaymentTypeText type={transactionData?.paymentType} />
                </Box>
                <Box mb='1rem'>
                  <Text textStyle='detailTitle' color='gray.300' mb='0.25rem'>
                    {_t('transaction.detail.payment_intent_id')}
                  </Text>
                  <Text>{transactionData?.paymentIntentId || '-'}</Text>
                </Box>
                <Box mb='1rem'>
                  <Text textStyle='detailTitle' color='gray.300' mb='0.25rem'>
                    {_t('transaction.detail.refund_session_id')}
                  </Text>
                  <Text>{transactionData?.refundSessionId || '-'}</Text>
                </Box>
                <Box mb='1rem'>
                  <Text textStyle='detailTitle' color='gray.300' mb='0.25rem'>
                    {_t('transaction.detail.last_update')}
                  </Text>
                  <Text>
                    {transactionData?.updatedAt
                      ? format(
                          new Date(transactionData?.updatedAt),
                          'dd MMM yyyy, h:mm a'
                        )
                      : '-'}
                  </Text>
                </Box>
              </Box>
            </Flex>
            <Box mb='1rem'>
              <Text textStyle='detailTitle' color='gray.300' mb='0.25rem'>
                {_t('transaction.detail.remarks')}
              </Text>
              <Text>{transactionData?.remarks || '-'}</Text>
            </Box>
            <Divider my='1rem' />
            <Flex
              direction={{ base: 'column', md: 'row' }}
              justify='space-between'
            >
              <Box flex={1}>
                <Box mb='1rem'>
                  <Text textStyle='detailTitle' color='gray.300' mb='0.25rem'>
                    {_t('transaction.detail.user_id')}
                  </Text>
                  <Text noOfLines={1}>{transactionData.user?.id || '-'}</Text>
                </Box>
                <Box mb='1rem'>
                  <Text textStyle='detailTitle' color='gray.300' mb='0.25rem'>
                    {_t('transaction.detail.centre_id')}
                  </Text>
                  <Text noOfLines={1}>{transactionData.center?.id || '-'}</Text>
                </Box>
                <Box mb='1rem'>
                  <Text textStyle='detailTitle' color='gray.300' mb='0.25rem'>
                    {_t('transaction.detail.enterprise_id')}
                  </Text>
                  <Text noOfLines={1}>
                    {transactionData.enterprise?.id || '-'}
                  </Text>
                </Box>
              </Box>
              <Box flex={1}>
                <Box mb='1rem'>
                  <Text textStyle='detailTitle' color='gray.300' mb='0.25rem'>
                    {_t('transaction.detail.user')}
                  </Text>
                  {transactionData.user ? (
                    <Flex align='center'>
                      <Avatar
                        size='xs'
                        name={getFullName(transactionData.user)}
                        src={transactionData.user.avatar}
                        key={transactionData.user.avatar || `user-default`}
                        mr='0.5rem'
                      />
                      <Text noOfLines={1}>
                        {getFullName(transactionData.user)}
                      </Text>
                    </Flex>
                  ) : (
                    '-'
                  )}
                </Box>
                <Box mb='1rem'>
                  <Text textStyle='detailTitle' color='gray.300' mb='0.25rem'>
                    {_t('transaction.detail.centre')}
                  </Text>
                  {transactionData.center ? (
                    <Flex align='center'>
                      <Avatar
                        size='xs'
                        name={transactionData.center.name}
                        src={transactionData.center.avatar}
                        key={transactionData.center.avatar || `centre-default`}
                        mr='0.5rem'
                      />
                      <Text noOfLines={1}>{transactionData.center.name}</Text>
                    </Flex>
                  ) : (
                    '-'
                  )}
                </Box>
                <Box mb='1rem'>
                  <Text textStyle='detailTitle' color='gray.300' mb='0.25rem'>
                    {_t('transaction.detail.enterprise')}
                  </Text>
                  {transactionData.enterprise ? (
                    <Flex align='center'>
                      <Avatar
                        size='xs'
                        name={transactionData.enterprise.name}
                        src={transactionData.enterprise.avatar}
                        key={
                          transactionData.enterprise.avatar ||
                          `enterprise-default`
                        }
                        mr='0.5rem'
                      />
                      <Text noOfLines={1}>
                        {transactionData.enterprise.name}
                      </Text>
                    </Flex>
                  ) : (
                    '-'
                  )}
                </Box>
              </Box>
            </Flex>
            <Divider my='1rem' />
            {renderHistory()}
          </>
        )}
      </Box>
    </Box>
  )
}

export default PreviewTransaction

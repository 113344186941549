import React, { useMemo } from 'react'
import { useParams } from 'react-router-dom'

import { Box } from '@chakra-ui/react'

import NotificationDetail from './Detail'
import NotificationList from './List'
import { HeadingSection, Layout } from 'components'
import { useLanguages, useQueryParams, useUserId } from 'hooks'
import { NotificationType } from 'types'

export type MyNotificationTabId = NotificationType

const Notification = () => {
  const params = useParams()
  const userId = useUserId()
  const { _t } = useLanguages()

  const query = useQueryParams()

  const myNotificationTabData = [
    {
      id: 'All',
      title: _t('common.all'),
      path: `/notifications?tab=${NotificationType.All}`,
    },
    {
      id: 'General',
      title: _t('common.general'),
      path: `/notifications?tab=${NotificationType.General}`,
    },
    {
      id: 'Request',
      title: _t('common.bid_requests'),
      path: `/notifications?tab=${NotificationType.Request}`,
    },
    {
      id: 'Connection',
      title: _t('common.connections'),
      path: `/notifications?tab=${NotificationType.Connection}`,
    },
    {
      id: 'Class',
      title: _t('common.sessions'),
      path: `/notifications?tab=${NotificationType.Class}`,
    },
  ]

  const currentTab = useMemo<MyNotificationTabId>(
    () => (query.tab || NotificationType.All) as MyNotificationTabId,
    [query]
  )
  const notificationId = useMemo(() => params.id, [params])

  return (
    <Layout
      as='main'
      topElement={
        <HeadingSection
          title={_t('common.notifications')}
          tabData={myNotificationTabData}
          currentTab={currentTab}
          widthType='wide'
        />
      }
      showFooter
    >
      <Box pb='2rem'>
        {notificationId ? (
          <NotificationDetail
            userId={userId}
            type={currentTab}
            id={notificationId}
          />
        ) : (
          currentTab && (
            <Box p='0'>
              <NotificationList userId={userId} type={currentTab} />
            </Box>
          )
        )}
      </Box>
    </Layout>
  )
}

export default Notification

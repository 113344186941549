import React from 'react'
import { FieldError } from 'react-hook-form'

import { Grid, Text } from '@chakra-ui/react'

import { useLanguages } from 'hooks'

interface FieldValidators {
  [rule: string]: {
    message: string
    validate: (value: any) => boolean
  }
}

export const passwordValidators: FieldValidators = {
  minLength: {
    message: 'auth.register.validate_password_minimum',
    validate: value => value.length >= 10,
  },
  upperCase: {
    message: 'auth.register.validate_password_uppercase',
    validate: value => /[A-Z]+/.test(value),
  },
  lowerCase: {
    message: 'auth.register.validate_password_lowercase',
    validate: value => /[a-z]+/.test(value),
  },
  numbers: {
    message: 'auth.register.validate_password_numerical',
    validate: value => /\d+/.test(value),
  },
  symbols: {
    message: 'auth.register.validate_password_special',
    validate: value => /[^a-zA-Z0-9]+/.test(value),
  },
}

export interface PasswordErrorMessagesProps {
  errors: FieldError | undefined
}

export const PasswordErrorMessages: React.FC<PasswordErrorMessagesProps> = ({
  errors,
}) => {
  const { _t } = useLanguages()
  return errors ? (
    <Grid
      gridTemplateColumns='repeat(2, 1fr)'
      mt='0.25rem'
      gridRowGap='0.25rem'
    >
      {Object.keys(passwordValidators).map(type =>
        errors?.types?.[type] ? (
          <Text key={type} textStyle='error'>
            {_t(passwordValidators[type].message)}
          </Text>
        ) : null
      )}
    </Grid>
  ) : null
}

import React from 'react'

import { Flex, Text } from '@chakra-ui/react'
import { useAtom } from 'jotai'

import { eduSysAtom, eduSysEnabled } from '../atom'
import { Select } from 'components'
import { system } from 'configs/apiPath'
import { useLanguages } from 'hooks'
import { formatCombinationOption } from 'utils/parser'

export default () => {
  const { _t } = useLanguages()
  const [enabled] = useAtom(eduSysEnabled)
  const [, setId] = useAtom(eduSysAtom)

  const onChange = (item: any) => {
    if (item) {
      setId(item.value)
    } else {
      setId('')
    }
  }

  return (
    <Flex direction='column' w='100%'>
      <Text
        as='h4'
        fontWeight='400'
        fontSize='0.75rem'
        color={enabled ? '' : 'hsl(0,0%,50%)'}
      >
        {_t('search.filter.education_system')}
      </Text>
      <Select
        isDisabled={!enabled}
        optionUrl={system.list({ page: 1, limit: 20 })}
        formatOption={formatCombinationOption}
        isClearable={true}
        placeholder={_t('search.filter.placeholder_education_system')}
        selectProps={{
          menuHeight: '8rem',
        }}
        defaultOptions={[]}
        defaultValue={[]}
        containerProps={{
          mt: '0',
          width: '100%',
        }}
        onChange={onChange}
      />
    </Flex>
  )
}

import React, { useEffect, useRef, useState } from 'react'

import CreateTeachingSubjectSection from '../../containers/CreateTeachingSubjectSection'
import EditProfileContainer from '../../containers/EditProfile'
import ProfileLayout from '../../containers/ProfileLayout'
import { AvatarRef, ContentContainer } from 'components'
import { useLanguages, useLoading, useUtilsForEnterpriseId } from 'hooks'
import { useAddUser } from 'queries/user'
import { AddUserData, GroupedCombination, UserProfileRef } from 'types'
import { getErrorMessage } from 'utils/parser'
import { uploadUserAvatar } from 'utils/upload'

interface CreateInstructorPropsContainer {
  centerId?: string
  handleToBack: () => void
  isKnovoAdmin?: boolean
}

const CreateInstructorContainer: React.FC<CreateInstructorPropsContainer> = ({
  centerId,
  handleToBack,
  isKnovoAdmin = false,
}) => {
  const { _t } = useLanguages()

  const { setLoading } = useLoading()
  const [combinations, setCombinations] = useState<GroupedCombination[]>([])
  const { enterpriseId } = useUtilsForEnterpriseId()

  const [error, setError] = useState<string>()
  const userRef = useRef<UserProfileRef>()
  const avatarRef = useRef<AvatarRef>()

  const { mutate: createUser, isSuccess, data: resData } = useAddUser()

  const handleUpdateCombination = (newCombinations: GroupedCombination[]) => {
    setCombinations(newCombinations)
  }

  // Upload avatar on user creation
  useEffect(() => {
    if (isSuccess && resData && avatarRef.current) {
      uploadUserAvatar(avatarRef, resData.data.data.id)
      setLoading(false)
      handleToBack()
    }
  }, [handleToBack, isSuccess, resData, setLoading])

  const handleCreateUser = async () => {
    try {
      let formattedData = (await userRef.current?.getData()) as AddUserData
      if (!formattedData) {
        return
      }
      const combinationIds = combinations.reduce<{ opt: string; id: string }[]>(
        (acc, cur) => {
          return [
            ...acc,
            ...cur.teachingLevels.map(item => ({
              opt: 'add',
              id: item.subjectCombinationId as string,
            })),
          ]
        },
        []
      )

      formattedData.subjectCombinations = combinationIds

      createUser(formattedData)
    } catch (err) {
      setError(getErrorMessage(err))
    }
  }

  return (
    <ProfileLayout
      title={_t('user_management.instructor.create_title')}
      backLabel={_t('user_management.instructor.title')}
      handleCancel={handleToBack}
      handleSubmit={handleCreateUser}
      isCreate
      isAdminView
    >
      <EditProfileContainer
        enterpriseId={isKnovoAdmin ? undefined : enterpriseId}
        centerId={isKnovoAdmin ? undefined : centerId}
        profileData={{}}
        avatarRef={avatarRef}
        toastError={error}
        setToastError={setError}
        userRef={userRef}
        subjectSection={
          <ContentContainer mt='1rem'>
            <CreateTeachingSubjectSection
              combinations={combinations}
              handleUpdateCombination={handleUpdateCombination}
            />
          </ContentContainer>
        }
        isInstructor
        isAdmin
        isCreate
      />
    </ProfileLayout>
  )
}

export default CreateInstructorContainer

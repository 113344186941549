import Axios from 'axios'

import { notification } from 'configs/apiPath'

interface TriggerPointDetail {
  data: {
    recipients: string[]
  }
}

export const getTriggerPointDetail = async (triggerPointId: string) => {
  return Axios.get<TriggerPointDetail>(
    notification.getTriggerPointDetail(triggerPointId)
  )
}

export const addNotification = async (data: any) => {
  return Axios.post(notification.create(), data)
}

export const updateNotification = async ({
  notificationId,
  data,
}: {
  notificationId: string
  data: any
}) => {
  return Axios.put(notification.update(notificationId), data)
}

export const deleteNotification = async (notificationId: string) => {
  return Axios.delete(notification.delete(notificationId))
}

export const readNotification = async (notificationId: string) => {
  return Axios.put(notification.read(notificationId))
}

export const readAllNotification = async () => {
  return Axios.put(notification.readAll())
}

import React, { createContext, useCallback } from 'react'

import storageKey from 'configs/storageKey'
import { useMessages } from 'queries/message'
import { Message } from 'types'

interface TranslateParams extends Message {}

interface LanguageContextProps {
  handleChangeLanguage: (lang: string) => void
  getLanguage: () => string
  listMessages: Message
  _t: (key: string, params?: TranslateParams) => string
}

export const LanguageContext = createContext<LanguageContextProps>({
  handleChangeLanguage: () => {},
  getLanguage: () => '',
  listMessages: {},
  _t: () => '',
})

interface LanguageProviderProps {
  locale: string
}

export const LanguageProvider: React.FC<LanguageProviderProps> = ({
  locale = 'en',
  children,
}) => {
  const { data: messages } = useMessages(locale)

  const handleChangeLanguage = useCallback((lang: string) => {
    localStorage.setItem(storageKey.language, lang)
    window.location.reload()
  }, [])

  const getLanguage = useCallback(() => {
    return localStorage.getItem(storageKey.language) || ''
  }, [])

  const _t = useCallback(
    (key: string, params?: TranslateParams) => {
      let message = messages?.[key] || ''
      if (!params) {
        return message || key
      }

      Object.entries(params).forEach(([item, value]) => {
        if (item) {
          message = message.replaceAll(`[${item}]`, value)
        }
      })

      return message || key
    },
    [messages]
  )

  return (
    <LanguageContext.Provider
      value={{
        handleChangeLanguage,
        getLanguage,
        listMessages: messages || {},
        _t,
      }}
    >
      {children}
    </LanguageContext.Provider>
  )
}

import React, { useCallback } from 'react'
import Countdown from 'react-countdown'
import { useNavigate } from 'react-router-dom'

import { Box, Button, Text } from '@chakra-ui/react'

import { Histogram, StatsBar } from '../../components'
import { Modal } from 'components'
import { useLanguages, useToast } from 'hooks'
import { GameStats } from 'types'
import { tomorrow } from 'utils/game/number'

interface StatisticModalProps {
  isOpen: boolean
  handleClose: () => void
  guesses: string[]
  gameStats: GameStats
  isGameLose: boolean
  isGameWon: boolean
}

const StatisticModal: React.FC<StatisticModalProps> = ({
  isOpen,
  handleClose,
  guesses,
  gameStats,
  isGameLose,
  isGameWon,
}) => {
  const { _t } = useLanguages()
  const navigate = useNavigate()
  const toast = useToast()

  const shareStatus = useCallback(() => {
    const winMessage = _t('games.knovole.result_win_message', {
      count: guesses.length.toString(),
    })
    const loseMessage = _t('games.knovole.result_lose_message')

    navigator.clipboard.writeText(
      `${isGameLose ? loseMessage : winMessage}\n\n`
    )

    toast({
      title: 'Copied!',
      description: _t('games.copied_to_clipboard'),
      status: 'success',
    })
  }, [_t, guesses, isGameLose, toast])

  const handleClick = useCallback(() => {
    navigate('/games/wheel')
  }, [navigate])

  if (gameStats.totalGames <= 0) {
    return (
      <Modal
        title={_t('games.statistics')}
        isOpen={isOpen}
        onClose={handleClose}
      >
        <StatsBar gameStats={gameStats} />
      </Modal>
    )
  }
  return (
    <Modal title={_t('games.statistics')} isOpen={isOpen} onClose={handleClose}>
      <StatsBar gameStats={gameStats} />
      <Histogram gameStats={gameStats} />
      {(isGameLose || isGameWon) && (
        <Box mt='2.5rem'>
          <Box>
            <Text as='h5'>{_t('games.knovole.new_number_in')}</Text>
            <Countdown date={tomorrow} daysInHours={true} />
          </Box>
          {isGameWon && (
            <>
              <Text as='h5' fontWeight={'semibold'} textAlign={'center'}>
                {_t('games.knovole.congratulations')}
              </Text>
              <Button
                mt='1rem'
                w='100%'
                px='2rem'
                py='1rem'
                onClick={handleClick}
              >
                {_t('games.knovole.go_to_wheel')}
              </Button>
            </>
          )}
          <Button
            mt='1rem'
            w='100%'
            px='2rem'
            py='1rem'
            onClick={() => {
              shareStatus()
            }}
          >
            {_t('games.share')}
          </Button>
        </Box>
      )}
    </Modal>
  )
}

export default StatisticModal

import React, { useCallback, useMemo } from 'react'
import { useLocation } from 'react-router-dom'

import { Box, Flex } from '@chakra-ui/react'

import { Link } from 'components'
import { useLanguages } from 'hooks'
import { SidebarRoute } from 'types'

interface SidebarProps {
  routes: SidebarRoute[]
  sideBarStatus?: boolean
  activeLinkColor?: string
  parentLinkColor?: string
}

const Sidebar: React.FC<SidebarProps> = ({
  routes,
  sideBarStatus,
  parentLinkColor = 'purple',
  activeLinkColor = 'lightPurple',
}) => {
  const location = useLocation()
  const pathname = useMemo(() => location.pathname, [location])

  const { _t } = useLanguages()

  const renderLink = useCallback(
    ({
      route,
      selected,
      offsetLeft = 0,
    }: {
      route: SidebarRoute
      selected: boolean
      offsetLeft: number
    }) => {
      if (route.hidden) return null

      if (!route.children) {
        return (
          <Link to={route.link}>
            <Box
              as='li'
              key={route.link}
              py={route.children ? '0' : '1rem'}
              pl={`${offsetLeft + 2}rem`}
              fontSize='0.875rem'
              color='#fff'
              _hover={{
                color: selected ? '#fff' : activeLinkColor,
              }}
              backgroundColor={selected ? activeLinkColor : 'transparent'}
            >
              {_t(route.name)}
            </Box>
          </Link>
        )
      }

      const haveChildSelected = pathname.includes(route.link)

      return (
        <Flex direction='column' key={route.link}>
          <Link to={route.link}>
            <Box
              py='1rem'
              pl='2rem'
              fontSize='0.875rem'
              color='#fff'
              _hover={{
                color: haveChildSelected ? '#fff' : activeLinkColor,
              }}
              backgroundColor={
                haveChildSelected ? parentLinkColor : 'transparent'
              }
            >
              {_t(route.name)}
            </Box>
          </Link>
          <Box as='ul'>
            {route.children.map(r => {
              const selectedChild = r.link === pathname

              return renderLink({
                route: r,
                selected: selectedChild,
                offsetLeft: offsetLeft + 2,
              })
            })}
          </Box>
        </Flex>
      )
    },
    [pathname, _t, activeLinkColor, parentLinkColor]
  )

  return (
    <Box
      as='nav'
      position='fixed'
      top='0'
      bottom='0'
      left='0'
      pt='6.5rem'
      pb='2.5rem'
      width={sideBarStatus ? '16.5rem' : '0rem'}
      backgroundColor='sidebarBg'
      overflowY='scroll'
      overflowX='hidden'
      transition='0.5s'
      zIndex='sidebar'
    >
      <Box as='ul'>
        {routes.map(route => {
          const selected = route.link === pathname

          return renderLink({ route, selected, offsetLeft: 0 })
        })}
      </Box>
    </Box>
  )
}

export default Sidebar

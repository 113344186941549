import React, { useMemo } from 'react'

import {
  Box,
  Input,
  InputGroup,
  InputLeftElement,
  InputProps,
  InputRightElement,
} from '@chakra-ui/react'
import debounce from 'lodash.debounce'

import Select, { Option } from 'components/Select'

import { ReactComponent as SearchIcon } from 'assets/images/search_icon.svg'

interface SearchBarWithOptionProps extends InputProps {
  minChar?: number
  searchOptions?: Option[]
  onSearch?: (value: string) => void
  onSearchOption?: (value: Option) => void
}

const SearchBarWithOption: React.FC<SearchBarWithOptionProps> = ({
  placeholder = 'Search',
  color = '#fff',
  onSearch,
  searchOptions = [],
  onSearchOption,
  minChar = 3,
  ...inputProps
}) => {
  const handleChangeValue = useMemo(
    () =>
      debounce((e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value
        if (!value || value.length >= 1) {
          onSearch?.(value)
        }
      }, 250),
    [onSearch]
  )

  return (
    <InputGroup>
      <InputLeftElement
        pointerEvents='none'
        width='auto'
        children={
          <Box mb='0.2rem'>
            <SearchIcon fill={color.toString()} />
          </Box>
        }
      />
      <Input
        placeholder={placeholder}
        fontSize='0.875rem'
        borderRadius='0.5rem'
        color={color}
        pr='8rem'
        _placeholder={{
          color,
        }}
        _hover={{
          borderColor: 'primary',
        }}
        onChange={handleChangeValue}
        {...inputProps}
      />

      <InputRightElement
        h='80%'
        mt='3px'
        ml='3px'
        borderLeft='1px solid #E0E0E0'
        width='auto'
        maxW='8rem'
        zIndex='10'
        children={
          <Select
            variant='transparent'
            defaultValue={searchOptions[0]}
            defaultOptions={searchOptions}
            onChange={onSearchOption}
            containerProps={{ zIndex: 10, minW: '6rem' }}
          />
        }
      />
    </InputGroup>
  )
}

export default SearchBarWithOption

import React from 'react'

import { Box, BoxProps, Spinner, SpinnerProps } from '@chakra-ui/react'

interface LoadingSpinnerProps {
  boxProps?: BoxProps
  spinnerProps?: SpinnerProps
}

const LoadingSpinner: React.FC<LoadingSpinnerProps> = ({
  boxProps,
  spinnerProps,
}) => {
  return (
    <Box py='1.25rem' textAlign='center' {...boxProps}>
      <Spinner size='lg' color='primary' {...spinnerProps} />
    </Box>
  )
}

export default LoadingSpinner

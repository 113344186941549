import React, { useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router'

import { Box, Button, Divider, Flex, Text } from '@chakra-ui/react'
import { useAtom } from 'jotai'

import { LoadingSpinner } from 'components'
import { useLanguages, useUserId, useUtilsForEnterpriseId } from 'hooks'
import { useSubscription } from 'queries/subscription'
import { userProfileAtom } from 'store/authAtom'
import { isEnterprise, isEnterpriseAdmin, isInstructor } from 'utils/helper'
import { getNumber } from 'utils/parser'

interface SubscriptionSectionProps {}

const SubscriptionSection: React.FC<SubscriptionSectionProps> = () => {
  const { _t } = useLanguages()
  const navigate = useNavigate()
  const userId = useUserId()
  const { enterpriseId } = useUtilsForEnterpriseId()
  const [userProfile] = useAtom(userProfileAtom)

  const enterpriseIdMemo = useMemo(
    () =>
      enterpriseId ||
      (userProfile?.enterprises && userProfile?.enterprises[0].id),
    [enterpriseId, userProfile]
  )

  const { data: subscriptionInfo, isLoading } = useSubscription({
    userId,
    ...(enterpriseIdMemo && { enterpriseId: enterpriseIdMemo }),
  })

  const subscriptionData = useMemo(() => subscriptionInfo?.data, [
    subscriptionInfo,
  ])
  const isEnterpriseAdminMemo = useMemo(() => isEnterpriseAdmin(userProfile), [
    userProfile,
  ])
  const isPrivateInstructorMemo = useMemo(
    () => isInstructor(userProfile) && !isEnterprise(userProfile),
    [userProfile]
  )
  const isFreeSubscription = useMemo(() => subscriptionInfo?.data.price === 0, [
    subscriptionInfo,
  ])

  const handleViewSubscription = useCallback(() => {
    if (isEnterpriseAdminMemo) {
      return navigate('/enterprise-admin/enterprise-profile?tab=subscription')
    }
    if (isPrivateInstructorMemo) {
      return navigate('/wallet?tab=subscriptions')
    }
  }, [navigate, isEnterpriseAdminMemo, isPrivateInstructorMemo])

  return (
    <>
      <Divider />
      <Box px='1.5rem' py='1rem'>
        <Box as='h2' py='0.5rem'>
          {enterpriseIdMemo
            ? _t('header.subscription.enterprise_title')
            : _t('header.subscription.my_title')}
        </Box>
        {isLoading && <LoadingSpinner />}
        {!isLoading && subscriptionData && (
          <>
            <Flex align='center' justify='space-between' py='0.25rem'>
              <Text fontWeight={600}>{subscriptionData.name}</Text>
              {(isEnterpriseAdminMemo || isPrivateInstructorMemo) && (
                <Button
                  size='md'
                  variant={
                    isFreeSubscription ? 'solidPrimary' : 'outlinePrimary'
                  }
                  rounded='full'
                  fontSize='0.875rem'
                  onClick={handleViewSubscription}
                  _focus={{ outline: 'none' }}
                >
                  {isFreeSubscription
                    ? _t('common.upgrade')
                    : _t('common.view')}
                </Button>
              )}
            </Flex>
            <Box py='0.25rem'>
              <Text color='gray.300' fontSize='0.875rem'>
                {_t('header.subscription.commission_description', {
                  rate: `${getNumber(
                    (subscriptionData.commission || 0) * 100,
                    0
                  )}`,
                })}
              </Text>
            </Box>
          </>
        )}
      </Box>
    </>
  )
}

export default SubscriptionSection

import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { Controller, useForm } from 'react-hook-form'

import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  Text,
} from '@chakra-ui/react'

import { AdHocRequest } from 'apis/learner'
import {
  AttachmentFile,
  DateTimePicker,
  Input,
  Option,
  Select,
  Textarea,
} from 'components'
import { language, subject, teachingLevel } from 'configs/apiPath'
import { useLanguages, useToast, useUserId } from 'hooks'
import { useUploadAdhocRequestFile } from 'hooks/useUploadAdhocRequestFile'
import { useCreateAdHocRequest } from 'queries/learner'
import { Attachment, AttachmentFileStatus, UploadFileStatus } from 'types'
import { checkValidFilesName } from 'utils/helper'
import { formatCombinationOption, generateDuration } from 'utils/parser'

import './custom.css'

export interface FindInstructorForm {
  title?: string
  message?: string
  teachingLevel?: Option
  subject?: Option
  language?: Option
  date?: Date
  startTime?: Date
  endTime?: Date
  duration?: string
  attachments?: Attachment[]
}

interface UploadClass {
  file: Attachment
  status: AttachmentFileStatus
  uploadStatus: UploadFileStatus
}

interface FindInstructorProps {}

const FILES_LIMIT = 3

const FindInstructor: React.FC<FindInstructorProps> = () => {
  const {
    handleSubmit,
    control,
    watch,
    getValues,
    setValue,
    reset,
    formState: { errors },
  } = useForm<FindInstructorForm>({
    mode: 'onChange',
    defaultValues: {
      title: '',
      message: '',
      teachingLevel: { value: '', label: '' },
      subject: { value: '', label: '' },
      language: { value: '', label: '' },
      date: undefined,
      startTime: undefined,
      endTime: undefined,
      attachments: [],
    },
  })
  const userId = useUserId()
  const toast = useToast()
  const { _t } = useLanguages()
  const [reachLimit, setReachLimit] = useState(false)
  const [isFileUploading, setFileUploading] = useState(false)

  const attachments = useMemo(() => watch('attachments'), [watch])
  const duration = useMemo(() => {
    const startTime = watch('startTime')
    const endTime = watch('endTime')
    if (startTime && endTime) {
      return generateDuration(startTime?.toISOString(), endTime?.toISOString())
    }
    return '-'
  }, [watch])

  const {
    uploadFiles,
    isUploading,
    isSettled,
    handleChangeFiles,
    handleUpload,
    isSuccess: uploadFileIsSuccess,
  } = useUploadAdhocRequestFile()

  const { acceptedFiles, getInputProps } = useDropzone({
    maxSize: 25 * 1024 * 1024,
    accept: '.pdf, .png, .jpeg, .doc, .docx, .xls, .xlsx',
  })

  const {
    mutate: createAdHocRequest,
    data: adhocRequestData,
    isLoading,
    isSuccess,
    reset: adhocRequestOnReset,
  } = useCreateAdHocRequest()

  const requestId = useMemo(() => adhocRequestData?.data?.data?.id || '', [
    adhocRequestData,
  ])

  // ** Effects
  useEffect(() => {
    if (attachments?.length) {
      setReachLimit(false)
      const newAttachments = attachments.map<UploadClass>(attachment => {
        return {
          file: attachment,
          status: 'public',
          uploadStatus: 'pending',
        }
      })

      handleChangeFiles(oldAttachments => {
        const newFiles = [...oldAttachments, ...newAttachments]
        if (newFiles.length > FILES_LIMIT) {
          setReachLimit(true)
          return oldAttachments
        }

        return newFiles
      })
    }
  }, [attachments, handleChangeFiles])

  useEffect(() => {
    setValue('attachments', acceptedFiles)
  }, [acceptedFiles, setValue])

  const handleClear = useCallback(() => {
    reset()
    handleChangeFiles([])
    setReachLimit(false)
    setValue('date', undefined)
    setValue('startTime', undefined)
    setValue('endTime', undefined)
  }, [handleChangeFiles, reset, setValue])

  useEffect(() => {
    if (requestId && isSuccess && !isSettled && !isFileUploading) {
      if ((attachments?.length || 0) > 0) {
        handleUpload(requestId)
        setFileUploading(true)
        toast({
          title: _t('message.success.title'),
          description: _t('message.success.bid_request_with_attachment'),
          status: 'success',
        })
      } else {
        adhocRequestOnReset()
        setTimeout(() => {
          handleClear()
        }, 300)
        toast({
          title: _t('message.success.title'),
          description: _t('message.success.bid_request'),
          status: 'success',
        })
      }
    }
  }, [
    _t,
    adhocRequestOnReset,
    attachments,
    handleClear,
    handleUpload,
    isFileUploading,
    isSettled,
    isSuccess,
    requestId,
    toast,
  ])

  useEffect(() => {
    if (isSuccess && isSettled && uploadFileIsSuccess && isFileUploading) {
      adhocRequestOnReset()
      setTimeout(() => {
        handleClear()
      }, 300)

      setFileUploading(false)
      toast({
        title: _t('message.success.title'),
        description: _t('message.success.bid_attachment_upload'),
        status: 'success',
      })
    }
  }, [
    _t,
    adhocRequestOnReset,
    handleClear,
    isFileUploading,
    isSettled,
    isSuccess,
    toast,
    uploadFileIsSuccess,
  ])

  // ** Events

  const handleDeleteAttachment = useCallback(
    (name: string) => {
      handleChangeFiles(oldAttachments =>
        oldAttachments.filter(attachment => attachment.file.name !== name)
      )
    },
    [handleChangeFiles]
  )

  const handleCreate = useCallback(
    (value: FindInstructorForm) => {
      if (!checkValidFilesName(uploadFiles)) {
        return toast({
          title: _t('message.error.title'),
          status: 'error',
          duration: 6000,
          description: _t('message.error.InvalidFilesName'),
        })
      }

      const today = new Date().setHours(0, 0, 0, 0)
      const startTimeFormatted =
        new Date(value?.date || 0).getTime() +
        (new Date(value?.startTime || 0).getTime() - today)
      const endTimeFormatted =
        new Date(value?.date || 0).getTime() +
        (new Date(value?.endTime || 0).getTime() - today)

      const data: AdHocRequest = {
        title: value?.title || '',
        message: value?.message || '',
        subjectId: value?.subject?.value || '',
        teachingLevelId: value?.teachingLevel?.value || '',
        languageId: value?.language?.value || '',
        startDateTime: startTimeFormatted,
        endDateTime: endTimeFormatted,
      }

      createAdHocRequest({ userId, request: data })
    },
    [_t, createAdHocRequest, toast, uploadFiles, userId]
  )

  const filterDate = (time: any) => {
    const currentDate = new Date()
    const selectedDate = new Date(time)

    return currentDate.getTime() < selectedDate.getTime() + 24 * 3600 * 1000
  }

  const filterStartTime = (time: any) => {
    const selectedDate = new Date(time.getTime() + 1000 * 60 * 15)

    const endTime = getValues('endTime')
    if (endTime) {
      return endTime.getTime() > selectedDate.getTime()
    }

    return true
  }

  const filterEndTime = (time: any) => {
    const selectedDate = new Date(time)

    const startTime = getValues('startTime')
    if (startTime) {
      return startTime.getTime() < selectedDate.getTime()
    }

    return true
  }

  return (
    <Flex as='section' direction='column' className='find-tutor'>
      <Box as='h3' fontSize='1rem' lineHeight='1.313rem' mb='0.625rem'>
        {_t('dashboard.find_instructor.description')}
      </Box>
      <Box mb='1rem'>
        <Controller
          as={Input}
          control={control}
          name='title'
          aria-label='Title'
          label={_t('dashboard.find_instructor.name')}
          placeholder={_t('dashboard.find_instructor.placeholder_name')}
          fontSize='0.75rem'
          labelProps={{ fontSize: '0.75rem' }}
          error={errors?.title?.message || ''}
          rules={{
            required: _t('job.request.validate_name_required'),
          }}
        />
      </Box>
      <Box mb='1rem'>
        <Controller
          as={
            <Textarea
              h='6.5rem'
              fontSize='0.75rem'
              labelProps={{ fontSize: '0.75rem', marginBottom: '0.675rem' }}
            />
          }
          control={control}
          name='message'
          aria-label='message'
          label={_t('dashboard.find_instructor.question')}
          placeholder=''
          fontSize='0.75rem'
          labelProps={{ fontSize: '0.75rem' }}
          error={errors?.message?.message || ''}
          rules={{
            required: _t('job.request.validate_message_required'),
          }}
        />
      </Box>
      <Grid mb='1rem' gridTemplateColumns='1fr 1fr' columnGap='1rem'>
        <Controller
          as={Select}
          control={control}
          name='teachingLevel'
          label={_t('dashboard.find_instructor.subject_level')}
          placeholder={_t(
            'dashboard.find_instructor.placeholder_subject_level'
          )}
          error={errors?.teachingLevel?.message}
          rules={{
            validate: {
              teachingLevel: ({ value }: any) => {
                if (!value) {
                  return _t('job.request.validate_subject_level_required')
                }
                return true
              },
            },
          }}
          optionUrl={teachingLevel.list({ page: 1, limit: 100 })}
          formatOption={formatCombinationOption}
          labelProps={{ fontSize: '0.75rem' }}
          selectProps={{
            fontSize: '0.75rem',
          }}
        />
        <Controller
          as={Select}
          control={control}
          name='subject'
          placeholder={_t('dashboard.find_instructor.placeholder_subject')}
          label={_t('dashboard.find_instructor.subject')}
          error={errors?.subject?.message}
          rules={{
            validate: {
              subject: ({ value }: any) => {
                if (!value) {
                  return _t('job.request.validate_subject_required')
                }
                return true
              },
            },
          }}
          optionUrl={subject.list({ page: 1, limit: 100 })}
          formatOption={formatCombinationOption}
          labelProps={{ fontSize: '0.75rem' }}
          selectProps={{
            fontSize: '0.75rem',
          }}
        />
        <Text></Text>
        <Text></Text>
      </Grid>

      <Grid mb='1rem' gridTemplateColumns='1fr 1fr' columnGap='1rem'>
        <Controller
          control={control}
          name='date'
          render={({ onChange, value }) => (
            <DateTimePicker
              label={_t('dashboard.find_instructor.date')}
              onChange={onChange}
              selected={value}
              placeholderText={_t('dashboard.find_instructor.placeholder_date')}
              labelProps={{ fontSize: '0.75rem' }}
              filterDate={filterDate}
              error={errors?.date?.message || ''}
            />
          )}
          rules={{
            required: _t('job.request.validate_date_required'),
          }}
        />
        <div />
        <Text></Text>
      </Grid>
      <Grid mb='1rem' gridTemplateColumns='1fr 0fr 1fr' columnGap='1rem'>
        <Controller
          control={control}
          name='startTime'
          render={({ onChange, value }) => (
            <DateTimePicker
              label={_t('dashboard.find_instructor.start_time')}
              onChange={onChange}
              selected={value}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={15}
              placeholderText={_t(
                'dashboard.find_instructor.placeholder_start_time'
              )}
              dateFormat='h:mm aa'
              labelProps={{ fontSize: '0.75rem' }}
              filterTime={filterStartTime}
              error={errors?.startTime?.message || ''}
            />
          )}
          rules={{
            required: _t('job.request.validate_start_time_required'),
          }}
        />
        <Flex alignItems='center' fontWeight='600' fontSize='0.75rem'>
          {_t('common.to')}
        </Flex>
        <Controller
          control={control}
          name='endTime'
          render={({ onChange, value }) => (
            <DateTimePicker
              label={_t('dashboard.find_instructor.end_time')}
              onChange={onChange}
              selected={value}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={15}
              placeholderText={_t(
                'dashboard.find_instructor.placeholder_end_time'
              )}
              dateFormat='h:mm aa'
              labelProps={{ fontSize: '0.75rem' }}
              filterTime={filterEndTime}
              error={errors?.endTime?.message || ''}
            />
          )}
          rules={{
            required: _t('job.request.validate_end_time_required'),
          }}
        />
        <Text></Text>
        <div />
        <Text></Text>
      </Grid>
      <Grid mb='1rem' gridTemplateColumns='3fr 7fr' columnGap='1.5rem'>
        <Box>
          <Box fontSize='0.75rem' fontWeight='600'>
            {_t('dashboard.find_instructor.duration')}
          </Box>
          <Box fontSize='0.75rem'>{duration}</Box>
        </Box>
        <Controller
          as={Select}
          control={control}
          name='language'
          placeholder={_t('dashboard.find_instructor.placeholder_language')}
          label={_t('dashboard.find_instructor.language')}
          error={errors?.language?.message}
          rules={{
            validate: {
              language: ({ value }: any) => {
                if (!value) {
                  return _t('job.request.validate_language_required')
                }
                return true
              },
            },
          }}
          optionUrl={language.list({ page: 1, limit: 50, active: true })}
          formatOption={formatCombinationOption}
          labelProps={{ fontSize: '0.75rem' }}
          selectProps={{
            fontSize: '0.75rem',
          }}
        />
      </Grid>
      <Flex
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        mb='0.75rem'
      >
        <Box>
          <Text as='h4' fontSize='0.625rem' lineHeight='0.875rem'>
            {_t('dashboard.find_instructor.attachments')} (
            {uploadFiles?.length || 0})
          </Text>
          <Text as='h4' fontSize='0.625rem' lineHeight='0.875rem'>
            jpg, png, pdf, doc. max 25MB
          </Text>
        </Box>
        <Controller
          control={control}
          render={() => (
            <FormControl id='file-upload' w='100px'>
              <FormLabel
                display='flex'
                alignItems='center'
                justifyContent='center'
                m='0'
                height='2.375rem'
                textTransform='uppercase'
                borderRadius='0.5rem'
                border='1px solid #3959BF'
                background='white'
                userSelect='none'
                fontWeight='600'
                fontSize='1rem'
                color='#3959BF'
              >
                {_t('common.upload')}
              </FormLabel>
              <Box as='input' id='file-upload' {...getInputProps()} />
            </FormControl>
          )}
          name='attachments'
        />
      </Flex>

      {reachLimit && (
        <Text
          my='0.25rem'
          fontSize='0.75rem'
          textAlign='center'
          color='red.500'
        >
          {_t('job.request.validate_max_files')}
        </Text>
      )}
      <Box mb='1rem'>
        {!!uploadFiles?.length && (
          <Box>
            {(uploadFiles || []).map(({ file, uploadStatus }) => (
              <AttachmentFile
                key={file.name}
                item={file}
                deletable
                uploadStatus={isUploading ? 'uploading' : uploadStatus}
                onDelete={handleDeleteAttachment}
                withStatus={false}
              />
            ))}
          </Box>
        )}
      </Box>
      <Text
        as='h4'
        fontSize='0.625rem'
        lineHeight='0.875rem'
        color='#828282'
        mb='0.75rem'
      >
        {_t('dashboard.find_instructor.note')}
      </Text>
      <Flex>
        <Button
          flex='1'
          variant='primary'
          textTransform='uppercase'
          mr='5px'
          onClick={handleSubmit(handleCreate)}
          isLoading={isLoading || isFileUploading}
        >
          {_t('common.request')}
        </Button>
        <Button
          flex='1'
          variant='outlinePrimary'
          textTransform='uppercase'
          ml='5px'
          onClick={handleClear}
          isLoading={isLoading || isFileUploading}
        >
          {_t('common.clear')}
        </Button>
      </Flex>
    </Flex>
  )
}

export default FindInstructor

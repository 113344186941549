import React, {
  lazy,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react'
import { useForm } from 'react-hook-form'

import { useAtom } from 'jotai'

import {
  CommonProfileForm,
  CredentialDetailForm,
  EducationLevelField,
} from '../forms'
import CredentialCard from './CredentialCard'
import { AvatarRef, ContentContainer, IconText } from 'components'
import { useLanguages, useToast } from 'hooks'
import { userProfileAtom } from 'store/authAtom'
import { CredentialRef, UserProfile, UserProfileRef, UserRole } from 'types'
import {
  countryOptions,
  educationQualificationOptions,
  genderOptions,
  nationalityOptions,
  typeOptions,
} from 'utils/constant'
import { formatCombinationOption, getDefaultOptions } from 'utils/parser'

import { ReactComponent as InfoIcon } from 'assets/images/info_outline_icon.svg'

interface EditProfileProps {
  userId?: string
  centerId?: string
  enterpriseId?: string
  profileData: UserProfile
  avatarRef: React.MutableRefObject<AvatarRef | undefined>
  credentialRef?: React.MutableRefObject<CredentialRef | undefined>
  userRef: React.MutableRefObject<UserProfileRef | undefined>
  subjectSection?: React.ReactNode
  toastError: string | undefined
  setToastError: React.Dispatch<React.SetStateAction<string | undefined>>
  isInstructor?: boolean
  isLearner?: boolean
  isPrivate?: boolean
  isAdmin?: boolean
  isCreate?: boolean
}

const today = new Date()

const AboutCard = lazy(() => import('./AboutCard'))
const AddressCard = lazy(() => import('./AddressCard'))
const EducationCard = lazy(() => import('./EducationCard'))
const DetailsCard = lazy(() => import('./DetailsCard'))
const QualificationCard = lazy(() => import('./QualificationCard'))

const EditProfileContainer: React.FC<EditProfileProps> = ({
  userId,
  centerId,
  enterpriseId,
  profileData,
  avatarRef,
  credentialRef,
  userRef,
  subjectSection,
  toastError,
  setToastError,
  isInstructor = false,
  isLearner = false,
  isPrivate = true,
  isAdmin = false,
  isCreate = false,
}) => {
  const { _t } = useLanguages()
  const toast = useToast()
  const [, setLoading] = useState<boolean>(false)
  const [initLanguageIds, setInitLanguageIds] = useState<string[]>([])
  const [selfProfile] = useAtom(userProfileAtom)
  const isMSTAdmin = selfProfile?.roles?.find(
    o => o.type === UserRole.MSTSuperAdmin || o.type === UserRole.MSTAdmin
  )

  const {
    control: profileControl,
    formState: { errors: profileErrors },
    trigger: validationTrigger,
    getValues: getProfileValues,
    setValue: setProfileValue,
    watch: profileWatch,
  } = useForm<CommonProfileForm>({
    mode: 'onChange',
    defaultValues: {
      // Main profile fields
      firstName: profileData?.firstName || '',
      lastName: profileData?.lastName || '',
      description: profileData?.description || '',

      // Instructor-only Fields
      ...(isInstructor && {
        yearOfExperience: profileData?.yearOfExperience,
        youtubeLink: profileData?.youtubeLink,
      }),

      // Admin-editable fields
      ...(isAdmin && {
        accountStatus: profileData?.accountStatus || false,
      }),

      // Enterprise-only fields
      ...(!isPrivate && {
        centre: {
          value: profileData?.centers?.[0].id,
          label: profileData?.centers?.[0].name,
        },
        enterprise: {
          value: profileData?.enterprises?.[0].id,
          label: profileData?.enterprises?.[0].name,
        },
      }),

      // Profile creation data
      ...(isCreate && isMSTAdmin && { type: typeOptions[0] }),

      // Secondary profile details
      birthday: profileData?.birthday
        ? new Date(profileData?.birthday)
        : undefined,
      gender: getDefaultOptions(genderOptions, profileData?.gender),
      countryOfResidence: getDefaultOptions(
        countryOptions,
        profileData?.countryOfResidence
      ),
      nationality: getDefaultOptions(
        nationalityOptions,
        profileData?.nationality
      ),
      languages: profileData?.languages
        ? formatCombinationOption(profileData.languages)
        : [],

      // Address & Contacts
      address1: profileData?.address1 || '',
      address2: profileData?.address2 || '',
      city: profileData?.city || '',
      state: profileData?.state || '',
      country: getDefaultOptions(countryOptions, profileData?.country),
      zipCode: profileData?.zipCode || '',
      contactNumber: profileData?.contactNumber || '',
      email: profileData?.email || '',
      recoveryEmail: profileData?.recoveryEmail || '',

      // Qualifications & Credentials
      educationQualification: getDefaultOptions(
        educationQualificationOptions,
        profileData?.educationQualification
      ),
      credentials:
        profileData?.credentials?.map(item => ({
          opt: 'update',
          id: item.id,
          name: item.name,
          issueEnterpriseId: item.issueEnterpriseId,
          issueName: item.issueName,
          issueDate: item.issueDate ? new Date(item.issueDate) : undefined,
          expiryDate: item.expiryDate ? new Date(item.expiryDate) : undefined,
          referenceId: item.referenceId,
          referenceUrl: item.referenceUrl,
          attachmentKey: item.attachmentKey,
          attachment: item.attachment,
        })) || [],
    },
  })

  // Education level only applies to learners.
  const {
    control: educationControl,
    getValues: getEducationalLevel,
    watch: watchEducationalLevel,
  } = useForm<EducationLevelField>({
    shouldUnregister: true,
    defaultValues: {
      country: {
        value: profileData?.educationSystem?.country?.id || '',
        label: profileData?.educationSystem?.country?.name || '',
      },
      system: {
        value: profileData?.educationSystem?.system?.id || '',
        label: profileData?.educationSystem?.system?.name || '',
      },
      teachingLevel: {
        value: profileData?.educationSystem?.teachingLevel?.id || '',
        label: profileData?.educationSystem?.teachingLevel?.name || '',
      },
      startDate: profileData?.educationSystem?.startDateTime
        ? new Date(profileData?.educationSystem?.startDateTime as number)
        : undefined,
      endDate: profileData?.educationSystem?.endDateTime
        ? new Date(profileData?.educationSystem?.endDateTime as number)
        : undefined,
      isCompleted: profileData?.educationSystem?.isCompleted || false,
    },
  })

  // Display errors as toast messages
  useEffect(() => {
    if (toastError) {
      setLoading(false)
      toast({
        title: 'Error',
        description: toastError,
        status: 'error',
      })
      setToastError('')
    }
  }, [toastError, setLoading, setToastError, toast])

  // Get list of changed languages based on initial and current states
  const getTotalChangedLanguages = useCallback(
    (languages: { value: string; label: string }[]) => {
      const changeLanguageIds = languages?.map(item => item.value) || []
      const deletedLanguages = initLanguageIds
        .filter(id => !changeLanguageIds.includes(id))
        .map(item => ({
          opt: 'remove',
          id: item,
        }))
      const addedLanguages = changeLanguageIds
        .filter(id => !initLanguageIds.includes(id))
        .map(item => ({
          opt: 'add',
          id: item,
        }))

      return addedLanguages.length > 0 || deletedLanguages.length > 0
        ? [...deletedLanguages, ...addedLanguages]
        : undefined
    },
    [initLanguageIds]
  )

  // Compile all the data to pass back to parent for submission
  const getFormattedData = useCallback(async () => {
    const {
      birthday,
      gender,
      countryOfResidence,
      yearOfExperience,
      nationality,
      languages,
      country,
      educationQualification,
      ...values
    } = getProfileValues()

    if (!(await validationTrigger())) {
      return false
    }
    setLoading(true)

    const totalChangedLanguages = getTotalChangedLanguages(languages)
    const educationalLevel = getEducationalLevel()

    const formatValues = Object.entries(values)
      .filter(([, value]) => value !== undefined)
      .reduce(
        (acc, [key, value]) => ({
          ...acc,
          [key]: value,
        }),
        {}
      )

    const formattedData = {
      ...formatValues,
      centerId: centerId || values.centre?.value,
      birthday: birthday ? birthday.getTime() : undefined,
      gender: gender?.value || '',
      countryOfResidence: countryOfResidence?.value,
      nationality: nationality?.value,
      languages: totalChangedLanguages,
      country: country?.value,
      educationQualification: educationQualification?.value,
      educationSystem: {
        countryId: educationalLevel.country?.value,
        systemId: educationalLevel.system?.value,
        teachingLevelId: educationalLevel.teachingLevel?.value,
        startDateTime: educationalLevel.startDate?.getTime(),
        endDateTime: educationalLevel.endDate?.getTime(),
        isCompleted: educationalLevel.isCompleted,
      },
    } as any

    // Insert instructor-only fields
    // 0 years of experience shows up as "-"
    if (isInstructor) {
      formattedData.yearOfExperience = yearOfExperience || 0
    }

    // Account creation requires additional role data
    if (isCreate) {
      const roleName =
        values.type?.value === 'private'
          ? isLearner
            ? UserRole.Student
            : UserRole.Tutor
          : isLearner
          ? UserRole.EnterpriseStudent
          : UserRole.EnterpriseTutor

      formattedData.roleName = roleName
      delete formattedData.type
    }

    if (values?.centre) {
      formattedData.centerId = centerId || values.centre?.value
      delete formattedData.centre
    }

    if (values?.enterprise) {
      formattedData.enterpriseId = enterpriseId || values.enterprise?.value
      delete formattedData.enterprise
    }

    if (credentialRef) {
      const removedCredentials =
        credentialRef.current?.getRemoveCredential() || []
      const updatedCredentials =
        (formattedData.credentials &&
          formattedData.credentials.map((item: any) => ({
            ...item,
            id: item.opt === 'add' ? undefined : item.id,
            issueDate: item.issueDate?.getTime(),
            expiryDate: item.expiryDate?.getTime(),
            attachment: item.attachment?.id ? undefined : item.attachment,
          }))) ||
        []
      return {
        ...formattedData,
        ...((updatedCredentials.length > 0 ||
          removedCredentials.length > 0) && {
          credentials: [
            ...updatedCredentials,
            ...removedCredentials,
          ] as CredentialDetailForm[],
        }),
      }
    }
    return formattedData
  }, [
    getProfileValues,
    validationTrigger,
    getTotalChangedLanguages,
    getEducationalLevel,
    centerId,
    isInstructor,
    isCreate,
    credentialRef,
    isLearner,
    enterpriseId,
  ])

  useImperativeHandle(
    userRef,
    () => ({
      getData: () => getFormattedData(),
    }),
    [getFormattedData]
  )

  return (
    <>
      <AboutCard
        profileControl={profileControl}
        profileErrors={profileErrors}
        profileWatch={profileWatch}
        setInitLanguageIds={setInitLanguageIds}
        centerId={centerId}
        enterpriseId={enterpriseId}
        profileData={profileData}
        avatarRef={avatarRef}
        isInstructor={isInstructor}
        isPrivate={isPrivate}
        isMSTAdmin={isMSTAdmin}
        isAdmin={isAdmin}
        isCreate={isCreate}
      />

      <DetailsCard
        today={today}
        profileControl={profileControl}
        profileErrors={profileErrors}
        isLearner={isLearner}
      />

      {isLearner && (
        <EducationCard
          today={today}
          educationControl={educationControl}
          watchEducationalLevel={watchEducationalLevel}
        />
      )}

      {isInstructor && (
        <>
          <ContentContainer
            title={_t('profile.heading.teaching_subjects_and_levels')}
            mt='1rem'
            rightElement={
              <IconText
                icon={InfoIcon}
                text={_t('common.public')}
                tooltipText={_t('profile.alert.edit_about')}
                textProps={{ textColor: 'gray.500' }}
              />
            }
          >
            {subjectSection}
          </ContentContainer>
          <QualificationCard
            profileControl={profileControl}
            profileErrors={profileErrors}
          />
        </>
      )}

      <CredentialCard
        userId={userId}
        profileControl={profileControl}
        profileErrors={profileErrors}
        setValue={setProfileValue}
        credentials={profileData?.credentials || []}
        ref={credentialRef as React.Ref<CredentialRef>}
      />

      <AddressCard profileControl={profileControl} />
    </>
  )
}

export default EditProfileContainer

import React, { useCallback, useEffect, useState } from 'react'

import {
  Box,
  Button,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Radio,
  RadioGroup,
  Text,
} from '@chakra-ui/react'

import { useLanguages } from 'hooks'

interface ExportPopoverProps {
  title?: string
  handleExportFormat?: (value: string) => void
  fetchExport: () => void
  isLoading: boolean
  dataUrl?: string
}

const ExportPopover: React.FC<ExportPopoverProps> = ({
  title = 'common.export',
  handleExportFormat,
  fetchExport,
  isLoading,
  dataUrl,
}) => {
  const { _t } = useLanguages()
  const [isExport, setIsExport] = useState<boolean>(false)

  const handleExport = useCallback(async () => {
    fetchExport()
    setIsExport(true)
  }, [fetchExport])

  useEffect(() => {
    if (isExport && !isLoading && dataUrl) {
      window.open(dataUrl)
      setIsExport(false)
    }
  }, [dataUrl, isLoading, isExport])

  const renderFormatOption = useCallback(() => {
    return (
      <Box my='1rem'>
        <Text fontSize='0.875rem' color='gray.300'>
          {_t('export.file.format')}
        </Text>
        <RadioGroup onChange={handleExportFormat} defaultValue={'xlsx'}>
          <Radio value='xlsx' mr='1rem'>
            {_t('common.xlsx')}
          </Radio>
          <Radio value='csv'>{_t('common.csv')}</Radio>
        </RadioGroup>
      </Box>
    )
  }, [_t, handleExportFormat])

  return (
    <Box position='relative'>
      <Popover>
        <PopoverTrigger>
          <Button variant='outlinePrimary' textTransform='uppercase'>
            {_t('common.export')}
          </Button>
        </PopoverTrigger>
        <Box zIndex='5' position='relative'>
          <PopoverContent
            w='20rem'
            mr={{ base: '0', md: '5rem' }}
            _focus={{
              outline: 0,
            }}
          >
            <PopoverBody
              p='0'
              background='white'
              rounded='lg'
              boxShadow='lg'
              overflow='hidden'
            >
              <Box px='1.5rem' py='1rem'>
                <Text as='h2'>{_t(title)}</Text>
                {handleExportFormat && renderFormatOption()}
                <Button
                  size='md'
                  variant='solidPrimary'
                  w='full'
                  rounded='full'
                  fontSize='0.875rem'
                  onClick={handleExport}
                  _focus={{ outline: 'none' }}
                  isLoading={isLoading}
                >
                  {_t('common.export')}
                </Button>
              </Box>
            </PopoverBody>
          </PopoverContent>
        </Box>
      </Popover>
    </Box>
  )
}

export default ExportPopover

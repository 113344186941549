import React from 'react'

import { Box } from '@chakra-ui/react'

import ProfileLayout from '../../containers/ProfileLayout'
import ViewProfileContainer from '../../containers/ViewProfile'
import { LoadingSpinner, ProfileHeaderInfo } from 'components'
import { GroupedCombination, UserProfile } from 'types'
import { responsivePadding } from 'utils/constant'

interface GuestViewProfileContainerProps {
  userId?: string
  profileData: UserProfile | undefined | null
  teachingCombinations?: GroupedCombination[]
  isInstructor?: boolean
}

const GuestViewProfileContainer: React.FC<GuestViewProfileContainerProps> = ({
  userId,
  profileData,
  teachingCombinations,
  isInstructor = false,
}) => {
  if (!profileData || teachingCombinations === undefined) {
    return <LoadingSpinner />
  }

  return (
    <ProfileLayout
      topElement={
        <Box px={responsivePadding}>
          <ProfileHeaderInfo data={profileData} />
        </Box>
      }
    >
      <ViewProfileContainer
        userId={userId}
        profileData={profileData}
        teachingCombinations={teachingCombinations}
        isInstructor={isInstructor}
        isPublicView
        isGuestView
      />
    </ProfileLayout>
  )
}

export default GuestViewProfileContainer

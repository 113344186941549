import React, { useMemo } from 'react'
import { Row } from 'react-table'

import { Box, Switch, Text } from '@chakra-ui/react'
import format from 'date-fns/format'

import { CashFlowStatusText, Table } from 'components'
import { useLanguages } from 'hooks'
import { CashFlowHistory, CashFlowInfo } from 'types'

interface HistoryProps {
  data: CashFlowInfo
  isLoading: boolean
}

const History: React.FC<HistoryProps> = ({ data, isLoading }) => {
  const { _t } = useLanguages()
  const historyData = useMemo(() => data?.history || [], [data])
  const columns = [
    {
      Header: _t('cash_flow.detail.date_time'),
      id: 'dateTime',
      Cell: ({ row }: { row: Row<CashFlowHistory> }) => (
        <Text noOfLines={1}>
          {format(new Date(row.original.createdAt), 'dd MMM yyyy, h:mm a')}
        </Text>
      ),
      width: '200',
    },
    {
      Header: _t('cash_flow.detail.status'),
      id: 'status',
      Cell: ({ row }: { row: Row<CashFlowHistory> }) => (
        <CashFlowStatusText status={row.original.status} />
      ),
      width: '200',
    },
    {
      Header: _t('cash_flow.detail.freeze'),
      id: 'freeze',
      Cell: ({ row }: { row: Row<CashFlowHistory> }) => (
        <Switch size='sm' isChecked={row.original.freeze} disabled />
      ),
      width: '200',
    },
    {
      Header: _t('cash_flow.detail.remarks'),
      id: 'remarks',
      Cell: ({ row }: { row: Row<CashFlowHistory> }) => (
        <Text noOfLines={1}>{row.original.remarks || '-'}</Text>
      ),
      width: '200',
    },
  ]

  return (
    <Box>
      <Text as='h2' mb='1rem'>
        {_t('cash_flow.management.history_title')}
      </Text>
      <Table<CashFlowHistory>
        columns={columns}
        data={historyData}
        loading={isLoading}
      />
    </Box>
  )
}

export default History

import React, { useCallback, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'

import { Box, Button, Flex, HStack, Text } from '@chakra-ui/react'
import groupBy from 'lodash.groupby'
import uniqBy from 'lodash.uniqby'

import PermissionGroup from './PermissionGroup'
import {
  Input,
  LoadingSpinner,
  LoadMoreOptionSelect,
  Modal,
  ModalProps,
} from 'components'
import { adminPath } from 'configs/apiPath'
import { usePermission } from 'queries/permission'
import { useCreateRole } from 'queries/role'
import { UserProfile } from 'types'

import { ReactComponent as DeleteIcon } from '../../assets/images/trash_icon.svg'

interface AddRoleModalProps extends Omit<ModalProps, 'children'> {}

interface AddRoleField {
  roleTitle: string
}

interface AssignUserOption {
  label: string
  value: string
  original: UserProfile
}
interface SelectedPermission {
  [groupName: string]: string[]
}

const AddRoleModal: React.FC<AddRoleModalProps> = ({
  isOpen,
  onClose,
}: AddRoleModalProps) => {
  const [currentUsers, setCurrentUsers] = useState<UserProfile[]>([])
  const [selectPermissions, setPermissions] = useState<SelectedPermission>()

  const { control, handleSubmit, formState } = useForm<AddRoleField>({
    mode: 'onChange',
    defaultValues: {
      roleTitle: '',
    },
  })
  const { isDirty, isValid } = formState
  const { data: listPermission, isLoading: loadingPermission } = usePermission()
  const { mutate: addRole, isLoading } = useCreateRole()

  const handleChangeSelectPermissions = (groupName: string) => (
    data: string[]
  ) => {
    let tmpNewSelectPermissions = {
      ...selectPermissions,
      [groupName]: data,
    }
    setPermissions(tmpNewSelectPermissions)
  }

  const formatOptionLabel = useCallback(
    (
      option: AssignUserOption,
      { selectValue }: { selectValue: AssignUserOption[] }
    ) => {
      const isSelected = selectValue.some(
        select => select.value === option.value
      )

      if (isSelected) {
        return null
      }

      return (
        <Flex flex={1} alignItems='center'>
          <Text width='5rem' noOfLines={1}>
            {option?.original?.firstName} {option?.original?.lastName}
          </Text>
          <Text
            flex={1}
            mx='1.5rem'
            fontStyle='italic'
            color='gray.300'
            noOfLines={1}
          >
            {option?.original?.centers?.map(center => center.name)?.join(' | ')}
          </Text>
          <Box fontSize='1.25rem' fontWeight='700' color='primary'>
            &#43;
          </Box>
        </Flex>
      )
    },
    []
  )

  const formatOption = useCallback((options: UserProfile[]) => {
    return options.map(option => ({
      label: option.firstName || '',
      value: option.id || '',
      original: option,
    }))
  }, [])

  const handleSelectUser = (user: AssignUserOption) => {
    setCurrentUsers(curUsers =>
      curUsers?.length
        ? uniqBy([user.original, ...curUsers], 'id')
        : [user.original]
    )
  }

  const handleRemoveUser = (id: string | undefined) => () => {
    setCurrentUsers(curUsers => curUsers?.filter(user => user.id !== id))
  }

  const handleAddRole = (values: AddRoleField) => {
    const name = values.roleTitle
    let formattedPermissions: string[] = []

    Object.values(selectPermissions || {}).forEach(o => {
      formattedPermissions = [...formattedPermissions, ...o]
    })

    addRole({
      name,
      permissions: formattedPermissions,
      userIds: currentUsers.map(user => user.id as string),
    })
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Box textStyle='headingModalText' textAlign='center'>
        Add New Role
      </Box>
      <Box as='form' mt='1.5rem' onSubmit={handleSubmit(handleAddRole)}>
        <HStack align='flex-start' spacing={12}>
          <Box flexShrink={0}>
            <Controller
              as={Input}
              control={control}
              name='roleTitle'
              label='Role Title'
              rules={{
                required: 'Role Title is required',
              }}
            />

            <Text mt='2rem' fontWeight='600' fontSize='0.875rem'>
              Responsibility
            </Text>
            <Box h='10rem' overflow='auto'>
              {listPermission?.data ? (
                Object.entries(groupBy(listPermission?.data, 'group')).map(
                  ([groupName, data]) => {
                    return (
                      <PermissionGroup
                        key={groupName}
                        groupName={groupName}
                        onChange={handleChangeSelectPermissions(groupName)}
                        data={data}
                        selectedPermissions={selectPermissions?.[groupName]}
                      />
                    )
                  }
                )
              ) : (
                <LoadingSpinner />
              )}
            </Box>
          </Box>

          <Box w='100%'>
            <Text fontWeight='600' fontSize='0.875rem'>
              Assign Users
            </Text>

            <LoadMoreOptionSelect
              placeholder='Search User to Assign'
              optionUrl={adminPath.getListUsers({ page: 1, limit: 20 })}
              formatOptionLabel={formatOptionLabel}
              formatOption={formatOption}
              hideSelectedOptions={true}
              onChange={handleSelectUser}
              maxMenuHeight={300}
            />

            {!!currentUsers?.length &&
              currentUsers.map(user => (
                <Flex key={user.id} alignItems='center' mt='1.25rem'>
                  <Text w='5rem' textStyle='assignUserName'>{`${
                    user.firstName || ''
                  } ${user.lastName || ''}`}</Text>
                  <Text
                    flex={1}
                    mx='1.5rem'
                    fontStyle='italic'
                    color='gray.300'
                    noOfLines={1}
                  >
                    {user?.centers?.map(center => center.name)?.join(' | ')}
                  </Text>
                  <DeleteIcon
                    fill='#3959BF'
                    stroke='#3959BF'
                    cursor='pointer'
                    onClick={handleRemoveUser(user.id)}
                  >
                    Remove
                  </DeleteIcon>
                </Flex>
              ))}
          </Box>
        </HStack>

        <Flex mt='2rem' justifyContent='center' alignItems='center'>
          <Button
            variant='solidPrimary'
            textTransform='uppercase'
            px='5rem'
            type='submit'
            disabled={isLoading || !isValid || !isDirty || loadingPermission}
          >
            Add
          </Button>
          <Button
            variant='transparent'
            textTransform='uppercase'
            px='5rem'
            onClick={onClose}
          >
            Cancel
          </Button>
        </Flex>
      </Box>
    </Modal>
  )
}

export default AddRoleModal

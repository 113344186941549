import React, { useEffect, useMemo } from 'react'
import { DropzoneProps, useDropzone } from 'react-dropzone'

import { Box, BoxProps, Flex, Text } from '@chakra-ui/react'

import { MAX_FILE_SIZE } from 'utils/constant'

import { ReactComponent as KnovoLogo } from 'assets/images/knovo_logo_grayscale.svg'

interface FileUploadProps extends DropzoneProps {
  label?: string
  containerProps?: BoxProps
  onChange: (files: File[]) => void
  isReachLimit?: boolean
}

const FileUpload: React.FC<FileUploadProps> = ({
  label,
  containerProps,
  onChange,
  isReachLimit,
  maxSize,
  ...dropzoneProps
}) => {
  const {
    acceptedFiles,
    fileRejections,
    getRootProps,
    getInputProps,
    isDragActive,
  } = useDropzone({
    maxSize: MAX_FILE_SIZE,
    accept: '.pdf, .png, .jpeg, .jpg, .doc, .docx, .xls, .xlsx',
    ...dropzoneProps,
  })

  useEffect(() => {
    onChange(acceptedFiles)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acceptedFiles])

  const maxFilesError = useMemo(() => {
    const { maxFiles } = dropzoneProps

    return maxFiles && fileRejections.length >= maxFiles
  }, [dropzoneProps, fileRejections])

  const maxSizeError = useMemo(() => {
    return (
      fileRejections?.[0]?.file.size > (maxSize ? maxSize : MAX_FILE_SIZE) ||
      false
    )
  }, [fileRejections, maxSize])

  return (
    <Box {...containerProps} fontSize='0.875rem'>
      {label && (
        <Text mb='0.5rem' fontWeight='600'>
          {label}
        </Text>
      )}
      <Box {...getRootProps()}>
        <Box as='input' {...getInputProps()} />
        <Flex
          py='2rem'
          justifyContent='center'
          alignItems='center'
          border='1px dashed #c4c4c4'
          borderRadius='0.5rem'
        >
          <KnovoLogo width='3.5rem' height='3.5rem' />
          <Box ml='1rem'>
            <Text fontWeight='600' color='rgba(0,0,0,0.5)'>
              {isDragActive
                ? 'Drop the files here ...'
                : 'Drag and Drop your file or Click to Browse'}
            </Text>
            <Text fontSize='0.75rem' color='gray.100'>
              Files supported:{' '}
              {dropzoneProps.accept
                ? dropzoneProps.accept
                : '.pdf, .doc, .xls, .png, .jpg'}{' '}
              (Max. {maxSize ? maxSize / 1024 / 1024 : 25} mb)
            </Text>
          </Box>
        </Flex>
      </Box>
      <Text mt='0.25rem' fontSize='0.625rem' textAlign='left' color='gray.300'>
        <span style={{ fontWeight: 'bold' }}>Note</span>: If the upload file is
        quite large, it will take a while to display.
      </Text>

      {(maxFilesError || isReachLimit) && (
        <Text mt='0.25rem' fontSize='0.75' textAlign='center' color='red.500'>
          Limit of files
        </Text>
      )}
      {maxSizeError && (
        <Text mt='0.25rem' fontSize='0.75' textAlign='center' color='red.500'>
          The file is larger than allowed
        </Text>
      )}
    </Box>
  )
}

export default FileUpload

import React, { useMemo } from 'react'

import {
  Button,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
} from '@chakra-ui/react'
import { useAtom } from 'jotai'

import { FullCalendarController } from 'components'
import { userProfileAtom } from 'store/authAtom'
import { isInstructor, isLearner } from 'utils/helper'

import { ReactComponent as Calendar } from 'assets/images/calendar.svg'

const CalendarPopover = () => {
  const [userProfile] = useAtom(userProfileAtom)

  const isInstructorMemo = useMemo(() => isInstructor(userProfile), [
    userProfile,
  ])
  const isLearnerMemo = useMemo(() => isLearner(userProfile), [userProfile])

  if (!isInstructorMemo && !isLearnerMemo) {
    return null
  }

  return (
    <Popover>
      <PopoverTrigger>
        <Button variant='transparent' px='0'>
          <Calendar cursor='pointer' />
        </Button>
      </PopoverTrigger>
      <PopoverContent
        w={{ base: '0', lg: '60rem' }}
        mr={{ base: '0', lg: '10rem' }}
        color='#fff'
        borderRadius='1rem'
        boxShadow='md'
        _focus={{
          outline: 0,
        }}
      >
        <PopoverBody
          p='2rem'
          background='white'
          borderRadius='1rem'
          overflow='hidden'
        >
          <FullCalendarController />
        </PopoverBody>
      </PopoverContent>
    </Popover>
  )
}

export default CalendarPopover

import Axios from 'axios'

import { centre } from '../configs/apiPath'
import {
  CentreAvatarUrl,
  CreateCentreData,
  EditCentreData,
  ListingParams,
} from 'types'

export const createUploadAvatarCentre = async ({
  centreId,
}: {
  centreId: string
}) => {
  return Axios.get<CentreAvatarUrl>(centre.getUrlUploadAvatar(centreId))
}

export const createUploadBannerCentre = async ({
  centreId,
}: {
  centreId: string
}) => {
  return Axios.get<CentreAvatarUrl>(centre.getUrlUploadBanner(centreId))
}

export const uploadFileCentre = async (url: string, formData: FormData) => {
  return Axios.post(url, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      'No-Need-Authorization': true,
    },
  })
}

export const addCentre = ({
  data,
  onClose,
  avatarFile,
  bannerFile,
  resetFile,
}: CreateCentreData) => {
  return Axios.post(centre.create(), data)
}

export const updateCentre = ({
  data,
  id,
  avatarFile,
  bannerFile,
  resetFile,
}: EditCentreData) => {
  return Axios.put(centre.update(id), data)
}

export const deleteCentre = (centreId: string) => {
  return Axios.delete(centre.delete(centreId))
}

export const getCentresList = (enterpriseId: string, params: ListingParams) => {
  return Axios.get(centre.list(enterpriseId, params))
}

import React, { useCallback, useMemo } from 'react'
import { Column, Row } from 'react-table'

import {
  Avatar,
  Box,
  Flex,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Text,
} from '@chakra-ui/react'
import format from 'date-fns/format'

import {
  ActionDot,
  Link,
  PaymentTypeText,
  PopoverItem,
  Table,
  TransactionStatusBadge,
  TransactionTypeText,
} from 'components'
import { useLanguages, useToast } from 'hooks'
import { TransactionInfo, TransactionStatus } from 'types'
import { formatAmount, getFullName } from 'utils/parser'

import { ReactComponent as CopyIcon } from 'assets/images/copy_icon.svg'
import { ReactComponent as UpdateStatusIcon } from 'assets/images/update_status_icon.svg'

interface TransactionDataTableProps {
  isLoading: boolean
  isWithdrawal: boolean
  colorScheme?: string
  data: TransactionInfo[]
  handleView?: (id: string) => string
  handleUpdateWithdrawal?: (id: string) => void
}

const TransactionDataTable = ({
  isLoading,
  isWithdrawal,
  colorScheme = 'primaryDarker',
  data,
  handleView,
  handleUpdateWithdrawal,
}: TransactionDataTableProps) => {
  const { _t } = useLanguages()
  const toast = useToast()

  const handleCopy = useCallback(
    text => {
      navigator.clipboard.writeText(text)

      toast({
        title: _t('common.copied'),
        description: _t('common.copied_to_clipboard'),
        status: 'success',
      })
    },
    [_t, toast]
  )

  const columns = useMemo<Column<TransactionInfo>[]>(
    () => [
      {
        Header: _t('wallet.transaction.transaction_id'),
        id: 'transactionId',
        Cell: ({ row }: { row: Row<TransactionInfo> }) => {
          const link = handleView ? handleView(row.original.id) : '#'
          return (
            <Link to={link} color='primary'>
              <Text fontWeight={600} noOfLines={1}>
                {row.original.id}
              </Text>
            </Link>
          )
        },
        width: '260',
      },
      {
        Header: _t('wallet.transaction.date_and_time'),
        id: 'dateTime',
        Cell: ({ row }: { row: Row<TransactionInfo> }) => (
          <Text noOfLines={1}>
            {format(new Date(row.original.createdAt), 'dd MMM yyyy, h:mm a')}
          </Text>
        ),
        width: '200',
      },
      {
        Header: _t('wallet.transaction.description'),
        id: 'description',
        Cell: ({ row }: { row: Row<TransactionInfo> }) => (
          <Text noOfLines={1}>{row.original.description}</Text>
        ),
        width: '260',
      },
      {
        Header: _t('wallet.transaction.transaction_type'),
        id: 'type',
        Cell: ({ row }: { row: Row<TransactionInfo> }) => (
          <TransactionTypeText type={row.original.type} />
        ),
        width: '200',
      },
      {
        Header: _t('wallet.transaction.payment_type'),
        id: 'paymentType',
        Cell: ({ row }: { row: Row<TransactionInfo> }) => (
          <PaymentTypeText type={row.original.paymentType} />
        ),
        width: '145',
      },
      {
        Header: _t('wallet.transaction.amount'),
        id: 'amount',
        Cell: ({ row }: { row: Row<TransactionInfo> }) => {
          const { amount, method } = row.original
          const amountSign = method === 'credit' ? '+' : '-'
          const textColor = method === 'credit' ? 'actionGreen' : 'error'
          return (
            <Text noOfLines={1} color={textColor} fontWeight={600}>
              {`${amountSign}$${formatAmount((amount || 0) / 100)}`}
            </Text>
          )
        },
        width: '145',
      },
      {
        Header: _t('wallet.transaction.status'),
        id: 'status',
        Cell: ({ row }: { row: Row<TransactionInfo> }) => (
          <TransactionStatusBadge status={row.original.status} />
        ),
        width: '200',
      },
      {
        Header: _t('wallet.transaction.user'),
        id: 'user',
        Cell: ({ row }: { row: Row<TransactionInfo> }) =>
          row.original.user ? (
            <Flex align='center'>
              <Avatar
                size='xs'
                name={getFullName(row.original.user)}
                src={row.original.user.avatar}
                key={row.original.user.avatar || `user-${row.index}`}
                mr='0.5rem'
              />
              <Text noOfLines={1}>{getFullName(row.original.user)}</Text>
              <Box
                ml='0.5rem'
                cursor='pointer'
                onClick={() => handleCopy(row.original.user?.id)}
              >
                <CopyIcon width='15' height='15' />
              </Box>
            </Flex>
          ) : (
            '-'
          ),
        width: '260',
      },
      {
        Header: _t('wallet.transaction.centre'),
        id: 'centre',
        Cell: ({ row }: { row: Row<TransactionInfo> }) =>
          row.original.center ? (
            <Flex align='center'>
              <Avatar
                size='xs'
                name={row.original.center.name}
                src={row.original.center.avatar}
                key={row.original.center.avatar || `centre-${row.index}`}
                mr='0.5rem'
              />
              <Text noOfLines={1}>{row.original.center.name}</Text>
              <Box
                ml='0.5rem'
                cursor='pointer'
                onClick={() => handleCopy(row.original.center?.id)}
              >
                <CopyIcon width='15' height='15' />
              </Box>
            </Flex>
          ) : (
            '-'
          ),
        width: '260',
      },
      {
        Header: _t('wallet.transaction.enterprise'),
        id: 'enterprise',
        Cell: ({ row }: { row: Row<TransactionInfo> }) =>
          row.original.enterprise ? (
            <Flex align='center'>
              <Avatar
                size='xs'
                name={row.original.enterprise.name}
                src={row.original.enterprise.avatar}
                key={
                  row.original.enterprise.avatar || `enterprise-${row.index}`
                }
                mr='0.5rem'
              />
              <Text noOfLines={1}>{row.original.enterprise.name}</Text>
              <Box
                ml='0.5rem'
                cursor='pointer'
                onClick={() => handleCopy(row.original.enterprise?.id)}
              >
                <CopyIcon width='15' height='15' />
              </Box>
            </Flex>
          ) : (
            '-'
          ),
        width: '260',
      },
      ...(isWithdrawal
        ? [
            {
              Header: _t('wallet.transaction.action'),
              Cell: ({ row }: { row: Row<TransactionInfo> }) =>
                row.original.status === TransactionStatus.PENDING && (
                  <Popover>
                    <PopoverTrigger>
                      <Box>
                        <ActionDot />
                      </Box>
                    </PopoverTrigger>
                    <Portal>
                      <PopoverContent
                        w='16rem'
                        bgColor='primaryDarker'
                        color='#fff'
                        _focus={{
                          outline: 0,
                        }}
                      >
                        <PopoverBody p='0'>
                          {handleUpdateWithdrawal && (
                            <PopoverItem
                              onClick={() =>
                                handleUpdateWithdrawal(row.original.id)
                              }
                            >
                              <UpdateStatusIcon />
                              <Box ml='1rem'>
                                {_t('withdrawal.management.update')}
                              </Box>
                            </PopoverItem>
                          )}
                        </PopoverBody>
                      </PopoverContent>
                    </Portal>
                  </Popover>
                ),
              width: '100',
            },
          ]
        : []),
    ],
    [_t, handleCopy, handleView, isWithdrawal, handleUpdateWithdrawal]
  )

  return (
    <Table<TransactionInfo>
      columns={columns}
      data={data}
      loading={isLoading}
      colorScheme={colorScheme}
    />
  )
}

export default TransactionDataTable

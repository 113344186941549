import React, { useCallback, useEffect, useRef, useState } from 'react'

import { Box, Button, Flex, Grid, Text } from '@chakra-ui/react'

import StarsRating from './StarsRating'
import { Textarea } from 'components'
import Modal, { ModalProps } from 'components/Modal'
import { useLanguages } from 'hooks'
import { useCreateReview } from 'queries/review'
import { StarsRagingRef } from 'types'

interface RatingModalProps extends Omit<ModalProps, 'children'> {
  reviewInfo: {
    tutorId: string
    sessionId: string
  }
  onInvalidateList?: () => void
}

export interface ResetPasswordInput {
  email: string
  code: string
  password: string
  confirmPassword: string
}

const RatingModal: React.FC<RatingModalProps> = ({
  reviewInfo,
  isOpen,
  onClose,
  onInvalidateList,
}) => {
  const { _t } = useLanguages()
  const [reviewContent, setReviewContent] = useState<string>('')
  const starsRatingRef = useRef<StarsRagingRef>()
  const { mutate: createReview, isLoading } = useCreateReview({
    onCloseModal: onClose,
    onInvalidateUrl: onInvalidateList,
  })

  const handleSubmit = useCallback(() => {
    const rate = starsRatingRef.current?.getStarNumber() || 1
    const formattedData = {
      ...reviewInfo,
      rate,
      review: reviewContent,
    }
    createReview(formattedData)
  }, [createReview, reviewContent, reviewInfo])

  const handleChangeReviewContent = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      const content = e.target.value
      if (content.length <= 250) {
        setReviewContent(content)
      }
    },
    []
  )

  useEffect(() => {
    if (isOpen) {
      setReviewContent('')
    }
  }, [isOpen])

  return (
    <Modal isOpen={isOpen} onClose={onClose} size='xl'>
      <Box textStyle='headingModalText' textAlign='center'>
        {_t('history.rate_modal.title')}
      </Box>
      <Grid mt='1.5rem' gridTemplateColumns='30% 70%' rowGap='0.5rem'>
        <Text>{_t('history.rate_modal.rating')}</Text>
        <StarsRating
          ref={starsRatingRef as React.Ref<StarsRagingRef>}
          starSize='1rem'
          fillColor='#F4AF7D'
        />
        <Text>{_t('history.rate_modal.review')}</Text>
        <Box>
          <Textarea
            value={reviewContent}
            onChange={handleChangeReviewContent}
            placeholder={_t('history.rate_modal.placeholder_review')}
            aria-label='Review'
            h='6.5rem'
            fontSize='0.75rem'
          />
          <Text textAlign='right' fontSize='0.625rem' color='gray.300'>
            {reviewContent.length}/250
          </Text>
        </Box>
      </Grid>

      <Flex
        mx='auto'
        mt='2rem'
        w={{ base: '19rem', md: '90%' }}
        justifyContent='center'
      >
        <Button
          variant='solidPrimary'
          flex={1}
          textTransform='uppercase'
          type='submit'
          mr='0.5rem'
          isDisabled={reviewContent.length < 3}
          isLoading={isLoading}
          onClick={handleSubmit}
        >
          {_t('history.rate_modal.submit')}
        </Button>
        <Button
          variant='outlinePrimary'
          flex={1}
          textTransform='uppercase'
          onClick={onClose}
        >
          {_t('history.rate_modal.review_later')}
        </Button>
      </Flex>
    </Modal>
  )
}

export default RatingModal

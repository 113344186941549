import Axios from 'axios'

import { profilePath } from '../configs/apiPath'
import { defaultFetcher } from './fetcher'
import { Role, UserProfile } from 'types'

export const updateProfile = (
  userId: string,
  profile: Omit<UserProfile, 'id'>
) => {
  return Axios.put(profilePath.profile(userId), profile)
}

export const getProfile = (userId: string): Promise<{ data: UserProfile }> => {
  return defaultFetcher(profilePath.profile(userId))
}

export const updateRole = (userId: string, role: string) => {
  return Axios.put(profilePath.submitRole(userId), {
    roleName: role,
  })
}

export const getRoles = (
  userId: string
): Promise<{
  data: {
    roles: Role[]
  }
}> => {
  return defaultFetcher(profilePath.getRoles(userId))
}

export const forgetPassword = (data: { email: string }) => {
  return Axios.post(profilePath.forgetPassword(), data, {
    headers: {
      'No-Need-Authorization': true,
    },
  })
}

export const forgetEmail = (data: { email: string; isResend?: boolean }) => {
  return Axios.post(
    profilePath.forgetEmail,
    { email: data.email },
    {
      headers: {
        'No-Need-Authorization': true,
      },
    }
  )
}

import { format } from 'date-fns'

type DateTime = number | Date | null

export const getDateOnly = (dateTime?: DateTime) => {
  return dateTime ? format(dateTime, 'dd/MM/yyyy') : ''
}

export const getTimeOnly = (dateTime?: DateTime) => {
  return dateTime ? format(dateTime, 'HH:mm:ss') : ''
}

export const getDateTime = (dateTime?: DateTime) => {
  return dateTime ? format(dateTime, 'dd/MM/yyyy, h:mma') : ''
}

import React from 'react'

import { Box, Flex, Image, Text } from '@chakra-ui/react'

import { useLanguages } from 'hooks'

import { ReactComponent as PlusIcon } from 'assets/images/add_rounded_icon.svg'
import AssignmentIllus from 'assets/images/assignments_illus.png'
import LessonIllus from 'assets/images/lesson_illus.png'
import PackageClassIllus from 'assets/images/package_class_illus.png'
import SingleClassIllus from 'assets/images/single_class_illus.png'

interface CreateCardProps {
  title: string
  description: string
  listItems: string[]
  onClick: () => void
}

const CreateCard: React.FC<CreateCardProps> = cardProps => {
  const { _t } = useLanguages()
  return (
    <Box
      cursor='pointer'
      mt={['1rem', '1rem', '2rem']}
      onClick={cardProps.onClick}
    >
      <Flex
        w='100%'
        h='100%'
        maxH='23.5rem'
        p={{ base: '2.5rem', md: '2.5rem 2.5rem 3.5rem 2.5rem' }}
        bg='#fff'
        boxShadow='lg'
        rounded='lg'
        flexDirection='column'
        alignItems='left'
      >
        <Flex w='100%' color='#7D89F4'>
          <PlusIcon />
          <Text textStyle='createTitle' ml='1rem' w='100%'>
            Create {cardProps.title}
          </Text>
        </Flex>
        <Flex
          w='100%'
          justifyContent='space-between'
          alignItems='center'
          mt='1.5rem'
        >
          {cardProps.title === 'Class' && (
            <Image
              w='10rem'
              h='auto'
              display={['none', 'none', 'none', 'block']}
              src={SingleClassIllus}
              alt={`${cardProps.title}-illus`}
            />
          )}
          {cardProps.title === 'Content' && (
            <Image
              w='10rem'
              h='auto'
              display={['none', 'none', 'none', 'block']}
              src={PackageClassIllus}
              alt={`${cardProps.title}-illus`}
            />
          )}
          {cardProps.title === 'Assignment' && (
            <Image
              w='10rem'
              h='auto'
              display={['none', 'none', 'none', 'block']}
              src={AssignmentIllus}
              alt={`${cardProps.title}-illus`}
            />
          )}
          {cardProps.title === 'Lesson' && (
            <Image
              w='10rem'
              h='auto'
              display={['none', 'none', 'none', 'block']}
              src={LessonIllus}
              alt={`${cardProps.title}-illus`}
            />
          )}
          <Text display={['none', 'none', 'block']} ml='1rem'>
            {_t(cardProps.description)}
          </Text>
          <Box display={['block', 'block', 'none']}>
            {cardProps.listItems.map(item => (
              <Text key={item} fontSize='1rem' pb='1rem'>
                {_t(item)}
              </Text>
            ))}
          </Box>
        </Flex>
      </Flex>
    </Box>
  )
}

export default CreateCard

import React, { SVGProps } from 'react'

const Birthday = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      fill='none'
      height={36}
      viewBox='0 0 33 36'
      width={33}
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M28.357 16.168H20.5V9.326h-2.571a6.08 6.08 0 001.218-3.675c0-1.97-.934-3.821-2.438-4.83L16.07.391l-.638.428c-1.504 1.01-2.438 2.86-2.438 4.83a6.08 6.08 0 001.218 3.676h-2.856v6.842H4.643a4.152 4.152 0 00-2.928 1.212A4.134 4.134 0 00.5 20.3v12.824c0 .605.242 1.184.67 1.612.429.427 1.01.668 1.616.669h27.428c.606-.001 1.187-.242 1.616-.67a2.28 2.28 0 00.67-1.611V20.3a4.133 4.133 0 00-1.215-2.921 4.152 4.152 0 00-2.928-1.212zM16.071 3.302c.499.619.79 1.458.79 2.349 0 .89-.291 1.73-.79 2.349-.5-.62-.79-1.459-.79-2.35 0-.89.29-1.73.79-2.348zm-2.428 8.305h4.571v4.56h-4.571zM2.786 20.3a1.853 1.853 0 011.857-1.853h23.714a1.86 1.86 0 011.857 1.853v2.25l-1.543.68a2.383 2.383 0 01-1.915 0l-2.685-1.182-2.685 1.182a2.381 2.381 0 01-1.915 0l-2.685-1.182L14.1 23.23a2.381 2.381 0 01-1.915 0L9.5 22.049 6.815 23.23a2.382 2.382 0 01-1.915 0l-2.114-.93zm27.428 12.824H2.786v-8.332l1.191.524a4.676 4.676 0 003.76 0l1.763-.775 1.763.775a4.676 4.676 0 003.76 0l1.763-.775 1.762.775a4.676 4.676 0 003.76 0l1.763-.775 1.763.776a4.678 4.678 0 003.76 0l.621-.274v8.08z'
        fill='currentColor'
      />
    </svg>
  )
}

export default Birthday

import { useMemo } from 'react'

import { useAtom } from 'jotai'

import { routeAtom, routeParamAtom } from '../atom'
import { ChangeRouteType, ChatNavigator } from '../types'

export const CHAT_LIST_PAGE = 'CHAT_LIST_PAGE'
export const NEW_CHAT_PAGE = 'NEW_CHAT_PAGE'
export const PRIVATE_CHAT_PAGE = 'PRIVATE_CHAT_PAGE'
export const GROUP_CHAT_PAGE = 'GROUP_CHAT_PAGE'
export const CREATE_GROUP_CHAT = 'CREATE_GROUP_CHAT'
export const EDIT_GROUP_CHAT = 'EDIT_GROUP_CHAT'
export const ADD_GROUP_MEMBER = 'ADD_GROUP_MEMBER'
export const EDIT_GROUP_MEMBER = 'EDIT_GROUP_MEMBER'
export const SEARCH_CHAT = 'SEARCH_CHAT'

export const useChatNavigator = () => {
  const [routes, setRoutes] = useAtom(routeAtom)
  const [routeParams, setParams] = useAtom(routeParamAtom)

  const currentRoute = useMemo(() => {
    return routes[routes.length - 1]
  }, [routes])

  const pushNewScreen = ({ routeName, params }: ChangeRouteType) => {
    setParams({ ...routeParams, [routeName]: params })
    setRoutes([...routes, routeName])
  }

  const updateParams = ({ routeName, params }: ChangeRouteType) => {
    setParams({ [routeName]: params })
  }

  const back = () => {
    const newRoutes = [...routes]
    const removeRoute: string | undefined = newRoutes.pop()

    setRoutes(newRoutes)
    setParams((old: any) => {
      const _params = { ...old }

      if (removeRoute) {
        delete _params[removeRoute]
      }

      return _params
    })
  }

  const pushAndRemoveUntil = ({ routeName, params }: ChangeRouteType) => {
    const newRoutes = [CHAT_LIST_PAGE, routeName]

    setParams({ ...routeParams, [routeName]: params })
    setRoutes([...newRoutes])
  }

  const reset = () => {
    setParams({})
    setRoutes([CHAT_LIST_PAGE])
  }

  const navigator: ChatNavigator = {
    pushNewScreen,
    pushAndRemoveUntil,
    back,
    reset,
  }

  return {
    navigator,
    routes,
    currentRoute,
    params: routeParams,
    updateParams,
  }
}

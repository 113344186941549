import React, { useMemo } from 'react'

import {
  Badge,
  Box,
  Button,
  Flex,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
} from '@chakra-ui/react'
import format from 'date-fns/format'

import { ActionDot, ConfirmAlert, ItemDetail, PopoverItem } from 'components'
import { DraftClass } from 'types'
import { parseClassType } from 'utils/parser'

import { ReactComponent as DuplicateIcon } from 'assets/images/duplicate_icon.svg'
import { ReactComponent as EyeIcon } from 'assets/images/eye_white.svg'
import { ReactComponent as TrashIcon } from 'assets/images/trash_white_icon.svg'

interface DraftCardProps {
  data: DraftClass
  onEditDraft: (id: string) => void
  onPreviewDraft: (id: string) => void
  onDeleteDraft: (classId: string) => void
  onDuplicateDraft: (classId: string) => void
  onPublish: (classId: string) => void
}

const DraftCard: React.FC<DraftCardProps> = ({
  data,
  onEditDraft,
  onDeleteDraft,
  onDuplicateDraft,
  onPublish,
  onPreviewDraft,
}) => {
  const classType = useMemo(() => {
    const { type } = data
    return parseClassType(type)
  }, [data])

  return (
    <Box
      rounded='lg'
      shadow='lg'
      p='1.5rem'
      display='flex'
      justifyContent='space-between'
      minHeight='3rem'
      flexDirection={{ base: 'column', md: 'row' }}
      bg='white'
    >
      <Flex flexDirection='column'>
        <Text
          fontSize='1rem'
          numberOfLines={{ base: '3', md: '2' }}
          mr={{ base: '0', lg: '5rem' }}
        >
          {data.name}
        </Text>
        <Text color='gray.300' fontSize='0.75rem' mb='1rem'>
          Last edited on: {format(data.updatedAt, 'dd/MM/yyyy OOOO h:mmaaa')}
        </Text>
        <Flex flexDirection='row'>
          <Text
            textStyle='detailTitle'
            color='gray.100'
            lineHeight='1.125rem'
            textTransform='uppercase'
            mr='1.5rem'
          >
            Type
          </Text>
          <Badge>{classType}</Badge>
        </Flex>
        {classType.includes('PACKAGE') && (
          <ItemDetail title='Sessions' text={`${data.totalSession} Sessions`} />
        )}
        <ItemDetail title='Price' text={`$${data.price}`} />
      </Flex>
      <Flex alignSelf='flex-end' justifyContent='flex-end' alignItems='center'>
        <Button
          size='md'
          variant='primary'
          onClick={() => onEditDraft(data.id)}
          mr='0.2rem'
        >
          EDIT
        </Button>
        <ConfirmAlert
          title='Do you want to publish?'
          submitTitle='Publish'
          description={
            <Text>
              On publish, it will be immediately available for learners to book.
            </Text>
          }
          onSubmit={() => onPublish(data.id)}
          immediatelyCloseOnSubmit
        >
          {({ showAlert }) => (
            <Button size='md' variant='outlinePrimary' onClick={showAlert}>
              PUBLISH
            </Button>
          )}
        </ConfirmAlert>
        <Popover>
          <PopoverTrigger>
            <Box ml='0.8rem'>
              <ActionDot />
            </Box>
          </PopoverTrigger>

          <PopoverContent
            w='13rem'
            bgColor='primaryDarker'
            color='#fff'
            _focus={{
              outline: 0,
            }}
          >
            <PopoverBody p='0'>
              <PopoverItem onClick={() => onPreviewDraft(data.id)}>
                <EyeIcon />
                <Box ml='1rem'>Preview</Box>
              </PopoverItem>
              <ConfirmAlert
                title='Do you want to duplicate it?'
                submitTitle='Duplicate'
                onSubmit={() => onDuplicateDraft(data.id)}
                immediatelyCloseOnSubmit
              >
                {({ showAlert }) => (
                  <PopoverItem onClick={showAlert}>
                    <DuplicateIcon />
                    <Box ml='1rem'>Duplicate</Box>
                  </PopoverItem>
                )}
              </ConfirmAlert>
              <ConfirmAlert
                description={
                  <Box textAlign='center'>This action cannot be undone</Box>
                }
                onSubmit={() => onDeleteDraft(data.id)}
                immediatelyCloseOnSubmit
              >
                {({ showAlert }) => (
                  <PopoverItem onClick={showAlert}>
                    <TrashIcon />
                    <Box ml='1rem'>Delete Draft</Box>
                  </PopoverItem>
                )}
              </ConfirmAlert>
            </PopoverBody>
          </PopoverContent>
        </Popover>
      </Flex>
    </Box>
  )
}

export default DraftCard

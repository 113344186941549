import { useCallback, useEffect } from 'react'

import useLoading from './useLoading'
import Auth from 'configs/auth'
import storageKey from 'configs/storageKey'
import { Login } from 'types'
import { getData } from 'utils/helper'

export default () => {
  const { setLoading } = useLoading()
  const loginType = getData(storageKey.loginType)

  const guestLogin = useCallback(async () => {
    setLoading(true)
    await Auth.signIn({ type: Login.Guest })
    setLoading(false)
  }, [setLoading])

  useEffect(() => {
    if (!loginType) {
      guestLogin()
    }
  }, [guestLogin, loginType])
}

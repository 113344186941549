import React, { useCallback, useEffect, useMemo, useState } from 'react'
import Linkify from 'react-linkify'
import { Link as ScrollLink } from 'react-scroll'

import {
  Box,
  Divider,
  Flex,
  Link,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  TabProps,
  Tabs,
  Text,
} from '@chakra-ui/react'
import { useAtom } from 'jotai'

import InstructorSection from './InstructorSection'
import LocationSection from './LocationSection'
import MaterialSection from './MaterialSection'
import OverviewSection from './OverviewSection'
import PackageSection from './PackageSection'
import SessionSection from './SessionSection'
import StickyAction from './StickyAction'
import { LoadingSpinner } from 'components'
import PreviewLearnerInClass from 'components/PreviewLearnerInClass'
import { SwitchAccountModal } from 'containers/SearchResult/components'
import {
  useCenterId,
  useLanguages,
  useUserId,
  useUtilsForEnterpriseId,
} from 'hooks'
import { useUserProfile } from 'queries/user'
import { userProfileAtom } from 'store/authAtom'
import {
  AttachmentsType,
  ClassDetailItem,
  ClassPreview,
  MeetingType,
  UserRole,
} from 'types'
import {
  isContentClassType,
  isEnterprise,
  isInstructor,
  isLessonClassType,
} from 'utils/helper'
import { getUpcomingSession } from 'utils/parser'

export const tabClassStyle: TabProps = {
  color: 'rgba(0, 0, 0, 0.4)',
  px: '0',
  mr: '1rem',
  borderBottom: '2px solid',
  borderBottomColor: 'transparent',
  _hover: { color: 'primary' },
  _selected: {
    color: 'primary',
    borderBottomColor: 'primary',
  },
}

export const customNavStyle: any = {
  color: 'gray.300',
  px: '1rem',
  py: '0.2rem',
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  position: 'relative',
  _hover: {
    color: 'white',
    bg: 'primary',
    rounded: 'full',
  },
}

interface PreviewClassProps {
  previewBanner?: string
  data: ClassPreview | undefined
  isPreview?: boolean
  userId: string
  attachments: AttachmentsType[]
  sessionData?: ClassDetailItem[]
  onBuyClass?: (data: ClassPreview) => void
  onCancelClass?: () => void
  onJoinSession?: (data: { classId: string; sessionId: string }) => void
  onEditClass?: () => void
  topElement?: React.ReactElement
}

const PreviewClass = ({
  previewBanner,
  data,
  userId,
  attachments = [],
  sessionData,
  onBuyClass,
  onCancelClass,
  onJoinSession,
  onEditClass,
  topElement,
  isPreview,
}: PreviewClassProps) => {
  const { _t } = useLanguages()
  const [modalOpen, setModalOpen] = useState(false)
  const { data: tutorDetail, isSuccess } = useUserProfile(userId)
  const personalId = useUserId()
  const canEdit = personalId === tutorDetail?.data.id
  const [userProfile] = useAtom(userProfileAtom)
  const centerId = useCenterId()
  const { enterpriseId } = useUtilsForEnterpriseId()
  const [actionPanel, setActionPanel] = useState(false)

  useEffect(() => {
    window.onscroll = () => {
      if (window.pageYOffset >= 400 && !actionPanel) {
        setActionPanel(true)
      } else if (window.pageYOffset < 400 && actionPanel) {
        setActionPanel(false)
      }
    }
    return () => ((window.onscroll = null) as unknown) as undefined
  })

  const isMultiSessionMemo = useMemo(() => {
    if (data?.sessions) {
      return data?.sessions?.length > 1 ? true : false
    }
    return false
  }, [data])
  const isContentClassMemo = useMemo(() => isContentClassType(data?.type), [
    data,
  ])
  const isLessonClassMemo = useMemo(() => isLessonClassType(data?.type), [data])
  const isVirtual = useMemo(() => {
    const upcoming = getUpcomingSession(data?.sessions || [])
    if (!upcoming) return false
    return upcoming.meetingType !== MeetingType.PHYSICAL
  }, [data])

  const isEnterpriseMemo = useMemo(() => isEnterprise(userProfile), [
    userProfile,
  ])
  const isInstructorMemo = useMemo(() => isInstructor(userProfile), [
    userProfile,
  ])

  const aboutTitle = useMemo(() => {
    if (isLessonClassMemo) return _t('product.lesson.about')
    else if (isContentClassMemo) return _t('product.content.about')
    else return _t('product.class.about')
  }, [_t, isContentClassMemo, isLessonClassMemo])

  const isAdminShow = useMemo(() => {
    const isMstAdmin =
      userProfile?.accountType?.name === UserRole.MSTSuperAdmin ||
      userProfile?.accountType?.name === UserRole.MSTAdmin

    if (tutorDetail?.data.accountType?.name === UserRole.Tutor) {
      if (isMstAdmin || canEdit) {
        return true
      }
      return false
    } else {
      const isEnterpriseAdminRole =
        userProfile?.accountType?.name === UserRole.EnterpriseAdmin ||
        userProfile?.accountType?.name === UserRole.EnterpriseSuperAdmin
      const isCenterAdmin =
        userProfile?.accountType?.name === UserRole.CenterAdmin &&
        centerId &&
        centerId === tutorDetail?.data?.centers?.[0].id

      if (isMstAdmin || canEdit) {
        return true
      }
      // check if enterprise or centre admin is the one the enterprise instructor belongs to
      if (
        isEnterpriseAdminRole &&
        enterpriseId &&
        enterpriseId === tutorDetail?.data?.enterprises?.[0].id
      ) {
        return true
      }
      if (isCenterAdmin) {
        return true
      }
      return false
    }
  }, [canEdit, centerId, enterpriseId, tutorDetail, userProfile])

  const isShowMaterial = useMemo(() => {
    return isAdminShow || data?.purchased
  }, [isAdminShow, data])

  const classPrice = useMemo(() => {
    if (data?.tutorTypes?.[0].name?.toLowerCase().includes('private')) {
      return data?.price || 0
    } else {
      return (isEnterpriseMemo ? data?.enterprisePrice : data?.price) || 0
    }
  }, [data, isEnterpriseMemo])

  const usualClassPrice = useMemo(() => {
    if (data?.tutorTypes?.[0].name?.toLowerCase().includes('private')) {
      return data?.usualPrice
    } else {
      return isEnterpriseMemo ? data?.usualEnterprisePrice : data?.usualPrice
    }
  }, [data, isEnterpriseMemo])

  const handleBuyClass = useCallback(() => {
    if (isInstructorMemo) {
      setModalOpen(true)
      return
    }

    if (data?.id && onBuyClass) {
      onBuyClass(data)
    }
  }, [data, isInstructorMemo, onBuyClass])

  const handleCancelModal = () => {
    setModalOpen(false)
  }

  const handleJoinSession = useCallback(() => {
    const upcoming = getUpcomingSession(data?.sessions || [])
    if (!upcoming) return

    onJoinSession?.({ classId: data?.id || '', sessionId: upcoming?.id || '' })
  }, [data, onJoinSession])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const componentDecorator = (
    href: string | undefined,
    text:
      | boolean
      | React.ReactChild
      | React.ReactFragment
      | React.ReactPortal
      | null
      | undefined,
    key: React.Key | null | undefined
  ) => (
    <Link href={href} key={key} textColor='primary' isExternal>
      {text}
    </Link>
  )

  const renderMainContent = useCallback(() => {
    return (
      <Flex>
        <Flex
          direction='column'
          w='full'
          flex='1'
          mr={{ base: '0', lg: '2rem' }}
        >
          <Flex overflowX='auto'>
            <Box {...customNavStyle}>
              <ScrollLink to='details' spy={true} smooth={true} duration={250}>
                {_t('product.class.details')}
              </ScrollLink>
            </Box>
            {isLessonClassMemo && (
              <Box {...customNavStyle}>
                <ScrollLink
                  to='packages'
                  spy={true}
                  smooth={true}
                  duration={250}
                >
                  {_t('product.class.packages')}
                </ScrollLink>
              </Box>
            )}
            <Box {...customNavStyle}>
              <ScrollLink
                to='instructors'
                spy={true}
                smooth={true}
                duration={250}
              >
                {_t('product.class.instructors')}
              </ScrollLink>
            </Box>
            {isMultiSessionMemo && (
              <Box {...customNavStyle}>
                <ScrollLink
                  to='sessions'
                  spy={true}
                  smooth={true}
                  duration={250}
                >
                  {_t('product.class.sessions')}
                </ScrollLink>
              </Box>
            )}
            {/* TODO: enable review section once ready
              <Box {...customNavStyle}>
                <ScrollLink to='reviews' spy={true} smooth={true} duration={250}>Reviews</ScrollLink>
              </Box>
            */}
            {isShowMaterial && (
              <Box {...customNavStyle}>
                <ScrollLink
                  to='materials'
                  spy={true}
                  smooth={true}
                  duration={250}
                >
                  {_t('product.class.materials')}
                </ScrollLink>
              </Box>
            )}
          </Flex>
          <Box id='details' position='relative' top='-4rem'></Box>
          <Box display={{ base: 'inline', lg: 'none' }}>
            <Box my='2rem'>
              <OverviewSection
                data={data}
                isPreview={isPreview}
                previewBanner={previewBanner}
                pricing={classPrice}
                usualPricing={usualClassPrice}
                instructor={tutorDetail?.data}
                onJoinSession={handleJoinSession}
                onEditSession={onEditClass}
                onCancelSession={onCancelClass}
                onBuySession={handleBuyClass}
                isVirtual={isVirtual}
              />
            </Box>
            <Divider />
          </Box>
          <Box my='2rem'>
            <Text as='h2' mb='1rem'>
              {aboutTitle}
            </Text>
            <Linkify componentDecorator={componentDecorator}>
              <Text fontSize='0.75rem' whiteSpace='pre-wrap'>
                {data?.description}
              </Text>
            </Linkify>
          </Box>
          {isLessonClassMemo && data?.packages && (
            <>
              <Divider />
              <Box id='packages' position='relative' top='-4rem'></Box>
              <PackageSection
                data={data?.packages}
                instructorId={data?.user?.id || ''}
              />
            </>
          )}
          {!isMultiSessionMemo &&
            !isLessonClassMemo &&
            !isContentClassMemo &&
            data?.sessions &&
            data?.sessions?.length > 0 && (
              <>
                <Divider />
                <LocationSection
                  classType={data?.type}
                  meetingType={data?.sessions?.[0].meetingType}
                  meetingLocation={data?.sessions?.[0].meetingLocation}
                />
              </>
            )}
          <Divider />
          <Box id='instructors' position='relative' top='-4rem' />
          <InstructorSection instructor={tutorDetail?.data} />
          {isMultiSessionMemo && (
            <>
              <Divider />
              <Box id='sessions' position='relative' top='-4rem'></Box>
              <SessionSection classId={data?.id} sessions={sessionData} />
            </>
          )}
          {/* TODO: enable review section once ready
            <Divider />
            <Box id='reviews' position='relative' top='-4rem'></Box>
            <ReviewSection instructorId={tutorDetail?.data?.id} instructorRating={tutorDetail?.data?.rating} type={'tutor'} />
          */}
          {isShowMaterial && (
            <>
              <Divider />
              <Box id='materials' position='relative' top='-4rem'></Box>
              <MaterialSection
                attachments={attachments}
                materials={data?.materials}
                classId={data?.id}
              />
            </>
          )}
        </Flex>
        <Box w='20rem' display={{ base: 'none', lg: 'block' }}>
          <Box
            top='3rem'
            position='absolute'
            rounded='lg'
            boxShadow='lg'
            bg='white'
            w='20rem'
          >
            <OverviewSection
              data={data}
              isPreview={isPreview}
              previewBanner={previewBanner}
              attachments={attachments}
              pricing={classPrice}
              usualPricing={usualClassPrice}
              instructor={tutorDetail?.data}
              onJoinSession={handleJoinSession}
              onEditSession={onEditClass}
              onCancelSession={onCancelClass}
              onBuySession={handleBuyClass}
              isVirtual={isVirtual}
            />
          </Box>
        </Box>
      </Flex>
    )
  }, [
    _t,
    isLessonClassMemo,
    isMultiSessionMemo,
    isShowMaterial,
    data,
    isPreview,
    previewBanner,
    classPrice,
    usualClassPrice,
    tutorDetail,
    handleJoinSession,
    onEditClass,
    onCancelClass,
    handleBuyClass,
    isVirtual,
    aboutTitle,
    isContentClassMemo,
    sessionData,
    attachments,
  ])

  return (
    <>
      {!isSuccess && <LoadingSpinner />}
      {isSuccess && (
        <Box position='relative'>
          <Flex
            py='2rem'
            px={['2rem', '5rem', '5rem', '10rem']}
            minH='15rem'
            bg='secondaryLightBlue'
            justify='space-between'
          >
            <Flex direction='column' flex='1' mr={{ base: '0', lg: '2rem' }}>
              <Box>{topElement}</Box>
              <Box flex='1'>
                {data?.id && (
                  <Text fontSize='0.75rem'>
                    {`${_t('product.class.id')}: ${data?.id}`}
                  </Text>
                )}
                <Text
                  lineHeight={{ base: '1.6rem', md: '2.5rem' }}
                  fontSize={{ base: '1.25rem', md: '2rem' }}
                  fontWeight='500'
                  mb='0.5rem'
                  noOfLines={{ base: 3, md: 2 }}
                >
                  {data?.name}
                </Text>
                <Text
                  fontSize='0.75rem'
                  color='gray.500'
                  mb='0.5rem'
                  noOfLines={2}
                  whiteSpace='pre-wrap'
                >
                  {data?.description}
                </Text>
              </Box>
              {data?.center && (
                <>
                  <Text fontSize='0.75rem' color='gray.500'>
                    {`${_t('product.class.offered_by')}:`}
                  </Text>
                  <Text as='h3'>{data?.center?.name}</Text>
                </>
              )}
            </Flex>
            <Box w='20rem' display={{ base: 'none', lg: 'block' }}></Box>
          </Flex>

          <Tabs variant='unstyled'>
            <TabList overflowX='auto' mx={['2rem', '5rem', '5rem', '10rem']}>
              <Tab {...tabClassStyle}>{_t('common.about')}</Tab>
              {isAdminShow && !isLessonClassMemo && (
                <Tab {...tabClassStyle}>{_t('common.learners')}</Tab>
              )}
            </TabList>

            <TabPanels
              bg='white'
              minH='85vh'
              px={['2rem', '5rem', '5rem', '10rem']}
            >
              <TabPanel>{renderMainContent()}</TabPanel>
              {isAdminShow && !isLessonClassMemo && (
                <TabPanel px='0'>
                  <PreviewLearnerInClass data={data} />
                </TabPanel>
              )}
            </TabPanels>
          </Tabs>

          <Box
            position='sticky'
            bottom='0'
            py='1rem'
            px={['2rem', '5rem', '5rem', '10rem']}
            minH='3rem'
            bg='secondaryLightBlue'
            display={actionPanel ? 'inherit' : 'none'}
          >
            <StickyAction
              data={data}
              pricing={classPrice}
              usualPricing={usualClassPrice}
              instructor={tutorDetail?.data}
              onJoinSession={handleJoinSession}
              onBuySession={handleBuyClass}
              isVirtual={isVirtual}
            />
          </Box>

          <SwitchAccountModal
            isOpen={modalOpen}
            description={_t('product.class.switch_account_description')}
            onClickCancel={handleCancelModal}
          />
        </Box>
      )}
    </>
  )
}

export default PreviewClass

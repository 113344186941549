import React, { useCallback, useMemo, useState } from 'react'
import { Navigate } from 'react-router-dom'

import { Box, Flex } from '@chakra-ui/react'
import { useAtom } from 'jotai'

import { Order, PaymentMethod, Summary } from '../containers'
import { useLanguages, useUserId, useUtilsForEnterpriseId } from 'hooks'
import { useUserWallet } from 'queries/wallet'
import { userProfileAtom } from 'store/authAtom'
import { Method, UserRole } from 'types'
import { isEnterpriseAdmin, isInstructor } from 'utils/helper'

interface PaymentCheckoutProps {}

const PaymentCheckout: React.FC<PaymentCheckoutProps> = () => {
  const userId = useUserId()
  const { _t } = useLanguages()
  const { enterpriseId } = useUtilsForEnterpriseId()
  const [userProfile] = useAtom(userProfileAtom)
  const [payMethod, setPayMethod] = useState<Method>('credit')
  const [cardDetail, setCardDetail] = useState<any>()
  const userType = useMemo(() => {
    if (isEnterpriseAdmin(userProfile)) {
      return 'enterprise'
    } else if (isInstructor(userProfile)) {
      return 'privateTutor'
    }
    return 'student'
  }, [userProfile])

  const { data: walletInfo } = useUserWallet({
    type: userType === 'enterprise' ? userType : 'user',
    id: userType === 'enterprise' && enterpriseId ? enterpriseId : userId,
  })

  const handleChangePayMethod = useCallback((value: Method) => {
    setPayMethod(value)
  }, [])

  const handleChangeCardDetail = useCallback((value: any) => {
    setCardDetail(value)
  }, [])

  if (
    userProfile &&
    userProfile.accountType?.name === UserRole.EnterpriseTutor
  ) {
    return <Navigate to='/' />
  }

  return (
    <Box py='2rem'>
      <Flex direction={{ base: 'column', lg: 'row' }}>
        <Box flex={1}>
          <Box>
            <Order footerText={_t('payment.checkout.footer_text')} />
          </Box>
          <Box mt='1rem'>
            <PaymentMethod
              walletInfo={walletInfo}
              paymentOption={'class'}
              paymentMethod={['credit', 'newCard', 'savedCard', 'gcash']}
              payMethod={payMethod}
              handleChangePayMethod={handleChangePayMethod}
              handleChangeCardDetail={handleChangeCardDetail}
            />
          </Box>
        </Box>
        <Box ml={{ base: '0', lg: '1rem' }} mt={{ base: '1rem', lg: '0' }}>
          <Summary
            paymentOption={'class'}
            payMethod={payMethod}
            cardDetail={cardDetail}
            userType={userType}
            allowPromo
          />
        </Box>
      </Flex>
    </Box>
  )
}

export default PaymentCheckout

import React from 'react'

import { Box, Flex, Text, useDisclosure } from '@chakra-ui/react'

import { useLanguages } from 'hooks'

type FilterBoxInterface = {
  children: JSX.Element
}

export const FilterBox = (props: FilterBoxInterface) => {
  const { _t } = useLanguages()
  const { children } = props
  const { isOpen, onToggle } = useDisclosure()

  return (
    <>
      <Flex background='rgba(255, 255, 255, 0.6)'>
        <Flex
          flex='1'
          direction='row'
          alignItems='center'
          justify='space-between'
          height='70px'
          p='1rem 1.875rem'
        >
          <Text as='h2' fontWeight='600' fontSize='1.125rem'>
            {_t('search.filter.title')}
          </Text>

          <Text
            as='span'
            onClick={onToggle}
            color='#3959BF'
            fontSize='0.875rem'
            fontWeight='600'
            textDecoration='underline'
            cursor='pointer'
          >
            {_t('search.filter.options')}
          </Text>
        </Flex>
      </Flex>
      <Box
        pl='1.875rem'
        pr='1.875rem'
        pb='2.375rem'
        background='rgba(255, 255, 255, 0.6)'
        display={isOpen ? 'block' : 'none'}
      >
        {children}
      </Box>
    </>
  )
}

import React from 'react'

import { Box } from '@chakra-ui/react'

import { useReadAllNotification } from 'queries/notification'

interface ReadAllBtnProps {
  popup?: boolean
}

const ReadAllNotifications: React.FC<ReadAllBtnProps> = ({ popup = false }) => {
  const { mutate: readAllNotification } = useReadAllNotification()

  return (
    <Box
      cursor='pointer'
      color='black'
      fontSize={popup ? '0.875rem' : '1rem'}
      textAlign={popup ? 'right' : 'left'}
      onClick={() => readAllNotification()}
    >
      Mark all as read
    </Box>
  )
}

export default ReadAllNotifications

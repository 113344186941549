import React, { useEffect } from 'react'
import { Calendar, dateFnsLocalizer, View } from 'react-big-calendar'
import 'react-big-calendar/lib/css/react-big-calendar.css'

import { Box, Text } from '@chakra-ui/react'
import endOfDay from 'date-fns/endOfDay'
import format from 'date-fns/format'
import getDay from 'date-fns/getDay'
import parse from 'date-fns/parse'
import startOfDay from 'date-fns/startOfDay'

import { Period } from 'components/Calendar'

import '../FullCalendar/calendar.css'

const locales = {
  'en-US': require('date-fns/locale/en-US'),
}

interface DayCalendarProps {
  onChangePeriod?: (newPeriod: Period) => void
  events?: Event[]
  isTutor?: boolean
  views?: View[]
}

const localizer = dateFnsLocalizer({
  format,
  parse,
  getDay,
  locales,
})

export interface Event {
  id: number
  title?: string
  description?: string
  color?: string
  type?: string
  start: Date
  end: Date
}

const EventBox = ({ event }: { event: Event }) => {
  return (
    <Box
      backgroundColor={event.color || 'primary'}
      padding='1rem'
      width='100%'
      height='100%'
      borderRadius='0.25rem'
    >
      <Text fontSize='1rem' fontWeight='700' mb='0.25rem' isTruncated>
        {event.title}
      </Text>
      <Text fontSize='1rem' fontWeight='400' isTruncated>
        {event.description}
      </Text>
    </Box>
  )
}

const DayCalendar = ({
  onChangePeriod,
  events = [],
  views = ['day'],
}: DayCalendarProps) => {
  const today = new Date()

  const handleNavigate = (day: any) => {
    const date = new Date(day)

    onChangePeriod?.({
      from: startOfDay(date),
      to: endOfDay(date),
    })
  }

  useEffect(() => {
    const date = new Date()

    onChangePeriod?.({
      from: startOfDay(date),
      to: endOfDay(date),
    })
  }, [onChangePeriod])

  return (
    <Box height='80vh' width='100%' backgroundColor='white' color='black'>
      <Calendar
        views={views}
        events={events.map(event => ({
          ...event,
          color: 'primary',
        }))}
        step={60}
        timeslots={1}
        min={
          new Date(today.getFullYear(), today.getMonth(), today.getDate(), 6)
        }
        defaultDate={new Date()}
        localizer={localizer}
        defaultView='day'
        components={{
          event: EventBox,
        }}
        startAccessor='start'
        endAccessor='end'
        onNavigate={handleNavigate}
      />
    </Box>
  )
}

export default DayCalendar

import React, { useCallback, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { Box, Button, Link, Text, useDisclosure } from '@chakra-ui/react'
import qs from 'query-string'

import {
  AuthRoleSelection,
  ConfirmGuestModal,
  LayoutAuth,
  SignInForm,
  SignInFormInput,
  SocialSignInOptions,
  UpdatePasswordModal,
} from 'components'
import Auth from 'configs/auth'
import storageKey from 'configs/storageKey'
import { useLanguages, useLoading, useRedirectPath } from 'hooks'
import { Login, UserRole, UserRoleType } from 'types'
import { getErrorMessage } from 'utils/parser'

interface SignInProps {}

const SignIn: React.FC<SignInProps> = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { setLoading } = useLoading()
  const { _t } = useLanguages()
  const [role, setRole] = useState<UserRoleType>(
    (localStorage.getItem(storageKey.role) as UserRoleType) ||
      (localStorage.getItem(storageKey.lastRole) as UserRoleType) ||
      UserRole.Student
  )
  const [error, setError] = useState('')
  const [shouldUpdatePassword, setShouldUpdatePassword] = useState('')
  const [signInInfo, setSignInInfo] = useState<any>()
  const {
    isOpen: isOpenConfirmGuest,
    onOpen: onOpenConfirmGuest,
    onClose: onCloseConfirmGuest,
  } = useDisclosure()
  const redirectPath = useRedirectPath(false)

  const onChangeRole = useCallback(newRole => {
    setRole(newRole)
    localStorage.setItem(storageKey.role, newRole)
  }, [])

  const onSignIn = async (values: SignInFormInput) => {
    setError('')
    try {
      setLoading(true)
      const { email, password } = values

      localStorage.setItem(storageKey.lastRole, role)

      const signIn = await Auth.signIn({
        email,
        password,
      })

      if (signIn === 'wrongRole') {
        setError('Account does not exist or the selected role is incorrect')
      } else {
        setSignInInfo(signIn)
        if (signIn.session) {
          setShouldUpdatePassword(password)
        } else {
          const locationState = location.state as any
          navigate(locationState?.from || '/', { replace: true })
        }
      }
    } catch (err) {
      setError(_t(`message.error.${getErrorMessage(err)}`))
    } finally {
      setLoading(false)
    }
  }
  const handleCloseUpdatePassword = useCallback(() => {
    setShouldUpdatePassword('')
  }, [])

  const handleUpdateNewPassword = useCallback(
    async (password: string) => {
      setError('')
      try {
        setLoading(true)
        await Auth.changePassword({
          email: signInInfo?.email,
          session: signInInfo.session,
          password,
        })
        setShouldUpdatePassword('')
      } catch (err) {
        setError(getErrorMessage(err))
      } finally {
        setLoading(false)
      }
    },
    [setLoading, signInInfo]
  )

  const onSocialLogin = async ({
    provider,
    customOptions,
  }: SocialSignInOptions) => {
    localStorage.setItem(storageKey.lastRole, role)

    const scopes = (process.env.REACT_APP_OAUTH_SCOPE || '')
      .split(',')
      .map(scope => scope.trim())
      .join('+')
    const url = `${process.env.REACT_APP_OAUTH_DOMAIN}/oauth2/authorize?identity_provider=${provider}&redirect_uri=${process.env.REACT_APP_OAUTH_REDIRECT_SIGNIN}&response_type=code&client_id=${process.env.REACT_APP_AWS_USER_POOLS_CLIENT_ID}&scope=${scopes}`

    window.location.href = url
  }

  const goSignUp = () => {
    if (redirectPath) {
      navigate(`/sign-up?redirectPath=${redirectPath}`)
      return
    }

    navigate('/sign-up')
  }

  const handleNav = () => {
    navigate('/games')
  }

  const handleForgotPassword = () => {
    navigate('/forgot-password')
  }

  const guestLogin = async () => {
    navigate('/explore')
  }

  const handleSocialLogin = useCallback(
    async code => {
      setError('')

      try {
        setLoading(true)

        const roleName = localStorage.getItem(storageKey.lastRole) || ''

        const signIn = await Auth.signIn({
          code,
          type: Login.Social,
          roleName,
        })

        if (signIn === 'wrongRole') {
          setError('Account does not exist or the selected role is incorrect')
        }
      } catch (err) {
        setError(_t(`message.error.${getErrorMessage(err)}`))
      } finally {
        setLoading(false)
      }
    },
    [_t, setLoading]
  )

  useEffect(() => {
    const { code } = qs.parse(window.location.search)

    if (code) {
      handleSocialLogin(code)
    }
  }, [handleSocialLogin])

  return (
    <>
      <LayoutAuth>
        <AuthRoleSelection
          heading={_t('auth.login.title')}
          onChange={onChangeRole}
          initRole={role}
        />
        <SignInForm
          role={role}
          error={error}
          onSubmit={onSignIn}
          onSocialSignIn={onSocialLogin}
          loading={false}
          onForgot={handleForgotPassword}
        />
        <Box textAlign='center' mt='1rem' px='2rem'>
          <Text fontSize='0.875rem' fontWeight='600'>
            {_t('auth.login.no_account')}{' '}
            <Text
              as='span'
              fontWeight='bold'
              cursor='pointer'
              color='primary'
              textDecoration='underline'
              onClick={onOpenConfirmGuest}
            >
              {_t('auth.login.explore_as_guest')}
            </Text>{' '}
            {_t('common.or')}
          </Text>
          <Button
            variant='outlinePrimary'
            mt='1rem'
            width='100%'
            onClick={goSignUp}
            textTransform='uppercase'
          >
            {_t('auth.login.sign_up_now')}
          </Button>
        </Box>
        <Box textAlign='center' my='1rem'>
          <Text fontSize='0.875rem'>
            <Link
              fontWeight='bold'
              textColor='primary'
              href='https://www.knovo.io'
              isExternal
            >
              {_t('common.knovo')}
            </Link>
            {''} {_t('auth.login.footer_description')}
          </Text>
        </Box>
      </LayoutAuth>

      <UpdatePasswordModal
        isOpen={!!shouldUpdatePassword}
        onClose={handleCloseUpdatePassword}
        oldPassword={shouldUpdatePassword}
        onSubmit={handleUpdateNewPassword}
      />

      <ConfirmGuestModal
        isOpen={isOpenConfirmGuest}
        onClose={onCloseConfirmGuest}
        onConfirm={handleNav}
        onCancel={guestLogin}
      />
    </>
  )
}

export default SignIn

import { useMutation, useQuery } from 'react-query'

import { useToastError } from './helpers'
import { createWithdrawal, updateWithdrawal } from 'apis/transaction'
import { transactionPath } from 'configs/apiPath'
import { useLanguages, useLoading, useToast } from 'hooks'
import {
  ListingParams,
  TransactionAdminExportRequest,
  TransactionAdminExportResponse,
  TransactionAdminInfoRequest,
  TransactionAdminInfoResponse,
  TransactionAdminListResponse,
  TransactionApprovalListRequest,
  TransactionApprovalListResponse,
  TransactionInfoRequest,
  TransactionInfoResponse,
  TransactionListRequest,
  TransactionListResponse,
} from 'types'

export const useTransactions = (
  { type, id }: TransactionListRequest,
  params: ListingParams
) => {
  const options = { enabled: !!id }
  return useQuery<TransactionListResponse>(
    transactionPath.list({ type, id }, params),
    options
  )
}

export const useTransaction = ({
  type,
  id,
  transactionId,
}: TransactionInfoRequest) => {
  const options = { enabled: !!id }
  return useQuery<TransactionInfoResponse>(
    transactionPath.show({ type, id, transactionId }),
    options
  )
}

export const useAdminTransactions = (params: ListingParams) => {
  return useQuery<TransactionAdminListResponse>(
    transactionPath.adminList(params)
  )
}

export const useAdminTransaction = ({
  transactionId,
}: TransactionAdminInfoRequest) => {
  return useQuery<TransactionAdminInfoResponse>(
    transactionPath.adminShow({ transactionId })
  )
}

export const useAdminTransactionExport = (
  params: TransactionAdminExportRequest
) => {
  const options = { enabled: false }
  return useQuery<TransactionAdminExportResponse>(
    transactionPath.adminExport(params),
    options
  )
}

export const useAdminCreateWithdrawal = () => {
  const toast = useToast()
  const { setLoading } = useLoading()
  const { _t } = useLanguages()

  return useMutation(createWithdrawal, {
    onMutate: () => setLoading(true),
    onSettled: () => setLoading(false),
    onSuccess: (_, { onClose }) => {
      !!onClose && onClose()
      toast({
        title: 'Success!',
        description: _t('message.success.withdrawal_create'),
        status: 'success',
      })
    },
    onError: useToastError(),
  })
}

export const useAdminUpdateWithdrawal = () => {
  const toast = useToast()
  const { setLoading } = useLoading()
  const { _t } = useLanguages()

  return useMutation(updateWithdrawal, {
    onMutate: () => setLoading(true),
    onSettled: () => setLoading(false),
    onSuccess: (_, { onClose }) => {
      !!onClose && onClose()
      toast({
        title: 'Success!',
        description: _t('message.success.withdrawal_update'),
        status: 'success',
      })
    },
    onError: useToastError(),
  })
}

export const useTransactionApprovals = (
  { type, id }: TransactionApprovalListRequest,
  params: ListingParams
) => {
  const options = { enabled: !!id }
  return useQuery<TransactionApprovalListResponse>(
    transactionPath.approvalList({ type, id }, params),
    options
  )
}

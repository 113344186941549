import React, { useCallback, useMemo, useState } from 'react'

import { Box, Divider, Flex, SimpleGrid, Text } from '@chakra-ui/react'

import {
  DowngradeSubscriptionModal,
  LoadingSpinner,
  PaySubscriptionModal,
  SubscriptionCard,
} from 'components'
import { useLanguages, useUserId } from 'hooks'
import { useSubscription, useSubscriptionPackages } from 'queries/subscription'

export interface SubscriptionContainerProps {
  type: 'privateTutor' | 'enterprise'
  id: string // it will be enterpriseId when type = enterprise, userId when type = privateTutor
}

const SubscriptionContainer: React.FC<SubscriptionContainerProps> = ({
  type,
  id,
}) => {
  const { _t } = useLanguages()
  const userId = useUserId()
  const [openSubscriptionModal, setOpenSubscriptionModal] = useState(false)
  const [
    openDowngradeSubscriptionModal,
    setOpenDowngradeSubscriptionModal,
  ] = useState(false)
  const [changedPlanData, setChangedPlanData] = useState({
    cancelSubscriptionId: '',
    createPackageId: '',
    createPackagePrice: 0,
  })

  const { data: subscriptionPackages, isLoading } = useSubscriptionPackages({
    type,
    ...(type === 'enterprise' && { enterpriseId: id }),
  })
  const { data: subscriptionInfo } = useSubscription({
    userId,
    ...(type === 'enterprise' && { enterpriseId: id }),
  })

  const sortedPackagesData = useMemo(
    () => subscriptionPackages?.data.sort((a, b) => a.index - b.index) || [],
    [subscriptionPackages]
  )
  const currentSubscriptionName = useMemo(
    () =>
      sortedPackagesData.find(
        subscription => subscription.id === subscriptionInfo?.data.id
      )?.name,
    [sortedPackagesData, subscriptionInfo]
  )

  const handleChangePlan = (
    cancelSubscriptionId: string,
    createPackageId: string,
    createPackagePrice: number
  ) => {
    setChangedPlanData({
      cancelSubscriptionId,
      createPackageId,
      createPackagePrice,
    })
  }

  const handleDowngrade = useCallback(
    (subscriptionId?: string, packagePrice: number = 0) => {
      if (subscriptionId) {
        const currentSubscriptionId = subscriptionInfo?.data.id || ''

        handleChangePlan(currentSubscriptionId, subscriptionId, packagePrice)
        setOpenDowngradeSubscriptionModal(true)
      }
    },
    [subscriptionInfo]
  )

  const handleUpgrade = useCallback(
    (subscriptionId?: string, packagePrice?: number) => {
      if (subscriptionId && packagePrice) {
        const currentSubscriptionId = subscriptionInfo?.data.id || ''

        handleChangePlan(currentSubscriptionId, subscriptionId, packagePrice)
        setOpenSubscriptionModal(true)
      }
    },
    [subscriptionInfo]
  )

  return (
    <Box>
      <PaySubscriptionModal
        type={type}
        id={id}
        packagePrice={changedPlanData.createPackagePrice}
        cancelSubscriptionId={changedPlanData.cancelSubscriptionId}
        createPackageId={changedPlanData.createPackageId}
        isOpen={openSubscriptionModal}
        onClose={() => setOpenSubscriptionModal(false)}
      />
      <DowngradeSubscriptionModal
        type={type}
        id={id}
        cancelSubscriptionId={changedPlanData.cancelSubscriptionId}
        createPackageId={changedPlanData.createPackageId}
        isOpen={openDowngradeSubscriptionModal}
        onClose={() => setOpenDowngradeSubscriptionModal(false)}
      />
      <Flex justify='space-between' direction={{ base: 'column', md: 'row' }}>
        <Flex justifyContent='space-between' flex='1'>
          <Box>
            <Flex alignItems='center'>
              <Text fontSize='1.5rem' fontWeight='600' mr='0.5rem'>
                {_t('subscription.title')}
              </Text>
            </Flex>
            <Text fontSize='2rem'>{currentSubscriptionName || '-'}</Text>
          </Box>
        </Flex>
      </Flex>
      <Divider my='2rem' />
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <SimpleGrid columns={[1, 1, 1, 2]} spacing='3rem'>
          <SubscriptionCard type={type} data={sortedPackagesData[0]} />
          <SubscriptionCard
            type={type}
            data={sortedPackagesData[1]}
            onUpgrade={handleUpgrade}
            onDowngrade={handleDowngrade}
          />
        </SimpleGrid>
      )}
    </Box>
  )
}

export default SubscriptionContainer

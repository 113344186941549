import Axios from 'axios'

import {
  adminPath,
  passwordPath,
  profilePath,
  requestAccount,
} from 'configs/apiPath'
import {
  AddUserData,
  AvatarUrl,
  CredentialFileUrl,
  RequestAccount,
  UserEditForm,
} from 'types'
import { getFileInfo } from 'utils/parser'

export const getUploadAvatarUrl = (userId: string, fileType: string) => {
  return Axios.get<AvatarUrl>(profilePath.getUploadAvatarUrl(userId, fileType))
}

export const uploadAvatar = (url: string, formData: FormData) => {
  return Axios.post(url, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      'No-Need-Authorization': true,
    },
  })
}

export const getCredentialUploadUrl = (userId: string, file: any) => {
  const fileInfo = getFileInfo(file)
  fileInfo.fileName = file.name

  return Axios.get<CredentialFileUrl>(
    profilePath.getUploadCredentialUrl(userId, fileInfo)
  )
}

export const getCredential = (
  userId: string,
  key: string,
  type: 'preview' | 'download' = 'download'
) => {
  return Axios.get(profilePath.getCredential(userId, key, type))
}

export const updateUser = ({
  userId,
  data,
}: {
  userId: string
  data: UserEditForm
}) => {
  return Axios.put(adminPath.singleUser(userId), data)
}

export const deleteUser = (userId: string) => {
  return Axios.delete(adminPath.singleUser(userId))
}

export const deleteUsers = (ids: string[]) => {
  return Axios.delete(adminPath.getListUsers(), {
    data: {
      ids,
    },
  })
}

export const addUser = (data: AddUserData) => {
  return Axios.post(adminPath.addUser(), {
    ...data,
    ...(data.centerId && { centerId: data.centerId }),
    ...(data.enterpriseId && {
      enterpriseId: data.enterpriseId,
    }),
  })
}

export const resetUserPassword = (userId: string) => {
  return Axios.post(adminPath.resetUserPassword(), {
    userId,
  })
}

export const createRequestAccount = (data: RequestAccount) => {
  return Axios.post(requestAccount.create(), data, {
    headers: {
      'No-Need-Authorization': true,
    },
  })
}

export const updateUserProfile = ({
  userId,
  data,
  onInvalidateUrl,
}: {
  userId: string
  data: UserEditForm
  onInvalidateUrl?: () => void
}) => {
  return Axios.put(profilePath.profile(userId), data)
}

export const confirmForgotPassword = ({
  email,
  code,
  password,
}: {
  email: string
  code: string
  password: string
}) => {
  return Axios.post(
    passwordPath.confirmForgotPassword(),
    {
      email,
      code,
      password,
    },
    {
      headers: {
        'No-Need-Authorization': true,
      },
    }
  )
}

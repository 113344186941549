import React, { useEffect, useState } from 'react'
import TagManager from 'react-gtm-module'

import { Box, Flex, Text, useDisclosure } from '@chakra-ui/react'
import { useAtom } from 'jotai'

import {
  Board,
  InstructionModal,
  Keyboard,
  StatisticModal,
} from '../../containers'
import { maxChallenges, maxNumberLength, revealTime } from 'configs/game'
import { useLanguages, useToast } from 'hooks'
import { gameAtom, gameStatsAtom, gameUserAtom } from 'store/gameAtom'
import { GameStats } from 'types'
import { isWinningNumber, solution } from 'utils/game/number'

import { ReactComponent as ChartBarIcon } from 'assets/images/chart_bar_icon.svg'
import { ReactComponent as QuestionIcon } from 'assets/images/question_icon.svg'

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_ID || '',
  dataLayer: {
    event: 'Games',
    games_event: '',
  },
}

const Knovole = () => {
  const { _t } = useLanguages()
  const toast = useToast()
  const [game, setGame] = useAtom(gameAtom)
  const [gameStats, setGameStats] = useAtom(gameStatsAtom)
  const [gameUser, setGameUser] = useAtom(gameUserAtom)
  const [currentGuess, setCurrentGuess] = useState('')
  const [isGameWon, setIsGameWon] = useState(false)
  const [isGameLose, setIsGameLose] = useState(false)
  const [isRevealing, setIsRevealing] = useState(false)
  const [guesses, setGuesses] = useState<string[]>(() => {
    if (game?.solution !== solution) {
      return []
    }
    const gameWasWon = game.guesses.includes(solution)
    if (gameWasWon) {
      setIsGameWon(true)
    }
    if (game.guesses.length === maxChallenges && !gameWasWon) {
      setIsGameLose(true)
    }
    return game.guesses
  })

  const {
    isOpen: isInfoOpen,
    onOpen: onInfoOpen,
    onClose: onInfoClose,
  } = useDisclosure()
  const {
    isOpen: isStatsOpen,
    onOpen: onStatsOpen,
    onClose: onStatsClose,
  } = useDisclosure()

  useEffect(() => {
    setGame({ guesses, solution })
  }, [guesses, setGame])

  useEffect(() => {
    if (isGameWon || isGameLose) {
      onStatsOpen()
    }
  }, [isGameWon, isGameLose, onStatsOpen])

  const defaultStats: GameStats = {
    winDistribution: Array.from(new Array(maxChallenges), () => 0),
    gamesFailed: 0,
    currentStreak: 0,
    bestStreak: 0,
    totalGames: 0,
    successRate: 0,
  }

  const getSuccessRate = (gStats: GameStats) => {
    const { totalGames, gamesFailed } = gStats

    return Math.round(
      (100 * (totalGames - gamesFailed)) / Math.max(totalGames, 1)
    )
  }

  const finalizeStats = (gStats: GameStats, count: number) => {
    // Count is number of incorrect guesses before end.
    const stats = { ...gStats }

    stats.totalGames += 1

    if (count >= maxChallenges) {
      // A fail situation
      stats.currentStreak = 0
      stats.gamesFailed += 1
    } else {
      stats.winDistribution[count] += 1
      stats.currentStreak += 1

      if (stats.bestStreak < stats.currentStreak) {
        stats.bestStreak = stats.currentStreak
      }
    }

    stats.successRate = getSuccessRate(stats)

    setGameStats(stats)
  }

  const onChar = (value: string) => {
    if (
      currentGuess.length < maxNumberLength &&
      guesses.length < maxChallenges &&
      !isGameWon
    ) {
      setCurrentGuess(`${currentGuess}${value}`)
    }
  }

  const onDelete = () => {
    setCurrentGuess(currentGuess.slice(0, -1))
  }

  const sendTagManagerArgs = (type: string) => {
    tagManagerArgs.dataLayer.games_event = type

    TagManager.dataLayer(tagManagerArgs)
  }

  const onEnter = () => {
    if (isGameWon || isGameLose) {
      return
    }
    if (currentGuess.length !== maxNumberLength) {
      toast({
        title: 'Error',
        description: _t('games.knovole.not_enough_numbers'),
        status: 'error',
      })
    }

    setIsRevealing(true)
    // turn this back off after all
    // chars have been revealed
    setTimeout(() => {
      setIsRevealing(false)
    }, revealTime * maxNumberLength)

    const winningNumber = isWinningNumber(currentGuess)

    if (
      currentGuess.length === maxNumberLength &&
      guesses.length < maxChallenges &&
      !isGameWon
    ) {
      setGuesses([...guesses, currentGuess])
      setCurrentGuess('')
      sendTagManagerArgs('played_knovole')

      if (winningNumber) {
        if (gameUser?.tokens !== undefined) {
          setGameUser({ tokens: gameUser.tokens + 1 })
        } else {
          setGameUser({ tokens: 1 })
        }
        finalizeStats(gameStats || defaultStats, guesses.length)
        sendTagManagerArgs('won_knovole')
        return setIsGameWon(true)
      }

      if (guesses.length === maxChallenges - 1) {
        finalizeStats(gameStats || defaultStats, guesses.length + 1)
        toast({
          title: 'Better luck next time!',
          description: _t('games.knovole.correct_number', {
            solution,
          }),
          status: 'error',
        })
        sendTagManagerArgs('lost_knovole')
        setIsGameLose(true)
      }
    }
  }

  return (
    <Box pt='1rem' pb='4rem' mx='auto'>
      <Flex
        mx='auto'
        justifyContent='center'
        alignItems='center'
        mb='2rem'
        mt='2rem'
      >
        <QuestionIcon
          height='1.5rem'
          width='1.5rem'
          color='#3A3A3A'
          cursor='pointer'
          onClick={onInfoOpen}
        />
        <Text
          as='h1'
          mx='1.5rem'
          textAlign='center'
          fontWeight='semibold'
          className={'blue-purple-gradient-text'}
        >
          {_t('games.knovole.title').toUpperCase()}
        </Text>
        <ChartBarIcon
          height='1.5rem'
          width='1.5rem'
          color='#3A3A3A'
          cursor='pointer'
          onClick={onStatsOpen}
        />
      </Flex>
      <Board
        guesses={guesses}
        currentGuess={currentGuess}
        isRevealing={isRevealing}
      />
      <Keyboard
        onChar={onChar}
        onDelete={onDelete}
        onEnter={onEnter}
        guesses={guesses}
        isRevealing={isRevealing}
      />
      <InstructionModal isOpen={isInfoOpen} onClose={onInfoClose} />

      <StatisticModal
        isOpen={isStatsOpen}
        handleClose={onStatsClose}
        guesses={guesses}
        gameStats={gameStats || defaultStats}
        isGameLose={isGameLose}
        isGameWon={isGameWon}
      />
    </Box>
  )
}

export default Knovole

import React, { useMemo } from 'react'

import { Box, Text } from '@chakra-ui/react'

import { ContentContainer } from 'components'
import { useLanguages } from 'hooks'
import { useSubscription } from 'queries/subscription'
import { TabRef } from 'types'
import { getNumber } from 'utils/parser'

interface EditSubscriptionSectionProps {
  instructorId: string
}

const EditSubscriptionSection: React.ForwardRefRenderFunction<
  TabRef,
  EditSubscriptionSectionProps
> = ({ instructorId }, ref) => {
  const { _t } = useLanguages()

  const { data: subscriptionInfo } = useSubscription({
    userId: instructorId,
  })
  const subscriptionName = useMemo(() => subscriptionInfo?.data.name, [
    subscriptionInfo,
  ])
  const commissionFee = useMemo(
    () => getNumber((subscriptionInfo?.data.commission || 0) * 100, 0),
    [subscriptionInfo]
  )

  return (
    <ContentContainer title=''>
      <Box maxWidth='300px'>
        <Text fontSize='0.875rem' fontWeight='600'>
          {_t('user.profile.subscription_plan')}
        </Text>
        <Text>{subscriptionName || '-'}</Text>
        <Text color='gray.300' fontSize='0.875rem'>
          {_t('user.profile.commission_description', {
            rate: `${commissionFee}` || '-',
          })}
        </Text>
      </Box>
    </ContentContainer>
  )
}

export default React.forwardRef(EditSubscriptionSection)

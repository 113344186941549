import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { useDisclosure } from '@chakra-ui/react'

import ClassCreate, { CreateClassDataType } from '../../Product/Class/Create'
import { CreateClassSuccessModal } from 'components'
import { useLoading, useUserId } from 'hooks'
import { useAddClass } from 'queries/class'
import { CreateClassType, UserRole, UserRoleType } from 'types'
import { parseClassType } from 'utils/parser'

interface CreateClassContainerProps {
  role: UserRoleType
  handleToDashboard: () => void
  handleBackToCreate: () => void
}

const CreateClassContainer = ({
  role,
  handleToDashboard,
  handleBackToCreate,
}: CreateClassContainerProps) => {
  const { onClose, isOpen, onOpen } = useDisclosure()
  const [createdClass, setCreatedClass] = useState<{
    name: string
    type: string
    classNumber: number
  }>()
  const [isDraft, setIsDraft] = useState<boolean>(false)
  const { setLoading } = useLoading()
  const userId = useUserId()

  const roleIsTutor = useMemo(
    () => role === UserRole.Tutor || role === UserRole.EnterpriseTutor,
    [role]
  )

  const { mutate: addClass, data: createdClassData, isSuccess } = useAddClass()

  const handleCreateClass = useCallback(
    (creatingStatus: 'public' | 'draft') => (value: CreateClassDataType) => {
      const {
        name,
        description,
        price,
        enterprisePrice,
        usualPrice,
        usualEnterprisePrice,
        language,
        centre,
        tutor,
        subject,
        teachingLevel,
        minStudent,
        maxStudent,
        requireMinimum,
        type,
        tutorType,
      } = value.data
      setIsDraft(creatingStatus === 'draft')

      const formattedInvitedStudents = value.invitedLearners.map(item => ({
        opt: 'add',
        id: item.id,
      }))
      const formattedConfirmedStudents = value.confirmedLearners.map(item => ({
        opt: 'add',
        id: item.id,
      }))

      const sessionClass = value.classSessions.map(item => {
        if (!!item.meetingLocation) {
          return {
            index: item.index,
            name: item.name,
            description: item.description,
            startDateTime: item.startDateTime,
            endDateTime: item.endDateTime,
            meetingType: item.meetingType,
            meetingLocation: item.meetingLocation,
          }
        } else if (!!item.meetingLink) {
          return {
            index: item.index,
            name: item.name,
            description: item.description,
            startDateTime: item.startDateTime,
            endDateTime: item.endDateTime,
            meetingType: item.meetingType,
            meetingLink: item.meetingLink,
            meetingPassword: item.meetingPassword,
          }
        } else {
          return {
            index: item.index,
            name: item.name,
            description: item.description,
            startDateTime: item.startDateTime,
            endDateTime: item.endDateTime,
            meetingType: item.meetingType,
          }
        }
      })

      const formattedData: CreateClassType = {
        name: name,
        description: description,
        price: price * 1,
        enterprisePrice: (enterprisePrice || 0) * 1,
        usualPrice: usualPrice ? usualPrice * 1 : undefined,
        usualEnterprisePrice: usualEnterprisePrice
          ? usualEnterprisePrice * 1
          : undefined,
        languageId: language.value,
        centerId: centre?.value || '',
        tutorId: roleIsTutor ? userId : tutor.value,
        subjectId: subject.value,
        teachingLevelId: teachingLevel.value,
        maxStudent: maxStudent * 1,
        minStudent: minStudent * 1,
        requireMinimum: requireMinimum,
        invitedStudents: formattedInvitedStudents,
        confirmedStudents: formattedConfirmedStudents,
        type: type,
        status: creatingStatus,
        sessions: sessionClass,
      }

      if (tutorType === UserRole.Tutor) {
        delete formattedData.enterprisePrice
        delete formattedData.centerId
      }

      addClass({ data: formattedData })
    },
    [addClass, roleIsTutor, userId]
  )

  useEffect(() => {
    if (isSuccess) {
      setLoading(true)
    }
  }, [isSuccess, setLoading])

  const handleCreatedSuccessCallback = useCallback(
    (name: string, type: string, classNumber: number) => {
      setCreatedClass({ name, type, classNumber })
      setLoading(false)
      onOpen()
    },
    [onOpen, setLoading]
  )

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <ClassCreate
        role={role}
        handleClose={handleBackToCreate}
        handleCreateClass={handleCreateClass}
        createdData={createdClassData}
        onCreatedSuccess={handleCreatedSuccessCallback}
      />

      <CreateClassSuccessModal
        description={
          isDraft
            ? `"${createdClass?.name}" with ${
                createdClass?.classNumber
              } classes has been successfully saved in your drafts. Return to Drafts to publish your "${parseClassType(
                createdClass?.type || ''
              )}" anytime.`
            : `"${createdClass?.name}" with ${createdClass?.classNumber} classes has been successfully created.`
        }
        isOpen={isOpen}
        onClose={onClose}
        isTutor={roleIsTutor}
        isDraft={isDraft}
        actionBackToCreating={handleBackToCreate}
        actionGoToDashboard={handleToDashboard}
      />
    </>
  )
}

export default CreateClassContainer

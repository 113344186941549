import React from 'react'

import {
  Box,
  BoxProps,
  Collapse,
  Flex,
  Text,
  useDisclosure,
  useMediaQuery,
} from '@chakra-ui/react'

import { ReactComponent as ArrowDownIcon } from 'assets/images/arrow_down.svg'
import { ReactComponent as ArrowUpIcon } from 'assets/images/arrow_up.svg'

interface ContentContainerCollapseProps extends BoxProps {
  title?: string
}

const ContentContainerCollapse: React.FC<ContentContainerCollapseProps> = ({
  title,
  children,
  ...boxProps
}) => {
  const { isOpen, onToggle } = useDisclosure()
  const [isLargerThan1024] = useMediaQuery('(min-width: 1024px)')

  if (isLargerThan1024) {
    return (
      <Box
        py='2rem'
        px='2.5rem'
        bgColor='#fff'
        boxShadow='lg'
        rounded='lg'
        {...boxProps}
      >
        {title && (
          <Text textStyle='contentHeader' mb='1rem'>
            {title}
          </Text>
        )}
        {children}
      </Box>
    )
  }

  return (
    <Box bgColor='#fff' boxShadow='lg' rounded='lg' {...boxProps}>
      <Flex
        py='2rem'
        px='2.5rem'
        align='center'
        justify='space-between'
        position='relative'
      >
        {title && <Text textStyle='contentHeader'>{title}</Text>}
        <Box
          onClick={onToggle}
          _before={{
            content: '""',
            display: 'block',
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            cursor: 'pointer',
          }}
        >
          {isOpen ? (
            <ArrowUpIcon id='arrowUpIcon' />
          ) : (
            <ArrowDownIcon id='arrowDownIcon' />
          )}
        </Box>
      </Flex>
      <Collapse
        in={isOpen}
        animateOpacity
        style={{ overflow: 'initial !important' }}
      >
        <Box pb='2rem' px='2.5rem'>
          {children}
        </Box>
      </Collapse>
    </Box>
  )
}

export default ContentContainerCollapse

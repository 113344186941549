import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { Button, Flex, Spinner } from '@chakra-ui/react'

import { useLanguages, useUserId } from 'hooks'
import {
  useAcceptConnection,
  useAddConnection,
  useDeclineConnection,
} from 'queries/connection'
import { ConnectionStatus, ConnectionStatusType } from 'types/connection'

export const UserConnectionButton = ({
  connection = undefined,
  friendId,
  handleView,
}: {
  connection?: ConnectionStatus
  friendId: string
  handleView: () => void
}) => {
  const { _t } = useLanguages()
  const userId = useUserId()
  const [status, setStatus] = useState<ConnectionStatusType>()

  // ** Queries
  const {
    mutate: addConnection,
    isSuccess: addConnectionIsSuccess,
    isLoading: addConnectionIsLoading,
    reset: addConnectionOnReset,
  } = useAddConnection(userId, friendId)

  const {
    mutate: acceptConnection,
    isSuccess: acceptConnectionIsSuccess,
    isLoading: acceptConnectionIsLoading,
    reset: acceptConnectionOnReset,
  } = useAcceptConnection()

  const {
    mutate: declineConnection,
    isSuccess: declineConnectionIsSuccess,
    isLoading: declineConnecetionIsLoading,
    reset: declineConnectionOnReset,
  } = useDeclineConnection()

  // ** Effects
  useEffect(() => {
    if (connection && userId) {
      if (
        userId === connection.idRequestFriend &&
        connection.status === ConnectionStatusType.PENDING
      ) {
        setStatus(ConnectionStatusType.IM_WAITING)
      } else if (
        userId !== connection.idRequestFriend &&
        connection.status === ConnectionStatusType.PENDING
      ) {
        setStatus(ConnectionStatusType.PENDING)
      } else {
        setStatus(connection.status)
      }
    }
  }, [connection, userId])

  useEffect(() => {
    if (addConnectionIsSuccess) {
      setStatus(ConnectionStatusType.IM_WAITING)
      addConnectionOnReset()
    }
  }, [addConnectionIsLoading, addConnectionIsSuccess, addConnectionOnReset])

  useEffect(() => {
    if (acceptConnectionIsSuccess) {
      setStatus(ConnectionStatusType.CONFIRM)
      acceptConnectionOnReset()
    }
  }, [acceptConnectionIsSuccess, acceptConnectionOnReset])

  useEffect(() => {
    if (declineConnectionIsSuccess) {
      setStatus(ConnectionStatusType.UN_FRIEND)
      declineConnectionOnReset()
    }
  }, [declineConnectionIsSuccess, declineConnectionOnReset])

  // ** Events

  const handleConnect = useCallback(() => {
    addConnection()
  }, [addConnection])

  const handleAccept = useCallback(() => {
    if (userId && connection) {
      acceptConnection({
        userId,
        requestId: connection?.id,
      })
    }
  }, [acceptConnection, userId, connection])

  const handleDeclineConnection = useCallback(() => {
    declineConnection({
      userId,
      requestId: connection?.id,
    })
  }, [declineConnection, userId, connection])

  return useMemo(() => {
    if (!userId) {
      return (
        <Button
          color='socialBlue'
          bg='transparent'
          size='sm'
          w='full'
          display='flex'
          alignItems='center'
          justifyContent='center'
          textTransform='uppercase'
          fontWeight='700'
          fontSize='0.875rem'
          border='solid 1px'
          borderColor='socialBlue'
          rounded='full'
          onClick={handleView}
        >
          {_t('user.connection.view')}
        </Button>
      )
    }

    if (
      status === ConnectionStatusType.UN_FRIEND ||
      !status ||
      status === ConnectionStatusType.DECLINE
    ) {
      return (
        <Button
          color='white'
          bg='socialBlue'
          size='sm'
          w='full'
          display='flex'
          alignItems='center'
          justifyContent='center'
          textTransform='uppercase'
          fontWeight='700'
          fontSize='0.875rem'
          border='0'
          rounded='full'
          onClick={handleConnect}
        >
          {addConnectionIsLoading && <Spinner size='sm' color='primary' />}
          {!addConnectionIsLoading && _t('user.connection.connect')}
        </Button>
      )
    }

    if (status === ConnectionStatusType.IM_WAITING) {
      return (
        <Button
          color='#828282'
          bg='transparent'
          size='sm'
          w='full'
          display='flex'
          alignItems='center'
          justifyContent='center'
          textTransform='uppercase'
          fontWeight='700'
          fontSize='0.875rem'
          border='0'
          rounded='full'
        >
          {_t('user.connection.request_sent')}
        </Button>
      )
    }

    if (status === ConnectionStatusType.PENDING) {
      return (
        <Flex w='100%'>
          <Button
            color='white'
            bg='socialBlue'
            size='sm'
            w='full'
            display='flex'
            alignItems='center'
            justifyContent='center'
            textTransform='uppercase'
            fontWeight='700'
            fontSize='0.875rem'
            border='0'
            rounded='full'
            onClick={handleAccept}
          >
            {(acceptConnectionIsLoading || declineConnecetionIsLoading) && (
              <Spinner size='sm' color='primary' />
            )}
            {!acceptConnectionIsLoading &&
              !declineConnecetionIsLoading &&
              _t('user.connection.accept')}
          </Button>
          <Button
            color='socialBlue'
            bg='transparent'
            size='sm'
            w='full'
            display='flex'
            alignItems='center'
            justifyContent='center'
            textTransform='uppercase'
            fontWeight='700'
            fontSize='0.875rem'
            border='solid 1px'
            borderColor='socialBlue'
            rounded='full'
            onClick={handleDeclineConnection}
          >
            {(acceptConnectionIsLoading || declineConnecetionIsLoading) && (
              <Spinner size='sm' color='primary' />
            )}
            {!acceptConnectionIsLoading &&
              !declineConnecetionIsLoading &&
              _t('user.connection.decline')}
          </Button>
        </Flex>
      )
    }

    /* chat feature not available 
    if (status === ConnectionStatusType.CONFIRM) {
      return (
        <Button
          color='socialBlue'
          bg='transparent'
          size='sm'
          w='full'
          display='flex'
          alignItems='center'
          justifyContent='center'
          textTransform='uppercase'
          fontWeight='700'
          fontSize='0.875rem'
          border='solid 1px'
          borderColor='socialBlue'
          rounded='full'
          onClick={console.log}
        >
          <Image as={MessageIcon} mr='10px' />
          {_t('user.connection.message')}
        </Button>
      )
    }*/

    return (
      <Button
        color='socialBlue'
        bg='transparent'
        size='sm'
        w='full'
        display='flex'
        alignItems='center'
        justifyContent='center'
        textTransform='uppercase'
        fontWeight='700'
        fontSize='0.875rem'
        border='solid 1px'
        borderColor='socialBlue'
        rounded='full'
        onClick={handleView}
      >
        {_t('user.connection.view')}
      </Button>
    )
  }, [
    _t,
    userId,
    status,
    handleView,
    handleConnect,
    addConnectionIsLoading,
    handleAccept,
    acceptConnectionIsLoading,
    declineConnecetionIsLoading,
    handleDeclineConnection,
  ])
}

import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router'

import { Box, Button, Flex, SimpleGrid } from '@chakra-ui/react'
import { useAtom } from 'jotai'

import NotificationPopupItem from './NotificationItem'
import { ReadAllNotifications } from 'components'
import { useLanguages } from 'hooks'
import { useReadNotification } from 'queries/notification'
import { userProfileAtom } from 'store/authAtom'
import { NotificationOnApp, UserRole } from 'types'
import { getUrlForNotification } from 'utils/parser'

interface NotificationPopupProps {
  data: NotificationOnApp[]
}

const NotificationPopup: React.FC<NotificationPopupProps> = ({ data }) => {
  const { _t } = useLanguages()
  const navigate = useNavigate()
  const [userProfile] = useAtom(userProfileAtom)
  const roles = useMemo(() => userProfile?.roles?.map(role => role.type), [
    userProfile,
  ])
  const isMSTAdmin = useMemo(
    () =>
      roles?.includes(UserRole.MSTAdmin) ||
      roles?.includes(UserRole.MSTSuperAdmin) ||
      false,
    [roles]
  )

  const { mutate: readNotification } = useReadNotification()

  const [unreadNotifications, setUnreadNotifications] = useState<string[]>([])

  useEffect(() => {
    const unreadNotifs = data.filter(item => !item.isRead).map(item => item.id)
    setUnreadNotifications(unreadNotifs)
  }, [data])

  const handleViewAll = useCallback(() => {
    if (userProfile?.accountType?.name === UserRole.EnterpriseAdmin) {
      return navigate('/enterprise-admin/all-notifications')
    }
    if (userProfile?.accountType?.name === UserRole.CenterAdmin) {
      return navigate('/center-admin/all-notifications')
    }
    if (isMSTAdmin) {
      return navigate('/admin/all-notifications')
    }
    navigate('/notifications')
  }, [navigate, userProfile, isMSTAdmin])

  const handleClickItem = useCallback(
    (item: NotificationOnApp) => () => {
      !item.isRead && readNotification(item.id)
      const url = getUrlForNotification(item, isMSTAdmin)

      if (url) {
        navigate(url)
      }
    },
    [readNotification, navigate, isMSTAdmin]
  )

  return (
    <Box>
      <SimpleGrid columns={2} px='1.5rem' py='1rem'>
        <Box as='h2' color='#000'>
          {_t('header.notifications.title')}
        </Box>
        {unreadNotifications.length > 0 && <ReadAllNotifications popup />}
      </SimpleGrid>
      <Box h='27.5rem' overflowX='hidden' overflowY='scroll'>
        {data.map(item => (
          <NotificationPopupItem
            data={item}
            key={item.id}
            onClickItem={handleClickItem}
          />
        ))}
      </Box>
      <Flex
        justifyContent='center'
        boxShadow='6px 0px 4px rgba(0, 0, 0, 0.25)'
        cursor='auto'
      >
        <Button
          variant='transparent'
          color='primary'
          fontSize='0.875rem'
          textTransform='unset'
          onClick={handleViewAll}
          _focus={{ outline: 'none' }}
        >
          {_t('header.notifications.view_all')}
        </Button>
      </Flex>
    </Box>
  )
}

export default NotificationPopup

import React, { ChangeEvent } from 'react'
import { Control, Controller } from 'react-hook-form'

import { Box, Flex, Text } from '@chakra-ui/react'

import { CreateClassForm } from '../form'
import { ImageWrapper, Input, Textarea } from 'components'
import { useLanguages } from 'hooks'

import { ReactComponent as CameraIcon } from 'assets/images/camera_icon.svg'

interface HeaderSectionProps {
  control: Control<CreateClassForm>
  errors: any
  bannerFile?: File | string
  previewBanner?: string
  handleUpdateBanner: (e: ChangeEvent<HTMLInputElement>) => void
}

const HeaderSection: React.FC<HeaderSectionProps> = ({
  control,
  errors,
  bannerFile,
  previewBanner,
  handleUpdateBanner,
}) => {
  const { _t } = useLanguages()
  return (
    <Flex direction={{ base: 'column', md: 'row' }} justify='space-between'>
      <Box flex='1'>
        <Box mt='1.2rem'>
          <Controller
            as={Input}
            control={control}
            name='name'
            aria-label='Name'
            label={_t('product.class.name')}
            placeholder={_t('product.class.placeholder_name')}
            fontSize='0.875rem'
            labelProps={{ fontSize: '0.75rem' }}
            error={errors?.name?.message || ''}
            rules={{
              required: _t('product.class.validate_name_required'),
            }}
          />
        </Box>
        <Box mt='1.2rem'>
          <Controller
            as={
              <Textarea
                h='15.5rem'
                fontSize='0.875rem'
                labelProps={{ fontSize: '0.75rem' }}
              />
            }
            control={control}
            name='description'
            aria-label='Description'
            label={_t('product.class.description_title')}
            error={errors?.description?.message || ''}
            rules={{
              required: _t('product.class.validate_description_required'),
            }}
          />
        </Box>
      </Box>
      <Box w='4rem' />
      <Box flex='1' mt='1.2rem'>
        <Text fontSize='0.75rem' fontWeight='600'>
          {_t('product.class.banner')}
        </Text>
        <Flex
          w='100%'
          h='20rem'
          border='1px dashed #BDBDBD'
          rounded='lg'
          justify='center'
          align='center'
          position='relative'
        >
          <Box as='label' htmlFor='banner' mr='1rem' zIndex={5}>
            <CameraIcon cursor='pointer' />
          </Box>
          {bannerFile ? (
            <ImageWrapper
              src={previewBanner}
              w='100%'
              h='100%'
              position='absolute'
              top={0}
            />
          ) : (
            <Box>
              <Text>{_t('product.class.banner_upload_title')}</Text>
              <Text color='gray.100'>
                {_t('product.class.banner_upload_description')}
              </Text>
            </Box>
          )}
          <Box
            id='banner'
            as='input'
            type='file'
            accept='image/*'
            w='0.1px'
            h='0.1px'
            opacity={0}
            zIndex={-1}
            onChange={handleUpdateBanner}
          />
        </Flex>
      </Box>
    </Flex>
  )
}

export default HeaderSection

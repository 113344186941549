import Axios from 'axios'

import { adhocRequestPath } from 'configs/apiPath'
import { ClassUploadUrl } from 'types'
import { getFileInfo } from 'utils/parser'

export const getUploadAttachmentAdhocRequestUrl = ({
  userId,
  requestId,
  file,
}: {
  userId: string
  requestId: string
  file: File
}) => {
  const { contentType } = getFileInfo(file)
  return Axios.post<ClassUploadUrl>(
    adhocRequestPath.createUploadAttachment(userId, requestId),
    {
      fileName: file.name,
      contentType: contentType,
    }
  )
}

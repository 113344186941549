import Axios from 'axios'

import { assignment } from 'configs/apiPath'
import {
  AttachmentFileStatus,
  CreatedItem,
  TutorAssignment,
  UploadFileUrl,
} from 'types'
import { getFileInfo } from 'utils/parser'

export const addAssignment = async ({
  name,
  description,
  deadline,
  status,
  receivers,
}: Omit<TutorAssignment, 'id'>) => {
  return Axios.post<CreatedItem>(assignment.create(), {
    name,
    description,
    deadline,
    status,
    receivers,
  })
}

export const getUploadAttachmentUrl = async ({
  id,
  status,
  file,
}: {
  id: string
  status: AttachmentFileStatus
  file: File
}) => {
  const { contentType } = getFileInfo(file)
  return Axios.post<UploadFileUrl>(
    assignment.uploadAttachment({
      id,
      status,
      fileName: file.name,
      contentType,
    })
  )
}

export const updateAssignment = async ({
  id,
  ...updateInfo
}: Partial<TutorAssignment>) => {
  return Axios.put<TutorAssignment>(assignment.update(id as string), {
    ...updateInfo,
  })
}

export const deleteAssignment = async (id: string) => {
  return Axios.delete(assignment.delete(id))
}

export const deleteAssignments = (ids: string[]) => {
  return Axios.delete(assignment.tutorList(), {
    data: {
      ids,
    },
  })
}

export const downloadAllAssignments = async (assignmentId: string) => {
  return Axios.get<{
    data: string
  }>(assignment.downloadAllAttachments(assignmentId))
}

export const downloadAllSubmissions = async (assignmentId: string) => {
  return Axios.get<{
    data: string
  }>(assignment.downloadAllSubmissions(assignmentId))
}

export const getAttachmentUrl = async (
  assignmentId: string,
  key: string,
  type: 'download' | 'preview' = 'download'
) => {
  return Axios.get<{
    data: string
  }>(assignment.getAttachmentUrl(assignmentId, key, type))
}

export const getSubmissionAttachmentUrl = async (
  assignmentId: string,
  key: string
) => {
  return Axios.get<{
    data: string
  }>(assignment.getSubmissionAttachmentUrl(assignmentId, key))
}

export const getUploadSubmissionUrl = async ({
  id,
  status,
  file,
}: {
  id: string
  status?: AttachmentFileStatus
  file: File
}) => {
  const { contentType } = getFileInfo(file)
  return Axios.post<UploadFileUrl>(
    assignment.uploadSubmission({
      id,
      status,
      fileName: file.name,
      contentType,
    })
  )
}

export const deleteSubmissions = async (
  assignmentId: string,
  data: { id: string }[]
) => {
  return Axios.delete(assignment.deleteSubmissions(assignmentId), {
    data: {
      attachments: data,
    },
  })
}

import React, { useMemo } from 'react'

import {
  Box,
  Button,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
} from '@chakra-ui/react'
import { useAtom } from 'jotai'

import SubscriptionSection from './containers/SubscriptionSection'
import WalletSection from './containers/WalletSection'
import { userProfileAtom } from 'store/authAtom'
import {
  isAdmin,
  isEnterprise,
  isInstructor,
  isLearner,
  isMSTAdmin,
} from 'utils/helper'

import { ReactComponent as MoneyIcon } from 'assets/images/money.svg'

interface WalletPopoverProps {
  userType: string
  typeId: string
}

const WalletPopover: React.FC<WalletPopoverProps> = ({ userType, typeId }) => {
  const [userProfile] = useAtom(userProfileAtom)

  const isLearnerMemo = useMemo(
    () => isLearner(userProfile) && !isAdmin(userProfile),
    [userProfile]
  )
  const isEnterpriseInstructorMemo = useMemo(
    () =>
      isInstructor(userProfile) &&
      isEnterprise(userProfile) &&
      !isAdmin(userProfile),
    [userProfile]
  )
  const isKnovoAdminMemo = useMemo(() => isMSTAdmin(userProfile), [userProfile])
  const hasWallet = useMemo(() => !isEnterpriseInstructorMemo, [
    isEnterpriseInstructorMemo,
  ])
  const hasSubscription = useMemo(() => !isLearnerMemo && !isKnovoAdminMemo, [
    isLearnerMemo,
    isKnovoAdminMemo,
  ])

  return (
    <Box position='relative'>
      <Popover>
        <PopoverTrigger>
          <Button variant='transparent' px='0'>
            <MoneyIcon width='30px' height='30px' />
          </Button>
        </PopoverTrigger>
        <Box zIndex='1' position='relative'>
          <PopoverContent
            w='20rem'
            mr={{ base: '0', md: '6rem' }}
            borderRadius='0.5rem'
            boxShadow='md'
            _focus={{
              outline: 0,
            }}
          >
            <PopoverBody
              p='0'
              background='white'
              borderRadius='1rem'
              overflow='hidden'
            >
              {hasWallet && (
                <WalletSection userType={userType} typeId={typeId} />
              )}
              {hasSubscription && <SubscriptionSection />}
            </PopoverBody>
          </PopoverContent>
        </Box>
      </Popover>
    </Box>
  )
}

export default WalletPopover

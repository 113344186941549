import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import { Avatar, Box, Flex, Text } from '@chakra-ui/react'
import { isWithinInterval } from 'date-fns'
import format from 'date-fns/format'

import { ImageWrapper, Rating } from 'components'
import { ClassDetail, ClassType, MeetingType } from 'types'
import { isAdhocClass } from 'utils/helper'
import { parseMeetingType } from 'utils/parser'

import { ReactComponent as CoordinateIcon } from 'assets/images/coordinate_icon.svg'
import bg from 'assets/images/mapping.png'

interface UpcomingClassCardProps {
  data: ClassDetail
  onOpenLearners?: (data: ClassDetail) => void
  onJoinSession?: (data: ClassDetail) => void
  isLearner?: boolean
}

const UpcomingClassCard: React.FC<UpcomingClassCardProps> = ({
  data,
  onOpenLearners,
  onJoinSession,
  isLearner,
}) => {
  const navigate = useNavigate()
  const [isOnGoing, setIsOnGoing] = useState<boolean>(false)

  const now = new Date()

  const isExpired = useMemo(() => {
    const { upcomingSession } = data

    const halfHour = 30 * 60 * 1000

    return !isWithinInterval(now, {
      start: (upcomingSession.startDateTime || 0) - halfHour,
      end: (upcomingSession.endDateTime || 0) + halfHour,
    })
  }, [data, now])

  const isMultiSession = useMemo(() => {
    const { sessions } = data
    return sessions.length > 1 ? true : false
  }, [data])

  const meetingType = useMemo(() => {
    const { upcomingSession } = data
    return parseMeetingType(
      upcomingSession.meetingType || MeetingType.VIRTUAL_DEFAULT
    )
  }, [data])

  const handleJoinSession = useCallback(() => {
    if (!isExpired) {
      onJoinSession && onJoinSession(data)
    }
  }, [data, isExpired, onJoinSession])

  useEffect(() => {
    const datetime = Date.now()
    const { upcomingSession } = data

    if (
      upcomingSession.startDateTime <= datetime &&
      upcomingSession.endDateTime >= datetime
    ) {
      setIsOnGoing(true)
    }
  }, [
    data,
    data.sessions,
    data.upcomingSession.endDateTime,
    data.upcomingSession.startDateTime,
  ])

  const handleOpenLearners = useCallback(() => {
    onOpenLearners && onOpenLearners(data)
  }, [data, onOpenLearners])

  const handleView = () => {
    if (data.type === ClassType.ADHOC_REQUEST) {
      navigate(`/product/adhoc-class/${data.id}`)
    } else {
      navigate(`/product/class/${data.id}`)
    }
  }

  const getCentre = () => {
    const { center } = data
    if (center) {
      return center.name
    }

    return ''
  }

  const getStartDate = () => {
    const upcoming = data.upcomingSession

    return format(new Date(upcoming.startDateTime), 'dd MMM yyyy, eee')
  }

  const getTime = () => {
    const formatStr = 'h:mma'

    const upcoming = data.upcomingSession

    const start = format(new Date(upcoming.startDateTime), formatStr)
    const end = format(new Date(upcoming.endDateTime), formatStr)

    return `${start} - ${end}`
  }

  return (
    <Flex
      h={{ base: '92%', md: '360px' }}
      w={{ base: '92%', md: '250px' }}
      minW={{ base: '92%', md: '250px' }}
      direction='column'
      mx='0.7rem'
      mt='0.5rem'
      mb='0.7rem'
      boxShadow='lg'
      rounded='lg'
      bg='white'
    >
      <Flex h='100%' direction='column' position='relative'>
        <ImageWrapper
          h={{ base: '0', md: '135px' }}
          w='full'
          roundedTop='lg'
          bg='primary'
          src={data.banner || bg}
          key={data.banner || 'default-banner'}
          objectFit={'cover'}
          position='relative'
        />
        {isOnGoing && (
          <Box>
            <Flex
              position='absolute'
              top='10px'
              right='8px'
              bg='#D24848'
              textTransform='uppercase'
              borderRadius='4px'
              color='white'
              alignItems='center'
              fontSize='10px'
              p='3px'
            >
              <Box
                bg='white'
                borderRadius='50%'
                width='5px'
                height='5px'
                mb='2px'
                mr='3px'
              ></Box>
              <Box as='h5'>OnGoing</Box>
            </Flex>
          </Box>
        )}
        <Flex justify='flex-end' mt={{ base: '0.5rem', md: -3 }}>
          {isMultiSession && (
            <Text
              fontSize='0.75rem'
              color='white'
              bg={'#EB975E'}
              px={3}
              py={1}
              position='absolute'
              display={{ base: 'none', md: 'block' }}
            >
              {`Package ${data?.upcomingSession?.index || 0} of ${
                data.sessions.length
              }`}
            </Text>
          )}
          {isAdhocClass(data) && (
            <Text
              fontSize='0.75rem'
              color='white'
              bg={'#7D89F4'}
              px={3}
              py={1}
              position={'absolute'}
            >
              Bid-Request
            </Text>
          )}
        </Flex>
        <Flex
          flex='1'
          direction='column'
          px='1rem'
          py={{ base: '0.5rem', md: '1rem' }}
          pt={{ base: '0', md: '1.75rem' }}
        >
          <Flex
            flex='1'
            direction='column'
            justifyContent='flex-start'
            cursor='pointer'
          >
            <Text
              fontSize='0.75rem'
              fontWeight='600'
              color='primary'
              mb='0.25rem'
            >
              {`${getStartDate()} | ${getTime()}`}
            </Text>
            <Box
              fontSize={{ base: '1rem', md: '0.875rem' }}
              lineHeight={{ base: '1.1rem', md: '1.3rem' }}
              fontWeight='600'
              _hover={{
                color: 'primary',
              }}
              _before={{
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
              }}
              noOfLines={2}
              onClick={handleView}
            >
              {data.name || ''}
            </Box>
            {meetingType && (
              <Flex alignItems='center' justify='flex-start' width='100%'>
                <CoordinateIcon height='13px' width='13px' />
                <Text
                  fontSize='0.75rem'
                  color='gray.300'
                  lineHeight='0.875rem'
                  m='0.3rem'
                  textAlign='left'
                >
                  {meetingType}
                </Text>
              </Flex>
            )}
            <Text
              fontSize='0.75rem'
              fontWeight='300'
              color='gray.500'
              mb='0.25rem'
              noOfLines={2}
            >
              {data.description || ''}
            </Text>
          </Flex>
          {isLearner && (
            <Flex
              direction={{ base: 'row', md: 'column' }}
              justify='space-between'
              mt={{ base: '1rem', md: 'auto' }}
            >
              <Box position='relative' zIndex='1' cursor={'pointer'}>
                <Flex flexDirection='row' alignItems='center'>
                  <Avatar
                    size='sm'
                    name={`${data.user.firstName ?? ''} ${
                      data.user.lastName ?? ''
                    }`}
                    src={data.user.avatar}
                    key={data.user.avatar || 'default-avatar'}
                  />
                  <Box flex='1' ml='0.75rem'>
                    <Flex direction='column' justifyContent='center'>
                      {data.user.rating && (
                        <Rating rate={data.user.rating} size={'0.5rem'} />
                      )}
                      <Box
                        as={Link}
                        to={`/instructors/${data.user.id}`}
                        fontSize='0.75rem'
                        lineHeight='0.825rem'
                        fontWeight='600'
                        _hover={{
                          color: 'primary',
                        }}
                        _before={{
                          content: '""',
                          display: 'block',
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          width: '100%',
                          height: '100%',
                          cursor: 'pointer',
                        }}
                        noOfLines={1}
                      >
                        {`${data.user.firstName ?? ''} ${
                          data.user.lastName ?? ''
                        }`}
                      </Box>
                      <Text
                        fontSize='0.75rem'
                        color='gray.500'
                        lineHeight='0.875rem'
                        fontWeight='500'
                        noOfLines={1}
                      >
                        {getCentre()}
                      </Text>
                    </Flex>
                  </Box>
                </Flex>
              </Box>
            </Flex>
          )}
        </Flex>
      </Flex>
      <Flex
        bg='#fff'
        borderBottomRadius='0.5rem'
        borderTop='1px solid #EBEBEB'
        flexDirection='row'
        alignItems='center'
        marginTop='auto'
      >
        {!isLearner && (
          <Box
            as='h3'
            color='primary'
            flex='1'
            textAlign='center'
            py='0.625rem'
            borderRight='1px solid #EBEBEB'
            cursor='pointer'
            onClick={handleOpenLearners}
          >
            {`Learners (${data.confirmedStudents.length})`}
          </Box>
        )}
        {data.upcomingSession.meetingType !== MeetingType.PHYSICAL && (
          <Box
            as='h3'
            color={isExpired ? 'gray.300' : 'primary'}
            fontWeight={isExpired ? '200' : '600'}
            flex='1'
            textAlign='center'
            py='0.625rem'
            cursor={isExpired ? 'not-allowed' : 'pointer'}
            onClick={handleJoinSession}
            roundedBottom='lg'
          >
            JOIN
          </Box>
        )}
      </Flex>
    </Flex>
  )
}

export default UpcomingClassCard

export interface FilterForm {
  type?: string
  freeze?: string
  minAmount?: number
  maxAmount?: number
}

export const searchOptions = [
  { value: 'all', label: 'All' },
  { value: 'cashFlowId', label: 'ID' },
  { value: 'name', label: 'Name' },
]

export const sortOptions = [
  { value: 'createdAt', label: 'Date' },
  { value: 'updatedAt', label: 'Last Update' },
  { value: 'name', label: 'Name' },
  { value: 'amount', label: 'Amount' },
]

export const typeOptions = [
  {
    value: 'instructor',
    label: 'cash_flow.type.instructor',
  },
  {
    value: 'enterprise',
    label: 'cash_flow.type.enterprise',
  },
  {
    value: 'commission',
    label: 'cash_flow.type.commission',
  },
]

export const freezeOptions = [
  {
    value: 'true',
    label: 'cash_flow.freeze_type.frozen',
  },
  {
    value: 'false',
    label: 'cash_flow.freeze_type.no_freeze',
  },
]

import React, { useEffect, useRef, useState } from 'react'

import EditProfileContainer from '../../containers/EditProfile'
import ProfileLayout from '../../containers/ProfileLayout'
import { AvatarRef } from 'components'
import { useLanguages, useUtilsForEnterpriseId } from 'hooks'
import { useAddUser } from 'queries/user'
import { AddUserData, UserProfileRef } from 'types'
import { getErrorMessage } from 'utils/parser'
import { uploadUserAvatar } from 'utils/upload'

interface CreateLearnerContainerProps {
  centerId?: string
  handleToBack: () => void
}

const CreateLearnerContainer: React.FC<CreateLearnerContainerProps> = ({
  centerId,
  handleToBack,
}) => {
  const { _t } = useLanguages()

  const [, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<string>()
  const { enterpriseId } = useUtilsForEnterpriseId()
  const userRef = useRef<UserProfileRef>()
  const avatarRef = useRef<AvatarRef>()

  const { mutate: createUser, isSuccess, data: resData } = useAddUser()

  useEffect(() => {
    if (isSuccess && resData && avatarRef.current) {
      uploadUserAvatar(avatarRef, resData.data.data.id)
      setLoading(false)
      handleToBack()
    }
  }, [handleToBack, isSuccess, resData, setLoading])

  const handleCreateUser = async () => {
    try {
      const formattedData = (await userRef.current?.getData()) as AddUserData
      if (!formattedData) {
        return
      }

      createUser(formattedData)
    } catch (err) {
      setError(getErrorMessage(err))
    }
  }

  return (
    <ProfileLayout
      title={_t('user_management.learner.create_title')}
      backLabel={_t('user_management.learner.title')}
      handleCancel={handleToBack}
      handleSubmit={handleCreateUser}
      isCreate
      isAdminView
    >
      <EditProfileContainer
        centerId={centerId}
        enterpriseId={enterpriseId}
        profileData={{}}
        avatarRef={avatarRef}
        toastError={error}
        setToastError={setError}
        userRef={userRef}
        isLearner
        isAdmin
        isCreate
      />
    </ProfileLayout>
  )
}

export default CreateLearnerContainer

import React, { ChangeEvent, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'

import { Box, Text } from '@chakra-ui/react'

import {
  ActionButton,
  AttachmentSection,
  DetailSection,
  HeaderSection,
  ModuleSection,
  OccurrenceSection,
  Preview,
} from '../containers'
import { CreateClassForm } from '../containers/form'
import { createUploadBannerClass, uploadFileClass } from 'apis/class'
import { ContentContainer, ContentContainerCollapse } from 'components'
import { useLanguages, useToast, useUploadClassFile, useUserId } from 'hooks'
import { ClassType, Learner, MeetingType, UserRole } from 'types'
import { checkValidFilesName, isFileSizeOver } from 'utils/helper'
import { parseErrorToastMsg } from 'utils/parser'

export interface CreateClassDataType {
  data: CreateClassForm
  bannerFile?: File
  invitedLearners: Learner[]
  confirmedLearners: Learner[]
}

interface LessonCreateProps {
  role: string
  handleClose: () => void
  handleCreateClass: (
    creatingStatus: 'public' | 'draft'
  ) => (createData: CreateClassDataType) => void
  createdData: any
  onCreatedSuccess: (className: string, type: string) => void
}

const LessonCreate: React.FC<LessonCreateProps> = ({
  role,
  handleClose,
  handleCreateClass,
  createdData,
  onCreatedSuccess,
}) => {
  const { _t } = useLanguages()
  const userId = useUserId()
  const toast = useToast()
  const [bannerFile, setBannerFile] = useState<File | undefined>(undefined)
  const [isPreviewCreatingClass, setIsPreviewCreatingClass] = useState<boolean>(
    false
  )

  const [savingData, setSavingData] = useState<CreateClassForm | undefined>(
    undefined
  )

  const [allowUpload, setAllowUpload] = useState(true)

  const previewBanner = useMemo(
    () => (bannerFile ? URL.createObjectURL(bannerFile) : ''),
    [bannerFile]
  )

  const roleIsTutor = useMemo(
    () => role === UserRole.Tutor || role === UserRole.EnterpriseTutor,
    [role]
  )

  const {
    handleSubmit,
    control,
    watch,
    getValues,
    setValue,
    setError,
    errors,
  } = useForm<CreateClassForm>({
    mode: 'onChange',
    defaultValues: {
      name: '',
      description: '',
      price: 0,
      enterprisePrice: 0,
      usualPrice: undefined,
      usualEnterprisePrice: undefined,
      tutor: { value: '', label: '' },
      centre: { value: '', label: '' },
      teachingLevel: { value: '', label: '' },
      subject: { value: '', label: '' },
      language: { value: '', label: '' },
      minStudent: 0,
      maxStudent: 0,
      requireMinimum: false,
      type: ClassType.PUBLIC_LESSON,
      tutorType:
        role === UserRole.Tutor ? UserRole.Tutor : UserRole.EnterpriseTutor,
      occurrenceStartDate: undefined,
      numberOfPackage: undefined,
      occurrenceSession: [
        {
          name: '',
          description: '',
          week: 1,
          dayOfWeek: { value: '', label: '' },
          startTime: undefined,
          endTime: undefined,
          meetingType: MeetingType.VIRTUAL_DEFAULT,
        },
      ],
    },
  })

  const {
    uploadFiles,
    isUploading,
    isSettled,
    filesError,
    handleChangeFiles,
    handleUpload,
  } = useUploadClassFile()

  const classId = useMemo(() => createdData?.data.data.id || '', [createdData])

  const handleCreate = (creatingStatus: 'public' | 'draft') => (
    value: CreateClassForm
  ) => {
    if (!checkValidFilesName(uploadFiles)) {
      return toast({
        title: 'Error!',
        status: 'error',
        duration: 6000,
        description: _t('message.error.InvalidFileName'),
      })
    }

    const createData = {
      data: value,
      bannerFile: bannerFile,
      invitedLearners: [],
      confirmedLearners: [],
    }

    handleCreateClass(creatingStatus)(createData)
  }

  useEffect(() => {
    if (createdData?.data && isSettled) {
      onCreatedSuccess(createdData.data.data.name, createdData.data.data.type)
    }
  }, [createdData, onCreatedSuccess, isSettled, filesError])

  useEffect(() => {
    if (classId && allowUpload) {
      //  upload banner
      const handleUploadFile = async (
        id: string,
        banner: File,
        bodyGetUrl: any
      ) => {
        try {
          const res = await createUploadBannerClass(id, bodyGetUrl)
          const formData = new FormData()
          const { fields, url } = res.data.data
          Object.entries(fields).forEach(([key, value]) => {
            formData.append(key, value)
          }, [])
          formData.append('file', banner)
          const resUpload = await uploadFileClass(url, formData)
          if (resUpload) {
            return 'success'
          }
        } catch (err) {
          console.log(err)
        }
      }

      if (bannerFile) {
        const bodyGetUrl = {
          fileName: bannerFile.name,
          contentType: bannerFile.type,
        }
        handleUploadFile(classId, bannerFile, bodyGetUrl)
      }
      handleUpload(classId)
    }
  }, [allowUpload, handleUpload, classId, bannerFile])

  useEffect(() => {
    if (isSettled) {
      setAllowUpload(false)
    }
  }, [handleClose, isSettled])

  const handleUpdateBanner = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0]
    const maxSize = 10485760 // 10mb in byte

    if (file && isFileSizeOver(file.size, maxSize)) {
      return toast(
        parseErrorToastMsg(_t('product.class.validate_banner_invalid_size'))
      )
    }

    return setBannerFile(file)
  }

  const handleClosePreview = () => {
    setIsPreviewCreatingClass(false)
  }

  const handleOpenPreview = () => (value: CreateClassForm) => {
    setSavingData({ ...value })
    setIsPreviewCreatingClass(true)
  }

  useEffect(() => {
    if (!isPreviewCreatingClass && savingData) {
      const keys = Object.keys(savingData) as (keyof CreateClassForm)[]
      keys.forEach((key: keyof CreateClassForm) => {
        if (key === 'attachments') {
          return ''
        }
        setValue(key, savingData[key])
      })
    }
  }, [isPreviewCreatingClass, savingData, setValue])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  if (isPreviewCreatingClass) {
    return (
      <Preview
        previewBanner={previewBanner}
        onClose={handleClosePreview}
        data={savingData}
        userId={roleIsTutor ? userId : (savingData?.tutor.value as string)}
        attachments={uploadFiles.map(item => item.file)}
        handleCreateClass={handleCreate}
      />
    )
  }

  return (
    <>
      <Box as='form' pt='1.5rem' pb='3rem'>
        <Box as='h1' mb='1rem' className={'blue-purple-gradient-text'}>
          {_t('product.lesson.create_title')}
        </Box>
        <Text mb='2rem'>{_t('product.lesson.create_description')}</Text>

        <ContentContainer title={_t('product.lesson.title')}>
          <HeaderSection
            control={control}
            errors={errors}
            bannerFile={bannerFile}
            previewBanner={previewBanner}
            handleUpdateBanner={handleUpdateBanner}
          />
        </ContentContainer>

        <ContentContainerCollapse
          title={_t('product.lesson.details_title')}
          mt='2rem'
        >
          <DetailSection
            role={role}
            watch={watch}
            control={control}
            setValue={setValue}
            getValues={getValues}
            setError={setError}
            errors={errors}
            type={'lesson'}
          />
        </ContentContainerCollapse>

        <ContentContainerCollapse
          title={_t('product.class.materials_title')}
          mt='2rem'
        >
          <AttachmentSection
            watch={watch}
            control={control}
            errors={errors}
            uploadFiles={uploadFiles}
            isUploading={isUploading}
            handleChangeFiles={handleChangeFiles}
          />
        </ContentContainerCollapse>

        <Box mt='2rem'>
          <OccurrenceSection
            watch={watch}
            control={control}
            setValue={setValue}
            getValues={getValues}
            errors={errors}
          />
        </Box>

        <Box mt='2rem'>
          <ModuleSection
            watch={watch}
            control={control}
            setValue={setValue}
            getValues={getValues}
            errors={errors}
          />
        </Box>

        <ActionButton
          handleSubmit={handleSubmit}
          handleCreate={handleCreate}
          handleOpenPreview={handleOpenPreview}
          handleClose={handleClose}
          type={'lesson'}
        />
      </Box>
    </>
  )
}

export default LessonCreate

import Axios from 'axios'

import { walletPath } from 'configs/apiPath'
import {
  UserAddCardType,
  UserCard,
  WalletBankRequest,
  WalletTopUpRequest,
  WalletWriteOffRequest,
} from 'types'

export const addUserCard = async (data: UserCard): Promise<void> => {
  return Axios.post(walletPath.card(), data)
}

export const deleteUserCard = async ({
  type,
  enterpriseId,
}: {
  type: UserAddCardType
  enterpriseId?: string
}): Promise<void> => {
  return Axios.delete(walletPath.card(), {
    data: {
      type,
      ...(type === 'enterprise' && { enterpriseId }),
    },
  })
}

export const updateBankAccount = async ({
  data,
  onClose,
}: {
  data: WalletBankRequest
  onClose?: () => void
}): Promise<void> => {
  const { type, id, ...rest } = data
  return Axios.put(walletPath.updateBankAccount({ type, id }), rest)
}

export const topUp = async (data: WalletTopUpRequest) => {
  return Axios.post(walletPath.topup(), data)
}

export const writeOff = async ({
  data,
  onClose,
}: {
  data: WalletWriteOffRequest
  onClose?: () => void
}): Promise<void> => {
  return Axios.put(walletPath.writeOff(), data)
}

import React, { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import { Button } from '@chakra-ui/react'

import { useLanguages } from 'hooks'
import { useReadNotification } from 'queries/notification'
import { NotificationOnApp, RedirectActionType } from 'types'
import { getClassType } from 'utils/parser'

interface ActionButtonProps {
  data: NotificationOnApp
  isMSTAdmin?: boolean
}

const ActionButton: React.FC<ActionButtonProps> = ({
  data,
  isMSTAdmin = false,
}) => {
  const navigate = useNavigate()
  const { _t } = useLanguages()
  const { mutate: readNotification } = useReadNotification()

  const action = useMemo(() => {
    if (data) {
      const { actionType, classId, classType, assignmentId, requestID } = data
      switch (actionType) {
        case RedirectActionType.INVITED_STUDENT:
          if (classType && classId) {
            const type = getClassType(classType)
            if (type === 'class') {
              return {
                title: _t('notifications.view_class'),
                path: `/product/class/${classId}`,
              }
            }
            if (type === 'content') {
              return {
                title: _t('notifications.view_content'),
                path: `/product/content/${classId}`,
              }
            }
            return {
              title: _t('notifications.view_lesson'),
              path: `/product/lesson/${classId}`,
            }
          }
          break

        case RedirectActionType.TUTOR_BID_ADHOC:
          if (requestID) {
            return {
              title: _t('notifications.view_request'),
              path: `/my-courses/request-detail/${requestID}`,
            }
          }
          break

        case RedirectActionType.PURCHASED_CLASS:
          if (classType && classId) {
            const type = getClassType(classType)
            if (type === 'class') {
              return {
                title: _t('notifications.view_class'),
                path: isMSTAdmin
                  ? `/admin/session-management/preview/class/${classId}`
                  : `/product/class/${classId}`,
              }
            } else if (type === 'content') {
              return {
                title: _t('notifications.view_content'),
                path: isMSTAdmin
                  ? `/admin/session-management/preview/content/${classId}`
                  : `/product/content/${classId}`,
              }
            } else {
              return {
                title: _t('notifications.view_lesson'),
                path: isMSTAdmin
                  ? `/admin/session-management/preview/lesson/${classId}`
                  : `/product/lesson/${classId}`,
              }
            }
          }
          break

        case RedirectActionType.LEARNER_CANCEL_CLASS:
          return {
            title: _t('notifications.view_refund'),
            path: '/wallet?tab=refund',
          }

        case RedirectActionType.STUDENT_SUBMIT_ASSIGNMENT:
          if (assignmentId) {
            return {
              title: _t('notifications.view_assignment'),
              path: `/my-courses/assignments/${assignmentId}`,
            }
          }
          return {
            title: _t('notifications.view_assignment'),
            path: '/my-courses/assignments',
          }

        default:
          return null
      }
    }
    return null
  }, [_t, data, isMSTAdmin])

  if (action) {
    return (
      <Button
        variant='solidPrimary'
        ml='1rem'
        onClick={e => {
          !data.isRead && readNotification(data.id)
          e?.stopPropagation?.()
          navigate(action?.path)
        }}
      >
        {action.title}
      </Button>
    )
  }
  return null
}

export default ActionButton

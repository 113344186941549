import React from 'react'

import { Box, Button, Flex, useDisclosure } from '@chakra-ui/react'

import {
  ContentContainer,
  EditTeachingSubjectModal,
  TeachingSubjectLevelItemGroup,
} from 'components'
import { useLanguages } from 'hooks'
import { GroupedCombination } from 'types'

interface TeachingSubjectEditSectionProps {
  data: GroupedCombination[]
  handleUpdate: (data: GroupedCombination[]) => void
  handleRemove: (id: string) => void
}

const TeachingSubjectEditSection: React.FC<TeachingSubjectEditSectionProps> = ({
  data,
  handleUpdate,
  handleRemove,
}) => {
  const { _t } = useLanguages()
  const { onClose, onOpen, isOpen } = useDisclosure()

  const handleEditTeachingSubject = () => {
    onOpen()
  }

  return (
    <>
      <ContentContainer
        title={_t('centre.profile.teaching_subjects')}
        rightElement={
          <Button
            variant='outlinePrimary'
            px='1rem'
            size='md'
            onClick={handleEditTeachingSubject}
          >
            {_t('common.add')}
          </Button>
        }
        h='25rem'
      >
        <Box
          h='80%'
          overflowY='scroll'
          maxH='35rem'
          bg='#F2FBFF'
          mt='1rem'
          borderRadius='4px'
          p='1rem'
        >
          {data.length > 0 ? (
            <TeachingSubjectLevelItemGroup
              data={data}
              onRemoveItem={handleRemove}
            />
          ) : (
            <Flex
              justifyContent='center'
              alignItems='center'
              h='100%'
              fontSize='0.875rem'
            >
              {_t('centre.profile.teaching_subjects_empty')}
            </Flex>
          )}
        </Box>
      </ContentContainer>

      <EditTeachingSubjectModal
        isOpen={isOpen}
        onClose={onClose}
        userId={''}
        initData={data}
        isNoNeedUpdateForUser
        handleReturnData={handleUpdate}
      />
    </>
  )
}

export default TeachingSubjectEditSection

import Axios from 'axios'

import { classPath } from '../configs/apiPath'
import {
  AttachmentFileStatus,
  ClassUploadUrl,
  CreateClassType,
  ExportClassData,
  LessonOccurrence,
  UpdateClassType,
} from 'types'
import { getFileInfo } from 'utils/parser'

export const addClass = ({
  data,
  onInvalidateUrl,
}: {
  data: CreateClassType
  onInvalidateUrl?: () => void
}) => {
  return Axios.post(classPath.create(), data)
}

enum Status {
  public = 'public',
  draft = 'draft',
}

export const duplicateClass = async ({
  classId,
  tutorId,
  onInvalidateUrl,
}: {
  classId: string
  tutorId?: string
  onInvalidateUrl?: () => void
}) => {
  const res = await Axios.get(classPath.detail(classId))
  const classDetail = res.data.data

  if (classDetail.type === 'adhocRequest')
    throw new Error('You are not allowed to duplicate on-demand classes!')

  const invitedStudents = classDetail.invitedStudents.map((stud: any) => ({
    id: stud.id,
    opt: 'add',
  }))
  const invitedLearnerIds = classDetail.invitedStudents.map(
    (stud: any) => stud.id
  )
  const confirmedStudents = classDetail.confirmedStudents
    .filter((stud: any) => !invitedLearnerIds.includes(stud.id))
    .map((stud: any) => ({
      id: stud.id,
      opt: 'add',
    }))

  const formattedData = {
    name: `Copy of ${classDetail.name}`,
    description: classDetail.description,
    price: classDetail.price,
    enterprisePrice: classDetail?.enterprisePrice,
    languageId: classDetail?.language?.id,
    centerId: classDetail?.center?.id,
    tutorId: classDetail?.tutor?.id || tutorId,
    subjectId: classDetail?.subject?.id,
    teachingLevelId: classDetail?.teachingLevel?.id,
    maxStudent: classDetail.maxStudent || 1,
    minStudent: classDetail.minStudent || 0,
    requireMinimum: classDetail.requireMinimum || true,
    invitedStudents,
    confirmedStudents,
    type: classDetail.type,
    status: 'draft',
    sessions: classDetail.sessions?.map((o: any, index: number) => {
      const startDateTime = new Date(new Date().getTime() + 86400 * 7 * 1000)
      const endDateTime = new Date(new Date().getTime() + 86400 * 7 * 1000)
      startDateTime.setHours(new Date(o.startDateTime).getHours())
      startDateTime.setMinutes(new Date(o.startDateTime).getMinutes())
      startDateTime.setSeconds(new Date(o.startDateTime).getSeconds())
      startDateTime.setMilliseconds(new Date(o.startDateTime).getMilliseconds())
      endDateTime.setHours(new Date(o.endDateTime).getHours())
      endDateTime.setMinutes(new Date(o.endDateTime).getMinutes())
      endDateTime.setSeconds(new Date(o.endDateTime).getSeconds())
      endDateTime.setMilliseconds(new Date(o.endDateTime).getMilliseconds())

      return {
        index: index + 1,
        name: o.name,
        description: o.description,
        meetingPassword: o.meetingPassword,
        startDateTime: startDateTime.getTime(),
        endDateTime: endDateTime.getTime(),
        meetingType: o.meetingType,
        meetingLocation: o.meetingLocation,
      }
    }),
  }

  return Axios.post(classPath.create(), formattedData)
}

export const updateClass = ({
  data,
  classId,
  onInvalidateUrl,
}: {
  data: UpdateClassType
  classId: string
  onInvalidateUrl?: () => void
}) => {
  return Axios.put(classPath.update(classId), data)
}

export const deleteClass = ({
  classId,
  onClose,
  onInvalidateUrl,
}: {
  classId: string
  onClose?: () => void
  onInvalidateUrl?: () => void
}) => {
  return Axios.delete(classPath.delete(classId))
}

export const createUploadBannerClass = (
  classId: string,
  data: { fileName: string; contentType: string }
) => {
  return Axios.post<ClassUploadUrl>(classPath.createUploadBanner(classId), data)
}

// need to delete this createUploadAttachmentClass method
export const createUploadAttachmentClass = (
  classId: string,
  data: { status: string; fileName: string; contentType: string }
) => {
  return Axios.post<ClassUploadUrl>(
    classPath.createUploadAttachment(classId),
    data
  )
}
export const getUploadAttachmentClassUrl = ({
  classId,
  status,
  file,
}: {
  classId: string
  status: AttachmentFileStatus
  file: File
}) => {
  const { contentType } = getFileInfo(file)
  return Axios.post<ClassUploadUrl>(classPath.createUploadAttachment(classId), {
    status: status,
    fileName: file.name,
    contentType: contentType,
  })
}

export const uploadFileClass = async (url: string, formData: FormData) => {
  return Axios.post(url, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      'No-Need-Authorization': true,
    },
  })
}

export const downloadAllClassAttachments = (classId: string) => {
  return Axios.get(classPath.downloadAllAttachments(classId))
}

export const downloadClassAttachment = ({
  classId,
  key,
}: {
  classId: string
  key: string
}) => {
  return Axios.get(classPath.downloadAttachment(classId, key))
}

export const learnerCancelClass = async ({
  userId,
  classId,
  data,
  onClose,
}: {
  userId: string
  classId: string
  data: any
  onClose: () => void
}) => {
  return Axios.delete(classPath.learnerCancelClass(userId, classId), {
    data,
  })
}

export const tutorCancelClass = async ({
  userId,
  classId,
  data,
  onClose,
}: {
  userId: string
  classId: string
  data: any
  onClose: () => void
}) => {
  return Axios.delete(classPath.tutorCancelClass(userId, classId), {
    data,
  })
}

export const getClassDetail = async ({ classId }: { classId: string }) => {
  return Axios.get(classPath.detail(classId))
}

export const exportClassData = (data: ExportClassData) => {
  return Axios.get(classPath.export(data))
}

export const importClassesFile = (type: string, key: string) => {
  return Axios.post(classPath.importClasses, { type, key })
}

export const getImportClassesUrl = async (file: File) => {
  const fileNameSplit = file.name.split('.')
  const fileType = fileNameSplit[fileNameSplit.length - 1]
  const contentType = file.type

  return Axios.get(classPath.getImportClassesUrl(fileType, contentType))
}

export const initMeeting = () => {
  return Axios.post(classPath.initMeeting())
}

export const recurrence = ({ data }: { data: LessonOccurrence }) => {
  return Axios.post(classPath.recurrence(), data)
}

import Amplify from 'aws-amplify'

const oauth = {
  domain: process.env.REACT_APP_OAUTH_DOMAIN,
  scope: process.env.REACT_APP_OAUTH_SCOPE?.split(',').map(item => item.trim()),
  redirectSignIn: process.env.REACT_APP_OAUTH_REDIRECT_SIGNIN,
  redirectSignOut: process.env.REACT_APP_OAUTH_REDIRECT_SIGNOUT,
  responseType: process.env.REACT_APP_OAUTH_RESPONSE_TYPE,
}

Amplify.configure({
  aws_cognito_region: process.env.REACT_APP_AWS_COGNITO_REGION,
  aws_user_pools_id: process.env.REACT_APP_AWS_USER_POOLS_ID,
  aws_user_pools_web_client_id: process.env.REACT_APP_AWS_USER_POOLS_CLIENT_ID,
  aws_cognito_identity_pool_id: process.env.REACT_APP_AWS_IDENTITY_POOL_ID,
  Auth: {
    oauth,
  },
})

import React, { useCallback, useState } from 'react'

import { Box, Divider, Flex, Input, Text } from '@chakra-ui/react'

import { useLanguages } from 'hooks'
import { useUserGetPromoCodes } from 'queries/promo'
import { UserPromoDetail } from 'types'
import { formatAmount } from 'utils/parser'

import { ReactComponent as CloseIcon } from 'assets/images/close_gray_icon.svg'

interface PromoCodeProps {
  pricing: number
  activeCodes: UserPromoDetail[]
  handleActiveCodes: (value: UserPromoDetail[]) => void
}

const PromoCode: React.FC<PromoCodeProps> = ({
  pricing,
  activeCodes,
  handleActiveCodes,
}) => {
  const { _t } = useLanguages()
  const [promoCode, setPromoCode] = useState<string>('')
  const [errorMessage, setErrorMessage] = useState<string | undefined>('')

  const { data: promoCodes } = useUserGetPromoCodes({
    filter: 'valid',
    page: 1,
    limit: 100,
    order: 'desc',
  })
  const { data: expiredPromoCodes } = useUserGetPromoCodes({
    filter: 'expired',
    page: 1,
    limit: 100,
    order: 'desc',
  })

  const validatePromoCode = useCallback(
    (promo: UserPromoDetail) => {
      const expiredPromo = expiredPromoCodes?.data.find(
        item => item.code === promoCode
      )
      if (expiredPromo) {
        setErrorMessage('Promo code has expired')
        return false
      }
      if (activeCodes.findIndex(item => item.id === promo.id) !== -1) {
        setErrorMessage('Promo code has been used')
        return false
      }
      if (
        (!promo.stackable && activeCodes.length) ||
        activeCodes.findIndex(item => !item.stackable) !== -1
      ) {
        setErrorMessage('There are some promo codes that cannot be stacked')
        return false
      }
      if (pricing < promo.minSpend * 100) {
        setErrorMessage('Price is not met the minimum spend of this promo code')
        return false
      }
      return true
    },
    [activeCodes, expiredPromoCodes, pricing, promoCode]
  )

  const handleAddPromo = useCallback(() => {
    if (promoCode && promoCodes?.data?.length) {
      const promo = promoCodes.data.find(item => item.code === promoCode)
      if (promo && validatePromoCode(promo)) {
        setPromoCode('')
        setErrorMessage('')
        return handleActiveCodes([...activeCodes, promo])
      }
    }
    setErrorMessage('Invalid promo code')
  }, [promoCode, promoCodes, validatePromoCode, handleActiveCodes, activeCodes])

  const handleRemovePromo = useCallback(
    (id: string) => () => {
      handleActiveCodes(activeCodes.filter(item => item.id !== id))
    },
    [activeCodes, handleActiveCodes]
  )

  return (
    <>
      <Divider my='1.5rem' />
      <Flex
        p='0.5rem 1rem'
        justify='space-between'
        align='center'
        border='0.8px solid'
        borderColor={errorMessage ? 'error' : '#828282'}
        rounded='lg'
      >
        <Input
          value={promoCode}
          onChange={e => {
            setErrorMessage('')
            setPromoCode(e.target.value)
          }}
          placeholder={_t('payment.summary.promo_code')}
          variant='unstyled'
        />
        <Box
          fontWeight='600'
          color={!promoCode ? 'gray.100' : 'primary'}
          textTransform='uppercase'
          cursor={!promoCode ? 'not-allowed' : 'pointer'}
          onClick={() => promoCode && handleAddPromo()}
          ml='1rem'
        >
          {_t('payment.summary.apply')}
        </Box>
      </Flex>
      {errorMessage && (
        <Box mt='0.5rem'>
          <Text color='error' noOfLines={2}>
            {errorMessage}
          </Text>
        </Box>
      )}
      {activeCodes.map(item => (
        <Flex key={item.id} justify='space-between' mt='0.5rem'>
          <Box>{item.code}</Box>
          <Flex align='center'>
            <Text mr='1rem'>
              {item.discountUnit === 'flatRate' ? '-$' : ''}
              {item.discountUnit === 'flatRate'
                ? formatAmount(item.discountValue)
                : item.discountValue * 100}
              {item.discountUnit === 'flatRate' ? '' : '% off'}
            </Text>
            <CloseIcon
              width='0.68rem'
              height='0.68rem'
              cursor='pointer'
              onClick={handleRemovePromo(item.id)}
            />
          </Flex>
        </Flex>
      ))}
    </>
  )
}

export default PromoCode

import { useQuery } from 'react-query'

import { guestPath } from 'configs/apiPath'
import {
  AllSearchResult,
  CentreSearchResult,
  ClassSearchResult,
  ContentSearchResult,
  LessonSearchResult,
  QueryListResponse,
  SearchFilterBy,
  SearchParam,
  UserSearchResult,
} from 'types'

export const useGuestSearchAll = (params: SearchParam) => {
  return useQuery<QueryListResponse<AllSearchResult>>(
    guestPath.search({
      ...params,
      minPrice: isNaN(params.minPrice || NaN) ? undefined : params.minPrice,
      maxPrice: isNaN(params.maxPrice || NaN) ? undefined : params.maxPrice,
      meetingType: params.meetingType || 'all',
      filterBy: SearchFilterBy.ALL,
    })
  )
}

export const useGuestSearchLesson = (params: SearchParam) => {
  return useQuery<QueryListResponse<LessonSearchResult>>(
    guestPath.search({
      ...params,
      minPrice: isNaN(params.minPrice || NaN) ? undefined : params.minPrice,
      maxPrice: isNaN(params.maxPrice || NaN) ? undefined : params.maxPrice,
      meetingType: params.meetingType || 'all',
      filterBy: SearchFilterBy.LESSON,
    })
  )
}

export const useGuestSearchClass = (params: SearchParam) => {
  return useQuery<QueryListResponse<ClassSearchResult>>(
    guestPath.search({
      ...params,
      minPrice: isNaN(params.minPrice || NaN) ? undefined : params.minPrice,
      maxPrice: isNaN(params.maxPrice || NaN) ? undefined : params.maxPrice,
      meetingType: params.meetingType || 'all',
      filterBy: SearchFilterBy.CLASS,
    })
  )
}

export const useGuestSearchContent = (params: SearchParam) => {
  return useQuery<QueryListResponse<ContentSearchResult>>(
    guestPath.search({
      ...params,
      minPrice: isNaN(params.minPrice || NaN) ? undefined : params.minPrice,
      maxPrice: isNaN(params.maxPrice || NaN) ? undefined : params.maxPrice,
      meetingType: params.meetingType || 'all',
      filterBy: SearchFilterBy.CONTENT,
    })
  )
}

export const useGuestSearchInstructor = (params: SearchParam) => {
  return useQuery<QueryListResponse<UserSearchResult>>(
    guestPath.search({
      ...params,
      filterBy: SearchFilterBy.TUTOR,
    })
  )
}

export const useGuestSearchCentre = (params: SearchParam) => {
  return useQuery<QueryListResponse<CentreSearchResult>>(
    guestPath.search({
      ...params,
      filterBy: SearchFilterBy.CENTRE,
    })
  )
}

import React, { useState } from 'react'

import { Box, BoxProps, Container, Flex } from '@chakra-ui/react'

import Sidebar from '../../Admin/Sidebar'
import { enterpriseAdminRoutes } from 'configs/enterpriseAdminRoutes'
import { Header } from 'shared/containers'
import { Login } from 'types'

interface LayoutProps extends BoxProps {
  containerProps?: BoxProps
  topElement?: React.ReactElement
}

const Layout: React.FC<LayoutProps> = ({
  children,
  containerProps,
  topElement,
  ...boxProps
}) => {
  const [sideBarOpen, setSideBarOpen] = useState<boolean>(true)
  const toggleSidebar = () => setSideBarOpen(!sideBarOpen)

  return (
    <Box {...boxProps}>
      <Header
        sideBarOpen={sideBarOpen}
        toggleSidebar={toggleSidebar}
        loginType={Login.UserName}
      />
      <Flex>
        <Sidebar sideBarStatus={sideBarOpen} routes={enterpriseAdminRoutes} />
        <Box
          ml={sideBarOpen ? '16.5rem !important' : '0rem !important'}
          w={sideBarOpen ? 'calc(100% - 16.5rem)' : '100%'}
          transition='0.5s'
          bgColor='containerBg'
        >
          <Box w='full'>{topElement}</Box>
          <Container
            maxWidth='100%'
            minHeight='calc(100vh - 4.5rem)'
            px='5rem'
            py='2.25rem'
            overflow='auto'
            {...containerProps}
          >
            {children}
          </Container>
        </Box>
      </Flex>
    </Box>
  )
}

export default Layout

import React, { useCallback, useEffect, useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'

import { Box, Button, Flex, Radio, RadioGroup } from '@chakra-ui/react'

import { Input, Modal, ModalProps, Option, Select } from 'components'
import { adminPath, enterprise } from 'configs/apiPath'
import { useAddUser } from 'queries/user'
import { Centre, UserRole, UserRoleType } from 'types'
import { EMAIL_PATTERN } from 'utils/regexp'

interface AddUserModalProps extends Omit<ModalProps, 'children'> {
  role: {
    name: string
    type: UserRoleType
  }
}

interface AddUserField {
  email: string
  center?: Option
  enterprise?: Option
}

const formatCenterOption = (raw: Centre[]) => {
  return raw.map(item => ({
    label: item.name,
    value: item.id,
  }))
}

const AddUserModal: React.FC<AddUserModalProps> = ({
  isOpen,
  onClose,
  role,
}) => {
  const { control, handleSubmit, formState, watch } = useForm<AddUserField>({
    mode: 'onChange',
    defaultValues: {
      email: '',
    },
  })
  const { isDirty, isValid } = formState
  const { mutate: addUser, isLoading, isSuccess } = useAddUser()
  const [tutorType, setTutorType] = React.useState(UserRole.Tutor)

  const isAddingTutor = useMemo(() => role.type === UserRole.Tutor, [role])
  const isEnterpriseTutor = useMemo(
    () => tutorType === UserRole.EnterpriseTutor,
    [tutorType]
  )
  const enterpriseId = useMemo(() => watch('enterprise')?.value, [watch])

  useEffect(() => {
    onClose()
  }, [isSuccess, onClose])

  const handleAddUser = useCallback(
    (values: AddUserField) => {
      addUser({
        email: values.email,
        // @ts-ignore
        roleName: isAddingTutor ? tutorType : role.type,
        centerId: values.center?.value,
        enterpriseId: values.enterprise?.value,
      })
    },
    [addUser, isAddingTutor, role.type, tutorType]
  )

  return (
    <Modal isOpen={isOpen} onClose={onClose} size='md'>
      <Box textStyle='headingModalText' textAlign='center'>
        {`Add New ${role.name}`}
      </Box>
      <Box as='form' mt='1.5rem' onSubmit={handleSubmit(handleAddUser)}>
        {isAddingTutor && (
          <RadioGroup
            onChange={setTutorType as (index: string) => void}
            value={tutorType}
          >
            <Flex justifyContent='center' mb='1rem'>
              <Radio mr='1rem' value={UserRole.Tutor}>
                Private Tutor
              </Radio>
              <Radio value={UserRole.EnterpriseTutor}>Enterprise Tutor</Radio>
            </Flex>
          </RadioGroup>
        )}

        <Controller
          as={Input}
          control={control}
          name='email'
          label='Email'
          rules={{
            required: 'Email is required',
            pattern: {
              value: EMAIL_PATTERN,
              message: 'Invalid email address',
            },
          }}
          defaultValue=''
        />

        {(role.type === UserRole.EnterpriseAdmin || isEnterpriseTutor) && (
          <Controller
            as={Select}
            control={control}
            name='enterprise'
            label='Enterprise'
            rules={{
              required: 'Enterprise is required',
            }}
            placeholder='Select Enterprise to Assign'
            optionUrl={enterprise.list({ page: 1, limit: 80 })}
            formatOption={formatCenterOption}
            containerProps={{
              mt: '1rem',
            }}
            selectProps={{
              menuHeight: '6.25rem',
            }}
          />
        )}

        {([UserRole.Student].includes(role.type) || isEnterpriseTutor) && (
          <Controller
            as={Select}
            control={control}
            name='center'
            label='Centre'
            rules={{
              required: 'Centre is required',
            }}
            placeholder='Select Centre to Assign'
            optionUrl={
              enterpriseId
                ? enterprise.centresForEnterprise(enterpriseId, 1, 20)
                : adminPath.center.list({ page: 1, limit: 80 })
            }
            formatOption={formatCenterOption}
            containerProps={{
              mt: '1rem',
            }}
            selectProps={{
              menuHeight: '6.25rem',
            }}
            isDisabled={!isEnterpriseTutor && !enterpriseId}
          />
        )}

        <Flex mt='2rem' justifyContent='center' alignItems='center'>
          <Button
            variant='solidPrimary'
            textTransform='uppercase'
            px='5rem'
            type='submit'
            disabled={isLoading || !isValid || !isDirty}
          >
            Add
          </Button>
          <Button
            variant='transparent'
            textTransform='uppercase'
            px='5rem'
            onClick={onClose}
          >
            Cancel
          </Button>
        </Flex>
      </Box>
    </Modal>
  )
}

export default AddUserModal

import { Hub } from 'aws-amplify'
import Axios from 'axios'

import storageKey from './storageKey'
import { confirmForgotPassword } from 'apis/user'
import { authPath, profilePath } from 'configs/apiPath'
import { Login, LoginType, UserRole } from 'types'
import { getData, storeData } from 'utils/helper'

class Auth {
  private static instance: Auth

  private constructor() {}

  public static getInstance(): Auth {
    if (!Auth.instance) {
      Auth.instance = new Auth()
    }

    return Auth.instance
  }

  async signUp({
    email,
    password,
    firstName,
    lastName,
    birthday,
    roleName,
  }: any) {
    try {
      await Axios.post(authPath.signUp, {
        email,
        password,
        firstName,
        lastName,
        birthday,
        roleName,
      })
    } catch (err) {
      console.log(err)
      throw err
    }
  }

  checkRightSignInRole({
    signInInfo,
    type,
  }: {
    signInInfo: any
    type: LoginType
  }) {
    if (signInInfo && (type === Login.UserName || type === Login.Social)) {
      const role = getData(storageKey.role)
      const roleTypes = signInInfo.roles
      const enterpriseRoles = [
        UserRole.MSTAdmin,
        UserRole.MSTSuperAdmin,
        UserRole.EnterpriseSuperAdmin,
        UserRole.EnterpriseAdmin,
        UserRole.CenterAdmin,
        UserRole.EnterpriseStudent,
        UserRole.EnterpriseTutor,
      ]

      // Prevent Enterprise accounts for sign in with multiple roles
      const isPrivateStudent =
        roleTypes?.includes(UserRole.Student) &&
        !roleTypes.some((roleType: UserRole) =>
          enterpriseRoles.includes(roleType)
        )
      const isPrivateTutor =
        roleTypes?.includes(UserRole.Tutor) &&
        !roleTypes.some((roleType: UserRole) =>
          enterpriseRoles.includes(roleType)
        )

      if (role === UserRole.Student && isPrivateStudent) {
        return true
      }

      if (role === UserRole.Tutor && isPrivateTutor) {
        return true
      }

      if (role === UserRole.Enterprise) {
        const filterRoles = roleTypes?.filter(
          (item: string) => item !== UserRole.Student && item !== UserRole.Tutor
        )

        if (filterRoles?.length) {
          return true
        }
      }

      return false
    }

    return true
  }

  async signIn({
    email,
    password,
    type = Login.UserName,
    code,
    roleName,
  }: {
    email?: string
    password?: string
    type?: LoginType
    code?: string
    roleName?: string
  }): Promise<AuthUser | any> {
    try {
      let payload = {}

      if (type === Login.Social) {
        payload = { code, type, roleName }
      } else {
        payload = { email, password, type }
      }

      const response = await Axios.post(authPath.signIn, payload)

      const { data: authData } = response.data

      if (authData.changePassword) {
        return authData
      }

      const isRightRoleLogin = this.checkRightSignInRole({
        signInInfo: authData,
        type,
      })

      if (!isRightRoleLogin) {
        return 'wrongRole'
      }

      storeData(storageKey.accessToken, authData.accessToken)
      storeData(storageKey.refreshToken, authData.refreshToken)
      storeData(
        storageKey.expiresAt,
        new Date().getTime() + authData.expiresIn * 1000
      )
      storeData(storageKey.userId, authData?.user?.id || '')
      storeData(storageKey.loginType, type)

      const roles =
        authData?.roles?.map((r: any) => ({ name: r, type: r })) || []
      storeData(storageKey.roles, JSON.stringify(roles))

      if (type === 'guest') {
        storeData(storageKey.userId, authData?.user?.userId || '')
        Hub.dispatch('customAuth', {
          event: 'guestSignIn',
        })
      } else {
        Hub.dispatch('customAuth', {
          event: 'signIn',
          data: {
            user: authData.user,
            roles,
          },
        })
      }

      return authData
    } catch (err) {
      console.log(err)
      throw err
    }
  }

  async signOut() {
    // TODO

    Hub.dispatch('customAuth', {
      event: 'signOut',
    })
  }

  forgotPasswordSubmit(
    username: string,
    code: string,
    password: string
  ): Promise<void> {
    return Promise.resolve()
  }

  currentUserId() {
    return getData(storageKey.userId) || ''
  }

  currentlyGuest() {
    const loginType = getData(storageKey.loginType)
    const token = getData(storageKey.accessToken)

    if (!!token && loginType === Login.Guest) return true
    return false
  }

  currentAuthenticatedUser() {
    const userId = getData(storageKey.userId)
    if (!userId) {
      return Promise.reject('Not sign in')
    }
    return Axios.get(profilePath.profile(userId || ''))
  }

  getAccessToken(): string {
    return getData(storageKey.accessToken) || ''
  }

  confirmForgotPassword({ email, code, password }: any): Promise<any> {
    return confirmForgotPassword({ email, code, password })
  }

  async changePassword({ email, password, session }: any) {
    try {
      const response = await Axios.post(authPath.changePassword, {
        email,
        newPassword: password,
        session,
      })

      const { data: authData } = response.data

      storeData(storageKey.accessToken, authData.accessToken)
      storeData(storageKey.refreshToken, authData.refreshToken)
      storeData(
        storageKey.expiresAt,
        new Date().getTime() + authData.expiresIn * 1000
      )
      storeData(storageKey.userId, authData?.user?.id || '')
      storeData(storageKey.loginType, Login.UserName)

      const roles =
        authData?.roles?.map((r: any) => ({ name: r, type: r })) || []
      storeData(storageKey.roles, JSON.stringify(roles))

      Hub.dispatch('customAuth', {
        event: 'signIn',
        data: {
          user: authData.user,
          roles,
        },
      })

      return authData
    } catch (err) {
      console.log(err)
      throw err
    }
  }
}

const auth = Auth.getInstance()

export default auth

export interface AuthUser {
  accessToken: string
  refreshToken: string
  session?: string
  email?: string
  expiresIn: number
  user: {
    id: string
    firstName?: string
    lastName?: string
    email?: string
  }
  roles?: string[]
}

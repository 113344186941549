import React from 'react'

import { Link, Text } from '@chakra-ui/react'

interface MenuItemProps {
  isLast?: boolean
  to: string
  onClick?: () => void
}

const MenuItem: React.FC<MenuItemProps> = ({
  children,
  isLast,
  to = '/',
  onClick,
  ...rest
}) => {
  return (
    <Link href={to} textStyle='navItem' onClick={onClick}>
      <Text display='block' {...rest}>
        {children}
      </Text>
    </Link>
  )
}

export default MenuItem

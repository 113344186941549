import React, { useCallback, useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'

import { Box } from '@chakra-ui/react'

import BankInfoSection from '../containers/BankInfoSection'
import { WithdrawalCreateForm } from '../form'
import CreateWithdrawalForm from './CreateForm'
import { HeadingSection, LoadingSpinner } from 'components'
import { useLanguages, useQueryParams } from 'hooks'
import { useAdminCreateWithdrawal } from 'queries/transaction'
import { useUserWallet } from 'queries/wallet'
import { ApiUserType, PaymentType } from 'types'

interface CreateWithdrawalProps {
  backTitle: string
  handleBack?: () => void
}

const CreateWithdrawal: React.FC<CreateWithdrawalProps> = ({
  backTitle,
  handleBack,
}) => {
  const { _t } = useLanguages()
  const query = useQueryParams()
  const { control, errors, handleSubmit } = useForm<WithdrawalCreateForm>({
    mode: 'onChange',
    defaultValues: {
      amount: undefined,
      paymentType: 'bank',
      remarks: '',
    },
  })

  const userId = useMemo(() => (query.userId as string) || '', [query])
  const centerId = useMemo(() => (query.centerId as string) || '', [query])
  const walletRequest = useMemo(() => {
    if (centerId) {
      return { type: 'center' as ApiUserType, id: centerId }
    }
    return { type: 'user' as ApiUserType, id: userId }
  }, [centerId, userId])
  const { data: walletInfo, isLoading } = useUserWallet(walletRequest)
  const { mutate: createWithdrawal, isSuccess } = useAdminCreateWithdrawal()
  const walletData = useMemo(() => walletInfo?.data, [walletInfo])
  const availableWithdrawBalance = useMemo(
    () =>
      (walletData?.wallet?.availableBalance || 0) +
      (walletData?.wallet?.topUpBalance || 0),
    [walletData]
  )

  const handleCreateWithdrawal = useCallback(
    (value: WithdrawalCreateForm) => {
      const { amount, paymentType, remarks } = value
      const formattedData = {
        userId: userId || undefined,
        centerId: centerId || undefined,
        amount: Math.round((amount || 0) * 100),
        paymentType: paymentType as PaymentType,
        remarks,
      }

      createWithdrawal({ data: formattedData })
    },
    [createWithdrawal, userId, centerId]
  )

  const buttonData = useMemo(() => {
    const result = []

    if (handleSubmit) {
      result.push({
        label: _t('common.create'),
        onClick: handleSubmit(handleCreateWithdrawal),
      })
    }

    return result
  }, [handleSubmit, handleCreateWithdrawal, _t])

  useEffect(() => {
    if (isSuccess) {
      handleBack && handleBack()
    }
  }, [handleBack, isSuccess])

  return (
    <Box>
      <HeadingSection
        title={_t('withdrawal.management.create_title')}
        description={_t('withdrawal.management.create_description')}
        backLabel={backTitle}
        handleToBack={handleBack}
        buttonData={buttonData}
        isAdminStyle
      />
      <Box p='2rem 5rem 0'>
        {isLoading && <LoadingSpinner />}
        {!isLoading && walletData && <BankInfoSection data={walletData} />}
        <CreateWithdrawalForm
          control={control}
          errors={errors}
          availableLimit={availableWithdrawBalance}
        />
      </Box>
    </Box>
  )
}

export default CreateWithdrawal

import React, { useCallback, useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'

import {
  Box,
  Button,
  Checkbox,
  CheckboxGroup,
  Flex,
  FormControl,
  Text,
} from '@chakra-ui/react'
import { format } from 'date-fns'

import { Textarea } from 'components'
import Modal, { ModalProps } from 'components/Modal'
import Select, { Option } from 'components/Select'
import { useLanguages } from 'hooks'
import { ClassPreview, ClassSessionItem } from 'types'
import { reasonLearnerCancelClassOptions } from 'utils/constant'

export interface CancelLessonProps extends Omit<ModalProps, 'children'> {
  onSubmit: (reason: string, packageIds: string[] | undefined) => void
  data: ClassPreview | undefined
}

interface CancelForm {
  reasonSelect: Option
  reasonText: string
}

const CancelLessonModal: React.FC<CancelLessonProps> = ({
  isOpen,
  onClose,
  onSubmit,
  data,
}) => {
  const { _t } = useLanguages()

  const { handleSubmit, control, watch } = useForm<CancelForm>({
    defaultValues: {
      reasonSelect: {
        value: '',
        label: '',
      },
      reasonText: '',
    },
  })

  const [packageIds, setPackageIds] = useState<string[]>([])
  const [reasonPage, setReasonPage] = useState<boolean>(false)

  const packagesToCancel = useMemo(() => {
    if (data && data.packages && data.packages.length > 0) {
      return data.packages![data.packages!.length - 1].sessions[0]
        .startDateTime -
        Date.now() >
        3600000
        ? true
        : false
    } else {
      return false
    }
  }, [data])

  const isDisableConfirmButton = useMemo(
    () => (watch('reasonText').length > 15 ? false : true),
    [watch]
  )

  const handleCancel = () => {
    setReasonPage(false)
    setPackageIds([])
    onClose()
  }

  const handleConfirm = useCallback(
    (value: CancelForm) => {
      const { reasonSelect, reasonText } = value
      let formatData = 'No content'

      if (reasonSelect) {
        formatData = `${reasonSelect.value} - ${reasonText}`
      }

      onSubmit(formatData, packageIds)
    },
    [onSubmit, packageIds]
  )

  const getDateRange = useCallback((sessions: ClassSessionItem[]) => {
    const range = sessions.reduce(
      (prev, curr) => {
        return {
          startDateTime:
            prev.startDateTime < curr.startDateTime
              ? prev.startDateTime
              : curr.startDateTime,
          endDateTime:
            prev.endDateTime > curr.endDateTime
              ? prev.endDateTime
              : curr.endDateTime,
        }
      },
      {
        startDateTime: sessions[0].startDateTime,
        endDateTime: sessions[0].endDateTime,
      }
    )
    return `${format(range.startDateTime, 'dd MMM yyyy')} - ${format(
      range.endDateTime,
      'dd MMM yyyy'
    )}`
  }, [])

  const handleChangeCheckbox = useCallback(
    (option: string[]) => setPackageIds(option),
    []
  )

  const handleSelectAll = () => {
    if (packageIds.length === allAvailablePackageIds!.length) {
      setPackageIds([])
    } else {
      setPackageIds(allAvailablePackageIds!)
    }
  }

  const allAvailablePackageIds = useMemo(() => {
    if (data) {
      let packages = data!.packages!.map(pack => {
        if (pack.sessions[0].startDateTime - Date.now() > 3600000) {
          return pack.id
        }
      })
      return packages.filter(id => id !== undefined) as string[]
    }
  }, [data])

  const generateContent = () => {
    if (!reasonPage) {
      return !packagesToCancel ? (
        <Text fontSize='0.875rem' mx='2rem' align='center'>
          {_t('product.lesson.cancel_class_empty')}
        </Text>
      ) : (
        <Flex direction='column' justify='flex-end'>
          <Checkbox
            justifyContent='flex-end'
            onChange={handleSelectAll}
            isChecked={
              allAvailablePackageIds && packageIds === allAvailablePackageIds
            }
            px='1rem'
            py='0.5rem'
          >
            <Text>{_t('common.select_all')}</Text>
          </Checkbox>
          <Flex
            maxH='15.5rem'
            overflowY='auto'
            overflowX='hidden'
            px='0.5rem'
            py='0.5rem'
            direction='column'
          >
            <FormControl>
              <CheckboxGroup value={packageIds} onChange={handleChangeCheckbox}>
                {data?.packages?.map(item => {
                  const today = Date.now()
                  if (item.sessions[0].startDateTime - today > 3600000) {
                    return (
                      <Box
                        boxShadow='lg'
                        rounded='lg'
                        mb='0.5rem'
                        bg={packageIds?.includes(item.id) ? 'lavender' : ''}
                        px='1rem'
                        py='0.5rem'
                      >
                        <Checkbox value={item.id} key={item.id}>
                          <Flex
                            width={{ base: '17rem', md: '30rem' }}
                            direction={{ base: 'column', md: 'row' }}
                            justify='space-between'
                            align='center'
                          >
                            <Text>{item.name}</Text>
                            <Text color='primary'>
                              {getDateRange(item.sessions)}
                            </Text>
                          </Flex>
                        </Checkbox>
                      </Box>
                    )
                  }
                })}
              </CheckboxGroup>
            </FormControl>
          </Flex>
        </Flex>
      )
    } else {
      return (
        <>
          <Text fontSize='0.875rem' my='1rem'>
            {_t('product.lesson.cancel_description')}
          </Text>
          <Box mb='1.5rem' w='100%'>
            <Controller
              as={Select}
              control={control}
              name='reasonSelect'
              label={_t('product.lesson.cancel_reason')}
              defaultOptions={reasonLearnerCancelClassOptions}
              placeholder={_t('product.lesson.placeholder_cancel_reason')}
              labelProps={{ fontSize: '0.75rem' }}
            />
          </Box>
          <Box w='100%'>
            <Controller
              as={Textarea}
              control={control}
              name='reasonText'
              fontSize='0.875rem'
              placeholder={_t('product.lesson.placeholder_cancel_reason_text')}
            />
          </Box>
          <Text w='100%' color='gray.300' fontSize='0.625rem' textAlign='right'>
            {_t('product.lesson.cancel_reason_text_limit')}
          </Text>
        </>
      )
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={handleCancel} size='xl'>
      <Flex direction='column' alignItems='center' w='100%'>
        <Text textStyle='headingModalText' align='center' mb='2rem'>
          {_t('product.lesson.cancel_title')}
        </Text>
        <Text mb='1rem' align='center'>
          {data?.name}
        </Text>
        {generateContent()}
        <Flex mt='2rem'>
          <Button
            variant='solidPrimary'
            mr='0.5rem'
            onClick={
              reasonPage
                ? handleSubmit(handleConfirm)
                : () => setReasonPage(true)
            }
            isDisabled={reasonPage ? isDisableConfirmButton : false}
            textTransform='uppercase'
          >
            {reasonPage ? _t('common.confirm') : _t('common.next')}
          </Button>
          <Button
            variant='transparent'
            ml='0.5rem'
            onClick={handleCancel}
            textTransform='uppercase'
          >
            {_t('common.cancel')}
          </Button>
        </Flex>
      </Flex>
    </Modal>
  )
}

export default CancelLessonModal

import React, { ChangeEvent, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

import { Box } from '@chakra-ui/react'

import {
  DetailEditSection,
  EnterpriseEditSection,
  TeachingSubjectEditSection,
} from '../containers'
import { AvatarBannerGroup, HeadingSection } from 'components'
import { useLanguages, useLoading } from 'hooks'
import { useAddCentre } from 'queries/centre'
import { useEnterprises } from 'queries/enterprise'
import { useUpdateBankAccount } from 'queries/wallet'
import { BankAccountEdit } from 'shared/containers/BankAccount'
import {
  BankDetailForm,
  GroupedCombination,
  InfoCentreForm,
  UserRole,
  UserRoleType,
  WalletBankRequest,
} from 'types'
import { responsivePadding } from 'utils/constant'
import { getErrorMessage } from 'utils/parser'

interface CreateCentreProfileContainerProps {
  enterpriseId?: string
  backLabel: string
  handleToBack: () => void
  role?: UserRoleType
}

export interface CreateCentreForm
  extends Omit<InfoCentreForm, 'enterpriseId' | 'subjectCombinations'> {}

const CreateCentreProfileContainer: React.FC<CreateCentreProfileContainerProps> = ({
  enterpriseId = '',
  backLabel,
  handleToBack,
  role,
}) => {
  const { _t } = useLanguages()
  const { setLoading } = useLoading()
  const [avatar, setAvatar] = useState<string>()
  const [avatarFile, setAvatarFile] = useState<File>()
  const [banner, setBanner] = useState<string>()
  const [bannerFile, setBannerFile] = useState<File>()
  const [combinationData, setCombinationData] = useState<GroupedCombination[]>(
    []
  )
  const isSuperAdmin =
    role === UserRole.MSTSuperAdmin || role === UserRole.MSTAdmin

  const { mutate: addNewCentre, isSuccess, data: resData } = useAddCentre(
    enterpriseId
  )
  const { mutate: updateBankAccount } = useUpdateBankAccount({
    type: 'center',
    id: '',
  })
  const { data: enterprisesList } = useEnterprises({
    page: 1,
    limit: 100,
  })

  const {
    handleSubmit,
    control,
    formState: { dirtyFields },
    getValues,
  } = useForm<CreateCentreForm>({
    defaultValues: {
      enterprise: { value: '', label: '' },
      name: '',
      email: '',
      active: true,
      description: '',
      contactNumber: '',
      address: '',
      youtubeLink: '',
      languages: [],
    },
  })

  const {
    control: paymentControl,
    getValues: getValuePayment,
    errors,
  } = useForm<BankDetailForm>({
    defaultValues: {
      bankAccountName: '',
      bankName: '',
      bankAccountNumber: '',
      country: {
        label: '',
        value: '',
      },
      bankRemarks: '',
    },
  })

  const updateCombinationData = (data: GroupedCombination[]) => {
    setCombinationData(data)
  }

  const handleRemoveCombinationItem = (combinationId: string) => {
    setCombinationData(oldCombinations =>
      oldCombinations.map(combination => ({
        ...combination,
        teachingLevels: combination.teachingLevels.filter(
          item => item.subjectCombinationId !== combinationId
        ),
      }))
    )
  }

  const handleResetFile = () => {
    setAvatarFile(undefined)
    setBannerFile(undefined)
  }

  const handleAddNewCentre = (data: CreateCentreForm) => {
    const { languages } = getValues()
    const changeLanguageIds = languages?.map(item => item.value)
    const addedLanguages = changeLanguageIds?.map(item => ({
      opt: 'add',
      id: item,
    }))
    const totalChangedLanguages = addedLanguages?.length
      ? [...addedLanguages]
      : undefined

    const subjectCombinations = combinationData.reduce<
      { opt: string; id: string }[]
    >((acc, cur) => {
      return [
        ...acc,
        ...cur.teachingLevels.map(item => ({
          opt: 'add',
          id: item.subjectCombinationId as string,
        })),
      ]
    }, [])
    let newData = Object.assign({}, data)
    delete newData.enterprise
    const formattedData = {
      ...newData,
      enterpriseId: !!enterpriseId ? enterpriseId : data.enterprise?.value,
      subjectCombinations: subjectCombinations,
      languages: totalChangedLanguages,
    }

    addNewCentre({
      data: formattedData,
      avatarFile: avatarFile,
      bannerFile: bannerFile,
      resetFile: handleResetFile,
      onClose: handleToBack,
    })
  }

  const handleUpdateAvatar = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0]
    if (file) {
      setAvatar(URL.createObjectURL(file))
      setAvatarFile(file)
    }
  }

  const handleUpdateBanner = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0]
    if (file) {
      setBanner(URL.createObjectURL(file))
      setBannerFile(file)
    }
  }

  const enableCreateBtn = () => {
    if (
      dirtyFields &&
      dirtyFields.hasOwnProperty('name') &&
      dirtyFields.hasOwnProperty('email')
    ) {
      return false
    }
    return true
  }

  useEffect(() => {
    const handleUpdateBankAccount = async (centerId: string) => {
      try {
        setLoading(true)
        const {
          bankAccountName,
          bankAccountNumber,
          bankName,
          bankRemarks,
          country,
        } = getValuePayment()
        const formattedData = {
          type: 'center',
          id: centerId,
          bankAccountName,
          bankAccountNumber,
          bankName,
          bankRemarks,
          country: country.value,
        } as WalletBankRequest

        await updateBankAccount({ data: formattedData })
      } catch (err) {
        console.log(getErrorMessage(err))
      } finally {
        setLoading(false)
      }
    }
    if (isSuccess && resData) {
      const createdCenterId = resData.data.data.id
      handleUpdateBankAccount(createdCenterId)
    }
  }, [getValuePayment, isSuccess, resData, setLoading, updateBankAccount])

  return (
    <>
      <HeadingSection
        title={_t('centre.profile.add_title')}
        backLabel={backLabel}
        handleToBack={handleToBack}
        buttonData={[
          {
            label: _t('common.create'),
            isDisabled: enableCreateBtn(),
            onClick: handleSubmit(handleAddNewCentre),
          },
          {
            label: _t('common.cancel'),
            isSecondary: true,
            onClick: handleToBack,
          },
        ]}
        isAdminStyle
      />

      {!!enterprisesList?.data?.length && isSuperAdmin && (
        <Box px={responsivePadding} my='2.5rem'>
          <EnterpriseEditSection
            control={control}
            list={enterprisesList.data}
          />
        </Box>
      )}

      <Box pb='2rem'>
        <AvatarBannerGroup
          avatar={avatar}
          banner={banner}
          handleUpdateAvatar={handleUpdateAvatar}
          handleUpdateBanner={handleUpdateBanner}
        />

        <Box px={responsivePadding}>
          <Box mt='1.5rem'>
            <DetailEditSection control={control} isAllowUpdateStatus={true} />
          </Box>

          <Box mt='2.5rem'>
            <TeachingSubjectEditSection
              data={combinationData}
              handleUpdate={updateCombinationData}
              handleRemove={handleRemoveCombinationItem}
            />
          </Box>

          {isSuperAdmin && (
            <Box mt='2.5rem'>
              <BankAccountEdit control={paymentControl} errors={errors} />
            </Box>
          )}
        </Box>
      </Box>
    </>
  )
}

export default CreateCentreProfileContainer

import React from 'react'

import { Box, Button, Flex, SimpleGrid } from '@chakra-ui/react'

import Modal, { ModalProps } from 'components/Modal'

import confirmBg from 'assets/images/games-graphic.svg'

export interface ConfirmGuestModalProps extends Omit<ModalProps, 'children'> {
  onCancel: () => void
  onConfirm: () => void
}

const ConfirmGuestModal: React.FC<ConfirmGuestModalProps> = ({
  onCancel,
  onConfirm,
  isOpen,
  onClose,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size='2xl'
      modalBodyProps={{ px: { base: '2.5rem', md: '4.5rem' } }}
    >
      <Flex direction='column' alignItems='center' w='100%'>
        <Box
          as='h1'
          textAlign='center'
          mb={{ base: '1.5rem', md: '2rem' }}
          className={'blue-purple-gradient-text'}
        >
          Try KNOVOLE and win prizes!
        </Box>
        <Flex
          flexDirection={{ base: 'column', md: 'row' }}
          width='auto'
          alignSelf='center'
        >
          <Box
            as='img'
            src={confirmBg}
            h={{ base: '10rem', md: '14rem' }}
            w='100%'
            objectFit='contain'
          />
          <Box
            color='primaryDark'
            fontWeight='500'
            fontSize={{ base: '1rem', md: '1.625rem' }}
            lineHeight='2.15rem'
            my='1rem'
            alignSelf='flex-start'
            textAlign={{ base: 'center', md: 'initial' }}
            width='100%'
            className={'blue-purple-gradient-text'}
            marginLeft='0.675rem'
          >
            <p>
              Play our daily KNOVOLE game to get a chance to win prizes,
              vouchers, and promotions!
            </p>
          </Box>
        </Flex>
        <SimpleGrid
          columns={[1, 1, 2]}
          spacing='1rem'
          mt={{ base: '0.5rem', md: '2rem' }}
        >
          <Button variant='solidPrimary' onClick={onConfirm}>
            PLAY KNOVOLE
          </Button>
          <Button variant='outlinePrimary' onClick={onCancel}>
            CONTINUE EXPLORING
          </Button>
        </SimpleGrid>
      </Flex>
    </Modal>
  )
}

export default ConfirmGuestModal

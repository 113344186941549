import { useMutation, useQuery } from 'react-query'

import { useToastError } from './helpers'
import {
  createCombination,
  deleteCombination,
  editCombination,
} from 'apis/subjectCombination'
import { adminPath } from 'configs/apiPath'
import { useInvalidateUrl, useLanguages, useToast } from 'hooks'
import {
  CombinationData,
  ListingParams,
  QueryListResponse,
  SubjectCombination,
} from 'types'

export const useSubjectCombination = ({
  page,
  limit,
  search,
  searchBy,
}: ListingParams) => {
  return useQuery<QueryListResponse<SubjectCombination>>(
    adminPath.subjectCombination.list({ page, limit, search, searchBy })
  )
}

export const useFilterSubjectCombination = ({
  countryId,
  subjectId,
  systemId,
}: Omit<CombinationData, 'teachingLevelId'>) => {
  return useQuery<QueryListResponse<SubjectCombination>>(
    adminPath.subjectCombination.filterDetailList({
      countryId,
      subjectId,
      systemId,
    }),
    {
      enabled: !!(countryId && subjectId && systemId),
    }
  )
}

export const useCreateCombination = () => {
  const toast = useToast()
  const invalidate = useInvalidateUrl(adminPath.subjectCombination.list())
  const { _t } = useLanguages()

  return useMutation(createCombination, {
    onSuccess: async () => {
      await invalidate()
      toast({
        title: 'Success!',
        description: _t('message.success.subject_combination_add'),
        status: 'success',
      })
    },
    onError: useToastError(),
  })
}

export const useUpdateCombination = () => {
  const toast = useToast()
  const invalidate = useInvalidateUrl(adminPath.subjectCombination.list())
  const { _t } = useLanguages()

  return useMutation(editCombination, {
    onSuccess: async () => {
      await invalidate()
      toast({
        title: 'Success!',
        description: _t('message.success.subject_combination_update'),
        status: 'success',
      })
    },
    onError: useToastError(),
  })
}

export const useDeleteCombination = () => {
  const toast = useToast()
  const invalidate = useInvalidateUrl(adminPath.subjectCombination.list())
  const { _t } = useLanguages()

  return useMutation(deleteCombination, {
    onSuccess: async () => {
      await invalidate()
      toast({
        title: 'Success!',
        description: _t('message.success.subject_combination_remove'),
        status: 'success',
      })
    },
    onError: useToastError(),
  })
}

import { atom } from 'jotai'

import { User, UserProfile, UserRoleType } from 'types'

type authRequestInfo = {
  firstName?: string
  lastName?: string
  role: UserRoleType
}

export const userAtom = atom<User | null>(null)
export const derivedUserAtom = atom(
  get => get(userAtom),
  (_, set, user: User | null) => set(userAtom, user)
)

export const enterpriseIdAtom = atom<string>('')
export const derivedEnterpriseIdAtom = atom(
  get => get(enterpriseIdAtom),
  (_, set, enterpriseId: string | '') => set(enterpriseIdAtom, enterpriseId)
)

export const userProfileAtom = atom<UserProfile | null>(null)
export const derivedUserProfileAtom = atom(
  get => get(userProfileAtom),
  (get, set, userProfile: UserProfile | null) => {
    const currentProfile = get(userProfileAtom)

    if (userProfile) {
      return set(userProfileAtom, {
        ...currentProfile,
        ...userProfile,
      })
    }

    return set(userProfileAtom, null)
  }
)

export const authenticationCheckedAtom = atom(false)
export const setAuthStatusAtom = atom(null, (_, set, status: boolean) =>
  set(authenticationCheckedAtom, status)
)

export const authRequestAtom = atom<authRequestInfo | null>(null)
export const setAuthRequestAtom = atom(null, (_, set, data: authRequestInfo) =>
  set(authRequestAtom, data)
)

export const profileCheckedAtom = atom(false)
export const setProfileCheckedStatusAtom = atom(
  null,
  (_, set, status: boolean) => set(profileCheckedAtom, status)
)

export const firebaseAuthAtom = atom<string>('')
export const derivedFirebaseAuthAtom = atom(
  get => get(firebaseAuthAtom),
  (_, set, token: string) => set(firebaseAuthAtom, token)
)

export const firebaseUserAtom = atom<any>(null)
export const derivedFirebaseUserAtom = atom(
  get => get(firebaseUserAtom),
  (_, set, user) => set(firebaseUserAtom, user)
)

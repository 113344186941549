import React, { useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Flex,
  Text,
} from '@chakra-ui/react'
import { format } from 'date-fns'

import { useLanguages, useUserId } from 'hooks'
import { ClassSessionItem, LessonPackage } from 'types'

interface PackageSectionProps {
  data: LessonPackage[]
  instructorId: string
}

const PackageSection: React.FC<PackageSectionProps> = ({
  data,
  instructorId,
}) => {
  const { _t } = useLanguages()
  const personalId = useUserId()
  const canEdit = personalId === instructorId
  const navigate = useNavigate()

  const cleanData = useMemo(() => {
    if (data && data.length > 0) {
      const sortedData = data.sort((prev, curr) => {
        return prev.sessions[0].startDateTime - curr.sessions[0].startDateTime
      })

      return sortedData.filter(
        item => item.sessions[0].startDateTime > new Date().getTime()
      )
    }
    return []
  }, [data])

  const handleView = useCallback(
    (id: string) => {
      if (id) {
        navigate(`/product/class/${id}`)
      }
    },
    [navigate]
  )

  const getDateRange = useCallback((sessions: ClassSessionItem[]) => {
    const range = sessions.reduce(
      (prev, curr) => {
        return {
          startDateTime:
            prev.startDateTime < curr.startDateTime
              ? prev.startDateTime
              : curr.startDateTime,
          endDateTime:
            prev.endDateTime > curr.endDateTime
              ? prev.endDateTime
              : curr.endDateTime,
        }
      },
      {
        startDateTime: sessions[0].startDateTime,
        endDateTime: sessions[0].endDateTime,
      }
    )
    return `${format(range.startDateTime, 'dd MMM yyyy')} - ${format(
      range.endDateTime,
      'dd MMM yyyy'
    )}`
  }, [])

  return (
    <Box my='2rem'>
      <Text as='h2' mb='1rem'>
        {_t('product.class.packages_title')}
      </Text>
      {!!cleanData.length ? (
        <Accordion defaultIndex={[0]} allowToggle w='100%'>
          {cleanData?.map((item: LessonPackage, index: number) => (
            <Box key={`package-${index}`} w='100%' mb='1.25rem'>
              <AccordionItem
                rounded='lg'
                boxShadow='lg'
                border='0.8px solid #DEDEDE'
              >
                <AccordionButton p='1rem' _hover={{}}>
                  <Flex justify={'space-between'} w='full' mr='1rem'>
                    <Box flex='1' textAlign={'left'}>
                      <Text
                        fontSize='0.75rem'
                        fontWeight='600'
                        color='primary'
                        mb='0.25rem'
                      >
                        {getDateRange(item.sessions)}
                      </Text>
                      <Text>{item.name}</Text>
                    </Box>
                    <Button
                      variant='solidAction'
                      onClick={() => handleView(item.id)}
                      textTransform='uppercase'
                    >
                      {canEdit ? _t('common.view') : _t('common.buy')}
                    </Button>
                  </Flex>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel>
                  {item.sessions.map(
                    (session: ClassSessionItem, idx: number) => (
                      <Box key={`module-${idx}`}>
                        <Text
                          fontSize='0.75rem'
                          fontWeight='600'
                          color='primary'
                          mb='0.25rem'
                        >
                          {session.startDateTime && session.endDateTime
                            ? `${format(
                                session.startDateTime,
                                'dd MMM yyyy, eee | h:mma'
                              )} - ${format(session.endDateTime, 'h:mma')}`
                            : 'Date and time not added yet'}
                        </Text>
                        <Text mb='0.5rem'>{session.name}</Text>
                      </Box>
                    )
                  )}
                </AccordionPanel>
              </AccordionItem>
            </Box>
          ))}
        </Accordion>
      ) : (
        <Flex justify='center'>
          <Text fontSize='0.75rem' color='gray.300'>
            {_t('product.class.packages_empty')}
          </Text>
        </Flex>
      )}
    </Box>
  )
}

export default PackageSection

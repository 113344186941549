import React, { ChangeEvent, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'

import { Box } from '@chakra-ui/react'

import { DetailEditSection, TeachingSubjectEditSection } from '../containers'
import {
  AvatarBannerGroup,
  HeadingSection,
  LoadingSpinner,
  Option,
} from 'components'
import { useLanguages } from 'hooks'
import {
  useCentreDetail,
  useCentreTeachingSubjectList,
  useUpdateCentre,
} from 'queries/centre'
import { useUpdateBankAccount, useUserWallet } from 'queries/wallet'
import { BankAccountEdit } from 'shared/containers/BankAccount'
import {
  BankDetailForm,
  EditCentreFormInfo,
  GroupedCombination,
  Language,
  UserRole,
  UserRoleType,
  WalletBankRequest,
} from 'types'
import { bankAccountCountryOptions, responsivePadding } from 'utils/constant'
import { formatOption, getSelectedOptions } from 'utils/parser'

interface EditCentreProfileContainerProps {
  centreId: string
  enterpriseId: string
  backLabel: string
  handleToBack: () => void
  role?: UserRoleType
}

interface EditCentreForm
  extends Omit<
    EditCentreFormInfo,
    'subjectCombinations' | 'centerId' | 'languages'
  > {
  languages?: Option[]
}

const EditCentreProfileContainer: React.FC<EditCentreProfileContainerProps> = ({
  centreId,
  enterpriseId,
  backLabel,
  handleToBack,
  role,
}) => {
  const { _t } = useLanguages()
  const [avatar, setAvatar] = useState<string>()
  const [avatarFile, setAvatarFile] = useState<File | undefined>(undefined)
  const [banner, setBanner] = useState<string>()
  const [bannerFile, setBannerFile] = useState<File | undefined>(undefined)
  const [isUpdateCombination, setIsUpdateCombination] = useState<boolean>(false)
  const [combinationData, setCombinationData] = useState<GroupedCombination[]>(
    []
  )
  const [initCombinationIdData, setInitCombinationIdData] = useState<string[]>(
    []
  )
  const isSuperAdmin = useMemo(
    () => role === UserRole.MSTSuperAdmin || role === UserRole.MSTAdmin,
    [role]
  )
  const isEnterpriseAdmin = useMemo(
    () =>
      role === UserRole.EnterpriseAdmin ||
      role === UserRole.EnterpriseSuperAdmin,
    [role]
  )
  const refreshList = useMemo(() => isSuperAdmin || isEnterpriseAdmin, [
    isEnterpriseAdmin,
    isSuperAdmin,
  ])

  const {
    handleSubmit,
    control,
    formState: { isDirty },
    getValues,
    reset,
  } = useForm<EditCentreForm>({
    defaultValues: {
      name: '',
      email: '',
      active: false,
      description: '',
      contactNumber: '',
      address: '',
      youtubeLink: '',
      languages: [],
    },
  })
  const {
    control: paymentControl,
    getValues: getValuePayment,
    formState: { isDirty: paymentDirty },
    reset: paymentReset,
    errors,
  } = useForm<BankDetailForm>({
    defaultValues: {
      bankAccountName: '',
      bankName: '',
      bankAccountNumber: '',
      country: {
        value: '',
        label: '',
      },
      bankRemarks: '',
    },
  })
  const { mutate: updateCentre } = useUpdateCentre(
    enterpriseId,
    centreId,
    refreshList
  )
  const { data: centreDetail, isLoading: isCentreLoading } = useCentreDetail({
    centreId,
  })
  const { data: walletInfo } = useUserWallet({
    type: 'center',
    id: centreId,
  })
  const { mutate: updateUserBankAccount } = useUpdateBankAccount({
    type: 'center',
    id: centreId,
  })
  const {
    data: centreTeachingSubjectList,
    isLoading: isSubjectLoading,
  } = useCentreTeachingSubjectList({
    centreId,
  })

  useEffect(() => {
    if (centreDetail?.data) {
      const loadedData = centreDetail.data
      const detailKeys = [
        'name',
        'email',
        'active',
        'description',
        'contactNumber',
        'address',
        'youtubeLink',
        'languages',
      ] as (keyof EditCentreForm)[]
      let formattedData = {} as EditCentreForm
      detailKeys.forEach(key => {
        if (key === 'languages') {
          formattedData[key] = formatOption(
            loadedData['teachingLanguages'] as Language[]
          )
        } else {
          formattedData[key] = loadedData[key] as never
        }
      })
      reset({ ...formattedData })
      setAvatar(loadedData.avatar)
      setBanner(loadedData.banner)
    }
  }, [centreDetail, reset])

  const handleResetFile = () => {
    setAvatarFile(undefined)
    setBannerFile(undefined)
  }

  useEffect(() => {
    if (walletInfo?.data) {
      const resetData = {
        bankAccountName: walletInfo?.data?.bankAccountName || '',
        bankName: walletInfo?.data?.bankName || '',
        bankAccountNumber: walletInfo?.data?.bankAccountNumber || '',
        country: getSelectedOptions(
          bankAccountCountryOptions,
          walletInfo?.data?.country
        ),
        bankRemarks: walletInfo?.data?.bankRemarks || '',
      }

      paymentReset({ ...resetData })
    }
  }, [paymentReset, walletInfo])

  const handleUpdateCentre = (data: EditCentreForm) => {
    const { languages } = getValues()
    const changeLanguageIds = languages?.map(item => item.value)
    const addedLanguages = changeLanguageIds?.map(item => ({
      opt: 'add',
      id: item,
    }))
    const totalChangedLanguages = addedLanguages?.length
      ? [...addedLanguages]
      : undefined

    const changedSubjectCombinations = combinationData.reduce<string[]>(
      (acc, cur) => {
        return [
          ...acc,
          ...cur.teachingLevels.map(
            item => item.subjectCombinationId as string
          ),
        ]
      },
      []
    )

    const deletedCombinations = initCombinationIdData
      .filter(id => !changedSubjectCombinations.includes(id))
      .map(item => ({
        opt: 'remove',
        id: item,
      }))
    const addedCombinations = changedSubjectCombinations
      .filter(id => !initCombinationIdData.includes(id))
      .map(item => ({
        opt: 'add',
        id: item,
      }))
    const totalChangedCombinations = [
      ...deletedCombinations,
      ...addedCombinations,
    ]

    let formattedData = {} as EditCentreFormInfo
    let newData = Object.assign({}, data)
    if (totalChangedCombinations.length) {
      formattedData = {
        ...newData,
        centerId: centreId,
        subjectCombinations: totalChangedCombinations,
        languages: totalChangedLanguages,
      }
    } else {
      formattedData = {
        ...newData,
        centerId: centreId,
        languages: totalChangedLanguages,
      }
    }

    const {
      bankAccountName,
      bankAccountNumber,
      bankName,
      country,
      bankRemarks,
    } = getValuePayment()
    const formattedBankAccountData = {
      type: 'center',
      id: centreId,
      bankAccountName,
      bankAccountNumber,
      bankName,
      bankRemarks,
      country: country?.value,
    } as WalletBankRequest

    if (isSuperAdmin && paymentDirty) {
      updateUserBankAccount({ data: formattedBankAccountData })
    }
    updateCentre({
      id: centreId,
      data: formattedData,
      avatarFile: avatarFile,
      bannerFile: bannerFile,
      resetFile: handleResetFile,
    })
    setIsUpdateCombination(false)
  }

  const handleUpdateAvatar = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0]
    if (file) {
      setAvatar(URL.createObjectURL(file))
      setAvatarFile(file)
    }
  }

  const handleUpdateBanner = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0]
    if (file) {
      setBanner(URL.createObjectURL(file))
      setBannerFile(file)
    }
  }

  const enableSaveBtn = () => {
    if (
      isDirty ||
      paymentDirty ||
      typeof avatarFile !== 'undefined' ||
      typeof bannerFile !== 'undefined' ||
      isUpdateCombination
    ) {
      return false
    }
    return true
  }

  const updateCombinationData = (data: GroupedCombination[]) => {
    setIsUpdateCombination(true)
    setCombinationData(data)
  }

  const handleRemoveCombinationItem = (combinationId: string) => {
    setIsUpdateCombination(true)
    setCombinationData(oldCombinations =>
      oldCombinations.map(combination => ({
        ...combination,
        teachingLevels: combination.teachingLevels.filter(
          item => item.subjectCombinationId !== combinationId
        ),
      }))
    )
  }

  useEffect(() => {
    if (centreTeachingSubjectList?.data.length) {
      const subjectCombinations = centreTeachingSubjectList?.data.reduce<
        string[]
      >((acc, cur) => {
        return [
          ...acc,
          ...cur.teachingLevels.map(
            item => item.subjectCombinationId as string
          ),
        ]
      }, [])
      setCombinationData(centreTeachingSubjectList.data)
      setInitCombinationIdData(subjectCombinations)
    }
  }, [centreTeachingSubjectList])

  return (
    <>
      <HeadingSection
        title={_t('centre.profile.edit_title')}
        backLabel={backLabel}
        handleToBack={handleToBack}
        buttonData={[
          {
            label: _t('common.save'),
            isDisabled: enableSaveBtn(),
            onClick: handleSubmit(handleUpdateCentre),
          },
          {
            label: _t('common.cancel'),
            isSecondary: true,
            onClick: handleToBack,
          },
        ]}
        isAdminStyle
      />
      <Box pb='2rem'>
        {isCentreLoading || isSubjectLoading ? (
          <LoadingSpinner />
        ) : (
          <>
            <AvatarBannerGroup
              name={centreDetail?.data.name}
              avatar={avatar}
              banner={banner}
              handleUpdateAvatar={handleUpdateAvatar}
              handleUpdateBanner={handleUpdateBanner}
            />
            <Box px={responsivePadding}>
              <Box mt='1.5rem'>
                <DetailEditSection
                  control={control}
                  isAllowUpdateStatus={isSuperAdmin || isEnterpriseAdmin}
                />
              </Box>

              <Box mt='2.5rem'>
                <TeachingSubjectEditSection
                  data={combinationData}
                  handleUpdate={updateCombinationData}
                  handleRemove={handleRemoveCombinationItem}
                />
              </Box>

              {isSuperAdmin && (
                <Box mt='2.5rem'>
                  <BankAccountEdit control={paymentControl} errors={errors} />
                </Box>
              )}
            </Box>
          </>
        )}
      </Box>
    </>
  )
}

export default EditCentreProfileContainer

import React, { useCallback } from 'react'

import {
  Avatar,
  Box,
  Button,
  Collapse,
  Divider,
  Flex,
  Grid,
  GridItem,
  Text,
  Textarea,
  useDisclosure,
} from '@chakra-ui/react'
import { sub } from 'date-fns'

import { getAttachmentUrl } from 'apis/request'
import { ItemDetail } from 'components'
import { useLoading } from 'hooks'
import { useLanguages } from 'hooks'
import { useFriendStatus } from 'queries/connection'
import { useUserProfile } from 'queries/user'
import { ConnectionStatusType, Request } from 'types'
import { formatDate, getFullName } from 'utils/parser'

import { ReactComponent as DownIcon } from 'assets/images/arrow_down.svg'
import { ReactComponent as AttachmentIcon } from 'assets/images/attachment_icon.svg'

interface BidRequestItemProps {
  userId: string
  request: Request | undefined
  isTutor?: boolean
  isAdmin?: boolean
  isCancelled?: boolean
  isPending?: boolean
  showAttachments?: boolean
  onViewRequest?: () => void
  onCancelRequest?: {}
}

const BidRequestItem: React.FC<BidRequestItemProps> = ({
  userId,
  request,
  isTutor = false,
  isAdmin = false,
  isCancelled = false,
  isPending = false,
  showAttachments = false,
  onViewRequest,
  onCancelRequest,
}) => {
  // Normal variables
  const { _t } = useLanguages()
  const { setLoading } = useLoading()
  const { isOpen, onToggle } = useDisclosure()
  const {
    isOpen: isOpenAttachment,
    onToggle: onToggleAttachment,
  } = useDisclosure()

  const getRequestTime = useCallback(() => {
    const start = formatDate(request?.startDateTime || 0, {
      formatStr: 'h:mma',
    })
    const end = formatDate(request?.endDateTime || 0, { formatStr: 'h:mma' })
    const date = formatDate(request?.startDateTime || 0, {
      formatStr: 'eeee, MMMM d, yyyy',
    })
    return { range: `${start} - ${end}`, date: `${date}` }
  }, [request])

  const connectionStatus = useFriendStatus(userId, request?.user?.id || '')
  const learnerProfile = useUserProfile(request?.user?.id || '')

  const handleGetAttachmentUrl = async (
    requestId: string | undefined,
    key: string | undefined
  ) => {
    if (requestId && key) {
      setLoading(true)
      const res = await getAttachmentUrl({ userId, requestId, key })
      const url = res.data.data as string
      setLoading(false)
      const openLink = window.open(url, '_blank')
      openLink?.focus()
    }
  }

  // Render the individual attachment item
  const renderAttachment = (attachment: any) => {
    return (
      <Flex mt='1rem'>
        <AttachmentIcon style={{ flexShrink: 0 }} />
        <Text
          ml='0.5rem'
          fontSize='12px'
          color='primary'
          cursor='pointer'
          onClick={() => handleGetAttachmentUrl(request?.id, attachment.id)}
        >
          {attachment.name} ({(attachment.size / 1000000).toFixed(3)} MB)
        </Text>
      </Flex>
    )
  }

  // Render the description box and attachments part (Common for both tutor and learner)
  const renderDescriptionAttachment = (
    <Flex mt='1rem' flexDir={{ base: 'column', md: 'row' }}>
      <Text
        textStyle='detailTitle'
        color='gray.100'
        lineHeight='1.125rem'
        mr='1rem'
        textTransform='uppercase'
      >
        {_t('job.request.description')}
      </Text>
      <Box w='100%'>
        <Textarea fontSize='14px' readOnly>
          {request?.message}
        </Textarea>
        <Flex
          onClick={showAttachments ? onToggleAttachment : undefined}
          cursor={showAttachments ? 'pointer' : 'default'}
          mt='1rem'
          alignItems='center'
          display={request?.attachments.length === 0 ? 'none' : 'flex'}
        >
          <AttachmentIcon />
          <Text ml='0.5rem' mr='1rem' fontSize='12px'>
            {_t('job.request.attachments')} ({request?.attachments.length})
          </Text>
          {showAttachments && (
            <DownIcon
              style={
                isOpenAttachment
                  ? { transform: 'rotate(180deg)', transitionDuration: '0.25s' }
                  : { transform: 'rotate(0deg)', transitionDuration: '0.25s' }
              }
            />
          )}
        </Flex>
        {showAttachments && (
          <Collapse in={isOpenAttachment} animateOpacity>
            <Divider mt='1rem' />
            <Box overflowY='auto'>
              {request?.attachments.length
                ? request.attachments.map(attachment => {
                    return renderAttachment(attachment)
                  })
                : ''}
            </Box>
          </Collapse>
        )}
      </Box>
    </Flex>
  )

  const instructorAdminActionText = useCallback(() => {
    if (isTutor) {
      return isPending ? _t('job.bid.view') : _t('job.bid.place')
    }
    if (isCancelled) {
      return _t('job.request.recreate')
    } else {
      return request?.status === 'confirm'
        ? _t('job.bid.placed')
        : _t('job.bid.view_count', { count: `${request?.bids.length}` })
    }
  }, [_t, request, isTutor, isPending, isCancelled])

  const isLearnerActionDisabled = () => {
    if (isCancelled) {
      return false
    } else {
      return request?.bids.length === 0 ? true : request?.status === 'confirm'
    }
  }

  const learnerActionText = useCallback(() => {
    if (isCancelled) {
      return _t('job.request.recreate')
    } else if (request?.bids.length === 0) {
      return _t('job.request.no_bid')
    } else {
      return request?.status === 'confirm'
        ? _t('job.bid.placed')
        : _t('job.bid.view_count', { count: `${request?.bids.length}` })
    }
  }, [_t, request, isCancelled])

  if (isTutor || isAdmin) {
    return (
      <Box>
        <Box bg='white' boxShadow='lg' rounded='lg' p='2rem 2.5rem' my='1.5rem'>
          <Grid
            templateColumns={'repeat(8, 1fr)'}
            gap='1rem'
            alignItems='center'
            onClick={onToggle}
          >
            <GridItem
              colSpan={7}
              display={{ base: 'initial', md: 'none' }}
              overflowY='hidden'
            >
              <ItemDetail
                title={_t('job.request.name')}
                text={request?.title}
                isVertical
              />
            </GridItem>
            <GridItem
              colSpan={2}
              display={{ base: 'none', xl: 'initial' }}
              overflowY='hidden'
            >
              <Flex alignItems='center'>
                <Avatar
                  size='md'
                  src={request?.user?.avatar}
                  name={getFullName(request?.user || {})}
                  key={request?.user?.avatar || 'defaultAvatar'}
                />
                <Box ml='0.5rem'>
                  <Text fontSize='14px'>
                    {getFullName(request?.user || {})}
                  </Text>
                  <Text
                    fontSize='12px'
                    color='actionGreen'
                    display={connectionStatus.isLoading ? 'none' : 'block'}
                  >
                    {isTutor &&
                    connectionStatus.data?.data.status ===
                      ConnectionStatusType.CONFIRM
                      ? _t('job.bid.connected')
                      : _t('job.bid.not_connected')}
                    {learnerProfile.data?.data.accountType?.name}
                  </Text>
                </Box>
              </Flex>
            </GridItem>
            <GridItem
              colSpan={{ base: 4, xl: 2 }}
              display={{ base: 'none', md: 'initial' }}
            >
              <ItemDetail
                title={_t('job.request.subject_level')}
                text={request?.teachingLevel?.name}
              />
              <ItemDetail
                title={_t('job.request.subject')}
                text={request?.subject?.name}
              />
            </GridItem>
            <GridItem
              colSpan={{ base: 3, xl: 2 }}
              display={{ base: 'none', md: 'initial' }}
            >
              <ItemDetail
                title={_t('job.request.date')}
                text={getRequestTime().date}
              />
              <ItemDetail
                title={_t('job.request.time')}
                text={getRequestTime().range}
              />
            </GridItem>
            <GridItem
              colSpan={1}
              display={{ base: 'flex', xl: 'none' }}
              justifyContent='flex-end'
            >
              <DownIcon
                style={
                  isOpen
                    ? {
                        transform: 'rotate(180deg)',
                        transitionDuration: '0.25s',
                      }
                    : { transform: 'rotate(0deg)', transitionDuration: '0.25s' }
                }
              />
            </GridItem>
            <GridItem colSpan={2} display={{ base: 'none', xl: 'initial' }}>
              <Flex alignItems='center' justify='flex-end'>
                <Box mr='1rem'>
                  <Text textAlign='center' fontSize='12px'>
                    {_t('job.request.close_on')}
                  </Text>
                  <Text textAlign='center' fontSize='14px'>
                    {request?.startDateTime
                      ? formatDate(
                          sub(request?.startDateTime, { minutes: 5 }).getTime(),
                          { formatStr: 'dd MMM yyyy, h:mma' }
                        )
                      : ''}
                  </Text>
                </Box>
                <DownIcon
                  style={
                    isOpen
                      ? {
                          transform: 'rotate(180deg)',
                          transitionDuration: '0.25s',
                        }
                      : {
                          transform: 'rotate(0deg)',
                          transitionDuration: '0.25s',
                        }
                  }
                />
              </Flex>
            </GridItem>
          </Grid>
          <Collapse in={isOpen} animateOpacity>
            <Divider mt='1rem' mb='1rem' />
            <Grid
              templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)' }}
              gap='1rem'
              alignItems='center'
            >
              <GridItem colSpan={{ base: 1, md: 2, xl: 1 }}>
                <ItemDetail
                  title={_t('job.request.name')}
                  text={request?.title}
                />
              </GridItem>
              <GridItem
                colSpan={1}
                display={{ base: 'initial', md: 'none', xl: 'initial' }}
              >
                <ItemDetail
                  title={_t('job.request.language')}
                  text={request?.language?.name}
                />
              </GridItem>
              <GridItem colSpan={1} display={{ base: 'initial', md: 'none' }}>
                <ItemDetail
                  title={_t('job.request.date')}
                  text={getRequestTime().date}
                />
              </GridItem>
              <GridItem colSpan={1} display={{ base: 'initial', md: 'none' }}>
                <ItemDetail
                  title={_t('job.request.time')}
                  text={getRequestTime().range}
                />
              </GridItem>
              <GridItem colSpan={1} display={{ base: 'initial', md: 'none' }}>
                <ItemDetail
                  title={_t('job.request.subject_level')}
                  text={request?.teachingLevel?.name}
                />
              </GridItem>
              <GridItem colSpan={1} display={{ base: 'initial', md: 'none' }}>
                <ItemDetail
                  title={_t('job.request.subject')}
                  text={request?.subject?.name}
                />
              </GridItem>
            </Grid>
            {renderDescriptionAttachment}
            <Flex flexDir='row-reverse' mt='1rem' display='flex'>
              <Button
                disabled={
                  isAdmin
                    ? isCancelled
                      ? false
                      : request?.bids.length === 0
                    : false
                }
                variant='solidAction'
                onClick={onViewRequest}
              >
                {instructorAdminActionText()}
              </Button>
              {onCancelRequest}
            </Flex>
          </Collapse>
        </Box>
      </Box>
    )
  } else {
    return (
      <Box>
        <Box bg='white' boxShadow='lg' rounded='lg' p='2rem 2.5rem' my='1.5rem'>
          <Grid
            templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(6, 1fr)' }}
            gap='1rem'
            alignItems='center'
            onClick={onToggle}
          >
            <GridItem colSpan={{ base: 1, md: 3, xl: 2 }} overflowY='hidden'>
              <ItemDetail
                title={_t('job.request.name')}
                text={request?.title}
                isVertical
              />
            </GridItem>
            <GridItem colSpan={2} display={{ base: 'none', xl: 'initial' }}>
              <ItemDetail
                title={_t('job.request.date')}
                text={getRequestTime().date}
              />
              <ItemDetail
                title={_t('job.request.time')}
                text={getRequestTime().range}
              />
            </GridItem>
            <GridItem colSpan={1} display={{ base: 'none', xl: 'initial' }}>
              <Flex alignItems='center' display={isCancelled ? 'none' : ''}>
                <Box mr='1rem'>
                  <Text textAlign='center' fontSize='12px'>
                    {_t('job.request.close_on')}
                  </Text>
                  <Text textAlign='center' fontSize='14px'>
                    {request?.startDateTime
                      ? formatDate(
                          sub(request?.startDateTime, { minutes: 5 }).getTime(),
                          { formatStr: 'dd MMM yyyy, h:mma' }
                        )
                      : ''}
                  </Text>
                </Box>
              </Flex>
            </GridItem>
            <GridItem colSpan={{ base: 1, md: 3, xl: 1 }}>
              <Flex justifyContent='flex-end' alignItems='center'>
                <Button
                  disabled={isLearnerActionDisabled()}
                  mr='1rem'
                  w={{ base: '100%', md: 'auto', xl: '100%' }}
                  variant='solidAction'
                  onClick={onViewRequest}
                >
                  {learnerActionText()}
                </Button>
                <DownIcon
                  style={
                    isOpen
                      ? {
                          transform: 'rotate(180deg)',
                          transitionDuration: '0.25s',
                        }
                      : {
                          transform: 'rotate(0deg)',
                          transitionDuration: '0.25s',
                        }
                  }
                />
              </Flex>
            </GridItem>
          </Grid>
          <Collapse in={isOpen} animateOpacity>
            <Divider mt='1rem' mb='1rem' />
            <Grid
              templateColumns={{
                base: 'repeat(1, 1fr)',
                md: 'repeat(2, 1fr)',
                xl: 'repeat(3, 1fr)',
              }}
              gap='1rem'
              alignItems='center'
            >
              <GridItem colSpan={1} display={{ base: 'initial', xl: 'none' }}>
                <ItemDetail
                  title={_t('job.request.date')}
                  text={getRequestTime().date}
                />
              </GridItem>
              <GridItem colSpan={1} display={{ base: 'initial', xl: 'none' }}>
                <ItemDetail
                  title={_t('job.request.time')}
                  text={getRequestTime().range}
                />
              </GridItem>
              <GridItem colSpan={1}>
                <ItemDetail
                  title={_t('job.request.subject_level')}
                  text={request?.teachingLevel?.name}
                />
              </GridItem>
              <GridItem colSpan={1}>
                <ItemDetail
                  title={_t('job.request.subject')}
                  text={request?.subject?.name}
                />
              </GridItem>
              <GridItem colSpan={1} display={{ base: 'none', xl: 'initial' }}>
                <ItemDetail
                  title={_t('job.request.language')}
                  text={request?.language?.name}
                />
              </GridItem>
            </Grid>
            {renderDescriptionAttachment}
          </Collapse>
        </Box>
      </Box>
    )
  }
}

export default BidRequestItem

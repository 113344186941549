import { useCallback, useEffect, useState } from 'react'

import { AxiosResponse } from 'axios'

import { uploadFileUrl } from 'apis/upload'
import { getCredentialUploadUrl } from 'apis/user'
import {
  Attachment,
  CredentialDetail,
  CredentialFileUrl,
  UploadFileStatus,
} from 'types'

export interface UploadFile {
  file: Attachment
  uploadStatus: UploadFileStatus
}

export interface GroupUploadFile {
  index: string
  uploadFiles: UploadFile[]
}

const useUploadCredential = (credentials: CredentialDetail[] = []) => {
  const [uploadFiles, handleChangeFiles] = useState<GroupUploadFile[]>(() =>
    credentials.map<GroupUploadFile>(item => ({
      index: item.id,
      uploadFiles:
        (item.attachment && [
          {
            file: item.attachment,
            uploadStatus: item.attachment.createdAt ? 'uploaded' : 'pending',
          },
        ]) ||
        [],
    }))
  )

  const [isUploading, setIsUploading] = useState(false)
  const [filesError, setFilesError] = useState<string[]>([])
  const [isSuccess, setIsSuccess] = useState(false)
  const [isError, setIsError] = useState(false)
  const [isSettled, setIsSettled] = useState(false)

  useEffect(() => {
    if (isSettled) {
      handleChangeFiles(oldFiles => {
        return oldFiles.map<GroupUploadFile>(item => ({
          ...item,
          uploadFiles: item.uploadFiles.map(fileInfo => ({
            ...fileInfo,
            uploadStatus: filesError.includes(fileInfo.file.name)
              ? 'failure'
              : 'uploaded',
          })),
        }))
      })
    }
  }, [filesError, isSettled])

  useEffect(() => {
    if (isSettled) {
      setTimeout(() => setIsSettled(false), 0)
    }
  }, [isSettled])

  const handleUpload = useCallback(
    async (userId, itemIndex) => {
      setIsUploading(true)
      setIsSettled(false)
      const promises: Promise<AxiosResponse<CredentialFileUrl>>[] = []

      const newUploadFiles = uploadFiles.map(upload => ({
        ...upload,
        uploadFiles: upload.uploadFiles.filter(
          file => file.uploadStatus !== 'uploaded'
        ),
      }))

      const uploadingItem = newUploadFiles.find(
        item => item.index === itemIndex
      )

      if (uploadingItem) {
        uploadingItem.uploadFiles.forEach(item => {
          promises.push(getCredentialUploadUrl(userId, item.file))
        })

        try {
          const uploadUrlRes = await Promise.allSettled(promises)
          const result: string[] = []
          const uploadStatus = await Promise.allSettled(
            uploadUrlRes.map((uploadUrl, index) => {
              if (uploadUrl.status === 'fulfilled') {
                result.push(uploadUrl.value.data.data.fields.key)
                return uploadFileUrl(
                  uploadingItem.uploadFiles[index].file,
                  uploadUrl.value.data
                )
              }

              return Promise.reject({
                fileName: uploadingItem.uploadFiles[index].file.name,
              })
            })
          )

          let errorNames: string[] = []

          uploadStatus.forEach(upload => {
            if (upload.status === 'rejected') {
              errorNames.push(upload.reason?.fileName)
            }
          })

          setFilesError(errorNames)
          setIsSuccess(true)
          setIsError(false)
          return result
        } catch {
          setIsSuccess(false)
          setIsError(true)
        } finally {
          setIsUploading(false)
          setIsSettled(true)
        }
      }
    },
    [uploadFiles]
  )

  return {
    uploadFiles,
    isUploading,
    isError,
    isSuccess,
    isSettled,
    filesError,
    handleUpload,
    handleChangeFiles,
  }
}

export default useUploadCredential

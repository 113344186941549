import React, { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'

import { Box, Button, Flex, Grid, Text } from '@chakra-ui/react'

import { Input } from 'components'
import Modal, { ModalProps } from 'components/Modal'
import { useLanguages } from 'hooks'
import {
  PasswordErrorMessages,
  passwordValidators,
} from 'utils/passwordValidator'

interface UpdatePasswordModalProps extends Omit<ModalProps, 'children'> {
  oldPassword: string
  onSubmit: (newPassword: string) => Promise<void>
}

type UpdatePasswordInput = {
  password: string
  confirmPassword: string
}

const UpdatePasswordModal: React.FC<UpdatePasswordModalProps> = ({
  isOpen,
  onClose,
  oldPassword,
  onSubmit,
}) => {
  const {
    errors,
    control,
    getValues,
    handleSubmit,
  } = useForm<UpdatePasswordInput>({
    mode: 'onChange',
    criteriaMode: 'all',
    defaultValues: {
      password: '',
      confirmPassword: '',
    },
  })

  const [loading, setLoading] = useState(false)
  const [formError, setFormError] = useState('')
  const [show, setShow] = useState(false)
  const [showConfirm, setShowConfirm] = useState(false)
  const handleClick = () => setShow(!show)
  const handleClickConfirm = () => setShowConfirm(!showConfirm)
  const { _t } = useLanguages()

  const handleUpdatePassword = async ({ password }: UpdatePasswordInput) => {
    setLoading(true)

    if (password === oldPassword) {
      setFormError('New password needs to be different from old password')
    }

    try {
      await onSubmit(password)
    } catch (err) {
      setFormError(
        err?.message || 'Something went wrong, please try again later.'
      )
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size='xl'>
      <Flex direction='column' alignItems='center'>
        <Box textStyle='headingModalText'>{_t('auth.update.title')}</Box>
        <Box textStyle='subHeadingModalText' my='0.5rem'>
          {_t('auth.update.subtitle')}
        </Box>
        <Grid gridTemplateColumns='5fr 5fr' columnGap='1.8rem'>
          <Box as='form' width='100%'>
            <Box mb='1rem'>
              <Controller
                control={control}
                rules={{
                  required: 'Please input your password',
                  validate: Object.keys(passwordValidators).reduce(
                    (acc, cur) => {
                      return {
                        ...acc,
                        [cur]: passwordValidators[cur].validate,
                      }
                    },
                    {}
                  ),
                }}
                label='Password'
                labelProps={{ fontSize: '0.75rem' }}
                name='password'
                render={({ value, onChange }) => (
                  <Input
                    type={show ? 'text' : 'password'}
                    placeholder={_t('auth.update.placeholder_password')}
                    customErrorMessages={
                      <PasswordErrorMessages errors={errors.password} />
                    }
                    value={value}
                    onChange={onChange}
                    size='sm'
                    aria-label='Password'
                    textStyle='authText'
                    error={errors.password?.message}
                    rightElement={
                      <Box
                        onClick={handleClick}
                        color='primary'
                        textTransform='uppercase'
                        cursor='pointer'
                        pointerEvents={'auto'}
                        fontSize={'0.7rem'}
                      >
                        {show ? _t('common.hide') : _t('common.show')}
                      </Box>
                    }
                  />
                )}
              />
            </Box>

            <Controller
              control={control}
              rules={{
                required: 'Please repeat your password',
                validate: {
                  matchPassword: value =>
                    value === getValues()['password'] ||
                    'Password does not match',
                },
              }}
              label='Confirm Password'
              labelProps={{ fontSize: '0.75rem' }}
              name='confirmPassword'
              render={({ value, onChange }) => (
                <Input
                  type={showConfirm ? 'text' : 'password'}
                  size='sm'
                  value={value}
                  onChange={onChange}
                  placeholder={_t('auth.update.placeholder_confirm_password')}
                  error={errors.confirmPassword?.message}
                  aria-label='Confirm Password'
                  textStyle='authText'
                  rightElement={
                    <Box
                      onClick={handleClickConfirm}
                      color='primary'
                      textTransform='uppercase'
                      cursor='pointer'
                      pointerEvents={'auto'}
                      fontSize={'0.7rem'}
                    >
                      {showConfirm ? _t('common.hide') : _t('common.show')}
                    </Box>
                  }
                />
              )}
            />

            {formError && (
              <Text textStyle='error' textAlign='center' mb='1rem'>
                {formError}
              </Text>
            )}
          </Box>
          <Box
            bgColor='lightBlue'
            borderRadius='0.5rem'
            p='1rem'
            fontSize='0.75rem'
          >
            <Flex mb='1rem'>
              &#9679;
              <Text ml='0.3rem'>{_t('auth.update.description_minimum')}</Text>
            </Flex>
            <Flex mb='1rem'>
              &#9679;
              <Text ml='0.3rem'>{_t('auth.update.description_numerical')}</Text>
            </Flex>
            <Flex mb='1rem'>
              &#9679;
              <Text ml='0.3rem'>{_t('auth.update.description_uppercase')}</Text>
            </Flex>
            <Flex mb='1rem'>
              &#9679;
              <Text ml='0.3rem'>{_t('auth.update.description_lowercase')}</Text>
            </Flex>
            <Flex mb='1rem'>
              &#9679;
              <Text ml='0.3rem'>{_t('auth.update.description_special')}</Text>
            </Flex>
          </Box>
        </Grid>
        <Button
          variant='solidPrimary'
          flex={1}
          mt='1rem'
          py='1rem'
          textTransform='uppercase'
          onClick={handleSubmit(handleUpdatePassword)}
          isLoading={loading}
        >
          {_t('auth.update.button_save')}
        </Button>
      </Flex>
    </Modal>
  )
}

export default UpdatePasswordModal

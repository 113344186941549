import Axios from 'axios'

import { adminPath, messageUrl } from 'configs/apiPath'
import { Message } from 'types'

interface AddMessageParams {
  locale: string
  key: string
  value: string
}

export const getMessages = async (lang: string) => {
  return Axios.get<Message>(`${messageUrl}/messages/${lang}.json`, {
    headers: {
      'No-Need-Authorization': true,
      'Cache-Control': 'no-cache',
    },
  })
}

export const addMessage = async (params: AddMessageParams): Promise<void> => {
  return Axios.put(adminPath.message.update(params.locale), {
    path: params.key,
    message: params.value,
  })
}

export const deleteMessage = async (
  params: Omit<AddMessageParams, 'value'>
): Promise<void> => {
  return Axios.delete(adminPath.message.update(params.locale), {
    data: {
      path: params.key,
    },
  })
}

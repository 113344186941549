import React, { ChangeEvent, FC, useCallback, useEffect, useRef } from 'react'

import { Box, BoxProps, Flex, Text } from '@chakra-ui/react'

import './multiRangeSlider.css'

interface MultiRangeSliderProps {
  onChange: (data: { min: number; max: number }) => void
  containerProps?: BoxProps
  defautlRange: {
    min: number
    max: number
  }
  currentMin: number
  currentMax: number
  isDisabled?: boolean
}

const MultiRangeSlider: FC<MultiRangeSliderProps> = ({
  onChange,
  containerProps,
  defautlRange,
  currentMin,
  currentMax,
  isDisabled = false,
}) => {
  const minValRef = useRef(defautlRange.min)
  const maxValRef = useRef(defautlRange.max)
  const range = useRef<HTMLDivElement>(null)

  // Convert to percentage
  const getPercent = useCallback(
    (value: number) =>
      Math.round(
        ((value - defautlRange.min) / (defautlRange.max - defautlRange.min)) *
          100
      ),
    [defautlRange]
  )

  // Set width of the range to decrease from the left side
  useEffect(() => {
    const minPercent = getPercent(currentMin)
    const maxPercent = getPercent(currentMax)

    if (range.current) {
      range.current.style.left = `${minPercent}%`
      range.current.style.width = `${maxPercent - minPercent}%`
    }
  }, [currentMin, currentMax, getPercent])

  // Set width of the range to decrease from the right side
  useEffect(() => {
    const minPercent = getPercent(currentMin)
    const maxPercent = getPercent(currentMax)

    if (range.current) {
      range.current.style.width = `${maxPercent - minPercent}%`
    }
  }, [currentMin, currentMax, getPercent])

  return (
    <Flex
      w='100%'
      justifyContent='center'
      alignItems='center'
      position='relative'
      opacity={isDisabled ? 0.5 : 1}
      cursor={isDisabled ? 'not-allowed' : 'unset'}
      {...containerProps}
    >
      <input
        type='range'
        min={defautlRange.min}
        max={defautlRange.max}
        value={currentMin}
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          const value = Math.min(Number(event.target.value), currentMax - 1)
          onChange({ min: value, max: currentMax })
          minValRef.current = value
        }}
        className='thumb thumb--left'
        style={{ zIndex: currentMin > defautlRange.max - 100 ? 5 : 3 }}
        disabled={isDisabled}
      />
      <input
        type='range'
        min={defautlRange.min}
        max={defautlRange.max}
        value={currentMax}
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          const value = Math.max(Number(event.target.value), currentMin + 1)
          onChange({ min: currentMin, max: value })
          maxValRef.current = value
        }}
        className='thumb thumb--right'
        disabled={isDisabled}
      />

      <Flex
        justifyContent='space-between'
        alignItems='center'
        mt='1rem'
        mb='3.5rem'
        fontSize='0.875rem'
      >
        <Text className='slider__left-value' color='primary'>
          {currentMin}
        </Text>
        <Text className='slider__right-value' color='primary'>
          {currentMax}
        </Text>
      </Flex>
      <Box position='relative' w='100%' py='1rem'>
        <Box className='slider__track'></Box>
        <Box ref={range} className='slider__range' bgColor='primary'></Box>
      </Box>
    </Flex>
  )
}

export default MultiRangeSlider

import { lazy } from 'react'

export const GuestDashboard = lazy(
  () => import(/* webpackChunkName: "guest-dashboard" */ './Dashboard')
)
export const GuestClassProduct = lazy(
  () =>
    import(
      /* webpackChunkName: "guest preview class" */ './ClassProductDetail/Class'
    )
)
export const GuestContentProduct = lazy(
  () =>
    import(
      /* webpackChunkName: "guest preview content" */ './ClassProductDetail/Content'
    )
)
export const GuestLessonProduct = lazy(
  () =>
    import(
      /* webpackChunkName: "guest preview lesson" */ './ClassProductDetail/Lesson'
    )
)
export const GuestInstructorView = lazy(
  () =>
    import(
      /* webpackChunkName: "guest view instructor profile" */ './InstructorProfile'
    )
)
export const GuestCentreProfileView = lazy(
  () => import(/* webpackChunkName: "guest view centre profile" */ './CentreProfile')
)

import React, { lazy, Suspense, useCallback, useMemo, useState } from 'react'

import { Box, useDisclosure } from '@chakra-ui/react'

import ImportClassesModal from './ImportClassesModal'
import ImportClassesSuccessModal from './ImportClassesSuccessModal'
import { HeadingSection, LoadingSpinner } from 'components'
import { useLanguages, useQueryParams, useUtilsForEnterpriseId } from 'hooks'
import { UserRole, UserRoleType } from 'types'

import { ReactComponent as ThreeBooks } from 'assets/images/books_stack_of_three.svg'
import { ReactComponent as ImportIcon } from 'assets/images/import_icon.svg'
import { ReactComponent as OpenBook } from 'assets/images/open_book.svg'

const SessionTab = lazy(() => import('./SessionTab'))

const tabData = [
  {
    id: 'published',
    title: 'Published',
    path: '?tab=published',
  },
  {
    id: 'draft',
    title: 'Drafts',
    path: '?tab=draft',
  },
]

type TabId = 'published' | 'draft'

interface SessionManagementProps {
  role: UserRoleType
  handleAdd: (type: 'class' | 'content' | 'lesson') => void
  colorScheme?: string
}

const SessionManagement: React.FC<SessionManagementProps> = ({
  role,
  handleAdd,
  colorScheme,
}) => {
  const { _t } = useLanguages()
  const { enterpriseId } = useUtilsForEnterpriseId()
  const query = useQueryParams()
  const currentTab = useMemo<TabId>(() => (query.tab || 'published') as TabId, [
    query,
  ])

  const [totalImportedClass, setTotalImportedClass] = useState<number>(0)

  const {
    isOpen: isOpenImport,
    onClose: onCloseImport,
    onOpen: onOpenImport,
  } = useDisclosure()

  const {
    isOpen: isOpenImportSuccess,
    onClose: onCloseImportSuccess,
    onOpen: onOpenImportSuccess,
  } = useDisclosure()

  const handleUpdateTotalClass = useCallback(
    (value: number) => setTotalImportedClass(value),
    []
  )

  return (
    <>
      <HeadingSection
        title='Session Management'
        tabData={tabData}
        currentTab={currentTab}
        buttonData={[
          {
            label: `+ ${_t('common.add')}`,
            popoverItemData: [
              {
                label: 'New Class',
                icon: OpenBook,
                onClick: () => handleAdd('class'),
              },
              {
                label: 'New Content',
                icon: OpenBook,
                onClick: () => handleAdd('content'),
              },
              {
                label: 'New Lesson',
                icon: ThreeBooks,
                onClick: () => handleAdd('lesson'),
              },
              {
                label: 'Import Classes',
                icon: ImportIcon,
                onClick: () => onOpenImport(),
              },
            ],
          },
        ]}
        isAdminStyle
      />
      <Box p='0 3.5rem 0 6rem'>
        <Suspense fallback={<LoadingSpinner />}>
          {currentTab === 'published' && (
            <SessionTab
              enterpriseId={
                role === UserRole.EnterpriseAdmin ? enterpriseId : ''
              }
              role={role}
              colorScheme={colorScheme}
              classStatus='public'
            />
          )}
          {currentTab === 'draft' && (
            <SessionTab
              enterpriseId={
                role === UserRole.EnterpriseAdmin ? enterpriseId : ''
              }
              role={role}
              colorScheme={colorScheme}
              classStatus='draft'
            />
          )}
        </Suspense>
      </Box>

      {isOpenImport && (
        <ImportClassesModal
          isOpen={isOpenImport}
          onClose={onCloseImport}
          onImportSuccess={() => onOpenImportSuccess()}
          onUpdateTotalClass={handleUpdateTotalClass}
        />
      )}

      {isOpenImportSuccess && (
        <ImportClassesSuccessModal
          isOpen={isOpenImportSuccess}
          onClose={onCloseImportSuccess}
          totalImportedClass={totalImportedClass}
        />
      )}
    </>
  )
}

export default SessionManagement

import Axios from 'axios'

import { promoPath } from 'configs/apiPath'

export const createPromo = (data: any) => {
  return Axios.post(promoPath.create(), data)
}

export const updatePromo = ({
  promoId,
  data,
}: {
  promoId: string
  data: any
}) => {
  return Axios.put(promoPath.update(promoId), data)
}

export const deletePromo = ({ promoId }: { promoId: string }) => {
  return Axios.delete(promoPath.delete(promoId))
}

export const exportPromo = (fileType: string) =>
  Axios.get(promoPath.export(fileType))

import React from 'react'

import { Button, ButtonProps, Text } from '@chakra-ui/react'

import { ConfirmAlert } from 'components'

interface ButtonPublishProps extends ButtonProps {
  onSubmit: () => void
}

const ButtonPublish: React.FC<ButtonPublishProps> = ({
  onSubmit,
  ...buttonProps
}) => {
  return (
    <ConfirmAlert
      onSubmit={onSubmit}
      title={'Confirm Publish?'}
      description={
        <Text textAlign='center'>
          By Clicking publish, the class will immediately be available for
          learners on the network to book and schedule.
        </Text>
      }
      submitTitle='Publish'
      immediatelyCloseOnSubmit
    >
      {({ showAlert }) => (
        <Button variant='solidAction' onClick={showAlert} {...buttonProps}>
          PUBLISH
        </Button>
      )}
    </ConfirmAlert>
  )
}

export default ButtonPublish

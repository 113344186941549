import React, { useCallback } from 'react'

import { Box, Button, Flex, Text } from '@chakra-ui/react'

import Modal, { ModalProps } from 'components/Modal'

import KnovoLogo from 'assets/images/knovo_logo.svg'

export interface CreateClassSuccessModalProps
  extends Omit<ModalProps, 'children'> {
  description: string
  actionGoToDashboard: () => void
  actionBackToCreating: () => void
  isDraft?: boolean
  isTutor?: boolean
}

const CreateClassSuccessModal: React.FC<CreateClassSuccessModalProps> = ({
  isOpen,
  onClose,
  description,
  actionGoToDashboard,
  actionBackToCreating,
  isDraft = false,
  isTutor = false,
}) => {
  const goToSessionDashboard = useCallback(() => {
    onClose()
    actionGoToDashboard()
  }, [actionGoToDashboard, onClose])

  const backToCreateClass = useCallback(() => {
    onClose()
    actionBackToCreating()
  }, [actionBackToCreating, onClose])

  return (
    <Modal isOpen={isOpen} onClose={backToCreateClass} size='xl'>
      <Flex direction='column' alignItems='center'>
        <Box as='img' src={KnovoLogo} alt="Knovo's logo" />
        <Text
          mt='1.75rem'
          mb='1rem'
          fontSize='1.125rem'
          fontWeight='bold'
          lineHeight='1.25rem'
        >
          {isDraft ? 'Draft Saved' : 'New Class Created'}
        </Text>

        <Text>{description}</Text>

        <Flex my='2rem'>
          <Button
            variant='solidPrimary'
            mr='0.5rem'
            onClick={backToCreateClass}
          >
            BACK TO CREATE CLASS
          </Button>
          <Button
            variant='outlinePrimary'
            ml='0.5rem'
            onClick={goToSessionDashboard}
          >
            {isTutor ? 'GO TO MY CLASSES' : 'GO TO DASHBOARD'}
          </Button>
        </Flex>
      </Flex>
    </Modal>
  )
}

export default CreateClassSuccessModal

import React, { ReactElement } from 'react'

import { Box, Flex, Text } from '@chakra-ui/react'

interface BannerFieldProps {
  label: string
  value: string
  icon: ReactElement
}

const BannerField: React.FC<BannerFieldProps> = ({ label, value, icon }) => {
  return (
    <Box>
      <Flex alignItems='center' justify='flex-start' width='full'>
        {icon}
        <Text
          fontSize='0.75rem'
          color='gray.300'
          lineHeight='0.875rem'
          m='0.3rem'
          textAlign='left'
        >
          {label}
        </Text>
      </Flex>
      <Text fontSize='0.875rem'>{value}</Text>
    </Box>
  )
}

export default BannerField

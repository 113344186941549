import React from 'react'
import { useNavigate } from 'react-router-dom'

import { SearchBar } from 'components'
import { useQueryParams, useSearch } from 'hooks'

const SearchInput = () => {
  const navigate = useNavigate()
  const { search, handleSearch } = useSearch(0)
  const { s = '' } = useQueryParams()

  const handleSubmitFormSearch = async (
    e: React.ChangeEvent<HTMLFormElement>
  ) => {
    e.preventDefault()
    navigate(`/search-results?s=${search}`)
  }

  return (
    <form onSubmit={handleSubmitFormSearch}>
      <SearchBar
        onSearch={handleSearch}
        defaultValue={s?.toString()}
        delay={0}
      />
    </form>
  )
}

export default SearchInput

import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { Box, Flex, Text } from '@chakra-ui/react'

import Knovole from './Knovole'
import KnovoWheel from './KnovoWheel'
import { GuestLayout, Layout } from 'components'
import storageKey from 'configs/storageKey'
import { useLanguages } from 'hooks'
import { getData } from 'utils/helper'

import { ReactComponent as ArrowIcon } from 'assets/images/arrowBack_icon.svg'

const GamePage = () => {
  const { _t } = useLanguages()
  const isGuest = getData(storageKey.loginType) === 'guest'
  const location = useLocation()
  const gameName = location.pathname.split('/')[2]
  const navigate = useNavigate()

  const handleGoBack = () => {
    navigate('/games')
  }

  const renderTopElement = () => {
    return (
      <>
        <Flex
          onClick={handleGoBack}
          cursor='pointer'
          pt='2rem'
          alignItems='center'
        >
          <ArrowIcon />
          <Box display={{ base: 'none', sm: 'flex' }}>
            <Text ml='0.5rem'>{_t('games.go_back')}</Text>
          </Box>
        </Flex>
      </>
    )
  }

  const renderGame = () => {
    if (gameName === 'knovole') {
      return <Knovole />
    } else if (gameName === 'wheel') {
      return <KnovoWheel />
    }
  }

  return (
    <>
      {isGuest ? (
        <GuestLayout as='main'>
          {renderTopElement()}
          {renderGame()}
        </GuestLayout>
      ) : (
        <Layout as='main'>
          {renderTopElement()}
          {renderGame()}
        </Layout>
      )}
    </>
  )
}

export default GamePage

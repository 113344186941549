import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'

import {
  Dashboard,
  Explore,
  LearnerCentreProfileView,
  LearnerCourses,
  LearnerEditProfile,
  LearnerInstructorView,
  LearnerNotifications,
  LearnerPreviewAdhocClass,
  LearnerPreviewAssignment,
  LearnerPreviewClass,
  LearnerPreviewContent,
  LearnerPreviewLesson,
  LearnerPreviewRequest,
  LearnerProfile,
  LearnerWallet,
  Meeting,
  SearchResult,
} from 'containers'
import { useProfile, useRedirectPath } from 'hooks'
import { GameLibrary, GamePage, Notification, Payment } from 'shared/containers'
import { Login } from 'types'

const DashboardRoutes = () => {
  useProfile()
  const redirectPath = useRedirectPath()

  return (
    <Routes>
      <Route path='/' element={<Dashboard />} />
      <Route path='/my-courses'>
        <Route path='/' element={<LearnerCourses />} />
        <Route
          path='/request-detail/:requestId'
          element={<LearnerPreviewRequest />}
        />
        <Route
          path='/assignments/:assignmentId'
          element={<LearnerPreviewAssignment />}
        />
      </Route>
      <Route path='/explore' element={<Explore loginType={Login.UserName} />} />
      <Route path='/wallet' element={<LearnerWallet />} />
      <Route path='/my-profile' element={<LearnerProfile />} />
      <Route path='/my-profile/edit' element={<LearnerEditProfile />} />
      <Route path='/product'>
        <Route path='/class/:id' element={<LearnerPreviewClass />} />
        <Route path='/content/:id' element={<LearnerPreviewContent />} />
        <Route path='/lesson/:id' element={<LearnerPreviewLesson />} />
        <Route path='/adhoc-class/:id' element={<LearnerPreviewAdhocClass />} />
      </Route>
      <Route path='/notifications' element={<LearnerNotifications />} />
      <Route path='/notifications/:id' element={<Notification />} />
      <Route path='/instructors/:id' element={<LearnerInstructorView />} />
      <Route path='/centre/:id' element={<LearnerCentreProfileView />} />
      <Route
        path='/search-results'
        element={<SearchResult loginType={Login.UserName} />}
      />
      <Route path='/games'>
        <Route path='/' element={<GameLibrary />} />
        <Route path='/*' element={<GamePage />} />
      </Route>
      <Route path='/meeting' element={<Meeting />} />
      <Route path='/payment/topup' element={<Payment type='topup' />} />
      <Route path='/payment/checkout' element={<Payment type='checkout' />} />
      <Route
        path='/*'
        element={<Navigate replace to={redirectPath ? redirectPath : '/'} />}
      />
    </Routes>
  )
}

export default DashboardRoutes

import { lazy } from 'react'

const UserManagement = lazy(
  () =>
    import(
      /* webpackChunkName: "enterprise-user-management" */ './UserManagement'
    )
)
const EditEnterpriseAdminProfile = lazy(
  () =>
    import(
      /* webpackChunkName: "edit-enterprise-admin-profile" */ './UserManagement/EnterpriseAdmin/EditProfile'
    )
)
const CreateInstructorUser = lazy(
  () =>
    import(
      /* webpackChunkName: "create-instructor-user" */ './UserManagement/Instructor/CreateInstructor'
    )
)
const EnterpriseInstructorProfile = lazy(
  () =>
    import(
      /* webpackChunkName: "edit-instructor-user" */ './UserManagement/Instructor/EditInstructor'
    )
)
const CreateLearnerUser = lazy(
  () =>
    import(
      /* webpackChunkName: "create-learner-user" */ './UserManagement/Learner/CreateLearner'
    )
)
const EditLearnerUser = lazy(
  () =>
    import(
      /* webpackChunkName: "edit-learner-user" */ './UserManagement/Learner/EditLearner'
    )
)
const ViewEnterpriseAdmin = lazy(
  () =>
    import(
      /* webpackChunkName: "view-enterprise-admin-user" */ './UserManagement/EnterpriseAdmin/ViewEnterpriseAdmin'
    )
)
const CreateCentreAdmin = lazy(
  () =>
    import(
      /* webpackChunkName: "create-centre-admin" */ './UserManagement/CentreAdmin/CreateCentreAdmin'
    )
)
const EditCentreAdmin = lazy(
  () =>
    import(
      /* webpackChunkName: "edit-centre-admin" */ './UserManagement/CentreAdmin/EditCentreAdmin'
    )
)
const CentreManagement = lazy(
  () =>
    import(
      /* webpackChunkName: "enterprise-centre-management" */ './CentreManagement'
    )
)
const EnterpriseViewCentre = lazy(
  () =>
    import(
      /* webpackChunkName: "enterprise-view-centre" */ './CentreManagement/Preview'
    )
)
const EnterpriseEditCentre = lazy(
  () =>
    import(
      /* webpackChunkName: "enterprise-edit-centre" */ './CentreManagement/Edit'
    )
)
const SessionManagement = lazy(
  () =>
    import(/* webpackChunkName: "session-management" */ './SessionManagement')
)
const CreateSessionLanding = lazy(
  () =>
    import(
      /* webpackChunkName: "create-session-landing" */ './SessionManagement/Create'
    )
)
const EnterpriseCreateClass = lazy(
  () =>
    import(
      /* webpackChunkName: "create-session-class" */ './SessionManagement/Create/Class'
    )
)
const EnterpriseCreateContent = lazy(
  () =>
    import(
      /* webpackChunkName: "create-session-content" */ './SessionManagement/Create/Content'
    )
)
const EnterpriseCreateLesson = lazy(
  () =>
    import(
      /* webpackChunkName: "create-session-lesson" */ './SessionManagement/Create/Lesson'
    )
)
const EnterpriseEditClass = lazy(
  () =>
    import(
      /* webpackChunkName: "enterprise-edit-class" */ './SessionManagement/Edit/Class'
    )
)
const EnterpriseEditContent = lazy(
  () =>
    import(
      /* webpackChunkName: "enterprise-edit-content" */ './SessionManagement/Edit/Content'
    )
)
const EnterprisePreviewClass = lazy(
  () =>
    import(
      /* webpackChunkName: "enterprise-preview-class" */ './SessionManagement/Preview/Class'
    )
)
const EnterprisePreviewContent = lazy(
  () =>
    import(
      /* webpackChunkName: "enterprise-preview-content" */ './SessionManagement/Preview/Content'
    )
)
const EnterprisePreviewLesson = lazy(
  () =>
    import(
      /* webpackChunkName: "enterprise-preview-lesson" */ './SessionManagement/Preview/Lesson'
    )
)

const EnterpriseAdminProfile = lazy(
  () => import(/* webpackChunkName: "enterprise-admin-profile" */ './MyProfile')
)

const EnterprisePaymentSubscribe = lazy(
  () =>
    import(/* webpackChunkName: "enterprise-payment-subscribe" */ './Payment')
)

const ViewEnterpriseProfile = lazy(
  () =>
    import(
      /* webpackChunkName: "view-enterprise-profile" */ './EnterpriseProfile/Preview'
    )
)

const EditEnterpriseProfile = lazy(
  () =>
    import(
      /* webpackChunkName: "edit-enterprise-profile" */ './EnterpriseProfile/Edit'
    )
)

const EnterpriseAdminNotifications = lazy(
  () =>
    import(
      /* webpackChunkName: "view-enterprise-notifications" */ './Notifications'
    )
)

const Wallet = lazy(() => import(/* webpackChunkName: "wallet" */ './Wallet'))
const ViewMyTransaction = lazy(
  () =>
    import(
      /* webpackChunkName: "view-my-transaction" */ './Wallet/PreviewTransaction'
    )
)
const TransactionApproval = lazy(
  () =>
    import(
      /* webpackChunkName: "transaction-approval-management" */ './TransactionApproval'
    )
)

export {
  UserManagement,
  EditEnterpriseAdminProfile,
  CreateInstructorUser,
  EnterpriseInstructorProfile,
  CreateLearnerUser,
  EditLearnerUser,
  ViewEnterpriseAdmin,
  EditCentreAdmin,
  CreateCentreAdmin,
  CentreManagement,
  EnterpriseViewCentre,
  EnterpriseEditCentre,
  SessionManagement,
  CreateSessionLanding,
  EnterpriseCreateClass,
  EnterpriseCreateContent,
  EnterpriseCreateLesson,
  EnterpriseEditClass,
  EnterpriseEditContent,
  EnterprisePreviewClass,
  EnterprisePreviewContent,
  EnterprisePreviewLesson,
  EnterpriseAdminProfile,
  ViewEnterpriseProfile,
  EditEnterpriseProfile,
  EnterprisePaymentSubscribe,
  Wallet,
  ViewMyTransaction,
  TransactionApproval,
  EnterpriseAdminNotifications,
}

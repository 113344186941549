import React, { useCallback, useState } from 'react'

import { Box, Button, Flex, Radio, RadioGroup } from '@chakra-ui/react'

import { Modal, ModalProps } from 'components'

export interface DataSubmitForExport {
  fileType: 'xlsx' | 'csv'
}

interface AdminExportModalProps extends Omit<ModalProps, 'children'> {
  onSubmit: (data: DataSubmitForExport) => void
  isLoading: boolean
  title?: string
}

const AdminExportModal: React.FC<AdminExportModalProps> = ({
  isOpen,
  onClose,
  onSubmit,
  isLoading,
  title = 'Export Data',
}) => {
  const [fileType, setFileType] = useState<'xlsx' | 'csv'>('xlsx')

  const handleChange = useCallback((value: any) => {
    setFileType(value)
  }, [])

  return (
    <Modal isOpen={isOpen} onClose={onClose} size='md'>
      <Box textStyle='headingModalText' textAlign='center'>
        {title}
      </Box>
      <Box textAlign='center' mt='1.5rem' mb='0.6rem'>
        What file format do you want to download?
      </Box>
      <Box>
        <RadioGroup onChange={handleChange} value={fileType}>
          <Flex justifyContent='center' mb='1rem'>
            <Radio mr='2rem' value='xlsx'>
              xlsx file
            </Radio>
            <Radio value='csv'>csv file</Radio>
          </Flex>
        </RadioGroup>

        <Flex mt='2rem' justifyContent='center' alignItems='center'>
          <Button
            variant='solidPrimary'
            textTransform='uppercase'
            px='5rem'
            onClick={() => onSubmit({ fileType })}
            isLoading={isLoading}
          >
            Export
          </Button>
          <Button
            variant='transparent'
            textTransform='uppercase'
            px='5rem'
            onClick={onClose}
          >
            Cancel
          </Button>
        </Flex>
      </Box>
    </Modal>
  )
}

export default AdminExportModal

import React, { ChangeEvent, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'

import { Box, BoxProps } from '@chakra-ui/react'

import {
  ActionButton,
  AttachmentSection,
  DetailSection,
  HeaderSection,
  InvitationSection,
  Preview,
} from '../containers'
import { CreateClassForm } from '../containers/form'
import { createUploadBannerClass, uploadFileClass } from 'apis/class'
import { ContentContainer, ContentContainerCollapse } from 'components'
import { useLanguages, useToast, useUploadClassFile, useUserId } from 'hooks'
import { ClassType, Learner, UserRole } from 'types'
import { checkValidFilesName, isFileSizeOver } from 'utils/helper'
import { parseErrorToastMsg } from 'utils/parser'

export interface CreateContentDataType {
  data: CreateClassForm
  bannerFile?: File
  invitedLearners: Learner[]
  confirmedLearners: Learner[]
}

interface ClassContentProps {
  role: string
  handleClose: () => void
  handleCreateProduct: (
    creatingStatus: 'public' | 'draft'
  ) => (createData: CreateContentDataType) => void
  createdData: any
  onCreatedSuccess: (className: string, type: string) => void
  containerProps?: BoxProps
}

const ContentCreate: React.FC<ClassContentProps> = ({
  role,
  handleClose,
  handleCreateProduct,
  createdData,
  onCreatedSuccess,
  containerProps,
}) => {
  const { _t } = useLanguages()
  const toast = useToast()
  const userId = useUserId()

  const [bannerFile, setBannerFile] = useState<File | undefined>(undefined)
  const [isPreview, setIsPreview] = useState<boolean>(false)
  const [invitedLearners, setInvitedLearners] = useState<Learner[]>([])
  const [confirmedLearners, setConfirmedLearners] = useState<Learner[]>([])
  const [allowUpload, setAllowUpload] = useState(true)
  const [savingData, setSavingData] = useState<CreateClassForm | undefined>(
    undefined
  )

  const previewBanner = useMemo(
    () => (bannerFile ? URL.createObjectURL(bannerFile) : ''),
    [bannerFile]
  )

  const roleIsTutor = useMemo(
    () => role === UserRole.Tutor || role === UserRole.EnterpriseTutor,
    [role]
  )

  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    errors,
    setError,
    watch,
  } = useForm<CreateClassForm>({
    mode: 'onChange',
    defaultValues: {
      name: '',
      description: '',
      price: 0,
      enterprisePrice: 0,
      usualPrice: undefined,
      usualEnterprisePrice: undefined,
      tutor: { value: '', label: '' },
      centre: { value: '', label: '' },
      teachingLevel: { value: '', label: '' },
      subject: { value: '', label: '' },
      language: { value: '', label: '' },
      requireMinimum: false,
      type: ClassType.PUBLIC_CONTENT,
      tutorType:
        role === UserRole.Tutor ? UserRole.Tutor : UserRole.EnterpriseTutor,
      materials: [],
    },
  })

  const {
    uploadFiles,
    isUploading,
    isSettled,
    filesError,
    handleChangeFiles,
    handleUpload,
  } = useUploadClassFile()

  const classId = useMemo(() => createdData?.data.data.id || '', [createdData])

  // set successful creation
  useEffect(() => {
    if (createdData?.data && isSettled) {
      onCreatedSuccess(createdData.data.data.name, createdData.data.data.type)
    }
  }, [createdData, onCreatedSuccess, isSettled, filesError])

  useEffect(() => {
    if (classId && allowUpload) {
      //  upload banner
      const handleUploadFile = async (
        id: string,
        banner: File,
        bodyGetUrl: any
      ) => {
        try {
          const res = await createUploadBannerClass(id, bodyGetUrl)
          const formData = new FormData()
          const { fields, url } = res.data.data
          Object.entries(fields).forEach(([key, value]) => {
            formData.append(key, value)
          }, [])
          formData.append('file', banner)
          const resUpload = await uploadFileClass(url, formData)
          if (resUpload) {
            return 'success'
          }
        } catch (err) {
          console.log(err)
        }
      }

      if (bannerFile) {
        const bodyGetUrl = {
          fileName: bannerFile.name,
          contentType: bannerFile.type,
        }
        handleUploadFile(classId, bannerFile, bodyGetUrl)
      }
      handleUpload(classId)
    }
  }, [allowUpload, handleUpload, classId, bannerFile])

  useEffect(() => {
    if (isSettled) {
      setAllowUpload(false)
    }
  }, [handleClose, isSettled])

  //---- add, delete learner (invited & confirmed)
  const handleAddInvitedLearner = (data: Learner[]) => {
    setInvitedLearners(data)
  }
  const handleDeleteInvitedLearner = (learnerId: string) => {
    const newData = invitedLearners.filter(item => item.id !== learnerId)
    setInvitedLearners(newData)
  }
  const handleAddConfirmedLearner = (data: Learner[]) => {
    setConfirmedLearners(data)
  }
  const handleDeleteConfirmedLearner = (learnerId: string) => {
    const newData = confirmedLearners.filter(item => item.id !== learnerId)
    setConfirmedLearners(newData)
  }

  const handleUpdateBanner = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0]
    const maxSize = 10485760 // 10mb in byte

    if (file && isFileSizeOver(file.size, maxSize)) {
      return toast(
        parseErrorToastMsg(_t('product.class.validate_banner_invalid_size'))
      )
    }

    return setBannerFile(file)
  }

  const handleCreate = (creatingStatus: 'public' | 'draft') => (
    value: CreateClassForm
  ) => {
    const createData = {
      data: value,
      bannerFile: bannerFile,
      invitedLearners: invitedLearners,
      confirmedLearners: confirmedLearners,
    }
    handleCreateProduct(creatingStatus)(createData)
  }

  const handleClosePreview = () => {
    setIsPreview(false)
  }

  const handleOpenPreview = () => (value: CreateClassForm) => {
    if (!checkValidFilesName(uploadFiles)) {
      return toast({
        title: 'Error!',
        status: 'error',
        duration: 6000,
        description: _t('message.error.InvalidFileName'),
      })
    }

    setSavingData({ ...value })
    setIsPreview(true)
  }

  useEffect(() => {
    if (!isPreview && savingData) {
      const keys = Object.keys(savingData) as (keyof CreateClassForm)[]
      keys.forEach((key: keyof CreateClassForm) => {
        if (key === 'attachments') {
          return ''
        }
        setValue(key, savingData[key])
      })
    }
  }, [isPreview, savingData, setValue])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  if (isPreview) {
    return (
      <Preview
        previewBanner={previewBanner}
        onClose={handleClosePreview}
        data={savingData}
        userId={roleIsTutor ? userId : (savingData?.tutor.value as string)}
        attachments={uploadFiles.map(item => item.file)}
        handleCreateClass={handleCreate}
      />
    )
  }

  return (
    <>
      <Box as='section' pt='1.5rem' pb='3rem' {...containerProps}>
        <Box as='h1' mb='2rem' className={'blue-purple-gradient-text'}>
          {_t('product.content.create_title')}
        </Box>

        <ContentContainer title={_t('product.content.title')}>
          <HeaderSection
            control={control}
            errors={errors}
            bannerFile={bannerFile}
            previewBanner={previewBanner}
            handleUpdateBanner={handleUpdateBanner}
          />
        </ContentContainer>

        <ContentContainerCollapse
          title={_t('product.content.details_title')}
          mt='2rem'
        >
          <DetailSection
            role={role}
            watch={watch}
            control={control}
            setValue={setValue}
            getValues={getValues}
            setError={setError}
            errors={errors}
            type={'content'}
          />
        </ContentContainerCollapse>

        <ContentContainerCollapse
          title={_t('product.content.materials_title')}
          mt='2rem'
        >
          <AttachmentSection
            watch={watch}
            control={control}
            errors={errors}
            uploadFiles={uploadFiles}
            isUploading={isUploading}
            handleChangeFiles={handleChangeFiles}
            type={'content'}
          />
        </ContentContainerCollapse>

        <ContentContainerCollapse
          title={_t('product.content.invitations_title')}
          mt='2rem'
        >
          <InvitationSection
            watch={watch}
            role={role}
            invitedLearners={invitedLearners}
            confirmedLearners={confirmedLearners}
            handleAddInvitedLearner={handleAddInvitedLearner}
            handleDeleteInvitedLearner={handleDeleteInvitedLearner}
            handleAddConfirmedLearner={handleAddConfirmedLearner}
            handleDeleteConfirmedLearner={handleDeleteConfirmedLearner}
          />
        </ContentContainerCollapse>

        <ActionButton
          handleSubmit={handleSubmit}
          handleCreate={handleCreate}
          handleOpenPreview={handleOpenPreview}
          handleClose={handleClose}
          type={'content'}
        />
      </Box>
    </>
  )
}

export default ContentCreate

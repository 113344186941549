import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { useDisclosure } from '@chakra-ui/react'

import LessonCreate, { CreateClassDataType } from '../../Product/Lesson/Create'
import { CreateClassSuccessModal } from 'components'
import { useLoading, useUserId } from 'hooks'
import { useAddClass } from 'queries/class'
import { CreateClassType, UserRole, UserRoleType } from 'types'
import { parseClassType, parseDateTimeUnix } from 'utils/parser'

interface CreateLessonContainerProps {
  role: UserRoleType
  handleToDashboard: () => void
  handleBackToCreate: () => void
}

const CreateLessonContainer = ({
  role,
  handleToDashboard,
  handleBackToCreate,
}: CreateLessonContainerProps) => {
  const [createdClass, setCreatedClass] = useState<{
    name: string
    type: string
  }>()
  const [isDraft, setIsDraft] = useState<boolean>(false)
  const { isOpen, onClose, onOpen } = useDisclosure()
  const { setLoading } = useLoading()
  const userId = useUserId()

  const roleIsTutor = useMemo(
    () => role === UserRole.Tutor || role === UserRole.EnterpriseTutor,
    [role]
  )

  const { mutate: addClass, data: addedClassData, isSuccess } = useAddClass()

  const handleCreateClass = useCallback(
    (creatingStatus: 'public' | 'draft') => (
      createData: CreateClassDataType
    ) => {
      const {
        name,
        description,
        price,
        enterprisePrice,
        usualPrice,
        usualEnterprisePrice,
        centre,
        tutor,
        language,
        subject,
        teachingLevel,
        minStudent,
        maxStudent,
        requireMinimum,
        type,
        tutorType,
        occurrenceStartDate,
        numberOfPackage,
        occurrenceSession,
      } = createData.data
      setIsDraft(creatingStatus === 'draft')

      const formattedData: CreateClassType = {
        name: name,
        description: description,
        price: price * 1,
        enterprisePrice: (enterprisePrice || 0) * 1,
        usualPrice: usualPrice ? usualPrice * 1 : undefined,
        usualEnterprisePrice: usualEnterprisePrice
          ? usualEnterprisePrice * 1
          : undefined,
        centerId: centre?.value,
        tutorId: roleIsTutor ? userId : tutor.value,
        languageId: language.value,
        subjectId: subject.value,
        teachingLevelId: teachingLevel.value,
        maxStudent: maxStudent * 1,
        minStudent: minStudent * 1,
        requireMinimum: requireMinimum,
        type: type,
        status: creatingStatus,
        invitedStudents: [],
        confirmedStudents: [],
        occurrence: {
          startDate: parseDateTimeUnix(occurrenceStartDate),
          numberOfPackage: (numberOfPackage && +numberOfPackage) || 1,
          sessions: occurrenceSession?.map(item => {
            return {
              name: item.name || '',
              description: item.description || '',
              week: (item?.week && +item.week) || 1,
              dayOfWeek: item.dayOfWeek?.value || '',
              startTime: parseDateTimeUnix(item.startTime, item.startTime),
              endTime: parseDateTimeUnix(item.endTime, item.endTime),
              meetingType: item.meetingType,
              meetingLocation: item.meetingLocation,
            }
          }),
        },
      }

      if (tutorType === UserRole.Tutor) {
        delete formattedData.enterprisePrice
        delete formattedData.centerId
      }

      addClass({ data: formattedData })
    },
    [addClass, roleIsTutor, userId]
  )

  useEffect(() => {
    if (isSuccess) {
      // set loading again for upload banner and attachments
      setLoading(true)
    }
  }, [isSuccess, setLoading])

  const onCreatedSuccess = useCallback(
    (name: string, type: string) => {
      setCreatedClass({ name, type })
      setLoading(false)
      onOpen()
    },
    [onOpen, setLoading]
  )

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <LessonCreate
        role={role}
        createdData={addedClassData}
        handleClose={handleBackToCreate}
        handleCreateClass={handleCreateClass}
        onCreatedSuccess={onCreatedSuccess}
      />
      <CreateClassSuccessModal
        description={
          isDraft
            ? `"${
                createdClass?.name
              }" has been successfully saved in your drafts. Return to Drafts to publish your "${parseClassType(
                createdClass?.type || ''
              )}" anytime.`
            : `"${createdClass?.name}" has been successfully created.`
        }
        isOpen={isOpen}
        onClose={onClose}
        isTutor={roleIsTutor}
        isDraft={isDraft}
        actionBackToCreating={handleBackToCreate}
        actionGoToDashboard={handleToDashboard}
      />
    </>
  )
}

export default CreateLessonContainer

import React, { useMemo } from 'react'
import { useParams } from 'react-router-dom'

import { Box, Divider, Flex } from '@chakra-ui/react'

import {
  Detail,
  History,
  RecipientTransaction,
  SenderTransaction,
} from './containers'
import { HeadingSection, LoadingSpinner } from 'components'
import { useLanguages, useQueryParams } from 'hooks'
import { useCashFlow } from 'queries/cashflow'
import { CashFlowStatus } from 'types'

interface PreviewCashFlowProps {
  handleBack?: (tab: string) => void
}

const PreviewCashFlow: React.FC<PreviewCashFlowProps> = ({ handleBack }) => {
  const { _t } = useLanguages()
  const query = useQueryParams()
  const params = useParams()
  const cashFlowId = useMemo(() => params.id, [params])
  const currentTab = useMemo<CashFlowStatus>(
    () => (query.tab || 'onhold') as CashFlowStatus,
    [query]
  )

  const { data: cashFlow, isLoading } = useCashFlow({
    cashFlowId,
  })
  const cashFlowData = useMemo(() => cashFlow?.data, [cashFlow])

  return (
    <Box>
      <HeadingSection
        title={_t('cash_flow.management.detail_title')}
        description={_t('cash_flow.management.detail_description')}
        backLabel={_t('cash_flow.management.title')}
        handleToBack={() => handleBack && handleBack(currentTab)}
        isAdminStyle
      />
      <Box p='2rem 5rem 0'>
        {isLoading && <LoadingSpinner />}
        {!isLoading && !cashFlowData && (
          <Flex justify='center'>{_t('cash_flow.detail.empty_title')}</Flex>
        )}
        {!isLoading && cashFlowData && (
          <>
            <Detail data={cashFlowData} />
            <Divider my='2rem' />
            <Box>
              <History data={cashFlowData} isLoading={isLoading} />
              <Divider my='2rem' />
              <Flex
                direction={{ base: 'column', md: 'row' }}
                justify='space-between'
              >
                <Box flex={1}>
                  <SenderTransaction data={cashFlowData} />
                </Box>
                <Box flex={1}>
                  <RecipientTransaction data={cashFlowData} />
                </Box>
              </Flex>
            </Box>
          </>
        )}
      </Box>
    </Box>
  )
}

export default PreviewCashFlow

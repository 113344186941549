import { atom } from 'jotai'

interface UnreadMessage {
  conversationId: string
  amount: number
}

interface LatestMessage {
  conversationId: string
  createdAt: number
}

export const totalUnreadMessagesAtom = atom<UnreadMessage[]>([])
export const derivedTotalUnreadMessagesAtom = atom(
  get => get(totalUnreadMessagesAtom),
  (get, set, unreadMessage: UnreadMessage) => {
    const currentTotalUnreadMessages = get(totalUnreadMessagesAtom)
    const isExist = currentTotalUnreadMessages.find(
      s => s.conversationId === unreadMessage.conversationId
    )
    if (isExist) {
      const index = currentTotalUnreadMessages.indexOf(isExist)
      const newTotalUnreadMessages = [...currentTotalUnreadMessages]
      newTotalUnreadMessages[index] = unreadMessage
      set(totalUnreadMessagesAtom, newTotalUnreadMessages)
    } else {
      const newTotalUnreadMessages = [
        ...currentTotalUnreadMessages,
        unreadMessage,
      ]
      set(totalUnreadMessagesAtom, newTotalUnreadMessages)
    }
  }
)

// export const latestMessageIdsAtom = atom<string[]>([])
export const latestMessagesAtom = atom<LatestMessage[]>([])
export const derivedLatestMessagesAtom = atom(
  get => get(latestMessagesAtom),
  (get, set, latestMessage: LatestMessage) => {
    const currentLatestMessages = get(latestMessagesAtom)
    const index = currentLatestMessages.findIndex(
      m => m.conversationId === latestMessage.conversationId
    )
    if (index < 0) {
      const newLatestMessages = [...currentLatestMessages, latestMessage]
      set(latestMessagesAtom, newLatestMessages)
    } else {
      const newLatestMessages = [...currentLatestMessages]
      newLatestMessages[index] = latestMessage
      set(latestMessagesAtom, newLatestMessages)
    }
  }
)
// export const derivedLatestMessagesAtom = atom(
//     get => get(latestMessageIdsAtom),
//     (get, set, latestMessage: LatestMessage) => {
//         const currentLatestMessages = get(latestMessagesAtom)
//         const index = currentLatestMessages.findIndex(m => m.conversationId === latestMessage.conversationId)
//         if(index < 0) {
//             const newLatestMessages = [
//                 ...currentLatestMessages,
//                 latestMessage
//             ]
//             set(latestMessagesAtom, newLatestMessages)
//             const sortedLatestMessages = newLatestMessages.sort((a, b) => a.createdAt - b.createdAt)
//             const newLatestMessageIds = sortedLatestMessages.map(m => m.conversationId)
//             set(latestMessageIdsAtom, newLatestMessageIds)
//         } else {
//             const newLatestMessages = [
//                 ...currentLatestMessages
//             ]
//             newLatestMessages[index] = latestMessage
//             set(latestMessagesAtom, newLatestMessages)
//             const sortedLatestMessages = newLatestMessages.sort((a, b) => a.createdAt - b.createdAt)
//             const newLatestMessageIds = sortedLatestMessages.map(m => m.conversationId)
//             set(latestMessageIdsAtom, newLatestMessageIds)
//         }
//     }
// )

import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'

import {
  CognitoHostedUIIdentityProvider,
  FederatedSignInOptionsCustom,
} from '@aws-amplify/auth/lib/types'
import { Box, Button, Flex, Text, VStack } from '@chakra-ui/react'

import Input from '../Input'
import Link from '../Link'
import { useLanguages } from 'hooks'
import { UserRole, UserRoleType } from 'types'
import { EMAIL_PATTERN } from 'utils/regexp'

import { ReactComponent as FacebookIcon } from 'assets/images/facebook.svg'
import { ReactComponent as GoogleIcon } from 'assets/images/google.svg'

export interface SocialSignInOptions {
  provider?: CognitoHostedUIIdentityProvider
  customOptions?: FederatedSignInOptionsCustom
}

interface SignInFormProps {
  role: UserRoleType
  onSubmit: (values: SignInFormInput) => Promise<void>
  onSocialSignIn: (options: SocialSignInOptions) => Promise<void>
  onForgot: () => void
  error?: string
  loading?: boolean
}

export interface SignInFormInput {
  email: string
  password: string
  keepLoginState: boolean
}

const SignInForm: React.FC<SignInFormProps> = ({
  role,
  onSubmit,
  onSocialSignIn,
  onForgot,
  error,
  loading,
}) => {
  const [formError, setFormError] = useState(error)

  useEffect(() => {
    if (error) {
      setFormError(error)
    }
  }, [error])

  const [show, setShow] = useState(false)
  const handleClick = () => setShow(!show)

  const { handleSubmit, control, errors } = useForm<SignInFormInput>({
    mode: 'onChange',
    defaultValues: {
      email: '',
      password: '',
      keepLoginState: false,
    },
  })

  const handleSignIn = async (values: SignInFormInput) => {
    setFormError('')
    try {
      await onSubmit(values)
    } catch (err) {
      setFormError(
        err?.message || 'Something went wrong, please try again later.'
      )
    }
  }

  const handleSocialSignIn = (options: SocialSignInOptions) => () => {
    return onSocialSignIn(options)
  }

  const { _t } = useLanguages()

  return (
    <Box
      as='form'
      onSubmit={handleSubmit(handleSignIn)}
      fontSize='0.875rem'
      lineHeight='1.125rem'
      px='2rem'
    >
      <VStack spacing={3} align='stretch'>
        <Box>
          <Controller
            as={Input}
            control={control}
            rules={{
              required: _t('auth.login.validate_email_required'),
              pattern: {
                value: EMAIL_PATTERN,
                message: _t('auth.login.validate_email_invalid'),
              },
            }}
            error={errors.email?.message}
            name='email'
            placeholder={_t('auth.login.placeholder_email')}
            aria-label='Email'
            textStyle='authText'
          />
          <Box textAlign='right'>
            <Link
              to='/forgot-email'
              tabIndex={-1}
              textDecoration='underline'
              display='inline-block'
              mt='0.375rem'
              fontSize='0.875rem'
              lineHeight='0.95rem'
              fontWeight='600'
              textAlign='right'
            >
              {_t('auth.login.forgot_email')}
            </Link>
          </Box>
        </Box>
        <Box>
          <Controller
            control={control}
            rules={{
              required: _t('auth.login.validate_password_required'),
              minLength: {
                value: 6,
                message: _t('auth.login.validate_password_minimum'),
              },
            }}
            name='password'
            aria-label='Password'
            textStyle='authText'
            render={({ value, onChange }) => (
              <Input
                type={show ? 'text' : 'password'}
                placeholder={_t('auth.login.placeholder_password')}
                value={value}
                onChange={onChange}
                size='sm'
                error={errors.password?.message}
                rightElement={
                  <Box
                    onClick={handleClick}
                    color='primary'
                    textTransform='uppercase'
                    pointerEvents={'auto'}
                    cursor='pointer'
                    fontSize={'0.7rem'}
                  >
                    {show ? _t('common.hide') : _t('common.show')}
                  </Box>
                }
              />
            )}
          />
          <Box
            display='flex'
            alignItems='center'
            justifyContent='flex-end'
            mt='0.375rem'
          >
            {/* <Controller
              control={control}
              name='keepLoginState'
              render={({ onChange, onBlur, value, name }) => (
                <Checkbox
                  onBlur={onBlur}
                  onChange={e => onChange(!!e.target.checked)}
                  checked={value}
                  name={name}
                >
                  {' '}
                  <Box textStyle='authText'>Keep me logged in</Box>
                </Checkbox>
              )}
            /> */}

            <Box
              as='p'
              fontSize='0.875rem'
              lineHeight='0.95rem'
              fontWeight='600'
              textAlign='right'
              textDecoration='underline'
              cursor='pointer'
              _hover={{
                color: 'primary',
              }}
              onClick={onForgot}
            >
              {_t('auth.login.forgot_password')}
            </Box>
          </Box>
        </Box>
      </VStack>

      {formError && (
        <Text textStyle='error' textAlign='center'>
          {formError}
        </Text>
      )}

      <Button
        type='submit'
        width='100%'
        mt='1.5rem'
        isLoading={loading}
        textTransform='uppercase'
      >
        {_t('auth.login.button_login')}
      </Button>

      {role !== UserRole.Enterprise && (
        <Box alignItems='center' justifyContent='center' my='2rem'>
          <Text
            textAlign='center'
            fontSize='0.875rem'
            fontWeight='600'
            textTransform='uppercase'
          >
            {_t('auth.login.alternative')}
          </Text>
          <Flex
            mt='1rem'
            alignItems='center'
            width='100%'
            flex='1'
            justifyContent='space-between'
          >
            <Flex
              cursor='pointer'
              onClick={handleSocialSignIn({
                provider: CognitoHostedUIIdentityProvider.Facebook,
              })}
              rounded='lg'
              width='46%'
              height='3rem'
              borderWidth='1px'
              justifyContent='center'
              alignItems='center'
            >
              <FacebookIcon />
              <Text ml='0.5rem'>{_t('auth.login.facebook')}</Text>
            </Flex>
            <Flex
              cursor='pointer'
              onClick={handleSocialSignIn({
                provider: CognitoHostedUIIdentityProvider.Google,
              })}
              rounded='lg'
              width='46%'
              height='3rem'
              borderWidth='1px'
              justifyContent='center'
              alignItems='center'
            >
              <GoogleIcon />
              <Text ml='0.5rem'>{_t('auth.login.google')}</Text>
            </Flex>
          </Flex>
        </Box>
      )}
    </Box>
  )
}

export default SignInForm

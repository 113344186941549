import React from 'react'

import { Avatar, Box, Flex } from '@chakra-ui/react'

import { UserRole } from 'types'
import { Connection } from 'types/connection'
import { getFullName } from 'utils/parser'

import { ReactComponent as Star } from 'assets/images/star.svg'

interface DashboardUserItemProps {
  data: Connection
  onClick?: (data: Connection) => void
}

const DashboardUserItem: React.FC<DashboardUserItemProps> = ({
  data,
  onClick,
}) => {
  const handleClick = () => {
    onClick?.(data)
  }
  return (
    <Box>
      <Flex
        position='relative'
        direction='column'
        alignItems='center'
        cursor='pointer'
        onClick={handleClick}
        minW='50px'
      >
        <Avatar
          size='md'
          name={getFullName(data)}
          src={data.avatar}
          key={data.avatar || 'default-avatar'}
        />
        {data?.accountType?.toString() ===
          UserRole.EnterpriseTutor.toString() && (
          <Box
            className='blue-green-gradient'
            position='absolute'
            bottom='-0.5rem'
            p='0.25rem'
            fontSize='0.625rem'
            fontWeight='600'
            textAlign='center'
            textTransform='uppercase'
            borderRadius='0.25rem'
            color='#fff'
          >
            enterprise
          </Box>
        )}
      </Flex>
      <Box
        mt='0.625rem'
        mb='0.25rem'
        fontSize='0.625rem'
        fontWeight='600'
        lineHeight='0.875rem'
        textAlign='center'
      >
        <Box noOfLines={2}>{getFullName(data)}</Box>
      </Box>
      {data.rating && (
        <Flex justifyContent='center'>
          <Box mt='0.075rem' mr='0.25rem'>
            <Star />
          </Box>
          <Box fontSize='0.75rem' lineHeight='1rem' color='primary'>
            {data.rating}
          </Box>
        </Flex>
      )}
    </Box>
  )
}

export default DashboardUserItem

import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'

import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Divider,
  Flex,
  Radio,
  RadioGroup,
  SimpleGrid,
  Text,
} from '@chakra-ui/react'
import { format } from 'date-fns'

import { SessionItemForm } from '../form'
import { createUploadAttachmentSession } from 'apis/classSession'
import { uploadFile } from 'apis/upload'
import {
  AttachmentFile,
  ConfirmAlert,
  DateTimePicker,
  FileUpload,
  Input,
  Textarea,
  Tooltip,
} from 'components'
import { useGeocode, useLanguages } from 'hooks'
import { UploadFile } from 'hooks/useUploadClassFile'
import { UploadedFilesType } from 'hooks/useUploadSessionFile'
import {
  ClassDetailItem,
  MeetingLocation,
  MeetingType,
  UploadClass,
} from 'types'
import { parseDateTimeUnix } from 'utils/parser'

import { ReactComponent as TrashIcon } from 'assets/images/color_trash_icon.svg'
import { ReactComponent as InfoIcon } from 'assets/images/info_outline_icon.svg'

interface SessionItemProps {
  handleChangeClass: (index: number, data: ClassDetailItem) => void
  handleDeleteClass: (index: number) => void
  classItem: ClassDetailItem
  uploadFiles: UploadFile[]
  handleChangeFiles: React.Dispatch<React.SetStateAction<UploadedFilesType[]>>
  isUploading: boolean
  classId: string
}

const FILES_LIMIT = 3

const getTime = (input: number) => {
  const inputDate = new Date(input).setHours(0, 0, 0, 0)
  const today = new Date().setHours(0, 0, 0, 0)
  const inputTime = new Date(input).getTime() - inputDate + today

  return new Date(inputTime)
}

const SessionItem: React.FC<SessionItemProps> = ({
  handleChangeClass,
  handleDeleteClass,
  classItem,
  uploadFiles = [],
  handleChangeFiles,
  isUploading,
  classId,
}) => {
  const { _t } = useLanguages()
  const { getGeocode } = useGeocode()
  const [addresses, setAddresses] = useState<MeetingLocation[]>([])
  const [reachLimit, setReachLimit] = useState(false)
  const [attachmentIds, setAttachmentIds] = useState<string[]>([])

  const getMeetingType = useCallback(() => {
    const meetingType = classItem.meetingType
    const meetingLink = classItem.meetingLink
    if (meetingType === MeetingType.PHYSICAL) {
      return meetingType
    }
    if (meetingLink) {
      return MeetingType.VIRTUAL_CUSTOM
    }
    return MeetingType.VIRTUAL_DEFAULT
  }, [classItem])

  const {
    handleSubmit,
    control,
    register,
    getValues,
    setValue,
    watch,
    errors,
  } = useForm<SessionItemForm>({
    defaultValues: {
      name: classItem.name || '',
      description: classItem.description || '',
      date: classItem.startDateTime
        ? new Date(new Date(classItem.startDateTime).setHours(0, 0, 0, 0))
        : undefined,
      startTime: classItem.startDateTime
        ? getTime(classItem.startDateTime)
        : undefined,
      endTime: classItem.endDateTime
        ? getTime(classItem.endDateTime)
        : undefined,
      meetingType: getMeetingType(),
      meetingLocation: classItem.meetingLocation,
      meetingLink: classItem.meetingLink || '',
      meetingPassword: classItem.meetingPassword || '',
    },
  })

  const sessionAttachment = useMemo(() => watch('sessionAttachments'), [watch])
  const checkFilledForm = useMemo(
    () =>
      classItem.name &&
      classItem.description &&
      classItem.startDateTime &&
      classItem.endDateTime,
    [classItem]
  )
  const meetingTypeUsing = useMemo(() => watch('meetingType'), [watch])

  // ---- handle upload attachments for session
  useEffect(() => {
    if (!!sessionAttachment?.length) {
      setReachLimit(false)
      const newAttachments = sessionAttachment.map<UploadFile>(attachment => ({
        file: attachment,
        status: 'public',
        uploadStatus: 'pending',
      }))

      const newFiles = [...uploadFiles, ...newAttachments]

      if (newFiles.length <= FILES_LIMIT) {
        handleChangeFiles(oldData => {
          const data = [...oldData]
          const changeItemIndex = oldData.findIndex(
            item => item.index === classItem.index
          )

          if (changeItemIndex > -1) {
            data[changeItemIndex].uploadFiles = [...newFiles]
            return data
          } else {
            return oldData
          }
        })

        const newData = { ...classItem, sessionAttachments: newFiles }
        handleChangeClass(classItem.index, newData)
      } else {
        setReachLimit(true)
      }

      setValue('sessionAttachments', [])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionAttachment, handleChangeClass, handleChangeFiles, setValue])

  const handleAddressKeyDown = useCallback(
    async (e: any) => {
      if (e.keyCode === 13) {
        const query = getValues('meetingLocation.address') || ''
        const geocodeOptions = await getGeocode(query as string)
        setAddresses(geocodeOptions)
        setValue('meetingLocation.lng', undefined)
        setValue('meetingLocation.lat', undefined)
      }
    },
    [getGeocode, getValues, setValue]
  )

  const handleSelectAddress = (address: MeetingLocation) => {
    setValue('meetingLocation', address)
    setAddresses([])
    handleSubmit(handleChangeForm)()
  }

  const handleDeleteAttachment = useCallback(
    (name: string, status?: string, id?: string) => {
      if (status === 'uploaded' && id) {
        setAttachmentIds(prevIds => [...prevIds, id])
      }

      const newFiles = uploadFiles.filter(
        attachment => attachment.file.name !== name
      )

      handleChangeFiles(oldData => {
        const data = [...oldData]
        const changeItemIndex = oldData.findIndex(
          item => item.index === classItem.index
        )
        data[changeItemIndex].uploadFiles = [...newFiles]
        return data
      })

      const newData = { ...classItem, sessionAttachments: newFiles }
      handleChangeClass(classItem.index, newData)
    },
    [classItem, handleChangeClass, handleChangeFiles, uploadFiles]
  )

  const handleReUpload = useCallback(
    (item: UploadClass) => async () => {
      if (classId && classItem.id) {
        const uploadUrl = await createUploadAttachmentSession({
          classId,
          sessionId: classItem.id,
          status: item.status || 'public',
          file: item.file,
        })

        await uploadFile(item.file, uploadUrl.data)
      }
    },
    [classId, classItem]
  )

  const handleChangeForm = useCallback(
    (value: SessionItemForm) => {
      const {
        name,
        description,
        date,
        startTime,
        endTime,
        meetingType,
        meetingLocation,
        meetingLink,
        meetingPassword,
      } = value

      let formattedData = {
        ...classItem,
        name: name,
        description: description,
        startDateTime: parseDateTimeUnix(date, startTime),
        endDateTime: parseDateTimeUnix(date, endTime),
        meetingType: meetingType,
        meetingLocation: meetingLocation,
        meetingLink,
        meetingPassword,
      }

      if (
        meetingType === MeetingType.VIRTUAL_DEFAULT ||
        meetingType === MeetingType.PHYSICAL
      ) {
        delete formattedData.meetingLink
        delete formattedData.meetingPassword
      }
      if (
        meetingType === MeetingType.VIRTUAL_DEFAULT ||
        meetingType === MeetingType.VIRTUAL_CUSTOM
      ) {
        delete formattedData.meetingLocation
      }

      handleChangeClass(classItem.index, formattedData)
    },
    [classItem, handleChangeClass]
  )

  const filterDate = (time: any) => {
    const currentDate = new Date()
    const selectedDate = new Date(time)

    return currentDate.getTime() < selectedDate.getTime() + 24 * 3600 * 1000
  }

  const filterStartTime = (time: any) => {
    const selectedDate = new Date(time.getTime() + 1000 * 60 * 15)

    const endTime = getValues('endTime')
    if (endTime) {
      return endTime.getTime() > selectedDate.getTime()
    }

    return true
  }

  const filterEndTime = (time: any) => {
    const selectedDate = new Date(time)

    const startTime = getValues('startTime')
    if (startTime) {
      return startTime.getTime() < selectedDate.getTime()
    }

    return true
  }

  useEffect(() => {
    if (attachmentIds.length) {
      const newData = { ...classItem, attachmentIds }
      handleChangeClass(classItem.index, newData)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attachmentIds])

  const renderLocationInput = (meetingType: any) => {
    switch (meetingType) {
      case MeetingType.PHYSICAL:
        return (
          <Flex direction='column' mt='1.2rem'>
            <Flex alignItems='center' fontSize='0.75rem' fontWeight='600'>
              <Text mr='0.5rem'>{_t('product.class.address')}</Text>
              <Tooltip label={_t('product.class.tooltip_address')}>
                <InfoIcon />
              </Tooltip>
            </Flex>
            <Controller
              control={control}
              name='meetingLocation.address'
              render={({ value, onChange }) => (
                <Input
                  placeholder={_t('product.class.placeholder_address')}
                  fontSize='0.875rem'
                  value={value}
                  onChange={onChange}
                  onKeyDown={handleAddressKeyDown}
                  onBlur={handleSubmit(handleChangeForm)}
                  labelProps={{ fontSize: '0.75rem' }}
                  error={errors?.meetingLocation?.address?.message}
                />
              )}
              rules={{
                validate: {
                  address: value => {
                    if (!value) {
                      return _t('product.class.validate_address_required')
                    }
                    return true
                  },
                },
              }}
            />
            <Controller
              control={control}
              name='meetingLocation.lng'
              render={({ value, onChange }) => (
                <Input type='hidden' value={value} onChange={onChange} />
              )}
            />
            <Controller
              control={control}
              name='meetingLocation.lat'
              render={({ value, onChange }) => (
                <Input type='hidden' value={value} onChange={onChange} />
              )}
            />
            {register && (
              <Input type='hidden' {...register(`meetingLocation.id`)} />
            )}
            {addresses.length > 0 && (
              <Box mt='0.5rem'>
                <Flex alignItems='center' mb='0.5rem'>
                  <Text fontSize='0.75rem' fontWeight='600' mr='0.5rem'>
                    {_t('product.class.address_lookup')}
                  </Text>
                  <Tooltip label={_t('product.class.tooltip_address_lookup')}>
                    <InfoIcon />
                  </Tooltip>
                </Flex>
                {addresses.map((item: MeetingLocation, index: number) => {
                  return (
                    <Box key={`address-${index}`}>
                      {index !== 0 && <Divider my='0.5rem' />}
                      <Box
                        cursor='pointer'
                        fontSize='0.75rem'
                        onClick={() => handleSelectAddress(item)}
                      >
                        {item?.address}
                      </Box>
                    </Box>
                  )
                })}
              </Box>
            )}
          </Flex>
        )
      case MeetingType.VIRTUAL_CUSTOM:
        return (
          <SimpleGrid columns={[1, 1, 2]} spacing='1.2rem' mt='1.2rem'>
            <Controller
              name='meetingLink'
              control={control}
              render={({ onChange, value }) => (
                <Input
                  aria-label='Link'
                  label={_t('product.class.custom_link')}
                  placeholder={_t('product.class.placeholder_custom_link')}
                  onChange={onChange}
                  onBlur={handleSubmit(handleChangeForm)}
                  value={value}
                  fontSize='0.875rem'
                  labelProps={{ fontSize: '0.75rem' }}
                />
              )}
            />
            <Controller
              name='meetingPassword'
              control={control}
              render={({ onChange, value }) => (
                <Input
                  type='password'
                  aria-label='Password'
                  label={_t('product.class.custom_link_password')}
                  placeholder={_t(
                    'product.class.placeholder_custom_link_password'
                  )}
                  onChange={onChange}
                  onBlur={handleSubmit(handleChangeForm)}
                  value={value}
                  fontSize='0.875rem'
                  labelProps={{ fontSize: '0.75rem' }}
                />
              )}
            />
          </SimpleGrid>
        )
      default:
        return null
    }
  }

  return (
    <AccordionItem rounded='lg' boxShadow='lg' border='0.8px solid #DEDEDE'>
      <AccordionButton p='1rem' h='100%'>
        <Box flex='1' textAlign='left'>
          {checkFilledForm ? (
            <Box flex='1' textAlign='left'>
              <Text
                fontSize='0.75rem'
                fontWeight='600'
                color='primary'
                mb='0.25rem'
              >
                {`${format(
                  classItem.startDateTime,
                  'dd MMM yyyy, eee | h:mma'
                )} - ${format(classItem.endDateTime, 'h:mma')}` || '-'}
              </Text>
              <Text>{classItem.name || '-'}</Text>
            </Box>
          ) : (
            <Text color='primary'>{_t('product.class.session_empty')}</Text>
          )}
        </Box>
        <ConfirmAlert
          onSubmit={() => handleDeleteClass(classItem.index)}
          description={
            <Text textAlign='center'>
              {_t('product.class.session_remove_description')}
            </Text>
          }
          immediatelyCloseOnSubmit
        >
          {({ showAlert }) => (
            <TrashIcon cursor='pointer' onClick={showAlert} />
          )}
        </ConfirmAlert>
        <AccordionIcon />
      </AccordionButton>
      <AccordionPanel pb={4}>
        <Flex direction={{ base: 'column', md: 'row' }} justify='space-between'>
          <Box flex='1'>
            <Box mt='1.2rem'>
              <Controller
                control={control}
                name='name'
                render={({ onChange, value }) => (
                  <Input
                    label={_t('product.class.session_name')}
                    aria-label='Session Name'
                    placeholder={_t('product.class.placeholder_session_name')}
                    onChange={onChange}
                    onBlur={handleSubmit(handleChangeForm)}
                    value={value}
                    fontSize='0.875rem'
                    labelProps={{ fontSize: '0.75rem' }}
                  />
                )}
              />
            </Box>
            <Flex direction='column'>
              <SimpleGrid columns={[1, 1, 3]} spacing='1.2rem' mt='1.2rem'>
                <Controller
                  control={control}
                  name='date'
                  render={({ onChange, value }) => (
                    <DateTimePicker
                      label={_t('product.class.date')}
                      onChange={e => {
                        onChange(e)
                        handleSubmit(handleChangeForm)()
                      }}
                      selected={value}
                      placeholderText={_t('product.class.placeholder_date')}
                      labelProps={{ fontSize: '0.75rem' }}
                      filterDate={filterDate}
                    />
                  )}
                  rules={{
                    required: _t('product.class.validate_date_required'),
                  }}
                />
                <Controller
                  control={control}
                  name='startTime'
                  render={({ onChange, value }) => (
                    <DateTimePicker
                      label={_t('product.class.start_time')}
                      onChange={e => {
                        onChange(e)
                        handleSubmit(handleChangeForm)()
                      }}
                      selected={value}
                      showTimeSelect
                      showTimeSelectOnly
                      dateFormat='h:mm aa'
                      timeIntervals={15}
                      placeholderText={_t(
                        'product.class.placeholder_start_time'
                      )}
                      labelProps={{ fontSize: '0.75rem' }}
                      filterTime={filterStartTime}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name='endTime'
                  render={({ onChange, value }) => (
                    <DateTimePicker
                      label={_t('product.class.end_time')}
                      onChange={e => {
                        onChange(e)
                        handleSubmit(handleChangeForm)()
                      }}
                      selected={value}
                      showTimeSelect
                      showTimeSelectOnly
                      dateFormat='h:mm aa'
                      timeIntervals={15}
                      placeholderText={_t('product.class.placeholder_end_time')}
                      labelProps={{ fontSize: '0.75rem' }}
                      filterTime={filterEndTime}
                    />
                  )}
                />
              </SimpleGrid>
              {(errors?.date?.message ||
                errors?.startTime?.message ||
                errors?.endTime?.message) && (
                <Text textStyle='error'>
                  {errors?.date?.message ||
                    errors?.startTime?.message ||
                    errors?.endTime?.message}
                </Text>
              )}
            </Flex>
            <Box mt='1.2rem'>
              <Controller
                control={control}
                name='description'
                render={({ onChange, value }) => (
                  <Textarea
                    aria-label='Description'
                    label={_t('product.class.session_description')}
                    onChange={onChange}
                    onBlur={handleSubmit(handleChangeForm)}
                    value={value}
                    placeholder={_t(
                      'product.class.placeholder_session_description'
                    )}
                    fontSize='0.875rem'
                    labelProps={{ fontSize: '0.75rem' }}
                  />
                )}
                error={errors?.description?.message || ''}
                rules={{
                  required: _t(
                    'product.class.validate_session_description_required'
                  ),
                }}
              />
            </Box>
          </Box>
          <Box w='2rem' />
          <Box flex='1'>
            <Flex flexDirection='column'>
              <Text fontSize='0.75rem' fontWeight='600' mt='1.2rem'>
                {_t('product.class.meeting_type')}
              </Text>
              <Controller
                control={control}
                name='meetingType'
                defaultValue={MeetingType.VIRTUAL_DEFAULT}
                render={({ onChange, value }) => (
                  <RadioGroup
                    onChange={e => {
                      onChange(e)
                      handleSubmit(handleChangeForm)()
                    }}
                    value={value}
                  >
                    <Flex
                      direction={{ base: 'column', md: 'row' }}
                      justify='space-between'
                    >
                      <Radio value={MeetingType.VIRTUAL_DEFAULT} mt='0.5rem'>
                        <Flex alignItems='center'>
                          <Text fontSize='0.875rem' mr='0.5rem'>
                            {_t('product.class.meeting_type_knovo')}
                          </Text>
                          <Tooltip
                            label={_t(
                              'product.class.tooltip_meeting_type_knovo'
                            )}
                          >
                            <InfoIcon />
                          </Tooltip>
                        </Flex>
                      </Radio>
                      <Radio value={MeetingType.VIRTUAL_CUSTOM} mt='0.5rem'>
                        <Flex alignItems='center'>
                          <Text fontSize='0.875rem' mr='0.5rem'>
                            {_t('product.class.meeting_type_custom')}
                          </Text>
                          <Tooltip
                            label={_t(
                              'product.class.tooltip_meeting_type_custom'
                            )}
                          >
                            <InfoIcon />
                          </Tooltip>
                        </Flex>
                      </Radio>
                      <Radio value={MeetingType.PHYSICAL} mt='0.5rem'>
                        <Flex alignItems='center'>
                          <Text fontSize='0.875rem' mr='0.5rem'>
                            {_t('product.class.meeting_type_physical')}
                          </Text>
                          <Tooltip
                            label={_t(
                              'product.class.tooltip_meeting_type_physical'
                            )}
                          >
                            <InfoIcon />
                          </Tooltip>
                        </Flex>
                      </Radio>
                    </Flex>
                  </RadioGroup>
                )}
              />
            </Flex>
            {renderLocationInput(meetingTypeUsing)}

            <Box mt='1.2rem'>
              <Controller
                control={control}
                render={({ onChange }) => (
                  <FileUpload
                    onChange={onChange}
                    maxFiles={3}
                    isReachLimit={reachLimit}
                  />
                )}
                name='sessionAttachments'
              />
              {!!uploadFiles?.length && (
                <Box mt='1.2rem' p='0.5rem' pb='1rem' overflowY='auto'>
                  {uploadFiles.map(({ file, uploadStatus, status }) => (
                    <AttachmentFile
                      key={file.key || file.name}
                      item={file}
                      deletable
                      uploadStatus={isUploading ? 'uploading' : uploadStatus}
                      onDelete={handleDeleteAttachment}
                      withStatus={false}
                      onReUpload={handleReUpload({
                        file,
                        uploadStatus,
                        status,
                      })}
                    />
                  ))}
                </Box>
              )}
            </Box>
          </Box>
        </Flex>
      </AccordionPanel>
    </AccordionItem>
  )
}

export default SessionItem

import React from 'react'
import { Link, useNavigate } from 'react-router-dom'

import { Avatar, Box, Flex } from '@chakra-ui/react'

import { ImageWrapper, Rating } from 'components'
import { UserConnectionButton } from 'shared/containers'
import { CentreSearchResult } from 'types'

import defaultBanner from 'assets/images/default_centre_banner.jpg'

type CentreCardProps = {
  data: CentreSearchResult
}

const CentreCard: React.FC<CentreCardProps> = ({ data }: CentreCardProps) => {
  const navigate = useNavigate()

  const handleView = () => {
    navigate(`/centre/${data.id}`)
  }

  return (
    <Flex
      h={{ base: '260px', md: '300px' }}
      w={{ base: '160px', md: '210px' }}
      minW={{ base: '160px', md: '210px' }}
      direction='column'
      mx='0.7rem'
      mt='0.5rem'
      mb='0.7rem'
      boxShadow='lg'
      rounded='lg'
      position='relative'
      bg='white'
    >
      <ImageWrapper
        h={{ base: '60px', md: '80px' }}
        w='full'
        roundedTop='lg'
        bg='primary'
        src={data.banner || defaultBanner}
        key={data.banner || 'default-banner'}
        objectFit={'cover'}
      />
      <Flex justify='center' mt={-8}>
        <Avatar
          size='lg'
          name={data.name || ''}
          src={data.avatar}
          key={data.avatar || 'default-avatar'}
          css={{
            border: '2px solid white',
          }}
        />
      </Flex>
      <Flex flex='1' direction='column' p='1rem' pt='0.5rem'>
        <Flex
          flex='1'
          direction='column'
          alignItems='center'
          justifyContent='flex-start'
        >
          <Box
            as={Link}
            to={`/centre/${data.id}`}
            textAlign='center'
            fontWeight='600'
            mb='4px'
            _hover={{
              color: 'primary',
            }}
            _before={{
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              cursor: 'pointer',
            }}
            noOfLines={2}
          >
            {data.name || ''}
          </Box>
          {data.rating && <Rating rate={data.rating} />}
        </Flex>
        <Box zIndex={1}>
          <UserConnectionButton
            friendId={data?.id || ''}
            connection={data.connection}
            handleView={handleView}
          />
        </Box>
      </Flex>
    </Flex>
  )
}

export default CentreCard

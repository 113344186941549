import Axios from 'axios'

import { language } from 'configs/apiPath'
import { Language } from 'types'

export const addLanguage = async ({
  name,
  code,
  active = false,
}: Omit<Language, 'id'>): Promise<void> => {
  return Axios.post(language.create(), {
    name,
    code,
    active,
  })
}

export const updateLanguage = async ({
  id,
  name,
  code,
  active,
}: Language): Promise<void> => {
  return Axios.put(language.detail(id), {
    name,
    code,
    active,
  })
}

export const deleteLanguage = async (id: string): Promise<void> => {
  return Axios.delete(language.detail(id))
}

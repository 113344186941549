import React, { useCallback, useMemo } from 'react'

import { Avatar, Badge, Box, Flex, Text } from '@chakra-ui/react'

import { EnterpriseLabel } from 'components'
import { AccountType, UserProfile } from 'types'
import {
  isEnterpriseAccount,
  isInstructorAccount,
  isLearnerAccount,
} from 'utils/helper'
import { getDisplayName } from 'utils/parser'

import { ReactComponent as CoordinateIcon } from 'assets/images/coordinate_icon.svg'
import { ReactComponent as RateStar } from 'assets/images/rate_star.svg'

interface UserDisplayCardProps {
  data: UserProfile
}

const UserDisplayCard: React.FC<UserDisplayCardProps> = ({ data }) => {
  const { accountType = {} as AccountType } = data

  const isLearner = useMemo(() => isLearnerAccount(accountType.name), [
    accountType.name,
  ])
  const isInstructor = useMemo(() => isInstructorAccount(accountType.name), [
    accountType.name,
  ])
  const isEnterprise = useMemo(() => isEnterpriseAccount(accountType.name), [
    accountType.name,
  ])
  const displayName = useMemo(
    () => getDisplayName(data.firstName || '', data.lastName || ''),
    [data]
  )

  const renderSubjects = useCallback(() => {
    return (
      data?.subjects &&
      data?.subjects.map((subject: any) => (
        <Badge
          px={3}
          py={1}
          mx={1}
          variant='outline'
          fontSize='0.625rem'
          lineHeight='0.875rem'
          borderRadius='1rem'
          maxW={{ base: '150px', md: '200px' }}
          overflowX='hidden'
          textOverflow='ellipsis'
        >
          {subject.name}
        </Badge>
      ))
    )
  }, [data])

  const renderCentres = useCallback(() => {
    return (
      data?.centers &&
      data?.centers.map((centre: any) => (
        <Badge
          px={3}
          py={1}
          mx={1}
          variant='outline'
          fontSize='0.625rem'
          lineHeight='0.875rem'
          borderRadius='1rem'
          maxW={{ base: '150px', md: '200px' }}
          overflowX='hidden'
          textOverflow='ellipsis'
        >
          {centre.name}
        </Badge>
      ))
    )
  }, [data])

  const renderCountry = useCallback(() => {
    return (
      data?.country && (
        <Flex
          direction='row'
          alignItems='center'
          justifyContent='center'
          py='1rem'
        >
          <CoordinateIcon height='13px' width='13px' />
          <Text
            fontSize='0.825rem'
            color='gray.300'
            lineHeight='0.875rem'
            m='0.3rem'
          >
            {data.country || ''}
          </Text>
        </Flex>
      )
    )
  }, [data])

  return (
    <Flex
      h={{ base: '260px', md: '300px' }}
      w={{ base: '160px', md: '210px' }}
      minW={{ base: '160px', md: '210px' }}
      direction='column'
      p='1rem'
      mx='0.7rem'
      mt='0.5rem'
      mb='0.7rem'
      boxShadow='lg'
      rounded='lg'
      position='relative'
      bg='white'
    >
      <Flex alignItems='center' position='absolute' top='5px' right='1rem'>
        {isInstructor && data.rating && (
          <>
            <RateStar />
            <Text ml='5px' fontSize='0.825rem'>
              {data.rating}
            </Text>
          </>
        )}
      </Flex>
      <Box position='relative' m='auto'>
        <Avatar
          size='lg'
          name={displayName}
          src={data.avatar}
          key={data.avatar || 'default-avatar'}
        />
        {isEnterprise && <EnterpriseLabel />}
      </Box>
      <Flex
        flex='1'
        direction='column'
        alignItems='center'
        justifyContent='flex-start'
        pt='1rem'
      >
        <Box textAlign='center' fontWeight='600' mb='4px' noOfLines={2}>
          {displayName}
        </Box>
        <Box textAlign='center' noOfLines={2}>
          {isInstructor && renderCentres()}
          {isLearner && renderSubjects()}
        </Box>
      </Flex>
      {renderCountry()}
    </Flex>
  )
}

export default UserDisplayCard

import React from 'react'
import ReactDOM from 'react-dom'
import TagManager from 'react-gtm-module'
import {
  QueryClient,
  QueryClientProvider,
  QueryFunctionContext,
} from 'react-query'
import { ReactQueryDevtools } from 'react-query-devtools'
import { BrowserRouter } from 'react-router-dom'

import { ChakraProvider } from '@chakra-ui/react'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { Provider } from 'jotai'

import * as serviceWorker from './serviceWorker'
import { defaultFetcher } from './apis/fetcher'
import App from './App'
import { setupAxiosInterceptors } from './configs/axiosConfig'
import theme from './customTheme'
import './libs/aws-amplify.config'

import './index.css'

setupAxiosInterceptors()

const defaultQueryFn = ({ queryKey }: QueryFunctionContext) => {
  return defaultFetcher(queryKey[0] as string)
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      queryFn: defaultQueryFn,
      retry: 1,
    },
  },
})

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_ID || '',
}

TagManager.initialize(tagManagerArgs)

const publicKeyPayment = process.env.REACT_APP_STRIPE_PUBLIC_KEY || ''
const stripePromise = loadStripe(publicKeyPayment)

const renderer = () =>
  ReactDOM.render(
    <QueryClientProvider client={queryClient}>
      <Provider>
        <BrowserRouter>
          <ChakraProvider resetCSS={true} theme={theme}>
            <Elements stripe={stripePromise}>
              <App />
              <ReactQueryDevtools initialIsOpen={false} />
            </Elements>
          </ChakraProvider>
        </BrowserRouter>
      </Provider>
    </QueryClientProvider>,
    document.getElementById('root')
  )

if (process.env.NODE_ENV !== 'production') {
  import('react-axe').then(axe => {
    axe.default(React, ReactDOM, 1000)
    renderer()
  })
} else {
  renderer()
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()

import { ApiUserType, Meta, PaymentType } from 'types'

export enum TransactionType {
  TOPUP = 'topup',
  PURCHASE = 'purchase',
  SUBSCRIPTION = 'subscription',
  EARNING = 'earning',
  SUBSCRIPTION_EARNING = 'subscription_earning',
  REFUND = 'refund',
  WITHDRAWAL = 'withdrawal',
  WRITE_OFF = 'write_off',
}

export enum TransactionStatus {
  PENDING = 'pending',
  PENDING_APPROVAL = 'pending_approval',
  APPROVED = 'approved',
  REJECTED = 'rejected',
  SUCCESS = 'success',
  FAILED = 'failed',
  PROCESSING = 'processing',
  COMPLETED = 'completed',
  REFUNDED = 'refunded',
}

export enum TransactionApprovalStatus {
  PENDING = 'pending',
  APPROVED = 'approved',
  REJECTED = 'rejected',
}

export interface TransactionHistory {
  id: string
  transactionId: string
  status: TransactionStatus
  paymentType: PaymentType
  paymentIntentId: string
  remarks: string
  createdAt: number
}

export interface TransactionInfo {
  id: string
  userId?: string
  centerId?: string
  enterpriseId?: string
  orderId: string
  amount: number
  currency: string
  status: TransactionStatus
  type: TransactionType
  method: string
  paymentType: PaymentType
  paymentIntentId?: string
  refundSessionId?: string
  description: string
  remarks: string
  createdAt: number
  updatedAt: number
  history?: TransactionHistory[]
  user?: {
    id: string
    firstName: string
    lastName: string
    email: string
    avatar: string
  }
  center?: {
    id: string
    name: string
    email: string
    avatar: string
  }
  enterprise?: {
    id: string
    name: string
    email: string
    avatar: string
  }
}

export interface TransactionApprovalInfo {
  id: string
  transaction: TransactionInfo
  description: string
  status: TransactionApprovalStatus
  reason: string
  approverComment?: string
  createdAt: number
  updatedAt: number
  learner: {
    id: string
    firstName: string
    lastName: string
    avatar: string
  }
}

export interface TransactionExportUrl {
  url: string
}

export interface TransactionListRequest {
  type: ApiUserType
  id: string
}

export interface TransactionListResponse extends Meta {
  data: TransactionInfo[]
}

export interface TransactionInfoRequest {
  type: ApiUserType
  id: string
  transactionId: string
}

export interface TransactionInfoResponse {
  data: TransactionInfo
}

export interface TransactionAdminListResponse extends Meta {
  data: TransactionInfo[]
}

export interface TransactionAdminInfoRequest {
  transactionId: string
}

export interface TransactionAdminInfoResponse {
  data: TransactionInfo
}

export interface TransactionAdminExportRequest {
  order?: string
  orderBy?: string
  fileType?: string
  status?: TransactionStatus
  type?: TransactionType
  paymentType?: PaymentType
}

export interface TransactionAdminExportResponse {
  data: TransactionExportUrl
}

export interface TransactionAdminCreateWithdrawalRequest {
  userId?: string
  centerId?: string
  amount: number
  paymentType: PaymentType
  remarks: string
}

export interface TransactionAdminUpdateWithdrawalRequest {
  transactionId: string
  status?: string
  remarks?: string
}

export interface TransactionApprovalListRequest {
  type: ApiUserType
  id: string
}

export interface TransactionApprovalListResponse extends Meta {
  data: TransactionApprovalInfo[]
}

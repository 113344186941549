import React, { forwardRef } from 'react'

import {
  Avatar,
  Box,
  Flex,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuItemOption,
  MenuItemProps,
  MenuList,
  MenuOptionGroup,
  Portal,
  Text,
  useDisclosure,
} from '@chakra-ui/react'

import { Link } from 'components'
import { useLanguages } from 'hooks'
import { useLanguages as useQueryLanguages } from 'queries/language'
import { UserProfile, UserRole } from 'types'

import { ReactComponent as Centre } from 'assets/images/centre_icon.svg'
import defaultAvatar from 'assets/images/default_avatar_grayscale.svg'
import { ReactComponent as Enterprise } from 'assets/images/enterprise_profile_icon.svg'
import { ReactComponent as Logout } from 'assets/images/logout_icon.svg'
import { ReactComponent as Money } from 'assets/images/money.svg'
import { ReactComponent as Setting } from 'assets/images/setting_icon.svg'

interface UserMenuProps {
  onLogout: () => void
  hasWallet?: boolean
  hasEnterpriseProfile?: boolean
  hasCenterProfile?: boolean
  userProfile: UserProfile | null
  profileUrl?: string
}

const Item: React.FC<MenuItemProps> = ({ children, ...props }) => {
  return (
    <MenuItem
      _active={{
        backgroundColor: 'initial',
      }}
      {...props}
    >
      {children}
    </MenuItem>
  )
}

const LanguagesMenu = forwardRef((props, ref) => {
  const { onOpen, isOpen, onClose } = useDisclosure()
  const { handleChangeLanguage, getLanguage, _t } = useLanguages()
  const allowLanguageCodes = ['en', 'zh']

  const { data: languages } = useQueryLanguages({
    page: 1,
    limit: 100,
  })

  return (
    <Menu placement='left' onOpen={onOpen} onClose={onClose} isOpen={isOpen}>
      <MenuButton ref={ref as React.LegacyRef<HTMLButtonElement>} {...props}>
        <Flex>
          <Flex width='2.5rem' justifyContent='center'>
            <Setting />
          </Flex>
          <Box ml='0.75rem' as='span' color='#000'>
            {_t('common.language')}
          </Box>
        </Flex>
      </MenuButton>
      <Portal>
        <MenuList maxHeight='15rem' overflowY='auto'>
          <MenuOptionGroup
            defaultValue={getLanguage()}
            title={_t('common.select_language')}
          >
            {isOpen &&
              languages?.data &&
              languages?.data.map(language => {
                if (allowLanguageCodes.includes(language.code)) {
                  return (
                    <MenuItemOption
                      key={language.id}
                      value={language.code}
                      onClick={() => handleChangeLanguage(language.code)}
                    >
                      <Flex>
                        <Flex
                          w={25}
                          h={25}
                          p='0.75rem'
                          mr='0.5rem'
                          fontSize='0.75rem'
                          color='white'
                          background='primaryDarker'
                          alignItems='center'
                          justifyContent='center'
                          rounded='full'
                          textTransform='uppercase'
                        >
                          {language.code}
                        </Flex>
                        {language.name}
                      </Flex>
                    </MenuItemOption>
                  )
                }
                return null
              })}
          </MenuOptionGroup>
        </MenuList>
      </Portal>
    </Menu>
  )
})

const UserMenu: React.FC<UserMenuProps> = ({
  onLogout,
  userProfile,
  hasWallet,
  hasEnterpriseProfile,
  hasCenterProfile,
  profileUrl = '/my-profile',
}) => {
  const { _t } = useLanguages()
  const isMstAdmin = React.useMemo(
    () =>
      userProfile?.accountType?.name === UserRole.MSTSuperAdmin ||
      userProfile?.accountType?.name === UserRole.MSTAdmin,
    [userProfile]
  )

  return (
    <Menu closeOnSelect={false}>
      <MenuButton>
        <Avatar
          src={isMstAdmin ? defaultAvatar : userProfile?.avatar}
          size='md'
          name={`${userProfile?.firstName} ${userProfile?.lastName}`}
        />
      </MenuButton>
      <MenuList minW='16rem' fontSize='0.875rem'>
        <Item minH='3.25rem'>
          <Box as={Link} to={profileUrl}>
            <Flex alignItems='center'>
              <Avatar
                src={isMstAdmin ? defaultAvatar : userProfile?.avatar}
                size='md'
                name={`${userProfile?.firstName} ${userProfile?.lastName}`}
              />
              <Box ml='0.75rem'>
                <Text
                  color='#000'
                  maxW='12rem'
                  noOfLines={1}
                  title={`${userProfile?.firstName} ${userProfile?.lastName}`}
                >
                  {userProfile?.firstName} {userProfile?.lastName}
                </Text>
                <Text fontSize='0.75rem' color='primary'>
                  {_t('common.view_profile')}
                </Text>
              </Box>
            </Flex>
          </Box>
        </Item>
        {hasWallet && (
          <Box as={Link} to='/wallet'>
            <MenuDivider />
            <Item minH='2.25rem'>
              <Flex width='2.5rem' justifyContent='center'>
                <Money />
              </Flex>
              <Box ml='0.75rem' as='span' color='#000'>
                {_t('common.wallet')}
              </Box>
            </Item>
          </Box>
        )}
        {hasEnterpriseProfile && (
          <Box as={Link} to='/enterprise-admin/enterprise-profile'>
            <MenuDivider />
            <Item minH='2.25rem'>
              <Flex width='2.5rem' justifyContent='center'>
                <Enterprise />
              </Flex>
              <Box ml='0.75rem' as='span' color='#000'>
                {_t('common.view_enterprise_profile')}
              </Box>
            </Item>
          </Box>
        )}
        {hasCenterProfile && (
          <Box as={Link} to='/center-admin/center-profile'>
            <MenuDivider />
            <Item minH='2.25rem'>
              <Flex width='2.5rem' justifyContent='center'>
                <Centre />
              </Flex>
              <Box ml='0.75rem' as='span' color='#000'>
                {_t('common.view_center_profile')}
              </Box>
            </Item>
          </Box>
        )}
        <MenuDivider />
        <Item minH='2.25rem' as={LanguagesMenu} />
        <MenuDivider />
        <Item minH='2.25rem' onClick={onLogout}>
          <Flex width='2.5rem' justifyContent='center'>
            <Logout />
          </Flex>
          <Box ml='0.75rem' as='span' color='#000'>
            {_t('common.logout')}
          </Box>
        </Item>
      </MenuList>
    </Menu>
  )
}

export default UserMenu

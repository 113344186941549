import React from 'react'

import { Box, Flex, Text } from '@chakra-ui/react'
import format from 'date-fns/format'

import { Attachment } from 'types'
import { getBytes } from 'utils/parser'

import { ReactComponent as DocumentIcon } from 'assets/images/attached_icon.svg'
import { ReactComponent as DownloadIcon } from 'assets/images/download_icon.svg'

interface MaterialItemProps {
  data: Attachment
  onDownloadAttachment?: (key: string) => void
}

const MaterialItem: React.FC<MaterialItemProps> = ({
  data,
  onDownloadAttachment,
}) => {
  return (
    <Box
      py='1rem'
      px={{ base: '1rem', md: '2rem' }}
      rounded='lg'
      boxShadow='lg'
      border='0.8px solid #DEDEDE'
    >
      <Flex justify='space-between'>
        <Flex align='center'>
          <DocumentIcon />
          <Box ml='1rem'>
            <Text fontWeight='600'>{data.name}</Text>
            {data.createdAt && (
              <Text fontSize='0.75rem' color='gray.300'>
                Uploaded: {format(data.createdAt, 'dd MMM yyyy, h:mma')}
              </Text>
            )}
          </Box>
        </Flex>
        <Flex align='center'>
          <Text mr='0.5rem' fontSize='0.75rem' color='primary'>
            {getBytes(data.size)}
          </Text>
          {!!onDownloadAttachment && (
            <DownloadIcon
              cursor='pointer'
              color='#312A8D'
              height='1.2rem'
              width='1.2rem'
              onClick={() => onDownloadAttachment(data.key as string)}
            />
          )}
        </Flex>
      </Flex>
    </Box>
  )
}

export default MaterialItem

import { useEffect, useState } from 'react'

import { useAtom } from 'jotai'

import { userProfileAtom } from 'store/authAtom'
import { UserRole } from 'types'

const useCenterId = () => {
  const [userProfile] = useAtom(userProfileAtom)
  const isMSTSuperAdmin = !!userProfile?.roles?.find(
    o => o.type === UserRole.MSTSuperAdmin
  )
  const [centerId, setCenterId] = useState<string>(
    isMSTSuperAdmin ? '' : userProfile?.centers?.[0]?.id || ''
  )

  useEffect(() => {
    if (userProfile?.centers?.[0]?.id && !isMSTSuperAdmin) {
      setCenterId(userProfile.centers[0].id)
    }
  }, [userProfile, isMSTSuperAdmin])

  return centerId
}

export default useCenterId

import React, { useCallback, useMemo, useState } from 'react'

import { Box, Button, Flex, SimpleGrid, Text } from '@chakra-ui/react'

import EditBankAccountInfo from './EditBankAccountInfo'
import { Tooltip } from 'components'
import {
  useCenterId,
  useLanguages,
  useUserId,
  useUtilsForEnterpriseId,
} from 'hooks'
import { useUpdateBankAccount } from 'queries/wallet'
import {
  ApiUserType,
  BankDetailForm,
  UserRole,
  UserRoleType,
  UserWalletInfo,
  WalletBankRequest,
} from 'types'
import { bankAccountCountryOptions } from 'utils/constant'
import { mask } from 'utils/helper'
import { getSelectedOptions } from 'utils/parser'

import { ReactComponent as NoteIcon } from 'assets/images/note_icon.svg'

interface BankAccountInfoProps {
  role: UserRoleType
  walletInfo?: UserWalletInfo
}

const BankAccountInfo: React.FC<BankAccountInfoProps> = ({
  role,
  walletInfo,
}) => {
  const { _t } = useLanguages()
  const userId = useUserId()
  const centerId = useCenterId()
  const { enterpriseId } = useUtilsForEnterpriseId()
  const [isEditingBankDetail, setIsEditingBankDetail] = useState<boolean>(false)

  const type = useMemo(() => {
    switch (role) {
      case UserRole.MSTAdmin:
      case UserRole.MSTSuperAdmin:
        return { user: 'admin', id: userId }
      case UserRole.EnterpriseAdmin:
      case UserRole.EnterpriseSuperAdmin:
        return { user: 'enterprise', id: enterpriseId }
      case UserRole.CenterAdmin:
        return { user: 'center', id: centerId }
      default:
        return { user: 'user', id: userId }
    }
  }, [role, userId, enterpriseId, centerId])

  const { mutate: updateBankAccount } = useUpdateBankAccount({
    type: type.user as ApiUserType,
    id: type.id,
  })

  const handleOpenEditBankDetail = useCallback(() => {
    setIsEditingBankDetail(true)
  }, [])

  const handleCloseEditBankDetail = useCallback(() => {
    setIsEditingBankDetail(false)
  }, [])

  const handleUpdateBankAccount = useCallback(
    (value: BankDetailForm) => {
      const {
        bankAccountName,
        bankAccountNumber,
        bankName,
        country,
        bankRemarks,
      } = value

      const formattedData = {
        type: type.user as ApiUserType,
        id: type.id,
        bankAccountName,
        bankAccountNumber,
        bankName,
        country: country.value,
        bankRemarks,
      } as WalletBankRequest

      updateBankAccount({
        data: formattedData,
        onClose: handleCloseEditBankDetail,
      })
    },
    [handleCloseEditBankDetail, updateBankAccount, type]
  )

  const renderItem = useCallback(
    (label: string, value?: string, tooltip?: string) => {
      return (
        <Box>
          <Flex align='center'>
            <Text
              fontSize='0.75rem'
              color='gray.300'
              textTransform='uppercase'
              mr='0.5rem'
            >
              {label}
            </Text>
            {tooltip && (
              <Tooltip label={tooltip}>
                <NoteIcon width='12px' height='12px' />
              </Tooltip>
            )}
          </Flex>
          <Text whiteSpace='pre-wrap' noOfLines={2}>
            {value || '-'}
          </Text>
        </Box>
      )
    },
    []
  )

  return (
    <Box
      flex='2'
      bg='white'
      py='1.5rem'
      px='2rem'
      mx={{ base: '0', md: '0.5rem' }}
      my='0.5rem'
      boxShadow='lg'
      rounded='lg'
    >
      {isEditingBankDetail ? (
        <EditBankAccountInfo
          initData={walletInfo}
          onClose={handleCloseEditBankDetail}
          onSubmitUpdate={handleUpdateBankAccount}
        />
      ) : (
        <>
          <Flex justifyContent='space-between' alignItems='center' mb='1rem'>
            <Flex align='center'>
              <Text fontWeight='600' mr='0.5rem'>
                {_t('user.bank_account.title')}
              </Text>
              <Tooltip label={_t('user.bank_account.tooltip_title')}>
                <NoteIcon />
              </Tooltip>
            </Flex>
            <Button
              variant='outlinePrimary'
              textTransform='uppercase'
              onClick={handleOpenEditBankDetail}
            >
              {_t('common.edit')}
            </Button>
          </Flex>
          <SimpleGrid mb='1rem' columns={[1, 1, 2, 2]} spacing='1rem'>
            {renderItem(
              _t('user.bank_account.name'),
              walletInfo?.bankAccountName,
              _t('user.bank_account.tooltip_name')
            )}
            {renderItem(
              _t('user.bank_account.bank_name'),
              walletInfo?.bankName,
              _t('user.bank_account.tooltip_bank_name')
            )}
            {renderItem(
              _t('user.bank_account.number'),
              mask(walletInfo?.bankAccountNumber, 4),
              _t('user.bank_account.tooltip_number')
            )}
            {renderItem(
              _t('user.bank_account.country'),
              walletInfo?.country &&
                getSelectedOptions(
                  bankAccountCountryOptions,
                  walletInfo.country
                ).label,
              _t('user.bank_account.tooltip_country')
            )}
          </SimpleGrid>
          {renderItem(
            _t('user.bank_account.remarks'),
            walletInfo?.bankRemarks,
            _t('user.bank_account.tooltip_remarks')
          )}
        </>
      )}
    </Box>
  )
}

export default BankAccountInfo

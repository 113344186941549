import React from 'react'

import {
  CenterAdminProfile,
  CenterEditClass,
  CenterEditContent,
  CenterPreviewClass,
  CenterPreviewContent,
  CenterPreviewLesson,
  CenterProfile,
  CreateClass,
  CreateContent,
  CreateInstructor,
  CreateLearner,
  CreateLesson,
  CreateSessionLanding,
  EditCenterProfile,
  EditCentreAdmin,
  EditInstructor,
  EditLearner,
  Notifications,
  SessionManagement,
  TransactionApproval,
  UserManagement,
  ViewCenterAdmin,
  ViewMyTransaction,
  Wallet,
} from 'containers/CenterAdmin'
import { SidebarRoute, UserRole } from 'types'

export const centerAdminRoutes: SidebarRoute[] = [
  // {
  //   name: 'admin.sidebar.dashboard',
  //   link: '/center-admin'
  // },
  {
    name: '',
    link: '/center-admin/my-profile',
    element: <CenterAdminProfile />,
    hidden: true,
  },
  {
    name: '',
    link: '/center-admin/center-profile',
    element: <CenterProfile />,
    hidden: true,
  },
  {
    name: '',
    link: '/center-admin/edit-center',
    element: <EditCenterProfile />,
    hidden: true,
  },
  {
    name: '',
    link: '/center-admin/profile/edit/:id',
    element: <EditCentreAdmin isOnProfileAdmin />,
    hidden: true,
  },
  {
    name: 'admin.sidebar.user_management',
    link: '/center-admin/user-management',
    children: [
      {
        name: 'admin.sidebar.learners',
        link: '/center-admin/user-management/learners',
        element: (
          <UserManagement
            tableType='Learners'
            type={UserRole.AdminManageStudent}
          />
        ),
      },
      {
        name: '',
        link: '/center-admin/user-management/learners/create',
        element: <CreateLearner />,
        hidden: true,
      },
      {
        name: '',
        link: '/center-admin/user-management/learners/:id',
        element: <EditLearner />,
        hidden: true,
      },
      {
        name: 'admin.sidebar.instructors',
        link: '/center-admin/user-management/instructors',
        element: (
          <UserManagement
            tableType='Instructors'
            type={UserRole.AdminManageTutor}
          />
        ),
      },
      {
        name: '',
        link: '/center-admin/user-management/instructors/create',
        element: <CreateInstructor />,
        hidden: true,
      },
      {
        name: '',
        link: '/center-admin/user-management/instructors/:id',
        element: <EditInstructor />,
        hidden: true,
      },
      {
        name: 'admin.sidebar.centre_admin',
        link: '/center-admin/user-management/centre-admin',
        element: (
          <UserManagement
            tableType='Centre Admin'
            type={UserRole.CenterAdmin}
          />
        ),
      },
      {
        name: '',
        link: '/center-admin/user-management/centre-admin/view/:id',
        element: <ViewCenterAdmin />,
        hidden: true,
      },
      {
        name: '',
        link: '/center-admin/user-management/centre-admin/edit/:id',
        element: <EditCentreAdmin />,
        hidden: true,
      },
    ],
  },
  {
    name: 'admin.sidebar.session_management',
    link: '/center-admin/session-management',
    children: [
      {
        name: 'Data Table',
        link: '/center-admin/session-management/data',
        element: <SessionManagement />,
        hidden: true,
      },
      {
        name: 'admin.sidebar.create_class',
        link: '/center-admin/session-management/create',
        element: <CreateSessionLanding />,
      },
      {
        name: '',
        link: '/center-admin/session-management/create/class',
        element: <CreateClass />,
        hidden: true,
      },
      {
        name: '',
        link: '/center-admin/session-management/create/content',
        element: <CreateContent />,
        hidden: true,
      },
      {
        name: '',
        link: '/center-admin/session-management/create/lesson',
        element: <CreateLesson />,
        hidden: true,
      },
      {
        name: '',
        link: '/center-admin/session-management/edit/class/:id',
        element: <CenterEditClass />,
        hidden: true,
      },
      {
        name: '',
        link: '/center-admin/session-management/edit/content/:id',
        element: <CenterEditContent />,
        hidden: true,
      },
      {
        name: '',
        link: '/center-admin/session-management/preview/class/:id',
        element: <CenterPreviewClass />,
        hidden: true,
      },
      {
        name: '',
        link: '/center-admin/session-management/preview/content/:id',
        element: <CenterPreviewContent />,
        hidden: true,
      },
      {
        name: '',
        link: '/center-admin/session-management/preview/lesson/:id',
        element: <CenterPreviewLesson />,
        hidden: true,
      },
    ],
  },
  {
    name: 'admin.sidebar.wallet_management',
    link: '/center-admin/wallet-management',
    children: [
      {
        name: 'admin.sidebar.wallet_management',
        link: '/center-admin/wallet-management/manage',
        element: <Wallet />,
        hidden: true,
      },
      {
        name: 'Transaction Detail',
        link: '/center-admin/wallet-management/view/:id',
        element: <ViewMyTransaction />,
        hidden: true,
      },
    ],
  },
  {
    name: 'admin.sidebar.transaction_approval_management',
    link: '/center-admin/transaction-approval-management',
    children: [
      {
        name: 'admin.sidebar.transaction_approval_management',
        link: '/center-admin/transaction-approval-management/manage',
        element: <TransactionApproval />,
        hidden: true,
      },
    ],
  },
  {
    name: 'admin.sidebar.my_notifications',
    link: '/center-admin/all-notifications',
    children: [
      {
        name: '',
        link: '/center-admin/all-notifications/list',
        element: <Notifications />,
        hidden: true,
      },
      {
        name: '',
        link: '/center-admin/all-notifications/:id',
        element: <Notifications />,
        hidden: true,
      },
    ],
  },
]

export interface FilterForm {
  status?: string
}

export const searchOptions = [
  { value: 'all', label: 'All' },
  { value: 'transactionApprovalId', label: 'ID' },
  { value: 'description', label: 'Description' },
  { value: 'status', label: 'Status' },
  { value: 'reason', label: 'Reason' },
]

export const sortOptions = [
  { value: 'createdAt', label: 'Date' },
  { value: 'updatedAt', label: 'Last Update' },
  { value: 'status', label: 'Status' },
]

export const statusOptions = [
  {
    value: 'pending',
    label: 'transaction_approval.status.pending',
  },
  {
    value: 'approved',
    label: 'transaction_approval.status.approved',
  },
  {
    value: 'rejected',
    label: 'transaction_approval.status.rejected',
  },
]

export const freezeOptions = [
  {
    value: 'true',
    label: 'cash_flow.freeze_type.frozen',
  },
  {
    value: 'false',
    label: 'cash_flow.freeze_type.no_freeze',
  },
]

import { ChangeEvent, useCallback, useEffect, useState } from 'react'

import defaultAvatar from 'assets/images/default_avatar.svg'

export default (defaultSrc?: string) => {
  const [avatar, setAvatar] = useState<File>()
  const [preview, setPreview] = useState(defaultSrc)
  const [fileType, setFileType] = useState('')

  useEffect(() => {
    setPreview(defaultSrc || defaultAvatar)
  }, [defaultSrc])

  const handleUpdateAvatar = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0]
    if (file) {
      setAvatar(file)
      setPreview(URL.createObjectURL(file))
      const fileTypes = file.type.split('/')
      const extension = fileTypes[fileTypes.length - 1]
      setFileType(extension)
    }
  }, [])

  return {
    avatar,
    preview,
    fileType,
    handleUpdateAvatar,
  }
}

import React, { useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router'

import EditAdminProfileContainer from '../../containers/EditAdminProfile'
import { EditMstAdminForm } from '../../containers/forms'
import { updateProfile } from 'apis/profile'
import { useLanguages, useUserId } from 'hooks'
import { useUpdateUser, useUserDetail } from 'queries/user'
import { UserRole } from 'types'

interface EditKnovoAdminContainerProps {
  handleToBack: () => void
  selfUpdate?: boolean
}

const EditKnovoAdminContainer = ({
  handleToBack,
  selfUpdate = false,
}: EditKnovoAdminContainerProps) => {
  const { _t } = useLanguages()
  const { id } = useParams()
  const personalId = useUserId()
  const userId = selfUpdate ? personalId : id
  const { data: userProfile, isLoading } = useUserDetail(userId)
  const { mutate: updateUser } = useUpdateUser(userId)

  const userDetail = useMemo(() => userProfile?.data || undefined, [
    userProfile,
  ])

  const {
    control,
    formState: { errors: formErrors },
    handleSubmit,
    reset,
  } = useForm<EditMstAdminForm>({
    defaultValues: {
      ...({} as EditMstAdminForm),
      accountStatus: userDetail?.accountStatus || false,
    },
  })

  // set init data
  useEffect(() => {
    if (userProfile?.data) {
      const initData = {
        firstName: userDetail?.firstName || '',
        lastName: userDetail?.lastName || '',
        email: userDetail?.email || '',
        recoveryEmail: userDetail?.recoveryEmail || '',
        address1: userDetail?.address1 || '',
        address2: userDetail?.address2 || '',
        country: userDetail?.country || '',
        contactNumber: userDetail?.contactNumber || '',
        city: userDetail?.city || '',
        zipCode: userDetail?.zipCode || '',
        birthday: userDetail?.birthday
          ? new Date(userDetail.birthday)
          : undefined,
      }

      reset(initData)
    }
  }, [reset, userDetail, userProfile])

  const handleSave = async (values: EditMstAdminForm) => {
    const formattedDataUser = {
      ...values,
      roleName: UserRole.MSTAdmin,
      birthday: values.birthday
        ? new Date(values.birthday).getTime()
        : undefined,
    }

    if (!selfUpdate) {
      updateUser({
        userId,
        data: formattedDataUser,
      })
    } else {
      await updateProfile(userId, formattedDataUser)
      window.location.reload()
    }
  }

  return (
    <EditAdminProfileContainer
      toBackLabel={_t('user_management.knovo_admin.title')}
      title={_t('user_management.knovo_admin.edit_title')}
      handleToBack={handleToBack}
      handleSubmit={handleSubmit}
      handleSubmitCall={handleSave}
      isLoading={isLoading}
      userDetail={userDetail}
      control={control}
      formErrors={formErrors}
      isEdit
      showStatus
    />
  )
}

export default EditKnovoAdminContainer

import React, { ChangeEvent, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'

import { Box, BoxProps } from '@chakra-ui/react'

import {
  ActionButton,
  AttachmentSection,
  DetailSection,
  HeaderSection,
  InvitationSection,
} from '../containers'
import { CreateClassForm } from '../containers/form'
import SessionCreate from './SessionCreate'
import { createUploadBannerClass, uploadFileClass } from 'apis/class'
import { ContentContainer, ContentContainerCollapse } from 'components'
import { useLanguages, useToast, useUploadClassFile } from 'hooks'
import { ClassDetailItem, ClassType, Learner, UserRole } from 'types'
import { checkValidFilesName, isFileSizeOver } from 'utils/helper'
import { parseErrorToastMsg } from 'utils/parser'

export interface CreateClassDataType {
  data: CreateClassForm
  bannerFile?: File
  invitedLearners: Learner[]
  confirmedLearners: Learner[]
  classSessions: ClassDetailItem[]
}

interface ClassCreateProps {
  role: string
  handleClose: () => void
  handleCreateClass: (
    creatingStatus: 'public' | 'draft'
  ) => (createData: CreateClassDataType) => void
  createdData: any
  onCreatedSuccess: (
    className: string,
    type: string,
    classNumber: number
  ) => void
  containerProps?: BoxProps
}

const ClassCreate: React.FC<ClassCreateProps> = ({
  role,
  handleClose,
  handleCreateClass,
  createdData,
  onCreatedSuccess,
  containerProps,
}) => {
  const { _t } = useLanguages()
  const toast = useToast()

  const [bannerFile, setBannerFile] = useState<File | undefined>(undefined)
  const [invitedLearners, setInvitedLearners] = useState<Learner[]>([])
  const [confirmedLearners, setConfirmedLearners] = useState<Learner[]>([])
  const [allowUpload, setAllowUpload] = useState(true)

  const [isCreateClasses, setCreateClasses] = useState(false)
  const [savingData, setSavingData] = useState<CreateClassForm | undefined>(
    undefined
  )
  const [confirmedLearnersError, setConfirmedLearnersError] = useState('')

  const previewBanner = useMemo(
    () => (bannerFile ? URL.createObjectURL(bannerFile) : ''),
    [bannerFile]
  )

  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    errors,
    setError,
    clearErrors,
    watch,
  } = useForm<CreateClassForm>({
    mode: 'onChange',
    defaultValues: {
      name: '',
      description: '',
      price: 0,
      enterprisePrice: 0,
      usualPrice: undefined,
      usualEnterprisePrice: undefined,
      tutor: { value: '', label: '' },
      centre: { value: '', label: '' },
      teachingLevel: { value: '', label: '' },
      subject: { value: '', label: '' },
      language: { value: '', label: '' },
      minStudent: 0,
      maxStudent: 0,
      requireMinimum: false,
      type: ClassType.PUBLIC_PACKAGE,
      tutorType:
        role === UserRole.Tutor ? UserRole.Tutor : UserRole.EnterpriseTutor,
    },
  })

  const {
    uploadFiles,
    isUploading,
    isSettled,
    filesError,
    handleChangeFiles,
    handleUpload,
  } = useUploadClassFile()

  const classId = useMemo(() => createdData?.data.data.id || '', [createdData])

  // set successful creation
  useEffect(() => {
    if (createdData?.data && isSettled) {
      onCreatedSuccess(
        createdData.data.data.name,
        createdData.data.data.type,
        createdData?.data.data.sessions.length
      )
    }
  }, [createdData, onCreatedSuccess, isSettled, filesError])

  useEffect(() => {
    if (classId && allowUpload) {
      //  upload banner
      const handleUploadFile = async (
        id: string,
        banner: File,
        bodyGetUrl: any
      ) => {
        try {
          const res = await createUploadBannerClass(id, bodyGetUrl)
          const formData = new FormData()
          const { fields, url } = res.data.data
          Object.entries(fields).forEach(([key, value]) => {
            formData.append(key, value)
          }, [])
          formData.append('file', banner)
          const resUpload = await uploadFileClass(url, formData)
          if (resUpload) {
            return 'success'
          }
        } catch (err) {
          console.log(err)
        }
      }

      if (bannerFile) {
        const bodyGetUrl = {
          fileName: bannerFile.name,
          contentType: bannerFile.type,
        }
        handleUploadFile(classId, bannerFile, bodyGetUrl)
      }
      handleUpload(classId)
    }
  }, [allowUpload, handleUpload, classId, bannerFile])

  useEffect(() => {
    if (isSettled) {
      setAllowUpload(false)
    }
  }, [handleClose, isSettled])

  //---- add, delete learner (invited & confirmed)
  const handleAddInvitedLearner = (data: Learner[]) => {
    setInvitedLearners(data)
  }
  const handleDeleteInvitedLearner = (learnerId: string) => {
    const newData = invitedLearners.filter(item => item.id !== learnerId)
    setInvitedLearners(newData)
  }
  const handleAddConfirmedLearner = (data: Learner[]) => {
    setConfirmedLearners(data)
  }
  const handleDeleteConfirmedLearner = (learnerId: string) => {
    const newData = confirmedLearners.filter(item => item.id !== learnerId)
    setConfirmedLearners(newData)
  }

  const handleUpdateBanner = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0]
    const maxSize = 10485760 // 10mb in byte

    if (file && isFileSizeOver(file.size, maxSize)) {
      return toast(
        parseErrorToastMsg(_t('product.class.validate_banner_invalid_size'))
      )
    }

    return setBannerFile(file)
  }

  const handleCreate = (creatingStatus: 'public' | 'draft') => (
    value: CreateClassForm
  ) => {
    const createData = {
      data: value,
      bannerFile: bannerFile,
      invitedLearners: invitedLearners,
      confirmedLearners: confirmedLearners,
      classSessions: [],
    }

    handleCreateClass(creatingStatus)(createData)
  }

  const handleNext = (values: CreateClassForm) => {
    if (!checkValidFilesName(uploadFiles)) {
      return toast({
        title: 'Error!',
        status: 'error',
        duration: 6000,
        description: _t('message.error.InvalidFileName'),
      })
    }

    const data = values

    if (!validate(data)) return

    setSavingData(data)
    setCreateClasses(true)
  }

  const validate = (value: CreateClassForm) => {
    let isValid = true

    if (value.maxStudent || 0 >= value.minStudent) {
      clearErrors('maxStudent')
    } else {
      setError('maxStudent', {
        message: _t('product.class.validate_max_min_learners_invalid'),
      })
      isValid = false
    }

    if (confirmedLearners.length > (value.maxStudent || 0)) {
      setConfirmedLearnersError(
        _t('product.class.validate_confirmed_learners_invalid')
      )
      isValid = false
    } else {
      setConfirmedLearnersError('')
    }

    return isValid
  }

  useEffect(() => {
    if (!isCreateClasses && savingData) {
      const keys = Object.keys(savingData) as (keyof CreateClassForm)[]
      keys.forEach((key: keyof CreateClassForm) => {
        if (key === 'attachments') {
          return ''
        }
        setValue(key, savingData[key])
      })
    }
  }, [isCreateClasses, savingData, setValue])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  if (isCreateClasses) {
    return (
      <SessionCreate
        role={role}
        bannerFile={bannerFile}
        data={savingData}
        invitedLearners={invitedLearners}
        confirmedLearners={confirmedLearners}
        attachments={uploadFiles.map(item => item.file)}
        createdData={createdData}
        goBack={() => setCreateClasses(false)}
        handleSubmit={handleSubmit}
        handleCreateClass={handleCreateClass}
        handleCancel={handleClose}
      />
    )
  }

  return (
    <>
      <Box as='section' pt='1.5rem' pb='3rem' {...containerProps}>
        <Box as='h1' mb='2rem' className={'blue-purple-gradient-text'}>
          {_t('product.class.create_title')}
        </Box>

        <ContentContainer title={_t('product.class.title')}>
          <HeaderSection
            control={control}
            errors={errors}
            bannerFile={bannerFile}
            previewBanner={previewBanner}
            handleUpdateBanner={handleUpdateBanner}
          />
        </ContentContainer>

        <ContentContainerCollapse
          title={_t('product.class.details_title')}
          mt='2rem'
        >
          <DetailSection
            role={role}
            watch={watch}
            control={control}
            setValue={setValue}
            getValues={getValues}
            setError={setError}
            errors={errors}
          />
        </ContentContainerCollapse>

        <ContentContainerCollapse
          title={_t('product.class.materials_title')}
          mt='2rem'
        >
          <AttachmentSection
            watch={watch}
            control={control}
            errors={errors}
            uploadFiles={uploadFiles}
            isUploading={isUploading}
            handleChangeFiles={handleChangeFiles}
          />
        </ContentContainerCollapse>

        <ContentContainerCollapse
          title={_t('product.class.invitations_title')}
          mt='2rem'
        >
          <InvitationSection
            watch={watch}
            role={role}
            invitedLearners={invitedLearners}
            confirmedLearners={confirmedLearners}
            handleAddInvitedLearner={handleAddInvitedLearner}
            handleDeleteInvitedLearner={handleDeleteInvitedLearner}
            handleAddConfirmedLearner={handleAddConfirmedLearner}
            handleDeleteConfirmedLearner={handleDeleteConfirmedLearner}
            confirmedLearnersError={confirmedLearnersError}
          />
        </ContentContainerCollapse>

        <ActionButton
          isHidePublish
          handleSubmit={handleSubmit}
          handleCreate={handleCreate}
          handleNext={handleNext}
          handleClose={handleClose}
        />
      </Box>
    </>
  )
}

export default ClassCreate

import React from 'react'

import { Box, Flex, SystemProps } from '@chakra-ui/react'

import { useLanguages } from 'hooks'

import desktopBg from 'assets/images/desktop_auth_bg.png'
import { ReactComponent as Logo } from 'assets/images/logo_large.svg'
import { ReactComponent as Steps } from 'assets/images/steps.svg'

interface AuthIntroProps extends SystemProps {}

const AuthIntro: React.FC<AuthIntroProps> = ({ ...boxProps }) => {
  const { _t } = useLanguages()

  return (
    <Box
      as='section'
      background={{ base: 'none', lg: `url(${desktopBg}) no-repeat center` }}
      backgroundColor={{ base: '', lg: 'secondaryLightBlue' }}
      backgroundSize={{ base: 'none', lg: 'cover' }}
      backgroundPosition={{ base: 'center', lg: 'center' }}
      {...boxProps}
    >
      <Box position='relative' pt={{ base: '1rem' }}>
        <Flex direction='column' alignItems='center'>
          <Logo />
        </Flex>
        <Flex
          direction='column'
          alignItems='center'
          display={{ base: 'none', lg: 'block' }}
        >
          <Box as='h1' textAlign='center' position='relative'>
            {_t('auth.description')}
          </Box>
          <Box mt='1rem'>
            <Steps width='100%' />
          </Box>
        </Flex>
      </Box>
    </Box>
  )
}

export default AuthIntro

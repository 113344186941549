import { AxiosResponse } from 'axios'

import { useLanguages, useToast } from 'hooks'
import { getErrorMessage } from 'utils/parser'

export default () => {
  const toast = useToast()
  const { _t } = useLanguages()
  return (error: AxiosResponse) => {
    toast({
      title: 'Error!',
      description: _t(`message.error.${getErrorMessage(error)}`),
      status: 'error',
    })
  }
}

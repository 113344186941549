import React, { useCallback, useMemo } from 'react'
import { Column, Row } from 'react-table'

import {
  Badge,
  Box,
  Flex,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Switch,
  Text,
} from '@chakra-ui/react'
import format from 'date-fns/format'

import {
  ActionDot,
  CashFlowTypeText,
  ConfirmAlert,
  Link,
  PopoverItem,
  Table,
} from 'components'
import { useLanguages } from 'hooks'
import {
  CashFlowInfo,
  CashFlowStatus,
  Centre,
  Enterprise,
  PaymentType,
  UserProfile,
} from 'types'
import { formatAmount, getFullName } from 'utils/parser'

import { ReactComponent as PlayIcon } from 'assets/images/play_icon.svg'
import { ReactComponent as ReverseIcon } from 'assets/images/reverse_icon.svg'

interface CashFlowDataTableProps {
  statusTab: CashFlowStatus
  isLoading: boolean
  colorScheme?: string
  data: CashFlowInfo[]
  handleView?: (id: string, tab: string) => string
  handleSelect?: (values: CashFlowInfo[]) => void
  handleFreeze?: (id: string, freeze: boolean) => void
  handleExecute?: (
    id: string,
    status: CashFlowStatus,
    withdrawalType?: PaymentType,
    remarks?: string
  ) => void
}

const CashFlowDataTable: React.FC<CashFlowDataTableProps> = ({
  statusTab,
  isLoading,
  colorScheme,
  data,
  handleView,
  handleSelect,
  handleFreeze,
  handleExecute,
}) => {
  const { _t } = useLanguages()

  const getStatusName = useCallback(
    (status: CashFlowStatus) => {
      const names = {
        [CashFlowStatus.INITIATE]: _t('cash_flow.status.initiate'),
        [CashFlowStatus.ONHOLD]: _t('cash_flow.status.on_hold'),
        [CashFlowStatus.UNRELEASED]: _t('cash_flow.status.unreleased'),
        [CashFlowStatus.RELEASED]: _t('cash_flow.status.released'),
        [CashFlowStatus.CANCELLED]: _t('cash_flow.status.cancelled'),
      }
      return names[status]
    },
    [_t]
  )

  const renderExecuteStatus = useCallback(
    (
      { detail, status }: { detail: CashFlowInfo; status: CashFlowStatus },
      isReverse?: boolean
    ) => (
      <ConfirmAlert
        onSubmit={() => handleExecute && handleExecute(detail.id, status)}
        title={_t('cash_flow.management.update_title')}
        description={
          <Box textAlign='left'>
            <Text textAlign='center'>
              {isReverse
                ? _t('cash_flow.management.reverse_description', {
                    status: getStatusName(status),
                  })
                : _t('cash_flow.management.update_description', {
                    status: getStatusName(status),
                  })}
            </Text>
            <Flex
              direction='column'
              p='1rem'
              bg='#F2FBFF'
              rounded='lg'
              my='1rem'
            >
              <Box mb='0.5rem'>
                <Text
                  fontSize='0.75rem'
                  color='gray.300'
                  textTransform='uppercase'
                >
                  {_t('cash_flow.management.cash_flow_id')}
                </Text>
                <Text noOfLines={1}>{detail.id}</Text>
              </Box>
              <Box>
                <Text
                  fontSize='0.75rem'
                  color='gray.300'
                  textTransform='uppercase'
                >
                  {_t('cash_flow.management.name')}
                </Text>
                <Text noOfLines={1}>{detail.name || '-'}</Text>
              </Box>
            </Flex>
          </Box>
        }
        submitTitle={_t('common.confirm')}
      >
        {({ showAlert }) => (
          <PopoverItem onClick={showAlert}>
            {isReverse ? <ReverseIcon /> : <PlayIcon width={17} />}
            <Box ml='1rem'>
              {isReverse ? _t('common.reverse') : _t('common.execute')}
            </Box>
          </PopoverItem>
        )}
      </ConfirmAlert>
    ),
    [_t, getStatusName, handleExecute]
  )

  const renderName = useCallback(
    (user: UserProfile, center: Centre, enterprise: Enterprise) => {
      if (user) {
        return <Text noOfLines={1}>{getFullName(user)}</Text>
      }
      return (
        <Flex align='center'>
          {center && (
            <Text mr='0.5rem' noOfLines={1}>
              {center?.name || ''}
            </Text>
          )}
          <Badge variant='solid' colorScheme='blue' noOfLines={1}>
            {enterprise?.name || ''}
          </Badge>
        </Flex>
      )
    },
    []
  )

  const isActionStatus = useMemo(
    () =>
      [CashFlowStatus.UNRELEASED, CashFlowStatus.RELEASED].includes(statusTab),
    [statusTab]
  )

  const columns = useMemo<Column<CashFlowInfo>[]>(
    () => [
      {
        Header: _t('cash_flow.management.id'),
        id: 'id',
        Cell: ({ row }: { row: Row<CashFlowInfo> }) => {
          const link = handleView ? handleView(row.original.id, statusTab) : '#'
          return (
            <Link to={link} color='primary'>
              <Text fontWeight={600} noOfLines={1}>
                {row.original.id}
              </Text>
            </Link>
          )
        },
        width: '260',
        sticky: 'left',
      },
      {
        Header: _t('cash_flow.management.product_name'),
        id: 'productName',
        Cell: ({ row }: { row: Row<CashFlowInfo> }) => (
          <Text noOfLines={1}>{row.original.orderItem?.description}</Text>
        ),
        width: '260',
        sticky: 'left',
      },
      {
        Header: _t('cash_flow.management.name'),
        id: 'name',
        Cell: ({ row }: { row: Row<CashFlowInfo> }) => (
          <Text noOfLines={1}>{row.original.name}</Text>
        ),
        width: '260',
      },
      {
        Header: _t('cash_flow.management.date_and_time'),
        id: 'createdAt',
        Cell: ({ row }: { row: Row<CashFlowInfo> }) => (
          <Text noOfLines={1}>
            {format(new Date(row.original.createdAt), 'dd MMM yyyy, h:mm a')}
          </Text>
        ),
        width: '200',
      },
      {
        Header: _t('cash_flow.management.type'),
        id: 'type',
        Cell: ({ row }: { row: Row<CashFlowInfo> }) => (
          <CashFlowTypeText type={row.original.type} />
        ),
        width: '160',
      },
      {
        Header: _t('cash_flow.management.amount'),
        id: 'amount',
        Cell: ({ row }: { row: Row<CashFlowInfo> }) => (
          <Text noOfLines={1}>
            ${formatAmount((row.original.amount || 0) / 100)}
          </Text>
        ),
        width: '160',
      },
      {
        Header: _t('cash_flow.management.expense_amount'),
        id: 'expenseAmount',
        Cell: ({ row }: { row: Row<CashFlowInfo> }) => (
          <Text noOfLines={1}>
            ${formatAmount((row.original.expenseAmount || 0) / 100)}
          </Text>
        ),
        width: '160',
      },
      {
        Header: _t('cash_flow.management.freeze'),
        id: 'freeze',
        Cell: ({ row }: { row: Row<CashFlowInfo> }) => (
          <Text noOfLines={1}>
            <ConfirmAlert
              onSubmit={() => {
                handleFreeze &&
                  handleFreeze(row.original.id, !row.original.freeze)
              }}
              title={
                row.original.freeze
                  ? _t('cash_flow.management.unfreeze_title')
                  : _t('cash_flow.management.freeze_title')
              }
              description={
                <Box textAlign='center'>
                  <Text mb='1rem'>
                    {row.original.freeze
                      ? _t('cash_flow.management.unfreeze_description')
                      : _t('cash_flow.management.freeze_description')}
                  </Text>
                  <Text fontSize='0.75rem' color='gray.300'>
                    {_t('cash_flow.management.freeze_note')}
                  </Text>
                </Box>
              }
              submitTitle={_t('common.confirm')}
            >
              {({ showAlert }) => (
                <Switch
                  p='0.2rem'
                  size='sm'
                  isChecked={row.original.freeze}
                  onChange={showAlert}
                />
              )}
            </ConfirmAlert>
          </Text>
        ),
        width: '160',
      },
      {
        Header: _t('cash_flow.management.sender_name'),
        id: 'sender',
        Cell: ({ row }: { row: Row<CashFlowInfo> }) =>
          renderName(
            row.original.sender,
            row.original.centerSender,
            row.original.enterpriseSender
          ),
        width: '260',
      },
      {
        Header: _t('cash_flow.management.sender_email'),
        id: 'senderEmail',
        Cell: ({ row }: { row: Row<CashFlowInfo> }) => (
          <Text noOfLines={1}>
            {row.original.sender ? (
              row.original.sender?.email || ''
            ) : (
              <Flex align='center'>
                {row.original.centerSender && (
                  <Text mr='0.5rem' noOfLines={1}>
                    {row.original.centerSender?.email || ''}
                  </Text>
                )}
                <Badge variant='solid' colorScheme='blue' noOfLines={1}>
                  {row.original.enterpriseSender?.email || ''}
                </Badge>
              </Flex>
            )}
          </Text>
        ),
        width: '260',
      },
      {
        Header: _t('cash_flow.management.recipient_name'),
        id: 'recipient',
        Cell: ({ row }: { row: Row<CashFlowInfo> }) =>
          renderName(
            row.original.recipient,
            row.original.centerRecipient,
            row.original.enterpriseRecipient
          ),
        width: '260',
      },
      {
        Header: _t('cash_flow.management.recipient_email'),
        id: 'recipientEmail',
        Cell: ({ row }: { row: Row<CashFlowInfo> }) => (
          <Text noOfLines={1}>
            {row.original.recipient ? (
              row.original.recipient?.email || ''
            ) : (
              <Flex align='center'>
                {row.original.centerRecipient && (
                  <Text mr='0.5rem' noOfLines={1}>
                    {row.original.centerRecipient?.email || ''}
                  </Text>
                )}
                <Badge variant='solid' colorScheme='blue' noOfLines={1}>
                  {row.original.enterpriseRecipient?.email || ''}
                </Badge>
              </Flex>
            )}
          </Text>
        ),
        width: '260',
      },
      {
        Header: _t('cash_flow.management.last_update'),
        id: 'updatedAt',
        Cell: ({ row }: { row: Row<CashFlowInfo> }) => (
          <Text noOfLines={1}>
            {format(new Date(row.original.updatedAt), 'dd MMM yyyy, h:mm a')}
          </Text>
        ),
        width: '200',
      },
      ...(isActionStatus
        ? [
            {
              Header: _t('cash_flow.management.action'),
              Cell: ({ row }: { row: Row<CashFlowInfo> }) => (
                <Popover>
                  <PopoverTrigger>
                    <Box>
                      <ActionDot />
                    </Box>
                  </PopoverTrigger>
                  <Portal>
                    <PopoverContent
                      w='16rem'
                      bgColor='primaryDarker'
                      color='#fff'
                      _focus={{
                        outline: 0,
                      }}
                    >
                      <PopoverBody p='0'>
                        {statusTab === CashFlowStatus.RELEASED &&
                          renderExecuteStatus(
                            {
                              detail: row.original,
                              status: CashFlowStatus.UNRELEASED,
                            },
                            true
                          )}
                        {statusTab === CashFlowStatus.UNRELEASED &&
                          renderExecuteStatus({
                            detail: row.original,
                            status: CashFlowStatus.RELEASED,
                          })}
                      </PopoverBody>
                    </PopoverContent>
                  </Portal>
                </Popover>
              ),
              width: '100',
            },
          ]
        : []),
    ],
    [
      _t,
      handleFreeze,
      handleView,
      isActionStatus,
      renderExecuteStatus,
      renderName,
      statusTab,
    ]
  )

  return (
    <Table<CashFlowInfo>
      columns={columns}
      data={data}
      loading={isLoading}
      colorScheme={colorScheme}
      onSelectRows={handleSelect}
    />
  )
}

export default CashFlowDataTable

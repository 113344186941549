import Axios, { AxiosRequestConfig } from 'axios'

const defaultFetcher = async <T>(
  url: string,
  config: AxiosRequestConfig = {}
) => {
  const { data } = await Axios.get<T>(url, config)

  return data
}

export { defaultFetcher }

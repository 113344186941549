import React, { useCallback, useMemo } from 'react'

import { Box, Flex, Image, Text, useDisclosure } from '@chakra-ui/react'

import AddCardModal from './AddCardModal'
import { ConfirmAlert } from 'components'
import {
  useCenterId,
  useLanguages,
  useUserId,
  useUtilsForEnterpriseId,
} from 'hooks'
import { useDeleteUserCard } from 'queries/wallet'
import { ApiUserType, UserRole, UserRoleType, UserWalletInfo } from 'types'

import { ReactComponent as TrashIcon } from 'assets/images/color_trash_icon.svg'
import master from 'assets/images/mastercard.svg'
import { ReactComponent as PlusIcon } from 'assets/images/plus_icon.svg'
import visa from 'assets/images/visacard.svg'

interface MyCardProps {
  role: UserRoleType
  walletInfo?: UserWalletInfo
}

const getCardLogo = (brand: string) => {
  switch (brand) {
    case 'visa':
      return visa
    case 'mastercard':
      return master
    default:
      return ''
  }
}

const MyCard: React.FC<MyCardProps> = ({ role, walletInfo }) => {
  const { _t } = useLanguages()
  const userId = useUserId()
  const centerId = useCenterId()
  const { enterpriseId } = useUtilsForEnterpriseId()
  const {
    isOpen: isAddCardOpen,
    onClose: onAddCardClose,
    onOpen: onAddCardOpen,
  } = useDisclosure()

  const type = useMemo(() => {
    switch (role) {
      case UserRole.MSTAdmin:
      case UserRole.MSTSuperAdmin:
        return { user: 'admin', id: userId }
      case UserRole.EnterpriseAdmin:
      case UserRole.EnterpriseSuperAdmin:
        return { user: 'enterprise', id: enterpriseId }
      case UserRole.CenterAdmin:
        return { user: 'center', id: centerId }
      default:
        return { user: 'user', id: userId }
    }
  }, [role, userId, enterpriseId, centerId])

  const legacyType = useMemo(() => {
    switch (role) {
      case UserRole.EnterpriseAdmin:
      case UserRole.EnterpriseSuperAdmin:
        return 'enterprise'
      case UserRole.Tutor:
        return 'privateTutor'
      default:
        return 'student'
    }
  }, [role])

  const { mutate: deleteCard } = useDeleteUserCard({
    type: type.user as ApiUserType,
    id: type.id,
  })

  const handleDeleteUserCard = useCallback(() => {
    deleteCard({
      enterpriseId: [
        UserRole.EnterpriseAdmin,
        UserRole.EnterpriseSuperAdmin,
      ].includes(role)
        ? enterpriseId
        : '',
      type: legacyType,
    })
  }, [deleteCard, enterpriseId, legacyType, role])

  return (
    <>
      <Box
        flex='1'
        position='relative'
        bg='white'
        py='1.5rem'
        px='2rem'
        mx={{ base: '0', md: '0.5rem' }}
        my='0.5rem'
        minW={{ base: '100%', md: '20rem' }}
        boxShadow='lg'
        rounded='lg'
        overflow='hidden'
      >
        <Box maxW='16rem'>
          <Flex alignItems='center' justifyContent='space-between' mb='1rem'>
            <Text fontWeight='600'>{_t('wallet.card.title')}</Text>
            {walletInfo?.card?.last4 && (
              <ConfirmAlert
                title={_t('wallet.card.remove_title')}
                description={
                  <Text textAlign='center'>
                    {_t('wallet.card.remove_description')}
                  </Text>
                }
                submitTitle={_t('wallet.card.remove')}
                immediatelyCloseOnSubmit
                onSubmit={handleDeleteUserCard}
              >
                {({ showAlert }) => (
                  <TrashIcon cursor='pointer' onClick={showAlert} />
                )}
              </ConfirmAlert>
            )}
          </Flex>

          {walletInfo?.card?.last4 ? (
            <Flex
              flexDir='column'
              justifyContent='space-between'
              alignItems='baseline'
              w='100%'
              h='10.5rem'
              bgColor='rgba(57, 88, 191, 0.12)'
              rounded='lg'
              p='2rem 1.5rem'
            >
              <Image src={getCardLogo(walletInfo.card.brand)} maxW='4rem' />
              <Text textStyle='assignUserName'>{walletInfo.card.name}</Text>
              <Box as='h2'>{`**** **** **** ${walletInfo.card.last4}`}</Box>
            </Flex>
          ) : (
            <Flex
              h='10.5rem'
              border='1px solid'
              borderColor='primary'
              rounded='lg'
              alignItems='center'
              justifyContent='center'
              cursor='pointer'
              onClick={onAddCardOpen}
            >
              <Flex
                w='4rem'
                h='4rem'
                borderRadius='50%'
                bgColor='#F3F6FE'
                alignItems='center'
                justifyContent='center'
              >
                <PlusIcon />
              </Flex>
            </Flex>
          )}
        </Box>
      </Box>
      {isAddCardOpen && (
        <AddCardModal
          type={legacyType}
          isOpen={isAddCardOpen}
          onClose={onAddCardClose}
        />
      )}
    </>
  )
}

export default MyCard

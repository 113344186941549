import React from 'react'

import { Box } from '@chakra-ui/react'

import { useUserId } from 'hooks'
import { SubscriptionContainer } from 'shared/containers'

export interface SubscriptionTabProps {}

const SubscriptionTab: React.FC<SubscriptionTabProps> = () => {
  const userId = useUserId()

  return (
    <Box>
      <SubscriptionContainer type='privateTutor' id={userId} />
    </Box>
  )
}

export default SubscriptionTab

import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { useDisclosure } from '@chakra-ui/react'

import ContentCreate, {
  CreateContentDataType,
} from '../../Product/Content/Create'
import { CreateClassSuccessModal } from 'components'
import { useLoading, useUserId } from 'hooks'
import { useAddClass } from 'queries/class'
import { CreateClassType, UserRole, UserRoleType } from 'types'
import { parseClassType } from 'utils/parser'

interface CreateContentContainerProps {
  role: UserRoleType
  handleToDashboard: () => void
  handleBackToCreate: () => void
}

const CreateContentContainer = ({
  role,
  handleToDashboard,
  handleBackToCreate,
}: CreateContentContainerProps) => {
  const { onClose, isOpen, onOpen } = useDisclosure()
  const [createdContent, setCreatedContent] = useState<{
    name: string
    type: string
  }>()
  const [isDraft, setIsDraft] = useState<boolean>(false)
  const { setLoading } = useLoading()
  const userId = useUserId()

  const roleIsTutor = useMemo(
    () => role === UserRole.Tutor || role === UserRole.EnterpriseTutor,
    [role]
  )

  const { mutate: addContent, data: contentData, isSuccess } = useAddClass()

  const handleCreateContent = useCallback(
    (creatingStatus: 'public' | 'draft') => (value: CreateContentDataType) => {
      const {
        name,
        description,
        price,
        enterprisePrice,
        usualPrice,
        usualEnterprisePrice,
        language,
        centre,
        tutor,
        subject,
        teachingLevel,
        requireMinimum,
        type,
        tutorType,
        materials,
      } = value.data
      setIsDraft(creatingStatus === 'draft')

      const formattedInvitedStudents = value.invitedLearners.map(item => ({
        opt: 'add',
        id: item.id,
      }))
      const formattedConfirmedStudents = value.confirmedLearners.map(item => ({
        opt: 'add',
        id: item.id,
      }))
      const formattedMaterials = materials?.map(item => ({
        opt: 'add',
        ...item,
      }))

      const formattedData: CreateClassType = {
        name: name,
        description: description,
        price: price * 1,
        enterprisePrice: (enterprisePrice || 0) * 1,
        usualPrice: usualPrice ? usualPrice * 1 : undefined,
        usualEnterprisePrice: usualEnterprisePrice
          ? usualEnterprisePrice * 1
          : undefined,
        languageId: language.value,
        centerId: centre?.value || '',
        tutorId: roleIsTutor ? userId : tutor.value,
        subjectId: subject.value,
        teachingLevelId: teachingLevel.value,
        requireMinimum: requireMinimum,
        invitedStudents: formattedInvitedStudents,
        confirmedStudents: formattedConfirmedStudents,
        type: type,
        status: creatingStatus,
        materials: formattedMaterials,
      }

      if (tutorType === UserRole.Tutor) {
        delete formattedData.enterprisePrice
        delete formattedData.centerId
      }

      addContent({ data: formattedData })
    },
    [addContent, roleIsTutor, userId]
  )

  useEffect(() => {
    if (isSuccess) {
      setLoading(true)
    }
  }, [isSuccess, setLoading])

  const handleCreatedSuccessCallback = useCallback(
    (name: string, type: string) => {
      setCreatedContent({ name, type })
      setLoading(false)
      onOpen()
    },
    [onOpen, setLoading]
  )

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <ContentCreate
        role={role}
        handleClose={handleBackToCreate}
        handleCreateProduct={handleCreateContent}
        createdData={contentData}
        onCreatedSuccess={handleCreatedSuccessCallback}
      />

      <CreateClassSuccessModal
        description={
          isDraft
            ? `"${
                createdContent?.name
              }" has been successfully saved in your drafts. Return to Drafts to publish your "${parseClassType(
                createdContent?.type || ''
              )}" anytime.`
            : `"${createdContent?.name}" has been successfully created.`
        }
        isOpen={isOpen}
        onClose={onClose}
        isTutor={roleIsTutor}
        isDraft={isDraft}
        actionBackToCreating={handleBackToCreate}
        actionGoToDashboard={handleToDashboard}
      />
    </>
  )
}

export default CreateContentContainer

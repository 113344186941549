import React from 'react'

import { Tooltip, TooltipProps } from '@chakra-ui/react'

interface CustomTooltipProps extends TooltipProps {
  children: React.ReactChild
  label: string
}

const CustomTooltip = ({ children, label, ...props }: CustomTooltipProps) => {
  return (
    <Tooltip
      shouldWrapChildren
      label={label}
      placement='right-start'
      bg='primary'
      color='#fff'
      w='12rem'
      p='0.5rem'
      {...props}
    >
      {children}
    </Tooltip>
  )
}

export default CustomTooltip

import React from 'react'

import { Box, Flex, Text } from '@chakra-ui/react'

import { Link, LoadingSpinner, Result } from 'components'
import { ResultType } from 'types'

type CardListProps = {
  children: React.ReactNode
  title?: string
  description?: string
  dataList: any[]
  action?: string
  actionUrl?: string
  emptyMessage?: string
  emptySubMessage?: string
  isLoading?: boolean
  wrap?: boolean
  justify?: string
  resultType?: ResultType
}

const CardList: React.FC<CardListProps> = ({
  children,
  title,
  description,
  dataList = [],
  action = '',
  actionUrl = '',
  emptyMessage = 'No selected data. Please check back soon!',
  emptySubMessage = '',
  isLoading = false,
  wrap = false,
  justify = 'flex-start',
  resultType,
}: CardListProps) => {
  const renderAction = () => {
    const show = action !== '' && actionUrl !== ''
    if (show) {
      return (
        <Box as={Link} to={actionUrl} right='0px' bottom='-5px'>
          <Box
            as='span'
            color='primaryDarker'
            fontWeight='600'
            fontSize='0.875rem'
            textTransform='uppercase'
          >
            {action}
          </Box>
        </Box>
      )
    }
    return null
  }

  const renderEmpty = () => {
    if (isLoading) {
      return (
        <Flex
          direction='column'
          alignItems='center'
          justifyContent='center'
          minH='20rem'
        >
          <LoadingSpinner />
        </Flex>
      )
    }
    return dataList.length === 0 ? (
      <Flex
        direction='column'
        alignItems='center'
        justifyContent='center'
        minH='20rem'
      >
        <Result
          title={emptyMessage}
          subTitle={emptySubMessage}
          type={resultType}
        />
      </Flex>
    ) : null
  }

  return (
    <Box as='div' mb='1.875rem' w='100%'>
      <Box mb='1.5rem'>
        <Flex position='relative'>
          {title && (
            <Box as='h2' flex='3'>
              {title}
            </Box>
          )}
          {renderAction()}
        </Flex>
        {description && (
          <Text mt='0.5rem' fontSize='0.875rem' color='gray.300'>
            {description}
          </Text>
        )}
      </Box>

      {renderEmpty()}

      {!isLoading && (
        <Flex
          flexWrap={wrap ? 'wrap' : 'nowrap'}
          justifyContent={justify}
          overflowY='hidden'
          w='100%'
          pb='0.5rem'
        >
          {children}
        </Flex>
      )}
    </Box>
  )
}

export default CardList

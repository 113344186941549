import React, { useMemo } from 'react'

import { Box, Button, Flex, Text } from '@chakra-ui/react'
import { compareAsc } from 'date-fns'

import { useLanguages, useUserId } from 'hooks'
import { ClassPreview, UserProfile } from 'types'
import { isContentClassType, isLessonClassType } from 'utils/helper'
import { formatAmount } from 'utils/parser'

interface StickyActionProps {
  data: ClassPreview | undefined
  pricing: number
  usualPricing?: number
  instructor: UserProfile | undefined
  onJoinSession?: () => void
  onBuySession?: () => void
  isVirtual?: boolean
}

const StickyAction: React.FC<StickyActionProps> = ({
  data,
  pricing,
  usualPricing,
  instructor,
  onJoinSession,
  onBuySession,
  isVirtual = false,
}) => {
  const { _t } = useLanguages()
  const personalId = useUserId()
  const canEdit = personalId === instructor?.id
  const now = new Date()

  const isContentClassMemo = useMemo(() => isContentClassType(data?.type), [
    data,
  ])
  const isLessonClassMemo = useMemo(() => isLessonClassType(data?.type), [data])
  const availableSlot = useMemo(() => {
    const max = data?.maxStudent || 0

    return max - (data?.numOfConfirmedStudents || 0)
  }, [data])

  const isExpired = useMemo(() => {
    if (data?.sessions && data?.sessions.length > 0) {
      for (const session of data.sessions) {
        const isMore30MinsBeforeClass =
          compareAsc(
            now,
            new Date(session?.startDateTime || 0).getTime() - 1800000
          ) === -1
        const isMore30MinsAfterClass =
          compareAsc(
            now,
            new Date(session?.endDateTime || 0).getTime() + 1800000
          ) === 1
        if (!isMore30MinsBeforeClass && !isMore30MinsAfterClass) {
          return false
        }
      }
    }
    return true
  }, [data, now])

  const lessonExpiredMemo = useMemo(() => {
    if (isLessonClassMemo) {
      for (const pack of data!.packages!) {
        if (pack.sessions[0].startDateTime > Date.now()) {
          return false
        }
      }
      return true
    }
  }, [data, isLessonClassMemo])

  const renderPricing = () => {
    return (
      <Flex align='center' mx='2rem' display={{ base: 'none', md: '10rem' }}>
        <Text
          px={2}
          fontSize={{ base: '0.75rem', md: '1rem' }}
          fontWeight='400'
          textDecoration='line-through'
          color='gray.300'
        >
          {!!usualPricing && `$${formatAmount(usualPricing)}`}
        </Text>
        {isLessonClassMemo && (
          <Flex align='end'>
            <Text as='h1' noOfLines={1}>
              {pricing === 0 ? _t('common.free') : `$${formatAmount(pricing)}`}
            </Text>
            <Text noOfLines={1} ml='0.5rem'>
              {`/ package`}
            </Text>
          </Flex>
        )}
        {!isLessonClassMemo && (
          <Text as='h1' noOfLines={1}>
            {pricing === 0 ? _t('common.free') : `$${formatAmount(pricing)}`}
          </Text>
        )}
      </Flex>
    )
  }

  const renderAction = () => {
    if (data?.status === 'cancelled') {
      if (isContentClassMemo) {
        if (canEdit) {
          return (
            <>
              {renderPricing()}
              <Box
                w='full'
                bgColor='gray.100'
                py='0.8rem'
                px='1rem'
                rounded='lg'
                color='#fff'
                textAlign='center'
                fontWeight='600'
                textTransform='uppercase'
              >
                {_t('common.cancelled')}
              </Box>
            </>
          )
        }
        return <></>
      }
      return (
        <>
          {renderPricing()}
          <Box
            w='fit-content'
            bgColor='gray.100'
            py='0.8rem'
            px='1rem'
            rounded='lg'
            color='#fff'
            textAlign='center'
            fontWeight='600'
            textTransform='uppercase'
          >
            {_t('common.cancelled')}
          </Box>
        </>
      )
    }

    if (canEdit) {
      return (
        <>
          {isVirtual && (
            <Button
              variant='solidAction'
              width='full'
              disabled={isExpired}
              onClick={onJoinSession}
              textTransform='uppercase'
            >
              {_t('product.class.join')}
            </Button>
          )}
        </>
      )
    }

    if (data?.purchased) {
      return (
        <>
          <Flex justify='space-between'>
            {isVirtual && (
              <Button
                variant='solidAction'
                width='full'
                disabled={isExpired}
                onClick={onJoinSession}
                textTransform='uppercase'
              >
                {_t('product.class.join')}
              </Button>
            )}
          </Flex>
        </>
      )
    }

    if (!isContentClassMemo && !isLessonClassMemo && availableSlot <= 0) {
      return (
        <>
          {renderPricing()}
          <Box
            w='full'
            bgColor='gray.100'
            py='0.8rem'
            px='1rem'
            rounded='lg'
            color='#fff'
            textAlign='center'
            fontWeight='600'
            textTransform='uppercase'
          >
            {_t('product.class.full')}
          </Box>
        </>
      )
    }

    if (isLessonClassMemo && lessonExpiredMemo) {
      return (
        <>
          {renderPricing()}
          <Box
            w='full'
            bgColor='gray.100'
            py='0.8rem'
            px='1rem'
            rounded='lg'
            color='#fff'
            textAlign='center'
            fontWeight='600'
            textTransform='uppercase'
          >
            {_t('product.lesson.expired')}
          </Box>
        </>
      )
    }

    return (
      <>
        {renderPricing()}
        <Button
          variant='solidAction'
          w='full'
          onClick={onBuySession}
          textTransform='uppercase'
        >
          {pricing === 0 ? _t('common.free') : _t('common.buy_now')}
        </Button>
      </>
    )
  }

  return (
    <>
      <Flex direction='row' align='center' h='50px' justify='space-between'>
        <Text as='h2' noOfLines={2}>
          {data?.name}
        </Text>
        <Flex align='center'>{renderAction()}</Flex>
      </Flex>
    </>
  )
}

export default StickyAction
